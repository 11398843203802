<template>
  <div class="eu-feed-settings unHiddenEl">
    <div v-if="!isShowing" class="eu-simple-navbar" style="display: block">
      <a class="eu-simple-navbar-item hiddenEl" @click="switchView" href="javascript:;" aria-label="Show feeds page">
        Feed View
      </a>
      <a class="eu-simple-navbar-item eu-simple-navbar-item-active hiddenEl" href="javascript:;"> Details </a>
    </div>
    <div v-if="areDeptsEnabled" class="eu-feed-settings-section">
      <div class="eu-feed-dept-header">
        <span class="eu-feed-dept-title">{{deptPlural}} to Follow</span>
        <!-- <div class="eu-feed-dept-header">
            <span class='eu-feed-dept-title'>Departments </span>
            <span
              tabindex="0"
              class="eu-select"
              @click="showBurger('i')"
              @keyup.enter="showBurger('i')"
              aria-label="Burger menu sort"
              role="menu"
              aria-haspopup="true"
            >
                Sort
            </span>
            <i class="fal fa-angle-down eu-select-arrow"></i>
            <div id="i" class="eu-resource-burger">
              <span tabindex="0" @click="sort_departments(`following`)" @keyup.enter="sort_departments(`following`)"  class="eu-resource-burger-el">
                <span>Following</span>
              </span>
              <span tabindex="0" @click="sort_departments(`notfollowing`)" @keyup.enter="sort_departments(`notfollowing`)" class="eu-resource-burger-el">
                <span>Not Following</span>
              </span>
              <span tabindex="0" @click="sort_departments(`A-Z`)" @keyup.enter="sort_departments(`A-Z`)" class="eu-resource-burger-el">
                <span>Alphabetical A-Z</span>
              </span>
              <span tabindex="0" @click="sort_departments(`Z-A`)" @keyup.enter="sort_departments(`Z-A`)" class="eu-resource-burger-el">
                <span>Alphabetical Z-A</span>
              </span>
            </div>

            <div class="eu-search" style="margin: 8px -4px">
              <input v-model="search" @input="searchDept()" type="text" placeholder="Search Departments" class="eu-search-input js-global-search-input" />
              <button aria-label="Search button for departments" @click="searchDept()" @keyup.enter="searchDept()" class="eu-search-btn-icon">
                <i class="eu-search-icon fas fa-search"></i>
              </button>
            </div>-->
        <p class="eu-feed-dept-count-wrapper">
          <span
            tabindex="0"
            @click="changeShowing()"
            @keyup.enter="changeShowing()"
            aria-label="Go to following @ page"
            role="link"
            class="eu-feed-dept-count-following"
          >
            {{ number_of_following_departments }} Following
          </span>
        </p>
      </div>
      <div v-for="d of departments_list" :key="d.name">
        <div class="iu-feed-department-follow-item-container">
          <img :src="d.logo_url" alt="Department logo" class="iu-feed-department-follow-item-image" />
          <router-link
            v-if="areDeptsEnabled"
            class="iu-feed-department-follow-item-name"
            :to="`/departments/${d.name}`"
            :aria-label="`Go to ${d.name} page`"
            >{{ d.name }}</router-link
          >
          <span class="iu-feed-department-follow-item-name" style="cursor: default" v-else>{{ d.name }}</span>
          <button
            @click="followFeed(false, d.name)"
            v-if="d.is_following"
            class="iu-feed-department-follow-item-button-active"
            :aria-label="`unfollow ${d.name}`"
          >
            <i class="fa-solid fa-check-circle" style="margin-right: 5px"></i>
            Following
          </button>
          <button
            @click="followFeed(true, d.name)"
            v-else
            class="iu-feed-department-follow-item-button"
            :aria-label="`follow ${d.name}`"
          >
            Follow
          </button>
        </div>
      </div>
    </div>
    <div v-if="isPartnerTenant" class="eu-feed-settings-section">
      <div class="eu-feed-dept-header">
        <span class="eu-feed-dept-title">Partners</span>
        <p class="eu-feed-dept-count-wrapper">
          <span
            tabindex="0"
            @click="changeShowing()"
            @keyup.enter="changeShowing()"
            role="link"
            aria-label="Go to following summary"
            class="eu-feed-dept-count-following"
          >
            {{ number_of_following_partners }} Following
          </span>
        </p>
      </div>
      <div v-for="d of partner_list" :key="d.name">
        <div class="iu-feed-department-follow-item-container">
          <router-link
            class="iu-feed-department-follow-item-name"
            :to="`/partneraccounts/${d.name}`"
            :aria-label="`Go to ${d.name} page`"
            >{{ d.name }}</router-link
          >
          <button
            @click="followPartnerFeed(false, d.name)"
            v-if="d.is_following"
            class="iu-feed-department-follow-item-button-active"
            :aria-label="`unfollow ${d.name}`"
          >
            <i class="fa-solid fa-check-circle" style="margin-right: 5px"></i>
            Following
          </button>
          <button
            @click="followPartnerFeed(true, d.name)"
            v-else
            class="iu-feed-department-follow-item-button"
            :aria-label="`follow ${d.name}`"
          >
            Follow
          </button>
        </div>
      </div>
    </div>
    <div v-if="areGroupsEnabled" class="eu-feed-settings-section">
      <div class="eu-feed-dept-header">
        <span class="eu-feed-dept-title">Groups</span>
        <!-- <div class="eu-feed-dept-header">
            <span class='eu-feed-dept-title'>Affinity Groups</span>
            <span
              tabindex="0"
              class="eu-select"
              @click="showBurger('ig')"
              @keyup.enter="showBurger('ig')"
              aria-label="Burger menu sort"
              role="menu"
              aria-haspopup="true"
            >
                Sort
            </span>
            <i class="fal fa-angle-down eu-select-arrow"></i>
            <div id="ig" class="eu-resource-burger">
              <span tabindex="0" aria-label="Sort by Following"
              role="button" @click="sort_groups(`following`)" @keyup.enter="sort_groups(`following`)"  class="eu-resource-burger-el">
                <span>Following</span>
              </span>
              <span tabindex="0" aria-label="Sort by Not Following"
              role="button" @click="sort_groups(`notfollowing`)" @keyup.enter="sort_groups(`notfollowing`)" class="eu-resource-burger-el">
                <span>Not Following</span>
              </span>
              <span tabindex="0" aria-label="Sort Alphabetical A-Z"
              role="button" @click="sort_groups(`A-Z`)" @keyup.enter="sort_groups(`A-Z`)" class="eu-resource-burger-el">
                <span>Alphabetical A-Z</span>
              </span>
              <span aria-label="Sort Alphabetical Z-A"
              role="button" tabindex="0" @click="sort_groups(`Z-A`)" @keyup.enter="sort_groups(`Z-A`)" class="eu-resource-burger-el">
                <span>Alphabetical Z-A</span>
              </span>
            </div>

            <div class="eu-search" style="margin: 8px -4px">
              <input v-model="searchGroupTerm" @input="searchGroups()" type="text" placeholder="Search Groups" class="eu-search-input js-global-search-input" />
              <button aria-label="Search button for groups" @click="searchGroups()" @keyup.enter="searchGroups()" class="eu-search-btn-icon">
                <i class="eu-search-icon fas fa-search"></i>
              </button>
            </div> -->
        <p class="eu-feed-dept-count-wrapper">
          <span
            tabindex="0"
            @click="changeShowing()"
            @keyup.enter="changeShowing()"
            role="link"
            aria-label="Go to following summary"
            class="eu-feed-dept-count-following"
          >
            {{ number_of_following_groups }} Following
          </span>
        </p>
      </div>
      <div v-for="d of group_list" :key="d.name">
        <div class="iu-feed-department-follow-item-container">
          <router-link
            class="iu-feed-department-follow-item-name"
            :to="`/groups/${d.name}`"
            :aria-label="`Go to ${d.name} page`"
            >{{ d.name }}</router-link
          >
          <button
            @click="followGroupFeed(false, d.name)"
            v-if="d.is_following"
            class="iu-feed-department-follow-item-button-active"
            :aria-label="`unfollow ${d.name}`"
          >
            <i class="fa-solid fa-check-circle" style="margin-right: 5px"></i>
            Following
          </button>
          <button
            @click="followGroupFeed(true, d.name)"
            v-else
            class="iu-feed-department-follow-item-button"
            :aria-label="`follow ${d.name}`"
          >
            Follow
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import * as Cookies from "js-cookie";
import { isPartnerTenant } from "../../../utils/CustomUtils";
import { isFeatureEnabled, LICENSE_ONLY_FEATURES } from '../../../utils/PermissionUtils';
import { useCommonDataStore } from '../../../stores/commonData';

export default {
  components: {},
  name: "feed-details",
  props: ["loggedUser", "dashboardPath", "isShowing", "number_of_following_departments", "number_of_following_groups", "number_of_following_partners"],
  data() {
    return {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      search: "",
      searchGroupTerm: "",
      searchPartnerTerm: "",
      departments_list: this.loggedUser.departments,
      group_list: this.loggedUser.groups,
      partner_list: this.loggedUser.partners,
      isPartnerTenant: null,
      areDeptsEnabled: false,
      areGroupsEnabled: false
    };
  },
  async created() {
    this.areDeptsEnabled = isFeatureEnabled(LICENSE_ONLY_FEATURES.DEPTS);
    this.areGroupsEnabled = isFeatureEnabled(LICENSE_ONLY_FEATURES.GROUPS);
    this.isPartnerTenant = isPartnerTenant();
  },
  methods: {
    setActiveTab(tab) {
      sessionStorage.setItem("activeDashboardTab", tab);
    },
    async followFeed(isFollowing: boolean, department: string) {
      this.$emit("follow-feed", isFollowing, department);
      this.departments_list = this.loggedUser.departments;
      this.search = "";
    },
    async followGroupFeed(isFollowing: boolean, group: string) {
      this.$emit("follow-group-feed", isFollowing, group);
      this.group_list = this.loggedUser.groups;
      this.searchGroupTerm = "";
    },
    async followPartnerFeed(isFollowing: boolean, partner: string) {
      this.$emit("follow-partner-feed", isFollowing, partner);
      this.partner_list = this.loggedUser.partners;
      this.searchPartnerTerm = "";
    },
    switchView() {
      const feedEl = document.querySelector(".eu-feed-posts");
      const detailsEl = document.querySelector(".eu-feed-settings");
      detailsEl.classList.add("unHiddenEl");
      feedEl.classList.remove("unHiddenEl");
    },
    searchDept() {
      let list = new Array();
      if (this.search === "") {
        this.departments_list = this.loggedUser.departments;
        return;
      }
      for (let d of this.loggedUser.departments) {
        if (d.name.toLowerCase().includes(this.search.toLowerCase())) {
          list.push(d);
        }
      }
      this.departments_list = list;
    },
    searchGroups() {
      let list = new Array();
      if (this.searchGroupTerm === "") {
        this.group_list = this.loggedUser.groups;
        return;
      }
      for (let d of this.loggedUser.groups) {
        if (d.name.toLowerCase().includes(this.searchGroupTerm.toLowerCase())) {
          list.push(d);
        }
      }
      this.group_list = list;
    },
    searchPartners() {
      let list = new Array();
      if (this.searchPartnerTerm === "") {
        this.partner_list = this.loggedUser.partners;
        return;
      }
      for (let d of this.loggedUser.partners) {
        if (d.name.toLowerCase().includes(this.searchPartnerTerm.toLowerCase())) {
          list.push(d);
        }
      }
      this.partner_list = list;
    },
    changeShowing() {
      this.$emit("is-shown");
    },
    showBurger(id) {
      const burgerEl = document.querySelector(`#${id}`);
      burgerEl.classList.toggle("eu-resource-burger-active");
    },
    sort(param, type) {
      let list = new Array();
      const burgerEl = document.querySelector("#i");

      // Depending on the type, we use either 'departments' or 'groups'
      let items = type === "department" ? this.loggedUser.departments : this.loggedUser.groups;

      if (param === "following" || param === "notfollowing") {
        for (let item of items) {
          if (item.is_following) {
            list.unshift(item);
          } else {
            list.push(item);
          }
        }
        burgerEl.classList.remove("eu-resource-burger-active");
        if (param === "notfollowing") {
          list = list.reverse();
        }
      } else if (param === "A-Z" || param === "Z-A") {
        burgerEl.classList.remove("eu-resource-burger-active");
        list = items.slice().sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        if (param === "Z-A") {
          list = list.reverse();
        }
      } else {
        list = items;
      }

      // Depending on the type, we update either 'departments_list' or 'group_list'
      if (type === "department") {
        this.departments_list = list;
      } else {
        this.group_list = list;
      }
    },
  },
  computed: {
    deptPlural() {
      return useCommonDataStore().getFeatureName('department_plural')
    },
  },
};
</script>
