<template>
  <nav class="partner-tenant-header-container">
    <div class="partner-tenant-header-top-navigation-container">
      <a href="/partner/home">
        <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/BeGlobalii_logo_large.png" alt="BeGlobalii Logo" class="partner-tenant-header-logo">
      </a>
      <div class="partner-tenant-header-navigation-container">
        <a
          class="partner-tenant-header-navigation-item"
          aria-label="Navigate to home page"
          href="/partner/home/opportunities">
            Opportunities
        </a>
        <a
          class="partner-tenant-header-navigation-item"
          aria-label="Navigate to about us page"
          href="/partner/home/partners">
            Partners
        </a>
        <a
          class="partner-tenant-header-navigation-item"
          aria-label="Navigate to about us page"
          href="/partner/home/blogs">
            Blog
        </a>
        <a
          class="partner-tenant-header-navigation-item"
          aria-label="Navigate to about us page"
          href="/partner/home/about-us">
            About BeGlobalii
        </a>
      </div>
    </div>
    <div class="partner-tenant-header-action-buttons-container">
      <router-link id="login-header-button" class="partner-tenant-header-navigation-item" to="/accounts/login">
        Log In
      </router-link>
      <router-link class="partner-tenant-header-button-outlined" to="/accounts/register">
        Sign Up
      </router-link>
    </div>
    <button
      ref="mobile-menu-button"
      @click="toggleMobileMenu"
      class="partner-tenant-header-burger-menu-button"
    >
    <Transition name="fade-mobile-menu-button" mode="out-in">
      <component :is="mobileMenuIcon"></component>
    </Transition>
    </button>
    <Transition name="slide-mobile-menu">
    <div
      ref="mobile-menu"
      class="partner-tenant-header-navigation-container-mobile"
      v-if="isMobileMenuOpen"
      v-closableMobileMenu="{ exclude: ['mobile-menu-button', 'mobile-menu'], handler: 'closeMobileMenu' }"
    >
      <a
        class="partner-tenant-header-navigation-item"
        aria-label="Navigate to home page"
        href="/partner/home/opportunities">
          Opportunities
        </a>
      <a
        class="partner-tenant-header-navigation-item"
        aria-label="Navigate to about us page"
        href="/partner/home/partners">
          Partners
      </a>
      <a
        class="partner-tenant-header-navigation-item"
        aria-label="Navigate to about us page"
        href="/partner/home/blogs">
          Blog
      </a>
      <a
        class="partner-tenant-header-navigation-item"
        aria-label="Navigate to about us page"
        href="/partner/home/about-us">
          About BeGlobalii
      </a>
      <div class="partner-tenant-header-action-buttons-container-mobile">
        <router-link class="partner-tenant-header-button-outlined secondary" style="width: 100%; justify-content: center;" to="/accounts/login">
          Log In
        </router-link>
        <router-link class="partner-tenant-header-button-outlined" style="width: 100%; justify-content: center;" to="/accounts/register">
          Sign Up
        </router-link>
      </div>
    </div>
    </Transition>
  </nav>
</template>

<script>
import handleOutsideClick from '../../../../directives/handleOutsideClick';

export default {
  name: 'partner-tenant-header',
  directives: {
    closableMobileMenu: handleOutsideClick,
  },
  computed: {
    mobileMenuIcon() {
      return this.isMobileMenuOpen ? 'x-icon': 'bars-icon';
    }
  },
  components: {
    'bars-icon': {
      template: '<i class="fa-regular fa-bars"></i>'
    },
    'x-icon': {
      template: '<i class="fa-regular fa-x" style="font-size: 24px;"></i>'
    }
  },
  data() {
    return {
      isMobileMenuOpen: false,
    }
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
  },
}
</script>

<style scoped>

.slide-mobile-menu-enter-active,
.slide-mobile-menu-leave-active {
  transition: all 0.8s ease-in-out;
  overflow: hidden;
}

.slide-mobile-menu-enter,
.slide-mobile-menu-leave-to {
  height: 0;
  padding: 0 32px;
  overflow: hidden;
}

.slide-mobile-menu-enter-to,
.slide-mobile-menu-leave {
  height: 320px;
  overflow: hidden;
}

.fade-mobile-menu-button-enter-active,
.fade-mobile-menu-button-leave-active {
  transition: opacity 0.4s ease;
}

.fade-mobile-menu-button-enter,
.fade-mobile-menu-button-leave-to {
  opacity: 0;
}
</style>
