<template>
  <div class="eu-feed-settings">
    <div class="department-container department-container-left">
      <div
        class="eu-feed-settings-section"
      >
        <p class="eu-feed-dept-address-title">About</p>
        <p style="white-space:pre-line;margin-bottom: 16px;">{{ group.description }}</p>
      </div>
      <div v-if="group.welcome_video_url" class="video-wrapper">
        <iframe
          :src="getYoutubeOrVimeoEmbedLink(group.welcome_video_url)"
          class="dept-video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
       <div
        v-if="group.important_links && Object.keys(group.important_links).length > 0"
        class="eu-feed-settings-section slim-scrollbar-vertical"
        style="max-height: 355px; overflow: scroll; margin-top: 24px"
      >
        <p class="eu-feed-dept-address-title">Helpful Links</p>
        <div v-for="(value, title) in group.important_links" :key="value" style="border-bottom: 2px solid #ececec; margin-bottom: 24px; padding-bottom: 22px">
          <p class="helpful-link-description">{{ title }}</p>
          <a class="eu-feed-dept-social-href" target="_blank" :href="value">{{ value }}</a>
        </div>
      </div>
    </div>

    <div class="department-container department-container-right">
      <div v-if="group.email || group.phone_number || group.website || hasSocials" class="eu-feed-settings-section" style="padding: 0 30px">
        <h2>Contact Information</h2>
        <div style="border-bottom: 2px solid #dadada">
          <div v-if="group.phone_number" class="eu-feed-dept-contact-wrapper">
            <i class="far fa-phone-alt eu-feed-dept-social-icon" style="background-color: #f5eee5; border-radius: 4px; padding: 8px"></i>
            <a :href="'tel:' + getPhoneNumberString(group.phone_number)" :aria-label="getAriaLabelPhoneNumberString(group.phone_number)" class="eu-feed-dept-contact">{{ group.phone_number }}</a>
          </div>
          <div v-if="group.email" class="eu-feed-dept-contact-wrapper">
            <i class="far fa-envelope eu-feed-dept-social-icon" style="background-color: #f5eee5; border-radius: 4px; padding: 8px"></i>
            <a :href="'mailto:' + group.email" class="eu-feed-dept-social-href">{{ group.email }}</a>
          </div>
          <div v-if="group.website" class="eu-feed-dept-contact-wrapper" style="padding-bottom: 20px">
            <i class="far fa-globe eu-feed-dept-social-icon" style="background-color: #f5eee5; border-radius: 4px; padding: 8px"></i>
            <span class="eu-feed-dept-contact-website">
              <a class="eu-feed-dept-contact" target="_blank" :href="group.website">{{ group.website | shortenURL }}</a>
            </span>
          </div>
        </div>
        <div class="eu-feed-dept-social-icon-wrapper">
          <div class="eu-feed-dept-item">
            <div v-for="s of group.socials" :key="s.value" v-show="s.url !== ''" style="margin-right: 16px; font-size: 20px">
              <a v-if="s.url" :aria-label="`link to ${s.value} page`" :href="s.url"
                ><i :class="s.icon" style="margin-right: 10px"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="group.contacts && Object.keys(group.contacts).length > 0"
        class="eu-feed-settings-section slim-scrollbar-vertical"
        style="max-height: 390px; overflow: scroll; margin-top: 24px"
      >
        <p class="eu-feed-dept-address-title" style="padding: 0">Community Contacts</p>
        <div class="department-contacts-containers" v-for="dc of group.contacts" :key="dc.email">
          <img alt="Profile picture of user" :src="dc.profile_pic" style="width: 48px; height: 48px" />
          <div>
            <a :aria-label="`Go to ${dc.full_name} profile page`" class="eu-feed-dept-social-href" target="_blank" :href="`/profiles/${dc.url}`">{{
              dc.full_name
            }}</a>
            <p class="helpful-link-description">{{ dc.role }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { getYoutubeOrVimeoEmbedLink } from '../../utils/CustomUtils';
import { formatURL, getAriaLabelPhoneNumberString, getPhoneNumberString } from '../../utils/StringUtils';

export default {
  components: {},

  name: 'group-details',

  props: ['loggedUser', 'group'],

  data() {
    return {};
  },

  async created() {},

  methods: {
    getAriaLabelPhoneNumberString,
    getPhoneNumberString,
    getYoutubeOrVimeoEmbedLink,
    switchView() {
      const feedEl = document.querySelector('.eu-feed-posts');
      const detailsEl = document.querySelector('.eu-feed-settings');
      detailsEl.classList.add('unHiddenEl');
      feedEl.classList.remove('unHiddenEl');
    },
  },

  computed: {
    hasSocials() {
      if(!this.group || !this.group.socials) {
        return false;
      }

      let socialExists = false;

      this.group.socials.forEach(social => {
        if(!!social.url) {
          socialExists = true;
          return;
        }
      });

      return socialExists;
    }
  },

  filters: {
    shortenURL: function (url: string) {
      return formatURL(url);
    },
  },
};
</script>
