<template>
    <section>
      <div class="eu-simple-title-medium iu-create-meeting-title-container">
        <span
            tabindex="0"
            @click="setCurrentStep('appointments')"
            @keyup.enter="setCurrentStep('appointments')"
            aria-label="Back to the previous page"
            role="button"
            class="eu-meetings-back-btn">
          <i class="far fa-arrow-left iu-create-meeting-back-btn"></i>
        </span>
        Create Appointment
      </div>

      <div class="mc-create-meeting-wrapper">
        <div class="mc-create-meeting-text">
          Great! Connection is good for the soul. We're happy you'd like to connect.
          To help us guide you to the right mentor, tell us what your goal is:
        </div>
        <div
          tabindex="0"
          aria-label="Find a mentor with specific focus area"
          @click="setMentorGroupOrFocus(MentorGroupOrFocus.FOCUS_AREA)"
          @keyup.enter="setMentorGroupOrFocus(MentorGroupOrFocus.FOCUS_AREA)"
          class="mc-create-meeting-form-button"
          :class="`${mentorGroupOrFocus === MentorGroupOrFocus.FOCUS_AREA ? 'active' : ''} `"
          role="button"
        >
          I’d like to speak to a mentor about a specific topic or focus area
        </div>
        <div
          tabindex="0"
          aria-label="Find a mentor from a specific Mentor Group"
          @click="setMentorGroupOrFocus(MentorGroupOrFocus.MENTOR_GROUP)"
          @keyup.enter="setMentorGroupOrFocus(MentorGroupOrFocus.MENTOR_GROUP)"
          class="mc-create-meeting-form-button"
          :class="`${mentorGroupOrFocus === MentorGroupOrFocus.MENTOR_GROUP ? 'active' : ''} `"
          role="button"
        >
          I’d like to speak to a mentor from a specific Mentor Group
        </div>
      </div>

      <div v-if="mentorGroupOrFocus === MentorGroupOrFocus.FOCUS_AREA"  class="mc-create-meeting-wrapper">
        <div class="mc-create-meeting-text">
          Select your focus area:
        </div>
        <select class="mc-create-meeting-input-select" @change="mentorGroupOrFocusChanged" v-model="meetingRequest.focus_area">
          <option selected value="">Any Area</option>
          <option  v-for="option in filterData.focus_areas" v-bind:value="option.name" v-bind:key="option.name">
            {{ option.name }}
          </option>
        </select>
      </div>

      <div v-if="mentorGroupOrFocus === MentorGroupOrFocus.MENTOR_GROUP"  class="mc-create-meeting-wrapper">
        <div class="mc-create-meeting-text">
          Select Mentor Group:
        </div>
        <select class="mc-create-meeting-input-select" @change="mentorGroupOrFocusChanged" v-model="meetingRequest.mentor_group">
          <option selected value="">Any Mentor Group</option>
          <option  v-for="option in filterData.mentorGroups" v-bind:value="option.name" v-bind:key="option.name">
            {{ option.name }}
          </option>
        </select>
      </div>

      <div v-if="mentorGroupOrFocus" class="mc-create-meeting-wrapper">
        <div class="mc-create-meeting-text">
          Choose your preferred meeting type:
        </div>
        <span v-if="hasVideoChat()" tabindex="0" aria-label="Meeting type chat" role="button"  @click="setMeetingReqProp('meeting_type', 'chat'); submitMeetingRequest(false)" @keyup.enter="setMeetingReqProp('meeting_type', 'chat'); submitMeetingRequest(false)"  class="mc-create-meeting-form-button type-btn" :class="`${meetingRequest.meeting_type === 'chat' ? 'active' : ''} `">Chat</span>
        <span tabindex="0" aria-label="Meeting type video" role="button" @click="setMeetingReqProp('meeting_type', 'video'); submitMeetingRequest(false)" @keyup.enter="setMeetingReqProp('meeting_type', 'video'); submitMeetingRequest(false)" class="mc-create-meeting-form-button type-btn" :class="`${meetingRequest.meeting_type === 'video' ? 'active' : ''} `">Video</span>
        <span tabindex="0" aria-label="Meeting type inperson" role="button" @click="setMeetingReqProp('meeting_type', 'inperson'); submitMeetingRequest(false)" @keyup.enter="setMeetingReqProp('meeting_type', 'inperson'); submitMeetingRequest(false)" class="mc-create-meeting-form-button type-btn" :class="`${meetingRequest.meeting_type === 'inperson' ? 'active' : ''} `">In-Person</span>
      </div>

      <div v-if="meetingRequest.meeting_type && currentStep ==='choose-mentor'" class="mc-create-meeting-wrapper">
        <div class="mc-create-meeting-text" :style="mentor_results.length > 0 ? '' : 'border: none'">
          {{ mentor_results.length > 0 ? 'Here are the mentors that match your criteria:' : 'There are no mentors that match your search.' }}
        </div>
        <span v-for="user in mentor_results"
          tabindex="0"
          @click="selectMentor(user)"
          @keyup.enter="selectMentor(user)"
          :aria-label="`Select ${user.first_name} as mentor`"
          v-bind:value="user.id"
          v-bind:key="user.id" >
          <profile-card :listView="true" :user="user" :department_name="user.department && user.department.name" :focus_areas="stringifyArray(user.focus_areas, 'name')" @openModal="openProfileModal"
              :className="meetingRequest.chosen_mentor && meetingRequest.chosen_mentor.id === user.id ? 'mc-list-card active' : 'mc-list-card' "
          />
        </span>
      </div>

      <div v-if="meetingRequest.meeting_type && mentor_results.length > 0 && meetingRequest.chosen_mentor && meetingRequest.chosen_mentor.id && currentStep ==='choose-mentor'" class="mc-create-meeting-wrapper">
        <div class="mc-create-meeting-text">Fill out the following to create your appointment:</div>

        <div :style="availableDates.length == 0 ? 'pointer-events: none;opacity:0.5': ''" class="field-wrapper hasValue eu-file-upload-modal-field eu-meetings-input">
          <select class="eu-upload-select" @change="onDateSelected" v-model="meetingRequest.date">
          <option v-for="option in availableDates" v-bind:value="option" v-bind:key="option">
              {{ option }}
          </option>
          </select>
          <i class="fal fa-angle-down eu-select-form-arrow"></i>
          <div class="field-placeholder" style="bottom: 38px">
            <span>Date<span class="required-star">*</span></span>
          </div>
        </div>

        <div :style="meetingRequest.date == null ? 'pointer-events: none;opacity:0.5': ''" class="field-wrapper hasValue eu-file-upload-modal-field eu-meetings-input ">
          <select class="eu-upload-select" v-model="meetingRequest.time">
              <option v-for="option in availableTimes" v-bind:value="option.id" v-bind:key="option.time">
              {{ option.time }}
              </option>
          </select>
          <i class="fal fa-angle-down eu-select-form-arrow"></i>
          <div class="field-placeholder" style="bottom: 38px">
              <span>Time<span class="required-star">*</span></span>
          </div>
        </div>

        <div class="field-wrapper hasValue eu-file-upload-modal-field eu-meetings-input" style="margin-top:45px;" >
          <textarea aria-labelledby="message"  v-model="meetingRequest.description" type="text" placeholder="Leave some details about what you’d like to discuss." style="margin-bottom:50px" rows="3" class="eu-textarea-modern" />
          <div id="message" class="field-placeholder"><span>Message</span></div>
        </div>

        <div class="mc-create-meeting-final-buttons">
          <span v-if="formError" tabindex="0" id="book_appointment_error" class="mc-create-meeting-error">Please fill in all required fields.</span>
          <button v-if="currentStep ==='choose-mentor'" @click="setCurrentStep('appointments')" class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary">Cancel</button>
          <button v-if="currentStep ==='choose-mentor'"  @click="submitMeetingRequest(true)" :class="mentor_results.length === 0 ? 'disabled' : ''" class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary">Book Appointment</button>
        </div>
      </div>

      <profile-card-modal :isModalActive="isProfileModalActive" :user="meetingRequest.chosen_mentor" @onClose="closeProfileModal" />
    </section>
</template>

<script lang="ts">

enum MentorGroupOrFocus {
    MENTOR_GROUP = 'mentor_group',
    FOCUS_AREA = 'focus_area'
}
import ProfileCard from '../../../accounts/components/ProfileCard.vue';
import ProfileCardModal from '../../../accounts/components/ProfileCardModal.vue';
import { focusElement } from '../../../utils/CustomUtils';
import { stringifyArray } from '../../utils/common';
export default {
  components: {ProfileCard, ProfileCardModal},
  name: 'create-appointment-section',
  props: ['meetingRequest', 'filterData', 'formError', 'currentStep', 'availableDates', 'availableTimes', 'mentor_results'],
  data() {
    return {
      MentorGroupOrFocus,
      mentorGroupOrFocus: null,
      isProfileModalActive: false,
    };
  },
  async created() {
  },
  methods: {
    stringifyArray,
    hasVideoChat() {
          const isMeetingsEnabled: HTMLElement = document.querySelector('.js-has-video-chat-meetings');
          return JSON.parse(isMeetingsEnabled.dataset.hasVideoChatMeetings.toLowerCase());
    },
    setMentorGroupOrFocus(type: MentorGroupOrFocus) {
      this.mentorGroupOrFocus = this.mentorGroupOrFocus === type ? null : type;
      this.setMeetingReqProp('department', '');
      this.setMeetingReqProp('focus_area', '');
      this.setMeetingReqProp('meeting_type', null);
      this.setCurrentStep('create-appointment');
    },
    mentorGroupOrFocusChanged(e) {
      if(this.meetingRequest.meeting_type) {
        this.submitMeetingRequest(false);
      }
    },
    submitMeetingRequest(isFinal) {
      this.$emit('_submitMeetingRequest', isFinal);
    },
    setCurrentStep(step) {
      this.$emit('_setCurrentStep', step);
    },
    onDateSelected(){
       this.$emit('_onDateSelected');
    },
    setMeetingReqProp(el, value) {
     this.$emit('_setMeetingReqProp', el, value);
    },
    selectMentor(user) {
     this.$emit('_selectMentor', user);
    },
    openProfileModal() {
      this.isProfileModalActive = true;
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      focusElement("profile_modal_title");
      sidebar.style.display = "none";
    },
    closeProfileModal(id) {
      this.isProfileModalActive = false;
      focusElement(`profile_user_card_${id}`);
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      sidebar.style.display = "block";
    },

  },
  computed: {}
};
</script>
