<template>
  <section style="position: relative">
    <header class="first-login-header">
      <div class="flex">
        <div class="da-global-header-logo-box" style="padding-left: 45px; padding-top: 5px">
          <a aria-label="Logo (Go Home)" href="/"
            ><img alt="Logo" :src="`${logoUrl}`" style="height: 55px; width: auto"
          /></a>
        </div>
        <div
          v-if="!welcomeStepActive"
          aria-label="Close Onboarding"
          role="button"
          @keyup.enter="openModal"
          @click="openModal"
          class="sign-in-btn flex center goToDashBtn"
        >
          Go to Dashboard
        </div>
      </div>
    </header>
    <welcome-page
      v-if="welcomeStepActive"
      :video="welcomeVideo"
      @open-close-modal="openModal"
      @continue-to-onboarding="continueToOnboarding"
    />
    <div v-if="!welcomeStepActive">
      <section class="onboarding-section" :style="onboarding_pic ? `background-image: url(${onboarding_pic});` : ''">
        <div class="onboarding-white-tint"></div>
        <div class="onboarding-wrapper">
          <h2 class="onboarding-title">Welcome to {{ isPartnerTenant() ? "BeGlobalii" : "Includifi" }} at</h2>
          <img class="onboarding-logo" alt="Organization logo" :src="logoUrl" />

          <div class="onboarding-progress-container">
            <div class="onboarding-progress-bar-wrapper">
              <div class="onboarding-progress-bar">
                <span class="onboarding-progress-bar-step" :class="currentStepNo >= 0 ? 'active' : ''">
                  <i class="fa-solid fa-bullseye"></i>
                </span>
                <span class="onboarding-progress-bar-line" :class="currentStepNo >= 0 ? 'active' : ''"></span>
                <span class="onboarding-progress-bar-line" :class="currentStepNo >= 1 ? 'active' : ''"></span>
                <span class="onboarding-progress-bar-step" :class="currentStepNo >= 1 ? 'active' : ''">
                  <i class="fa-solid fa-bullseye-arrow"></i>
                </span>
                <span class="onboarding-progress-bar-line" :class="currentStepNo >= 1 ? 'active' : ''"></span>
                <span class="onboarding-progress-bar-line" :class="currentStepNo >= 2 ? 'active' : ''"></span>
                <span class="onboarding-progress-bar-step" :class="currentStepNo >= 2 ? 'active' : ''">
                  <i class="fa-regular fa-people-group"></i>
                </span>
                <span
                  v-if="isPartnerTenant() || areDeptsEnabled"
                  class="onboarding-progress-bar-line"
                  :class="currentStepNo >= 2 ? 'active' : ''"
                ></span>
                <span
                  v-if="isPartnerTenant() || areDeptsEnabled"
                  class="onboarding-progress-bar-line"
                  :class="currentStepNo >= 3 ? 'active' : ''"
                ></span>
                <span
                  v-if="isPartnerTenant() || areDeptsEnabled"
                  class="onboarding-progress-bar-step"
                  :class="currentStepNo >= 3 ? 'active' : ''"
                >
                  <i class="fa-regular fa-landmark"></i>
                </span>
                <span class="onboarding-progress-bar-line" :class="currentStepNo >= 3 ? 'active' : ''"></span>
                <span class="onboarding-progress-bar-line" :class="currentStepNo >= 4 ? 'active' : ''"></span>
                <span class="onboarding-progress-bar-step" :class="currentStepNo >= 4 ? 'active' : ''">
                  <i class="fa-solid fa-flag-checkered"></i>
                </span>
              </div>
            </div>
            <div class="onboarding-description-container">
              <h3 class="onboarding-description-title" style="padding: 0">{{ steps[currentStepNo].title }}</h3>
              <p class="onboarding-description-text">{{ steps[currentStepNo].desc }}</p>
              <p v-if="isLimitReached" class="onboarding-description-text" style="color: #820a01">
                You can select up to 5 options only.
              </p>
            </div>
          </div>
          <div v-if="loading" class="onboarding-select-input-container">
            <loading-spinner wrapperClass="onboarding-loading-spinner-wrapper" />
          </div>
          <div
            v-if="
              (currentStepNo < 3 || (isPartnerTenant() && (currentStepNo < 4 || currentStepNo == 5))) &&
              !loading &&
              !universityPick &&
              !zipCodePick &&
              !graduationYearPicked
            "
            class="onboarding-select-input-container"
          >
            <div
              v-for="(stepItem, i) of steps[currentStepNo].data"
              :key="`firstLoginStep${i}`"
              class="onboarding-select-item-button"
              :class="getIsSelectedClass(stepItem.id)"
              @click="toggleSelect(stepItem.id)"
              @keyup.enter="toggleSelect(stepItem.id)"
              tabindex="0"
              role="button"
              :title="stepItem.name"
              :aria-label="stepItem.label"
            >
              <span>
                {{ stepItem.name }}
              </span>
            </div>
            <div v-if="steps[currentStepNo].data.length == 0">The list is empty</div>
          </div>
          <div v-if="universityPick && !loading" class="onboarding-select-input-container" style="overflow: hidden">
            <div class="onboarding-select-input-wrapper">
              <singleselect-filter-component
                id="uniPick"
                key="uniPick"
                placeholder="Search/enter your school name"
                filter_name="CAI"
                :options="universities"
                @search-change="institutionOptionsList"
                :selected_status="selectedUni"
                labelProp="institution_name"
                trackByProp="institution_id"
                noOptionsText="Start typing in order to get a list of available schools/institutions"
                @filter-data="changeUniSelection"
                additionalStyle="width: 100% !important;"
              />
            </div>
          </div>
          <div v-if="zipCodePick && !loading" class="onboarding-select-input-container" style="overflow: hidden">
            <div class="onboarding-select-input-wrapper">
              <input
                placeholder="ZIP Code (up to 7 characters)"
                type="text"
                class="iu-input-field"
                v-model="zipCode"
                @input="validateZipCode"
                title="Enter a valid ZIP code of 4 to 7 alphanumeric characters"
              />
              <p v-if="zipCodeError" style="color: #f16b4c">Invalid ZIP code. Please enter 4 to 7 characters.</p>
            </div>
          </div>
          <div
            v-if="graduationYearPicked && !loading"
            class="onboarding-select-input-container"
            style="overflow: hidden"
          >
            <div class="onboarding-select-input-wrapper">
              <singleselect-filter-component
                id="gradYear"
                key="gradYear"
                placeholder="Search your expected graduation year"
                :options="generateYears()"
                :selected_status="selectedGradYear"
                labelProp="year"
                trackByProp="year"
                @filter-data="changeGraduationSelection"
                additionalStyle="width: 100% !important;"
              />
            </div>
          </div>
          <div
            v-if="(isPartnerTenant() ? currentStepNo == 4 : currentStepNo == 3) && !loading"
            class="onboarding-select-input-container"
            style="overflow: hidden"
          >
            <div class="onboarding-select-input-wrapper">
              <multiselect-filter-component
                placeholder="Search/select option"
                filter_name="CAI"
                :options="steps[currentStepNo].data"
                :selected_status="selectedCAI"
                :maxHeight="240"
                :limit="5"
                @filter-data="changeCAISelection"
                additionalStyle="width: 100% !important;"
              />
            </div>
          </div>
          <div v-if="currentStepNo == lastStep && !loading" class="onboarding-select-input-container grid">
            <div v-if="steps[currentStepNo].data.length == 0">The list is empty</div>
            <div
              v-for="(stepItem, i) of steps[currentStepNo].data"
              :key="`firstLoginStep${i}`"
              :id="`department-card-${i}`"
              class="onboarding-department-card"
              :class="getIsSelectedClass(stepItem.id)"
              @click="toggleSelect(stepItem.id)"
              @keyup.enter="toggleSelect(stepItem.id)"
              @mouseover="displayDescription(`department-card-${i}`, stepItem.description)"
              @mouseleave="hideDescription"
              tabindex="0"
              role="button"
              :title="stepItem.name"
              :aria-label="stepItem.label"
            >
              <img
                class="onboarding-department-card-image"
                :src="getBackgroundImagePath(stepItem)"
                alt="Department image"
              />
              <div class="onboarding-department-card-name">
                {{ stepItem.name }}
              </div>
            </div>
          </div>
          <div v-if="currentStepNo > lastStep" class="onboarding-select-input-container">
            <lottie-animation
              path="static/assets/icons/first_login_anim.json"
              :loop="false"
              :width="300"
              :height="300"
            />
          </div>
          <div class="onboarding-action-buttons-container">
            <button
              v-if="
                (currentStepNo > 0 && currentStepNo <= lastStep) ||
                universityPick ||
                zipCodePick ||
                graduationYearPicked
              "
              aria-label="Go one step back"
              class="iu-main-btn small text-only gray onboarding-back-button"
              @click="goStepBack()"
            >
              Back
            </button>
            <button
              v-if="currentStepNo <= lastStep"
              aria-label="Go to the next step"
              @click="nextStepOrFinish()"
              @keyup.enter="nextStepOrFinish()"
              class="iu-main-btn small solid main onboarding-next-button"
              :style="zipCodeError ? `pointer-events: none; opacity: 0.7;` : ''"
            >
              {{ currentStepNo == lastStep ? "Finish" : "Next" }}
              <i aria-hidden="true" class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
            </button>
          </div>
        </div>
      </section>
    </div>
    <span
      id="pop-up-textbox-wrapper"
      class="onboarding-department-pop-up-textbox-wrapper"
      :style="popupTextPositionStyle"
    >
      <pop-up-textbox :text="popupTextboxText" :visible="popupTextboxVisible"></pop-up-textbox>
    </span>
    <Modal :isActiveProp="isModalActive" @close="closeModal">
      <div class="row" style="width: auto">
        <div style="text-align: center">
          <span class="eu-upload-filename" style="position: inherit"> Go to Dashboard? </span>
          <p style="padding-top: 30px">Are you sure that you want to close onboarding?</p>
        </div>
      </div>
      <div class="row">
        <label class="eu-checkbox-container" style="margin-bottom: 30px; margin-left: 20%"
          >Do not show onboarding again
          <input tabindex="0" type="checkbox" v-model="dontShowOnboarding" name="dont_show_onboarding" />
          <span id="close_onboarding" class="eu-checkmark"></span>
        </label>
        <div class="column">
          <button
            @click="closeOnboarding()"
            @keyup.enter="closeOnboarding()"
            :aria-label="`Close Onboarding`"
            class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
            style="width: fit-content; height: fit-content"
          >
            Yes
          </button>
          <button
            @click="closeModal"
            @keyup.enter="closeModal"
            aria-label="Close modal"
            class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
            style="width: fit-content; height: fit-content"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>
<script lang="ts">
import * as Cookies from "js-cookie";
import AccountsService from "../../services/AccountsService";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import Notification from "../../../components/Notification.vue";
import { focusElement } from "../../../utils/CustomUtils";
import Modal from "../../../components/ModalExtended.vue";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import MultiselectFilterComponent from "../../../form/components/MultiselectFilterComponent.vue";
import SingleselectFilterComponent from "../../../form/components/SingleselectFilterComponent.vue";
import PopUpTextbox from "../../../components/PopUpTextbox.vue";
import USInstitutionService from "../../../users/services/USInstitutionService";
import { isPartnerTenant } from "../../../utils/CustomUtils";
import WelcomePage from "./WelcomePage.vue";
import { majorLanguages } from '../utils/FirstLoginUtils';

const FOCUS_AREA_TYPES_MSG = {
  experiences: "What experiences are you interested in?",
  support: "What services are you interested in?",
  destinations: "Which destinations are you interested in?",
  services_and_experiences: "What services are you interested in?",
};

export default {
  components: {
    LottieAnimation,
    Notification,
    Modal,
    LoadingSpinner,
    MultiselectFilterComponent,
    SingleselectFilterComponent,
    PopUpTextbox,
    WelcomePage,
  },
  name: "first-login-index",
  props: [
    "dashboardLink",
    "departments",
    "focus_areas_1",
    "focus_areas_2",
    "cai",
    "identities",
    "audiences",
    "logoUrl",
    "onboarding_pic",
    "focus_areas_types",
    "partners",
    "welcome_video_seen",
    "welcome_video",
    "areDeptsEnabled",
    "program_types"
  ],
  data() {
    return {
      isNotificationActive: false,
      FOCUS_AREA_TYPES_MSG,
      loading: false,
      graduationYearPicked: false,
      universityPick: false,
      zipCodePick: false,
      universities: [],
      zipCode: "",
      zipCodeError: false,
      welcomeStepActive: true,
      uniService: new USInstitutionService(),
      lastStep: isPartnerTenant() ? 6 : 4,
      regularSteps: [
        {
          label: "Focus Area Card 2",
          title: FOCUS_AREA_TYPES_MSG[this.focus_areas_types[1]],
          desc: "Make selections below to customize your Includifi experience.",
          data: this.focus_areas_2,
        },
        {
          label: "Focus Area Card 1",
          title: FOCUS_AREA_TYPES_MSG[this.focus_areas_types[0]],
          desc: "Make selections below to customize your Includifi experience.",
          data: this.focus_areas_1,
        },
        {
          label: "Identity Card",
          title: "Interested in identity-based resources?",
          desc: "Make selections below to customize your Includifi experience.",
          data: this.identities,
        },
        {
          label: "Career/Academic Interests Card",
          title: "What are your career/academic interests?",
          desc: "Make selections below to customize your Includifi experience.",
          data: this.cai,
        },
        {
          label: "Department Card",
          title: "Select departments you’d like to follow.",
          desc: "Get access to department-specific videos, articles, events, and mentor matchups.",
          data: this.departments,
        },
        { title: "You're all set!", desc: "Hang on while we load your dashboard." },
      ],
      dabiSteps: [
        {
          label: "Audiences Card",
          title: "What best describes you?",
          desc: "Make selections below to customize your experience.",
          data: this.audiences.sort((a, b) => a.id - b.id),
        },
        {
          label: "Opportunity types",
          title: "What kind of education or career opportunities are you interested in?",
          desc: "Make selections below to customize your experience.",
          data: this.program_types.map(e => { return ({name: e.name, label: e.name, description: e.name, id: e.name})}),
        },
        {
          label: "Focus Area Card 2",
          title: "Where do you want to pursue these opportunities?",
          desc: "Make selections below to customize your experience.",
          data: this.focus_areas_1.sort((a, b) => a.name.localeCompare(b.name)),
        },
        {
          label: "Focus Area Card 1",
          title: "What kind of services and support are you interested in?",
          desc: "Make selections below to customize your experience.",
          data: this.focus_areas_2.sort((a, b) => a.name.localeCompare(b.name)),
        },
        {
          label: "Career/Academic Interests Card",
          title: "What are your career and education interests?",
          desc: "Make selections below to customize your experience.",
          data: this.cai,
        },
        {
          label: "Languages you know",
          title: "Besides English, which language are you proficient in?",
          desc: "Make selections below to customize your experience.",
          data: majorLanguages.map(e => { return ({name: e, label: e, description: e, id: e})}),
        },
        {
          label: "Partner Card",
          title: "Select Partner Accounts you’d like to follow.",
          desc: "Get access to partner-specific videos, articles, events, and mentor matchups.",
          data: this.partners,
        },
        { title: "You're all set!", desc: "Hang on while we load your dashboard." },
      ],
      steps: [],
      selectedAreas1: [],
      selectedAreas2: [],
      selectedIdentities: [],
      selectedCAI: [],
      selectedUni: null,
      selectedDepts: [],
      selectedPartners: [],
      selectedAudiences: [],
      selectedTypes: [],
      selectedLanguages: [],
      selectedGradYear: null,
      isFinalStep: false,
      currentStepNo: 0,
      dontShowOnboarding: false,
      isLimitReached: false,
      isModalActive: false,
      assetPath: process.env.MEDIA_URL,
      notification: { msg: "", isError: false, icon: null },
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      welcomeVideo: {
        url: null,
        title: null,
        description: null,
      },
      screenSize: 0,
      popupTextboxVisible: true,
      popupTextboxOffsetY: 0,
      popupTextboxOffsetX: 0,
      popupTextPositionStyle: "bottom: 0; left: 0;",
      popupTextboxText: "",
    };
  },
  async created() {
    this.steps = this.isPartnerTenant() ? this.dabiSteps : this.regularSteps;
    this.welcomeVideo = this.welcome_video;
    if (!this.welcomeVideo.url && !this.welcomeVideo.title && !this.welcomeVideo.description) {
      this.welcomeStepActive = false;
    }
    this.welcomeVideo.show = !this.welcome_video_seen;
    this.service = new AccountsService();
    this.makeScrollbarSlim();
    const burgerMenu = document.getElementById("mobile-open-nav");
    burgerMenu.style.display = "none";
  },
  methods: {
    goStepBack() {
      this.zipCodeError = false;
      if (this.universityPick || this.zipCodePick || this.graduationYearPicked) {
        //?
      } else {
        this.currentStepNo--;
      }
      this.steps[0].title = this.isPartnerTenant() ? "What best describes you?" : FOCUS_AREA_TYPES_MSG[this.focus_areas_types[1]];
      this.universityPick = false;
      this.zipCodePick = false;
      this.graduationYearPicked = false;
    },
    generateYears() {
      const currentYear = new Date().getFullYear(); // Gets the current year
      const years = [];

      for (let i = 0; i <= 6; i++) {
        years.push({ year: currentYear + i });
      }

      return years;
    },
    continueToOnboarding() {
      this.welcomeStepActive = false;
    },
    async institutionOptionsList(q) {
      let params: Object = {
        institution_name: q,
      };
      const res = await this.uniService.get(params);
      this.universities = res.schools;
    },
    isPartnerTenant,
    changeCAISelection(value, filter_name, param_name) {
      this.selectedCAI = value;
    },
    changeUniSelection(value) {
      this.selectedUni = value;
    },
    changeGraduationSelection(value) {
      this.selectedGradYear = value.year;
    },
    displayDescription(element_id, description) {
      this.popupTextboxText = description;
      const bodyRect = document.body.getBoundingClientRect();
      const element = document.getElementById(element_id);
      const elemRect = element.getBoundingClientRect();
      const elementWidth = element.offsetWidth;
      this.popupTextboxOffsetY = bodyRect.bottom - elemRect.top + 12;
      this.popupTextboxOffsetX = elemRect.left - bodyRect.left + elementWidth / 2 - 155;
      this.popupTextPositionStyle = `bottom: ${this.popupTextboxOffsetY}px; left: ${this.popupTextboxOffsetX}px;`;
      this.popupTextbox = description;
      this.popupTextboxVisible = true;
    },
    hideDescription() {
      this.popupTextboxVisible = false;
      this.popupTextPositionStyle = "top: 0; left: 0;";
    },
    openModal() {
      this.isModalActive = true;
      focusElement(`close_onboarding`);
    },
    async closeOnboarding() {
      if (this.dontShowOnboarding) {
        const res = await this.service.closeOnboarding({}, this.headers);
        if (res?.success) {
          location.replace(this.dashboardLink);
        } else {
          this.showNotification({ msg: res.message, isError: true, icon: "fad fa-exclamation-circle" });
        }
      } else {
        location.replace(this.dashboardLink);
      }
    },
    closeModal() {
      this.isModalActive = false;
    },
    getBackgroundImagePath(step) {
      const assets_path = process.env.MEDIA_URL;
      return step.image_url && step.image_url.startsWith("http")
        ? step.image_url
        : step.image_url
        ? assets_path + step.image_url
        : step.logo_url
        ? step.logo_url
        : this.logoUrl;
    },
    makeScrollbarSlim() {
      const bodyEl = document.getElementsByTagName("BODY")[0];
      bodyEl.classList.add("slim-scrollbar-vertical");
      bodyEl.classList.add("slim-scrollbar-horizontal");
    },
    getIsSelectedClass(item) {
      return this.isPartnerTenant() ? this.getIsSelectedClassDabi(item) : this.getIsSelectedClassRegular(item);
    },
    getIsSelectedClassDabi(item) {
      const lookup = ["selectedAudiences", "selectedTypes", "selectedAreas2","selectedAreas1", "selectedCAI","selectedLanguages", "selectedPartners"];
      return this[lookup[this.currentStepNo]].includes(item) ? "selected" : "";
    },
    getIsSelectedClassRegular(item) {
      const lookup = ["selectedAreas1", "selectedAreas2", "selectedIdentities", "selectedCAI", "selectedDepts"];
      return this[lookup[this.currentStepNo]].includes(item) ? "selected" : "";
    },
    // Determines if the university picker should be shown
    shouldShowUniPicker() {
      const needUniPicker = [1, 2, 4, 6].includes(this.selectedAudiences[0]);
      return needUniPicker && this.currentStepNo === 0;
    },
    validateZipCode() {
      const pattern = /^[a-zA-Z0-9]{4,7}$/;
      this.zipCodeError = !pattern.test(this.zipCode);
    },
    // Handles the university selection step
    handleUniversitySelection() {
      if (!this.universityPick && !this.zipCodePick && !this.graduationYearPicked) {
        this.graduationYearPicked = false;
        if (this.selectedAudiences[0] === 6) {
          this.steps[0].title = "What is your ZIP Code?";
          this.validateZipCode();
          this.zipCodePick = true;
        } else {
          this.universityPick = true;
          this.steps[0].title = "Select your school/institution";
        }
      } else if (!this.graduationYearPicked) {
        this.universityPick = false;
        this.zipCodePick = false;
        this.graduationYearPicked = true;
        this.steps[0].title = "What's Your Expected Graduation Year?";
      } else {
        this.graduationYearPicked = false;
        this.currentStepNo++;
      }
    },

    // Handles the final step after setting initial login data
    async handleFinalStepAfterLoginData() {
      this.loading = true;
      const res = await this.service.setFirstLoginData(
        {
          focus_areas_1: this.selectedAreas1,
          focus_areas_2: this.selectedAreas2,
          audiences: this.selectedAudiences,
          cai: this.selectedCAI.map((c) => c.id),
          departments: this.selectedDepts,
          partners: this.selectedPartners,
          identities: this.selectedIdentities,
          school: this.selectedUni?.institution_id ? this.selectedUni.institution_id : "",
          zip: this.zipCode,
          languages: this.selectedLanguages,
          types: this.selectedTypes,
          gradYear: this.selectedGradYear ?? '',
        },
        this.headers
      );
      this.loading = false;
      if (res?.success) {
        this.currentStepNo = this.lastStep + 1;
        setTimeout(() => {
          location.replace("/");
        }, 2500);
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: "fad fa-exclamation-circle" });
      }
    },

    // Handles setting initial data and getting recommendations
    async handleSetDataAndGetRecommendations() {
      this.loading = true;
      const res = await this.service.setFirstLoginInitDataAndGetRecommendedDepts(
        {
          focus_areas_1: this.selectedAreas1,
          focus_areas_2: this.selectedAreas2,
          cai: this.selectedCAI.map((c) => c.id),
          identities: this.selectedIdentities,
        },
        this.headers
      );
      this.loading = false;
      if (res?.success) {
        this.currentStepNo++;
        this.setRecommendedDeptsOrPartners(res.recommendations, 4);
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: "fad fa-exclamation-circle" });
      }
    },

    // Main method to decide the next step or finish the process
    async nextStepOrFinish() {
      if (this.loading) return;

      const showUniPicker = this.shouldShowUniPicker();
      if (this.currentStepNo < this.getLastPickingStep() && !showUniPicker) {
        this.currentStepNo++;
      } else if (showUniPicker) {
        this.handleUniversitySelection();
      } else if (this.currentStepNo === this.getLastPickingStep()) {
        // Final step, specific to setFirstLoginInitDataAndGetRecommendedDepts

        if (this.isPartnerTenant() || this.areDeptsEnabled) {
          await this.handleSetDataAndGetRecommendations();
        } else {
          await this.handleFinalStepAfterLoginData();
        }
      } else {
        await this.handleFinalStepAfterLoginData();
      }
    },

    // Gets the last picking step based on whether the tenant is a partner
    getLastPickingStep() {
      return this.lastStep - 1;
    },

    setRecommendedDeptsOrPartners(recommendedDeptsOrPartners, numberToShow) {
      if (recommendedDeptsOrPartners.length < numberToShow) {
        const allDeptsOrPartners = this.isPartnerTenant() ? this.partners : this.departments;
        for (let i = 0; i < allDeptsOrPartners.length && recommendedDeptsOrPartners.length < numberToShow; i++) {
          if (!recommendedDeptsOrPartners.some((el) => el.id === allDeptsOrPartners[i].id)) {
            recommendedDeptsOrPartners.push(allDeptsOrPartners[i]);
          }
        }
      }
      this.steps[this.lastStep].data = recommendedDeptsOrPartners;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    toggleItem(item, array, limit = 5, replace = false) {
      if(this.currentStepNo === this.lastStep) {
        limit = 50;
      }
      if (array.includes(item)) {
        this.isLimitReached = false;
        return array.filter((e) => e !== item);
      } else {
        if (replace) {
          return [item];
        } else {
          if (array.length < limit) {
            this.isLimitReached = false;
            return [...array, item];
          } else {
            this.isLimitReached = true;
            return array;
          }
        }
      }
    },
    toggleRegularSelect(item) {
      const lookup = ["selectedAreas1", "selectedAreas2", "selectedIdentities", "selectedCAI", "selectedDepts"];
      this[lookup[this.currentStepNo]] = this.toggleItem(item, this[lookup[this.currentStepNo]]);
    },
    toggleDabiSelect(item) {
      const lookup = ["selectedAudiences", "selectedTypes", "selectedAreas2","selectedAreas1", "selectedCAI","selectedLanguages", "selectedPartners"];
      const replace = this.currentStepNo === 0;
      const limit = replace ? 1 : 5;
      this[lookup[this.currentStepNo]] = this.toggleItem(item, this[lookup[this.currentStepNo]], limit, replace);
    },
    toggleSelect(item) {
      this.isPartnerTenant() ? this.toggleDabiSelect(item) : this.toggleRegularSelect(item);
    },
  },
};
</script>
<style scoped>
.goToDashBtn {
  padding-right: 45px;
  cursor: pointer;
  color: #5e50b5;
  align-self: center;
  border: none;
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  .first-login-wrapper.wider {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 700px) {
  .da-global-header-logo-box {
    padding-left: 10px !important;
  }
  .goToDashBtn {
    padding-right: 10px;
    font-size: 15px;
  }
}
</style>
