<template>
  <section>
    <div class="engagement-snapshot-analytic">
      <div
        style="
          position: absolute;
          width: 200vw;
          height: 100%;
          left: -50%;
          top: 0px;
          z-index: -1;
          background-color: #f1f1f9;
        "
      ></div>
      <h2 class="title">Engagement Snapshot</h2>
      <ul class="analytic-admin-dashboard-wraper">
        <li class="pie-chart left">
          <div v-if="!isLoading" class="analytic-admin-dashboard-legend">
            <p>
              <span class="eu-dashboard-card-icon far fa-user" style="color: #5e50b5"></span>
              <span class="eu-dashboard-card-title">{{ totalUsers }}</span>
            </p>
            <p>Total Users</p>
          </div>
          <div v-if="isLoading" style="margin: 0 auto">
            <loading-spinner />
          </div>
          <div v-if="!isLoading" style="width: 100%">
            <pie-chart
              :array="array_data"
              :colors="[
                '#00a1b3',
                '#f6764d',
                '#9f2a8c',
                '#6df0d2',
                '#1bd4d4',
                '#00a1b3',
                '#007d99',
                '#5e50b5',
                '#9950cd',
                '#d35fa5',
                '#ff997b',
                '#fac484',
                '#fae994',
              ]"
            />
          </div>
        </li>
        <li class="iu-snapshot-container-right">
          <div v-if="isLoading" style="margin: 0 auto">
            <loading-spinner />
          </div>
          <div v-if="!isLoading" class="iu-snapshot-card small">
            <div class="iu-snapshot-card-quantity-container">
              <i class="fa-solid fa-user iu-snapshot-card-icon"></i>
              <span class="iu-snapshot-card-quantity">{{ new_users }}</span>
            </div>
            <div class="iu-snapshot-card-title">
              New users <br />
              (last 7 days)
            </div>
          </div>
          <div v-if="!isLoading && !isPartnerTenant" class="iu-snapshot-card small">
            <div class="iu-snapshot-card-quantity-container">
              <i class="fa-solid fa-building iu-snapshot-card-icon"></i>
              <span class="iu-snapshot-card-quantity">{{ totalDepartments }}</span>
            </div>
            <div class="iu-snapshot-card-title">
              Active <br />
              {{deptPlural}}
            </div>
          </div>
          <div v-if="!isLoading" class="iu-snapshot-card small">
            <div class="iu-snapshot-card-quantity-container">
              <i class="fa-regular fa-eye iu-snapshot-card-icon"></i>
              <span class="iu-snapshot-card-quantity">{{ total_page_views }}</span>
            </div>
            <div class="iu-snapshot-card-title">Pageviews</div>
          </div>
          <div v-if="!isLoading && !isPartnerTenant" class="iu-snapshot-card medium">
            <div class="iu-snapshot-card-quantity-container">
              <i class="fa-regular fa-users iu-snapshot-card-icon"></i>
              <span class="iu-snapshot-card-quantity">{{ active_community_groups }}</span>
            </div>
            <div class="iu-snapshot-card-title">Active Community Groups</div>
          </div>
          <div v-if="!isLoading" class="iu-snapshot-card medium">
            <div class="iu-snapshot-card-quantity-container">
              <i class="fa-regular fa-folder-closed iu-snapshot-card-icon"></i>
              <span class="iu-snapshot-card-quantity">{{ total_resources_engagement }}</span>
            </div>
            <div class="iu-snapshot-card-title">Resources Engagement</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="admin-dashboard-content">
      <ul class="admin-data-cards-container">
        <li
          v-for="card in analyticsData"
          :key="card.id"
          class="data-card"
          :class="{ [card.class]: card.data > 0 }"
          style="display: flex; flex-direction: column; justify-content: space-between"
        >
          <div class="iu-data-card-title description-text">
            {{ card.desc }}
          </div>
          <div class="iu-data-card-action-container">
            <span>
              <i :class="[card.icon, 'data-icon', card.class]"></i>
              <span class="data">{{ card.data }}</span>
            </span>
            <router-link
              :to="card.link"
              class="iu-main-btn main small text-only"
              style="display: flex; align-items: center"
              :aria-label="card.label"
            >
              See all <i aria-hidden="true" class="fa-solid fa-chevron-right iu-main-button-arrow-icon"></i>
            </router-link>
          </div>
        </li>
      </ul>
      <ul class="inup-dashboard-cards-container">
        <li v-for="card in cardsData" :key="card.id" class="inup-dashboard-card">
          <a :aria-label="card.label" :href="card.link" :target="card.target" style="cursor: pointer">
            <div style="display: flex; justify-content: space-between">
              <span>
                <span :class="['dashboard-card-icon', card.icon]" :style="card.iconStyle"></span>
                <span class="dashboard-card-title">
                  {{ card.title }}
                </span>
              </span>

              <a :href="card.link" class="iu-main-btn main small text-only" :aria-label="card.goto">
                {{ card.goto }}<i aria-hidden="true" class="fa-solid fa-chevron-right iu-main-button-arrow-icon"></i>
              </a>
            </div>
            <p class="dashboard-card-description-text" style="cursor: pointer">
              {{ card.desc }}
            </p>
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import PartnerService from "../../partners/services/PartnerService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import PieChart from "../../analytics/components/chart/PieChart.vue";
import { isPartnerTenant } from "../../utils/CustomUtils";
import { useCommonDataStore } from '../../stores/commonData';

export default {
  components: {
    LoadingSpinner,
    PieChart,
  },
  props: ["analyticsData", "cardsData"],
  computed: {
    deptPlural() {
      return useCommonDataStore().getFeatureName('department_plural')
    },
  },
  data() {
    return {
      array_data: [],
      isLoading: true,
      totalUsers: 0,
      totalDepartments: 0,
      isPartnerTenant: isPartnerTenant(),
    };
  },
  async created() {
    this.service = new PartnerService();
    let res = await this.service.getAnalytics();
    this.array_data = res?.array;
    this.isLoading = false;
    this.totalUsers = res?.total;
    this.totalDepartments = res?.departments;
    this.new_users = res?.new_users;
    this.active_community_groups = res?.active_community_groups;
    this.total_resources_engagement = res?.total_resources_engagement;
    this.total_page_views = res?.total_page_views;
  },
  methods: {},
};
</script>

<style scoped>
.analytic-admin-dashboard-legend {
  width: 25%;
  margin-block: auto;
  text-align: center;
}
</style>
