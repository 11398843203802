<template>
    <div :class="'analytics-wrapper-cards ' + valueOfclass" >
        <slot name="header"> </slot>
        <slot name="body"> 

        </slot>
        <slot name="footer"> </slot>
    </div>
</template>

<script>
export default {
    name: "analytics-card",
    props: ['typeFlag', 'typeOfCards', 'flag'],
    data(){
        return{
            valueOfclass: '',
            type: ""
        }
    },
    created(){
        this.type = this.typeOfCards;
    },
    beforeUpdate(){
        this.onChange();
    },
    methods: {
        onChange(){
            if(this.type == this.typeFlag){ 
                this.valueOfclass = this.flag
                return;
            }
        }
    },

    watch: {
        flag: function(newVal, oldVal) {
            this.onChange();
        }
    }
}
</script>

<style scoped>
 

</style>
