<template>
  <section style="height: 105px;">
  <div v-if="listView" tabindex="0" :class="className">
    <img
      class="mc-list-card-img"
      :src="user.profile_pic ? user.profile_pic : '/static/assets/icons/default-profile.svg'"
    />
    <div :id="`profile_user_card_${user.id}`" tabindex="0" class="mc-list-card-name">{{ user.first_name }} {{ user.last_name }}</div>
    <div class="mc-list-card-subtitle" v-if="department_name" :title="`${department_name} • ${focus_areas}`">
      <i class="fas fa-building mc-list-card-icon"></i>
      <span class="mc-list-card-dept">{{ department_name}}</span>
      <span v-if="focus_areas">
        <i class="fa-solid fa-circle-user mc-list-card-icon"></i>
        <span class="mc-list-card-areas" :title="`${focus_areas}`">{{ focus_areas }}</span>
      </span>
    </div>
    <div class="mc-list-card-subtitle" v-else>
      <i class="fas fa-user mc-list-card-icon"></i>
      <span class="mc-list-card-dept">STUDENT</span>
    </div>
    <div
      v-if="!hideProfileHref"
      tabindex="0"
      class="mc-list-card-profile-btn"
      @click="openProfileModal()"
      @keyup.enter="openProfileModal()"
      aria-label="Open profile modal"
      aria-haspopup="dialog"
      role="button"
    >
      View Profile
    </div>

  </div>
  </section>
</template>
<script lang="ts">
import handleOutsideClick from '../../directives/handleOutsideClick'

export default {
  directives: { closable: handleOutsideClick },
  components: {},
  name: "profile-card",
  props: ["user", "className", "department_name", "focus_areas", "listView", "hideProfileHref"],
  data() {
    return {
      styleObject: {
        padding: "",
      },
    };
  },
  async created() {},
  methods: {
    openProfileModal() {
      this.$emit("openModal");
    },
  },
  computed: {},
};
</script>
