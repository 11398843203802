<template>
  <div>
    <div class="dashboard-quest-card">
      <div class="image-wrapper">
        <div
          class="image"
          @click="handleRedirect"
          :aria-label="`Visit ${quest.name} details page`"
          :style="`background-image: url(${quest.image_url})`"
        >
          <span class="tint"></span>
        </div>
        <div class="tags-container">
          <div v-if="quest.is_featured" class="featured-tag">
            <i class="fa-solid fa-stars"></i>
            Featured
          </div>
          <div v-if="quest.is_required" class="required-tag">Required</div>
        </div>
        <button
          class="quest-bookmark-button"
          :class="quest.is_bookmarked ? 'active' : ''"
          :aria-label="`${quest.is_bookmarked ? 'Remove from' : 'Add to'} bookmarked quests`"
          @click="handleBookmark()"
        >
          <i :class="`${quest.is_bookmarked ? 'fa-solid' : 'fa-regular'} fa-bookmark`"></i>
        </button>
      </div>
      <div
        class="title text-clamp-2"
        @click="handleRedirect"
        @keyup.enter="handleRedirect"
        tabindex="0"
        :aria-label="`Visit ${quest.name} details page`"
      >
        {{ quest.name }}
      </div>
      <div class="info-container">
        <div v-if="quest.focus_areas.length > 0" class="focus-areas-container text-clamp-1">
          Focus Areas:
          <span v-for="(focusArea, index) in quest.focus_areas" :key="index" class="focus-area-item">
            <span v-if="index !== 0">,</span>
            {{ focusArea.name }}
          </span>
        </div>
        <div class="bottom-container">
          <div class="tasks"><i class="fa-solid fa-list"></i> {{ taskCount }} task{{ taskCount == 1 ? "" : "s" }}</div>
          <div class="department text-clamp-1">
            <i class="fa-solid fa-building"></i>
            {{ quest.department.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookmarkService from "../../bookmarks/services/BookmarkService";

export default {
  name: "dashboard-quest-card",
  props: ["quest"],
  computed: {
    taskCount() {
      return this.quest.tasks ? this.quest.tasks.length : 0;
    },
  },
  data() {
    return {
      bookmarkService: new BookmarkService(),
    };
  },
  methods: {
    async handleBookmark() {
      const method = this.quest.is_bookmarked ? "deleteBookmarkedQuest" : "bookmarkQuest";
      const notification = { msg: "", isError: false, icon: null };
      const res = await this.bookmarkService[method]({ target: this.quest.id });
      if (res.success) {
        //Idea: create one event instead of two (handle-bookmark-response for example)
        //and extract this code in a parent component
        notification.msg = `Bookmark successfully ${method == "bookmarkQuest" ? "added" : "removed"}`;
        notification.icon = "fad fa-check-circle";
      } else {
        notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        notification.isError = false;
        notification.icon = "fad fa-exclamation-circle";
      }
      this.$emit("show-notification", notification);
      if (res.success) {
        this.$emit("reload");
      }
    },
    handleRedirect() {
      this.$router.push(`/quests/${this.quest.id}`);
    },
  },
};
</script>
