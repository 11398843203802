<template>
    <div class="multiple-input-select">
        <div class="multiple-elements">
            <div class="eu-form-element-container-small">
                <select id="mis-select" class="eu-form-input" v-model="selected">
                    <option disabled value="">Select an option</option>
                    <option v-for="option in options" :value="option.value" :key="option.value">
                        {{ option.text }}
                    </option>
                </select>
                <div class="eu-form-label-container">
                    <label for="mis-select">{{ selectLabel }}</label>
                </div>
            </div>
            <div class="eu-form-element-container-small">
                <input id="mis-text-input" type="text" :class="[{'input-error': hasError}, 'eu-form-input']" v-model="userInput">
                <div class="eu-form-label-container">
                    <label for="mis-text-input">{{ inputLabel }}</label>
                </div>
            </div>
            <button aria-label="Add social link" type="button" class="mis-add-button" @click="addObject">+ Add</button>
        </div>
        <div v-for="item in filledOut" :key="item.value" class="mis-item-container">
            <i v-if="item.icon" class="mis-social-icon" :class="item.icon"></i>
            <i v-else class="fas fa-plus mis-social-icon"></i>
            <p class="mis-item-text">{{ item.text }}:</p>
            <a target="_blank" :href="item.userInput" class="mis-link-text">{{ item.userInput }}</a>
            <button :aria-label="`Remove social lnk for ${item.text}`" class="mis-remove-button" @click="removeObject(item.value)"><i class="fad fa-trash mis-remove-icon"></i></button>
        </div>
    </div>
</template>
<script lang="ts">

export default {
    name: 'multiple-input-select',

    props: ['input', 'optionList', 'filledOptions', 'selectLabel', 'inputLabel',],

    data() {
        return {
            options: [],

            filledOut: [],

            selected: '',
            userInput: '',

            returnObject: {},
            hasError: false,
        };
    },

    created() {

        for (const obj of this.optionList) {
            this.options.push(JSON.parse(JSON.stringify(obj)));
            this.returnObject[obj.value] = '';
        }
        if(this.filledOptions){
            for (const [key, value] of Object.entries(this.filledOptions)) {
                if (value) {
                    const index = this.options.findIndex(x => x.value === key);

                    let temp = this.options.splice(index, 1)[0];
                    temp.userInput = value;
                    this.filledOut.push(temp);

                    this.returnObject[key] = value;
                }
            }
        }

    },

    methods: {
        validateInput: function(input) {
            let regexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

            if(!regexp.test(input)) {
              return false;
            }

            return true;
        },

        addObject: function() {
            if (!this.userInput || !this.selected) {
                return;
            }

            if(!this.validateInput(this.userInput)) {
                this.hasError = true;
                return;
            }
            this.hasError = false;

            const userInput = this.userInput;
            this.userInput = '';

            const index = this.options.findIndex(x => x.value === this.selected);
            let temp = this.options.splice(index, 1)[0];
            temp.userInput= userInput;
            this.filledOut.push(temp);

            this.returnObject[this.selected] = userInput;
            this.selected = '';

            this.input.value = JSON.stringify(this.returnObject);
        },

        removeObject: function(value: 'string') {
            this.returnObject[value] = '';

            const index = this.filledOut.findIndex(x => x.value === value);
            let temp = this.filledOut.splice(index, 1)[0];
            this.options.push(temp);

            this.input.value = JSON.stringify(this.returnObject);
        },
    },
}
</script>
