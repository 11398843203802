import Vue from 'vue';
import ProgramsManage from './components/ProgramsManage.vue'

interface AppData {
  audiences:[],
  type:[],
  departments:[],
  identities:[],
  focus_areas:[],
  destinations:[],
  career_academic_interests: [],
  image_size_data,
  contacts,
  state,
  country,
  program,
  documents,
  partner,
  group,
  isNewProgram,
  deptSingular
}

export default class ProgramsManageView {
  protected mountPoint: HTMLElement;
  protected audiences;
  protected type;
  protected departments;
  protected identities;
  protected focus_areas;
  protected destinations;
  protected career_academic_interests;
  protected image_size_data;
  protected contacts;
  protected state;
  protected country;
  protected program;
  protected documents;
  protected partner;
  protected group;
  protected isNewProgram;
  protected deptSingular;


  constructor(el: HTMLElement, appData:AppData) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.audiences = appData.audiences;
    this.type = appData.type;
    this.departments = appData.departments;
    this.identities = appData.identities;
    this.focus_areas = appData.focus_areas;
    this.destinations = appData.destinations;
    this.image_size_data = appData.image_size_data;
    this.career_academic_interests = appData.career_academic_interests;
    this.contacts = appData.contacts;
    this.state = appData.state;
    this.country = appData.country;
    this.program = appData.program;
    this.documents = appData.documents;
    this.group = appData.group;
    this.partner = appData.partner;
    this.isNewProgram = appData.isNewProgram;
    this.deptSingular = appData.deptSingular;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const programsManageView = new Vue({
      components: {ProgramsManage},
      template: `
        <programs-manage
          :audiences="audiences"
          :type="type"
          :departments="departments"
          :identities="identities"
          :focus_areas="focus_areas"
          :destinations="destinations"
          :career_academic_interests="career_academic_interests"
          :image_size_data="image_size_data"
          :contacts="contacts"
          :state="state"
          :country="country"
          :program_form="program"
          :documents="documents"
          :partner="partner"
          :group="group"
          :isNewProgram="isNewProgram"
          :deptSingular="deptSingular"
        />
      `,
      data() {
        return {
          audiences: _class.audiences,
          type: _class.type,
          departments: _class.departments,
          identities: _class.identities,
          focus_areas: _class.focus_areas,
          destinations: _class.destinations,
          career_academic_interests: _class.career_academic_interests,
          image_size_data: _class.image_size_data,
          contacts: _class.contacts,
          state: _class.state,
          country: _class.country,
          program: _class.program,
          documents: _class.documents,
          partner: _class.partner,
          group: _class.group,
          isNewProgram: _class.isNewProgram,
          deptSingular: _class.deptSingular
        };
      },
      methods: {
      },
    });
    programsManageView.$mount(this.mountPoint);
  }
}
