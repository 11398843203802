<template>
  <section
    class="task-card-wrapper"
    :class="{
      selected: isSelected,
    }"
    :style="isReadOnly ? 'gap: 0' : ''"
  >
    <i v-if="isEditMode" class="fa-regular fa-bars drag-handle"></i>
    <section class="task-card">
      <div class="top-section">
        <div>
          <span class="title">{{ task.name }}</span>
          <span v-if="task.due_date" class="due"
            ><i class="fa-solid fa-hourglass-half due-icon"></i> Due {{ task.due_date | parseDate }}</span
          >
        </div>
        <div v-if="task.items[0]" class="itemType">{{ ITEM_TYPES_MAP[task.items[0].type] }}</div>
      </div>
      <div class="desc" v-html="formatTextToHtml(task.description)"></div>
      <a
        :href="getItemPath(task.items[0])"
        target="_blank"
        v-if="!isReadOnly && task.items[0] && task.items[0].type != 'resource'"
        class="item"
        >{{ getItemName(task.items[0].item) }}<i class="fa-regular fa-arrow-up-right-from-square nav-icon"></i
      ></a>
      <div
        v-if="!isReadOnly && task.items[0] && task.items[0].type == 'resource'"
        class="item"
        @keyup.enter="viewItem(task.items[0].item)"
        @click="viewItem(task.items[0].item)"
      >
        {{ getItemName(task.items[0].item) }}
      </div>
      <div v-if="isEditMode" class="btns">
        <span tabindex="0" @keyup.enter="startEditTask(task)" class="editBtn" @click="startEditTask(task)">Edit</span>
        <span tabindex="0" @keyup.enter="openDeleteModal(task)" class="deleteBtn" @click="openDeleteModal(task)"
          >Delete</span
        >
      </div>
    </section>
    <resource-modal
      :isResourceModalActive="isResourceModalActive"
      :activeResource="activeResource"
      @close-resource-modal="closeResourceModal"
    />
  </section>
</template>

<script>
const ITEM_TYPES_MAP = {
  course: "Pathway Course",
  resource: "Resource Library Item",
  event: "Event",
};
import ResourceModal from "../../library/components/modals/ResourceModal.vue";
import { formatTextToHtml } from "../../utils/CustomUtils";
export default {
  name: "task-card",
  components: { ResourceModal },
  props: ["task", "isReadOnly", "isEditMode", "isSelected"],
  computed: {},
  data() {
    return {
      ITEM_TYPES_MAP: ITEM_TYPES_MAP,
      activeResource: { name: null, resource_url: null, description: null },
      isResourceModalActive: false,
    };
  },
  async created() {},
  methods: {
    formatTextToHtml,
    getItemPath(taskItem) {
      switch (taskItem.type) {
        case "event":
          return `/events/${taskItem.item.slug}`;
        case "course":
          return `/courses/${taskItem.item.id}`;
        default:
          return "";
      }
    },
    getItemName(item) {
      return item.name ? item.name : item.title;
    },
    openDeleteModal(task) {
      this.$emit("open-delete-modal", task);
    },
    startEditTask(task) {
      this.$emit("start-edit-task", task);
    },
    closeResourceModal() {
      this.isResourceModalActive = false;
      const sidebar = document.querySelector("#sidebar");
      sidebar.style.display = "block";
      this.activeResource = { name: null, resource_url: null, description: null };
    },
    viewItem(el) {
      this.activeResource = el;
      const sidebar = document.querySelector("#sidebar");
      sidebar.style.display = "none";
      this.isResourceModalActive = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.task-card-wrapper {
  display: flex;
  border: 1px solid #d1d1d7;
  background: #fff;
  padding: 16px;
  position: relative;
  border-radius: 8px;
  margin: 16px 0;
  flex-grow: 1;
}
.selected {
  border: solid 1px #d0c2ff;
  background-color: rgba(241, 241, 249, 0.9);
}
.task-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.top-section {
  display: flex;
  justify-content: space-between;
}

.title {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.15;
}

.due {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  color: #f6764d;
  white-space: nowrap;
  padding: 1px 6px;
  border-radius: 4px;
  background-color: #fff6d2;
  margin-left: 8px;
}

.itemType {
  padding: 0 6px;
  border-radius: 4px;
  background: #e7e5ec;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.6;
  color: #88858e;
  height: fit-content;
  margin-left: 8px;
}

.desc {
  font-size: 16px;
  line-height: 1.35;
  color: #555555;
}

.item {
  padding: 9px 16px;
  border-radius: 4px;
  background: #e7e5ec;
  font-size: 14px;
  font-weight: 600;
  color: #444444;
  margin-top: 8px;
  width: fit-content;
  line-height: 1.4;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.btns {
  display: flex;
  gap: 16px;
}

.editBtn {
  color: #22a5a0;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

.deleteBtn {
  color: #444444;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}
.nav-icon {
  font-size: 10px;
  color: #137583;
  margin-left: 8px;
}
.due-icon {
  color: #f6764d;
  font-size: 10px;
  margin-right: 6px;
}
.drag-handle {
  color: #b6b4bf;
  font-size: 24px;
  cursor: move;
  margin-right: 16px;
  user-select: none;
}
</style>
