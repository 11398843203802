<template>
  <section class="eu-meeting-appointment-section favorite-mentors-section">
    <div class="header-container">
      <div class="mentor-connect-title-wrapper">
        <common-title
          title="MentorConnect"
          description="Connect with peer mentors and manage appointments."
        />
      </div>

      <div class="button-container">
        <button
          v-if="!noMeetings"
          class="da-components-button da-btn-solid enup-demo-button request-meeting-btn-big" id='requestMeetingBtn'
          @click="setCurrentStep('create-appointment')"
        >
          Connect with a Mentor
          <i class="fas fa-chevron-right right-chevron-btn" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div id="main" class='eu-simple-navbar'>
      <router-link v-if="canManageAppointments" class="eu-simple-navbar-item" to='/meetings/manage'>Manage Mentors</router-link>
      <router-link class="eu-simple-navbar-item" to="/meetings?tab=appointments">My Appointments</router-link>
      <router-link v-if="isMentor" class="eu-simple-navbar-item" to='/meetings/calendar'>My Availability</router-link>
      <router-link class="eu-simple-navbar-item eu-simple-navbar-item-active" to='/meetings?tab=favorites'>Favorite Mentors</router-link>
    </div>
    <div v-if="isLoading">
      <loading-spinner wrapperClass="spinner-whole-screen-center"/>
    </div>
    <admins-and-mentors-tab
      v-if="!isLoading"
      :users="mentors"
      :favoriteMentorsPage="true"
      :isMentor="isMentor"
      :ids_favorite_mentors=[]
      @show-burger="showBurger"
      @close-burgers="closeBurgers"
      @remove-mentor="removeMentor"
    />
    <div class="eu-empty-container" v-if="mentors.length==0 && !isLoading">
      <div class='eu-empty'>
        <div class="eu-empty-icon-container">
          <i class="fad fa-chalkboard-teacher eu-empty-icon"></i>
        </div>
        <div style="display: flex; flex-direction: column;">
          <span class="eu-empty-title">You haven't favorited any mentors yet!</span>
          <span class='eu-empty-text'>You can book meetings with mentors in-person or online.</span>
          <button
            type="button"
            class="da-components-button da-btn-solid enup-demo-button"
            style="width: fit-content; align-self: center; margin-top: 45px; width: 100%;"
            @click="setCurrentStep('create-appointment')"
          >
            <span style="margin-left:10px"> Connect with a Mentor </span>
            <i style="margin:10px" class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import ProfileCard from '../../accounts/components/ProfileCard.vue'
import AccountsService from '../../accounts/services/AccountsService'
import { stringifyArray } from "../../meetings/utils/common"
import * as Cookies from 'js-cookie';
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import AdminsAndMentorsTab from '../../feed/components/AdminsAndMentorsTab.vue'
import CommonTitle from '../../components/CommonTitle.vue';

export default {
    components: {CommonTitle, ProfileCard, LoadingSpinner, AdminsAndMentorsTab},
    name: 'favorite-mentors',
    props: ['isMentor', 'canManageAppointments', 'noMeetings'],
    data() {
        return {
          mentors: [],
          lastOpenedBurger: null,
          isLoading: true,
          headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            Accept: 'application/json'
          },
        };
    },
    async created() {
      this.service = new AccountsService();
      this.mentors = await this.service.getFavoriteMentors();
      this.isLoading = false;
    },
    methods:{
        setCurrentStep(step) {
            this.$emit('_setCurrentStep', step);
        },
        showBurger(el,view){
          const burgerEl = document.querySelector(`#${view}${el.uuid}`);
          if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl){
            this.lastOpenedBurger.classList.remove('eu-resource-burger-active');
          }
          burgerEl.classList.toggle('eu-resource-burger-active');
          setTimeout(() => {this.lastOpenedBurger = burgerEl}, 0);
        },
        closeBurgers(){
          this.lastOpenedBurger && this.lastOpenedBurger.classList.remove('eu-resource-burger-active');
        },
        async removeMentor(id){
          this.isLoading = true
          await this.service.removeFavoriteMentor(id, this.headers);
          this.mentors = await this.service.getFavoriteMentors();
          this.isLoading = false;
        },
        stringifyArray
    }
}

</script>

<style lang="scss" scoped>
.mentor-connect-title-wrapper {
  padding: 24px 0;
}
.header-container {
  margin-top: 0;
}

.title {
  margin: 0;
}
</style>
