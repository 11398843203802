<script lang="ts">
import { defineComponent, reactive, ref } from '@vue/composition-api';
import useBlogManager from '../../blogs/composables/useBlogManager';
import ResourceService from '../services/ResourceLibraryService';

export interface ResourceFilter {
  sort?: string;
  departments?: string;
  name?: string;
  resource_types?: Array<string>;
  focus_areas?: Array<string>;
  identities?: Array<string>;
}

export default defineComponent({
  setup(props, context) {
    const featuredResourcesList = ref([]);
    const resourcesList = ref([]);
    const pageCount = ref(0);
    const isFetching = ref(false);
    const total_num = ref(0);
    const allLoaded = ref(false);
    const searchInput = ref('');
    const filter = ref<ResourceFilter>({})

    const resourceService = new ResourceService();

    const fetchFeaturedResources = async (filters: ResourceFilter) => {
      isFetching.value = true;
      const response = await resourceService.getFeatured(
        {
          ...filters,
          ...filter.value,
          name: searchInput.value,
        }
      );
      featuredResourcesList.value = [];

      if (response) {
        featuredResourcesList.value = response;
        total_num.value += featuredResourcesList.value.length;
      }
      isFetching.value = false;
    };

    const fetchResources = async (filters: ResourceFilter) => {
      pageCount.value += 1;
      isFetching.value = true;

      const response = await resourceService.getResources(
        {
          ...filters,
          ...filter.value,
          page: pageCount.value,
          name: searchInput.value,
        });
      let new_resources = [];
      if (response) {
        new_resources = response.resources;
        if (new_resources && new_resources.length > 0) {
          new_resources.forEach((r) => resourcesList.value.push(reactive(r)));
          total_num.value = response.total_num ? response.total_num : 0;
          total_num.value += featuredResourcesList.value.length;
        } else {
          allLoaded.value = true;
        };
      }
      isFetching.value = false;
    };

    const search = (inputText = "") => {
      featuredResourcesList.value = [];
      resourcesList.value = [];
      allLoaded.value = false;
      pageCount.value = 0;
      searchInput.value = inputText;
      fetchFeaturedResources({});
      fetchResources({});
    }

    const filterData = ref<any>(null);

    const loadFilterData = () => {
      filterData.value = resourceService.getFilterData();
    }

    const createFilterData = (value, name) => {
      if (name === 'Departments') {
        filter.value.departments = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === 'Focus Areas') {
        filter.value.focus_areas = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === 'Identities') {
        filter.value.identities = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === 'Resource Types') {
        filter.value.resource_types = value.map((v) => encodeURIComponent(v.name));
      }

      search();
    }

    const getResourceImage = (resource) => {
      if (resource.resource_type === 'BLOG') {
        return resource.image.url;
      }

      if (resource.resource_type === 'IMG') {
        return resource.resource_url;
      }

      if (resource.thumbnail_url) {
        return resource.thumbnail_url;
      }

      return null;
    };

    const getResourceImageAlt = (resource) => {
      if (resource.resource_type === 'BLOG') {
        return resource.image.alt;
      }

      if (resource.resource_type === 'IMG') {
        return resource.name;
      }

      return null;
    };

    const getAuthorText = (resource) => {
      if (resource.resource_type === 'BLOG') {
        return resource.author.name;
      }

      if (props['mode'] === 'PARTNER') {
        return resource.partner_name;
      }

      return null;
    };

    const getAuthorImage = (resource) => {
      if (resource.resource_type === 'BLOG') {
        resource.author.profilePic.url;
      }

      if (props['mode'] === 'PARTNER') {
        return resource.partner_logo;
      }

      return null;
    };

    const getAuthorImageAlt = (resource) => {
      if (resource.resource_type === 'BLOG') {
        return resource.image.url;
      }

      return resource.name;
    };

    const getResourceBackgroundIcon = (resource) => {
      if (resource.resource_type === 'DOC') return "fa-regular fa-file-alt";
      if (resource.resource_type === 'VID') return "fa-regular fa-file-video";
      if (resource.resource_type === 'URL') return "fa-regular fa-external-link";
      if (resource.resource_type === 'URL_INTERNAL') return "fa-regular fa-file-alt fa-external-link";
      if (resource.resource_type === 'URL_EXTERNAL') return "fa-regular fa-file-alt fa-external-link";
    }

    return {
      featuredResourcesList,
      resourcesList,
      isFetching,
      total_num,
      allLoaded,
      searchInput,
      filterData,
      fetchFeaturedResources,
      fetchResources,
      search,
      getResourceImage,
      getResourceImageAlt,
      getAuthorText,
      getAuthorImage,
      getAuthorImageAlt,
      getResourceBackgroundIcon,
      loadFilterData,
      createFilterData,
    };
  },
});
</script>
