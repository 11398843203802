<template>
  <table class="eu-resource-table">
    <tr class="eu-resource-row">
      <th class="eu-resource-cell eu-resource-cell-th" scope="col">
        User
        <span
          tabindex="0" 
          aria-label="Sort table by name" 
          role="button" 
          @click="sort('first_name')"
          @keyup.enter="sort('first_name')" 
        >
          <i :class="getSortIcon('first_name')"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th" scope="col">
        Role
        <span
          tabindex="0" 
          aria-label="Sort table by role"
          role="button"
          @click="sort('role')" 
          @keyup.enter="sort('role')" 
        >
          <i :class="getSortIcon('role')"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th" scope="col">
        Date added
        <span
          tabindex="0"
          aria-label="Sort table by date added"
          role="button"
          @click="sort('date_joined')" 
          @keyup.enter="sort('date_joined')"
        >
          <i :class="getSortIcon('date_joined')"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th" scope="col">
        Notify
      </th>
    </tr>
    <tr
      v-for="(item, i) in users"
      v-bind:key="i"
      tabindex="0"
      :id="`table_manage_account_${item.id}`" role="row"
      class='eu-resource-row'
    >
      <td class="eu-resource-cell eu-resource-cell-name eu-accounts-cell">
        <img class="eu-profile-thumbnail"  :src="item.profile_pic ? item.profile_pic : '/static/assets/icons/default-profile.svg'"  />
        <span 
          style="position: relative;bottom: 10px;"
        >
            {{ item.first_name }} {{item.last_name}}
        </span>
      </td>
      <td class="eu-resource-cell">{{ item.role && capitalizeFirstLetter(item.role.name) }}</td>
      <td class="eu-resource-cell">{{ item.added }}</td>
      <td class="eu-resource-cell eu-resource-cell-dots-icon">
        <button
          tabindex="0"
          @click="notifyUser(item)"
          @keyup.enter="notifyUser(item)"
          class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
          style="width: fit-content;"
          :aria-label="`Notify ${item.first_name} ${item.last_name}`"
          :disabled="!isActive"
        >
          Notify
        </button>
      </td>
    </tr>
  </table>
</template>
<script lang="ts">

export default {
  name: 'users-table',

  props: ['users', 'sortBy', 'sortDirection'],

  data() {
    return {
      isActive: true,
    };
  },

  methods: {

    getSortIcon(sortValue: string) {
      if (this.sortBy !== sortValue) {
        return 'fas fa-sort';
      }

      if (this.sortDirection === 'up') {
        return 'fad fa-sort-up';
      } else {
        return 'fad fa-sort-down';
      }
    },

    sort(column: string) {
      this.$emit('sortTable', column);
    },

    viewItem(el, index) {
      this.$emit('viewTableItem', el, index);
    },

    openEditModal(item) {
      this.$emit('openEdit', item);
    },

    capitalizeFirstLetter(string) {
        if(string)
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        else return '';
    },
    notifyUser(user) {
      this.isActive = false;
      this.$emit('notify-user', user, (response)=>{
        this.isActive = true;
      });
    },
  },
  computed: {}
};
</script>
