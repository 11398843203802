import HttpService from '../../services/HttpService';

export default class AnalyticsService extends HttpService {
  constructor() {
    super('/analytic/api');
  }

  getBodyCardsData(key: string, paramsOptional?: Object): Promise<any> {
    const params = paramsOptional || {};
    this.endpoint = `/analytics/api/cards/${key}`;
    return this.get(params);
  }

  getTableData(key: string, paramsOptional?: Object): Promise<any> {
    const params = paramsOptional || {};
    this.endpoint = `/analytics/api/table/${key}`;
    return this.get(params);
  }

  getTabData(key: string, paramsOptional?: Object): Promise<any> {
    const params = paramsOptional || {};
    this.endpoint = `/analytics/api/tab/${key}`;
    return this.get(params);
  }

  getChartData(key: string, paramsOptional?: Object): Promise<any> {
    const params = paramsOptional || {};
    this.endpoint = `/analytics/api/chart/${key}`;
    return this.get(params);
  }

  async generateReport(headers, name) {
    const response = await fetch(`/analytics/report/${name}`, {
      method: 'GET',
      headers: new Headers(headers)
    })
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name + "_report.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  getPreData(key) {
    this.endpoint = `/analytics/api/predata/detailed/${key}`;
    return this.get()
  }

  getSummaryPreData() {
    this.endpoint = '/analytics/api/predata';
    return this.get();
  }
}

