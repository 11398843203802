<template>
  <section class='accounts-browse-section'>
    <h1 style="margin-top:30px" class="preference-container-description">
      Users subscribed to newsletter
    </h1>
    <div class="eu-search">
      <form action="" v-on:submit.prevent="fetchUsers()" class="eu-search-form" style="margin-top:30px" role="search">
        <input v-model="searchInput" type="search" placeholder="Search for a user" class="eu-search-input js-global-search-input" />
        <button class="eu-search-btn-icon" aria-label="search">
          <i class="eu-search-icon fas fa-search"></i>
        </button>
      </form>
    </div>
    <div class="table-container" style="width: 100%; overflow-y: hidden;overflow-x: auto;margin: 30px 0;">
      <users-table
          :users="users"
          :sortBy="sortBy"
          :sortDirection="sortDirection"
          @sortTable="sort"
          @notify-user="notifyUser"
        />
    </div>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>
<script lang="ts">
import StudentsService from '../services/UsersService';
import NewsletterService from '../services/NewsletterService';
import UsersTable from './UsersTable.vue';
import Modal from '../../components/ModalExtended.vue';
import Notification from "../../components/Notification.vue";
import { focusElement } from '../../utils/CustomUtils';
import * as Cookies from 'js-cookie';
export default {
  components: { UsersTable, Modal, Notification },

  name: 'users-browse',
  props: ['schema'],

  data() {
    return {
        users: [],
        searchInput: '',
        sortBy: 'first_name',
        sortDirection: 'up',
        isModalActive: false,
        schema_name: this.schema,
        isNotificationActive: false,
        notification: { msg: "", isError: false, icon: null },
        headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      },

    };
  },

  async created() {
     this.service = new StudentsService();
     this.newsletter = new NewsletterService();
     this.fetchUsers();
  },

  methods: {
    async fetchUsers() {

      const result = await this.service.getStudents({
        sort_direction: this.sortDirection,
        sort_by: this.sortBy,
        q: this.searchInput,
        timezone_offset: -new Date().getTimezoneOffset(),
        schema: this.schema_name,
        subscribed_to_newsletter: true,
      });

      this.users = result["students"];
    },

    async notifyUser(user, callback) {
      const params = {
        user_id: user.id,
        schema: this.schema
      };

      const result = await this.newsletter.post(params, this.headers);
      if (result?.success) {
        this.showNotification({ msg: result.content ? "User notified " + user.first_name + " " + user.last_name:"No content available for User " + user.first_name + " " + user.last_name, icon: "fad fa-check-circle" });
      } else {
        this.showNotification({ msg: "Error notifying user "+user.first_name + " " + user.last_name, isError: true, icon: "fad fa-exclamation-circle" });
      }
      this.fetchUsers();
      return callback("este es el mensaje como callback")
    },

    async sort(column) {
      if (this.sortBy === column) {
          this.sortDirection = this.sortDirection === 'up' ? 'down' : 'up';
      } else {
          this.sortBy = column;
          this.sortDirection = 'up';
      }

      this.fetchUsers();
    },

    viewItem(el, index) {
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = "none";
      el.role = el.role ? el.role : {name: null};
      if(el?.hours){
        el.upcoming_hours = el.hours.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
        el.upcoming_hours = el.upcoming_hours.slice(0, 5);
      }
      this.itemToEdit = JSON.parse(JSON.stringify(el));
      this.isModalActive = true;
      focusElement(el.id);
    },

    getProfilePic(user) {
      return (user.profile_pic ? user.profile_pic : "/static/assets/icons/default-profile.svg");
    },

    filterData(value, name){
      this.fetchUsers();
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
  },
};
</script>
