import HttpService from "../../services/HttpService";


export default class PolicyService extends HttpService {
    constructor() {
      super('/policies/api');
    }
  
    create(payload: Object, headers: Object): Promise<any> {
        this.endpoint = '/policies/api';
        return this.post(payload, headers);
    }

    update(payload: any, headers: Object): Promise<any> {
      this.endpoint = `/policies/api/${payload.id}`;
      return this.put(payload, headers);
    }

}