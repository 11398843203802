<template>
  <span
    class="slider-dots-container"
    tabindex="0"
    @keyup.right="selectNext()"
    @keyup.left="selectPrevious()"
    aria-label="Use arrow to change selected item."
  >
    <span
      v-for="index in totalNo"
      :key="index"
      class="slider-dot"
      :class="index === selectedNo ? 'active': ''"
      @click="changeSelection(index)"
      @keyup.enter="changeSelection(index)"
      tabindex="0"
      :aria-label="`Click to select item ${index}`"
    >
    </span>
  </span>
</template>

<script>
export default {
  name: 'slider-dots',
  props: [
    'totalNo',
    'initialSelectedNo',
    'switchSeconds'
  ],
  data() {
    return {
      selectedNo: 0,
      intervalFunctionId: null,
    }
  },
  created() {
    this.initSelection();
    if (this.switchSeconds) {
      this.activateIntervalFunction();
    }
  },
  methods: {
    initSelection() {
      this.changeSelection(this.initialSelectedNo ? this.initialSelectedNo : 1);
    },
    changeSelection(newSelectedNo) {
      this.selectedNo = newSelectedNo;
      this.$emit('changeSelected', newSelectedNo);
      if (this.switchSeconds) {
        this.resetTimer();
      }
    },
    selectNext() {
      this.changeSelection(this.selectedNo === this.totalNo ? 1 : this.selectedNo + 1);
    },
    selectPrevious() {
      this.changeSelection(this.selectedNo === 1 ? this.totalNo : this.selectedNo - 1);
    },
    activateIntervalFunction() {
      this.intervalFunctionId = setInterval(this.selectNext, 1000 * this.switchSeconds);
    },
    deactivateIntervalFunction() {
      if (this.intervalFunctionId) {
        clearInterval(this.intervalFunctionId);
        this.intervalFunctionId = null;
      }
    },
    resetTimer() {
      this.deactivateIntervalFunction();
      this.activateIntervalFunction();
    },
  }
}
</script>
