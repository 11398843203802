<template>
  <table class="eu-resource-table">
    <tr class="eu-resource-row">
      <th class="eu-resource-cell eu-resource-cell-th">
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="toggleAllPartners"
          @keyup.enter="toggleAllPartners"
          aria-label="Select all partners in table"
          role="checkbox"
          :class="allPartnersSelected ? 'eu-resource-cell-select eu-resource-cell-select-active' : 'eu-resource-cell-select'"
        ></span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('name')"
          @keyup.enter="sort('name')"
          aria-label="Sort table by partner name"
          role="button"
        >
          Name
          <i :class="sortBy !== 'name' ? 'fa-solid fa-sort': sortDirection === 'up' ? 'fa-duotone fa-sort-up' : 'fa-duotone fa-sort-down'"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span>
          Email
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span>
          Role
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span>
          Added
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('is_active')"
          @keyup.enter="sort('is_active')"
          aria-label="Sort table by status"
          role="button"
        >
          Status
          <i :class="sortBy !== 'is_active' ? 'fa-solid fa-sort': (sortDirection === 'up' ? 'fa-duotone fa-sort-up' : 'fa-duotone fa-sort-down')"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
      </th>
    </tr>

    <partners-table-row
      v-for="(item, i) in tableItemsList"
      :key="i"
      :rowItem="item"
      :rowVisible="rowVisible(item)"
      :isSelected="isItemSelected(item)"
      @toggle-partner="togglePartner"
      @edit-partner="editPartner"
      @add-manager="addManager"
      @edit-manager="editManager(item)"
      @view-dashboard="viewDashboard"
      @delete-partner="deletePartner"
      @toggle-managers-table="toggleManagersTable"
      @toggle-manager="toggleManager"
      @delete-manager="deleteManager"
      @resend-invitation="resendInvitation"
      @reset-password="resetPassword"
    ></partners-table-row>
  </table>
</template>

<script lang="ts">
import PartnersTableRow from './PartnersTableRow.vue';

export default {
  name: 'partners-table',
  components: {
    PartnersTableRow,
  },
  props: [
    'partners',
    'sortDirection',
    'selectedPartners',
    'selectedManagers',
    'allPartnersSelected',
  ],
  data() {
    return {
      sortBy: 'partner',
      managersExpanded: [],
      tableItemsList: [],
    }
  },
  created() {
    this.tableItemsList = this.getTableItemsList();
  },
  watch: {
      partners: function(newVal, oldVal) {
        this.tableItemsList = this.getTableItemsList();
      }
    },
  methods: {
    toggleManager(manager_id, partner_id) {
      this.$emit('toggle-manager', manager_id, partner_id);
    },
    getTableItemsList() {
      let tableList = [];

      this.partners.forEach(partner => {
        tableList.push({
          'partner': partner,
          'isPartnerRow': true,
          'isAddManagerRow': false,
        });
        const superAdminIds = new Set(partner.partner_superadmins.map(manager => manager.id));

        partner.partner_superadmins.forEach(manager => {
            tableList.push({
              'partner': partner,
              'manager': manager,
              'isPartnerRow': false,
              'isAddManagerRow': false,
            });
        });

        partner.partner_admins.forEach(manager => {
            // Check if the manager is not already in superAdminIds
            if (!superAdminIds.has(manager.id)) {
                tableList.push({
                  'partner': partner,
                  'manager': manager,
                  'isPartnerRow': false,
                  'isAddManagerRow': false,
                });
            }
        });
        tableList.push({
          'partner': partner,
          'isAddManagerRow': true,
        })
      });
      return tableList;
    },
    updateTableItemsList() {
      this.tableItemsList = this.getTableItemsList();
    },
    rowVisible(listItem) {
      if (listItem.isPartnerRow) {
        return true;
      }

      if (this.managersExpanded.includes(listItem.partner.id)) {
        return true;
      }

      return false;
    },
    toggleManagersTable(partnerId) {
      const index = this.managersExpanded.indexOf(partnerId);

      if (index > -1) {
        this.managersExpanded.splice(index, 1);
      } else {
        this.managersExpanded.push(partnerId);
      }
    },
    isItemSelected(item) {
      if(item.isPartnerRow) {
        return this.selectedPartners.includes(item.partner.id);
      }

      if(item.isAddManagerRow) {
        return false;
      }

      return this.selectedManagers.some(manager => manager.manager_id == item.manager.id && manager.partner_id == item.partner.id);
    },
    sort(sortBy: string) {
      this.sortBy = sortBy;
      this.$emit('sort', sortBy);
    },
    editPartner(id) {
      this.$emit('edit-partner', id);
    },
    addManager(id) {
      this.$emit('add-manager', id);
    },
    editManager(rowItem) {
      this.$emit('edit-manager', rowItem.manager, rowItem.partner);
    },
    viewDashboard(id) {
      this.$emit('view-dashboard', id);
    },
    deletePartner(partner) {
      this.$emit('delete-partner', partner);
    },
    resendInvitation(manager_id, partner_id) {
      this.$emit('resend-invitation', manager_id, partner_id);
    },
    toggleAllPartners() {
      this.$emit('toggle-all-partners');
    },
    togglePartner(id) {
      this.$emit('toggle-partner', id);
    },
    deleteManager(rowItem) {
      this.$emit('delete-manager', rowItem.manager.id, rowItem.partner.id)
    },
    resetPassword(manager_id) {
      this.$emit('reset-password', manager_id);
    },
  },
}
</script>
