<template>
  <section class="da-reminder-section da-global-cookie-disclaimer">
      <p class="da-cookie-disclaimer-text">
          This site uses cookies. For additional information you can checkout our
          <a
            href="/privacy"
            class="da-reminder-passport-link"
          >Privacy Policy</a>
          page.
      </p>
      <button @click="handleDismiss" class="da-reminder-btn da-components-button da-btn-solid">ACCEPT</button>
  </section>
</template>

<script>
export default {
  name: "cookie-disclaimer",
  props: {
    logged: String
  },
  methods: {
    handleDismiss: function(e) {
      e.preventDefault();
      return this.logged === "yes"
        ? this.handleDismissLogged()
        : this.handleDismissNotLogged();
    },
    handleDismissLogged: function() {
      fetch("/profiles/cookie/accept/", {
        method: "PUT"
      }).then(response => {
        if (response.status === 200) {
          localStorage.setItem("show-cookie-disclamer", "accepted");
          this.removeFromDom();
        }
      });
    },
    handleDismissNotLogged: function() {
      localStorage.setItem("show-cookie-disclamer", "accepted");
      this.removeFromDom();
    },
    removeFromDom: function() {
      const cookieDisclaimer = document.querySelector(
        ".da-global-cookie-disclaimer"
      );
      cookieDisclaimer.parentNode.removeChild(cookieDisclaimer);
      const contentSection = document.querySelector('.destination-guide-content-section');
      if (contentSection) {
        contentSection.style.bottom = '0px';
      }
    }
  }
};
</script>
