<template>
  <section v-if="quest.id" style="margin-bottom: 32px">
    <section class="quest-page-header-container">
      <section class="image-container">
        <img :src="quest.image_url" class="image" alt="Quest cover image" />
        <div class="tint"></div>
        <div class="back-button-container">
          <button @click="changePage('/quests/')" class="back-button">
            <i class="fa-solid fa-chevron-left back-arrow"></i>
            <span class="text">Browse all Quests</span>
          </button>
        </div>
        <div class="text-container">
          <span class="quest-label"><i class="fa-solid fa-flag-swallowtail quest-ico"></i> Quest</span>
          <span class="title">{{ quest.name }}</span>
        </div>
      </section>

      <div class="action-container">
        <navigation-tabs
          :navigationItems="navigationItems"
          :preventRedirect="true"
          :initialTab="selectedTab"
          @changeTab="changeTab"
          class="page-nav-section"
        ></navigation-tabs>
        <div class="buttons-container">
          <div class="ratings-wrapper" :class="quest.tasks.length == (quest.quest_student && quest.quest_student.no_of_completed_tasks) ? '' : 'disabled'">
            <div @click="rateQuest(quest.id, 1)" class="rating-item" :class="quest.current_user_rating == 1 ? 'positive' : ''" style="border-radius: 8px 0 0 8px;"><i class="fa-solid fa-thumbs-up"></i> {{positiveRatings}}</div>
            <div @click="rateQuest(quest.id, 0)" class="rating-item" :class="quest.current_user_rating == 0 ? 'negative' : ''" style="border-radius: 0 8px 8px 0;"><i class="fa-solid fa-thumbs-down"></i> {{negativeRatings}}</div>
          </div>
          <button
            v-if="canManage()"
            class="iu-main-btn small solid main"
            @click="changePage(`/quests/edit/${quest.id}`)"
            :aria-label="`Go to edit quest page`"
          >
            <i class="fa-solid fa-edit ico-md"></i>
            Edit Quest
          </button>
          <button @click="startQuest" class="iu-main-btn small solid main">
            <i v-if="getStatusText() != 'Review'" class="fa-solid fa-play ico-md"></i>{{ getStatusText() }} Quest
          </button>
        </div>
      </div>
    </section>
    <div class="departments-main-wrapper">
      <div v-if="selectedTab === 'overview'">
        <div class="event-content-body-wrapper border-bottom">
          <div class="event-content-left-bar" style="width: 70%">
            <div v-if="!quest.is_published" class="event-content-desc not-published">
              This quest hasn’t been published and is not visible to the public yet.
            </div>
            <div class="event-content-desc">
              <div class="flex-row-12">
                <h2 class="card-title">Quest Status</h2>
                <button v-if="getStatusText() != 'Review'" @click="startQuest" class="start-label">
                  <i class="fa-solid fa-play ico-sm"></i>{{ `${quest.quest_student ? "Continue" : "Start"}` }}
                </button>
              </div>

              <div class="quest-progress-bar">
                <span class="progress"
                  >{{ quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0 }} /
                  {{ quest.tasks.length }}</span
                >
                <span
                  class="quest-progress-bar-completed"
                  :style="`width: ${
                    100 / (quest.tasks.length / (quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0))
                  }%`"
                ></span>
                <div
                  class="svg-container"
                  :style="
                    quest.tasks.length != (quest.quest_student ? quest.quest_student.no_of_completed_tasks : 0)
                      ? 'filter: grayscale(0.9);'
                      : ''
                  "
                ></div>
              </div>
            </div>
            <div v-if="quest.video_url" class="event-content-desc" style="padding: 0">
              <div class="video-wrapper">
                <iframe
                  :src="getYoutubeOrVimeoEmbedLink(quest.video_url)"
                  class="dept-video"
                  style="height: 320px"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
            <div class="event-content-desc">
              <h2 class="card-title">About</h2>
              <p class="desc" style="margin-bottom: 20px" v-html="formatTextToHtml(quest.description)"></p>
            </div>
          </div>
          <div class="event-content-right-bar" style="width: 30%">
            <div class="right-bar-wrapper">
              <div class="content-wrapper">
                <h3 class="eu-feed-dept-address-title">Quest Details</h3>
                <p>
                  <i class="fas fa-building event-content-header-icon"></i>
                  <span class="boldTxt">
                    {{ quest.department.name }}
                  </span>
                </p>
              </div>
              <div v-if="quest.due_date" class="content-wrapper">
                <p class="deadlineTxt">Deadline</p>
                <p>
                  <span class="content-text" style="color: #e65722">
                    {{ quest.due_date | parseDate }}
                  </span>
                </p>
              </div>
              <div v-if="quest.students.length > 0" class="content-wrapper">
                <div class="students">
                  <div
                    v-for="(s, index) in firstFiveStudents"
                    v-bind:key="s.id"
                    class="student-thumb"
                    :style="`background-image: url('${s.profile_pic}'); left: ${index * 20}px;`"
                  ></div>
                </div>
                <span
                  :style="`margin-left: ${
                    8 + (quest.students.length > 5 ? 5 : quest.students.length) * 25
                  }px; line-height: 1.9`"
                  >{{ quest.students.length }} student<span v-if="quest.students.length > 1">s</span></span
                >
              </div>
            </div>

            <div v-if="quest.contacts.length > 0" class="right-bar-wrapper">
              <p class="eu-feed-dept-address-title" style="padding: 0">Contacts</p>
              <div v-for="item in quest.contacts" v-bind:key="item.uuid" class="department-contacts-containers">
                <img
                  alt="Profile picture of user"
                  :src="item.profile_picture"
                  style="width: 48px; height: 48px; border-radius: 4px"
                />
                <div>
                  <a aria-label="Go to full name profile page" class="boldTxt" :href="'/profiles/' + item.url">{{
                    item.name
                  }}</a>
                  <p class="smallTxt">{{ item.role }}</p>
                </div>
              </div>
            </div>

            <div class="right-bar-wrapper">
              <div v-if="quest.focus_areas.length !== 0" class="content-wrapper">
                <h2 class="event-content-address-title">Focus Areas</h2>
                <div class="flex-wrapper-content">
                  <p v-for="item in quest.focus_areas" v-bind:key="item.id" class="tag-item">
                    {{ item.name }}
                  </p>
                </div>
              </div>
              <div v-if="quest.identities.length !== 0" class="content-wrapper">
                <h2 class="event-content-address-title">Identity Groups</h2>
                <div class="flex-wrapper-content">
                  <p v-for="item in quest.identities" v-bind:key="item.id" class="tag-item">
                    {{ item.name }}
                  </p>
                </div>
              </div>
              <div v-if="quest.audiences.length !== 0" class="content-wrapper">
                <h2 class="event-content-address-title">Audience</h2>
                <div class="flex-wrapper-content">
                  <p v-for="item in quest.audiences" v-bind:key="item.id" class="tag-item">
                    {{ item.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="recommendedQuests.length > 0" class="event-content-body-wrapper flex-column-24">
          <div class="space-between">
            <div class="bigTxt">More Quests for You</div>
            <button @click="changePage(`/quests/`)" class="link">
              Browse all <i class="fas fa-chevron-right" aria-hidden="true"></i>
            </button>
          </div>

          <div class="recommendations">
            <quest-card v-for="q in recommendedQuests" v-bind:key="q.id" :q="q" />
          </div>
        </div>
      </div>

       <div v-if="quest.id && selectedTab === 'feed'" class="department-main-content-feed">
        <quest-feed
          :quest="quest"
          @show-notification="showNotification"
        />
       </div>

      <div v-if="quest.id && selectedTab === 'tasks'" class="tasks-wrapper">
        <div v-if="!quest.is_published" class="event-content-desc not-published" style="width: 100%">
          This quest hasn’t been published and is not visible to the public yet.
        </div>
        <div class="task-cards-wrapper">
          <div class="space-between">
            <span class="card-title" style="margin: 0">Tasks Preview</span>
            <span class="mdTxt">{{ quest.tasks.length }} task{{ quest.tasks.length == 1 ? "" : "s" }}</span>
          </div>
          <div v-for="t in quest.tasks" :key="t.id">
            <task-card :isReadOnly="true" :task="t" />
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'resources'" class="tasks-wrapper wide">
        <div v-if="!quest.is_published" class="event-content-desc not-published" style="width: 100%">
          This quest hasn’t been published and is not visible to the public yet.
        </div>
        <dashboard-card
          @set-page="setPage"
          @set-section="setSection"
          :pageIndex="resourcesTabIndex"
          :section="currentSection"
          :showOptions="true"
          :pagesLen="currentPageLen"
          :availableFeatures="resourcesTabItems"
          :eventsOnly="true"
          :hideSeeAll="true"
          :cardFeatures="['events', 'questCourses', 'resources']"
        >
          <section v-if="currentSection == SECTIONS.events">
            <div v-if="resourceTabData.events.length > 0" class="main-content">
              <div v-for="(event, i) in createPages(resourceTabData.events, itemLimit)[resourcesTabIndex]" :key="i">
                <event-card :event="event" />
              </div>
            </div>
            <div v-else-if="resourceTabData.events.length == 0"></div>
            <div v-else style="height: 272px">
              <loading-spinner wrapperClass="absolute-center" />
            </div>
          </section>
          <section v-if="currentSection == SECTIONS.questCourses">
            <div v-if="resourceTabData.questCourses.length > 0" class="main-content">
              <div
                v-for="(course, i) in createPages(resourceTabData.questCourses, itemLimit)[resourcesTabIndex]"
                :key="i"
              >
                <course-card :course="course" />
              </div>
            </div>
            <div v-else-if="resourceTabData.courses.length == 0"></div>
            <div v-else style="height: 272px">
              <loading-spinner wrapperClass="absolute-center" />
            </div>
          </section>
          <section v-if="currentSection == SECTIONS.resources">
            <div v-if="resourceTabData.resources.length > 0" class="main-content">
              <div v-for="(r, i) in createPages(resourceTabData.resources, itemLimit)[resourcesTabIndex]" :key="i">
                <resource-card :item="r" />
              </div>
            </div>
            <div v-else style="height: 272px">
              <loading-spinner wrapperClass="absolute-center" />
            </div>
          </section>
        </dashboard-card>
      </div>
    </div>
    <div v-if="isLoading">
      <loading-spinner wrapperClass="wrapper" />
    </div>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>
<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import QuestService from "../services/QuestService";
import Notification from "../../components/Notification.vue";
import NavigationTabs from "../../components/NavigationTabs.vue";
import { getYoutubeOrVimeoEmbedLink, formatTextToHtml } from "../../utils/CustomUtils";
import QuestCard from "./QuestCard.vue";
import TaskCard from "./TaskCard.vue";
import { SECTIONS, SEE_ALL_URLS } from "../../dashboard/components/DashboardCards.vue";
import DashboardCard from "../../dashboard/components/DashboardCard.vue";
import EventCard from "../../events/components/EventCard.vue";
import ResourceCard from "../../library/components/ResourceCard.vue";
import CourseCard from "../../courses/components/CourseCard.vue";
import QuestFeed from "./QuestFeed.vue";

export default {
  name: "quest-details",
  components: {
    LoadingSpinner,
    Notification,
    NavigationTabs,
    QuestCard,
    TaskCard,
    DashboardCard,
    EventCard,
    ResourceCard,
    CourseCard,
    QuestFeed
  },
  props: ["isAdmin", "isDeptLead"],
  computed: {
    firstFiveStudents() {
      return this.quest.students.slice(0, 5);
    },
    itemLimit() {
      return this.windowWidth > 1166 ? 4 : 8;
    },
  },
  data() {
    return {
      SECTIONS,
      SEE_ALL_URLS,
      isLoading: false,
      positiveRatings: 0,
      negativeRatings: 0,
      assetPath: process.env.MEDIA_URL,
      service: new QuestService(),
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      selectedTab: "overview",
      recommendedQuests: [],
      resourcesTabItems: {
        quest_courses: false,
        resource_library: false,
        events: false,
      },
      currentPageLen: null,
      currentSection: null,
      resourcesTabIndex: 0,
      resourceTabData: { questCourses: [], resources: [], events: [] },
      quest: {
        name: "",
        description: "",
        focus_areas: [],
        identities: [],
        department: null,
        due_date: null,
        contacts: [],
        video_url: "",
        image_url: "",
        is_featured: false,
        is_published: false,
        is_required: false,
      },
      navigationItems: [
        {
          label: "Overview",
          routeName: "overview",
          isRedirectUrl: false,
        },
        {
          label: "Feed",
          routeName: "feed",
          isRedirectUrl: false,
        },
        {
          label: "Tasks",
          routeName: "tasks",
          isRedirectUrl: false,
        },
      ],
    };
  },
  mounted() {
    document.documentElement.style.setProperty("--finish-ico", `url('${this.assetPath}assets/career-goals.svg')`);
  },
  async created() {
    this.selectedTab = this.$route.query.active_tab || 'overview';
    await this.getQuest();
    this.createResourceTabData();
  },
  methods: {
    createResourceTabData() {
      this.quest.tasks.forEach((t) => {
        const item = t.items[0];
        if (item && this.navigationItems.length === 3) {
          this.navigationItems.push({
            label: "Resources",
            routeName: "resources",
            isRedirectUrl: false,
          });
        }
        if (item?.type === "course") {
          this.resourcesTabItems.quest_courses = true;
          this.resourceTabData.questCourses.push(item.item);
          this.currentSection = SECTIONS.questCourses;
        } else if (item?.type === "resource") {
          this.resourcesTabItems.resource_library = true;
          this.resourceTabData.resources.push(item.item);
          this.currentSection = SECTIONS.resources;
        } else if (item?.type === "event") {
          this.resourcesTabItems.events = true;
          this.resourceTabData.events.push(item.item);
        }
      });
      if (this.resourceTabData.events.length > 0) {
        this.currentSection = SECTIONS.events;
      }
    },
    async rateQuest(questId, rating) {
      const res = await this.service.rate({ quest: questId, rating });
        if (res.success) {
          await this.getQuest();
        } else {
          this.showNotification({
            msg: "Error: Cannot rate this Quest",
            isError: true,
            icon: "fad fa-exclamation-circle",
          });
        }
    },
    getStatusText() {
      if (this.quest.quest_student) {
        if (this.quest.quest_student.no_of_completed_tasks === this.quest.tasks.length) {
          return "Review";
        }
        return "Continue";
      }
      return "Start";
    },
    getYoutubeOrVimeoEmbedLink,
    formatTextToHtml,
    async getQuest() {
      const questId = this.$route.path.split("/").pop();
      this.isLoading = true;
      this.quest = await this.service.getOne(questId);
      this.quest.tasks.sort((a, b) => a.order - b.order);
      this.calculateRatings()
      this.isLoading = false;
      this.recommendedQuests = await this.service.getRecommended({ limit: 4 });
      this.recommendedQuests = this.recommendedQuests.filter((e) => e.id !== questId);
      if (this.recommendedQuests.length === 4) {
        this.recommendedQuests.pop();
      }
    },
    calculateRatings() {
      this.positiveRatings = 0;
      this.negativeRatings = 0;
      this.quest.ratings.forEach(rating => {
      if (rating === 1) {
        this.positiveRatings += 1;
      } else if (rating === 0) {
        this.negativeRatings += 1;
      }
    });
    },
    async startQuest() {
      if (!this.quest.quest_student) {
        const res = await this.service.startQuest({ quest: this.quest.id });
        if (!res.success) {
          this.showNotification({
            msg: "Error: Cannot start a Quest",
            isError: true,
            icon: "fad fa-exclamation-circle",
          });
        }
      }
      this.changePage(`/quests/questMode/${this.quest.id}`);
    },
    canManage() {
      if (this.isAdmin || this.isDeptLead === this.quest.department.name) {
        return true;
      }
      return false;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    changePage(page) {
      this.$router.push(page);
    },
    async changeTab(tab) {
      this.selectedTab = tab;
    },
    createPages(list, size = 4) {
      let result = [];
      if (list) {
        for (let i = 0; i < list.length; i += size) {
          let chunk = list.slice(i, i + size);
          result.push(chunk);
        }
        this.currentPageLen = result.length;
        return result;
      }
      return [];
    },
    setPage(index) {
      this.resourcesTabIndex = index;
    },
    setSection(section) {
      this.currentSection = section;
      this.resourcesTabIndex = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.ratings-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 124px;
  line-height: 0;
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.7;
  }
}
.rating-item {
  width: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 8px;
  border: 1px solid #d1d1d7;
  padding: 12px 15px;
  color: #817e87;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &.positive {
    cursor: default;
    background: #e7fef0;
    pointer-events: none;
    color: black;
  }
  &.negative {
    cursor: default;
    background: #fff6d2;
    pointer-events: none;
    color: black
  }
  &:hover {
    opacity: 0.8;
  }
}
.svg-container {
  position: absolute;
  top: 50%;
  right: -6px;
  width: 81px;
  height: 87px;
  background-size: cover;
  transform: translateY(-50%);
  background-image: var(--finish-ico);
}
.tasks-wrapper {
  max-width: 720px;
  margin: 0 auto;
  margin-top: 32px;

  &.wide {
    max-width: 100%;
    padding: 32px;
  }
}
.task-cards-wrapper {
  background: #fff;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.06);
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mdTxt {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
}
.link {
  background: transparent;
  color: #685df3;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.recommendations {
  display: flex;
  gap: 24px;
}
.flex-row-12 {
  display: flex;
  gap: 12px;
  align-items: center;
}
.flex-column-24 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.not-published {
  color: #555555;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  background: #ffe6e0;
  border-radius: 8px;
}
.bigTxt {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
  color: #333333;
}
.border-bottom {
  border-bottom: 2px solid #e7e5ec;
  margin-bottom: 32px;
}
.tag-item {
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3;
  padding: 4px 8px;
  background-color: #ececec;
}
.start-label {
  color: #0b9d84;
  font-weight: 600;
  background: #e7fef0;
  padding: 1px 6px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: max-content;
  &:hover {
    opacity: 0.7;
  }
}
.ico-sm {
  color: #0b9d84;
  font-size: 11px;
  margin-right: 5px;
}
.ico-md {
  color: #cbe9ec;
  font-size: 14px;
  margin-right: 6px;
}
.quest-label {
  background: #fadef5;
  padding: 11px 22px;
  border-radius: 4px;
  border: solid 1px #a054c3;
  width: max-content;
  margin-bottom: 8px;
  color: #a054c3;
  font-size: 18px;
  font-weight: 600;
  cursor: default;
}
.quest-ico {
  @extend .ico-md;
  color: #a054c3;
}
.progress {
  position: relative;
  z-index: 9;
}
.quest-progress-bar {
  background-color: #e7e5ec;
  color: #777777;
  border-radius: 16px;
  height: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
  position: relative;

  &-completed {
    position: absolute;
    height: 24px;
    left: 0;
    border-radius: 16px 0 0 16px;
    background-color: #77c4ca;
  }
}

.card-title {
  font-size: 26px;
  font-weight: 500;
  color: #444444;
  margin: 16px 0;
  line-height: 1.31;
}
.students {
  display: flex;
  position: relative;
}
.student-thumb {
  position: absolute;
  width: 32px;
  height: 32px;
  background-size: cover;
  border: 1px solid #fff;
  border-radius: 50%;
}
.desc {
  color: #555555;
  font-size: 20px;
  line-height: 1.55;
}
.boldTxt {
  color: #555555;
  line-height: 1.15;
  font-size: 16px;
  font-weight: 600;
}
.smallTxt {
  color: #95919b;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
}
.deadlineTxt {
  color: #95919b;
  font-size: 16px;
  font-weight: 600;
}
.right-bar-wrapper {
  background-color: transparent;
  padding: 24px;
  border-radius: 16px;
  border: none;
}
.image-container {
  width: 100%;
  min-height: 300px;
  max-width: 1400px;
  overflow: hidden;
  border-radius: 0 0 24px 24px;
  position: relative;
  padding: 2.5rem 2rem;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .back-button-container {
    width: 100%;
    position: relative;

    .back-button {
      color: #101828;
      background-color: #d8dce5;
      border: none;
      padding: 9px 16px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        background-color: #838ea1;
      }

      .back-arrow {
        color: #95919b;
        margin-right: 4px;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
}

.text-container {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 170px;
  justify-content: flex-end;

  .title {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #fff;
  }
}

.quest-page-header-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid #c3cbd6;
  padding: 0 2rem;
}

.page-nav-section {
  width: 100%;
  position: relative;
  top: 13px;
  padding-top: 1rem;
}

.action-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-top: 0.75rem;
  gap: 1.5rem;

  .buttons-container {
    display: flex;
    gap: 0.5rem;
    padding-bottom: 10px;
  }
}
</style>
