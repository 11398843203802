import HttpService from '../../services/HttpService';
import * as Cookies from 'js-cookie';

const headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class ProfileService extends HttpService {
  constructor() {
    super('/profiles/api');
  }

  getPreData(profileUrl: string): Promise<any> {
    this.endpoint = `/profiles/api/${profileUrl}`;
    return this.get();
  }

  getProfileSettings(data): Promise<any> {
    this.endpoint = `/profiles/api/settings/profile`;
    return this.get();
  }

  updateProfile(data): Promise<any> {
    this.endpoint = `/profiles/api/settings/profile`;
    return this.post(data, headers);
  }

  updatePassword(data): Promise<any> {
    this.endpoint = `/profiles/api/settings/password`;
    return this.postFormData(data, headers);
  }

  updatePreferences(data): Promise<any> {
    this.endpoint = `/profiles/api/preferences`;
    return this.postFormData(data, headers);
  }

}
