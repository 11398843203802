<template>
    <div class="analytics-body-wrapper-cards" >
        <div v-for="d in data" :key="d.text" class="analytics-body-cards"> 
            <i :class="'analytics-cards-body-icon analytics-cards-icon-global far ' + d.icon"> </i>
            <span class="analytics-cards-body-title analytics-cards-title-global" > {{d.text}} </span>
            <span class="analytics-cards-body-right"> {{d.value}} </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "analytics-body-card",
    props:['data'],
    data(){
        return{

        }
    },
}
</script>

<style scoped>
 

</style>