<template>
  <div class="resource-card" :class="resource.is_recommended ? 'featured' : ''">
    <div class="image-wrapper" :class="resource.is_recommended ? 'featured' : ''">
      <span
        :class="isSelected ? 'selected' : ''"
        class="iu-resource-grid-checkbox"
        tabindex="0"
        role="checkbox"
        style="z-index: 8"
        @click="toggleResourceSelection($event)"
        @keyup.enter="toggleResourceSelection($event)"
        :aria-label="`Select ${resource.name}`"
      >
        <i class="fa-solid fa-check iu-resource-grid-check-icon" v-if="isSelected"></i>
      </span>
      <div class="resource-type-pill">
        {{ getResourceTypeText() }}
      </div>
      <img
        class="resource-image"
        :class="resource.is_recommended ? 'featured' : ''"
        v-if="cardImageUrl"
        :src="cardImageUrl"
        :alt="getResourceImageAlt(resource)"
        @click="emitEvent('view-item')"
        @keyup.enter="emitEvent('view-item')"
        tabindex="0"
        :aria-label="`View ${resource.name}`"
      />
      <div
        v-else
        class="resource-icon-container"
        :class="resource.is_recommended ? 'featured' : ''"
        @click="emitEvent('view-item')"
        @keyup.enter="emitEvent('view-item')"
        tabindex="0"
        :aria-label="`View ${resource.name}`"
      >
        <i :class="cardFaIconClass"></i>
      </div>
    </div>
    <div class="text-container" :class="resource.is_recommended ? 'featured' : ''">
      <div class="title-description-wrapper">
        <h3
          class="title"
          :class="resource.is_recommended ? 'text-clamp-2' : 'text-clamp-1'"
          @click="emitEvent('view-item')"
          @keyup.enter="emitEvent('view-item')"
          tabindex="0"
          :aria-label="`View ${resource.name}`"
        >
          <i v-if="resource.is_recommended" class="featured-icon fa-solid fa-stars"></i>
          {{ resource.name }}
        </h3>
        <p class="description" :class="resource.is_recommended ? 'text-clamp-7' : 'text-clamp-4'">
          {{ resource.description }}
        </p>
      </div>
      <div class="bottom-container">
        <div class="partner-container">
          <img
            v-if="getAuthorImage(resource)"
            :src="getAuthorImage(resource)"
            :alt="getAuthorImageAlt(resource)"
            class="partner-logo"
            :class="resource.is_recommended ? 'featured' : ''"
          />
          <span class="partner-name text-clamp-1">{{ getAuthorText(resource) }}</span>
        </div>
        <div class="actions-container">
          <button
            class="bookmark-button partner"
            :class="resource.is_bookmarked ? 'active' : ''"
            :aria-label="(resource.is_bookmarked ? 'Remove' : 'Add') + ' resource bookmark'"
            @click="toggleResourceBookmark(resource.id)"
          >
            <i class="fa-bookmark" :class="bookmarkIcon(resource)"></i>
          </button>
          <dropdown-burger-menu
            buttonClass="partner-resource-dropdown"
            :actionList="getActionList()"
            @view-item="emitEvent('view-item')"
            @edit-item="emitEvent('edit-item')"
            @edit-blog="emitEvent('edit-blog')"
            @unfeature-item="emitEvent('unfeature-item')"
            @feature-item="emitEvent('feature-item')"
            @download-item="emitEvent('download-item')"
            @remove-item-bookmark="emitEvent('remove-item-bookmark')"
            @bookmark-item="emitEvent('bookmark-item')"
            @copy-item-link="emitEvent('copy-item-link')"
            @unarchive-item="emitEvent('unarchive-item')"
            @archive-item="emitEvent('archive-item')"
            @delete-item="emitEvent('delete-item')"
          ></dropdown-burger-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useBlogManager from '../../blogs/composables/useBlogManager';
import DropdownBurgerMenu from '../../components/DropdownBurgerMenu.vue';
import useResourceManager from '../composables/useResourceManager.vue';

export default {
  name: 'partner-resource-card-basic',
  setup(pros, context) {
    // @ts-ignore
    const {
      // @ts-ignore
      getResourceImage, // @ts-ignore
      getResourceImageAlt, // @ts-ignore
      getAuthorText, // @ts-ignore
      getAuthorImage, // @ts-ignore
      getAuthorImageAlt, // @ts-ignore
      getResourceBackgroundIcon,
    } =
      // @ts-ignore
      useResourceManager.setup({ mode: 'PARTNER' }, context);

    return {
      getResourceImage,
      getResourceImageAlt,
      getAuthorText,
      getAuthorImage,
      getAuthorImageAlt,
      getResourceBackgroundIcon,
    };
  },
  components: {
    DropdownBurgerMenu,
  },
  computed: {
    cardImageUrl() {
      if (this.resource.resource_type === 'BLOG') {
        return this.resource.image.url;
      }

      if (this.resource.resource_type === 'IMG') {
        return this.resource.resource_url;
      }
      if (this.resource.thumbnail_url) {
        return this.resource.thumbnail_url;
      }
      return null;
    },
    cardFaIconClass() {
      if (this.resource.resource_type === 'DOC') {
        return 'fa-regular fa-file-alt';
      }
      if (this.resource.resource_type === 'VID') {
        return 'far fa-file-video';
      }
      if (this.resource.resource_type === 'URL') {
        return 'far fa-external-link';
      }
      if (this.resource.resource_type === 'URL_INTERNAL') {
        return 'far fa-external-link';
      }
      if (this.resource.resource_type === 'URL_EXTERNAL') {
        return 'far fa-external-link';
      }
    },
  },
  props: ['resource', 'manageAccess', 'isSelected', 'idsPartnerAccountsManaged'],
  methods: {
    toggleResourceSelection() {
      this.$emit('toggle-selection');
    },
    toggleResourceBookmark() {
      if (this.resource.is_bookmarked) {
        this.emitEvent('bookmark-item');
      } else {
        this.emitEvent('remove-item-bookmark');
      }
    },
    getResourceTypeText() {
      if (this.resource.resource_type === 'DOC') return "Document";
      if (this.resource.resource_type === 'VID') return "Video";
      if (this.resource.resource_type === 'URL') return "Link";
      if (this.resource.resource_type === 'IMG') return "Image";
      if (this.resource.resource_type === 'URL_INTERNAL') return "Link";
      if (this.resource.resource_type === 'URL_EXTERNAL') return "Link";
      if (this.resource.resource_type === 'BLOG') return "Blog";
    },
    isDownloadable() {
      return (
        this.resource.resource_type != 'VID' &&
        this.resource.resource_type != 'URL' &&
        this.resource.resource_type != 'URL_INTERNAL' &&
        this.resource.resource_type != 'URL_EXTERNAL' &&
        this.resource.resource_type != 'BLOG'
      );
    },
    bookmarkIcon(resource) {
      return resource.is_bookmarked ? 'fa-solid' : 'fa-regular';
    },
    emitEvent(event_name) {
      this.$emit(event_name);
    },
    getActionList() {
      let actionList = [];
      let isPartnerManaged = this.idsPartnerAccountsManaged && this.resource.partner && this.idsPartnerAccountsManaged.includes(this.resource.partner.id)
      const canPartnerManageAccess = this.manageAccess || isPartnerManaged;

      actionList.push({
        label: 'View',
        icon: 'fa-solid fa-eye',
        action: 'view-item',
      });

      if (this.resource.resource_type === 'BLOG' && canPartnerManageAccess) {
        actionList.push({
          label: 'Edit blog',
          icon: 'fa-solid fa-pen',
          action: 'edit-blog',
        });
      }

      if (canPartnerManageAccess && this.resource.resource_type !== 'BLOG') {
        actionList.push({
          label: 'Edit',
          icon: 'fa-solid fa-pen',
          action: 'edit-item',
        });
      }

      if (this.resource.is_recommended && canPartnerManageAccess && this.resource.resource_type !== 'BLOG') {
        actionList.push({
          label: 'Unfeature',
          icon: 'fa-solid fa-stars',
          action: 'unfeature-item',
        });
      } else if (canPartnerManageAccess && this.resource.resource_type !== 'BLOG') {
        actionList.push({
          label: 'Feature',
          icon: 'fa-solid fa-stars',
          action: 'feature-item',
        });
      }

      if (this.isDownloadable()) {
        actionList.push({
          label: 'Download',
          icon: 'fa-solid fa-download',
          action: 'download-item',
        });
      }

      if (this.is_bookmarked) {
        actionList.push({
          label: 'Unsave',
          icon: 'fa-solid fa-bookmark',
          action: 'remove-item-bookmark',
        });
      } else {
        actionList.push({
          label: 'Save',
          icon: 'fa-solid fa-bookmark',
          action: 'bookmark-item',
        });
      }

      actionList.push({
        label: 'Copy link',
        icon: 'fa-solid fa-link',
        action: 'copy-item-link',
        additionalDescription: 'File accessible to members only',
      });

      if (this.resource.is_archived && canPartnerManageAccess && this.resource.resource_type !== 'BLOG') {
        actionList.push({
          label: 'Unarchive',
          icon: 'fa-solid fa-archive',
          action: 'unarchive-item',
        });
      } else if (canPartnerManageAccess && this.resource.resource_type !== 'BLOG') {
        actionList.push({
          label: 'Archive',
          icon: 'fa-solid fa-archive',
          action: 'archive-item',
        });
      }

      if (canPartnerManageAccess && this.resource.resource_type !== 'BLOG') {
        actionList.push({
          label: 'Delete',
          icon: 'fa-solid fa-trash',
          action: 'delete-item',
        });
      }

      return actionList;
    },
  },
};
</script>

<style lang="scss" scoped>
.resource-card {
  width: 100%;
  height: 16.875rem;
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  border-radius: 24px 24px 16px 16px;
  box-shadow: 0 0 10px 4px rgba(20, 20, 20, 0.04);
  border: none;
  background-color: #fff;

  &.featured {
    grid-column: span 2;
    flex-direction: row;
    gap: 1rem;
    border-radius: 8px 16px 16px 8px;
  }

  .resource-type-pill {
    top: 5px;
    left: 31px;
    position: absolute;
    z-index: 8;
    background-color: #f2f5f7;
    padding: 0.188rem 0.5rem;
    color: #516075;
    border-radius: 16px;
    font-size: 0.75rem;
  }

  .image-wrapper {
    background-color: #000;
    overflow: hidden;
    height: 6.938rem;
    width: 100%;
    flex-shrink: 0;
    border-radius: 24px 24px 0 0;
    position: relative;

    &.featured {
      height: 100%;
      width: 15rem;
      border-radius: 8px 0 0 8px;
    }
  }

  .resource-image {
    height: 6.938rem;
    width: 100%;
    object-fit: cover;
    object-position: center;
    flex-shrink: 0;
    transition: 0.3s;
    opacity: 1;

    &.featured {
      height: 100%;
      width: 15rem;
      border-radius: 8px 0 0 8px;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      opacity: 0.85;
    }
  }

  .resource-icon-container {
    background-color: #f1f1f9;
    color: #22a5a0;
    width: 100%;
    height: 6.938rem;
    font-size: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px 24px 0 0;
    height: 6.938rem;
    flex-shrink: 0;
    transition: font-size 0.3s, opacity 0.3s;
    opacity: 1;

    &.featured {
      height: 100%;
      width: 15rem;
      font-size: 64px;
      border-radius: 8px 0 0 8px;
    }

    &:hover {
      cursor: pointer;
      font-size: 59px;
      opacity: 0.85;

      &.featured {
        font-size: 67px;
      }
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0 1rem 1rem;
    justify-content: space-between;
    height: 100%;

    &.featured {
      gap: 1rem;
      padding: 1rem 1rem 1.25rem 0;
      width: 100%;
    }
  }

  .title-description-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &.featured {
      gap: 0.5rem;
    }
  }

  .title {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
    color: #333;
    padding: 0;

    &.featured {
      font-size: 1.25rem;
      line-height: 1.2;
    }

    .featured-icon {
      color: #05c1a2;
      font-size: 1rem;
    }

    &:hover {
      cursor: pointer;
      color: #494949;
    }
  }

  .description {
    font-size: 0.875rem;
    line-height: 1.2;
    color: #494949;

    &.featured {
      font-size: 1rem;
    }
  }

  .bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .partner-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .partner-logo {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 16px;
    border: 1px solid #d8dce5;
    object-fit: cover;
    object-position: center;

    .featured {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .partner-name {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1;
    color: #444444;
  }

  .actions-container {
    display: flex;
    gap: 0.5rem;
  }

  .action-button {
    width: 1.25rem;
    height: 1.25rem;
    color: #d8dce5;
    font-size: 1.25rem;
    border: none;
    background: transparent;

    &:hover {
      cursor: pointer;
      color: #2d98d2;
    }

    .active {
      color: #2d98d2;
    }
  }
}

.bookmark-button.partner {
  color: #26354a;
  font-size: 1.25rem;
  background-color: transparent;
  border: none;

  &:hover {
    color: #516075;
  }
}

@media screen and (max-width: 576px) {
  .resource-card {
    gap: 1rem;
    width: 19rem;
    height: 21.5rem;
    border-radius: 24px;

    &.featured {
      flex-direction: column;
      border-radius: 24px;
    }

    .image-wrapper {
      height: 8.5rem;
      width: 100%;
      border-radius: 24px 24px 0 0;

      &.featured {
        height: 8.5rem;
        width: 100%;
        border-radius: 24px 24px 0 0;
      }
    }

    .resource-image {
      height: 8.5rem;
      width: 100%;
      border-radius: 24px 24px 0 0;

      &.featured {
        height: 8.5rem;
        width: 100%;
        border-radius: 24px 24px 0 0;
      }
    }

    .resource-icon-container {
      height: 8.5rem;
      width: 100%;
      border-radius: 24px 24px 0 0;

      &.featured {
        height: 8.5rem;
        width: 100%;
        border-radius: 24px 24px 0 0;
      }
    }

    .text-container {
      padding: 0 1rem 1rem;

      &.featured {
        padding: 0 1rem 1rem;
      }
    }

    .description {
      -webkit-line-clamp: 5 !important;
    }
  }
}
</style>
