export function parseGETParamsIntoObject(): Object {
    const paramsString: string = window.location.search.substring(1);
    const returnObject = {};
    const paramsArray: Array<string> = paramsString.split("&");

    paramsArray.forEach(element => {
        const keyValuePair = element.split('=');
        const key = decodeURIComponent(keyValuePair[0]);
        const value = decodeURIComponent(keyValuePair[1]);

        if (typeof returnObject[key] === 'undefined') { // if first entry
            returnObject[key] = decodeURIComponent(value);

        } else if (typeof returnObject[key] === 'string') { // second entry
            const tempArray = [returnObject[key], decodeURIComponent(value)];
            returnObject[key] = tempArray;

        } else { // third or later entry with same key
            returnObject[key].push(decodeURIComponent(value));
        }
    });

    return returnObject;
}
