import HttpService from '../../services/HttpService';

export default class PartnerAccountService extends HttpService {
  constructor() {
    super('/partneraccounts/api');
  }

  updatePartner(payload: Object, headers: Object): Promise<any> {
    this.endpoint = `/partneraccounts/api/${payload['id']}`;
    return this.put(payload, headers);
  }

  removePartner(id, body, headers): Promise<any> {
    this.endpoint = `/partneraccounts/api/${id}`;
    return this.delete(body, headers);
  }

  getAllPartners(params): Promise<any> {
    this.endpoint = '/partneraccounts/api/';
    return this.get(params);
  }

  getRecommendedPartners(params): Promise<any> {
    this.endpoint = '/partneraccounts/api/recommendations';
    return this.get(params);
  }

  createPartner(payload: Object, headers: Object): Promise<any> {
    this.endpoint = `/partneraccounts/api/`;
    return this.postFormData(payload, headers);
  }

  createPartnerManager(payload: Object, headers: Object): Promise<any> {
    this.endpoint = `/partneraccounts/api/users`;
    return this.postFormData(payload, headers);
  }

  editPartnerManager(payload: Object, headers: Object): Promise<any> {
    this.endpoint = `/partneraccounts/api/users`;
    return this.postFormData({...payload, is_edit_user: true}, headers);
  }

  getPartnerUsers(params): Promise<any> {
    this.endpoint = '/partneraccounts/api/users';
    return this.get(params);
  }

  getPartnerManagers(params): Promise<any> {
    this.endpoint = '/partneraccounts/api/managers';
    return this.get(params)
  }

  removePartnersManager(manager_id, params, headers): Promise<any> {
    this.endpoint = `/partneraccounts/api/users/${manager_id}`;
    return this.delete(params, headers);
  }

  bulkRemovePartners(params, headers): Promise<any> {
    this.endpoint = `/partneraccounts/api/`;
    return this.delete(params, headers);
  }

  bulkRemoveManagers(params, headers): Promise<any> {
    this.endpoint = `/partneraccounts/api/users`;
    return this.delete(params, headers);
  }

  resendPasswordEmail(params, headers): Promise<any> {
    this.endpoint = '/partneraccounts/api/send_reset_password_mail';
    return this.post(params, headers);
  }

  inviteUsers(params, headers): Promise<any> {
    this.endpoint = '/partneraccounts/api/users/invite';
    return this.post(params, headers);
  }

  getAllManagers(params): any {
    this.endpoint = '/partneraccounts/api/managers';
    return this.get(params);
  }

  downloadPartners(params, headers) {
    this.endpoint = `/import/partner/export?fileType=${params.fileType}&partner_ids=${params.partner_ids}`;
    fetch(this.endpoint, {
      method: 'GET',
      headers: new Headers(headers),
    })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'partners_export';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }

  downloadManagers(params, headers) {
    this.endpoint = `/import/manager/export?fileType=${params.fileType}&partner_id=${params.partner_id}`;
    fetch(this.endpoint, {
      method: 'GET',
      headers: new Headers(headers),
    })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'managers_export';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }

  initializePartnerAccount(data, headers) {
    this.endpoint = `/partneraccounts/api/initialize`
    return this.post(data, headers, true);
  }

  requestDemo(data, headers) {
    this.endpoint = '/partner/api/request-demo'
    return this.post(data, headers, true);
  }

  getPredata(){
    this.endpoint = '/partneraccounts/api/predata';
    return this.get();
  }

  getDetailsPredata(partner_name) {
    this.endpoint = `/partneraccounts/api/${partner_name}/details/predata`;
    return this.get();
  }
}
