<template>
  <div class="feed-container">
    <section class="title-section">
      <h3 class="title">
        Feed Updates
      </h3>
    </section>
    <section class="content-section">
      <div class="loading-container" v-if="isLoading">
        <loading-spinner></loading-spinner>
      </div>
      <div v-else-if="isPostListEmpty" class="empty-container">
        No posts yet. Check back later.
      </div>
      <div v-else class="content-container">
        <div v-for="item, index in post_list" :key="index" class="post-card-wrapper">
          <feed-card
            :post="item"
            @preview-files="previewFiles"
          ></feed-card>
        </div>
      </div>
    </section>
    <resource-modal
      :activeResource="activeResource"
      :isResourceModalActive="isPreviewResourcesModalActive"
      :showNextPrevArrow="showNextPrevArrow"
      backTxt="close"
      @next-item="nextResourcePreview()"
      @previous-item="prevResourcePreview()"
      @close-resource-modal="closePreviewResourcesModal"
    />
  </div>
</template>

<script lang="ts">
import FeedCard from './FeedCard.vue';
import FeedService from '../../../feed/services/FeedService';
import LoadingSpinner from '../../../components/LoadingSpinner.vue';
import ResourceModal from '../../../library/components/modals/ResourceModal.vue';

export default {
  name: 'top-navbar-feed',
  components: {
    FeedCard,
    LoadingSpinner,
    ResourceModal,
  },
  data() {
    return {
      feedService: new FeedService(),
      post_list: [],
      isLoading: true,
      isPreviewResourcesModalActive: false,
      activeResource: { name: null, resource_url: null, description: null },
      previewResourcesIndex: 0,
      previewResourcesArray: [],
    }
  },
  computed: {
    isPostListEmpty() {
      return !this.post_list || this.post_list.length === 0;
    },
    showNextPrevArrow() {
      return this.previewResourcesArray && this.previewResourcesArray.length > 1;
    }
  },
  methods: {
    nextResourcePreview() {
      if (this.previewResourcesIndex === this.previewResourcesArray.length - 1) {
        this.previewResourcesIndex = 0;
      } else {
        this.previewResourcesIndex = this.previewResourcesIndex + 1;
      }

      this.activeResource = this.previewResourcesArray[this.previewResourcesIndex];
    },
    prevResourcePreview() {
      if (this.previewResourcesIndex === 0) {
        this.previewResourcesIndex = this.previewResourcesArray.length - 1;
      } else {
        this.previewResourcesIndex = this.previewResourcesIndex - 1;
      }

      this.activeResource = this.previewResourcesArray[this.previewResourcesIndex];
    },
    closePreviewResourcesModal() {
      this.isPreviewResourcesModalActive = false;
      const sidebar = document.querySelector<HTMLElement>("#sidebar");
      sidebar.style.display = "block";
      this.activeResource = { name: null, resource_url: null, description: null };
    },
    previewFiles(previewResourcesArray, previewResourcesIndex) {
      this.previewResourcesArray = previewResourcesArray;
      this.previewResourcesIndex = previewResourcesIndex;
      this.activeResource = this.previewResourcesArray[previewResourcesIndex];
      const sidebar = document.querySelector<HTMLElement>("#sidebar");
      sidebar.style.display = "none";
      this.isPreviewResourcesModalActive = true;
    },
    viewItem(el, index) {
      this.isPreviewResourcesModalActive = true;
      this.activeResource = el;
      this.activeResource.index = index;
    },
    async loadPosts(minPosts=10) {
      let newPosts = [];
      let page = 1;
      while (newPosts.length < minPosts) {
        const res = await this.feedService.getPosts({q: '', page: page})
        if (res.length === 0) {
          break;
        }
        newPosts = [...newPosts, ...res.filter((p) => p.verb.includes("post"))]
        if(newPosts.length < minPosts ) {
          page++;
        }
      }

      this.post_list = newPosts;
      this.isLoading = false;
    },
  },
  created() {
    this.loadPosts();
  },
}
</script>

<style scoped lang="scss">

</style>
