import HttpService from '../../services/HttpService';
import * as Cookies from 'js-cookie';

const headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class CampusAppService extends HttpService {
  constructor() {
    super('/campusapps/api');
  }

  getFilterData(params): Promise<any> {
    this.endpoint = `/campusapps/api/filterData`;
    return this.get(params);
  }

  getAll(params): Promise<any> {
    this.endpoint = `/campusapps/api`;
    return this.get(params);
  }

  getOne(id): Promise<any> {
    this.endpoint = `/campusapps/api/${id}`;
    return this.get({});
  }

  add(item): Promise<any> {
    this.endpoint = `/campusapps/api`;
    return this.post(item, headers);
  }

  remove(id, body): Promise<any> {
    this.endpoint = `/campusapps/api/${id}`;
    return this.delete(body, headers);
  }

  update(body): Promise<any> {
    this.endpoint = `/campusapps/api/${body.id}`;
    return this.put(body, headers);
  }
}
