<template>
  <div class="partner-tenant-featured-programs-card" v-if="featuredProgramsCount">
    <div class="partner-tenant-featured-programs-content-wrapper">
      <div class="image-wrapper">
        <img
          class="image"
          :src="featuredProgramCardActive.thumbnail"
          alt="Featured Image"
        >
      </div>
      <div class="content">
        <div>
          <div class="featured-label-container">
            <div class="featured-label">
              Featured Program
            </div>
            <div class="dots-container">
              <slider-dots
                :totalNo="featuredProgramsCount"
                :initialSelectedNo="0"
                :switchSeconds="7"
                @changeSelected="changeSelectedProgramSliderHandler"
              />
            </div>
          </div>
          <div class="title">{{featuredProgramCardActive.title}}</div>
          <div class="text">{{featuredProgramCardActive.description}}</div>
        </div>
        <div>
          <div class="location">
            <i v-if="featuredProgramCardActive.is_online" class="fa-solid fa-desktop"></i>
            <i v-else class="fa-solid fa-location-dot"></i>
            {{featuredProgramCardActive.location}}
          </div>
          <div class="action-buttons-container">
            <router-link
              class="dabi-main-button-outlined"
              :to="`/programs/${featuredProgramCardActive.slug}`"
              :aria-label="`Visit ${featuredProgramCardActive.title} page`"
            >
              View Details
              <i class="fa-regular fa-chevron-right"></i>
            </router-link>
            <button
              class="dabi-bookmark-button"
              :class="featuredProgramCardActive.is_bookmarked ? 'active' : ''"
              @click="toggleBookmark(featuredProgramCardActive.id)"
              :aria-label="`${featuredProgramCardActive.is_bookmarked ? 'Remove' : 'Add'} bookmark`"
            >
              <i v-if="featuredProgramCardActive.is_bookmarked" class="fa-solid fa-bookmark"></i>
              <i v-else class="fa-regular fa-bookmark"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PartnerDashboardService from '../../services/PartnerDashboardService';
import BookmarkService from '../../../bookmarks/services/BookmarkService';
import * as Cookies from 'js-cookie';
import SliderDots from '../../../partners/components/landing_pages/components/SliderDots.vue';

export default {
  name: 'partner-tenant-featured-programs',
  components: {
    SliderDots,
  },
  props: [

  ],
  created() {
    this.partnerDashboardService.getFeaturedPrograms().then(data => {
      this.featuredProgramsList = data;
      this.emitIfEmpty();
    });
  },
  computed: {
    featuredProgramsCount() {
      return this.featuredProgramsList ? this.featuredProgramsList.length : 0;
    },
    featuredProgramsIdsArray() {
      return this.featuredProgramsList ? [...Array(this.featuredProgramsCount).keys()] : [];
    },
    featuredProgramCardActive() {
      return this.featuredProgramsList ? this.featuredProgramsList[this.featuredProgramSelectedId] : {};
    },
  },
  data() {
    return {
      featuredProgramsList: [
        {
          id: 0,
          title: "",
          location: "",
          is_online: false,
          slug: "/",
          image: "",
          description: "",
          is_bookmarked: false,
        },
      ],
      featuredProgramSelectedId: 0,
      switchSeconds: 10,
      intervalFunctionId: null,
      partnerDashboardService: new PartnerDashboardService(),
      eventBookmarkService: new BookmarkService(),
      headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
          Accept: "application/json",
      },
    }
  },
  methods: {
    emitIfEmpty() {
      if (!this.featuredProgramsList || this.featuredProgramsList.length === 0) {
        this.$emit('is-empty');
      }
    },
    changeSelectedProgramSliderHandler(sliderDotIndex) {
      this.changeSelectedProgram(sliderDotIndex-1);
    },
    changeSelectedProgram(index) {
      this.featuredProgramSelectedId = index;
    },
    selectNextProgramId() {
      if (this.featuredProgramSelectedId + 1 == this.featuredProgramsCount) {
        this.featuredProgramSelectedId = 0;
      } else {
        this.featuredProgramSelectedId = this.featuredProgramSelectedId + 1;
      }
    },
    isFeaturedProgramSelected(index) {
      return this.featuredProgramSelectedId == index;
    },
    toggleBookmark() {
      if (this.featuredProgramCardActive.is_bookmarked) {
        const payload = {
          'targets': [this.featuredProgramCardActive.id],
        }
        this.eventBookmarkService.deleteBookmarkedEvents(payload, this.headers);
        this.featuredProgramsList[this.featuredProgramSelectedId].is_bookmarked = false;
      } else {
        const payload = {
          'event_id': this.featuredProgramCardActive.id,
        }
        this.eventBookmarkService.postBookmarkEvent(payload, this.headers);
        this.featuredProgramsList[this.featuredProgramSelectedId].is_bookmarked = true;
      }
    },
  },
}
</script>
