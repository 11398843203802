<template>
  <section v-if="isLoading">
    <loading-spinner wrapperClass="wrapper" />
  </section>
  <section v-else>
    <section class="opportunity-page-header-container">
      <section class="image-container">
        <img :src="event.thumbnail" class="image" alt="Partner cover image" />
        <div class="tint"></div>
        <div class="back-button-container">
          <button @click="redirectTo('/opportunities')" class="back-button">
            <i class="fa-solid fa-chevron-left back-arrow"></i>
            <span class="text">Browse all opportunities</span>
          </button>
        </div>
        <div class="text-container">
          <span class="title">{{ event.title }}</span>
        </div>
      </section>
      <div class="action-container">
        <div class="event-header-btns">
          <div class="left">
            <div>
              <button
                ref="calendarButton"
                class="iu-main-btn small solid main"
                @click="openBurger('calendar-burger')"
                aria-label="Open menu for external calendars"
                role="menu"
                aria-haspopup="true"
              >
                Add to Calendar
                <i class="fal fa-angle-down calendar-carot"></i>
              </button>
              <div
                id="calendar-burger"
                class="eu-resource-burger"
                style="width: 250px; margin-top: 10px; right: revert"
                ref="calendarBurger"
                v-closable="{ exclude: ['calendarButton', 'calendarBurger'], handler: 'closeCalendarBurger' }"
              >
                <span
                  tabindex="0"
                  @click="openExternalCalendar('google')"
                  @keyup.enter="openExternalCalendar('google')"
                  class="eu-resource-burger-el"
                  style="margin: 10px 0 10px 0px"
                  aria-label="Create event in Google calendar"
                  role="link"
                >
                  <img
                    :src="`${assetPath}assets/icons/768px-Google_Calendar_icon_%282020%29.png`"
                    style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                  />
                  <span>Google Calendar</span>
                </span>
                <span
                  tabindex="0"
                  @click="openExternalCalendar('ics')"
                  @keyup.enter="openExternalCalendar('ics')"
                  class="eu-resource-burger-el"
                  style="margin: 10px 0 10px 0px"
                  aria-label="Create event in Apple calendar"
                  role="button"
                >
                  <img
                    :src="`${assetPath}assets/icons/apple-512.webp`"
                    style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                  />
                  <span>Apple Calendar</span>
                </span>
                <span
                  tabindex="0"
                  @click="openExternalCalendar('outlook')"
                  @keyup.enter="openExternalCalendar('outlook')"
                  class="eu-resource-burger-el"
                  style="margin: 10px 0 10px 0px"
                  aria-label="Create event in Outlook calendar"
                  role="link"
                >
                  <img
                    :src="`${assetPath}assets/icons/Outlook.com_icon.png`"
                    style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                  />
                  <span>Outlook Calendar</span>
                </span>
                <span
                  tabindex="0"
                  @click="openExternalCalendar('yahoo')"
                  @keyup.enter="openExternalCalendar('yahoo')"
                  class="eu-resource-burger-el"
                  style="margin: 10px 0 10px 0px"
                  aria-label="Create event in Yahoo calendar"
                  role="link"
                >
                  <img
                    :src="`${assetPath}assets/icons/yahoo.png`"
                    style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                  />
                  <span>Yahoo Calendar</span>
                </span>
              </div>
            </div>
            <button
              v-if="event.is_bookmarked"
              class="iu-main-btn small outlined main"
              @click="handleRemoveBookmark(event)"
              role="button"
              aria-label="Remove this opportunity from your bookmarks"
              tabindex="0"
            >
              <i class="fa-sharp fa-solid fa-bookmark" style="margin-right: 3px; margin-top: 3px; color: #00a1b3"></i>
              <span>Saved</span>
            </button>
            <button
              v-if="!event.is_bookmarked"
              class="iu-main-btn small outlined gray"
              @click="handleAddBookmark(event)"
              role="button"
              aria-label="Add this opportunity to your bookmarks"
            >
              <i class="fa-sharp fa-regular fa-bookmark" style="margin-right: 3px; margin-top: 3px"></i>
              <span>Save</span>
            </button>
            <!-- <button
              v-if="isAttending"
              class="iu-main-btn small outlined main"
              @click="changeAttendance(false)"
              role="button"
              aria-label="Update your RSVP status for this opportunity"
              tabindex="0"
            >
              <i class="fa-sharp fa-solid fa-bookmark" style="margin-right: 3px; margin-top: 3px; color: #00a1b3"></i>
              <span>{{ event.type_of_program_management === "program" ? "I'm Interested" : "Attending" }}</span>
            </button>
            <button
              v-if="!isAttending"
              class="iu-main-btn small outlined gray"
              @click="changeAttendance(true)"
              role="button"
              aria-label="Update your RSVP status for this opportunity (interested in/going)"
            >
              <i class="fa-sharp fa-regular fa-bookmark" style="margin-right: 3px; margin-top: 3px"></i>
              <span>{{ event.type_of_program_management === "program" ? "Not Interested" : "Not Attending" }}</span>
            </button> -->
          </div>
          <div class="right">
            <button
              v-if="event.can_manage"
              class="iu-main-btn small solid main"
              @click="redirectToEdit()"
              role="button"
              aria-label="Update your RSVP status for this opportunity (interested in/going)"
            >
              <span>Edit {{ capitalizeFirstLetterOnly(event.type_of_program_management) }}</span>
            </button>
          </div>
        </div>
      </div>
    </section>
    <div class="event-content-body-wrapper">
      <div class="event-content-left-bar">
        <div class="event-content-desc">
          <h2 class="event-about-this">
            About this {{ event.type_of_program_management === "program" ? "Program" : "Event" }}
          </h2>
          <p class="event-content-header-desc" style="margin-bottom: 20px" v-html="formatTextToHtml(event.description)"></p>
          <a v-if="event.apply_url" class="event-content-header-desc" :href="event.apply_url" target="_blank">
            Apply URL<i style="margin-left: 6px; font-size: 14px" class="fa-solid fa-arrow-up-right-from-square"></i
          ></a>
          <br />
          <a v-if="event.more_info_url" class="event-content-header-desc" :href="event.more_info_url" target="_blank">
            More Information<i
              style="margin-left: 6px; font-size: 14px"
              class="fa-solid fa-arrow-up-right-from-square"
            ></i
          ></a>
          <p v-if="!event.is_past_date" class="event-content-link-wrapper" style="margin-bottom: 16px">
            <a
              v-if="event.location == 'Online'"
              :href="event.online_url"
              target="_blank"
              style="border-radius: 8px"
              class="iu-main-btn small solid main"
              :class="!event.online_url ? 'disabled' : ''"
              aria-label="Visit the website for this opportunity"
            >
              Learn More
              <i aria-hidden="true" class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
            </a>
          </p>
          <section v-if="event.external_link_title && event.external_link">
            <p class="event-about-this">Additional Info</p>
            <a
              :href="event.external_link"
              :aria-label="'Visit the link for this opportunity. This is an external link. Be advised you will be navigating away from our website: '"
              >{{ event.external_link_title }}</a
            >
          </section>
        </div>

        <div v-if="event.type_of_program_management === 'program' && event.files.length !== 0">
          <div v-for="item in event.files" v-bind:key="item.id">
            <div v-if="item.resource_type === 'VID'" class="event-content-desc">
              <h2 class="video-about-this">{{ item.name }}</h2>
              <div class="video-wrapper">
                <iframe
                  :src="getYoutubeOrVimeoEmbedLink(item.resource_url)"
                  class="dept-video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>

          <div v-if="event.files.some((e) => e.resource_type != 'VID')" class="event-content-desc">
            <h2 class="event-about-this">Files</h2>
            <div v-for="item in event.files" v-bind:key="item.id">
              <div v-if="item.resource_type !== 'VID'">
                <div class="document-wrapper">
                  <div class="icon-section">
                    <a
                      v-if="item.resource_type !== 'DOC'"
                      :href="item.resource_url"
                      target="_blank"
                      aria-label="Show resource"
                    >
                      <!-- <i v-if="item.resource_type === 'VID'"  class="far fa-file-video" style="color:#9f2a8c" aria-hidden="true"></i> -->
                      <i
                        v-if="item.resource_type === 'URL'"
                        class="far fa-external-link"
                        style="color: #438ca4; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="item.resource_type === 'IMG'"
                        class="far fa-file-image"
                        style="color: #f4c64d; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="item.resource_type === 'URL_INTERNAL'"
                        class="far fa-external-link"
                        style="color: #438ca4; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="item.resource_type === 'URL_EXTERNAL'"
                        class="far fa-external-link"
                        style="color: #438ca4; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <i
                      v-if="item.resource_type === 'DOC'"
                      class="far fa-file-alt"
                      style="color: #8673fd"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="text-section">
                    <a :href="item.resource_url" target="_blank" class="text-section-title"> {{ item.name }} </a>
                    <!-- <p class="text-section-description"> Posted by Admissions </p> -->
                  </div>
                  <div
                    class="option-section"
                    :aria-label="`Download ${item.name}`"
                    role="button"
                    @click="downloadItem(item.resource_url, item.name)"
                    @keyup.enter="downloadItem(item.resource_url, item.name)"
                    v-if="
                      item.resource_type != 'VID' &&
                      item.resource_type != 'URL' &&
                      item.resource_type != 'URL_INTERNAL' &&
                      item.resource_type != 'URL_EXTERNAL'
                    "
                  >
                    <i class="far fa-download"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="event-content-right-bar">
        <div v-if="!event.is_past_event" class="event-content-right-bar-wrapper">
          <div v-if="event.start_date" class="content-wrapper">
            <h3 class="content-subtitle">
              {{ event.type_of_program_management === "program" ? "Program" : "Event" }} Date{{
                event.end_date != "" && event.end_date != null ? "s" : ""
              }}
            </h3>
            <p>
              <i class="fas fa-calendar-star event-content-header-icon"></i>
              <span class="content-text">
                {{ event.start_date | parseDate }}
                <span v-if="event.end_date != '' && event.end_date != null"> - {{ event.end_date | parseDate }}</span>
              </span>
            </p>
          </div>
          <div
            v-if="event.type_of_program_management === 'program'"
            class="content-wrapper"
            style="border-bottom: none; margin-bottom: 0"
          >
            <h3 class="content-subtitle">Application Deadline</h3>
            <p>
              <i class="fas fa-hourglass-half event-content-header-icon"></i>
              <span class="content-text" style="color: #e65722">
                {{ event.deadline | parseDateUTC }}
              </span>
            </p>
          </div>
          <!-- RSVP -->
          <div v-if="event.type_of_program_management === 'event'">
            <button
              id="rsvp-dropdown-button"
              ref="rsvpButton"
              class="event-content-right-bar-button"
              @click="openBurger('rsvp-burger')"
              aria-label="Open RSVP menu"
              role="menu"
              style="border: 2px solid #b4b4b4"
              aria-haspopup="true"
            >
              <span v-if="isAttending === true" style="color: #5e50b5">
                <i class="fas fa-check-circle rsvp-icon-green"></i>
                Attending
              </span>
              <span v-else-if="isAttending === false" style="color: #5e50b5">
                <i class="fas fa-times-circle rsvp-icon-red"></i>
                Not Attending
              </span>
              <span v-else>RSVP</span>
              <i class="fal fa-angle-down eu-select-arrow" style="left: 0"></i>
            </button>
            <div
              id="rsvp-burger"
              class="eu-resource-burger"
              style="width: 250px; margin-top: 10px"
              ref="rsvpBurger"
              v-closable="{ exclude: ['rsvpButton', 'rsvpBurger'], handler: 'closeRSVPBurger' }"
            >
              <span
                id="set-attending-true"
                tabindex="0"
                class="eu-resource-burger-el"
                @click="changeAttendance(true)"
                @keyup.enter="changeAttendance(true)"
                aria-label="Change status to attending this opportunity"
                role="button"
              >
                <i class="fas fa-check-circle rsvp-green-hover"></i>
                Attending
              </span>
              <span
                id="set-attending-false"
                tabindex="0"
                class="eu-resource-burger-el"
                @click="changeAttendance(false)"
                @keyup.enter="changeAttendance(false)"
                aria-label="Change status to - not attending this opportunity"
                role="button"
              >
                <i class="fas fa-times-circle rsvp-red-hover"></i>
                Not Attending
              </span>
            </div>
          </div>
        </div>
        <div v-if="event.type_of_program_management === 'program'" class="event-content-right-bar-wrapper">
          <h2 class="event-content-address-title">Program Details</h2>
          <p class="details-wrapper">
            <i class="fas fa-school event-content-header-icon"></i>
            <span> {{ event.type }} Program </span>
          </p>
          <p v-if="event.location != 'Online'" class="details-wrapper">
            <i class="fas fa-map-marker-alt event-content-header-icon"></i>
            <span> {{ event.state }} {{ event.address_1 }}</span>
          </p>
          <!-- <p class="details-wrapper">
            <i class="fas fa-coins event-content-header-icon"></i>
            <span> ~2,5000 USD</span>
          </p> -->
          <p class="details-wrapper">
            <i class="fas fa-building event-content-header-icon"></i>
            <span
              >Posted by
              {{
                event.partner ? event.partner : event.department ? event.department : event.group ? event.group : ""
              }}</span
            >
          </p>
          <p v-if="event.location == 'Online'">
            <i class="far fa-desktop event-content-header-icon"></i>
            <span>Virtual</span>
          </p>
        </div>
        <div v-if="event.type_of_program_management === 'event'" class="event-content-right-bar-wrapper">
          <h2 class="event-content-address-title">Event Details</h2>
          <p class="details-wrapper">
            <i class="fas fa-clock event-content-header-icon"></i>
            <span> {{ event.start_time }} - {{ event.end_time }} </span>
          </p>
          <p class="details-wrapper">
            <i class="far fa-globe event-content-header-icon"></i>
            <span>{{ event.time_zone }}</span>
          </p>
          <p class="details-wrapper">
            <i class="fas fa-building event-content-header-icon"></i>
            <span
              >Posted by
              {{
                event.partner ? event.partner : event.department ? event.department : event.group ? event.group : ""
              }}</span
            >
          </p>
          <p v-if="event.location == 'Online'">
            <i class="far fa-desktop event-content-header-icon"></i>
            <span>Virtual</span>
          </p>
        </div>
        <div
          v-if="event.type_of_program_management === 'program' && event.contacts.length > 0"
          class="event-content-right-bar-wrapper"
        >
          <p class="eu-feed-dept-address-title" style="padding: 0">Contacts</p>
          <div v-for="item in event.contacts" v-bind:key="item.uuid" class="department-contacts-containers">
            <img alt="Profile picture of user" :src="item.profile_picture" style="width: 48px; height: 48px" />
            <div>
              <a
                aria-label="Go to full name profile page"
                class="eu-feed-dept-social-href"
                :href="'/profiles/' + item.url"
                >{{ item.name }}</a
              >
              <p class="helpful-link-description">{{ item.role }}</p>
            </div>
          </div>
        </div>

        <div class="event-content-right-bar-wrapper">
          <div v-if="event.focus_areas.length !== 0" class="content-wrapper">
            <h2 class="event-content-address-title">Focus Area</h2>
            <div class="flex-wrapper-content">
              <p v-for="item in event.focus_areas" v-bind:key="item.id" class="content-item">
                {{ item.name }}
              </p>
            </div>
          </div>
          <div v-if="event.identities.length !== 0" class="content-wrapper">
            <h2 class="event-content-address-title">Identity Group</h2>
            <div class="flex-wrapper-content">
              <p v-for="item in event.identities" v-bind:key="item.id" class="content-item">
                {{ item.name }}
              </p>
            </div>
          </div>
          <div
            v-if="event.audiences && event.audiences.length !== 0"
            class="content-wrapper"
            style="border-bottom: 0; margin-bottom: 0"
          >
            <h2 class="event-content-address-title">Audience</h2>
            <div class="flex-wrapper-content">
              <p v-for="item in event.audiences" v-bind:key="item.id" class="content-item">
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="event.location != 'Online'" class="event-content-right-bar-wrapper">
          <h2 class="event-content-address-title">Location</h2>
          <p>{{ event.address_1 }}</p>
          <p>{{ event.address_2 }}</p>
          <p>{{ event.location }} {{ event.postal_code }}</p>
          <p>United States</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import * as Cookies from "js-cookie";
import makeUrls, { TCalendarEvent } from "../../meetings/utils/AddEventToCalendar";
import { focusElement } from "../../utils/CustomUtils";
import "../../meetings/utils/DateParser";
import { formatEventEnd, formatEventStart } from "../utils/DateFormat";
import EventsIndexService from "../services/EventsIndexService";
import RSVPService from "../services/RSVPService";
import handleOutsideClick from "../../directives/handleOutsideClick";
import { getYoutubeOrVimeoEmbedLink, isPartnerTenant, capitalizeFirstLetterOnly, formatTextToHtml} from "../../utils/CustomUtils";
import LoadingSpinner from "../../components/LoadingSpinner.vue";

export default {
  directives: { closable: handleOutsideClick },
  components: { LoadingSpinner },
  name: "event-details",

  props: [],

  data() {
    return {
      assetPath: process.env.MEDIA_URL,
      event: null,
      isLoading: true,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },

    computed: {
    isAttending() {
      return this.event.rsvp_status;
    },
  },

  async created() {
    this.service = new EventsIndexService();
    await this.getPreData();
    this.rsvpService = new RSVPService();
  },

  methods: {
    capitalizeFirstLetterOnly,
    getYoutubeOrVimeoEmbedLink,
    formatTextToHtml,
    async getPreData() {
      this.isLoading = true;
      const eventType = this.$route.meta.type;
      const opportunityId = this.$route.path.split("/").pop();
      let res = null;
      if (eventType === "event") {
        res = await this.service.getPredataEvent(opportunityId);
      } else if (eventType === "program") {
        res = await this.service.getPredataProgram(opportunityId);
      }
      if (res) {
        this.event = res;
        this.service.increaseViews(this.event.id);
      }
      this.isLoading = false;
    },
    openExternalCalendar(calendar: string) {
      const event = this.event;
      const eventTitle = `Event: ${event.title}`;
      let eventDetails = event.description || "";
      const startTime = formatEventStart(event);
      const endTime = formatEventEnd(event);
      let event_location = "";

      if (event.location == "Online Event") {
        eventDetails += "\\n\\nConference link: " + event.online_event_url;
      } else {
        if (event.full_address) {
          event_location = event.full_address;
        }
      }

      const calEvent: TCalendarEvent = {
        name: eventTitle,
        details: eventDetails,
        location: event_location,
        startsAt: startTime,
        endsAt: endTime,
      };

      const eventUrls = makeUrls(calEvent);

      if (calendar === "ics") {
        this.handleUrlDownload(eventUrls.ics, isPartnerTenant() ? 'beglobalii_meeting':'includifi_meeting');
      } else {
        window.open(eventUrls[calendar]);
      }
    },

    handleUrlDownload(url, filename) {
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", filename);
      a.click();
    },

    async handleAddBookmark(event): Promise<void> {
      const payload = {
        target: event.id,
        action: "add",
      };

      await this.service.postBookmark(payload, this.headers);

      event.is_bookmarked = true;
    },

    async handleRemoveBookmark(event): Promise<void> {
      const payload = {
        target: event.id,
        action: "remove",
      };

      await this.service.postBookmark(payload, this.headers);

      event.is_bookmarked = false;
    },

    async changeAttendance(isAttending: boolean): Promise<void> {
      this.closeRSVPBurger();
      focusElement("rsvp-dropdown-button");

      if (this.isAttending === isAttending) {
        return;
      }

      const payload = {
        target: this.event.id,
        isAttending: isAttending,
      };

      const res = await this.rsvpService.postAttendance(payload, this.headers);

      if (res["success"]) {
        this.isAttending = isAttending;
      }
    },

    openBurger(burgerId: string) {
      const burgerEl = document.getElementById(burgerId);
      burgerEl.classList.toggle("eu-resource-burger-active");
      if (burgerId === "rsvp-burger") {
        focusElement("set-attending-true", 100);
      }
    },
    redirectTo(url) {
      this.$router.push(url);
    },
    redirectToEdit() {
      if (this.event.type_of_program_management === "event") {
        window.location.href = `/events/edit/${this.event.id}`;
      } else {
        window.location.href = `/programs/edit/${this.event.id}`;
      }
    },

    closeCalendarBurger() {
      const burgerEl = document.getElementById("calendar-burger");
      burgerEl.classList.remove("eu-resource-burger-active");
    },

    closeRSVPBurger() {
      const burgerEl = document.getElementById("rsvp-burger");
      burgerEl.classList.remove("eu-resource-burger-active");
    },

    async downloadItem(dataurl: string, filename: string) {
      const res = await fetch(dataurl);
      const blob = await res.blob();
      this.startDownload(blob, filename);
    },

    startDownload(content, filename) {
      var a = document.createElement("a");
      a.download = filename ? filename : "resources";
      document.body.appendChild(a);
      a.href = URL.createObjectURL(content);
      a.style.display = "none";
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>
<style scoped lang="scss">
.event-header-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .left {
    display: flex;
    gap: 0.625rem;
  }
}
.calendar-carot {
  position: relative;
  font-size: 21px;
  left: -22px;
  top: 3px;
  left: 0;
}
.image-container {
  width: 100%;
  min-height: 300px;
  max-width: 1400px;
  overflow: hidden;
  border-radius: 0 0 24px 24px;
  position: relative;
  padding: 2.5rem 2rem;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .back-button-container {
    width: 100%;
    position: relative;

    .back-button {
      padding: 0.75rem 1.5rem;
      color: #101828;
      background-color: #d8dce5;
      border: none;
      border-radius: 100px;
      font-size: 1rem;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        background-color: #838ea1;
      }

      .back-arrow {
        color: #2d98d2;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
}
.text-container {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 170px;
  justify-content: flex-end;

  .title {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #fff;
  }
}
.opportunity-page-header-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid #c3cbd6;
  padding: 0 2rem;
}
.action-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem 0;
}
@media screen and (max-width: 740px) {
  .opportunity-page-header-container {
    padding: 0;

    .image-container {
      padding: 1.5rem 1rem;
      .back-button-container {
        .back-button {
          height: 3rem;
          width: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .text {
            display: none;
          }

          .back-arrow {
            font-size: 1rem;
            color: #101828;
          }
        }
      }

      .text-container {
        .title {
          font-size: 2rem;
        }
      }
    }
  }

  .partner-page-nav-section {
    padding: 0.75rem 1.25rem 0;
  }

  .action-container {
    padding-top: 0.75rem;
    flex-direction: column-reverse;
  }
  .event-header-btns {
    width: 100%;
    padding: 0 1rem;
    flex-direction: column;
    gap: 12px;
    .left {
      flex-direction: column;
    }
    .iu-main-btn {
      width: 100%;
    }
  }
}
</style>
