<template>
    <div id="app">
      <div class='wrapper'>
       <ejs-daterangepicker :placeholder="waterMark" v-model="date" @change="onChange">
            <e-presets>
                <e-preset label="Today" :start='today' :end='today'></e-preset>
                <e-preset label="Yesterday" :start='yesterday' :end='yesterday'></e-preset>
                <e-preset label="This week" :start='weekStart' :end='weekEnd'></e-preset>
                <e-preset label="Last week" :start='lastWeekStart' :end='lastweekEnd'></e-preset>
                <e-preset label="This Month" :start='monthStart' :end='monthEnd'></e-preset>
                <e-preset label="Last Month" :start='lastStart' :end='lastEnd'></e-preset>
                <e-preset label="This year" :start='yearStart' :end='today'></e-preset>
                <e-preset label="Last year" :start='lastYearStart' :end='lastYearEnd'></e-preset>
            </e-presets>
        </ejs-daterangepicker>
      </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { DateRangePickerPlugin } from '@syncfusion/ej2-vue-calendars';


Vue.use(DateRangePickerPlugin);
export default {
    name: "analytics-calendar",
    props:['keys'],
    data () {
      return {
        date: null,
        waterMark : 'Select a Range',
        today: new Date(),
        yesterday: new Date(new Date().setDate(new Date().getDate() - 1)),
        weekStart: new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString()),
        weekEnd : new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate() - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()),
        lastWeekStart: new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 14)).toDateString()),
        lastweekEnd: new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate() - (new Date().getDay() + 7) % 14))).getDate() + 6)).toDateString()),
        monthStart: new Date(new Date(new Date().setDate(1)).toDateString()),
        monthEnd: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString()), 
        lastStart: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
        lastEnd: new Date(new Date(new Date().setDate(0)).toDateString()),
        yearStart: new Date(new Date(new Date().getFullYear() - 0, 0, 1).toDateString()),
        lastYearStart: new Date(new Date(new Date().getFullYear() - 1, 0, 1).toDateString()),
        lastYearEnd:  new Date(new Date(new Date().getFullYear() - 1, 11, 31).toDateString())
       
      }
    },
    methods:{
      onChange(){
          this.$emit("onChange", this.keys, this.date);
      }
    },
}
</script>
<style>
 .wrapper {
    max-width: 200px;
    padding-bottom: 10px;
  }
</style>