import { defineComponent, ref, reactive } from '@vue/composition-api';
import { MediaCMSService, BlogService } from '../../services/CMSService';
import { Blog } from '../types';

export default defineComponent({
  setup(props, context) {
    const blog = ref<Blog|null>(null);
    const mediaCMSService = new MediaCMSService();
    const blogService = new BlogService();
    const isLoading = ref(false);

    const fetchBlog = async (slug: string) => {
      isLoading.value = true;
      blog.value = (await blogService.getBlogDetails(slug))[0];
      isLoading.value = false;
    }

    const getImage = (imageUrl: string) => {
      return mediaCMSService.getImage(imageUrl);
    }

    return { blog, isLoading, fetchBlog, getImage };
  },
});
