import HttpService from '../../services/HttpService';


export default class RoleService extends HttpService {
  constructor() {
    super('/roles/api');
  }

  getRoles(params: Object): Promise<any> {
    this.endpoint = `/roles/api/v1/roles`;
    return this.get(params);
  }
  getRoleData(params: Object): Promise<any> {
    this.endpoint = `/roles/api/data`;
    return this.get(params);
  }
}
