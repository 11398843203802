<template>
  <div>
    <p class="error" v-if="error">{{ error }}</p>
    <div v-if="src" class="thumbnail-container">

      <img
        :src="src"
        alt="this is where your selected image should be"
        class="thumbnail-image"
      >

      <div class="thumbnail-button-container">
        <label
          :for="originalInputName"
          class="thumbnail-button tooltip-container"
        >
          <i class="far fa-image"></i>
          <input
            :id="originalInputName"
            type="file"
            accept="image/*"
            @change="handleImageUpload"
            @keyup.enter="handleImageUpload"
            tabindex="0"
            aria-label="Change the selected image"
          />
          <span class="tooltip right">Change Image</span>
        </label>
        <div
          tabindex="0"
          aria-label="Remove the selected image"
          role="button"
          class="thumbnail-button tooltip-container"
          @click="removeImage"
          @keyup.enter="removeImage"
        >
          <i class="far fa-trash-alt"></i>
          <span class="tooltip right">Delete Image</span>
        </div>
      </div>
    </div>

    <div v-else class="row" style="margin-bottom: 0;">
      <div class="column">
        <div
          tabindex="0"
          v-cloak
          @click="handleInput"
          @keyup.enter="handleInput"
          @drop.prevent="handleImageUpload"
          aria-label="Upload affinity group logo"
          @dragover.prevent
          class="innerRow"
        >
          <label
            @dragenter="dragging = true"
            @dragleave="dragging = false"
            class="image-upload-label"
            :style="{ background: dragging ? '#eaeeff' : '' }"
            for="imageInput"
          >
            <span class="dropzone-help-text">
              <i class="fa-duotone fa-cloud-arrow-up" style="font-size: 60px; color: #9f87e0; opacity: 0.3;"></i> <br />
              <span>Drag &amp; drop image</span> <br />
              <span>or</span> <br />
              <button type="button" class="iu-main-btn small text-only main">
                Browse from device<i class="fa-solid fa-angle-right iu-main-button-arrow-icon"></i>
              </button>
            </span>
          </label>
        </div>
      </div>

      <label class="filetype-help-text">
        Accepted file types: <span>.jpeg or .png, no larger than {{ maxImageSizeFormat }}.</span>
      </label>
    </div>
  </div>
</template>
<script lang="ts">
import { validateExtensionFile } from "../../utils/TypecheckUtils";

export default {
  name: "dropzone-component",
  props: ['imageSrc', 'imageInput', 'maxImageSize', 'maxImageSizeFormat', 'originalInputName'],

  data() {
    return {
      dragging: false,
      error: "",
      src: '',
    };
  },

  created() {
    this.imageInput.addEventListener('change', e => {
      this.handleImageUpload(e);
    });

    this.src = this.imageSrc;
  },

  methods: {
    handleInput() {
      this.imageInput.click();
    },

    handleImageUpload(event) {
      const images = event.dataTransfer ? event.dataTransfer.files: event.target.files;
      const imageExt = ["jpg", "jpeg", "png"];

      let fileObject = {
        file: images[0],
        name: images[0].name,
        fileUrl: URL.createObjectURL(images[0]),
      }

      if (!validateExtensionFile(images[0].name, imageExt)) {
        this.error = "Please use a supported image filetype."

        return;
      }

      if (images[0].size > this.maxImageSize) {
        this.error = `Please use an image file under ${this.maxImageSizeFormat}.`

        return;
      }

      this.error = "";
      this.src = fileObject.fileUrl;

      this.imageInput.files = images;

      this.$emit('uploadImage', fileObject);
    },

    removeImage() {
      this.src = '';

      this.$emit('removeImage');
    }
  }
}

</script>
