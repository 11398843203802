<template>
  <settings-wrapper activeTab="password">
    <section class="profile-form">
      <div class="profile-form-header-container">
        <h2>Reset Password</h2>
      </div>
      <div v-if="isLoading" id="objects-list-loading-spinner">
        <loading-spinner />
      </div>
      <div v-else class="profile-form-section">
        <h3 v-if="account_channel_message">{{ account_channel_message }}</h3>

        <div class="inup-form-element-container two-thirds">
          <input
            type="password"
            v-model="password.current"
            id="currentPass"
            class="inup-input"
            :class="v$.password.current.$error ? 'input-error' : ''"
          />
          <div v-if="v$.password.current.$error" class="error">{{v$.password.current.$errors[0].$message}}</div>
          <div class="inup-form-label-container">
            <label for="currentPass">Current Password <span>*</span></label>
          </div>
        </div>

        <div class="inup-form-element-container two-thirds">
          <input type="password" v-model="password.new" id="newPass" class="inup-input" :class="v$.password.new.$error ? 'input-error' : ''" />
          <div v-if="v$.password.new.$error" class="error">{{v$.password.new.$errors[0].$message}}</div>
          <div class="inup-form-label-container">
            <label for="newPass">New Password <span>*</span></label>
          </div>
        </div>

        <div class="inup-form-element-container two-thirds">
          <input type="password" v-model="password.confirm" id="confirmPass" class="inup-input" :class="v$.password.confirm.$error ? 'input-error' : ''" />
          <div v-if="v$.password.confirm.$error" class="error">
            {{v$.password.confirm.$errors[0].$message}}
          </div>
          <div class="inup-form-label-container">
            <label for="confirmPass">Confirm New Password <span>*</span></label>
          </div>
        </div>

        <div class="profile-form-button-container">
          <button @click="saveForm" name="Save" class="eu-btn-primary-small" style="height: 41px; width: 91px">
            Save
          </button>
        </div>
      </div>
      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </section>
  </settings-wrapper>
</template>

<script lang="ts">
import { ToggleButton } from "vue-js-toggle-button";
import SettingsService from "../services/SettingsService";
import Multiselect from "vue-multiselect";
import Notification from "../../components/Notification.vue";
import SettingsWrapper from "./SettingsWrapper.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import LoadingSpinner from "../../components/LoadingSpinner.vue";

export default {
  name: "reset-password",
  components: { ToggleButton, Multiselect, Notification, SettingsWrapper, LoadingSpinner },
  props: [],
  data() {
    return {
      isNotificationActive: false,
      isLoading: false,
      account_channel_message: null,
      notification: { msg: "", isError: false, icon: null },
      password: { current: null, new: null, confirm: null },
    };
  },
  async created() {
    this.service = new SettingsService();
    await this.getPreData();
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  validations() {
    return {
      password: {
        current: { required },
        new: { required, minLength: minLength(8) },
        confirm: { required, sameAs: sameAs(this.password.new) },
      },
    };
  },
  methods: {
    async getPreData() {
      this.isLoading = true;
      const response = await this.service.getResetPasswordPreData();
      if (response.success) {
        this.account_channel_message = response.account_channel_message;
      }
      this.isLoading = false;
    },
    async saveForm() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      const res = await this.service.resetPassword({
        current_password: this.password.current,
        new_password: this.password.new,
        new_password_confirm: this.password.confirm
      });
      if (res?.success) {
        this.showNotification({ msg: 'Password successfully updated.', icon: "fad fa-check-circle" });
      } else {
        this.showNotification({
          msg: res ? res.message : "Server Error",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
  },
};
</script>

<style scoped>
</style>
