<template>
  <settings-wrapper activeTab="mentorProfile">
    <section class="profile-form">
      <div class="profile-form-header-container">
        <h2>Edit Mentor Profile</h2>
      </div>
      <div v-if="isLoading" id="objects-list-loading-spinner">
        <loading-spinner />
      </div>
      <div v-else>
        <h3 class="settings-subtitle">Meeting Limit</h3>
        <p style="margin-bottom: 30px; margin-top: -5px">
          Choose how many meetings you can have in a given time period (day, week, month).
        </p>
        <div class="inup-form-element-container">
          <select name="dayLimit" @change="handleLimits" v-model="dayLimit" class="inup-input">
            <option value="">None</option>
            <option v-for="option in dayOptions" v-bind:value="option" v-bind:key="option">
              {{ option }}
            </option>
          </select>
          <div class="inup-form-label-container">
            <label for="dayLimit">Day Limit</label>
          </div>
        </div>
        <div class="inup-form-element-container">
          <select name="weekLimit" v-model="weekLimit" @change="handleLimits" class="inup-input">
            <option value="">None</option>
            <option v-for="option in weekOptions" v-bind:value="option" v-bind:key="option">
              {{ option }}
            </option>
          </select>
          <div class="inup-form-label-container">
            <label for="weekLimit">Week Limit</label>
          </div>
        </div>
        <div class="inup-form-element-container">
          <select name="monthLimit" @change="handleLimits" v-model="monthLimit" class="inup-input">
            <option value="">None</option>
            <option v-for="option in monthOptions" v-bind:value="option" v-bind:key="option">
              {{ option }}
            </option>
          </select>
          <div class="inup-form-label-container">
            <label for="monthLimit">Month Limit</label>
          </div>
        </div>
        <h3 class="settings-subtitle">Focus Areas</h3>
        <div class="multiselect-wrapper" style="width: 100%; margin: 0; position: relative">
          <multiselect
            v-model="areas"
            :options="all_areas"
            :multiple="true"
            :close-on-select="false"
            placeholder=""
            label="name"
            track-by="name"
          ></multiselect>
          <i class="fal fa-angle-down eu-select-arrow arrow-mentor-fa"></i>
        </div>
        <h3 class="settings-subtitle">Identities</h3>
        <div class="multiselect-wrapper" style="width: 100%; margin: 0; position: relative">
          <multiselect
            v-model="identities"
            :options="all_identities"
            :multiple="true"
            :close-on-select="false"
            placeholder=""
            label="name"
            track-by="name"
          ></multiselect>
          <i class="fal fa-angle-down eu-select-arrow arrow-mentor-fa"></i>
        </div>
        <div class="profile-form-button-container">
          <button
            name="Save"
            class="eu-btn-primary-small"
            style="height: 41px; width: 91px"
            @click="saveMentorSettings"
            aria-label="Save Mentor Settings"
          >
            Save
          </button>
        </div>
        <h3 class="settings-subtitle wborder">Availability</h3>
        <a
          href="/meetings/calendar"
          name="Edit Meeting Hours"
          class="da-components-button eu-file-upload-modal-btn-secondary edit-profile-button"
          style="position: relative; top: -45px; float: right"
          >Edit Meeting Hours</a
        >
        <div v-if="isLoading" class="loading-wrap">
          <div class="loading-hole">&nbsp;</div>
        </div>
        <vue-scheduler
          v-if="!isLoading"
          style="margin-top: 50px"
          :events="events"
          :event-display="eventDisplay"
          @view-changed="viewChanged"
        />
      </div>
      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </section>
  </settings-wrapper>
</template>

<script lang="ts">
import Vue from "vue";
import MeetingsService from "../../meetings/services/MeetingsService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import Multiselect from "vue-multiselect";
import VueScheduler from "v-calendar-scheduler";
import moment from "moment";
import SettingsService from "../services/SettingsService";
import { useCommonDataStore } from "../../stores/commonData";
import SettingsWrapper from "./SettingsWrapper.vue";
import Notification from "../../components/Notification.vue";

Vue.use(VueScheduler, {
  minDate: moment(),
  availableViews: ["month", "week"],
  initialDate: new Date(),
  initialView: localStorage.calendarMeetingView || "month",
  showTimeMarker: false,
  showTodayButton: false,
});

export default {
  components: { Multiselect, SettingsWrapper, LoadingSpinner, Notification },
  name: "edit-mentor-profile",
  props: [],
  data() {
    return {
      areas: [],
      identities: [],
      events: [],
      dayLimit: "",
      weekLimit: "",
      monthLimit: "",
      dayOptions: Array.from({ length: 20 }, (_, i) => (i + 1) * 1),
      weekOptions: Array.from({ length: 50 }, (_, i) => (i + 1) * 1),
      monthOptions: Array.from({ length: 100 }, (_, i) => (i + 1) * 1),
      isLoading: false,
      all_areas: [],
      all_identities: [],
      commonDataStore: useCommonDataStore(),
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
    };
  },
  async created() {
    // Logic from the run method and created lifecycle hook of MentorProfileView
    this.settingsService = new SettingsService();
    this.meetingsService = new MeetingsService();
    await this.getPreData();
    await this.getHours();
    this.listenButtonClicks();
    this.changeEventBackground();
    this.handleLimits();
  },
  methods: {
    async getPreData() {
      this.isLoading = true;
      const response = await this.settingsService.getMentorSettingsPredata();
      if (response.success) {
        this.areas = response.areas;
        this.dayLimit = response.meetings_limit.day ?? "";
        this.weekLimit = response.meetings_limit.week ?? "";
        this.monthLimit = response.meetings_limit.month ?? "";
        this.identities = response.identities;
        this.all_identities = this.commonDataStore.getSingleDataArray("identities");
        this.all_areas = this.commonDataStore.getSingleDataArray("focus_areas");
      }
      this.isLoading = false;
    },
    handleLimits() {
      if (this.dayLimit) {
        this.weekOptions = this.weekOptions.filter((e) => e > this.dayLimit);
        this.monthOptions = this.monthOptions.filter((e) => e > this.dayLimit);
      }
      if (this.weekLimit) {
        this.dayOptions = this.dayOptions.filter((e) => e < this.weekLimit);
        this.monthOptions = this.monthOptions.filter((e) => e > this.weekLimit);
      }
      if (this.monthLimit) {
        this.dayOptions = this.dayOptions.filter((e) => e < this.monthLimit);
        this.weekOptions = this.weekOptions.filter((e) => e < this.monthLimit);
      }
    },
    async saveMentorSettings() {
      const res = await this.settingsService.updateMentorSettings({
        areas: this.areas.map((e) => e.name),
        identities: this.identities.map((e) => e.name),
        meetings_limit: JSON.stringify({ day: this.dayLimit, week: this.weekLimit, month: this.monthLimit }),
      });
      if (res?.success) {
        this.showNotification({
          msg: "Profile Successfully Updated",
          icon: "fad fa-check-circle",
        });
      } else {
        this.showNotification({
          msg: res ? res.message : "Server Error",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
    },
    eventDisplay(event) {
      return `${event.startTime.format("hh:mm A")} - ${event.endTime.format("hh:mm A")} ${
        event.name ? `\xa0BUSY` : " \xa0\xa0AVAILABLE"
      }`;
    },
    async getHours() {
      const hours = await this.meetingsService.getHours();
      this.events = hours.map((h) => {
        h.startTime = moment(h.startTime).format("HH:mm");
        h.endTime = moment(h.endTime).format("HH:mm");
        return h;
      });
      this.isLoading = false;
    },
    viewChanged(newView) {
      if (!newView) {
        localStorage.calendarMeetingView = "month";
      } else {
        localStorage.calendarMeetingView = newView;
      }

      this.listenButtonClicks();
      setTimeout(() => {
        this.changeEventBackground();
      }, 100);
    },
    listenButtonClicks() {
      var elements = document.getElementsByClassName("v-cal-button");
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener("click", (e) => {
          setTimeout(() => {
            this.changeEventBackground();
          }, 100);
        });
      }
    },
    changeEventBackground() {
      const eventElements = document.querySelectorAll(".v-cal-event-item");
      eventElements.forEach((e) => {
        if (!e.textContent.includes("AVAILABLE")) {
          e["style"].background = "#8676bc";
          e["style"].color = "#8676bc";
        }
      });
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
  },
  mounted() {
    // Any logic that needs to run when the component is mounted
  },
};
</script>

<style scoped>
/* Your CSS styles go here */
</style>