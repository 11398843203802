<template>
  <section id="eu-event-wrapper">
    <div class="eu-event-collection">
      <!-- event item -->
      <div
        class="eu-event-collection-card"
        v-for="item in events"
        :key="item.id"
      >
        <!-- thumbnail -->
        <router-link
          :to="`/events/${item.slug}`"
          :aria-label="`Go to page of ${item.title} event`"
        >
          <img
            v-if="item.thumbnail"
            alt="Cover picture of event"
            class="eu-event-collection-card-img"
            :src="item.thumbnail"
          />  
          <div v-if="is_attending(item.id)" class="event-attending-baner">
            <span>
              ATTENDING
            </span>
          </div> 
          <h5 class="eu-event-collection-title">{{ item.title }}</h5>
        </router-link>

        <div class="eu-event-collection-body">
          <div class="eu-event-collection-date">
            <p>
              {{ item.start_date | dateFormat() }}
              <span v-if="item.start_time">
                <span style="color:#00a1b3"> | </span>
                {{formatEventTime(item)}}
              </span>
              <br>
              <span>{{ item.time_zone }}</span>
            </p>
          </div>

          <div class="eu-event-collection-details">
            <p>{{ item.description }}</p>
          </div>
          <div class="event-card-bottom">
            <div class="event-card-bottom-left">
              <!-- <span v-if="is_attending(item.id)" class="event_collection-attending"># Attending </span> -->
              <span v-if="item.is_online" class="eu-event-collection-location">
                <i class="eu-event-collection-icon fas fa-desktop"></i>
                <p>Virtual</p>
              </span>
              <span v-else class="eu-event-collection-location">
                <i class="eu-event-collection-icon fas fa-map-marker-alt"></i>
                {{ item.city }}, {{ item.state }}
              </span>
            </div>
            <div class="event-card-bottom-right">
              <span
                tabindex="0"
                v-if="item.is_bookmarked"
                @click.prevent="removeBookmark(item)"
                @keyup.enter="removeBookmark(item)"
                class="eu-event-collection-bookmark"
                :aria-label="`Remove ${item.name} from saved events`"
              >
                <i class="eu-event-collection-icon fad fa-bookmark"></i>
                <p>Saved</p>
              </span>
              <span
                tabindex="0"
                v-else
                @click.prevent="addBookmark(item)"
                @keyup.enter="addBookmark(item)"
                class="eu-event-collection-bookmark"
                :aria-label="`Add ${item.name} to saved courses`"
              >
                <i class="eu-event-collection-icon fal fa-bookmark"></i>
                <p>Save</p>
              </span>
            </div>  
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
// THIS COMPONENT IS DEPRECATED / NOT USED ANYMORE
import { Event } from "../../types";
import "../utils/DateFormat";
import { formatEventTime  } from '../utils/DateFormat';

export default {
  name: "event-list",
  props: ["events","attending_event_ids"],

  data() {
    return {};
  },

  methods: {
    addBookmark(item: Event) {
      this.$emit("add-bookmark", item.id, 'add');
      item.is_bookmarked = true;
    },

    removeBookmark(item: Event) {
      this.$emit("remove-bookmark", item.id, 'remove');
      item.is_bookmarked = false;
    },
    is_attending(id){
      return this.attending_event_ids.includes(id);
    },
    formatEventTime 
  },
};
</script>
