<template>
  <Modal :isActiveProp="isModalActive" @close="closeModal">
    <div class="profile-notification-modal">
      <h2 tabindex="0" role="headnig" id="appointment-accept-deny-modal" class="eu-profile-title">{{ title }}</h2>
      <div class="profile-notification-modal-header" style="display: flex; flex-direction: column">
        <div v-if="isAcceptedMeeting && !hasVideoChat() && meeting.meeting_type !== 'inperson'" class="field-wrapper hasValue">
          <div class="da-form-formItem" style="margin-left: 32px; margin-right: 32px">
            <input
              class="da-components-forms-input large js-da-login-email"
              type="url"
              name="Link"
              v-model="meetingUrl"
            />
          </div>
          <div class="field-placeholder" style="bottom: 23px; margin-left: 32px">
            <label for="file-name"
              ><span>Meeting URL<span class="required-star">*</span></span></label
            >
          </div>
        </div>
        <div class="field-wrapper hasValue eu-file-upload-modal-field eu-meetings-input acceptReason">
          <textarea
            id="deny-accept-message"
            v-model="acceptReason"
            type="text"
            placeholder="Enter your message here (optional)."
            rows="4"
            class="eu-textarea-modern"
          />
          <div class="field-placeholder">
            <span>
              <label for="deny-accept-message">{{ isAccept ? "Accept Message" : "Decline Reason" }}</label></span
            >
          </div>
        </div>
        <div class="row" style="width: 100%">
          <div class="column">
            <button
              @click="answerMeeting(meeting.id, acceptReason, isAccept, meetingUrl)"
              class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
              :class="isAcceptedMeeting ? '' : 'orange'"
              style="width: fit-content; height: fit-content"
            >
              {{ isAcceptedMeeting ? "Accept Appointment" : "Deny Appointment" }}
            </button>
            <button
              @click="closeModal"
              class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
              style="width: fit-content; height: fit-content"
            >
              Cancel
            </button>
          </div>
          <p v-if="isAcceptedMeeting" style="color: #f16b4c; margin-top: 8px">{{ errorMsg }}</p>
        </div>
      </div>
      <div class="eu-profile-card" :class="flag_collapse ? 'eu-profile-card-collaps' : 'eu-profile-card-hidden'">
        <div class="eu-profile-card-header">
          <span class="eu-profile-card-header-title"> Appointment Details</span>
          <span
            v-if="flag_collapse"
            tabindex="0"
            @click="show()"
            @keyup.enter="show()"
            aria-label="Hide apppointment details"
            class="eu-profile-card-header-collapse"
            >Collapse <i class="fal fa-angle-up"></i
          ></span>
          <span
            v-else
            @click="show()"
            tabindex="0"
            @keyup.enter="show()"
            aria-label="Show appointment details"
            class="eu-profile-card-header-collapse"
          >
            Show <i class="fal fa-angle-down"></i
          ></span>
        </div>
        <div class="eu-profile-card-item">
          <span class="eu-profile-card-item-title">Status:</span>
          <span
            class="eu-meeting-card-status eu-profile-card-item-text urgent"
            style="border-radius: 8px; padding: 2px 12px; font-weight: 500; cursor: default"
          >
            Action Required
          </span>
        </div>
        <div class="eu-profile-card-item">
          <span class="eu-profile-card-item-title">Date:</span>
          <span v-if="meeting.meeting_hours" style="font-weight: 600" class="eu-profile-card-item-text">{{
            meeting.meeting_hours.date | parseLongDate
          }}</span>
        </div>
        <div class="eu-profile-card-item">
          <span class="eu-profile-card-item-title">Time:</span>
          <span v-if="meeting.meeting_hours" class="eu-profile-card-item-text"
            >{{ meeting.meeting_hours.startTime | parseOneTime }} -
            {{ meeting.meeting_hours.endTime | parseOneTime }}</span
          >
        </div>
        <div class="eu-profile-card-item">
          <span class="eu-profile-card-item-title">Meeting type:</span>
          <span v-if="meeting.meeting_type" class="eu-profile-card-item-text">{{ meeting.meeting_type }}</span>
        </div>
        <div class="eu-profile-card-item">
          <span class="eu-profile-card-item-title">Focus area:</span>
          <span v-if="meeting.focus_area" class="eu-profile-card-item-text">{{ meeting.focus_area.name }}</span>
          <span v-else class="eu-profile-card-item-text">Any</span>
        </div>
        <div class="eu-profile-card-item">
          <span class="eu-profile-card-item-title">Mentor Group:</span>
          <span v-if="meeting.mentor_group" class="eu-profile-card-item-text">{{ meeting.mentor_group.name }}</span>
          <span v-else class="eu-profile-card-item-text">Any</span>
        </div>
      </div>
      <div class="eu-profile-card" style="padding: 0; margin: 0 30px">
        <div class="profile-notification-modal-requester">
          <img
            class="profile-notification-modal-requester-img"
            style="top: 0"
            :src="
              meeting.requester_obj.profile_pic
                ? meeting.requester_obj.profile_pic
                : '/static/assets/icons/default-profile.svg'
            "
          />
          <div class="profile-notification-modal-requester-name">
            {{ meeting.requester_obj.first_name }} {{ meeting.requester_obj.last_name }}
          </div>
        </div>
        <div
          v-if="meeting.description && meeting.description.length > 0"
          class="profile-notification-modal-message-wrapper"
        >
          <div class="profile-notification-modal-message">
            <span style="width: auto"
              >Message from {{ meeting.requester_obj.first_name }}: {{ meeting.description }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script lang="ts">
import Modal from "../../components/ModalExtended.vue";
import { stringifyArray } from "../utils/common";
import ProfileCard from "../../accounts/components/ProfileCard.vue";
import { isURLInvalid } from "../../utils/CustomUtils";

export default {
  components: { Modal, ProfileCard },
  name: "meeting-accept-modal",
  props: ["meeting", "isModalActive", "title", "isAccept", "isAcceptedMeeting"],
  data() {
    return {
      acceptReason: "",
      meetingUrl: null,
      flag_collapse: false,
      errorMsg: "",
    };
  },
  async created() {},
  methods: {
    stringifyArray,
    isURLInvalid,
    hasVideoChat() {
      const isMeetingsEnabled: HTMLElement = document.querySelector(".js-has-video-chat-meetings");
      return JSON.parse(isMeetingsEnabled.dataset.hasVideoChatMeetings.toLowerCase());
    },
    closeModal() {
      this.$emit("onClose");
    },
    answerMeeting(meetingId, msg, isAccepted, meetingUrl) {
      if (this.hasVideoChat() || this.meeting.meeting_type === 'inperson' || (!this.hasVideoChat() && !this.isURLInvalid(meetingUrl)))
        this.$emit("answerRequest", meetingId, msg, isAccepted, meetingUrl);
      else this.errorMsg = "Please input valid Meeting URL (Google Meet, Zoom etc.)";
    },

    show() {
      this.flag_collapse = !this.flag_collapse;
    },
  },
  computed: {},
};
</script>
