<template>
  <section
    class="io-notifications-dropdown"
  >
    <div class="io-notifications-dropdown-header">
      <span class="io-notifications-dropdown-header-text">Notifications</span>

      <div class="io-notifications-dropdown-header-action-container">
        <span id="notification-options-dropdown" class="io-notifications-dropdown-header-action" :class="optionsMenuActive?'io-notifications-dropdown-header-action-active':''" ref="notificationsHeaderActions" @click="toggleOptionsMenu" @keyup.enter="toggleOptionsMenu" tabindex="0" role="button" aria-label="Toggle notification options menu">
          <i class="fad fa-ellipsis-v"></i>
        </span>
        <span class="io-notifications-dropdown-header-action" @click="closeNotificationsDropdown" @keyup.enter="closeNotificationsDropdown" role="button" aria-label="Close notification dropdown" tabindex="0">
          <i class="fad fa-times"></i>
        </span>
        <div
          v-if="optionsMenuActive"
          ref="optionsMenuButton"
          class="io-notifications-dropdown-header-action-options"
          v-closable="{ exclude: ['optionsMenuButton','notificationsHeaderActions'], handler: 'closeOptionsMenu' }"
        >
          <div class="io-notifications-dropdown-header-option" @click="readAllNotifications" @keyup.enter="readAllNotifications" role="button" tabindex="0" aria-label="Mark all notifications as read">
            <span class="io-notifications-dropdown-header-option-icon">
              <i class="fas fa-eye"></i>
            </span>
            Mark all as read
          </div>
          <div class="io-notifications-dropdown-header-option" @click="deleteAllNotifications" @keyup.enter="deleteAllNotifications" role="button" tabindex="0" aria-label="Delete all notifications">
            <span class="io-notifications-dropdown-header-option-icon">
              <i class="fa-solid fa-trash-can"></i>
            </span>
            Delete all
          </div>
        </div>
      </div>
    </div>
    <div class="io-notifications-dropdown-items-container">
      <div
      :id='notification.id + "-notification-card"'
      v-for="notification in display_notification_list"
      :key="notification.id"
      :class="getNotificationClasses(notification)"
      @click="handleNotificationClick(notification)"
      @keyup.enter="handleNotificationClick(notification)"
      tabindex="0"
      role="link"
      aria-label="Follow notification link"
      >
          <div class="io-notifications-dropdown-item-icon-container" :style="notification.icon_color + ''">
            <i :class="notification.icon + ' io-notifications-dropdown-item-icon'"></i>
          </div>
            <div class="io-notifications-dropdown-item-content-container">
            <div class="io-notifications-dropdown-item-text" v-html='notification.text'></div>
            <div class="io-notifications-dropdown-item-date">{{notification.created_at}}</div>
          </div>
          <i
          :id='notification.id + "-notification-card"'
          class="fas fa-circle io-notification-circle"
          :class="notification.is_read? 'io-notification-circle-read': ''"
          @click="handleReadNotification($event, notification.id)"
          @keyup.enter="handleReadNotification($event, notification.id)"
          role="button"
          aria-label="Mark notification as read"
          ></i>
      </div>
      <div v-if='display_notification_list.length == 0' class="io-notifications-dropdown-item-no-notifications">
        No new notifications.
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import handleOutsideClick from '../../directives/handleOutsideClick';

export default {
  name: 'top-navbar-notifications',
  props: [
    'notification_list','loggedUser'
  ],
  directives: { closable: handleOutsideClick },
  data() {
    return {
      optionsMenuActive: false,
      display_notification_list: [],
    };
  },
  watch: {
    active: function() {},
    notification_list: function() {
      this.display_notification_list = [];
      this.displayNotificationHandler();
    }
  },
  created() {
    this.$emit('noNewNotifications');
    this.displayNotificationHandler();
  },
  mounted() {},
  methods: {
    getNotificationClasses(notification) {
      if (notification.is_read) {
        if (notification.redirection_url != "" && notification.redirection_url != undefined && notification.redirection_url != null) {
          return 'io-notifications-dropdown-item-read-redirect'
        } else {
          return 'io-notifications-dropdown-item-read'
        }
      } else {
        return 'io-notifications-dropdown-item'
      }
    },
    getNotificationType(verb) {
      if(verb.includes('post_in')) {
        return 'POST'
      } else {
        return 'UNKNOWN'
      }

    },
    getNotificationIcon(notification_type) {
      if(notification_type=="POST") {
        return "fa-solid fa-megaphone"
      } else if(notification_type=="MEETING") {
        return "fa-solid fa-messages"
      } else if(notification_type=="EVENT") {
        return "fad fa-calendar-star"
      } else if(notification_type=="PROGRAM") {
        return "fad fa-calendar-star"
      } else if(notification_type=="RESOURCE") {
        return "fad fa-images"
      } else if(notification_type=="OUT_OF_MEETING_SLOTS") {
        return "fa-solid fa-user-xmark"
      }

      return "far fa-comment-alt-lines"
    },
    getNotificationColor(notification_type) {
      if(notification_type=="POST") {
        return "background-color: #e5f8f8; color: #00a1b3"
      } else if(notification_type=="MEETING") {
        return "background-color: #ffebdb; color: #db670b"
      } else if(notification_type=="EVENT") {
        return "background-color: #fff6d2; color: #dab567"
      } else if(notification_type=="PROGRAM") {
        return "background-color: #fff6d2; color: #dab567"
      } else if(notification_type=="RESOURCE") {
        return "background-color: #eaeeff; color: #905ea1"
      } else if(notification_type=="OUT_OF_MEETING_SLOTS") {
        return "background-color: #eaeeff; color: #905ea1"
      }

      return "background-color: #ffebdb; color: #db670b"
    },
    getNotificationText(notification_type, notification) {
      if(notification_type=="POST") {
        return "New post(s) in <b>" + notification.action_in + "</b>";
      } else if(notification_type=="MEETING") {
        if(notification.verb=="create") {
          if(notification.object.mentee_id!==this.loggedUser.id) {
            return "<b>" + notification.object.mentee_name + "</b>" + " requested a meeting."
          } else {
            return "";
          }
        } else if(notification.verb=="accept") {
          if(notification.object.mentor_id!==this.loggedUser.id) {
            return "<b>" + notification.object.mentor_name + "</b>" + " accepted meeting request."
          } else {
            return "";
          }
        } else if(notification.verb=="refuse") {
          if(notification.object.mentor_id!==this.loggedUser.id) {
            return "<b>" + notification.object.mentor_name + "</b>" + " refused meeting request."
          } else {
            return "";
          }
        } else if(notification.verb=="cancel") {
          const actor_id = parseInt(notification.actor.split("_")[2])
          const actor_role = notification.actor.split("_")[1]
          if(actor_id!==this.loggedUser.id) {
            const actor_name = notification.object.mentor_id==actor_id? notification.object.mentor_name: notification.object.mentee_name;
            return "<b>" + actor_name + "</b>" + " canceled meeting."
          } else {
            return "";
          }
        }
      } else if(notification_type=="EVENT") {
        const event_title = notification.object.event_title;
        const department_name = notification.object.department_name;
        const group_name = notification.object.group_name;
        const partner_name = notification.object.partner_name
        const actor_id = notification.actor.split("_")[0];
        if(notification.verb=="create" && actor_id != this.loggedUser.id) {
          return 'Event <b>' + event_title + '</b> is created in <b>'+ (department_name ? department_name : group_name ? group_name : partner_name ) + '</b>.';
        } else {
          return "";
        }
      } else if(notification_type=="PROGRAM") {
        const program_title = notification.object.event_title;
        const department_name = notification.object.department_name;
        const group_name = notification.object.group_name;
        const partner_name = notification.object.partner_name
        const actor_id = notification.actor.split("_")[0];
        if(notification.verb=="create" && actor_id != this.loggedUser.id) {
          let a = 'Program <b>' + program_title + '</b> is created in <b>'+ (department_name ? department_name : group_name ? group_name : partner_name ) + "</b>.";
          return a;
        } else {
          return "";
        }
      } else if(notification_type=="RESOURCE") {
        const resource_name = notification.object.resource_name;
        const destination_name = notification.object.destination_name;
        let resource_type = notification.object.resource_type;
        const actor_id = notification.actor.split("_")[0];


        if (resource_type == "VID") {
          resource_type = "Video";
        } else if(resource_type == "IMG") {
          resource_type = "Image";
        } else if(resource_type == "URL") {
          resource_type = "Url";
        } else if(resource_type == "URL_INTERNAL") {
          resource_type = "Url";
        } else if(resource_type == "URL_EXTERNAL") {
          resource_type = "Url";
        } else if(resource_type == "DOC") {
          resource_type = "Document"
        }

        if(notification.verb=="add" && actor_id != this.loggedUser.id) {
          return resource_type + " <b>" + resource_name + "</b> added in <b>" + destination_name + "</b>'s library."
        } else {
          return "";
        }
      } else if(notification_type=="OUT_OF_MEETING_SLOTS") {
        return "You don't have any free meeting slots. Let students know when you will be available again."
      }

      return "";
    },
    displayNotificationHandler() {
      this.notification_list.forEach(notification => {
        const date = new Date(Date.parse(notification.created_at));
        const date_text = date.toLocaleDateString('en-us', { day:"numeric", month:"short"});
        const notification_type = notification.type;

        if(notification_type != "UNKNOWN") {
          const text = this.getNotificationText(notification_type, notification);
          const icon = this.getNotificationIcon(notification_type);
          const icon_color = this.getNotificationColor(notification_type);
          if(text != "") {
            this.display_notification_list.push({
              'id': notification.id,
              'activity_id': notification.activity_id,
              'is_read': notification.is_read,
              'action_in': notification.action_in,
              'created_at': date_text,
              'text': text,
              'icon': icon,
              'icon_color': icon_color,
              'redirection_url': notification.redirection_url,
            });
          }
        }
      });
    },

    toggleOptionsMenu() {
      this.optionsMenuActive = !this.optionsMenuActive;
    },

    closeOptionsMenu() {
      this.optionsMenuActive = false;
    },

    closeNotificationsDropdown() {
      this.$emit("closeNotificationsDropdown")
    },

    handleReadNotification(event, id) {
      this.readNotification(id);
      event.stopPropagation();
    },

    readNotification(id) {
      this.display_notification_list.find(notification => notification.id == id).is_read = true;
      this.$emit("readNotification", id)

      if(this.display_notification_list.filter(notification=> notification.is_read == false).length == 0) {
        this.$emit('noNewNotifications');
      }
    },

    readAllNotifications() {
      this.display_notification_list.forEach(notification => {
        if (!notification.is_read) {
          notification.is_read = true;
          this.readNotification(notification.id);
        }
      });
    },

    deleteAllNotifications() {
      this.$emit('deleteAllNotifications', this.display_notification_list.map(notification=>notification.activity_id));
      this.display_notification_list = []
    },

    handleNotificationClick(notification) {
      if (!notification.is_read) {
        this.readNotification(notification.id);
      }
      this.redirectTo(notification.redirection_url);
    },

    redirectTo(url) {
      window.location.href = url;
    },
  }
};
</script>
