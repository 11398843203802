import Vue from 'vue';
import * as Cookies from 'js-cookie';
import NewsletterService from './services/NewsletterService';
import { Datetime } from 'vue-datetime';
import Notification from '../components/Notification.vue'
import UsersBrowse from './components/UsersBrowse.vue'

export default class NewsletterAdmin {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected service: NewsletterService;

  protected schema: string;

  constructor(el: HTMLElement, schema:string) {
    this.csrfToken = Cookies.get('csrftoken');
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);

    this.schema = schema;
    this.service = new NewsletterService();
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;

    const customHeaders = {
      'Accept': 'application/json',
      'X-CSRFToken': _class.csrfToken,
    };

    const newsletterAdmin = new Vue({
      template: `
        <section>
          <h3 class="preference-container-description">
            Notify all users of recently added content.
          </h3>
          <button 
            @click="handleSave" 
            class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
            aria-label="Save form"
          >
            Notify
          </button>
          <users-browse
            :schema=schema
          />
        </section>
      `,
      data() {
        return {
          schema: _class.schema,
        };
      },
      components: { Datetime, Notification, UsersBrowse },
      methods: {
        async handleSave(e) {
          e.preventDefault();

          const data = {
            'schema': _class.schema,
          };

          await _class.service.post(data, customHeaders);
          location.reload();
        },
      },
    });
    newsletterAdmin.$mount(this.mountPoint);
  }
}
