<template>
  <partner-row
    v-if="rowItem.isPartnerRow"
    :isSelected="isSelected"
    :partner="rowItem.partner"
    @toggle-partner="togglePartner"
    @edit-partner="editPartner"
    @add-manager="addManager"
    @view-dashboard="viewDashboard"
    @delete-partner="deletePartner"
    @toggle-managers-table="toggleManagersTable"
  ></partner-row>

  <add-manager-row
    v-else-if="rowItem.isAddManagerRow"
    :rowVisible="rowVisible"
    :partner="rowItem.partner"
    @add-manager="addManager"
  ></add-manager-row>

  <manager-row
    v-else
    :rowVisible="rowVisible"
    :manager="rowItem.manager"
    :partner="rowItem.partner"
    :isSelected="isSelected"
    @edit-manager="editManager"
    @toggle-manager="toggleManager"
    @delete-manager="deleteManager"
    @resend-invitation="resendInvitation"
    @reset-password="resetPassword"
  ></manager-row>
</template>

<script lang="ts">
import PartnerRow from "./PartnerRow.vue";
import ManagerRow from "./ManagerRow.vue";
import AddManagerRow from "./AddManagerRow.vue";

export default{
  name: 'partners-table-row',

  components: {
    PartnerRow,
    ManagerRow,
    AddManagerRow,
  },

  props: [
    'rowItem',
    'isSelected',
    'rowVisible',
  ],

  data() {
    return {
      partnersManagersExpanded: false,
      burgerExpanded: false,
    }
  },

  methods: {
    toggleManagersTable(partnerId) {
      this.$emit('toggle-managers-table', partnerId);
    },
    toggleManager(manager_id, partner_id) {
      this.$emit('toggle-manager', manager_id, partner_id);
    },
    togglePartner(id) {
      this.$emit('toggle-partner', id);
    },
    editPartner(id) {
      this.$emit('edit-partner', id);
    },
    addManager(partnerId) {
      this.$emit('add-manager', partnerId);
    },
    editManager() {
      this.$emit('edit-manager', this.rowItem.manager, this.rowItem.partner);
    },
    viewDashboard(name) {
      this.$emit('view-dashboard', name);
    },
    deletePartner(partner) {
      this.$emit('delete-partner', partner);
    },
    deleteManager() {
      this.$emit('delete-manager', this.rowItem);
    },
    resendInvitation(manager_id, partner_id) {
      this.$emit('resend-invitation', manager_id, partner_id);
    },
    resetPassword(manager_id) {
      this.$emit('reset-password', manager_id);
    },
  }
}
</script>
