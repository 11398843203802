<template>
  <div class="partner-resource-card-container">
    <div class="resource-card-image-section partner" :style="cardImageUrl ? `background-image: url('${cardImageUrl}')` : ''">
      <div v-if="!cardImageUrl" class="resource-card-background-icon-container partner">
        <i class="resource-card-background-icon" :class="resourceBackgroundIcon"></i>
      </div>
      <span
        :class="isSelected ? 'selected' : ''"
        class="iu-resource-grid-checkbox"
        tabindex="0"
        role="checkbox"
        style="position: relative"
        @click="toggleResourceSelection($event)"
        @keyup.enter="toggleResourceSelection($event)"
        :aria-label="`Select ${resource.name}`"
      >
        <i class="fa-solid fa-check iu-resource-grid-check-icon" v-if="isSelected"></i>
      </span>
    </div>
    <div class="resource-card-text-section partner">
      <div class="resource-card-full-height-text partner">
        <div class="partner-resource-card-image-section-mobile" :style="cardImageUrl ? `background-image: url('${cardImageUrl}')` : ''">
          <div v-if="!cardImageUrl" class="resource-card-background-icon-container partner">
            <i class="resource-card-background-icon" :class="resourceBackgroundIcon"></i>
          </div>
          <span
            :class="isSelected ? 'selected' : ''"
            class="iu-resource-grid-checkbox"
            tabindex="0"
            role="checkbox"
            style="position: relative"
            @click="toggleResourceSelection($event)"
            @keyup.enter="toggleResourceSelection($event)"
            :aria-label="`Select ${resource.name}`"
          >
            <i class="fa-solid fa-check iu-resource-grid-check-icon" v-if="isSelected"></i>
          </span>
        </div>
        <div
          class="resource-card-title partner text-clamp-2"
          aria-label="See the resource"
          tabindex="0"
          @click="$emit('view-item', resource.id)"
          @keyup.enter="$emit('view-item', resource.id)"
        >
          <i v-if="resource.is_partner_featured" class="fa-solid fa-stars"></i>
          {{ resource.name }}
        </div>
        <div class="resource-card-description partner text-clamp-3">
          {{ resource.description }}
        </div>
      </div>
      <div class="resource-card-bottom partner">
        <div class="resource-card-info-container">
          <div class="resource-card-partner-container" v-if="resource.partner_name">
            <img class="resource-partner-logo" v-if="resource.partner_logo" :src="resource.partner_logo" :alt="`${resource.partner_name} logo`">
            <span class="resource-card-partner-name">{{resource.partner_name}}</span>
          </div>
          <div class="resource-date">
            <span class="separator">•</span>
            {{resource.created_at}}
          </div>
        </div>
        <div class="resource-card-action-container partner">
          <button
            class="bookmark-button partner"
            :class="isBookmarkActive ? 'active' : ''"
            :aria-label="(isBookmarkActive ? 'Remove' : 'Add') + ' resource bookmark'"
            @click="toggleResourceBookmark()"
          >
            <i class="fa-bookmark" :class="bookmarkIcon"></i>
          </button>
          <dropdown-burger-menu
            buttonClass="partner-resource-dropdown"
            :actionList="getActionList()"
            @view-item="emitEvent('view-item')"
            @edit-item="emitEvent('edit-item')"
            @unfeature-item="emitEvent('unfeature-item')"
            @feature-item="emitEvent('feature-item')"
            @download-item="emitEvent('download-item')"
            @remove-item-bookmark="emitEvent('remove-item-bookmark')"
            @bookmark-item="emitEvent('bookmark-item')"
            @copy-item-link="emitEvent('copy-item-link')"
            @unarchive-item="emitEvent('unarchive-item')"
            @archive-item="emitEvent('archive-item')"
            @delete-item="emitEvent('delete-item')"
          ></dropdown-burger-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownBurgerMenu from '../../components/DropdownBurgerMenu.vue';

export default {
  //the component styles are developed for mobile sizes
  //if we are going to use it on desktop, we need some restyling
  name: 'partner-resource-card',
  components: {
    DropdownBurgerMenu,
  },
  props: ['isSelected', 'resource', 'partner'],
  computed: {
    cardImageUrl() {
      if (this.resource.resource_type === 'IMG') {
        return this.resource.image_source;
      }
      if (this.resource.thumbnail_url) {
        return this.resource.thumbnail_url;
      }
      return null;
    },
    hasDepartment() {
      return !!this.departmentName;
    },
    isDownloadable() {
      return (
        this.resource.resource_type != 'VID' &&
        this.resource.resource_type != 'URL' &&
        this.resource.resource_type != 'URL_INTERNAL' &&
        this.resource.resource_type != 'URL_EXTERNAL'
      );
    },
    hasDepartment() {
      return !!this.resource.department_name;
    },
    resourceTypeIcon() {
      if (this.resource.resource_type == 'VID') return 'fa-regular fa-file-video';
      if (this.resource.resource_type == 'URL') return 'fa-regular fa-link';
      if (this.resource.resource_type == 'DOC') return 'fa-regular fa-file';
      if (this.resource.resource_type == 'IMG') return 'fa-regular fa-image';
      return '';
    },
    resourceBackgroundIcon() {
      if (this.resource.resource_type === 'DOC') return 'fa-regular fa-file-alt iu-resource-grid-icon';
      if (this.resource.resource_type === 'VID') return 'fa-regular fa-file-video iu-resource-grid-icon';
      if (this.resource.resource_type === 'URL') return 'fa-regular fa-external-link iu-resource-grid-icon';
      if (this.resource.resource_type === 'URL_INTERNAL')
        return 'fa-regular fa-file-alt fa-external-link iu-resource-grid-icon';
      if (this.resource.resource_type === 'URL_EXTERNAL')
        return 'fa-regular fa-file-alt fa-external-link iu-resource-grid-icon';
    },
    bookmarkIcon() {
      return this.resource.is_bookmarked ? 'fa-solid' : 'fa-regular';
    },
    isBookmarkActive() {
      return this.resource.is_bookmarked;
    },
  },
  data() {
    return {};
  },
  methods: {
    emitEvent(event_name) {
      this.$emit(event_name);
    },
    toggleResourceSelection(event) {
      this.$emit('toggle-selection', event, this.resource);
    },
    toggleResourceBookmark() {
      if (this.resource.is_bookmarked) {
        this.emitEvent('bookmark-item');
      } else {
        this.emitEvent('remove-item-bookmark');
      }
    },
    getActionList() {
      let actionList = [];

      actionList.push({
        label: 'View',
        icon: 'fa-solid fa-eye',
        action: 'view-item',
      });

      if (this.resource.has_permission) {
        actionList.push({
          label: 'Edit',
          icon: 'fa-solid fa-pen',
          action: 'edit-item',
        });
      }

      if (this.resource.is_featured && this.resource.has_permission) {
        actionList.push({
          label: 'Unfeature',
          icon: 'fa-solid fa-stars',
          action: 'unfeature-item',
        });
      } else if (this.resource.has_permission) {
        actionList.push({
          label: 'Feature',
          icon: 'fa-solid fa-stars',
          action: 'feature-item',
        });
      }

      if (this.isDownloadable) {
        actionList.push({
          label: 'Download',
          icon: 'fa-solid fa-download',
          action: 'download-item',
        });
      }

      if (this.isBookmarked) {
        actionList.push({
          label: 'Unsave',
          icon: 'fa-solid fa-bookmark',
          action: 'remove-item-bookmark',
        });
      } else {
        actionList.push({
          label: 'Save',
          icon: 'fa-solid fa-bookmark',
          action: 'bookmark-item',
        });
      }

      actionList.push({
        label: 'Copy link',
        icon: 'fa-solid fa-link',
        action: 'copy-item-link',
        additionalDescription: 'File accessible to members only',
      });

      if (this.resource.is_archived && this.resource.has_permission) {
        actionList.push({
          label: 'Unarchive',
          icon: 'fa-solid fa-archive',
          action: 'unarchive-item',
        });
      } else if (this.resource.has_permission) {
        actionList.push({
          label: 'Archive',
          icon: 'fa-solid fa-archive',
          action: 'archive-item',
        });
      }

      if (this.resource.has_permission) {
        actionList.push({
          label: 'Delete',
          icon: 'fa-solid fa-trash',
          action: 'delete-item',
        });
      }

      return actionList;
    },
  },
};
</script>

<style lang="scss" scoped>
.partner-resource-card-container {
  display: flex;
  gap: 1.5rem;
  padding: 1.5rem 0;
  border-bottom: 1px solid #e7e5ec;

  &:last-child, &:nth-last-child(2) {
    border-bottom: none;
  }
}

.resource-card-text-section.partner {
  width: 100%;
  background-color: transparent;
  justify-content: space-between;
  padding: 0;
  gap: 1rem;
}

.resource-card-full-height-text.partner {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.resource-card-description.partner {
  min-height: unset;
  color: #494949;
  font-size: 1rem;
}

.resource-card-image-section.partner {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 16px;
  overflow: hidden;
}

.resource-card-background-icon-container.partner {
  border-radius: 16px;
}

.resource-card-bottom.partner {
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.resource-partner-logo {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #d8dce6;
  border-radius: 16px;
  margin-right: 0.5rem;
}

.resource-card-info-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.resource-card-partner-container {
  display: flex;
  align-items: center;
}

.resource-card-partner-name {
  color: #444444;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
}

.resource-date {
  color: #838ea1;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;

  .separator {
    color: #6d798c;
    margin: 0 0.25rem;
  }
}

.resource-card-action-container.partner {
  flex-direction: row;
  gap: 1.25rem;
}

.bookmark-button.partner {
  color: #26354a;
  font-size: 1.25rem;
  background-color: transparent;
  border: none;


  &:hover {
    color: #516075;
  }
}

.resource-card-title.partner {
  i {
    color: #00a1b3;
  }

  &:hover {
    cursor: pointer;
    color: #666;
  }
}

.partner-resource-card-image-section-mobile {
  display: none;
}

@media screen and (max-width: 576px) {
  .resource-card-image-section.partner {
    display: none;
  }
  .partner-resource-card-image-section-mobile {
    width: 6rem;
    height: 4.125rem;
    border-radius: 16px;
    overflow: hidden;
    display: initial;
    background-position: center;
    background-size: cover;

    .resource-card-background-icon-container.partner {
      width: 6rem;
      height: 4.125rem;
    }

    .iu-resource-grid-checkbox {
      display: block;
    }
  }
  .resource-card-full-height-text.partner {
    flex-direction: row;
    gap: 2rem;
  }
  .resource-card-description.partner {
    display: none !important;
  }
}

</style>
