<template>
    <section :style="`width:100%`">
        <div v-if="isLoading">
             <loading-spinner />
        </div>
        <line-series-chart
            v-if="!isLoading && !is_pie_chart && !is_column_chart && !is_buble_chart"
            :items="items"
            :keys="keys"
        />
        <pie-chart
            v-if="!isLoading && is_pie_chart && !is_column_chart"
            :array="items"
            :colors="[
                '#5e50b5',
                '#9950cd',
                '#1bd4d4',
                '#00a1b3',
                '#f6764d',
                '#9f2a8c',
                '#6df0d2',
                '#1bd4d4',
                '#00a1b3',
                '#007d99',
                '#5e50b5',
                '#9950cd',
                '#d35fa5',
                '#ff997b',
                '#fac484',
                '#fae994'
            ]"

        />
        <column-series-chart
            v-if="!isLoading && is_column_chart && !is_buble_chart"
            :items="items"
        />
        <circular-packing-bubble
             v-if="!isLoading && is_buble_chart"
             :items="bubble_chart_items"
             :items_domain="items_domain"
        />

    </section>
</template>

<script>
import CircularPackingBubble from '../chart/CircularPackingBubble.vue'
import LoadingSpinner from '../../../components/LoadingSpinner.vue';
import AnalyticsService from '../../services/AnalyticsService';
import ColumnSeriesChart from './ColumnSeriesChart.vue'
import LineSeriesChart from './LineSeriesChart.vue'
import PieChart from "./PieChart.vue"

export default {
    name: "analytics-chart-fetch-data",
    components: {
            CircularPackingBubble,
            ColumnSeriesChart,
            LineSeriesChart,
            LoadingSpinner,
            PieChart
        },
    props:{
        is_column_chart:Boolean,
        selectedOption:Number,
        is_pie_chart:Boolean,
        is_buble_chart:Boolean,
        keys:String,
        paramsOptional: {
            type: Object,
            default: function() {
                return {};
            },
        },
        filtersData : {
            start_date: Date,
            end_date: Date,
            keys: String,
        },
        items_domain:Array
    },

    data(){
        return{
            isLoading : true,
            items: [],
            bubble_chart_items: [],
            dic:{},
            service: new AnalyticsService()
        }
    },
    async created(){
        if(!this.keys) return;
    },
    methods:{
        async get_data(){
            let data = await this.service.getChartData(this.keys, this.paramsOptional);
            this.items = data.seriesData;
            this.bubble_chart_items = this.items.filter(item => item.value > 0);
            this.isLoading = false;
        },
    },
    watch: {
        keys: async function(newVal, oldVal) {
            this.isLoading = true;
            this.get_data();
        },
        paramsOptional: {
            handler(newVal, oldVal) {
                this.isLoading = true;
                 this.get_data();
            },
            immediate: true
        },
    }
}
</script>
<style>
</style>
