<template>
    <span
        v-if="hasAnyQuickFeature"
        class="quick-action-container"
    >
        <button
            id="quick-actions-btn"
            ref="quickActionsButton"
            class="da-components-button da-btn-solid eu-upload-btn quick-action-button"
            @click="toggleQuickActionMenu"
            aria-label="Open/close quick actions menu."
        >
            <i class="fad fa-plus"></i>
            Quick actions
        </button>
        <div
            id="quick-actions-burger"
            ref="quickActionsBurger"
            class="eu-resource-burger quick-action-burger"
            v-closable="{ exclude: ['quickActionsButton', 'quickActionsBurger', 'quickResourceDropDown'], handler: 'closeQuickActionMenu'}"
        >
            <span
                id="open-post-modal-btn"
                v-if="hasActivityFeed && loggedUser.department_name"
                tabindex="0"
                class="quick-action-burger-el"
                aria-haspopup="dialog"
                role="button"
                aria-label="Open post dialog."
                @click="openPostModal"
                @keyup.enter="openPostModal"
            >
                <i class="fas fa-comment-alt-plus fa-fw create-post"></i>
                <span>Create Post</span>
            </span>
            <span
                v-if="hasMentorConnect && !isPartnerTenant()"
                tabindex="0"
                class="quick-action-burger-el"
                aria-haspopup="dialog"
                role="button"
                aria-label="Open create mentor dialog."
                @click="createMentor"
                @keyup.enter="createMentor"
            >
                <i class="fas fa-user-plus fa-fw add-mentor"></i>
                <span>Add Mentor</span>
            </span>
            <a
                v-if="hasEvents"

                :href="isPartnerTenant && partner ? `/events/create/?partner=${partner}` : '/partner/dashboard/quickEvent'"
                tabindex="0"
                class="quick-action-burger-el"
                aria-label="Go to create event page."
                role="link"
                @click="setReturnLink"
            >
                <i class="fas fa-calendar-plus fa-fw add-event"></i>
                <span>Add Event</span>
            </a>
            <a
                v-if="hasPrograms"
                :href="isPartnerTenant && partner ? `/programs/create?partner=${partner}` : '/partner/dashboard/quickProgram'"
                tabindex="0"
                class="quick-action-burger-el"
                aria-label="Go to create new program page."
                role="link"
                @click="setReturnLink"
            >
                <i class="fas fa-calendar-plus fa-fw add-event"></i>
                <span>Add Program</span>
            </a>
            <span
                ref="quickResourceButton"
                v-if="hasResourceLibrary"
                tabindex="0"
                class="quick-action-burger-el"
                style="flex-direction: column; align-items: flex-start;"
                aria-haspopup="dialog"
                aria-label="Select uploading resources type."
                role="button"
                @click="showResourceDropDown"
                @keyup.enter="showResourceDropDown"
            >
            <span>
                <i class="fas fa-folder-plus fa-fw add-resource"></i>
                <span>Add Resource</span>
            </span>
                 <div
          ref="quickResourceDropDown"
          class="eu-quick-action-resource-dropdown eu-upload-dropdown-qactions"
          v-if="optionsDropdownVisible"
          v-closable="{ exclude: ['quickResourceButton', 'quickResourceDropDown'], handler: 'closeResourceDropDown'}"
        >
          <span
            id="open-upload-file-modal-btn"
            tabindex="0"
            class="eu-upload-dropdown-element"
            @click="openFileModal()"
            @keyup.enter="openFileModal()"
            aria-label="Open upload modal"
            aria-haspopup="dialog"
            role="button"
          >
          <i class="fa-regular fa-upload eu-upload-icon" style="margin-right: 0"></i>Upload from your device
        </span>
          <span
            id="open-upload-video-modal-btn"
            tabindex="0"
            class="eu-upload-dropdown-element"
            @click="openVideoModal()"
            @keyup.enter="openVideoModal()"
            aria-label="Open modal for embedding video links"
            aria-haspopup="dialog"
            role="button"
          >
            <i class="fas fa-video-plus eu-upload-icon" style="margin-right: 0"></i>Embed video from link
          </span>
          <span
            id="open-upload-link-modal-btn"
            tabindex="0"
            class="eu-upload-dropdown-element"
            @click="openUrlModal()"
            @keyup.enter="openUrlModal()"
            aria-label="Open modal for url upload"
            aria-haspopup="dialog"
            role="button"
          >
            <i class="fas fa-link eu-upload-icon" style="margin-right: 0"></i>Upload internal/external URL
          </span>
        </div>
            </span>
            <span
                v-if="canCreateAdmin && !isPartnerTenant()"
                tabindex="0"
                class="quick-action-burger-el"
                aria-haspopup="dialog"
                aria-label="Open create admin dialog."
                role="button"
                @click="createAdmin"
                @keyup.enter="createAdmin"
            >
                <i class="fas fa-users-cog fa-fw add-admin"></i>
                <span>Add New User</span>
            </span>
        </div>
        <manual-upload-modal
            :isModalActive="isManualAddModalActive"
            :roles="roles"
            :preselectedRole="selectedRole"
            :editMode="false"
            @add-manual="addUser"
            @close-modal="closeManualModal"
        />

        <feed-modals
        v-if="loggedUser.department_name"
        :isDeleteModalActive="isDeleteModalActive"
        :isPostModalActive="isPostModalActive"
        :showFileDialog="showFileDialog"
        :loggedUser="loggedUser"
        :files="files"
        :isLoadingUpload="isLoadingUpload"
        :postText="postText"
        @create-post="createPost"
        @update-post-text="updatePostText"
        @handle-file-upload="handleFileUpload"
        @create-post-check="createPostCheck"
        @close-post-modal="closePostModal"
        />

        <resource-modal
          v-if="loggedUser.department_name"
          :isResourceModalActive="isResourceModalActive"
          :activeResource="activeResource"
          @close-resource-modal="closeResourceModal"
        />

        <upload-file-modal
          :isModalActive="isFileModalActive"
          :showShareBtn="true"
          :list_departments="departments"
          :currentDepartment="department_name"
          :filterData="filterData"
          :isPartnerTenant="isPartnerTenant()"
          :isPartnerTab="!!partner"
          :currentPartner="partner"
          @close-file-modal="closeFileModal"
        />

        <upload-video-modal
          :isModalActive="isVideoModalActive"
          :showShareBtn="true"
           :list_departments="departments"
          :currentDepartment="department_name"
          :filterData="filterData"
          :isPartnerTenant="isPartnerTenant()"
          :isPartnerTab="!!partner"
          :currentPartner="partner"
          @close-video-modal="closeVideoModal"
        />

        <upload-url-modal
          :isModalActive="isUrlModalActive"
          :showShareBtn="true"
          :list_departments="departments"
          :currentDepartment="department_name"
          :filterData="filterData"
          :isPartnerTenant="isPartnerTenant()"
          :isPartnerTab="!!partner"
          :currentPartner="partner"
          @close-url-modal="closeUrlModal"
        />

        <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </span>
</template>
<script lang="ts">
import Cookies from 'js-cookie';
import Notification from '../../components/Notification.vue'
import handleOutsideClick from '../../directives/handleOutsideClick';
import RoleService from '../../roles/services/RoleService'
import ManualUploadModal from '../../accounts/components/modals/ManualUploadModal.vue'
import { manualImportUser } from '../../accounts/components/utils/UsersUtils'
import BulkUploadService from '../../accounts/services/BulkUploadService';
import FeedModals from '../../feed/components/FeedModals.vue'
import { validateExtensionFile } from '../../utils/TypecheckUtils';
import FeedService from '../../feed/services/FeedService';
import ResourceLibraryService from '../../library/services/ResourceLibraryService';

import UploadFileModal from '../../library/components/modals/UploadFileModal.vue'
import UploadVideoModal from '../../library/components/modals/UploadVideoModal.vue'
import UploadUrlModal from '../../library/components/modals/UploadUrlModal.vue'
import { focusElement, isPartnerTenant } from '../../utils/CustomUtils'
import ResourceModal from '../../library/components/modals/ResourceModal.vue';

export default {
    name: 'quick-action-button',

    components: {
      ManualUploadModal,
      Notification,
      FeedModals,
      UploadFileModal,
      UploadVideoModal,
      UploadUrlModal,
      ResourceModal
    },

    props: [
        'allowedFeatures',
        'isAdmin',
        'loggedUser',
        'focusAreas',
        'identities',
        'audiences',
        'departments',
        'partner',
        'careerAcademicInterests'
    ],

    directives: { closable: handleOutsideClick },

    data() {
        return {
            hasActivityFeed: false,
            hasMentorConnect: false,
            hasEvents: false,
            hasPrograms: false,
            hasResourceLibrary: false,
            canCreateAdmin: false,
            isManualAddModalActive: false,
            roleService: null,
            selectedRole: null,
            roles:[],

            error: "",

            isNotificationActive: false,
            notification: { msg: '', isError: false, icon: null },

            isResourceModalActive: false,
            activeResource: false,
            isDeleteModalActive: false,
            isPostModalActive: false,
            showFileDialog: false,
            files: [],
            isLoadingUpload:false,
            postText: "",

            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
                Accept: 'application/json'
            },
            service: null,
            department_name: "",

            optionsDropdownVisible: false,

            isFileModalActive: false,
            filterData: { focus_areas: this.focusAreas, identities: this.identities, audiences: this.audiences, career_academic_interests: this.careerAcademicInterests },

            isVideoModalActive: false,

            isUrlModalActive: false,
            isLoading: false,
        };
    },

    created() {
        if (this.allowedFeatures.includes('activity_feed')) this.hasActivityFeed = true;
        if (this.allowedFeatures.includes('appointments')) this.hasMentorConnect = true;
        if (this.allowedFeatures.includes('events')) this.hasEvents = true;
        if (this.allowedFeatures.includes('programs')) this.hasPrograms = true;
        if (this.allowedFeatures.includes('resource_library')) this.hasResourceLibrary = true;
        if (this.isAdmin) this.canCreateAdmin = true;
        if (this.loggedUser) this.department_name = this.loggedUser.department_name;

        this.bulkService = new BulkUploadService();
        this.roleService = new RoleService();
        this.service = new FeedService();
        this.resourceLibraryService = new ResourceLibraryService();
        this.getRoles();

        if (isPartnerTenant()){
          this.filterData.countries = this.filterData.focus_areas.filter(fa => fa.type === 'destinations');
          this.filterData.focus_areas = this.filterData.focus_areas.filter(fa => fa.type !== 'destinations');
        }
    },

    computed: {
        hasAnyQuickFeature(): boolean {
            return (
                this.hasActivityFeed ||
                this.hasMentorConnect ||
                this.hasEvents ||
                this.hasPrograms ||
                this.hasResourceLibrary ||
                this.canCreateAdmin
            )
        },
    },

    methods: {
        isPartnerTenant,
        setReturnLink() {
          sessionStorage.setItem('redirectTo', '/');
        },

        showResourceDropDown() {
          this.optionsDropdownVisible = true;
          focusElement('open-upload-file-modal-btn', 50);
        },

        closeResourceDropDown() {
          this.optionsDropdownVisible = false;
        },

        async getRoles() {
          this.roles = await this.roleService.getRoles({showCustom: false});
        },

        openFileModal() {
          this.isFileModalActive = true;
          focusElement('upload-file-modal-title', 50)
          this.closeResourceDropDown();
        },

        closeFileModal() {
          this.isFileModalActive = false;
          focusElement('quick-actions-btn', 100);
        },

        openVideoModal() {
          this.isVideoModalActive = true;
          focusElement('upload-video-modal-title', 50)
          this.closeResourceDropDown();
        },

        closeVideoModal() {
          this.isVideoModalActive = false;
          focusElement('quick-actions-btn', 100);
        },

        openUrlModal() {
          this.isUrlModalActive = true;
          focusElement('upload-url-modal-title', 50)
          this.closeResourceDropDown();
        },

        closeUrlModal() {
          this.isUrlModalActive = false;
          focusElement('quick-actions-btn', 100);
        },

        showNotification(notification) {
            this.isNotificationActive = true;
            this.notification = notification;
        },

        closeNotification() {
            this.isNotificationActive = false;
        },

        toggleQuickActionMenu() {
            const burgerEl = document.querySelector('#quick-actions-burger');
            if (burgerEl)
              burgerEl.classList.toggle('eu-resource-burger-active');
        },

        closeQuickActionMenu() {
            const burgerEl = document.querySelector('#quick-actions-burger');
            if (burgerEl && burgerEl.classList.contains('eu-resource-burger-active')) {
                burgerEl.classList.remove('eu-resource-burger-active');
            }
        },

        createAdmin() {
          this.isManualAddModalActive = true;
          focusElement('modal_manual_add_student', 50);
          this.roles.forEach(role => {
            if (role.name=="Admin") {
              this.selectedRole = role;
            }
          });
        },

        createMentor() {
          this.isManualAddModalActive = true;
          focusElement('modal_manual_add_student', 50);
          this.roles.forEach(role => {
            if (role.name=="Mentor") {
              this.selectedRole = role;
            }
          });
        },

        async addUser(params) {
          this.isManualAddModalActive = false;
          this.selectedRole = null;

          const result = await manualImportUser(params, this.bulkService, this.headers)

          if(result) {
            if(result.status === 'success') {
                this.showNotification({ msg: `${params.studentObject.role} successfully created.`, isError: false, icon: 'fad fa-check-circle' });
            }
            if(result.status === 'error') {
                this.showNotification({ msg: result.message, isError: true, icon: 'fad fa-exclamation-circle' });
            }
          } else {
            this.showNotification({ msg: "Oops, something went wrong. Check your inputs and try again.", isError: true, icon: 'fad fa-exclamation-circle' });
          }
        },

        openPostModal(){
          this.isPostModalActive = true;
          focusElement('create-post-title', 50);
        },

        closeManualModal() {
          this.isManualAddModalActive = false;
          this.selectedRole = null;
          focusElement('quick-actions-btn', 100);
        },

        updatePostText(text) {
          this.postText = text;
        },

        handleFileUpload(event) {
          const uploadFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
          const imageExt = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
          const docExt = ['txt', 'pdf', 'doc', 'docx', 'xlsx', 'ppt', 'pptx'];
          if (!uploadFiles.length) return;
          for (let i = 0; i < uploadFiles.length; i++) {
            this.error = '';
            let file = {
              resource_url: URL.createObjectURL(uploadFiles[i]),
              file: uploadFiles[i],
              name: uploadFiles[i].name,
              resource_type: null,
              department: '',
              status: 'pending',
              error: null
            };
            if (validateExtensionFile(uploadFiles[i].name, imageExt)) {
              file.resource_type = 'IMG';
            } else if (validateExtensionFile(uploadFiles[i].name, docExt)) {
              file.resource_type = 'DOC';
            } else {
              this.error = 'Please use a supported image filetype.';
              return;
            }

            if (!this.files.some(e => e.name === file.name)) {
              this.files.push(file);
            } else {
              this.error = `File with name '${file.name}' already exists.`;
              return;
            }
          }
        },

        async submitFiles(saveToRL): Promise<void> {
          const uploadedFiles = [];
          if (this.files.length == 0) {
            this.closePostModal();
          }
          this.files.forEach(async file => {
            if (file.name.length > 0) {
              file.status = 'uploading';
              if(this.currentDepartment) file.department = this.currentDepartment;
              let res;
              if(saveToRL) {
                file['file_type'] = file.resource_type;
                res = await this.resourceLibraryService.postFormData(file, this.headers);
              }
              else {
                res = await this.service.uploadFile(file, this.headers);
              }
              if (res.success) {
                uploadedFiles.push(res.resource);
                file.status = 'uploaded';
                this.files = this.files.filter(e => e.name !== file.name);
                if (this.files.length == 0) {
                  this.loading = false;
                  this.createPost(saveToRL, true, uploadedFiles);
                }
              } else {
                file.status = 'failed';
              }
            } else {
              this.showNotification({ msg: `Upload failed for file ${file.name}: Required file details missing.`, isError: true, icon: 'fad fa-exclamation-circle' });
            }
          });
        },

        createPostCheck() {
          if(this.files.length > 0) {
            this.showFileDialog = true;
            focusElement("add-to-library-btn", 50);
          } else this.createPost(false, true);
        },

        async createPost(saveToRL, createFinalPost, uploadedFiles) {
          if(this.loading) return;
          this.loading = true;
          if (this.postText || this.files.length > 0 || createFinalPost) {
            const reqObj = { text: this.postText, department: this.department_name };
            if(this.files.length > 0 && !createFinalPost) {
                this.isLoadingUpload = true;
                this.submitFiles(saveToRL);
                return;
            }
            if(createFinalPost) {
              if(uploadedFiles) {
                if(saveToRL) reqObj['files'] = uploadedFiles.map(e => e.id);
                else reqObj['files'] = uploadedFiles;
              }
              const res = await this.service.createPost(reqObj, this.headers);
              if (res?.success) {
                this.closePostModal();
                this.isLoadingUpload = false;
                this.showNotification({ msg: 'New post created successfully!', icon: 'fad fa-check-circle' });
                this.postText = '';
              } else {
                this.showNotification({ msg: 'Server error!', isError: true, icon: 'fad fa-exclamation-circle' });
              }
            }
          }
          this.loading = false;
        },

        closePostModal(){
          this.isPostModalActive = false;
          this.postText = '';
          this.files = [];
          this.showFileDialog = false;
          focusElement('quick-actions-btn', 100);
        },

        closeResourceModal() {
          this.isResourceModalActive = false;
          this.activeResource = { name: null, resource_url: null, description: null };
          focusElement('quick-actions-btn', 100);
        },
    },
}
</script>
