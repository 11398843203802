import { useAuthStore } from "./stores/auth";
import { useLicenseStore } from "./stores/license";


//DO we need this module? right now all the functions below can be placed
//in stores. Are there going to be any more complex checks?

export const isLoggedIn = () => {
  //it might be better if we use session_id instead of user
  const authStore = useAuthStore();
  const loggedInUser = authStore.getUser;

  if (loggedInUser) {
    return true;
  }
  return false;
}

export const isFeatureEnabled = async (featureName) => {
  const licenseStore = useLicenseStore();
  const authStore = useAuthStore();
  const isAdmin = authStore.getIsAdmin;

  if (licenseStore.isFeatureEnabled(featureName, isAdmin)) {
    return true;
  }

  return false;
}
