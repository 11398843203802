export enum StepTypes {
    ACTIVE = 'ACTIVE',
    UNANSWERED = 'UNANSWERED',
    COMPLETED = 'COMPLETED'
}

export enum StepNames {
    PROFILE = 'Your Profile',
    BACKGROUND = 'My Background',
    EDUCATION = 'My Education',
    CAREER = 'My Career Goals & Experience',
    INTERESTS = 'My Interests'
}

export function checkIsFormValid(stepName, stepNo, obj) {
    if(stepName === StepNames.PROFILE) {
        if(stepNo === 0) {
            return obj['user']['first_name']?.length > 0 && obj['user']['last_name']?.length > 0;
        }
        else if(stepNo === 1) {
            if(obj['about_me']) {
                return obj['about_me']?.length <= 200;
            } return true;
        }
        else if(stepNo === 2) {
            return true;
        }
        else if(stepNo === 3) {
            return true;
        }
    }
    // all other StepNames do not have required fields or validations like char length/regex etc.
    else return true;
};

export function checkIsFormCompleted(stepName, stepNo, obj) {
    if(stepName === StepNames.PROFILE) {
        if(stepNo === 0) {
            return true;
        }
        else if(stepNo === 1) {
            return obj['about_me']?.length > 0;
        }
        else if(stepNo === 2) {
            return obj['profile_pic']?.length > 0;
        }
        else if(stepNo === 3) {
            return obj['dontShareSocials'] || obj['website']?.length > 0;
        }
    }
    else if(stepName === StepNames.BACKGROUND) {
        if(stepNo === 0) {
            return obj['first_generation_student'] !== null || obj['first_generation_student_answered'];
        }
        else if(stepNo === 1) {
            return obj['pell_grant_recipient'] !== null || obj['pell_grant_recipient_answered'];
        }
        else if(stepNo === 2) {
            return !!obj['gender'];
        }
        else if(stepNo === 3) {
            return !!obj['ethnicity'];
        }
        else if(stepNo === 4) {
            return obj['differently_abled'] !== null || obj['differently_abled_answered'];
        }
    }
    else if(stepName === StepNames.EDUCATION) {
        if(stepNo === 0) {
            return obj['major_field_of_study'].length > 0;
        }
        else if(stepNo === 1) {
            return !!obj['minor_field_of_study'];
        }
        else if(stepNo === 2) {
            return !!obj['graduation_year'];
        }
        else if(stepNo === 3) {
            return !!obj['fluent_languages'];
        }
    }
    else if(stepName === StepNames.CAREER) {
        if(stepNo === 0) {
            return obj['graduate_school_plans'] !== null || obj['graduate_school_plans_answered'];
        }
        else if(stepNo === 1) {
            return !!obj['current_industry'];
        }
        else if(stepNo === 2) {
            return !!obj['career_interests'];
        }
    }
    else if(stepName === StepNames.INTERESTS) {
        if(stepNo === 0) {
            return obj['user_interests'].length > 0;
        }
    }
    else return true;
};