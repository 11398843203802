<template>
  <div class="partner-tenant-overall-container">
    <partner-tenant-overall-card
      :label="cards_data.upcoming_opportunities.label"
      :number="cards_data.upcoming_opportunities.count"
      icon="fa-solid fa-calendar-star"
      iconColor="#ffa800"
      :bgImage="`${ASSETS_PATH}assets/focus_areas/career-development.jpg`"
      title="Upcoming opportunities"
    />
    <partner-tenant-overall-card
      :label="cards_data.saved_articles.label"
      :number="cards_data.saved_articles.count"
      icon="fa-solid fa-messages fa-swap-opacity"
      iconColor="#f6764d"
      :bgImage="`${ASSETS_PATH}assets/focus_areas/fellowships.jpg`"
      title="Saved articles"
    />
    <partner-tenant-overall-card
      :label="cards_data.partners.label"
      :number="cards_data.partners.count"
      icon="fa-solid fa-folder-image fa-swap-opacity"
      iconColor="#8673fd"
      :bgImage="`${ASSETS_PATH}assets/focus_areas/tutoring.jpg`"
      title="Partners"
    />
    <partner-tenant-overall-card
      :label="cards_data.saved_guides.label"
      :number="cards_data.saved_guides.count"
      icon="fa-solid fa-book-open-reader"
      iconColor="#45b298"
      :bgImage="`${ASSETS_PATH}assets/focus_areas/community-service.jpg`"
      title="Saved guides"
    />
  </div>
</template>
<script>
import PartnerTenantOverallCard from './PartnerTenantOverallCard.vue';
import PartnerDashboardService from '../../services/PartnerDashboardService';
import * as Cookies from 'js-cookie';

export default {
  name: 'partner-tenant-overall',
  components: { PartnerTenantOverallCard },
  props: [

  ],
  data() {
    return {
      cards_data: { upcoming_opportunities: {label: "", count: 0},
              saved_articles: {label: "", count: 0},
              partners: {label: "", count: 0},
              saved_guides: {label: "", count: 0}
      },
      ASSETS_PATH: process.env.MEDIA_URL,
      partnerDashboardService: new PartnerDashboardService(),
      headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
          Accept: "application/json",
      },
    }
  },
  created() {
    this.partnerDashboardService.getOverallData().then(data => {
      this.cards_data = data;
    });
  },
  methods: {

  },
  computed: {}
}
</script>
