<template>
  <header role="banner" class="io-top-navbar" id="io-top-nav">
    <a href="/" class="eu-logo-navbar" :style="`background-image: url(${logo})`"></a>
    <button
      ref="faqBtn"
      role="button"
      @click="toggleMenu('isFAQOpened')"
      aria-label="Toggle help and FAQ"
      class="top-navbar-widget-button"
      title="FAQ"
    >
      <i class="fa-regular fa-circle-info"></i>
    </button>
    <button
      ref="calendarBtn"
      role="button"
      @click="toggleMenu('isCalendarOpened')"
      aria-label="Toggle calendar"
      class="top-navbar-widget-button"
      title="Calendar"
    >
      <i class="fa-swap-opacity fa-duotone fa-calendar-range"></i>
    </button>
    <button
      v-if="availableFeatures.activity_feed"
      ref="feedWidgetBtn"
      aria-label="Show/hide campus360 widget"
      @click="toggleFeedWidget"
      class="top-navbar-widget-button"
      title="Feed Updates"
    >
      <i class="fa-regular fa-bullhorn"></i>
    </button>
    <button
      v-if="availableFeatures.quests"
      ref="tasksWidgetBtn"
      aria-label="Show/hide tasks widget"
      @click="toggleTasksWidget"
      class="top-navbar-widget-button"
      title="Quests Updates"
    >
      <i class="fa-regular fa-flag-swallowtail"></i>
    </button>
    <div class="io-top-navbar-notification-button">
      <button
        ref="notificationBtn"
        role="button"
        @click="toggleMenu('isNotificationOpened')"
        aria-label="Toggle notifications"
        class="top-navbar-widget-button"
        title="Notifications"
      >
        <i class="fad fa-swap-opacity fa-bell"></i>
      </button>
      <i v-if="isNewNotificationCircleVisible" class="fas fa-circle io-new-notification-circle"></i>
    </div>
    <img
      id="profileBtn"
      alt="Your profile picture"
      ref="profileBtn"
      role="button"
      @click="toggleMenu('isProfileOpened')"
      @keyup.enter="toggleMenu('isProfileOpened')"
      tabindex="0"
      aria-label="Toggle profile"
      :src="profileImagePath"
      class="io-profile-picture"
      title="Profile"
    />

    <Transition name="slide-widget">
      <top-navbar-faq
        v-show="isFAQOpened"
        ref="faqDropdown"
        :user_role="loggedUser.user.role"
        v-closableFaq="{
          exclude: ['faqBtn', 'faqDropdown', 'contactUsBackButton', 'contactUsSubmitButton'],
          handler: 'closeFaqModal',
        }"
        @closeFaqModal="closeFaqModal"
      />
    </Transition>
    <Transition name="slide-widget">
      <top-navbar-feed
        v-show="isFeedWidgetActive && availableFeatures.activity_feed"
        v-closableFeedWidget="{ exclude: ['feedWidgetBtn'], handler: 'closeFeedWidget' }"
      />
    </Transition>
    <Transition name="slide-widget">
      <top-navbar-tasks
        v-show="isTasksWidgetActive && availableFeatures.quests"
        v-closableTasksWidget="{ exclude: ['tasksWidgetBtn'], handler: 'closeTasksWidget' }"
      />
    </Transition>
    <top-navbar-profile
      v-if="isProfileOpened"
      ref="profileDropdown"
      :loggedUser="loggedUser"
      :profileImagePath="profileImagePath"
      v-closableProfile="{ exclude: ['profileBtn', 'profileDropdown'], handler: 'closeProfile' }"
    />
    <Transition name="slide-widget">
      <top-navbar-notifications
        ref="notificationDropdown"
        v-if="isNotificationOpened"
        v-closableNotifications="{ exclude: ['notificationBtn', 'notificationDropdown'], handler: 'closeNotifications' }"
        :notification_list="notification_list"
        :loggedUser="loggedUser.user"
        @closeNotificationsDropdown="closeNotificationsDropdown"
        @noNewNotifications="hideNewNotificationCircle"
        @readNotification="readNotification"
        @deleteAllNotifications="deleteAllNotifications"
      />
    </Transition>
    <top-navbar-calendar
      :isCalendarOpened="isCalendarOpened"
      ref="calendarDropdown"
      v-closableCalendar="{ exclude: ['calendarBtn', 'calendarDropdown'], handler: 'closeCalendar' }"
    />
  </header>
</template>
<script lang="ts">
import handleOutsideClick from '../../directives/handleOutsideClick';
import TopNavbarFaq from './TopNavbarFAQ.vue';
import TopNavbarProfile from './TopNavbarProfile.vue';
import TopNavbarCalendar from './TopNavbarCalendar.vue';
import TopNavbarNotifications from './TopNavbarNotifications.vue';
import TopNavbarFeed from './FeedWidget/TopNavbarFeed.vue';
import NotificationService from '../../services/NotificationService';
import * as Cookies from 'js-cookie';
import TopNavbarTasks from './TasksWidget/TopNavbarTasks.vue';
import DashboardService from '../../dashboard/services/DashboardService';

export default {
  name: 'top-navbar',
  props: {},
  components: {
    TopNavbarProfile,
    TopNavbarCalendar,
    TopNavbarNotifications,
    TopNavbarFaq,
    TopNavbarFeed,
    TopNavbarTasks,
  },
  directives: {
    closableProfile: handleOutsideClick,
    closableCalendar: handleOutsideClick,
    closableNotifications: handleOutsideClick,
    closableFaq: handleOutsideClick,
    closableFeedWidget: handleOutsideClick,
    closableTasksWidget: handleOutsideClick,
  },
  data() {
    return {
      isFeedWidgetActive: false,
      isTasksWidgetActive: false,
      loggedUser: null,
      isFAQOpened: false,
      isProfileOpened: false,
      isNotificationOpened: false,
      isCalendarOpened: false,
      prevScrollpos: 0,
      isNewNotificationCircleVisible: false,
      notification_list: [],
      logo: '',
      availableFeatures: [],
      dashboardService: new DashboardService(),
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },
  async created() {
    this.getAvailableFeatures();
    this.logo = this.getLogo();
    window.addEventListener('scroll', this.hideNavbarOnScroll);
    //this.hideNavbarOnScroll();
    this.getLoggedUserData();

    this.notificationService = new NotificationService();

    const notification_list_response = await this.notificationService.get();

    this.notification_list = notification_list_response.notification_list;
    if (this.notification_list.length > 0 && this.isThereNewNotifications()) {
      this.isNewNotificationCircleVisible = true;
    } else {
      this.isNewNotificationCircleVisible = false;
    }

  },
  destroyed() {
    window.removeEventListener('scroll', this.hideNavbarOnScroll);
  },
  mounted() {},
  methods: {
    async getAvailableFeatures() {
      const filtered_permissions = await this.dashboardService.getAvailableFeaturesFromLicense();
      this.availableFeatures = filtered_permissions.reduce((acc, item) => {
        acc[item.value] = item.isActive;
        return acc;
      }, {});
    },
    closeTasksWidget() {
      this.isTasksWidgetActive = false;
    },
    toggleTasksWidget() {
      this.isTasksWidgetActive = !this.isTasksWidgetActive;
    },
    closeFeedWidget() {
      this.isFeedWidgetActive = false;
    },
    toggleFeedWidget() {
      this.isFeedWidgetActive = !this.isFeedWidgetActive;
    },
    isFeatureEnabled(feature) {
      return true;
    },
    toggleMenu(menu) {
      const prevState = this[menu];
      this.closeAllMenus();
      this[menu] = !prevState;
    },
    closeFaqModal() {
      this.isFAQOpened = false;
    },
    closeCalendar() {
      this.isCalendarOpened = false;
    },
    closeProfile() {
      this.isProfileOpened = false;
    },
    closeNotifications() {
      this.isNotificationOpened = false;
    },
    closeAllMenus() {
      this.isNotificationOpened = false;
      this.isCalendarOpened = false;
      this.isProfileOpened = false;
      this.isFAQOpened = false;
    },
    getLoggedUserData() {
      const userStatusDataComponent: HTMLElement = document.querySelector('.js-status-data');
      this.loggedUser = JSON.parse(userStatusDataComponent.dataset.userStatus);
    },
    hideNavbarOnScroll() {
      if (
        this.isFAQOpened ||
        this.isCalendarOpened ||
        this.isProfileOpened ||
        this.isNotificationOpened ||
        this.isFeedWidgetActive ||
        this.isTasksWidgetActive
      ) {
        return;
      }

      let currentScrollPos = window.pageYOffset;

      if (this.prevScrollpos > currentScrollPos || currentScrollPos <= 200) {
        document.getElementById('io-top-nav').style.top = '0';
      } else {
        document.getElementById('io-top-nav').style.top = '-60px';
      }
      this.prevScrollpos = currentScrollPos;
    },
    closeNotificationsDropdown() {
      this.isNotificationOpened = false;
    },
    isThereNewNotifications() {
      return this.notification_list.filter((notification) => notification.is_seen == false).length != 0;
    },
    hideNewNotificationCircle() {
      this.isNewNotificationCircleVisible = false;
      this.notificationService.put({ action: 'seen' }, this.headers);
    },
    readNotification(notification_id) {
      this.notification_list.find((notification) => notification.id == notification_id).is_read = true;
      this.notificationService.put({ notification_id: notification_id, action: 'read' }, this.headers);
    },
    showNotificationCircle() {
      this.isNewNotificationCircleVisible = true;
    },
    deleteAllNotifications(notification_ids) {
      this.notificationService.put({ notification_ids: notification_ids, action: 'delete' }, this.headers);
      this.notification_list = [];
    },
    getLogo() {
      const logoUrlComponent: HTMLElement = document.querySelector('.js-logo-url');
      const logoUrl = logoUrlComponent.dataset.logoUrl;
      if (logoUrl === 'None' || logoUrl === '' || !logoUrl) {
        return process.env.MEDIA_URL + 'assets/engageup-logo.svg';
      }
      return logoUrl;
    },
  },
  computed: {
    profileImagePath() {
      let image_path = '';

      // not logged in
      if (!this.loggedUser) {
        return '';
      }

      if (this.loggedUser.account_type == 'Partner') {
        if (!this.loggedUser.partner.profile_image || this.loggedUser.partner.profile_image == '') {
          image_path = process.env.MEDIA_URL + 'assets/icons/org_icon.svg';
        } else {
          image_path = this.loggedUser.partner.profile_image;
        }
      } else {
        if (!this.loggedUser.user.profile_image || this.loggedUser.user.profile_image == '') {
          image_path = '/static/assets/icons/default-profile.svg';
        } else {
          image_path = this.loggedUser.user.profile_image;
        }
      }

      return image_path;
    },
  },
};
</script>

<style scope lang="scss">
.slide-widget-enter-active,
.slide-widget-leave-active {
  transition: 0.5s;
}

.slide-widget-enter,
.slide-widget-leave-to {
  transform: translateX(100%);
}

.top-navbar-widget-button {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  font-size: 22px;
  color: #b4b4b4;

  &:hover {
    color: #22a5a0;
    cursor: pointer;
  }
}
</style>
