<template>
  <section tabindex="0" class="item-card">
    <div
      class="top-part"
      :style="
        cardImageUrl
          ? `background: linear-gradient(to bottom, #000 4%, rgba(0, 0, 0, 0) 57%), url(${cardImageUrl}) no-repeat center center / cover;`
          : 'background: #f1f1f9;'
      "
    >
      <span
        tabindex="0"
        v-if="!cardImageUrl"
        @click="viewItem()"
        @keyup.enter="viewItem()"
        :aria-label="`Open ${item.name} in modal`"
        aria-haspopup="dialog"
        role="button"
        style="cursor: pointer"
        class="resource-type-icon-wrapper"
      >
        <i :class="cardFaIconClass" class="file-icon-grid"></i>
      </span>

      <div class="toolbar">
        <div class="left"><div class="type-label">Resource</div></div>
        <div class="right">
          <i
            class="fa-bookmark"
            tabindex="0"
            :class="`${item.is_bookmarked ? 'active fas' : 'far'}`"
            :title="`${item.is_bookmarked ? 'Remove bookmark' : 'Bookmark item'}`"
            :style="`${item.resource_type !== 'IMG' ? 'color: #88858e' : ''}`"
            @click="handleBookmark()"
            @keyup.enter="handleBookmark()"
            aria-label="Toggle bookmark for item"
            role="button"
          ></i>
          <i
            class="fas fa-ellipsis-v"
            tabindex="0"
            ref="resListBurger"
            :style="`${item.resource_type !== 'IMG' ? 'color: #88858e' : ''}`"
            @click="showBurger()"
            @keyup.enter="showBurger()"
            aria-label="Burger menu"
            role="menu"
            aria-haspopup="menu"
          >
          </i>
          <div
            ref="resListBurgerDropdown"
            :id="`res${item.id}`"
            v-closable="{ exclude: ['resListBurgerDropdown', 'resListBurger'], handler: 'closeBurger' }"
            class="inup-profile-row-burger dashboard-burger-pos"
          >
            <span
              @click="viewItem()"
              @keyup.enter="viewItem()"
              tabindex="0"
              :aria-label="`Open ${item.name}`"
              aria-haspopup="dialog"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-eye" style="padding-right: 5px; color: #88858e"></i>
              <span>View</span>
            </span>
            <span
              v-if="
                item.resource_type != 'VID' &&
                item.resource_type != 'URL' &&
                item.resource_type != 'URL_INTERNAL' &&
                item.resource_type != 'URL_EXTERNAL'
              "
              @click="downloadItem(item.resource_url, item.name, item.id)"
              @keyup.enter="downloadItem(item.resource_url, item.name, item.id)"
              tabindex="0"
              :aria-label="`Download ${item.name}`"
              aria-haspopup="dialog"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-download" style="padding-right: 5px; color: #88858e"></i>
              <span>Download</span>
            </span>
          </div>
        </div>
      </div>
      <div v-if="item.is_featured || item.is_featured_for_audience" class="featured">
        <i class="fas fa-stars"></i>
        FEATURED
      </div>
    </div>
    <div class="bottom-part">
      <div class="content">
        <div @click="viewItem()" @keyup.enter="viewItem()" tabindex="0" class="title text-clamp-1">{{ item.name }}</div>
        <div class="desc text-clamp-4">
          {{ item.description }}
        </div>
        <div class="item-card-footer" style="margin-top: 6px">
          <span class="item-card-footer-wrapper between">
            <i v-if="item.resource_type === 'VID'" class="far fa-file-video"></i>
            <i v-if="item.resource_type === 'URL'" class="far fa-external-link"></i>
            <i v-if="item.resource_type === 'DOC'" class="far fa-file-alt"></i>
            <i v-if="item.resource_type === 'IMG'" class="far fa-image"></i>
            <p v-if="item.department" class="text-clamp-1">Posted by {{ item.department.name }}</p>
          </span>
        </div>
      </div>
    </div>
    <resource-modal
      :isResourceModalActive="isModalActive"
      :activeResource="item"
      @close-resource-modal="closeResourceModal"
    />
  </section>
</template>
<script lang="ts">
import handleOutsideClick from "../../directives/handleOutsideClick";
import ResourceBookmarkService from "../../library/services/ResourceBookmarkService";
import ResourceLibraryService from "../../library/services/ResourceLibraryService";
import { focusElement, getYoutubeOrVimeoEmbedLink } from "../../utils/CustomUtils";
import Cookies from "js-cookie";
import ResourceModal from './modals/ResourceModal.vue';

export default {
  directives: { closable: handleOutsideClick },
  components: { ResourceModal },
  name: "resource-card",
  props: ["item"],
  data() {
    return {
      lastOpenedBurger: null,
      assetPath: process.env.MEDIA_URL,
      isModalActive: false,
      bookmarkService: new ResourceBookmarkService(),
      resourcesService: new ResourceLibraryService(),
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  async created() {},
  computed: {
    cardImageUrl() {
      if (this.item.resource_type === 'IMG') {
        return this.item.resource_url;
      }
      if (this.item.thumbnail_url) {
        return this.item.thumbnail_url;
      }
      return null;
    },
    cardFaIconClass() {
      if (this.item.resource_type === 'DOC') {
        return 'fa-regular fa-file-alt';
      }
      if (this.item.resource_type === 'VID') {
        return 'far fa-file-video';
      }
      if (this.item.resource_type === 'URL') {
        return 'far fa-external-link';
      }
      if (this.item.resource_type === 'URL_INTERNAL') {
        return 'far fa-external-link';
      }
      if (this.item.resource_type === 'URL_EXTERNAL') {
        return 'far fa-external-link';
      }
    },
  },
  methods: {
    getYoutubeOrVimeoEmbedLink,
    showBurger() {
      const burgerEl = document.querySelector(`#res${this.item.id}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl) {
        this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
      }
      burgerEl.classList.toggle("eu-resource-burger-active");
      this.lastOpenedBurger = burgerEl;
    },
    closeBurger() {
      this.lastOpenedBurger && this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
    },
    async handleBookmark() {
      if (this.item.is_bookmarked) {
        await this.bookmarkService.delete({ target: this.item.id }, this.headers);
      } else {
        await this.bookmarkService.post({ target: this.item.id }, this.headers);
      }

      this.item.is_bookmarked = !this.item.is_bookmarked;
    },
    viewItem() {
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      sidebar.style.display = "none";
      this.isModalActive = true;
      this.resourcesService.increaseViews(this.item.id)
      this.closeBurger();
      focusElement("resource-header");
    },

    closeResourceModal() {
      this.isModalActive = false;
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      sidebar.style.display = "block";
      focusElement(`${this.idPrefix}${this.item.id}-options`);
    },

    async downloadItem(dataurl: string, filename: string, id) {
      const res = await fetch(dataurl);
      const blob = await res.blob();
      this.startDownload(blob, filename);
      this.resourcesService.increaseDownloaded(id);
      this.closeBurger();
    },

    startDownload(content, filename) {
      var a = document.createElement("a");
      a.download = filename ? filename : "resources";
      document.body.appendChild(a);
      a.href = URL.createObjectURL(content);
      a.style.display = "none";
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style lang="scss" scoped>
.partner-tenant-all-features-tab-container .content-cell .title {
  height: unset;
}
</style>
