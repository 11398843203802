<template>
<section class="request-a-demo-section">
  <div class="request-a-demo-desktop-content-container">
    <div class="white-tint"></div>
    <div class="text-container">
      <h2 class="title">
        Join leading institutions that
        leverage Includifi to support
        first-gen success
      </h2>
      <p class="description">
        Equip your first-gen students with a
        one-stop digital hub to access curated
        content and opportunities for navigating
        their college experience. During the Includifi
        demo we will:
      </p>
      <div v-for="item, index in items_list" :key="index" class="list-item-container">
        <i class="item-icon" :class="item.icon"></i>
        <span class="item-text">{{item.text}}</span>
      </div>
    </div>
    <div class="form-container desktop">
      <h4 class="title">Request a Demo</h4>
      <form class="form">
        <div class="two-inputs-container">
          <input-base
            label="First &amp; Last Name"
            id="name-input"
            wrapperClass="input-wrapper"
            aria-label="Enter first and last name"
            :required="true"
            v-model="demo_form.name"
            :isError="!!demo_form_error.name"
          />
          <input-base
            label="Email"
            id="email-input"
            type="email"
            wrapperClass="input-wrapper"
            aria-label="Enter email"
            :required="true"
            v-model="demo_form.email"
            :isError="!!demo_form_error.email"
          />
        </div>
        <div class="two-inputs-container">
          <input-base
            label="Phone"
            id="phone-input"
            wrapperClass="input-wrapper"
            aria-label="Enter phone number"
            :required="false"
            v-model="demo_form.phone"
            :isError="!!demo_form_error.phone"
          />
          <input-base
            label="Title"
            id="title-input"
            type="text"
            wrapperClass="input-wrapper"
            :required="true"
            v-model="demo_form.title"
            :isError="!!demo_form_error.title"
          />
        </div>
        <div class="two-inputs-container">
          <input-base
            label="Organization/University"
            id="organization-input"
            wrapperClass="input-wrapper"
            aria-label="Enter organization/university name"
            :required="true"
            v-model="demo_form.organization"
            :isError="!!demo_form_error.organization"
          />
          <input-base
            label="Department(optional)"
            id="title-input"
            type="text"
            wrapperClass="input-wrapper"
            :required="false"
            v-model="demo_form.department"
            :isError="!!demo_form_error.department"
          />
        </div>
        <div class="checkbox-area-container">
          <div class="title">Which Solutions Are You Interested In?</div>
          <div class="checkboxes-container">
            <div class="checkbox-wrapper">
              <input v-model="demo_form.outreach_recruitment" id="outreach_recruitment" class="checkbox" type="checkbox">
              <label class="label" for="outreach_recruitment">Recruitment &amp; Enrollment</label>
            </div>
            <div class="checkbox-wrapper">
              <input v-model="demo_form.new_student_programming" id="new_student_programming" class="checkbox" type="checkbox">
              <label class="label" for="new_student_programming">First Year Experiences</label>
            </div>
            <div class="checkbox-wrapper">
              <input v-model="demo_form.first_gen_belonging" id="first_gen_belonging" class="checkbox" type="checkbox">
              <label class="label" for="first_gen_belonging">Retention &amp; Success</label>
            </div>
          </div>
        </div>
        <div class="text-area-wrapper">
          <div class="label-wrapper">
            <label class="label" for="message">Message (optional)</label>
          </div>
          <textarea v-model="demo_form.message" name="message" id="message" rows="7" placeholder="Write your message..."
            class="text-area"
          ></textarea>
        </div>
        <div class="checkbox-wrapper align-top">
          <input v-model="demo_form.is_subscribed" id="outreach_recruitment" class="checkbox" type="checkbox">
          <label class="label small" for="outreach_recruitment">Yes, I’d like to receive marketing communications regarding products, services, and events.</label>
        </div>
        <div>
          <button class="iu-main-btn main small solid" @click.prevent="submitForm">
            Submit
            <i class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="mobile-form-section">
      <div class="form-container">
        <h4 class="title">Request a Demo</h4>
        <form class="form">
          <div class="two-inputs-container">
            <input-base
              label="First &amp; Last Name"
              id="name-input"
              wrapperClass="input-wrapper"
              aria-label="Enter first and last name"
              :required="true"
              v-model="demo_form.name"
              :isError="!!demo_form_error.name"
            />
            <input-base
              label="Email"
              id="email-input"
              type="email"
              wrapperClass="input-wrapper"
              aria-label="Enter email"
              :required="true"
              v-model="demo_form.email"
              :isError="!!demo_form_error.email"
            />
          </div>
          <div class="two-inputs-container">
            <input-base
              label="Phone"
              id="phone-input"
              wrapperClass="input-wrapper"
              aria-label="Enter phone number"
              :required="false"
              v-model="demo_form.phone"
              :isError="!!demo_form_error.phone"
            />
            <input-base
              label="Title"
              id="title-input"
              type="text"
              wrapperClass="input-wrapper"
              :required="true"
              v-model="demo_form.title"
              :isError="!!demo_form_error.title"
            />
          </div>
          <div class="two-inputs-container">
            <input-base
              label="Organization/University"
              id="organization-input"
              wrapperClass="input-wrapper"
              aria-label="Enter organization/university name"
              :required="true"
              v-model="demo_form.organization"
              :isError="!!demo_form_error.organization"
            />
            <input-base
              label="Department(optional)"
              id="title-input"
              type="text"
              wrapperClass="input-wrapper"
              :required="false"
              v-model="demo_form.department"
              :isError="!!demo_form_error.department"
            />
          </div>
          <div class="checkbox-area-container">
            <div class="title">Which Solutions Are You Interested In?</div>
            <div class="checkboxes-container">
              <div class="checkbox-wrapper">
                <input v-model="demo_form.outreach_recruitment" id="outreach_recruitment" class="checkbox" type="checkbox">
                <label class="label" for="outreach_recruitment">Recruitment &amp; Enrollment</label>
              </div>
              <div class="checkbox-wrapper">
                <input v-model="demo_form.new_student_programming" id="new_student_programming" class="checkbox" type="checkbox">
                <label class="label" for="new_student_programming">First Year Experiences</label>
              </div>
              <div class="checkbox-wrapper">
                <input v-model="demo_form.first_gen_belonging" id="first_gen_belonging" class="checkbox" type="checkbox">
                <label class="label" for="first_gen_belonging">Retention &amp; Success</label>
              </div>
            </div>
          </div>
          <div class="text-area-wrapper">
            <div class="label-wrapper">
              <label class="label" for="message">Message (optional)</label>
            </div>
            <textarea v-model="demo_form.message" name="message" id="message" rows="4" placeholder="Write your message..."
              class="text-area"
            ></textarea>
          </div>
          <div class="checkbox-wrapper align-top">
            <input v-model="demo_form.is_subscribed" id="outreach_recruitment" class="checkbox" type="checkbox">
            <label class="label small" for="outreach_recruitment">Yes, I’d like to receive marketing communications regarding products, services, and events.</label>
          </div>
          <div>
            <button :disabled="submit_button_disabled" class="iu-main-btn main small solid" @click.prevent="submitForm">
              Submit
              <i class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
            </button>
          </div>
        </form>
    </div>
  </div>
</section>
</template>

<script lang="ts">
import InputBase from '../components/formBase/InputBase.vue';
import PublicService from './PublicService';
import { isValidEmailString } from '../utils/CustomUtils';

export default {
  name: "request-a-demo",
  components: {
    InputBase
  },
  data() {
    return {
      public_service: new PublicService(),
      // notification_store: useNotificationStore(),
      submit_button_disabled: false,
      demo_form: {
        name: '',
        email: '',
        phone: '',
        title: '',
        organization: '',
        department: '',
        outreach_recruitment: false,
        new_student_programming: false,
        first_gen_belonging: false,
        message: '',
        is_subscribed: false,
      },
      demo_form_error: {
        name: '',
        email: '',
        phone: '',
        title: '',
        organization: '',
      },
      items_list: [
        {
          icon: 'fa-solid fa-list-check',
          text: 'Give an overview of Includifi’s solutions, JourneyU and Pathways',
        },
        {
          icon: 'fa-solid fa-messages',
          text: 'Discuss current support structure for first-gen students at your institution',
        },
        {
          icon: 'fa-solid fa-magnifying-glass-chart',
          text: 'Explore if Includifi’s solutions are a right fit for your first-gen students',
        },
        {
          icon: 'fa-solid fa-file-alt',
          text: 'Review our straightforward cost structure and give an overview of our onboarding process',
        },
      ],
    }
  },
  computed: {
    emailIsInvalid() {
      return this.demo_form.email != "" && !isValidEmailString(this.demo_form.email)
    }
  },
  methods: {
    validateForm() {
      let is_form_valid = true;
      if (!this.demo_form.name) {
        is_form_valid = false;
        this.demo_form_error.name = "This field is required.";
      } else {
        this.demo_form_error.name = "";
      }
      if (!this.demo_form.email) {
        is_form_valid = false;
        this.demo_form_error.email = "This field is required.";
      } else {
        if(this.emailIsInvalid) {
          is_form_valid = false;
          this.demo_form_error.email = "Email is not valid.";
        } else {
          this.demo_form_error.email = "";
        }
      }
      if (!this.demo_form.title) {
        is_form_valid = false;
        this.demo_form_error.title = "This field is required.";
      } else {
        this.demo_form_error.title = "";
      }
      if (!this.demo_form.organization) {
        is_form_valid = false;
        this.demo_form_error.organization = "This field is required.";
      } else {
        this.demo_form_error.organization = "";
      }

      return is_form_valid;
    },
    clearForm() {
      this.demo_form = {
        name: '',
        email: '',
        phone: '',
        title: '',
        organization: '',
        department: '',
        outreach_recruitment: false,
        new_student_programming: false,
        first_gen_belonging: false,
        message: '',
        is_subscribed: false,
      }
      this.demo_form_error = {
        name: '',
        email: '',
        phone: '',
        title: '',
        organization: '',
      }
    },
    async submitForm() {
      if (this.validateForm()) {
        this.submit_button_disabled = true;
        this.public_service.requestADemo(this.demo_form).then(
          data => {
            // this.notification_store.addNotification("A demo request successfully sent.", 10000);
            window.location.href = '/demo-success';
          }
        ).catch(error => {
            // this.notification_store.addNotification("Something went wrong. Please try again later or contact our support.", 10000);
        }).finally(() => {
          this.submit_button_disabled = false;
        });
      } else {
        // this.notification_store.addNotification(`Please fill all required inputs${this.emailIsInvalid ? ' and enter a valid email.' : '.'}`, 10000);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.request-a-demo-section {
  margin-top: 70px;

  .mobile-form-section {
    display: none;
  }

  .request-a-demo-desktop-content-container {
    width: 100%;
    min-height: 740px;
    padding: 82.5px 32px;
    display: flex;
    gap: 48px;
    align-items: center;
    justify-content: center;
    background-image: url("https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/request_a_demo/request-demo-bg.jpg");
    background-position: center;
    background-size: cover;
    position: relative;

    .white-tint {
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-width: 536px;
      position: relative;

      .title {
        color: #333333;
        font-size: 40px;
        line-height: 1.2;
        padding: 0;
      }

      .description {
        color: #444444;
        font-size: 24px;
        line-height: 1.45;
      }

      .list-item-container {
        display: flex;
        gap: 12px;

        .item-icon {
          color: #685df3;
          font-size: 20px;
          width: 25px;
          flex-shrink: 0;
          transform: translateY(6px);
        }

        .item-text {
          color: #444444;
          font-size: 20px;
          line-height: 1.5;
        }
      }
    }
  }

    .form-container {
      padding: 24px;
      width: 496px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.12);
      display: flex;
      flex-direction: column;
      gap: 32px;
      position: relative;

      .title {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 600;
        color: #000;
        padding: 0;
      }

      .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;

        .two-inputs-container {
          display: flex;
          width: 100%;
          gap: 12px;
        }

        .input-wrapper {
          width: 100%;
        }

        .checkbox-area-container {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .title {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.3;
            color: #333;
          }

          .checkboxes-container {
            display: flex;
            gap: 16px;
            row-gap: 8px;
            flex-wrap: wrap;
          }
        }
        .checkbox-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;

          &.align-top {
            align-items: flex-start;
          }

          .checkbox {
            border: 1.5px solid #817e87;
            background-color: #f1f1f9;
            height: 20px;
            width: 20px;
            border-radius: 2px;

            &:hover {
              cursor: pointer;
            }
          }
          .label {
            font-size: 16px;
            line-height: 1.35;
            color: #555;

            &.small {
              font-size: 14px;
            }
          }
        }

        .text-area-wrapper {
          position: relative;
          margin-top: 8px;

          .label-wrapper {
            position: absolute;
            top: -12px;
            left: 6px;
            background-color: #fff;
            padding: 0 6px;

            .label {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.15;
            }
          }

          .text-area {
            resize: none;
            height: 72px;
            padding: 0.625rem 0.875rem;
            border: solid 1.5px #95919b;
            border-radius: 6px;
            background-color: #fff;
            font-size: 1rem;
            width: 100%;
          }
        }
      }
    }
}

@media screen and (max-width: 1166px) {
  .request-a-demo-section {

    .request-a-demo-desktop-content-container {
      padding: 40px 64px 96px;
      min-height: unset;
      background-image: url('https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/request_a_demo/mobile-request-demo-bg.jpg');

      .text-container {
        max-width: 640px;

        .description {
          font-size: 20px;
          line-height: 1.36;
        }

        .list-item-container {

          .item-text {
            font-size: 18px;
            line-height: 1.45;
          }
        }
      }
      .form-container {
        &.desktop {
          display: none;
        }
      }
    }

    .mobile-form-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 64px;

      .form-container {
        max-width: 640px;
        width: 100%;
        transform: translateY(-64px);

        &.desktop {
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .request-a-demo-section {

    .request-a-demo-desktop-content-container {
      padding: 32px 24px;
    }

    .mobile-form-section {
      padding: 0;

      .form-container {
        transform: translateY(0);

        .form {

          .two-inputs-container {
            flex-direction: column;
          }
        }
      }
    }
  }
}
</style>
