// this should match PermissionTypes(Enum)
export const PERMISSION_TYPES = {
    READ: 0,
    READ_WRITE: 1,
    MANAGE: 2,
    MANAGE_WITH_GRANT: 3
}

export const FEATURES = {
    COURSES: 'courses',
    RESOURCE_LIBRARY: 'resource_library',
    BULK_UPLOAD: 'bulk_upload',
    EVENTS: 'events',
    PROGRAMS: 'programs',
    MENTOR_CONNECT: 'appointments',
    ACTIVITY_FEED: 'activity_feed',
}

export const LICENSE_ONLY_FEATURES = {
    GUIDES: 'guides',
    ONE_STOP: 'one_stop',
    PASSPORT: 'passport',
    DEPTS: 'depts',
    GROUPS: 'groups',
    ANALYTICS: 'analytics',
    RECOMMENDATIONS: 'recommendations',
    CAMPUS_APPS: 'campus_apps',
    VIDEO_CHAT_MEETINGS: 'video_chat_meetings',
    QUESTS: 'quests'
};

import { useLicenseStore } from '../stores/license';

/**
 * Checks if the user has the required permission for a specific feature.
 *
 * @param {string} feature - The feature to check permissions for (e.g., FEATURES.COURSES).
 * @param {number} requiredPermission - The minimum permission level required.
 * @returns {boolean} - True if the user has the required permission.
 */
export const hasPermission = (feature: string, requiredPermission = PERMISSION_TYPES.READ) => {
    const userStatusDataComponent: HTMLElement = document.querySelector('.js-status-data');
    const loggedUser = JSON.parse(userStatusDataComponent.dataset.userStatus);
    const isAdmin = loggedUser.user.role === 'ADMIN';
    const permissionsArray = loggedUser.permissions;
    const licenseStore = useLicenseStore();
    const licences = licenseStore.getLicenses;
    const permissionsMap = permissionsArray.reduce((acc, perm) => {
        const [key, value] = perm.split(':');
        acc[key] = Number(value);
        return acc;
    }, {});
    if (licences && !(licences.includes(feature))) {
        return false;
    }
    if(isAdmin) return true;
    if (!(feature in permissionsMap)) {
        return false;
    }

    // Check if the user's permission level meets the required permission level
    return permissionsMap[feature] >= requiredPermission;
};

export const isFeatureEnabled = (feature: string) => {
    const licenseStore = useLicenseStore();
    const licences = licenseStore.getLicenses;
    if (licences && !(licences.includes(feature))) {
        return false;
    } else return true;
}

