<template>
  <tr
    class="eu-resource-row iu-manage-partners-row"
    :class="isSelected ? 'eu-resource-row-selected': ''"
  >
    <td class="eu-resource-cell iu-table-small-cell">
      <span
        class="iu-manage-partners-row-arrow-button"
        aria-label="Toggle partner's managers"
        @click="togglePartnersManagersTable"
        @keyup.enter="togglePartnersManagersTable"
      >
        <i
          class="fa-solid"
          :class="partnersManagersExpanded ? 'fa-chevron-down' : 'fa-chevron-right'"
        ></i>
      </span>
    </td>
    <td class="eu-resource-cell iu-table-small-cell">
      <span
        tabindex="0"
        @click="togglePartner(partner.id)"
        @keyup.space="togglePartner(partner.id)"
        :aria-label="`Select ${partner.name}`"
        role="checkbox"
        style="margin-right: 0px;"
        class='eu-resource-cell-select'
        :class="isSelected ? 'eu-resource-cell-select-active' : ''"
      ></span>
    </td>
    <td class="eu-resource-cell iu-table-cell-image-name">
      <img
        :src="partner.logo_url ? partner.logo_url : '/static/assets/icons/default-profile.svg'"
        alt="profile img"
        style="width:32px;height:32px;top:unset;"
        class="iu-manage-partners-thumbnail"
      />
      {{partner.name}}
    </td>
    <td class="eu-resource-cell">

    </td>
    <td class="eu-resource-cell">
      {{managersCount}} manager{{isPlural ? 's' : ''}}
    </td>
    <td class="eu-resource-cell">
      {{partner.added}}
    </td>
    <td class="eu-resource-cell">
      <span class="iu-table-status-pill" :class="isActive ? 'active' : 'not-active'">
        <i class="fa-solid fa-circle" v-if="isActive"></i>
        {{isActive ? 'Active' : 'Not Active '}}
      </span>
    </td>
    <td class="eu-resource-cell iu-table-small-cell">
      <div class="iu-table-action-cell">
        <button
          class="iu-table-action-button"
          aria-label="Burger menu"
          :ref="'burgerMenuButton-' + partner.id"
          @click="toggleBurger"
        >
          <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
        </button>
        <partners-burger-menu
          v-if="burgerExpanded"
          :ref="'burgerMenu-' + partner.id"
          v-closable="{ exclude: [`burgerMenu-${partner.id}`, `burgerMenuButton-${partner.id}`], handler: 'closeBurger' }"
          @edit-partner="editPartner"
          @add-manager="addManager"
          @view-dashboard="viewDashboard"
          @delete-partner="deletePartner"
        ></partners-burger-menu>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import PartnersBurgerMenu from './PartnersBurgerMenu.vue';
import handleOutsideClick from '../../../directives/handleOutsideClick'

export default{
  name: 'partner-row',

  directives: { closable: handleOutsideClick },

  components: {
    PartnersBurgerMenu,
  },

  props: [
    'partner',
    'isSelected',
  ],

  computed: {
    isActive() {
      return this.partner.is_active;
    },
    managersCount() {
      const managerUsers = this.partner.partner_admins ? this.partner.partner_admins : [];
      const adminUsers = this.partner.partner_superadmins ? this.partner.partner_superadmins : [];

      const uniqueUserIds = new Set();
      managerUsers.forEach(user => uniqueUserIds.add(user.id));
      adminUsers.forEach(user => uniqueUserIds.add(user.id));
      return uniqueUserIds.size;
    },
    isPlural() {
      return this.managersCount > 1 || this.managerCount == 0;
    },
  },

  data() {
    return {
      partnersManagersExpanded: false,
      burgerExpanded: false,
    }
  },

  methods: {
    togglePartnersManagersTable() {
      this.partnersManagersExpanded = !this.partnersManagersExpanded;
      this.$emit('toggle-managers-table', this.partner.id);
    },
    togglePartner() {
      this.$emit('toggle-partner', this.partner.id);
    },
    toggleBurger() {
      this.burgerExpanded = !this.burgerExpanded;
    },
    closeBurger() {
      this.burgerExpanded = false;
    },
    editPartner() {
      this.$emit('edit-partner', this.partner.name);
    },
    addManager() {
      this.$emit('add-manager', this.partner.id);
    },
    viewDashboard() {
      this.$emit('view-dashboard', this.partner.name);
    },
    deletePartner() {
      this.$emit('delete-partner', this.partner);
    },
  }
}
</script>
