import HttpService from '../../services/HttpService';
import * as Cookies from 'js-cookie';

const headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class CoursesService extends HttpService {
  constructor() {
    super('/courses/api');
  }

  toggleCourseBookmark(item, headers) {
    this.endpoint = `/courses/api`;
    return this.postFormData(item, headers);
  }

  getCourses(params) {
    this.endpoint = `/courses/api`;
    return this.get(params);
  }

  setRecommended(data: { course_id: number }, _headers: Object) {
    this.endpoint = `/courses/api/recommended`;
    return this.post(data, headers);
  }

  removeRecommended(data: { course_id: number }, _headers: Object) {
    this.endpoint = `/courses/api/recommended`;
    return this.delete(data, headers);
  }

  syncData(schema:string, headers: Object) {
    this.endpoint = `/courses/api/sync`;
    return this.post({'schema':schema}, headers);
  }

  syncEnrolment(schema:string, headers: Object) {
    this.endpoint = `/courses/api/enrollment`;
    return this.post({'schema':schema}, headers);
  }
  update(item, headers) {
    this.endpoint = `/courses/api/${item.id}`;
    return this.put(item, headers);
  }

  getPredataCourses() {
    this.endpoint = `/courses/api/predata`;
    return this.get();
  }

  getPredataCourse(course_id) {
    this.endpoint = `/courses/api/predata/${course_id}`;
    return this.get();
  }

  getPredataCourseEdit(course_id) {
    this.endpoint = `/courses/api/predata/${course_id}/edit`;
    return this.get();
  }

  getPredataCoursesEdit() {
    this.endpoint = `/courses/api/predata/edit`;
    return this.get();
  }

  courseEnroll(course_id) {
    this.endpoint = `/courses/api/enroll`;
    return this.post({course_id}, headers);
  }

}
