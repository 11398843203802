import HttpService from './HttpService';

export default class ContactGroupService extends HttpService {
  constructor() {
    super("/blog/api/overview");
  }

  getBlogsOverview(): Promise<any> {
    this.endpoint = `/blog/api/overview`
    return this.get();
  }
}
