<template>
<Modal :isActiveProp="isModalActive" @close="closeModal">
  <div class='iu-profile-modal' style="padding: 32px;" v-if="user">
    <div class="iu-profile-modal-main-section">
      <div class="iu-profile-modal-info-container-left">
        <div class="iu-profile-modal-picture-name-container">
          <img class="iu-profile-modal-picture" :src="user.profile_pic ? user.profile_pic : '/static/assets/icons/default-profile.svg'" alt="User's profile picture" />
          <div id="profile_modal_title" tabindex="0" role="heading" class='iu-profile-modal-name'>{{user.first_name}} {{user.last_name}}</div>
        </div>
        <div class="iu-profile-modal-tags-container">
          <div v-if="isTagActive('MENTOR')" class="iu-profile-modal-tag mentor">
            Mentor
          </div>
          <div v-if="isTagActive('ALUM')" class="iu-profile-modal-tag alum">
            Alum
          </div>
        </div>
        <div class="iu-profile-modal-about-me-container">
          <div class='iu-profile-modal-about-me'>{{user.about_me}}</div>
        </div>
        <div v-if="user.department" class="iu-profile-modal-department-section">
          <div class="iu-profile-modal-section-title">
            <i class="fa-solid fa-building iu-profile-modal-department-icon"></i>Department
          </div>
          <div class="iu-profile-modal-department-container">
            <div class="iu-profile-modal-common-tag">
              {{user.department.name ? user.department.name : user.department}}
            </div>
          </div>
        </div>
      </div>
      <div class="iu-profile-modal-info-container-right">
        <div class="iu-profile-modal-focus-areas-section">
          <div class="iu-profile-modal-section-title">
             {{user.is_mentor? 'Mentor ' : ''}}Focus Areas
          </div>
          <div class="iu-profile-modal-focus-areas-container">
            <div v-for="fa of user.focus_areas" v-bind:key="fa.name" class="iu-profile-modal-common-tag">{{fa.name}}</div>
          </div>
        </div>
        <div v-if="user.role && user.role.is_mentor" class="iu-profile-modal-mentoring-hours-section">
          <div class="iu-profile-modal-section-title">
            Upcoming Mentoring Hours
          </div>
          <div class="iu-profile-modal-mentoring-hours-container">
            <div v-if="user.upcoming_hours">
              <div v-for="(hour, i) in user.upcoming_hours" v-bind:value="hour.startTime" v-bind:key="hour.startTime">
                  <div class="iu-profile-modal-time">
                    <div v-if="!isSameDate(user.upcoming_hours[i], user.upcoming_hours[i-1])">{{hour.startTime | parseDay}}:</div>
                  </div>
                  <div class="iu-profile-modal-day">
                    {{hour | parseTime}}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="iu-profile-modal-actions-section">
        <a
          target="_blank"
          class="da-components-button da-btn-standard iu-profile-modal-btn view-profile"
          style="width: fit-content; height: fit-content;color: #fff;"
          :href="`/profiles/${user.profile_url}`"
          aria-label="Go to user's profile page"
        >
          View Full Profile
        </a>
        <button
          @click="closeModal(user.id)"
          @keyup.enter="closeModal(user.id)"
          class="da-components-button da-btn-standard iu-profile-modal-btn cancel"
          style="width: fit-content; height: fit-content"
          aria-label="Close preview profile dialog"
        >
          Close
        </button>
    </div>
  </div>
</Modal>
</template>
<script lang="ts">
import Modal from '../../components/ModalExtended.vue';
import { focusElement } from '../../utils/CustomUtils';
export default {
  components: {Modal},
  name: 'profile-card-modal',
  props: ['user', 'isModalActive'],
  data() {
    return {
      activeTags: [],
    };
  },
  watch: {
    user: function() {
      this.updateActiveTags();
    }
  },
  async created() {
    this.updateActiveTags();
  },
  methods: {
    closeModal() {
      focusElement(`table_manage_account_${this.user.id}`)
      this.$emit('onClose');
    },
    isSameDate(hour1, hour2) {
      if(hour1 && hour2) {
        const d1 = new Date(hour1.startTime);
        const d2 = new Date(hour2.startTime);
        if(d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()) {
          return true;
        }
      }
      return false;
    },
    updateActiveTags() {
      this.activeTags = [];

      if (this.user?.role?.is_mentor) {
        this.activeTags.push('MENTOR')
      }
    },
    isTagActive(tag_name) {
      return this.activeTags.find(element => element == tag_name) !== undefined;
    },
  },
  computed: {}
};
</script>
