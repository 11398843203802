import { defineStore } from 'pinia';
import PermissionsService from '../services/PermissionsService';
import { hasPermission, PERMISSION_TYPES } from '../utils/PermissionUtils';
import { getUserProps } from '../utils/RouterUtils';

type License = {
  //...
};

const AUTO_ALLOWED_FEATURES = ['dashboard', 'admin_dashboard', 'profiles', 'settings'];

const PARTNER_ADMIN_FEATURES = ['manage_partners', 'manage_managers']

// features that require to have enabled both licence AND read permission
const PERMISSION_FEATURES = [
  'courses',
  'events',
  'programs',
  'resource_library',
  'manage_accounts',
  'bulk_upload',
  'appointments',
  'activity_feed',
];

export const useLicenseStore = defineStore({
  id: 'license',
  state: () => ({
    licenses: [],
    licensesLoaded: false,
  }),
  getters: {
    getLicenses: (state) => state.licenses,
    isFeatureEnabled: (state) => (feature, isAdmin) => {
      // If the feature is automatically allowed, return true
      if (AUTO_ALLOWED_FEATURES.includes(feature)) {
        return true;
      }
      if (PARTNER_ADMIN_FEATURES.includes(feature)) {
        const { isPartnerAdmin, isPartnerManager } = getUserProps();
        return isAdmin || isPartnerAdmin || isPartnerManager;
      }
      // If the feature requires permission
      if (PERMISSION_FEATURES.includes(feature)) {
        // If the user is an admin, grant access
        if (isAdmin) {
          return true;
        }
        // Otherwise, check if the user has read permission
        return hasPermission(feature, PERMISSION_TYPES.READ);
      }
      // Check if the feature is included in the licenses
      return state.licenses.includes(feature);
    },
    isLicensesLoaded: (state) => state.licensesLoaded,
  },
  actions: {
    async loadLicenses() {
      const service = new PermissionsService();
      const res = await service.getAllowedFeatures();

      if (res.success) {
        this.licenses = res.licenses;
      }
      this.licensesLoaded = true;
    },
    reset() {
      this.licenses = [];
      this.licensesLoaded = false;
    },
  },
});
