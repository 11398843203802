<template>
  <Modal class="dialog-container" :isActiveProp="isModalActive" @close="closeModal(true)">
      <div class="dialog-title">
        <h3  tabindex="0" :id="titleId ? titleId : 'objects-select-modal-title'">{{title}}</h3>
      </div>
      <div class="dialog-search-container">
        <div class="search dialog-search-objects" role="search">
          <input
            @keyup.enter="search()"
            v-model="searchInput"
            type="search"
            :placeholder="'Search for a ' + objectName"
            class="search-input"
          />
          <button @click="search()" class="search-btn" aria-label="Search">
            <i class="search-icon fas fa-search"></i>
          </button>
        </div>
      </div>
      <div class="dialog-content">
        <div class="dialog-content-filter-container">
          <div class="filter-list-item selected" id="filter-list-item-0">
            <p
              @click="selectFilterGroup(0)"
              @keyup.enter="selectFilterGroup(0)"
              tabindex="0"
              :aria-label='"Display all " + objectName + "s"'
            >
              All {{objectName}}s
            </p>
          </div>
          <div
            v-for="object_group in filterGroups"
            :key="object_group.id"
            :id="'filter-list-item-'+object_group.id"
            class="filter-list-item">
            <p
              @click="selectFilterGroup(object_group.id)"
              @keyup.enter="selectFilterGroup(object_group.id)"
              tabindex="0"
              :aria-label='"Display " + object_group.name'
            >
              {{object_group.name}}
            </p>
          </div>
        </div>
        <div id="objects-list-container" class="dialog-content-list-container" @scroll="loadOnScroll">
          <div class="dialog-list-item dialog-list-item-first">
            <span class="dialog-list-item-text">Select All</span>
            <span
              id="select-all-check-box"
              class="dialog-list-item-select"
              :class="allSelected ? 'dialog-list-item-select-active' : ''"
              @click="toggleAll()"
              @keyup.enter="toggleAll()"
              role="check-box"
              aria-label="Select all."
              tabindex="0"
            >
            </span>
          </div>
          <div
            class="dialog-list-item"
            v-for="item in objectsList"
            @mouseover="expandItem(item.id)"
            @mouseout="hideTooltip()"
            :key= item.id
            tabindex="0"
            :aria-label="'Select ' + objectName + ' ' + item.name"
            @keyup.enter="toggleBox(item.id)"
          >
            <div class="dialog-list-item-front">
              <span class="dialog-list-item-icon">
                <img class="eu-profile-thumbnail"  :src="item.icon ? item.icon : '/static/assets/icons/default-profile.svg'"  />
              </span>
              <span
              class="dialog-list-item-text"
              >
                <div class="dialog-list-item-name">
                  {{item.name}}
                </div>
                <div
                  class="dialog-list-unique-signature"
                  :id="'dialog-list-unique-signature-' + item.id"
                >
                  {{item.unique_signature}}
                </div>
              </span>
            </div>

            <span
              :id="'check-box-' + item.id"
              class="dialog-list-item-select"
              :class="isSelected(item.id)? 'dialog-list-item-select-active': ''"
              @click="toggleBox(item.id)"
              role="check-box"
              :aria-label="'Select ' + item.name"
            >
            </span>
          </div>
          <div v-if="isLoading" id="objects-list-loading-spinner">
            <loading-spinner/>
          </div>
        </div>

        <div class="dialog-content-selected-container">
          <div
            class="dialog-list-item"
            v-for="object in selectedObjects"
            :key = object.id
          >
            <span class="dialog-list-item-text">{{object.name}}</span>
            <span
              tabindex="0"
              @keyup.enter="toggleBox(object.id)"
              class="dialog-list-item-unselect"
              @click="toggleBox(object.id)"
              role="button"
              :aria-label="'Uncheck ' + object.name"
            >
              <i class="far fa-times"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="dialog-actions">
        <div class="dialog-actions-button-container">
          <button
            class="dialog-action-button"
            @click="closeModal(false)"
            :aria-label="actionName + ' selected.'"
          >
            {{actionName}}
          </button>
          <button
            class="dialog-cancel-button"
            @click="closeModal(true)"
            aria-label="Close dialog."
          >
            Cancel
          </button>
        </div>
      </div>
  </Modal>
</template>

<script lang="ts">
import Modal from "../../../components/ModalExtended.vue";
import LoadingSpinner from '../../../components/LoadingSpinner.vue';

export default {
  //isLoading - display loading spinner
  //page - number of page that is last loaded
  //currentPage - update this to page number after loading of new page is done
  name: "objects-select-modal",

  props: [
    'isModalActive',
    'title',
    'actionName',
    'objectsList',
    'isLoading',
    'page',
    'totalPageNumber',
    'currentPage',
    'objectName',
    'showIcon',
    'preselectedObjects',
    'filterGroups',
    'titleId'
  ],

  watch: {
    preselectedObjects: function() {
      this.placePreselectedObjects();
    },
    objectsList: function() {
      this.checkAllSelected();
    },
  },

  components: {
    Modal,
    LoadingSpinner,
  },

  data() {
    return {
      searchInput: '',
      allSelected: false,
      selectedObjects: [],
      tooltipDisplay: 0,
      selectedFilterGroupId: 0,
    }
  },

  created(){
    this.selectedObjects = this.preselectedObjects;
  },

  methods: {
    placePreselectedObjects() {
      this.selectedObjects = [];
      this.preselectedObjects.forEach(element => {
        this.selectedObjects.push(element)
      });
    },
    closeModal(isCanceled) {
      this.allSelected = false;
      if (isCanceled) {
        this.placePreselectedObjects();
        this.$emit('close-modal', {'canceled': isCanceled, 'selectedObjects': []});
      }
      else {
        this.$emit('close-modal', {'canceled': isCanceled, 'selectedObjects': this.selectedObjects});
      }
      this.selectFilterGroup(0);
      this.searchInput = "";
    },

    markSelected() {

    },

    search() {
      this.$emit("searchObjects", this.searchInput, 1);
    },

    action() {
      return;
    },

    getObject(id) {
      return this.objectsList.find(object => object.id == id);
    },

    isSelected(id) {
      return this.selectedObjects.find(object => object.id == id) !== undefined;
    },

    addSelectedObject(id) {
      this.selectedObjects.push(this.getObject(id));
    },

    removeSelectedObject(id) {
      this.selectedObjects = this.selectedObjects.filter((object) => {return object.id != id});
    },

    checkAllSelected() {
      this.allSelected = null;
      this.objectsList.forEach(object => {
        if(!this.isSelected(object.id)) {
          this.allSelected = false;
          return;
        }
      });
      if (this.allSelected == null) {
        this.allSelected = true;
      }
    },

    toggleBox(id) {
      this.isSelected(id) ? this.removeSelectedObject(id): this.addSelectedObject(id);
      if (this.allSelected) this.allSelected=false;
      if (this.checkAllSelected()) this.allSelected=true;
    },

    toggleAll() {
      if(!this.allSelected) {
        this.objectsList.forEach(object => {
          if(!this.isSelected(object.id)) {
            this.addSelectedObject(object.id);
          }
        });
        this.allSelected = true;
      } else {
        this.objectsList.forEach(object => {
          if(this.isSelected(object.id)) {
            this.removeSelectedObject(object.id);
          }
        });
        this.allSelected = false;
      }
    },

    loadData(page=0) {
      this.$emit("loadData", this.searchInput, page==0?this.page + 1:page, this.selectedFilterGroupId);
    },

    loadOnScroll(event) {
      if (this.page == this.currentPage && this.page != this.totalPageNumber) {
        let listContainerPosition = document.getElementById("objects-list-container").getBoundingClientRect();
        let listLoadingSpinnerPosition = document.getElementById("objects-list-loading-spinner").getBoundingClientRect();

        if (listContainerPosition.bottom > listLoadingSpinnerPosition.top) {
          this.loadData();
        }
      }
    },

    expandItem(id) {
      this.tooltipDisplay = id;
    },

    hideTooltip() {
      this.tooltipDisplay = 0;
    },

    showTooltip(id) {
      return this.tooltipDisplay == id;
    },

    toggleSelectedFilterGroup(element_id) {
      document.getElementById(element_id).classList.toggle('selected');
    },

    selectFilterGroup(id) {
      this.toggleSelectedFilterGroup('filter-list-item-' + this.selectedFilterGroupId);
      this.selectedFilterGroupId = id;
      this.loadData(1);
      this.toggleSelectedFilterGroup('filter-list-item-' + this.selectedFilterGroupId);
    }
  },
}
</script>

<style>
.filter-list-item {
  margin-bottom: 4px;
}

.filter-list-item:hover {
  cursor: pointer;
}

.filter-list-item.selected {
  color: #5E50B5;
}

.dialog-container > div.modal-content {
  width: 50%;
  padding: 0px 24px 24px;
  text-align: center;
}

.dialog-title {
  text-align: center;
  margin: 20px 0 15px;
}

.dialog-search-container {
  width:100%;
}

.dialog-content {
  display: flex;
  height: 400px;
  margin-top: 15px;
  width: 100%;
}

.dialog-content-filter-container {
  width: 20%;
  text-align: left;
  border-right: 1px solid #ececec;
}

.dialog-content-list-container {
  width: 50%;
  text-align: left;
  border-right: 1px solid #ececec;
  padding: 5px 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dialog-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  margin-bottom: 5px;
}

.dialog-list-item-first {
  margin-bottom: 10px;
}

.dialog-list-item-text {
  max-width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog-list-item-text:hover {
  white-space: normal;
  word-wrap: break-word;
}

.dialog-list-item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.dialog-list-item-name:hover {
  white-space: normal;
  word-wrap: break-word;
}

.dialog-list-item-front {
  max-width: 90%;
  display: flex;
}

.dialog-list-item-icon {
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.dialog-list-item-icon > img {
  width:25px;
  height:25px;
}

.dialog-list-unique-signature {
  color: #b4b4b4;
  font-size: 0.75rem;
  max-width: 100%;
}

.dialog-list-item-select {
  width: 18px;
  height: 18px;
  padding: 2px;
  border: solid 2px #949494;
  background-image: linear-gradient(to bottom, #faf8ff, #faf8ff), linear-gradient(to top, #5E50B5, #4c55a7);
  background-origin: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.dialog-list-item-select-active {
  border-color: #5e50b5;
  background: transparent;
  background-clip: content-box;
  background-color: #5e50b5;
}

.dialog-content-selected-container {
  width: 30%;
  text-align: left;
  padding: 5px 10px;
  max-height: 100%;
  overflow-y: scroll;
}

.dialog-list-item-unselect {
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  color: #5e50b5;
}

.search {
  display: flex;
  pointer-events: none;
  height: 40px;
  background: linear-gradient(to right, #ececec,#ececec), linear-gradient(15deg, #ececec ,#ececec);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  pointer-events: visible;
  transition: 0.3s;
  border-radius: 8px;
  transition: 0.3s;
  border: 2px solid transparent;
}

.search-input {
  width: 100%;
  padding: 2px 2px 2px 5px;
  background: transparent;
  border: none;
  outline: none;
  pointer-events: visible;

  font-size: .9em;
  font-weight: 500;
}

.search-input::placeholder {
    color: #707070;
    font-weight: 500;
}

.search-btn {
  cursor: pointer;
  padding: 0 20px;
  border: none;
  background-color: transparent;
}

.search-icon {
  font-size: 18px;
  color: #5e50b5;
  opacity: 1;
  top: 4px;
  position: relative;
}

.dialog-actions {
  margin-top: 15px;
  border-top: 1px solid #ececec;
  padding-top: 15px;
  width: 100%;
}

.dialog-action-button {
  background: #00a1b3;
  margin-right: 8px;
  border-radius: 15px 15px 0px 15px;
  width: 100px;
  height: 40px;
  border: none;
  font-weight: 800;
  color: #fff;
}

.dialog-action-button:hover {
  cursor: pointer;
  background-image: linear-gradient(#9cdce4,#00a1b3)
}

.dialog-cancel-button {
  background: #fff;
  border: 3px solid #00a1b3;
  margin-left: 8px;
  border-radius: 15px 15px 0px 15px;
  width: 100px;
  height: 40px;
  color: #00a1b3;
  font-weight: 800;
}

.dialog-cancel-button:hover {
  cursor: pointer;
  background: #00a1b3;
  color: #fff;
}

@media only screen and (max-width: 960px) {
  .dialog-container > div.modal-content {
    width: 80%;
  }
}

@media only screen and (max-width: 577px) {
  .dialog-content-list-container {
    width: 100%;
    border: none;
  }

  .dialog-content-filter-container {
    width: 0%;
    visibility: hidden;
  }

  .dialog-content-selected-container {
    width: 0%;
    visibility: hidden;
    padding: 0;
  }
}

@media only screen and (max-width: 390px) {
  .dialog-container > div.modal-content {
    width: 95%;
  }
}
</style>
