<template>
  <section class="analytics-section">
    <div class="analytics-header-container">
      <h1 class="title">Analytics</h1>
      <h2 v-if="permission_level === 'DEPARTMENT'" class="eu-simple-subtitle">
        These analytics are for your department only.
      </h2>
      <p class="eu-simple-subtitle">View at-a-glance summaries of your features.</p>
      <div id="main" class="eu-simple-navbar">
        <router-link to="/analytics" class="eu-simple-navbar-item eu-simple-navbar-item-active">Summary</router-link>
        <router-link to="/analytics/detailed/users" class="eu-simple-navbar-item">Detailed Reports</router-link>
      </div>
    </div>
    <!--NavBar -->

    <div class="analytics-content-container">
      <analytics-card
        :typeFlag="typeFlag"
        v-for="data in cards_data"
        :key="data.title"
        :typeOfCards="data.title"
        :flag="typeOfCards"
      >
        <template #header>
          <analytics-card-header
            :title="data.title"
            :icon="data.icon"
            :color="data.color"
            :isImg="data.isImg"
            :has_icon="data.has_icon"
            :url="data.url"
            @clicked="show"
          />
        </template>
        <template #body>
          <analytics-body-card-fetch-data :keys="data.cards" />
          <analytics-card-table-fetch-data v-if="data.has_table" :keys="data.cards" />
        </template>
        <template #footer>
          <analytics-card-footer v-if="data.has_footer" :link="data.link" :name="data.title" />
        </template>
      </analytics-card>
    </div>
  </section>
</template>

<script>
import AnalyticsCard from './AnalyticsCard.vue';
import AnalyticsCardHeader from './AnalyticsCardHeader.vue';
import AnalyticsCardFooter from './AnalyticsCardFooter.vue';
import AnalyticsBodyCardFetchData from './AnalyticsBodyCardFetchData.vue';
import AnalyticsCardTableFetchData from './AnalyticsCardTableFetchData.vue';
import AnalyticsService from '../services/AnalyticsService';
import CommonTitle from '../../components/CommonTitle.vue';

export default {
  components: {
    CommonTitle,
    AnalyticsCard,
    AnalyticsCardHeader,
    AnalyticsCardFooter,
    AnalyticsBodyCardFetchData,
    AnalyticsCardTableFetchData,
  },
  computed: {
    description_text() {
      const text = '';
      if (this.permission_level === 'DEPARTMENT') {
        text = 'These analytics are for your department only. \n'
      }

      text = text.concat('View at-a-glance summaries of your features.');

      return text;
    }
  },
  data() {
    return {
      typeFlag: '',
      typeOfCards: '',
      cards_data: {},
      permission_level: null,
      analyticsService: new AnalyticsService(),
    };
  },
  async created() {
    const predata = await this.analyticsService.getSummaryPreData();
    this.permission_level = predata.permission_level;
    this.cards_data = JSON.parse(predata.cards_data);
  },
  methods: {
    show(typeOfCards, typeFlag) {
      this.typeOfCards = typeOfCards;
      this.typeFlag = typeFlag;
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-section {
  padding: 24px 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.analytics-header-container {
  width: 100%;
  max-width: 1400px;
}

.analytics-content-container {
  width: 100%;
  max-width: 1400px;
}

.title {
  font-size: 40px;
  line-height: 1.2;
  color: #333;
}

@media screen and (max-width: 576px) {
  .analytics-section {
    padding: 20px 16px;
  }
}
</style>
