<template>
  <div class="feed-card">
    <div class="author-container">
      <img :src="post.profile_pic" class="author-profile-image" alt="Author profile image" />
      <div class="author-text-container">
        <div class="author-details-text">
          <!-- Role •  -->
          <strong v-if="hasDestination">{{ destination }} •</strong> {{ timeAgo }}
        </div>
        <div class="action-text-container">
          <strong>{{ post.actor_name }}</strong>
          {{ postActionText }}
          <strong>{{ postActionObjectText }}</strong>
        </div>
      </div>
    </div>
    <div class="post-content-container">
      <div v-if="postText" class="post-text">{{ postText }}</div>
      <div v-if="hasAttachments" class="post-attachments-container">
        <div v-if="nonImageArray" class="multiple-files-container">
          <div
            v-for="(file, index) in nonImageArray"
            :key="index"
            class="file-container"
            @click="previewFiles(file)"
            @keyup.enter="previewFiles(file)"
            tabindex="0"
            aria-label="Preview files"
          >
            <div class="name-container">
              <i class="fa-regular fa-file-alt"></i>
              <span class="text-clamp-1">{{ file.name }}</span>
            </div>
            <button v-if="file.resource_type === 'DOC'" aria-label="Download the file" class="download-button">
              <i class="fa-regular fa-download"></i>
            </button>
          </div>
        </div>
        <img
          v-if="hasOneImage"
          :src="getImageSource(0)"
          @click="previewFiles(imagesArray[0])"
          @keyup.enter="previewFiles(imagesArray[0])"
          class="large-image"
          alt="large image"
        />
        <div v-if="hasMultipleImages" class="multiple-images-container">
          <div
            v-for="index in Array.from({ length: imagesCount }, (value, index) => 0 + index)"
            :key="index"
            class="image-card"
          >
            <img
              :src="getImageSource(index)"
              alt="attachment image"
              @click="previewFiles(imagesArray[index])"
              @keyup.enter="previewFiles(imagesArray[index])"
              tabindex="0"
              aria-label="Open resource modal"
            />
            <div
              v-if="index == maxImages - 1 && imagesCountExtra > 0"
              @click="previewFiles(imagesArray[index])"
              @keyup.enter="previewFiles(imagesArray[index])"
              tabindex="0"
              aria-label="Open resource modal"
              class="tint"
            >
              <span class="number">+{{ imagesCountExtra }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

export default {
  name: 'feed-card',
  props: ['post'],
  computed: {
    hasOneImage() {
      return this.imagesArray.length === 1;
    },
    hasMultipleImages() {
      return this.imagesArray.length > 1;
    },
    hasDestination() {
      return this.post.department || this.post.group || this.post.partner;
    },
    destination() {
      return this.post.department
        ? this.post.department
        : this.post.group
        ? this.post.group
        : this.post.partner
        ? this.post.partner
        : '';
    },
    hasAttachments() {
      return this.post.files && this.post.files.length > 0;
    },
    allImages() {
      if (!this.hasAttachments) return false;

      return this.post.files.every((file) => {
        return file.resource_type === 'IMG';
      });
    },
    postActionObjectText() {
      if (
        this.post.event ||
        this.post.program ||
        ['event', 'program'].some((key) => {
          return key in this.postObject;
        })
      )
        return 'opportunity';

      if (!this.hasAttachments) return 'post';

      const isMultiple = this.post.files.length > 1;

      if (isMultiple && this.allImages) return 'photos';
      if (isMultiple && !this.allImages) return 'files';
      if (!isMultiple && this.allImages) return 'photo';
      if (!isMultiple && !this.allImages) return 'file';
    },
    postActionText() {
      if (!this.hasAttachments && this.postActionObjectText === 'opportunity') {
        return 'created an';
      }
      if (!this.hasAttachments) {
        return 'created a';
      }
      if (this.post.files.length === 1) {
        return 'uploaded a';
      }
      if (this.post.files.length > 1) {
        return 'uploaded';
      }
      return '';
    },
    postObject() {
      return JSON.parse(this.post.object);
    },
    postText() {
      return this.postObject.text;
    },
    timeAgo() {
      return timeAgo.format(new Date(this.post.time.replace(' ', 'T')));
    },
    maxImages() {
      if (window.innerWidth > 500) {
        return 4
      }
      return 3;
    },
    imagesCountExtra() {
      if (!this.hasAttachments) {
        return 0;
      }
      return this.imagesArray.length - this.maxImages;
    },
    imagesCount() {
      if (this.imagesArray.length < this.maxImages) {
        return this.imagesArray.length;
      }
      return this.maxImages;
    },
    nonImageArray() {
      if (this.hasAttachments && this.allImages) {
        return [];
      }

      return this.post.files.filter((file) => file.resource_type !== 'IMG');
    },
    imagesArray() {
      if (!this.hasAttachments) {
        return [];
      }

      return this.post.files.filter((obj) => obj.resource_type === 'IMG');
    },
  },
  methods: {
    previewFiles(file) {
      const filesArray = [...this.nonImageArray, ...this.imagesArray];
      const index = filesArray.findIndex((obj) => {
        return file === obj;
      });
      this.$emit('preview-files', filesArray, index);
    },
    getImageSource(fileIndex) {
      if (!this.hasAttachments) {
        return null;
      }

      return this.imagesArray[fileIndex].resource_url;
    },
  },
};
</script>

<style scoped lang="scss">
.feed-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid transparent;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.12);
  padding: 16px;
  border-radius: 8px;
}

.author-container {
  display: flex;
  gap: 8px;
}

.author-text-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.author-profile-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}

.author-details-text {
  font-size: 12px;
  color: #88858e;
  line-height: 1.2;
}

.action-text-container {
  font-size: 16px;
  color: #555;
  line-height: 1.15;

  strong {
    color: #333;
  }
}

.post-content-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 48px;
}

.post-text {
  font-size: 16px;
  line-height: 1.35;
  color: #555555;
}

.post-attachments-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .large-image {
    width: 224.4px;
    height: 144px;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;

    &:hover {
      cursor: pointer;
    }
  }

  .multiple-files-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .file-container {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #d1d1d7;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name-container {
      color: #444;
      font-size: 14px;
      line-height: 1.3;
      display: flex;
      gap: 8px;
      align-items: center;

      i {
        font-size: 24px;
        color: #4d88a9;
      }
    }

    .download-button {
      border: none;
      background: transparent;
      font-size: 18px;
      padding: 4px;
      color: #88858e;

      &:hover {
        color: #4d88a9;
        cursor: pointer;
      }
    }

    &:hover {
      border-color: #22a5a0;
      cursor: pointer;
    }
  }

  .multiple-images-container {
    display: flex;
    gap: 8px;

    .image-card {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      width: 72px;
      height: 72px;
      img {
        width: 72px;
        height: 72px;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
      }

      .tint {
        position: absolute;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        .number {
          color: #fff;
          line-height: 1.2;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .feed-card {
    gap: 12px;
  }
}
</style>
