<template>
  <ul class="eu-course-collection grid">
    <li v-for="item in courses" :key="item.id" class="eu-course-collection-card grid">
      <router-link :aria-label="`Go to page of ${item.name} course`" :to="`/courses/${item.id}`">
        <img :src="item.course_card_image_url" alt="Course cover image" class="eu-course-collection-card-img grid" />
      </router-link>
      <div class="eu-course-collection-body">
        <router-link :aria-label="`Go to page of ${item.name} course`" :to="`/courses/${item.id}`">
          <h5 class="eu-course-collection-title grid">{{ item.name }}</h5>
        </router-link>
        <div class="eu-course-collection-details grid">
          <p>{{ item.description }}</p>
        </div>
        <div
          tabindex="0"
          @click="removeBookmark(item.id)"
          @keyup.enter="removeBookmark(item.id)"
          class="eu-course-collection-bookmark grid"
          role="button"
          :aria-label="`Remove ${item.name} from saved courses`"
        >
          <i class="fad fa-bookmark eu-course-collection-bookmark-icon" style="color: #4c55a7"></i>
          <span style="color: #4c55a7">Saved</span>
        </div>
      </div>
    </li>
  </ul>
</template>
<script lang="ts">
export default {
  name: "browse-saved-courses",

  props: ["courses"],

  data() {
    return {};
  },

  methods: {
    removeBookmark(id: number) {
      this.$emit("remove-bookmark", id);
    },
  },
};
</script>
