<template>
  <div class="partner-tenant-pathways-container">
    <div class="title-container">
      <h2 class="title">
        <i class="fa-regular fa-book-open-reader icon"></i>
        Pathways You Might Like
      </h2>
      <router-link
        class="dabi-main-button-outlined see-all-button"
        to="/courses"
        aria-label="Go to courses page"
      >
        See all
      </router-link>
    </div>
    <div class="content-container">
      <button
        class="left-arrow arrow-button"
        aria-label="Show previous page"
        @click="showPreviousPage"
        @keyup.enter="showPreviousPage"
      >
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <button
        class="right-arrow arrow-button"
        aria-label="Show next page"
        @click="showNextPage"
        @keyup.enter="showNextPage"
      >
        <i class="fa-solid fa-arrow-right"></i>
      </button>
      <div
        v-for="(course, index) in paginate(recommendedCourses, pageSize, activePage)"
        :key="index"
        class="item"
        :style="`background-image: url(${course.course_card_image_url});`"
      >
        <div class="tint"></div>
        <div
          class="name"
          @click="goToCoursePage(course.id)"
          @keyup.enter="goToCoursePage(course.id)"
          aria-label="Go to course page"
          tabindex="0"
          role="button"
        >
          {{course.name}}
        </div>
        <button
          class="dabi-bookmark-button"
          :class="isCourseBookmarked(course.id) ? 'active': ''"
          @click="toggleBookmark(course.id)"
          :aria-label="`Toggle bookmark for course ${course.name}`"
        >
          <i class="fa-bookmark" :class="isCourseBookmarked(course.id) ? 'fa-solid': 'fa-regular'"></i>
        </button>
      </div>
    </div>
    <div class="actions-container">
      <slider-dots
        ref="recommendedPathwaysSlider"
        :totalNo="getPagesCount(recommendedCourses, pageSize)"
        :initialSelectedNo="1"
        @changeSelected="changeDisplayedPageHandler"
      />
      <router-link
        class="dabi-main-button-outlined see-all-button mobile"
        to="/courses"
        aria-label="Go to courses page"
      >
        See all
      </router-link>
    </div>
  </div>
</template>
<script>
import DashboardService from '../../../dashboard/services/DashboardService';
import BookmarkService from '../../../bookmarks/services/BookmarkService';
import SliderDots from '../../../partners/components/landing_pages/components/SliderDots.vue';
import * as Cookies from 'js-cookie';

export default {
  name: 'partner-tenant-pathways',
  components: {
    SliderDots,
  },
  props: [

  ],
  data() {
    return {
      dashboardService: new DashboardService(),
      bookmarkService: new BookmarkService(),
      recommendedCourses: [],
      bookmarkedCourses: [],
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      },
      activePage: 1,
      pageSize: 4,
    }
  },
  created() {
    this.dashboardService.getDashboardData({ section: "recommendedCourses", limit: 16 }).then(data => {
      this.recommendedCourses = data;
      this.emitIfEmpty();
    });
    this.bookmarkService.getBookmarkedCourses({ limit: 16, ids_only: true }).then(data => {
      this.bookmarkedCourses = data.courses;
    });
    if (window.screen.width <= 576) {
      this.pageSize = 999;
    }
  },
  methods: {
    emitIfEmpty() {
      if (!this.recommendedCourses || this.recommendedCourses.length === 0) {
        this.$emit('is-empty');
      }
    },
    paginate(array, page_size, page_number) {
      return array.slice((page_number - 1) * page_size, page_number * page_size);
    },
    getPagesCount(array, page_size=4) {
      let result = Math.floor(array.length / page_size);
      if (array.length % page_size === 0) {
        return result;
      }
      return result + 1;
    },
    changeDisplayedPageHandler(pageNo) {
      this.changePage(pageNo);
    },
    changePage(pageNo) {
      this.activePage = pageNo;
    },
    showPreviousPage() {
      this.$refs.recommendedPathwaysSlider.selectPrevious();
    },
    showNextPage() {
      this.$refs.recommendedPathwaysSlider.selectNext();
    },
    isCourseBookmarked(courseId) {
      return this.bookmarkedCourses.includes(courseId);
    },
    toggleBookmark(courseId) {
      if (this.isCourseBookmarked(courseId)) {
        const index = this.bookmarkedCourses.indexOf(courseId);
        this.bookmarkedCourses.splice(index, 1);
        this.bookmarkService.deleteBookmarkedCourses({'targets': [courseId]}, this.headers);
        return;
      }

      this.bookmarkService.postBookmarkCourses({'targets': [courseId]});
      this.bookmarkedCourses.push(courseId);
    },
    goToCoursePage(course_id) {
      this.$router.push(`/courses/${course_id}`);
    },
  },
  computed: {}
}
</script>
