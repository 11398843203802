import HttpService from '../../services/HttpService';
import * as Cookies from 'js-cookie';

const _headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class FeedService extends HttpService {
  constructor() {
    super('/feed/api');
  }

  // --------------------------------
  // FEED ENDPOINTS

  getPredata() {
    this.endpoint = `/feed/api/predata`;
    return this.get();
  }

  followFeed(item, headers) {
    this.endpoint = `/feed/api/follow`;
    return this.postFormData(item, headers);
  }

  followGroupFeed(item, headers) {
    this.endpoint = `/feed/api/group/follow`;
    return this.postFormData(item, headers);
  }

  followPartnerFeed(item, headers) {
    this.endpoint = `/feed/api/partner/follow`;
    return this.postFormData(item, headers);
  }

  // --------------------------------
  // POSTS ENDPOINTS

  getDepartmentPosts(params): Promise<any> {
    this.endpoint = `/feed/api/feed/department`;
    return this.get(params);
  }

  getQuestPosts(params): Promise<any> {
    this.endpoint = `/feed/api/feed/quest`;
    return this.get(params);
  }

  getPartnerPosts(params): Promise<any> {
    this.endpoint = `/feed/api/feed/partner`;
    return this.get(params);
  }

  getPosts(params): Promise<any> {
    this.endpoint = `/feed/api/feed/user`;
    return this.get(params);
  }

  createPost(item, headers): Promise<any> {
    this.endpoint = `/feed/api/feed/department`;
    return this.postFormData(item, headers);
  }

  updatePost(id, body, headers): Promise<any> {
    this.endpoint = `/feed/api/post/${id}`;
    return this.put(body, headers);
  }

  uploadFile(item, headers): Promise<any> {
    this.endpoint = `/feed/api/feed/file`;
    return this.postFormData(item, _headers);
  }

  deletePost(id, body, headers): Promise<any> {
    this.endpoint = `/feed/api/post/${id}`;
    return this.delete(body, headers);
  }

  // --------------------------------
  // REACTIONS ENDPOINTS

  addReaction(item, headers): Promise<any> {
    this.endpoint = `/feed/api/reaction`;
    return this.postFormData(item, headers);
  }

  removeReaction(id, body, headers): Promise<any> {
    this.endpoint = `/feed/api/reaction/${id}`;
    return this.delete(body, headers);
  }

  updateReaction(id, body, headers): Promise<any> {
    this.endpoint = `/feed/api/reaction/${id}`;
    return this.put(body, headers);
  }

  removeArea(id, body, headers): Promise<any> {
    this.endpoint = `/profiles/api/settings/focusareas/${id}`;
    return this.delete(body, headers);
  }

  removeIdentity(id, body, headers): Promise<any> {
    this.endpoint = `/profiles/api/settings/identities/${id}`;
    return this.delete(body, headers);
  }

  removeCAI(id, body, headers): Promise<any> {
    this.endpoint = `/profiles/api/settings/cai/${id}`;
    return this.delete(body, headers);
  }

  removeAudience(id, body, headers): Promise<any> {
    this.endpoint = `/profiles/api/settings/audiences/${id}`;
    return this.delete(body, headers);
  }

  createGroupPost(item, headers): Promise<any> {
    this.endpoint = `/feed/api/feed/group`;
    return this.postFormData(item, headers);
  }

  createPartnerPost(item, headers): Promise<any> {
    this.endpoint = `/feed/api/feed/partner`;
    return this.postFormData(item, headers);
  }

  createQuestPost(item, headers): Promise<any> {
    this.endpoint = `/feed/api/feed/quest`;
    return this.postFormData(item, headers);
  }

}
