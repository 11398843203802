<template>
  <Transition name="slide-and-fade-notification">
    <div
      id="notification"
      v-if="notification.text"
      class="notification-container"
      :style="`background-color: ${getBgColor(notification.isError)}; color: ${getTextColor(notification.isError)} `"
    >
      <span class="notification-text">
        {{ notification.text }}
      </span>
      <button class="close-button" @click="closeNotification" :style="`color: ${getTextColor(notification.type)} `">
        <i class="fa-solid fa-x"></i>
      </button>
    </div>
  </Transition>
</template>

<script>
import { useNotificationStore } from "../stores/notification";
import { isPartnerTenant } from "../utils/CustomUtils";
export default {
  name: "notification",
  computed: {
    notification() {
      return this.notificationStore;
    },
  },
  data() {
    return {
      notificationStore: useNotificationStore(),
    };
  },
  methods: {
    closeNotification() {
      this.notificationStore.closeNotification();
    },
    getBgColor(isError) {
      if (isError) {
        return "#E65722";
      }
      return isPartnerTenant() ? "#05c1a2" : "#4c55a7";
    },
    getTextColor(isError) {
      if (isError) {
        return "#fff";
      }
      return isPartnerTenant() ? "#4A4A4A" : "#fff";
    },
  },
};
</script>

<style scoped lang="scss">
.slide-and-fade-notification-enter-active,
.slide-and-fade-notification-leave-active {
  transition: all 0.8s ease-in-out;
}

.slide-and-fade-notification-enter,
.slide-and-fade-notification-leave-to {
  right: -240px;
  opacity: 0;
}

.slide-and-fade-notification-enter-to,
.slide-and-fade-notification-leave {
  right: 24px;
  opacity: 1;
}
</style>
