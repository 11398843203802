<template>
    <div>
        <ejs-chart 
            :id="keys + 1"
            :primaryXAxis='primaryXAxis' 
            :primaryYAxis='primaryYAxis' 
            :palettes='palettes' 
            :chartArea='chartArea'
            class="container"
        >
            <e-series-collection>
                <e-series 
                    :dataSource='items' 
                    :width='width' 
                    type='Line' 
                    xName='month' 
                    yName='sales' 
                    name='Sales' 
                > </e-series>
            </e-series-collection>
        </ejs-chart>
    </div>
  
</template>
<script>
import Vue from 'vue';
import {ChartPlugin, LineSeries, Category} from "@syncfusion/ej2-vue-charts"

Vue.use(ChartPlugin);
export default {
    name: "line-series-chart",
    props: ['items', 'keys'],
    data() {
        return {
            primaryXAxis: {
                valueType: 'Category',
                majorGridLines: { width: 0 },
            },
              primaryYAxis: {
                lineStyle: { width: 0 },
            },
            palettes: ["#5e50b5"],
            chartArea: { 
                border: { width: 0 } 
            },
           width: 3
        };
  },
  provide:{
      chart:[LineSeries, Category]
  },
  created(){}
  
}
</script>

<style  scoped>
.container {
   height: 250px;
 }

</style>