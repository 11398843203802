<template>
  <div class="pwi-page">
    <div class="welcome-to-includifi-section">
      <div class="title-container">
        <h2 class="title">
          Welcome to Includifi
        </h2>
        <p class="description">
          At Includifi we’re on a mission to
          support first-generation students
          in navigating their college journey
          with confidence and success. Join Us.
        </p>
      </div>
      <div class="content-container">
        <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/partner_with_us/partner-with-us-section-1.jpg" alt="Welcome to includifi" class="image">
        <div class="text-container">
          <h3 class="title">
            How Does Partnership with Includifi Work?
          </h3>
          <p class="description">
            Includifi is the first one-stop hub
            specifically dedicated to supporting
            first-generation student success. We
            aggregate curated content and opportunities
            for first-generation students, allowing our
            partners to reach a talented, yet often
            under-supported, student population.
          </p>
        </div>
      </div>
    </div>
    <div class="why-partner-section">
      <div class="white-tint"></div>
      <div class="content-container">
        <h3 class="title">
          Why Partner with Includifi?
        </h3>
        <div class="content-wrapper">
          <div class="item-container">
            <div class="icon-container">
              <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/partner_with_us/partner-icon1.svg" alt="Target reach">
            </div>
            <div class="text-container">
              <h4 class="title">Targeted Reach</h4>
              <p class="description">
                Your organization has valuable opportunities
                and resources that can positively impact
                first-gen students. Partnering with Includifi
                allows you to directly reach this resilient
                group of learners across institutions.
              </p>
            </div>
          </div>
          <div class="item-container">
            <div class="icon-container">
              <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/partner_with_us/partner-icon2.svg" alt="Impactful Engagement">
            </div>
            <div class="text-container">
              <h4 class="title">Impactful Engagement</h4>
              <p class="description">
                By showcasing your resources on Includifi,
                you’re not just sharing opportunities;
                you're actively contributing to the success
                and empowerment of first-gen students.
              </p>
            </div>
          </div>
          <div class="item-container">
            <div class="icon-container">
              <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/partner_with_us/partner-icon3.svg" alt="Collaborative Growth">
            </div>
            <div class="text-container">
              <h4 class="title">Collaborative Growth</h4>
              <p class="description">
                We believe in growing together. Your
                resources and opportunities will help
                us provide better and more comprehensive
                support to first-gen students.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="partner-with-us-section">
      <div class="content-container">
        <div class="text-container">
          <h3 class="title">
            Partner with Us
          </h3>
          <p class="description">
            Partnering with Includifi is free!
            If you represent a company, non-profit,
            or educational organization that has
            resources or opportunities that are
            relevant to first-gen students, we want
            to hear from you! Complete the form
            below and we’ll be in touch.
          </p>
        </div>
        <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/partner_with_us/partner-with-us-section-2.jpg" alt="Partner with us" class="image">
      </div>
    </div>
    <div class="become-a-partner-section">
      <div class="content-container">
        <div class="title-container">
          <h3 class="title">
            Become an Includifi Partner
          </h3>
        </div>
        <form class="form-container">
          <div class="two-fields-container">
            <input-base
              label="First &amp; Last Name"
              id="name-input"
              type="text"
              wrapperClass="input-wrapper"
              :required="true"
              v-model="form.name"
              :isError="!!form_error.name"
            />
            <input-base
              label="Email"
              id="email-input"
              type="text"
              wrapperClass="input-wrapper"
              :required="true"
              v-model="form.email"
              :isError="!!form_error.email"
            />
          </div>
          <div class="two-fields-container">
            <input-base
              label="Company/Organization"
              id="company-input"
              type="text"
              wrapperClass="input-wrapper"
              :required="true"
              v-model="form.company"
              :isError="!!form_error.company"
            />
            <input-base
              label="Title"
              id="title-input"
              type="text"
              wrapperClass="input-wrapper"
              :required="true"
              v-model="form.title"
              :isError="!!form_error.title"
            />
          </div>
          <div class="text-area-wrapper">
            <div class="label-wrapper">
              <label class="label" for="message">Tell Us More About Your Resources/Opportunities</label>
            </div>
            <textarea v-model="form.message" name="message" id="message" rows="7" placeholder="Write your message..."
              class="text-area"
            ></textarea>
          </div>
          <div class="actions-container">
            <div class="checkbox-wrapper align-top">
              <input v-model="form.is_subscribed" id="outreach_recruitment" class="checkbox" type="checkbox">
              <label class="label small" for="outreach_recruitment">Yes, I’d like to receive marketing communications regarding products, services, and events.</label>
            </div>
            <div>
              <button :disabled="submit_button_disabled" @click.prevent="submitForm" aria-label="Submit form" class="iu-main-btn main solid small">
                Submit
                <i class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '../partners/components/landing_pages/components/Breadcrumbs.vue'
import InputBase from '../components/formBase/InputBase.vue';
import PublicService from './PublicService';
import { Base64 } from 'js-base64';


export default {
  name: 'partner-with-includifi',
  components: {
    Breadcrumbs,
    InputBase
  },
  data() {
    return {
      public_service: new PublicService(),
      breadcrumbsLinkItems: [
        {
          'label': 'Home',
          'path': '/',
          'isSpa': false
        },
        {
          'label': 'About Us',
          'path': '/about-us',
          'isSpa': false,
        }
      ],
      form: {
        name: '',
        email: '',
        company: '',
        title: '',
        message: '',
        is_subscribed: false,
      },
      form_error: {
        name: '',
        email: '',
        company: '',
        title: '',
      },
      submit_button_disabled: false,
    }
  },
  methods: {
    getBackendEnvironment() {
      const clientXpPathData: HTMLElement = document.querySelector(
        ".js-data-client-xp-path"
      );
      const clientXpPath = clientXpPathData.dataset.clientXpPath;
      return Base64.decode(clientXpPath).split('*')[1]
    },
    isValidEmailString(email:string, entity:string='general' ): boolean {
      let entitiesList = [
        'accounts',
      ]
      if (entitiesList.includes(entity)){
        var regex = /^(\w|\.|\_|\-)+[@](\w|\_|\-|\.)+[.]\w{2,5}$/
      }
      else {
        var regex = this.getBackendEnvironment() === 'prod' ? /^(\w|\.|\_|\-)+[@](\w|\_|\-|\.)+[.]\w{2,3}$/ : /^(\w|\.|\_|\-)+[@](\w|\_|\-|\.)+[.]\w{2,5}$/
      }
      return regex.test(email);
    },
    validateForm() {
      let is_form_valid = true;
      if (!this.form.name) {
        is_form_valid = false;
        this.form_error.name = "This field is required.";
      } else {
        this.form_error.name = "";
      }
      if (!this.form.email) {
        is_form_valid = false;
        this.form_error.email = "This field is required.";
      } else {
        this.form_error.email = "";
      }
      if (!this.form.company) {
        is_form_valid = false;
        this.form_error.company = "This field is required.";
      } else {
        this.form_error.company = "";
      }
      if (!this.form.title) {
        is_form_valid = false;
        this.form_error.title = "This field is required.";
      } else {
        this.form_error.title = "";
      }

      return is_form_valid;
    },
    submitForm() {
      if (this.validateForm()) {
        this.submit_button_disabled = true;
        this.public_service.becomeAPartner(this.form).then(
          data => {
          // this.notification_store.addNotification("A demo request successfully sent.", 10000);
          window.location.href = '/demo-success';
          }
        ).catch(error => {
            // this.notification_store.addNotification("Something went wrong. Please try again later or contact our support.", 10000);
        }).finally(() => {
          this.submit_button_disabled = false;
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pwi-page {
  .welcome-to-includifi-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    background-color: #fff;
    padding: 48px 32px 64px;

    .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      max-width: 880px;
      text-align: center;

      .title {
        font-size: 56px;
        line-height: 1.15;
        color: #333;
        padding: 0;
      }

      .description {
        font-size: 28px;
        line-height: 1.5;
        color: #444;
      }
    }

    .content-container {
      display: flex;
      gap: 48px;
      max-width: 1080px;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .image {
        width: 384px;
        height: 270px;
        object-fit: cover;
        border-radius: 8px;
        flex-shrink: 0;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 648px;

        .title {
          padding: 0;
          font-size: 32px;
          line-height: 1.2;
          color: #333;
        }

        .description {
          font-size: 24px;
          line-height: 1.45;
          color: #555;
        }
      }
    }
  }

  .why-partner-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-image: url('https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/partner_with_us/partner-with-us-bg.jpg');
    background-size: cover;
    background-position: center;
    padding: 48px 32px;

    .white-tint {
      background-color: rgba(255, 255, 255, 0.85);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .content-container {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 48px;
      align-items: center;
      max-width: 1080px;

      .title {
        font-size: 40px;
        line-height: 1.2;
        color: #333;
        padding: 0;
      }

      .content-wrapper {
        display: flex;
        width: 100%;
        gap: 64px;
        align-items: flex-start;
        border: none;
        padding: 0;
        margin: 0;

        .item-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          flex: 1;

          .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 72px;
            width: 72px;
            border-radius: 100px;

            i {
              font-size: 32px;
              color: #836cff;
            }
          }

          .text-container {
            text-align: center;
            gap: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
              font-size: 24px;
              line-height: 1.2;
              font-weight: 600;
              color: #444;
            }

            .description {
              font-size: 20px;
              line-height: 1.45;
              color: #444;
            }
          }
        }
      }
    }
  }

  .partner-with-us-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 32px;
    background-color: #fff;

    .content-container {
      display: flex;
      gap: 64px;
      align-items: center;
      max-width: 1080px;
      width: 100%;

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .title {
          padding: 0;
          color: #333;
          font-size: 40px;
          line-height: 1.2;
        }

        .description {
          font-size: 24px;
          line-height: 1.45;
          color: #555;
        }
      }

      .image {
        width: 460px;
        height: 320px;
        object-fit: cover;
        border-radius: 8px;
        flex-shrink: 0;
      }
    }
  }

  .become-a-partner-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 32px 64px;
    background-color: #f1f1f9;

    .content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      max-width: 680px;

      .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        text-align: center;

        .title {
          font-size: 40px;
          line-height: 1.15;
          color: #333;
          padding: 0;
          text-align: center;
        }

        .description {
          font-size: 24px;
          line-height: 1.5;
          color: #444;
        }
      }

      .form-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
        background-color: #fff;
        border-radius: 8px;

        .two-fields-container {
          display: flex;
          gap: 16px;
          width: 100%;
        }

        .input-wrapper {
          width: 100%;
        }

        .text-area-wrapper {
          position: relative;
          margin-top: 8px;

          .label-wrapper {
            position: absolute;
            top: -12px;
            left: 6px;
            background-color: #fff;
            padding: 0 6px;

            .label {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.15;
            }
          }

          .text-area {
            resize: none;
            height: 72px;
            padding: 0.625rem 0.875rem;
            border: solid 1.5px #95919b;
            border-radius: 6px;
            background-color: #fff;
            font-size: 1rem;
            width: 100%;
          }
        }
      }

      .actions-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 16px;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .pwi-page {
    .why-partner-section {
      .content-container {
        .content-wrapper {
          flex-direction: column;
          align-items: center;

          .item-container {
            width: 100%;
            max-width: 450px;
          }
        }
      }
    }
    .partner-with-us-section {

      .content-container {
        flex-direction: column;
        align-items: center;

        .text-container {
          align-items: center;

          .description {
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .pwi-page {
    .welcome-to-includifi-section {
      padding: 32px 20px;
      gap: 40px;

      .title-container {
        align-items: center;

        .title {
          font-size: 48px;
        }

        .description {
          font-size: 20px;
        }
      }

      .content-container {
        gap: 24px;
        flex-direction: column;
        padding: 0;

        .image {
          width: 100%;
          height: unset;
        }

        .text-container {
          gap: 12px;
          text-align: center;
          padding: 0;

          .description {
            font-size: 20px;
          }
        }
      }
    }

    .why-partner-section {
      padding: 32px 20px;
      background-image: url('https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/partner_with_us/mobile-partner-with-us-bg.jpg');
      .content-container {
        gap: 24px;
        padding: 0;
        .title {
          font-size: 32px;
          text-align: center;
        }
        .content-wrapper {
          gap: 32px;

          .item-container {
            .text-container {
              .title {
                font-size: 22px;
                line-height: 1.15;
              }
              .description {
                font-size: 18px;
                line-height: 1.4;
              }
            }
          }
        }
      }
    }

    .partner-with-us-section {
      padding: 32px 20px;

      .content-container {
        padding: 0;
        gap: 24px;

        .text-container {
          .title {
            font-size: 32px;
            line-height: 1.25;
          }
          .description {
            font-size: 20px;
            line-height: 1.36;
          }
        }

        .image {
          width: 100%;
          height: unset;
        }
      }
    }

    .become-a-partner-section {
      padding: 32px 20px;
      .content-container {
        padding: 0;
        gap: 24px;

        .title-container {
          .title {
            font-size: 32px;
            line-height: 1.25;
          }

          .description {
            font-size: 20px;
            line-height: 1.36;
          }
        }

        .form-container {
          padding: 24px 16px;
          width: 100%;

          .two-fields-container {
            flex-direction: column;
            gap: 24px;
          }

          .text-area-wrapper {
            margin-top: 16px;
            .label-wrapper {
              max-width: 170px;
              top: -32px;
            }
          }
        }
      }
    }
  }
}
</style>
