import Vue from 'vue';
import VueRouter from 'vue-router';
import VueProgressBar from 'vue-progressbar';
import PartnerTenantSignUpStudent from './components/register_login_pages/PartnerTenantSignUpStudent.vue';
import PartnerTenantLoginRegisterBase from './components/register_login_pages/PartnerTenantLoginRegisterBase.vue';
import PartnerTenantLogin from './components/register_login_pages/PartnerTenantLogin.vue';
import { createPinia, PiniaPlugin } from 'pinia';
import VueCompositionAPI from '@vue/composition-api'
import { globalProgressBarOptions } from '../GlobalComponents';
export default class PartnerTenantLoginRegisterApp {
  protected mountPoint: HTMLElement;
  private readonly google_client_id: string;
  private readonly google_sso_redirect_uri: string;
  private google_sso_token: string;

  constructor(
    el: HTMLElement, google_client_id: string, google_sso_redirect_uri: string, google_sso_token: string) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.google_client_id = google_client_id;
    this.google_sso_redirect_uri = google_sso_redirect_uri;
    this.google_sso_token = google_sso_token;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;

    const routes = [
      {path: '/accounts/register', component: PartnerTenantSignUpStudent, props: {
        google_client_id: this.google_client_id,
        google_sso_redirect_uri: this.google_sso_redirect_uri,
        google_sso_token: this.google_sso_token
      }},
      {path: '/accounts/login', component: PartnerTenantLogin, props: {
        google_client_id: this.google_client_id,
        google_sso_redirect_uri: this.google_sso_redirect_uri,
        google_sso_token: this.google_sso_token
      }, name: 'login'},
      {
        path: '/accounts/confirmed', component: PartnerTenantLogin, props: {
          google_client_id: this.google_client_id,
          google_sso_redirect_uri: this.google_sso_redirect_uri,
          google_sso_token: this.google_sso_token,
        }
      }
    ];

    const progressBarOptions = globalProgressBarOptions;

    Vue.use(VueCompositionAPI);
    Vue.use(VueRouter);
    const pinia = createPinia();
    Vue.use(PiniaPlugin);
    Vue.use(VueProgressBar, progressBarOptions)

    const router = new VueRouter({
      routes, // short for `routes: routes`
      mode: 'history',
      scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
      },
    });

    const partnerTenantHome = new Vue({
      components: {'partner-tenant-login-register-base': PartnerTenantLoginRegisterBase},
      template: `<partner-tenant-login-register-base />`,
      pinia,
      mounted () {
        this.$Progress.finish()
      },
      created () {
        //  [App.vue specific] When App.vue is first loaded start the progress bar
        this.$Progress.start()
        //  hook the progress bar to start before we move router-view
        this.$router.beforeEach((to, from, next) => {
          //  does the page we want to go to have a meta.progress object
          if (to.meta.progress !== undefined) {
            let meta = to.meta.progress
            // parse meta tags
            this.$Progress.parseMeta(meta)
          }
          //  start the progress bar
          this.$Progress.start()
          //  continue to next page;
          next()
        })
        //  hook the progress bar to finish after we've finished moving router-view
        this.$router.afterEach((to, from) => {
          //  finish the progress bar
          this.$Progress.finish()
        })
      },
      methods: {},
      router,
    });

    partnerTenantHome.$mount(this.mountPoint);
  }
}
