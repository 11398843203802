import Vue from 'vue';
import FeedService from '../feed/services/FeedService';
import * as Cookies from 'js-cookie';
import Modal from '../components/ModalExtended.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import { focusElement } from '../utils/CustomUtils';
import AccountsService from '../accounts/services/AccountsService';
import ContactGroupService from '../services/ContactGroupService';
import ObjectsSelectModal from '../accounts/components/modals/ObjectsSelectModal.vue'
import { capitalizeFirstLetter } from '../utils/StringUtils';

export default class AreasTable {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected areas: any;
  protected areaType: string;
  protected contactGroup_list: any;

  constructor(el: HTMLElement, areas, areaType, contactGroup_list) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.areas = areas;
    this.areaType = areaType;
    this.contactGroup_list = contactGroup_list;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const areasTable = new Vue({
      components: { Modal, LoadingSpinner, ObjectsSelectModal },
      template: `
      <section>
        <div v-if="isLoading">
          <loading-spinner />
        </div>
        <div v-else>
          <table v-if="areas && areas.length > 0" class="eu-resource-table">
              <tr class="eu-resource-row">
                <th v-if="areaType != 'Audience'" scope="col" class="eu-resource-cell eu-resource-cell-th analytics-table-small-screen-cell-th">
                    <span></span>
                </th>
                <th scope="col" class="eu-resource-cell eu-resource-cell-th analytics-table-small-screen-cell-th">
                    <span> Name</span>
                </th>
                <th v-if="areaType == 'Focus Area'" scope="col" class="eu-resource-cell eu-resource-cell-th analytics-table-small-screen-cell-th">
                    <span> Type</span>
                </th>
                <th scope="col" class="eu-resource-cell eu-resource-cell-th analytics-table-small-screen-cell-th">
                </th>
              </tr>
              <tr v-for="d in areas" v-bind:key="d.name" class="eu-resource-row">
                  <td v-if="areaType != 'Audience'" class="eu-resource-cell" style="padding-right: 0">
                      <span :style="'background-image:url(' + getAreaImgUrl(d) + ')'" class="interests-img-sm"></span>
                  </td>
                  <td class="eu-resource-cell">
                      <span>{{d.name}}</span>
                  </td>
                  <td v-if="areaType == 'Focus Area'" class="eu-resource-cell">
                      <span>{{capitalizeFirstLetter(d.type)}}</span>
                  </td>
                  <td class="eu-resource-cell eu-resource-cell-dots-icon" style="display:inline-flex;float:right">
                      <span v-if="areaType == 'Audience'" title="Add users to the audience" tabindex="0" role="button" :aria-label="'Edit users for audience: ' + d.name" @click="openSelectUsersModal(d)" @keyup.enter="openSelectUsersModal(d)" style="display: flex; margin-right: 10px;"> <i class="fas fa-users" style="font-size: 14px"></i></span>
                      <span :aria-label="'Open ' + d.name + ' delete modal'" aria-haspopup="dialog" tabindex="0" role="button" @click="openCancelationModal(d)" @keyup.enter="openCancelationModal(d)" style="display: flex"> <i class="fas fa-trash-alt" style="font-size: 14px;color:#f16b4c;background:#ffe6e0"></i></span>
                  </td>
              </tr>
          </table>
        </div>
        <objects-select-modal
          v-if="areaType == 'Audience'"
          :isModalActive="isSelectUsersModalActive"
          @close-modal="closeSelectUsersModalEventHandler"
          title="Add Students to Audience"
          actionName="Confirm"
          objectName="User"
          :objectsList="usersList"
          :isLoading="isLoadingSelectUsers"
          :page="selectUserPage"
          :totalPageNumber="selectUserTotalPageNumber"
          :currentPage="selectUserCurrentPage"
          :showIcon="true"
          :preselectedObjects="preselectedUsers"
          :filterGroups="contactGroup_list"
          @loadData="loadUsersList"
          @searchObjects="loadUsersList"
        ></objects-select-modal>
        <div class='accounts-view-section'>
            <Modal :isActiveProp="isCancelationModalActive" @close="closeCancelationModal">
            <div class='profile-notification-modal' >
                <h2 class="eu-profile-title">Remove {{areaType}}</h2>
                <p style="padding:0 30px 30px 30px;text-align:center">Are you sure that you want to remove <i>{{selectedArea.name}}</i>? This cannot be undone.</p>
                <div class="row" style="width: 100%">
                <div class="column">
                    <span
                    @click="removeArea"
                    class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary orange"
                    style="width: fit-content; height: fit-content"
                    >
                    Remove {{areaType}}
                    </span>
                    <span
                    @click="closeCancelationModal"
                    class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                    style="width: fit-content; height: fit-content"
                    >
                    Cancel
                    </span>
                </div>
                </div>
            </div>
            </Modal>
        </div>
      </section>
      `,
      data() {
        return {
          areas: _class.areas,
          ASSETS_PATH: process.env.MEDIA_URL,
          isCancelationModalActive: false,
          selectedArea: { name: null },
          areaType: _class.areaType,
          isLoading: false,
          isSelectUsersModalActive: false,
          usersList: [],
          isLoadingSelectUsers: false,
          preselectedUsers: [],
          selectUserPage: 1,
          selectUserCurrentPage: 1,
          selectUserTotalPageNumber:1,
          contactGroup_list: _class.contactGroup_list,
          headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
            Accept: 'application/json',
          },
        };
      },
      async created() {
        this.service = new FeedService();
        this.accountsService = new AccountsService();
        this.contactGroupService = new ContactGroupService();
      },
      methods: {
        capitalizeFirstLetter,
        async removeArea() {
          this.closeCancelationModal();
          this.isLoading = true;
          const selectedItem = this.selectedArea.id;
          const res =
            this.areaType === 'Focus Area'
              ? await this.service.removeArea(selectedItem, {}, this.headers)
              : this.areaType === 'Identity'
              ? await this.service.removeIdentity(selectedItem, {}, this.headers)
              : this.areaType === 'CAI'
              ? await this.service.removeCAI(selectedItem, {}, this.headers)
              : await this.service.removeAudience(selectedItem, {}, this.headers);
          if (res?.success) {
            this.isLoading = false;
            location.reload();
          }
        },
        openCancelationModal(area) {
          this.selectedArea = area;
          this.isCancelationModalActive = true;
          const sidebar: HTMLElement = document.querySelector('#sidebar');
          sidebar.style.display = 'none';
        },
        closeCancelationModal() {
          this.isCancelationModalActive = false;
          const sidebar: HTMLElement = document.querySelector('#sidebar');
          sidebar.style.display = 'block';
        },
        getAreaImgUrl(fa) {
          return fa.image_url.startsWith('http') ? fa.image_url : this.ASSETS_PATH + fa.image_url;
        },
        async loadContactGroupUsers(audience_id) {
          this.accountsService.getAudienceUsers(audience_id).then(data=>{
            this.preselectedUsers = data.data.map(user => this.userToSelectObjectMapper(user));
          });
        },
        openSelectUsersModal(contactGroup){
          this.selectedContactGroup = contactGroup;
          this.isSelectUsersModalActive = true;
          focusElement("objects-select-modal-title")
          this.loadContactGroupUsers(contactGroup.id);
          this.loadUsersList("", 1);
        },
        closeSelectUsersModal() {
          this.isSelectUsersModalActive = false;
          this.selectedContactGroup = {name:null, id:null};
        },
        async closeSelectUsersModalEventHandler(closeInfo) {
          if(!closeInfo["canceled"]){
            const data = {
              audience_id: this.selectedContactGroup.id,
              user_list: closeInfo.selectedObjects.map(user => user.id),
            }
            await this.accountsService.addAudienceUsers(data, this.headers);
          }
          this.closeSelectUsersModal();
        },
        async loadUsersList(searchString="", page=1, selectedContactGroup_id) {
          this.selectUserPage = page;

          let selectedContactGroup_id_check = selectedContactGroup_id;
          if (selectedContactGroup_id == undefined) selectedContactGroup_id_check = 0;

          if(selectedContactGroup_id_check == 0) {
            if (page==1) this.usersList = [];
            this.isLoadingSelectUsers = true;

            let response = await this.accountsService.getUsers({
              "name":searchString,
              "page": page,
            });
            this.usersList = this.usersList.concat(response.page_data.map(user => this.userToSelectObjectMapper(user)));
            this.selectUserTotalPageNumber = response.total_pages_number;
            if(this.selectUserTotalPageNumber == this.selectUserPage) {
              this.isLoadingSelectUsers = false;
            }
            this.selectUserCurrentPage = page;
          } else {
            this.usersList = [];
            this.isLoadingSelectUsers = true;
            let response = await this.contactGroupService.getContactGroupUsers(selectedContactGroup_id, {"name":searchString});
            this.usersList = this.usersList.concat(response.data.map(user => this.userToSelectObjectMapper(user)));

            this.selectUserCurrentPage = page;
            this.isLoadingSelectUsers = false;
          }
        },
        userToSelectObjectMapper(user) {
          return {
            "id": user.id,
            "name": user.first_name + " " + user.last_name,
            "unique_signature": user.email,
            "icon": user.profile_picture,
          }
        },
      },
      mounted() {},
    });
    areasTable.$mount(this.mountPoint);
  }
}
