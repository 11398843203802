<template>
  <section>
    <div class="engagement-snapshot-analytic engagement-snapshot">
      <div class="engagement-snapshot-wrapper"></div>
      <h2 class="title">Engagement Snapshot</h2>
      <div class="cards">
        <div v-if="upcomingData.events" class="snapshot-card-sm">
          <div class="sm-card-title">Upcoming Opportunities</div>
          <div class="sm-card-bottom">
            <div class="">
              <i class="fad fa-calendar-star" style="color: #ffa800"></i> {{ upcomingData.events.data }}
            </div>
            <router-link class="goto" to="/opportunities"
              >View <i class="fad fa-chevron-right" style="top: 1px; font-size: 13px"></i
            ></router-link>
          </div>
        </div>
        <div v-if="upcomingData.library"  class="snapshot-card-sm">
          <div class="sm-card-title">Partner Resources</div>
          <div class="sm-card-bottom">
            <div class="">
              <i class="fad fa-folder-image" style="color: #9f87e0"></i> {{ upcomingData.library.data }}
            </div>
            <router-link class="goto" to="/library"
              >View <i class="fad fa-chevron-right" style="top: 1px; font-size: 13px"></i
            ></router-link>
          </div>
        </div>
        <div v-if="isAdmin" class="snapshot-card">
          <div class="card-title">Overview</div>
          <div v-if="!isLoading" class="card-items-wrapper">
            <div class="card-item">
              <div>Total Accounts</div>
              <div>{{ totalUsers }}</div>
            </div>
            <div class="card-item">
              <div>New Accounts</div>
              <div>{{ new_users }}</div>
            </div>
            <div class="card-item">
              <div>Pageviews</div>
              <div>{{ total_page_views }}</div>
            </div>
            <div class="card-item">
              <div>Resource Engagement</div>
              <div>{{ total_resources_engagement }}</div>
            </div>
          </div>
        </div>
        <div v-if="(isPartnerAdmin || isPartnerManager)" class="snapshot-card">
          <div class="card-title">Overview</div>
          <div v-if="!isLoading" class="card-items-wrapper">
            <div class="card-item">
              <div>Total Followers</div>
              <div>{{ total_partner_account_followers }}</div>
            </div>
            <div class="card-item">
              <div>Total Partner Admins</div>
              <div>{{ total_partner_account_admins }}</div>
            </div>
            <div class="card-item">
              <div>Total Partner Managers</div>
              <div>{{ total_partner_account_managers }}</div>
            </div>
          </div>
        </div>
        <div v-else> <loading-spinner wrapperClass="wrapper" wrapperStyle="top: 30px; left: 20vw;" /></div>
      </div>
    </div>

    <div class="performance">
      <div class="performance-title">Performance</div>
      <div class="nav-tab">
        <p
          v-if="upcomingData.events"
          class="tab"
          tabindex="0"
          @click="setTab('opportunities')"
          @:keyup.enter="setTab('opportunities')"
          :class="currentFeature === 'opportunities' && 'active'"
        >
          Opportunities
        </p>
        <p
          v-if="upcomingData.library"
          class="tab"
          tabindex="0"
          @click="setTab('resources')"
          @:keyup.enter="setTab('resources')"
          :class="currentFeature === 'resources' && 'active'"
        >
          Resources
        </p>
      </div>
      <div class="tables slim-scrollbar-vertical slim-scrollbar-horizontal">
        <table v-if="currentFeature === 'opportunities'" class="eu-resource-table">
          <tr class="eu-resource-row">
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Opportunities
              <span
                tabindex="0"
                aria-label="Sort table by name"
                role="button"
                @click="sort('title')"
                @:keyup.enter="sort('title')"
              >
                <!-- <i :class="sortedBy !== 'title' ? 'fas fa-sort' : directionUp.title ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i> -->
              </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Saves
              <span
                tabindex="0"
                aria-label="Sort table by role"
                role="button"
                @click="sort('start_date')"
                @:keyup.enter="sort('start_date')"
              >
                <!-- <i :class="sortedBy !== 'start_date' ? 'fas fa-sort' : directionUp.start_date ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i> -->
              </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Views
              <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Click throughs
              <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Attending
              <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Type
              <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Added
              <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
            </th>
          </tr>
          <tr
            v-for="(item, i) in opportunities"
            v-bind:key="i"
            tabindex="0"
            id="table_manage_account_${item.id}"
            role="row"
            class="eu-resource-row"
            style="position: relative"
          >
            <td class="eu-resource-cell event-cell">
              <div style="display: flex; flex-direction: row; gap: 12px">
                <img
                  :src="item.thumbnail"
                  style="height: 3.5rem; width: 3.5rem; border-radius: 4px; object-fit: cover"
                />
                <div style="display: flex; flex-direction: column; justify-content: center">
                  <span class="event-table-title" :title="item.name"> {{ item.title }}</span>
                  <span v-if="item.start_date" class="event-table-date">
                    <p>
                      {{ item.start_date | shortDateFormat }}
                      <span v-if="item.end_date"> - {{ item.end_date | shortDateFormat }} </span>
                    </p>
                  </span>
                </div>
              </div>
            </td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.num_bookmarks || 0 }}</td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.views || 0 }}</td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.click_throughs || 0 }}</td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.num_rsvps || 0 }}</td>
            <td class="eu-resource-cell" style="white-space: nowrap">
              {{ capitalize(item.type_of_program_management) }}
            </td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.creation_date | shortDateFormat }}</td>
          </tr>
        </table>
        <table v-if="currentFeature === 'resources'" class="eu-resource-table">
          <tr class="eu-resource-row">
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Resources
              <span
                tabindex="0"
                aria-label="Sort table by name"
                role="button"
                @click="sort('title')"
                @:keyup.enter="sort('title')"
              >
                <!-- <i :class="sortedBy !== 'title' ? 'fas fa-sort' : directionUp.title ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i> -->
              </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Saves
              <span
                tabindex="0"
                aria-label="Sort table by role"
                role="button"
                @click="sort('start_date')"
                @:keyup.enter="sort('start_date')"
              >
                <!-- <i :class="sortedBy !== 'start_date' ? 'fas fa-sort' : directionUp.start_date ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i> -->
              </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Views
              <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Click throughs
              <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Type
              <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
            </th>
            <th class="eu-resource-cell eu-resource-cell-th" scope="col">
              Added
              <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
            </th>
          </tr>
          <tr
            v-for="(item, i) in resources"
            v-bind:key="i"
            tabindex="0"
            id="table_manage_account_${item.id}"
            role="row"
            class="eu-resource-row"
            style="position: relative"
          >
            <td class="eu-resource-cell event-cell">
              <div style="display: flex; flex-direction: column; justify-content: center">
                <span class="event-table-title" :title="item.name"> {{ item.name }}</span>
              </div>
            </td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.num_bookmarks || 0 }}</td>
             <td class="eu-resource-cell" style="white-space: nowrap">{{ item.views || 0 }}</td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.downloaded + item.click_throughs }}</td>
            <td class="eu-resource-cell" style="white-space: nowrap">
              {{ item.resource_type }}
            </td>
            <td class="eu-resource-cell" style="white-space: nowrap">{{ item.timestamp | shortDateFormat }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="feature-cards">
      <div class="feature-card">
        <div class="feature-card-top">
          <div class=""><i class="fad fa-users" style="color: #9f87e0; margin-right: 8px"></i>Manager Settings</div>
          <router-link class="goto" :to="partner ? '/accounts/partners' : '/accounts'"
            >View <i class="fad fa-chevron-right" style="top: 1px; font-size: 13px"></i
          ></router-link>
        </div>
        <div>Add/delete managers, edit their settings, and reset passwords.</div>
      </div>
      <div class="feature-card" :class="!partner && 'disabled'">
        <div class="feature-card-top">
          <div class=""><i class="fad fa-file-invoice" style="color: #54c5d4; margin-right: 8px"></i>Partner Profile</div>
          <router-link class="goto" :to="`/partneraccounts/${partner}`"
            >View <i class="fad fa-chevron-right" style="top: 1px; font-size: 13px"></i
          ></router-link>
        </div>
        <div>Edit and manage your partner profile content and settings.</div>
      </div>
      <div class="feature-card disabled">
        <div class="feature-card-top">
          <div class=""><i class="fad fa-chart-mixed" style="color: #cf8e7d; margin-right: 8px"></i>Analytics</div>
          <router-link class="goto" to="/library"
            >View <i class="fad fa-chevron-right" style="top: 1px; font-size: 13px"></i
          ></router-link>
        </div>
        <div>View student engagement data and insights.</div>
      </div>
    </div>
  </section>
</template>
<script>
import PartnerService from "../../partners/services/PartnerService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import PieChart from "../../analytics/components/chart/PieChart.vue";
import { formatEventTime } from "../../events/utils/DateFormat";
import { FEATURES, hasPermission } from '../../utils/PermissionUtils';
import { useAuthStore } from "../../stores/auth";

export default {
  components: {
    LoadingSpinner,
    PieChart,
  },
  name: 'dabi-admin-dashboard',
  props: ["analyticsData", "partner"],
  data() {
    return {
      array_data: [],
      upcomingData: { events: null, library: null },
      isLoading: true,
      totalUsers: 0,
      total_resources_engagement: 0,
      total_page_views: 0,
      active_community_groups: 0,
      new_users: 0,
      currentFeature: "opportunities",
      opportunities: [],
      resources: [],
      hasFeedAccess: hasPermission(FEATURES.ACTIVITY_FEED),
      authStore: useAuthStore(),
      total_partner_account_followers: 0,
      total_partner_account_admins: 0,
      total_partner_account_managers: 0,
      isAdmin: false,
      isPartnerAdmin: false,
      isPartnerManager: false
    };
  },
  async created() {
    this.upcomingData = this.analyticsData.reduce((acc, item) => {
      const { class: key, ...rest } = item;
      acc[key] = rest;
      return acc;
    }, {});
    if(!this.upcomingData.events) {
      this.currentFeature = 'resources'
    }
    this.service = new PartnerService();
    let res = await this.service.getAnalytics();
    this.opportunities = this.upcomingData.events && await this.service.getOpportunitiesAnalytics();
    this.resources = this.upcomingData.library && await this.service.getResourcesAnalytics();
    this.array_data = res?.array;
    this.isLoading = false;
    this.totalUsers = res?.total;
    this.new_users = res?.new_users;
    this.active_community_groups = res?.active_community_groups;
    this.total_resources_engagement = res?.total_resources_engagement;
    this.total_page_views = res?.total_page_views;
    this.total_partner_account_followers = res?.total_partner_account_followers;
    this.total_partner_account_admins = res?.total_partner_account_admins;
    this.total_partner_account_managers = res?.total_partner_account_managers;
    const authStore = useAuthStore();
    this.isAdmin = authStore.getIsAdmin
    this.isPartnerAdmin = authStore.getIsPartnerAdmin
    this.isPartnerManager = authStore.getIsPartnerManager
  },
  methods: {
    formatEventTime,
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    setTab(tab) {
      this.currentFeature = tab;
    },
  },
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.tables {
  overflow: auto;
  max-height: 500px;
}
.performance {
  padding: 24px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 2px 2px 24px 16px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  margin-bottom: 32px;
}
.performance-title {
  color: #333;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 24px;
}
.nav-tab {
  border-bottom: 1px solid #e7e5ec;
  display: flex;
  gap: 12px;
  margin-bottom: 32px;
}
.tab {
  font-size: 18px;
  font-weight: 600;
  color: #95919b;
  border-bottom: 3px solid #fff;
  padding-bottom: 8px;
  width: fit-content;
  cursor: pointer;
}
.tab.active,
.tab:hover {
  color: #00a1b3;
  border-bottom: 3px solid #00a1b3;
}
.feature-cards {
  display: flex;
  gap: 24px;
  margin-bottom: 32px;
}
.feature-card {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
  padding: 24px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 2px 2px 24px 16px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  height: 130px;
}
.engagement-snapshot {
  padding: 32px 0;
}
.cards {
  display: flex;
  gap: 24px;
}
.card-items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.snapshot-card {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  height: 230px;
  margin-top: 24px;
}
.snapshot-card-sm {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  background: #fff;
  width: 183px;
  min-width: 183px;
  height: 230px;
  margin-top: 24px;
}
.card-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: #333;
}

.card-item {
  display: flex;
  justify-content: space-between;
  color: #555;
  line-height: 1.35;
}

.sm-card-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  color: #88858e;
}

.sm-card-bottom {
  font-size: 24px;
  color: #333;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.feature-card-top {
  font-size: 20px;
  color: #333;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.goto {
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  display: flex;
  gap: 8px;
}

.engagement-snapshot-wrapper {
  position: absolute;
  width: 200vw;
  height: 100%;
  left: -50%;
  top: 0px;
  z-index: -1;
  background-color: #f1f1f9;
}
</style>
