<template>
    <section>
        <div v-if="isLoading">
            <loading-spinner />
        </div>
        <analytics-body-card v-if="!isLoading" :data="cards_data_top" />
        <analytics-body-card v-if="!isLoading" :data="card_data_botom" />
    </section>
</template>

<script>
import AnalyticsService from '../services/AnalyticsService';
import AnalyticsBodyCard from './AnalyticsBodyCard.vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';

export default {
    name: "analytics-body-card-fetch-data",

    components: { AnalyticsBodyCard, LoadingSpinner },

    props: {
        keys: String,
        filtersData : {
            start_date: Date,
            end_date: Date,
            keys: String,
        },
        params_mentor: {
            type: Object,
            default: function() {
                return {};
            },
        }

    },
 
    data() {
        return {
            cards_data_top : null,
            card_data_botom : null,
            isLoading : true,
            params: {
                type: Object,
                default: function() {
                    return {};
                },
            },
        }
    },

    async created() {
        this.service = new AnalyticsService();
        if(!this.keys) return;
        if(this.params_mentor){
            this.params = {...this.params_mentor};
            
        }
        this.get_data(this.params);
    },
    methods: {
        async get_data(params){
            let cards = await this.service.getBodyCardsData(this.keys, params);
            this.cards_data_top = cards.cardsTop;
            this.card_data_botom = cards.cardsBottom;
            this.isLoading = false;
        }
    },
    watch: {
        filtersData: async function(newVal, oldVal) {
            let params= {
                ...this.params,
                start_date: this.filtersData.start_date,
                end_date: this.filtersData.end_date
            }
            this.isLoading = true;
            this.params = params;
            this.get_data(params)
        },
        keys: function(newVal, oldVal){
            this.isLoading = true;
            this.get_data(this.params)
        }
    }

}
</script>
