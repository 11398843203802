<template>
  <section class="flex-column-center" v-if="loggedUser && dashboardPath">
    <div class="main-wrapper">
      <section id="main" v-if="!isShowing" class="dashboard-nav-wrapper">
        <router-link to="/" class="dashboard-nav-pill"
          ><i class="fa-solid fa-grid" style="margin-right: 4px"></i> Dashboard
        </router-link>
        <router-link to="/feed" class="dashboard-nav-pill active"
          ><i class="fa-sharp fa-solid fa-bullhorn" style="margin-right: 4px"></i>
          {{ `${isPartnerTenant ? "News &amp; Updates" : "Campus360"}` }}
        </router-link>
      </section>
      <div class="updates-title-wrapper">
        <common-title
          :title="title_text"
          description="Stay current with latest news and updates"
        />
      </div>

      <section :style="{ display: shownStyle() }">
        <feed-posts
          v-if="!isShowing"
          :loggedUser="loggedUser"
          :isLoadingPosts="isLoadingPosts"
          :isLoadingScroll="isLoadingScroll"
          :posts="posts"
          :showNavbar="true"
          :has_navbar="true"
          :show_search="true"
          @show-all-comments="showAllComments"
          @show-notification="showNotification"
          @get-posts="getPosts"
          @set-is-loading="setIsLoading"
          @search-feed="searchFeed"
          @update-post-reaction="updatePostReaction"
        />
        <div v-if="!isShowing" class="vl"></div>
        <feed-details
          v-if="!isShowing"
          :isShowing="isShowing"
          :loggedUser="loggedUser"
          :dashboardPath="dashboardPath"
          :number_of_following_departments="number_of_following_departments"
          :number_of_following_groups="number_of_following_groups"
          :number_of_following_partners="number_of_following_partners"
          @follow-feed="followFeed"
          @follow-group-feed="followGroupFeed"
          @follow-partner-feed="followPartnerFeed"
          @is-shown="changeToShow"
        />
        <feed-activity-feed
          v-if="isShowing"
          :loggedUser="loggedUser"
          :dashboardPath="dashboardPath"
          :isShowing="isShowing"
          :number_of_following_departments="number_of_following_departments"
          :following_departments="following_departments"
          :following_groups="following_groups"
          :following_partners="following_partners"
          :isPartnerTenant="isPartnerTenant"
          @follow-feed="followFeed"
          @follow-group-feed="followGroupFeed"
          @follow-partner-feed="followPartnerFeed"
          @to-feed="changeToShow"
        />
        <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
      </section>
    </div>
  </section>
</template>
<script lang="ts">
import * as Cookies from "js-cookie";
import FeedService from "../../services/FeedService";
import ResourcesUpload from "../../../library/components/ResourcesUpload.vue";
import Notification from "../../../components/Notification.vue";
import FeedDetails from "./FeedDetails.vue";
import FeedPosts from "../FeedPosts.vue";
import FeedActivityFeed from "./FeedActivityFeed.vue";
import { isPartnerTenant } from "../../../utils/CustomUtils";
import { FEATURES, hasPermission, PERMISSION_TYPES } from "../../../utils/PermissionUtils";
import CommonTitle from '../../../components/CommonTitle.vue';

export default {
  components: { CommonTitle, ResourcesUpload, Notification, FeedDetails, FeedPosts, FeedActivityFeed },
  name: "feed-index",
  props: ["isAdmin"],
  data() {
    return {
      posts: [],
      isLoadingPosts: true,
      isLoadingScroll: false,
      isFetchPending: false,
      searchHolder: "",
      pageCount: 1,
      isNotificationActive: false,
      number_of_following_departments: 0,
      number_of_following_groups: 0,
      number_of_following_partners: 0,
      following_departments: [],
      following_groups: [],
      following_partners: [],
      notification: { msg: "", isError: false, icon: null },
      searchResultsShown: false,
      isShowing: false,
      isPartnerTenant: null,
      dashboardPath: null,
      loggedUser: null,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  async created() {
    this.service = new FeedService();
    await this.getFeedData();
    this.isPartnerTenant = isPartnerTenant();
    this.scroll();
    if (this.isPartnerTenant) {
      this.get_following_partners();
      this.countFollowingPartners();
    } else {
      this.get_following_departments();
      this.countFollowingDepts();
    }
    this.get_following_groups();
    this.countFollowingGroups();
    await this.getPosts();
  },
  methods: {
    async getFeedData() {
      const res = await this.service.getPredata();
      if (res.loggedUser) {
        this.dashboardPath = res.dashboard_path;
        this.coverImg = res.header_image_url;
        this.loggedUser = {
          id: res.loggedUser,
          profile_pic: res.profile_pic,
          fullname: res.user_fullname,
          isAdmin: this.isAdmin,
          departments: res.departments,
          groups: res.groups,
          partners: res.partners,
          hasWriteAccess: hasPermission(FEATURES.ACTIVITY_FEED, PERMISSION_TYPES.READ_WRITE),
          userDepts: res.user_departments.map((e) => e.name),
        };
      }
    },
    showAllComments(post) {
      this.posts = this.posts.map((p) => {
        if (post.id === p.id) {
          p.shrinkComments = false;
        }
        return p;
      });
    },
    checkCommentSize() {
      this.posts = this.posts.map((p) => {
        if (p.comments.length >= 3 && p.shrinkComments === undefined) {
          p.shrinkComments = true;
        }
        return p;
      });
    },
    setIsLoading(isLoading) {
      this.isLoadingPosts = isLoading;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    async getPosts(pageNo, minPosts = 3) {
      this.pageCount = pageNo ? pageNo : this.pageCount;
      let newPosts = [];
      // with this while loop, we make sure that as a result we get at least 5 new posts
      while (newPosts.length <= minPosts) {
        this.isFetchPending = true;
        // get all posts from getstream (even if they're not the right type)
        const rawRes = await this.service.getPosts({ q: this.searchHolder, page: this.pageCount });
        if (rawRes.length === 0) {
          break;
        }
        // filter out right type of posts
        const res = rawRes.filter((p) => p.verb.includes("post"));
        newPosts = [...newPosts, ...res];
        if (newPosts.length <= minPosts) {
          this.pageCount++;
        }
      }
      this.posts = newPosts;
      this.posts &&
        this.posts.forEach((p) => {
          p.object = JSON.parse(p.object);
        });
      this.checkCommentSize();
      this.isFetchPending = false;
      this.isLoadingPosts = false;
    },
    async searchFeed(searchInput) {
      if (searchInput.length > 0) {
        this.searchResultsShown = true;
      } else this.searchResultsShown = false;
      this.searchHolder = searchInput;
      this.isLoadingPosts = true;
      await this.getPosts(1, 0);
    },
    async updatePostReaction(p) {
      p.object = JSON.parse(p.object);
      this.posts = this.posts.map((e) => {
        if (e.id === p.id) {
          e.own_reactions = p.own_reactions;
          e.reaction_counts = p.reaction_counts;
          e.comments = p.comments;
          e.newComment = "";
        }
        return e;
      });
    },
    async scroll() {
      window.onscroll = async () => {
        await this.infiniteScroll();
      };
    },
    async infiniteScroll() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;
      if (bottomOfWindow && this.pageCount !== 0 && !this.searchResultsShown && !this.isFetchPending) {
        this.pageCount += 1;
        if (!this.isLoadingPosts) {
          this.isLoadingScroll = true;
        }
        if (!this.isFetchPending) {
          let newPosts = [];
          while (newPosts.length <= 3) {
            this.isFetchPending = true;
            // get all posts from getstream (even if they're not the right type)
            const rawRes = await this.service.getPosts({ q: this.searchHolder, page: this.pageCount });
            if (rawRes.length === 0) {
              this.pageCount = 0;
              break;
            }
            // filter out right type of posts
            const res = rawRes.filter((p) => p.verb.includes("post"));
            newPosts = [...newPosts, ...res];
            if (newPosts.length <= 3) {
              this.pageCount++;
            }
          }
          newPosts.forEach((p) => {
            p.object = JSON.parse(p.object);
            this.posts.push(p);
          });
          this.isLoadingScroll = false;
          this.isFetchPending = false;
          this.checkCommentSize();
        }
      }
    },
    shownStyle() {
      return !this.isShowing ? "flex" : "block";
    },
    changeToShow() {
      this.isShowing = !this.isShowing;
    },
    countFollowingDepts() {
      for (let d of this.loggedUser.departments) if (d.is_following) this.number_of_following_departments += 1;
    },
    following(param) {
      this.number_of_following_departments += param;
    },
    countFollowingGroups() {
      for (let d of this.loggedUser.groups) if (d.is_following) this.number_of_following_groups += 1;
    },
    followingGroup(param) {
      this.number_of_following_groups += param;
    },
    countFollowingPartners() {
      for (let d of this.loggedUser.partners) if (d.is_following) this.number_of_following_partners += 1;
    },
    followingPartner(param) {
      this.number_of_following_partners += param;
    },
    async followFeed(isFollowing: boolean, department: string) {
      const res = await this.service.followFeed({ isFollowing, department }, this.headers);
      if (res?.success) {
        if (isFollowing) {
          this.following(1);
        } else {
          this.following(-1);
        }
        this.setIsLoading(true);
        this.getPosts(1);
        if (this.loggedUser.departments.length != 0)
          this.loggedUser.departments = this.loggedUser.departments.map((d) => {
            if (d.name === department) d.is_following = isFollowing;
            return d;
          });
        this.get_following_departments();
      } else {
        this.showNotification({ msg: "Server error!", isError: true, icon: "fad fa-exclamation-circle" });
      }
    },
    async followGroupFeed(isFollowing: boolean, group: string) {
      const res = await this.service.followGroupFeed({ isFollowing, group }, this.headers);
      if (res?.success) {
        if (isFollowing) {
          this.followingGroup(1);
        } else {
          this.followingGroup(-1);
        }
        this.setIsLoading(true);
        this.getPosts(1);
        if (this.loggedUser.groups.length != 0)
          this.loggedUser.groups = this.loggedUser.groups.map((d) => {
            if (d.name === group) d.is_following = isFollowing;
            return d;
          });
        this.get_following_departments();
      } else {
        this.showNotification({ msg: "Server error!", isError: true, icon: "fad fa-exclamation-circle" });
      }
    },
    async followPartnerFeed(isFollowing: boolean, partner: string) {
      const res = await this.service.followPartnerFeed({ isFollowing, partner }, this.headers);
      if (res?.success) {
        if (isFollowing) {
          this.followingPartner(1);
        } else {
          this.followingPartner(-1);
        }
        this.setIsLoading(true);
        this.getPosts(1);
        if (this.loggedUser.partners.length != 0)
          this.loggedUser.partners = this.loggedUser.partners.map((d) => {
            if (d.name === partner) d.is_following = isFollowing;
            return d;
          });
        this.get_following_partners();
      } else {
        this.showNotification({ msg: "Server error!", isError: true, icon: "fad fa-exclamation-circle" });
      }
    },
    get_following_departments() {
      const list = new Array();
      for (let dep of this.loggedUser.departments) {
        if (dep.is_following) {
          list.push(dep);
        }
      }
      this.following_departments = list;
    },
    get_following_groups() {
      const list = new Array();
      for (let dep of this.loggedUser.groups) {
        if (dep.is_following) {
          list.push(dep);
        }
      }
      this.following_groups = list;
    },
    get_following_partners() {
      const list = new Array();
      for (let dep of this.loggedUser.partners) {
        if (dep.is_following) {
          list.push(dep);
        }
      }
      this.following_partners = list;
    },
  },
  computed: {
    title_text() {
      if (this.isPartnerTenant) {
        return "News & Updates";
      }

      return "Campus360";
    }
  },
};
</script>

<style lang="scss" scoped>
.updates-title-wrapper {
  padding: 24px 0 0;
}
</style>
