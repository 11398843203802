<template>
  <section>
    <div class="passport-step-wrapper">
      <div v-for="step of stepsData" :key="step.name" class="iu-passport-step">
        <img :src="`${assetPath}badges/${step.badgeName}${step.percentage === 100 ? '-completed' : ''}.svg`" class="passport-step-badge" :style="step.percentage === 100 ? 'opacity: 1': ''"/>
        <div class="iu-passport-step-completed-wrapper">
          <div class="iu-passport-step-name-container">
            <span class="iu-passport-step-name">
              {{step.name}}
            </span>
            <div class="iu-passport-step-no"
              :style="step.percentage === 100 ? 'color: #50cdb7; font-weight:600' : ''">
              <span v-html="step.percentage === 100 ? `<i class='fa-sharp fa-solid fa-circle-check'></i>` : `${step.stepsDone}/${step.totalSteps}`"></span>
            </div>
          </div>
          <div class="iu-passport-step-percentage-container">
            <div class="iu-passport-step-percentage-bar">
               <div class="iu-passport-step-percentage-bar-completed" :style="`width: ${step.percentage}%`"></div>
            </div>
            <div class="iu-passport-step-percentage" :style="step.percentage === 100 ? 'color: #50cdb7' : ''">{{step.percentage}}%</div>
          </div>
          <a :aria-label="`Edit ${step.name}`" class="iu-main-btn x-small text-only main" :href="`/passport?step=${encodeURIComponent(step.name)}`">
            {{step.percentage !== 0 ? 'Edit' : 'Get Started'}}
            <i aria-hidden="true" class="fa-solid fa-chevron-right iu-main-button-arrow-icon" style="color: #5E50B5"></i>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
export default {
  components: { },
  name: "passport-step-picker",
  props: ['stepsData'],
  data() {
    return {
      assetPath: process.env.MEDIA_URL,
    };
  },
  created() {
    },
  methods: {
  },
  computed: {},
};
</script>

