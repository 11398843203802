// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./components/App.vue";
import UserStatus from "./users/components/UserStatus.vue";
import UserImport from './users/UserImport';
import GoogleAppsManager from "./GooglePlatformLoader";
import DiversityGlobal from "./Global";
import MeetingsChat from "./meetings/MeetingsChat";
import GroupManageView from './groups/GroupManageView';
import PartnerManageView from './partneraccounts/PartnerManageView';
import PartnerInitializeView from './partneraccounts/PartnerInitializeView';
import PassportView from './passport/PassportView';
import DropzoneUploader from './form/DropzoneUploader';
import SmallDropzoneUploader from './form/SmallDropzoneUploader';
import MultipleInputSelectClass from './form/MultipleInputSelect';
import PassportStepPickerView from './passport/PassportStepPickerView';
import MentorMeetingsAnalytics from "./analytics/MentorMeetingsAnalytics";
import DepartmentsTable from './settings/DepartmentsTable';
import GroupsTable from './settings/GroupsTable';
import PartnersTable from './settings/PartnersTable';
import ContactGroupsTable from './settings/ContactGroupsTable';
import MentorGroupsTable from './settings/MentorGroupsTable';
import AreasTable from './settings/AreasTable';
import MultiselectInterface from './form/MultiselectInterface';
import FirstLogin from './accounts/FirstLogin';
import PoliciesManageView from './policies/PoliciesManageView'
import ProgramsManageView from './programs/ProgramsManageView'
import TopNavbarView from './top_navbar/TopNavbarView';
import SubscriptionForm from './newsletter_subscription/SubscriptionForm'
import NotificationInterface from "./components/NotificationInterface";
import BlogsView from "./blogs/BlogsView";
import RequestADemoView from "./marketing/RequestADemoView";
import PartnerWithIncludifiView from "./marketing/PartnerWithIncludifiView";
import FirstGenSuccessView from "./marketing/FirstGenSuccessView";

// moved from cpanel.ts
import EventRepeatDialogView from "./events/EventRepeatDialog";
import EventDateTimePickers from './events/EventDateTimePickers';
import LicenseManager from "./licenses/LicenseManager";
import RoleManager from './roles/RoleManager';
import PermissionsManager from './roles/PermissionsManager';
import NewsletterAdmin from "./newsletter_admin/NewsletterAdmin";
import { registerLicense } from '@syncfusion/ej2-base';
import BlogDetailsView from "./blogs/BlogDetailsView";
import { getClientData, LogService } from "./services/LogService";

import PartnerTenantLandingApp from "./partners/PartnerTenantLandingApp";
import PartnerTenantLoginRegisterApp from "./partners/PartnerTenantLoginRegisterApp";

import PartnerResourceLibrary from "./library/PartnerResourceLibrary";

import AppBaseView from "./AppBaseView";

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  const logService = new LogService();

  const additionalInfo = getClientData()
  const payload = {
    'error': err,
    'info': info,
    'additionalInfo': additionalInfo,
  }

  logService.log(payload)
}

Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV === 'production'){
    const logService = new LogService();
    const additionalInfo = getClientData()
    const payload = {
      'error': err,
      'info': info,
      'additionalInfo': additionalInfo,
    }
    logService.log(payload)
  }
  else{
    console.error(err)
  }
}

registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFaclxJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1iW39XdHVVTmlZUkY=');

declare global {
    interface Window {
        diva: any;
    }
}

class DiversityApp {
    mountPoint: HTMLElement;
    private userStatusApp: any;
    protected userStatusData: any;

    constructor() {
        this.mountPoint = document.querySelector("#vue-app-platform");
        this.registerPlatformApps();

        document.addEventListener("DOMContentLoaded", () => {
            this.loadPlatformApps();
            this.loadGlobalApps();
        });
    }

    loadPlatformApps(): void {
        /* eslint-disable no-new */
        /* Global component app */
        let userStatusMountPoint: HTMLElement = document.querySelector(
            ".da-user-status-component"
        );
        let userStatusDataComponent: HTMLElement = document.querySelector(
            ".js-status-data"
        );
        let userStatusMenuComponent: HTMLElement = document.querySelector(
            ".js-menu-data"
        );
        let logoUrlComponent: HTMLElement = document.querySelector(
            ".js-logo-url"
        );

        let mainApp = new Vue({
            components: { App },
            template: "<App/>"
        });
        this.mountPlatformApp(mainApp);

        if (!userStatusDataComponent) {
            return;
        }

        this.userStatusData = JSON.parse(userStatusDataComponent.dataset.userStatus);

        if (userStatusMountPoint) {
            let menuItems = JSON.parse(userStatusMenuComponent.dataset.menuOptions);
            let logoUrl = logoUrlComponent.dataset.logoUrl;
            let _self = this;
            this.userStatusApp = new Vue({
                components: { UserStatus },
                template: `<UserStatus :nav-menu-items="menuItems" :logo-url="logoUrl" :user-data="userData" ref="userStatusMenu" />`,
                data() {
                    return {
                        menuItems: menuItems,
                        userData: _self.userStatusData,
                        logoUrl
                    };
                },
                methods: {
                    getUserMenuItems() {
                        return this.$refs.userStatusMenu.getMenuItems();
                    }
                }
            });
            this.userStatusApp.$mount(userStatusMountPoint);
        }
    }

    loadGlobalApps(): void {
        const global = DiversityGlobal.getInstance();
        global.searchBoxApp();
        global.mobileMenuHandler();
        global.tenantMobileMenuHandler();
        global.closeTaskNotification();
        global.showCookieDisclaimer();
    }

    registerPlatformApps(): void {
        window.diva.ui["GoogleAppsManager"] = GoogleAppsManager;
        window.diva.ui.UserImport = UserImport;
        window.diva.ui.MeetingsChat = MeetingsChat;
        window.diva.ui.GroupManageView = GroupManageView;
        window.diva.ui.PartnerManageView = PartnerManageView;
        window.diva.ui.PartnerInitializeView = PartnerInitializeView;
        window.diva.ui.PassportView = PassportView;
        window.diva.ui.MentorMeetingsAnalytics = MentorMeetingsAnalytics;
        window.diva.ui.DropzoneUploader = DropzoneUploader;
        window.diva.ui.SmallDropzoneUploader = SmallDropzoneUploader;
        window.diva.ui.MultipleInputSelect = MultipleInputSelectClass;
        window.diva.ui.PassportStepPickerView = PassportStepPickerView;
        window.diva.ui.DepartmentsTable = DepartmentsTable;
        window.diva.ui.GroupsTable = GroupsTable;
        window.diva.ui.PartnersTable = PartnersTable;
        window.diva.ui.ContactGroupsTable = ContactGroupsTable;
        window.diva.ui.AreasTable = AreasTable;
        window.diva.ui.MultiselectInterface = MultiselectInterface;
        window.diva.ui.PoliciesManageView = PoliciesManageView;
        window.diva.ui.ProgramsManageView = ProgramsManageView;
        window.diva.ui.BlogsView = BlogsView;
        window.diva.ui.BlogDetailsView = BlogDetailsView;
        window.diva.ui.SubscriptionForm = SubscriptionForm;
        window.diva.ui.NotificationInterface = NotificationInterface;

        window.diva.ui.LicenseManager = LicenseManager;
        window.diva.ui.RoleManager = RoleManager;
        window.diva.ui.PermissionsManager = PermissionsManager;
        window.diva.ui.EventDateTimePickers = EventDateTimePickers;
        window.diva.ui.FirstLogin = FirstLogin;
        window.diva.ui.TopNavbarView = TopNavbarView;
        window.diva.ui.MentorGroupsTable = MentorGroupsTable;
        window.diva.ui.ContactGroupsTable = ContactGroupsTable;
        window.diva.ui.NewsletterAdmin = NewsletterAdmin;

        window.diva.ui.PartnerTenantLoginRegisterApp = PartnerTenantLoginRegisterApp;
        window.diva.ui.PartnerTenantLandingApp = PartnerTenantLandingApp;
        window.diva.ui.EventRepeatDialogView = EventRepeatDialogView;
        window.diva.ui.PartnerResourceLibrary = PartnerResourceLibrary;
        window.diva.ui.RequestADemoView = RequestADemoView;
        window.diva.ui.PartnerWithIncludifiView = PartnerWithIncludifiView;
        window.diva.ui.FirstGenSuccessView = FirstGenSuccessView;

        window.diva.ui.AppBaseView = AppBaseView;
    }

    mountPlatformApp(app: any): void {
        const stage = document.createElement("div");
        this.mountPoint.appendChild(stage);
        app.$mount(stage);
    }

    namespace() {
        if (!window.diva) window.diva = {};
        if (!window.diva.ui) window.diva.ui = {};
    }

}

new DiversityApp();
