<template>
  <div class="da-standard-grid-16">
    <div class="da-student-dashboard">
      <div class="lg-space-between">
        <section style="margin-top: 24px">
          <h1 v-if="$route.params.id">Edit Campus App</h1>
          <h1 v-else>Add Campus App</h1>
        </section>
        <button
          aria-label="Add new Campus App"
          class="iu-btn-light big title-btn"
          @click="changePage('/campusapps/')"
        >
          Back/Cancel
        </button>
      </div>

      <div>
        <form v-if="!isLoading" class="ca-form" @submit.prevent="" id="eu-form" enctype="multipart/form-data">
          <div class="ca-form-section small-dropzone-container">
            <small-dropzone-component
              v-if="logoInput"
              :imageSrc="app.logo_url"
              :imageInput="logoInput"
              :maxImageSize="image_size_data.max_size"
              :maxImageSizeFormat="image_size_data.formatted"
              changeButtonText="Change Logo"
              uploaderText="Add Logo"
              @removeImage="handleRemoveLogo"
              @uploadImage="prepareLogoUpload"
            />
            <input type="file" name="logo" id="logo" class="js-input-file-control da-components-hidden-file-control" accept="image/*" maxlength="400" />

            <div class="inup-form-element-container" style="margin-top: 40px">
              <input :disabled="isDeptLead" type="text" v-model="app.name" id="name" class="inup-input" maxlength="255" required="" />
              <div class="inup-form-label-container">
                <label for="name">App Name <span>*</span></label>
              </div>
            </div>
            <div class="inup-form-element-container" style="margin-top: 40px">
              <input :disabled="isDeptLead" type="text" v-model="app.url" id="name" class="inup-input" maxlength="255" required="" />
              <div class="inup-form-label-container">
                <label for="name">URL <span>*</span></label>
              </div>
            </div>
            <div class="inup-form-element-container">
              <textarea name="description" cols="40" rows="6" v-model="app.description" id="desc" class="inup-textarea-input no-resize" />
              <div class="inup-form-label-container">
                <label for="desc">Description</label>
              </div>
            </div>
            <div class="multiselect-wrapper" style="width: 100%; margin: 0; position: relative; height: 50px">
              <multiselect
                v-model="app.campus_focus_areas"
                :options="focus_areas"
                :multiple="true"
                :max="5"
                label="name"
                track-by="name"
                :close-on-select="false"
                placeholder=""
              >
              </multiselect>
              <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer; left: 96%"></i>
              <div class="inup-form-label-container">
                <label>Focus Areas</label>
              </div>
            </div>
            <div class="multiselect-wrapper" style="width: 100%; margin: 0; margin-top: 24px; position: relative; height: 50px">
              <multiselect
                :disabled="isDeptLead"
                v-model="app.department"
                :options="departments"
                :multiple="false"
                label="name"
                track-by="name"
                :close-on-select="true"
                :allow-empty="false"
                placeholder=""
              >
              </multiselect>
              <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer; left: 96%"></i>
              <div class="inup-form-label-container">
                <label>{{deptSingular}} <span>*</span></label>
              </div>
            </div>
          </div>
          <button aria-label="Add new Campus App" class="iu-btn-solid big title-btn" @click.prevent="submitForm">Save</button>
          <button
            aria-label="Add new Campus App"
            class="iu-btn-light big title-btn"
            @click.prevent="changePage('/campusapps/')"
          >
            Cancel
          </button>
        </form>
        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>
      </div>
    </div>
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import SmallDropzoneComponent from "../../form/components/SmallDropzoneComponent.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import FeedService from "../../feed/services/FeedService";
import CampusAppService from "../services/CampusAppService";
import Notification from "../../components/Notification.vue";
import { isURLInvalid } from "../../utils/CustomUtils";
import { useCommonDataStore } from "../../stores/commonData";

const msgs = {
  addSuccess: "Campus App Successfully Created.",
  addErr: "Error while creating Campus App.",
  updateSuccess: "Campus App Successfully Updated.",
  updateErr: "Error while updating Campus App.",
};

export default {
  name: "campus-apps-form",
  components: { SmallDropzoneComponent, Multiselect, LoadingSpinner, Notification },
  props: ["isAdmin", "isDeptLead"],
  computed: {
    deptSingular() {
      return useCommonDataStore().getFeatureName('department_singular')
    }
  },
  data() {
    return {
      logoInput: null,
      logo: {},
      image_size_data: { max_size: 2621440, formatted: "2.5 MB" },
      focus_areas: [],
      departments: [],
      isLoading: false,
      feedService: new FeedService(),
      service: new CampusAppService(),
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      mode: null,
      app: {
        name: "",
        url: "",
        description: "",
        campus_focus_areas: [],
        department: null,
        logo_url: "",
      },
    };
  },
  async created() {
    this.setMode();
    const { focus_areas, departments } = await this.service.getFilterData();
    this.focus_areas = focus_areas;
    this.departments = departments;
  },
  methods: {
    async setMode() {
      if (this.$route.path.startsWith("/campusapps/add")) {
        this.mode = "add";
        if (!this.isAdmin) {
          this.$router.push(`/campusapps/`);
        }
      } else {
        const appId = this.$route.path.split("/").pop();
        this.mode = "update";
        this.isLoading = true;
        this.app = await this.service.getOne(appId);
        if (this.isDeptLead !== this.app.department.name && !this.isAdmin) {
          this.$router.push(`/campusapps/`);
        }
        this.isLoading = false;
      }
      this.getLogoInput();
    },
    getLogoInput() {
      setTimeout(() => {
        this.logoInput = document.querySelector("#logo");
      }, 0);
    },
    changePage(page) {
      this.$router.push(page);
    },
    parseData() {
      const appDTO = { ...this.app };
      appDTO.department = this.app.department.id;
      appDTO.campus_focus_areas = this.app.campus_focus_areas.map((e) => e.id);
      return appDTO;
    },
    validateForm() {
      if (!this.app.name || !this.app.url || !this.app.department) {
        this.showNotification({ msg: "Error: You must fill in all required fields", isError: true, icon: "fad fa-exclamation-circle" });
        return false;
      }
      if (isURLInvalid(this.app.url)) {
        this.showNotification({ msg: "Error: You must submit a valid URL.", isError: true, icon: "fad fa-exclamation-circle" });
        return false;
      }
      return true;
    },
    getErrorMsg(res) {
      if (res?.Error.includes("code='unique'")) {
        return "Error: There is already an app with that name or URL.";
      }
      return msgs[`${this.mode}Err`];
    },
    async submitForm() {
      this.isLoading = true;
      const uploadRes = await this.uploadLogo();
      if (uploadRes.success) {
        const isValid = this.validateForm();
        const appDTO = isValid && this.parseData();
        if (appDTO) {
          const res = await this.service[this.mode](appDTO);
          if (res.success) {
            this.showNotification({ msg: msgs[`${this.mode}Success`], icon: "fad fa-check-circle" });
            this.changePage("/campusapps/");
          } else {
            this.showNotification({ msg: this.getErrorMsg(res), isError: true, icon: "fad fa-exclamation-circle" });
          }
        }
      } else {
        this.showNotification({ msg: "App Logo Upload Failed", isError: true, icon: "fad fa-exclamation-circle" });
      }
      this.isLoading = false;
    },
    handleRemoveLogo() {
      this.logo = {};
    },
    prepareLogoUpload(fileObject) {
      this.logo = fileObject;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    async uploadLogo() {
      let logoRes;
      if (this.logo && Object.keys(this.logo).length > 0) {
        logoRes = await this.feedService.uploadFile(this.logo, this.headers);
        if (logoRes?.resource) {
          this.app.logo_url = logoRes.resource;
        } else {
          return { success: false, msg: "App Logo Upload Failed" };
        }
      }
      return { success: true };
    },
  },
};
</script>
