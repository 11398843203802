import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import router from './router';
import { createPinia, PiniaPlugin } from 'pinia';
import Notification from './common/Notification.vue';
import { useCommonDataStore } from './stores/commonData';
import { useAuthStore } from './stores/auth';
import { useLicenseStore } from './stores/license';
import UserStatus from './users/components/UserStatus.vue';
import LoadingSpinner from "./components/LoadingSpinner.vue";
import TopNavbar from "./top_navbar/components/TopNavbar.vue";

const pinia = createPinia();
Vue.use(VueCompositionAPI);
Vue.use(PiniaPlugin);

export default class AppBaseView {
  protected mountPoint: HTMLElement;

  constructor(el: HTMLElement) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.run();
  }

  async initializeData() {
    // Initialize your stores and load data
    const commonDataStore = useCommonDataStore();
    const authStore = useAuthStore();
    const licenceStore = useLicenseStore();

    await commonDataStore.loadFeatureNames();
    commonDataStore.loadData();
    authStore.loadUserData();
    await licenceStore.loadLicenses();
  }

  async run(): Promise<void> {
    let userStatusMenuComponent: HTMLElement = document.querySelector('.js-menu-data');
    let logoUrlComponent: HTMLElement = document.querySelector('.js-logo-url');
    let userStatusDataComponent: HTMLElement = document.querySelector('.js-status-data');
    let menuItems = JSON.parse(userStatusMenuComponent.dataset.menuOptions);
    let logoUrl = logoUrlComponent.dataset.logoUrl;
    let userData = JSON.parse(userStatusDataComponent.dataset.userStatus);
    await this.initializeData();
    const app = new Vue({
      components: { Notification, UserStatus, LoadingSpinner, TopNavbar },
      router,
      pinia,
      template: `
      <section v-if="isLoading">
        <loading-spinner wrapperClass="wrapper" />
      </section>
      <section v-else>
        <nav class="eu-sidebar" id='sidebar' role="navigation">
          <UserStatus :nav-menu-items="menuItems" :logo-url="logoUrl" :user-data="userData" ref="userStatusMenu" />
        </nav>
        <nav id="top-navbar-wrapper">
          <top-navbar />
        </nav>
        <div id='content-wrapper' class='eu-content-wrapper'>
            <div id='content' class="da-page-content">
              <vue-progress-bar></vue-progress-bar>
              <notification />
              <router-view :key="$route.fullPath"></router-view>
            </div>
        </div>
        </section>
      `,
      data() {
        return {
          isLoading: true,
          commonDataStore: useCommonDataStore(),
          authStore: useAuthStore(),
          licenceStore: useLicenseStore(),
          menuItems,
          userData,
          logoUrl
        };
      },
      mounted() {},
      async created() {
        this.isLoading = false;
      },
    });

    app.$mount(this.mountPoint);
  }
}
