<template>
  <section class="partner-tenant-home-footer-container">
    <div class="content-container">
      <div class="partner-tenant-home-footer-links-wrapper">
        <router-link
          to="/partner/home"
        >
          <img
            src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/BeGlobalii-logo-white.webp"
            alt="BeGlobalii logo"
            class="partner-tenant-home-footer-logo"
          >
        </router-link>
        <div class="partner-tenant-home-footer-links-container">
          <div class="partner-tenant-home-footer-socials-container">
            <a class="partner-tenant-home-footer-social-icon" href="https://www.instagram.com/beglobalii/" target="_blank" rel="noopener noreferrer">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a class="partner-tenant-home-footer-social-icon" href="https://www.linkedin.com/company/diversity-abroad" target="_blank" rel="noopener noreferrer">
              <i class="fa-brands fa-linkedin"></i>
            </a>
            <!-- <a href="#" class="partner-tenant-home-footer-social-icon">
              <i class="fa-brands fa-twitter"></i>
            </a> -->
            <a class="partner-tenant-home-footer-social-icon" href="mailto:community@beglobalii.com" >
              <i class="fa-solid fa-envelope"></i>
            </a>
          </div>
          <router-link
            to="/partner/home/about-us"
            class="partner-tenant-home-footer-link-item">
              Resources
          </router-link>
          <router-link
            to="/partner/home/about-us"
            class="partner-tenant-home-footer-link-item">
              Partner With Us
          </router-link>
          <router-link
            to="/partner/home/about-us"
            class="partner-tenant-home-footer-link-item">
            Privacy
          </router-link>
          <router-link
            to="/partner/home/about-us"
            class="partner-tenant-home-footer-link-item">
              Terms of Service
          </router-link>
        </div>
      </div>
      <div class="partner-tenant-home-footer-send-email-container">
        <form class="partner-tenant-home-footer-send-email-form" action="POST">
          <label class="partner-tenant-home-footer-send-email-label">
            Sign up to to be the first to know about our product updates.
          </label>
          <div class="partner-tenant-home-footer-send-email-input-container">
            <input type="text" id="first_name" v-model="formData.first_name" placeholder="First Name" class="partner-tenant-home-footer-send-email-input" />
          </div>
          <div class="partner-tenant-home-footer-send-email-input-container">
            <input type="text" id="last_name" v-model="formData.last_name" placeholder="Last Name" class="partner-tenant-home-footer-send-email-input" />
          </div>
          <div class="partner-tenant-home-footer-send-email-input-container">
            <input type="email" id="email" v-model="formData.email" placeholder="Email Address" class="partner-tenant-home-footer-send-email-input" />
            <button @click.prevent="submitForm()" class="partner-tenant-home-footer-send-email-button">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { useNotificationStore } from '../../../../stores/notification';
import * as Cookies from 'js-cookie';
import NewsletterService from '../../../services/NewsletterService';
import { isValidEmailString } from '../../../../utils/CustomUtils';

export default {
  name: 'partner-tenant-footer',
  data() {
    return {
      newsletterService: new NewsletterService(),
      notificationStore: useNotificationStore(),
      formData: {
        'first_name': '',
        'last_name': '',
        'email': '',
      },
      formErrorMessagesObject: {
        'first_name': {
          'isActive': false,
          'message': ''
        },
        'last_name': {
          'isActive': false,
          'message': ''
        },
        'email': {
          'isActive': false,
          'message': ''
        },
      },
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    }
  },
  methods: {
    clearForm() {
      this.formData = {
        'first_name': '',
        'last_name': '',
        'email': '',
      }
    },
    getErrorMessage(inputId) {
      if (this.formErrorMessagesObject[inputId].isActive) {
        return this.formErrorMessagesObject[inputId].message;
      }

      return null;
    },
    validateEmail(email: string) {
      return isValidEmailString(email);
    },
    validateForm() {
      const requiredFields = ['first_name', 'last_name', 'email',];
      let isValid = true;
      requiredFields.forEach(field => {
        if (!this.formData[field]) {
          this.formErrorMessagesObject[field].isActive = true;
          isValid = false;
          this.formErrorMessagesObject[field].message = 'This field is required.';
        } else {
          this.formErrorMessagesObject[field].isActive = false;
          this.formErrorMessagesObject[field].message = '';
        }
      });
      if (this.formData.email) {
        if (!this.validateEmail(this.formData.email)) {
          this.formErrorMessagesObject.email.isActive = true;
          isValid = false;
          this.formErrorMessagesObject.email.message = 'Please enter a valid email address.';
        }
      }
      return isValid;
    },
    submitForm() {
      this.$Progress.start();
      if(this.validateForm()) {
        this.newsletterService.subscribe(this.formData, this.headers, true).then(response => {
          this.$Progress.finish();
          this.notificationStore.addNotification('Request successfully made.', 5000);
          this.clearForm();
        }).catch(error => {
          this.$Progress.fail();
          this.notificationStore.addNotification('Oops, something went wrong. Please verify the data and try again.', 5000);
        })
      } else {
        this.$Progress.fail();
        this.notificationStore.addNotification('Please verify the data and try again.', 5000);
      }
    },
  },
}
</script>
