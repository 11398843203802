import Vue from 'vue';
import SmallDropzoneComponent from "./components/SmallDropzoneComponent.vue";
import { ImageConstraints } from "../types";

export default class SmallDropzoneUploader {
  private readonly mountPoint: HTMLElement;
  protected imageInput: HTMLInputElement;
  protected imageSrc: string;
  protected maxImageSize: number;
  protected maxImageSizeFormat: string;
  protected uploaderText: string;
  protected changeButtonText: string;

  constructor(el: HTMLElement, imgInput: HTMLInputElement, imageSrc: string, imageSizeData: ImageConstraints, uploaderText: string, changeButtonText: string) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.imageInput = imgInput;
    this.imageSrc = imageSrc;
    this.maxImageSize = imageSizeData.max_size;
    this.maxImageSizeFormat = imageSizeData.formatted;
    this.uploaderText = uploaderText ? uploaderText : 'Upload profile picture';
    this.changeButtonText = changeButtonText ? changeButtonText: 'Change Image';

    this.run();
  }

  async run(): Promise<void> {
    const _self = this;

    const dropzone = new Vue({
      components: { SmallDropzoneComponent },
      template: `
        <small-dropzone-component
          :imageSrc="imageSrc"
          :imageInput="imageInput"
          :maxImageSize="maxImageSize"
          :maxImageSizeFormat="maxImageSizeFormat"
          :uploaderText="uploaderText"
          :changeButtonText="changeButtonText"
          @removeImage="handleRemoveImage"
          @uploadImage="prepareUpload"
        />
      `,

      data() {
        return {
          imageSrc: _self.imageSrc,
          imageInput: _self.imageInput,

          maxImageSize: _self.maxImageSize,
          maxImageSizeFormat: _self.maxImageSizeFormat,
          uploaderText: _self.uploaderText,
          changeButtonText: _self.changeButtonText,
          originalInputName: '',
        };
      },

      created() {
        this.originalInputName = _self.imageInput.name;
      },

      methods: {
        prepareUpload() {
          this.imageInput.name = this.originalInputName;
        },

        handleRemoveImage() {
          this.imageSrc = '';
          this.imageInput.files = null;

          this.imageInput.name = `delete-${this.originalInputName}`;
        },
      }
    });

    dropzone.$mount(this.mountPoint);
  }
}
