import HttpService from '../../services/HttpService';


export default class SeedDataService extends HttpService {
    constructor() {
      super('/cp/api');
    }
  
    addData(schema:string) {
        this.endpoint = `/cp/api/loaddata`;
        return this.get({'schema':schema});
    }
  deleteData(schema:string, headers) {
      this.endpoint = `/cp/api/loaddata`;
      return this.delete({'schema':schema}, headers);
  }
}