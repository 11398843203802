
<template>
    <div id="app">
         <ejs-chart class="container" :primaryXAxis='primaryXAxis' :pointRender='pointRender' :tooltip='tooltip'>
            <e-series-collection>
                <e-series 
                    :dataSource='seriesData' 
                    type='Column' 
                    xName='reason' 
                    yName='value'
                    name="Reason"
                    tooltipMappingName='value' 
                > </e-series>
            </e-series-collection>
        </ejs-chart>
    </div>
</template>
<script>
import Vue from "vue";
import { ChartPlugin, ColumnSeries, Category, Tooltip  } from "@syncfusion/ej2-vue-charts";

Vue.use(ChartPlugin);

export default {
    name: "column-series-chart",
    props: ['items'],
    data() {
        return {
            seriesData: this.items,
            primaryXAxis: {
                valueType: 'Category',
                },
            tooltip: {enable: true, format:  "${point.x} : ${point.tooltip}"},
        };
    },
    provide: {
        chart: [ColumnSeries, Category, Tooltip ]
    },
    methods: {
        pointRender: function(args) {
        var seriesColor = ['#6df0d2', '#1bd4d4', '#00a1b3', '#007d99', '#5e50b5',
                    '#9950cd', '#d35fa5', '#ff997b', '#fac484', '#fae994 '];
            args.fill = seriesColor[args.point.index];
        },
    },
};
</script>
<style scoped>
 .container {
   height: 350px;
 }
</style>