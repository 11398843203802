<template>
  <div class="flex-column-center">
    <main id="main" class="main-wrapper">
      <div v-if="displayHeader">
        <div class="title-wrapper">
          <common-title title="Community Library" :description="description_text" />
        </div>
      </div>
      <section class="resource-section partner">
        <div
          class="community-library-upload-button-container"
          :style="isPartnerTab ? 'padding: 0; padding-bottom: 8px;' : ''"
        >
          <resources-upload
            v-if="manageAccessOrPartnerAdmin && currentPage === 'browse' && !isDepartmentProfile && !isEmpty"
            :currentGroup="currentGroup"
            :currentPartner="currentPartner"
            :showShareBtn="showShareOnFeedBtn"
            :filterData="filterData"
            :isAdminRole="isAdmin"
            :department_name="userDepartment"
            :isPartnerTenant="isPartnerTenant"
            :list_departments="list_departments"
            :isPartnerTab="isPartnerTab"
            btnClassName="community-library-button-override"
            dropdownContainerClass="community-library-dropdown-container-override"
            componentContainerClass="community-library-component-container-override"
            iconColor="#fff"
            @reloadResources="reloadAllResources"
          />
        </div>

        <div v-if="!isDepartmentProfile && !group && !isPartnerTab">
          <navigation-tabs
            :navigationItems="navigationItems"
            :preventRedirect="true"
            @changeTab="changePage"
            initialTab="browse"
            class="community-library-nav-section partner"
          ></navigation-tabs>
        </div>

        <div v-if="!isDepartmentProfile && !group && !isPartnerTab" class="filter-section mobile-full-w">
          <div class="search-container">
            <div class="tint"></div>
            <div class="text-container">
              <h3 class="title">Explore resources</h3>
              <p class="subtitle">Search for files, videos, audio, and more</p>
            </div>
            <search-base
              ref="searchResources"
              id="search-resources"
              placeholder="Search by keyword"
              aria-label="Search guides by keyword"
              style="width: 100%"
              v-model="searchInput"
              @search="search"
            ></search-base>
          </div>
        </div>
        <div
          class="filter-container content-filters-container"
          :class="isPartnerTab ? 'partner' : ''"
          style="padding-bottom: 24px"
          v-if="!isMobile"
        >
          <multiselect-filter-component
            v-if="!isDepartmentProfile && !isPartnerTenant && areDeptsEnabled"
            :placeholder="`Any ${deptSingular}`"
            filter_name="Departments"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.departments"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            v-if="!isPartnerTab && isPartnerTenant && !(isPartnerAdminOrManager && currentPage == 'archive')"
            placeholder="Any Partner"
            filter_name="Partners"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.partners"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            placeholder="Any Type"
            filter_name="Resource Types"
            style="width: 100% !important; margin: 0px !important"
            :options="resourceLabelOptions"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            v-if="!group"
            placeholder="Any Focus Area"
            filter_name="Focus Areas"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.focus_areas"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            v-if="!group && !isPartnerTenant"
            placeholder="Any Identity"
            filter_name="Identities"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.identities"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            v-if="isPartnerTenant"
            placeholder="Any Destination"
            filter_name="Destinations"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.countries"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            v-if="isPartnerTenant"
            placeholder="Any Audience"
            filter_name="Audiences"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.audiences"
            @filter-data="createFilterData"
          />
        </div>
        <div class="option-pills-container mobile-only" :class="isPartnerTab ? 'partner' : ''" v-if="isMobile">
          <div v-if="showSearchOverallSection && isMobile" class="search-overall-container" style="margin: 0">
            <button class="search-overall-back-button" @click="closeSearchResults" aria-label="Close search results">
              <i class="fa-solid fa-arrow-left"></i>
            </button>
            <span class="search-overall-text-container">
              {{ total_num }} Result<span v-if="isPlural">s</span> for
              <span class="search-overall-search-input"> “{{ displaySearchInputText }}” </span>
            </span>
          </div>

          <button
            class="collapse-filter-button partner"
            :class="!filterSectionCollapsed ? 'active' : ''"
            style="margin-left: auto"
            aria-label="Toggle filter section"
            @click="toggleFilterSection"
          >
            <i class="fa-solid fa-filter"></i>
            Filters
          </button>

        </div>
        <div
          class="filter-container content-filters-container"
          v-if="!filterSectionCollapsed"
          style="margin-bottom: 24px; padding-right: 1.5rem; padding-left: 1.5rem;"
        >
          <multiselect-filter-component
            v-if="!isDepartmentProfile && !isPartnerTenant"
            placeholder="Any Department"
            filter_name="Departments"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.departments"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            v-if="!isPartnerTab && isPartnerTenant"
            placeholder="Any Partner"
            filter_name="Partners"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.partners"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            placeholder="Any Type"
            filter_name="Resource Types"
            style="width: 100% !important; margin: 0px !important"
            :options="resourceLabelOptions"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            v-if="!group"
            placeholder="Any Focus Area"
            filter_name="Focus Areas"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.focus_areas"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            v-if="!group && !isPartnerTenant"
            placeholder="Any Identity"
            filter_name="Identities"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.identities"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            v-if="isPartnerTenant"
            placeholder="Any Destination"
            filter_name="Destinations"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.countries"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            v-if="isPartnerTenant"
            placeholder="Any Audience"
            filter_name="Audiences"
            style="width: 100% !important; margin: 0px !important"
            :options="filterData.audiences"
            @filter-data="createFilterData"
          />
        </div>
        <div v-if="showSearchOverallSection && !isMobile" class="search-overall-container">
          <button class="search-overall-back-button" @click="closeSearchResults" aria-label="Close search results">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <span class="search-overall-text-container">
            {{ total_num }} Result<span v-if="isPlural">s</span> for
            <span class="search-overall-search-input"> “{{ displaySearchInputText }}” </span>
          </span>
        </div>

        <section v-if="!isLoading">
          <div class="eu-selected-toolbar select-toolbar mobile-margin" v-if="selectedResources.length > 1">
            <span class="eu-selected-toolbar-text">{{ selectedResources.length }} files selected.</span>
            <span
              v-if="currentPage === 'browse'"
              tabindex="0"
              @click="toggleBookmark()"
              @keyup.enter="toggleBookmark()"
              aria-label="save selected resources"
              role="button"
              style="cursor: pointer; color: #685df3; font-size: 16px"
            >
              <i class="fad fa-bookmark eu-selected-toolbar-icon" style="font-size: 14px; color: #9f87e0"></i
              >{{ bookmarkText }}</span
            >
            <span
              @click="toggleBookmark()"
              @keyup.enter="toggleBookmark()"
              v-if="currentPage === 'saved'"
              tabindex="0"
              aria-label="unsave selected resources"
              role="button"
              style="cursor: pointer; color: #685df3; font-size: 16px"
            >
              <i class="fad fa-bookmark eu-selected-toolbar-icon" style="font-size: 14px; color: #9f87e0"></i
              >{{ bookmarkText === "Unsaving..." ? bookmarkText : "Unsave" }}
            </span>
            <span
              @click="downloadItem()"
              @keyup.enter="downloadItem()"
              style="cursor: pointer; color: #685df3; font-size: 16px"
              tabindex="0"
              aria-label="download selected resources"
              role="button"
            >
              <i class="fas fa-download eu-selected-toolbar-icon" style="font-size: 14px; color: #9f87e0"></i>
              Download
            </span>
            <span
              v-if="manageAccess"
              @click="toggleArchive()"
              @keyup.enter="toggleArchive()"
              tabindex="0"
              aria-label="archive selected resources"
              role="button"
              style="cursor: pointer; color: #685df3; font-size: 16px"
            >
              <i class="fas fa-archive eu-selected-toolbar-icon" style="font-size: 14px; color: #9f87e0"></i>
              {{ archiveText }}
            </span>
            <span
              v-if="manageAccess"
              @click="openDeleteModal()"
              @keyup.enter="openDeleteModal()"
              tabindex="0"
              aria-label="Open remove dialog for selected resources"
              aria-haspopup="dialog"
              role="button"
              style="cursor: pointer; color: #685df3; font-size: 16px"
            >
              <i class="fas fa-trash-alt eu-selected-toolbar-icon" style="font-size: 14px; color: #9f87e0"></i>
              Delete
            </span>
            <span
              @click="cancelSelection()"
              @keyup.enter="cancelSelection()"
              tabindex="0"
              aria-label="cancel selection"
              role="button"
              style="cursor: pointer; color: #685df3; font-size: 16px"
            >
              <i class="fas fa-times eu-selected-toolbar-icon" style="font-size: 14px; color: #9f87e0"></i>
              Cancel
            </span>
          </div>

          <div v-if="isPartnerTab">
            <resources-grid
              :directionUp="directionUp"
              :manageAccess="manageAccess"
              :resources="partnerTabResourcesList"
              :sortBy="sortedBy"
              :sortedBy="sortedBy"
              :selectedAll="selectedAllResources"
              :selectedResources="selectedResources"
              :currentPage="currentPage"
              :department_name="userDepartment"
              :isAdminRole="isAdmin"
              :group="group"
              :partner="partner"
              :isPartnerTab="isPartnerTab"
              :idsPartnerAccountsManaged="idsPartnerAccountsManaged"
              resourceTable="resources"
              @showBurgerWindow="showBurger"
              @archive="toggleArchive"
              @feature="toggleFeature"
              @bookmark="toggleBookmark"
              @download="downloadItem"
              @openDelete="openDeleteModal"
              @openEdit="openEditModal"
              @viewTableItem="viewItem"
              @sortTable="sort"
              @toggleItemSelection="toggleSelectResourceRegular"
              @setSelectedResourceItem="setSelectedResource"
              @selectAllItems="selectAll"
              @close-burgers="closeBurgers"
              @copyResourceLink="handleCopyResourceLinkEvent"
            />
          </div>

          <div
            v-if="!isPartnerTab"
            class="resource-content-container partner"
            ref="resourcesContainer"
            @scroll="onScrollHandlerMobile($event)"
          >
            <partner-resource-card-basic
              v-for="(resource, index) in featuredResources"
              :key="`featured-${index}`"
              :resource="resource"
              :manageAccess="manageAccess"
              :isSelected="selectedResources.includes(resource.id)"
              :idsPartnerAccountsManaged="idsPartnerAccountsManaged"
              @view-item="viewItem(resource, index)"
              @edit-blog="editBlog(resource)"
              @archive-item="toggleArchive(resource)"
              @feature-item="toggleFeature(resource)"
              @unfeature-item="toggleFeature(resource)"
              @bookmark-item="toggleBookmark(resource)"
              @remove-item-bookmark="toggleBookmark(resource)"
              @download-item="downloadItem(resource.resource_url, resource.name, resource.id)"
              @delete-item="openDeleteModal(resource)"
              @edit-item="openEditModal(resource)"
              @toggle-selection="toggleSelectResourceFeatured(resource)"
              @setSelectedResourceItem="setSelectedResource"
              @copy-item-link="handleCopyResourceLinkEvent(resource.id, resource.urlSlug)"
            />
            <partner-resource-card-basic
              v-for="(resource, index) in resources"
              :key="index"
              :resource="resource"
              :manageAccess="manageAccess"
              :isSelected="selectedResources.includes(resource.id)"
              :idsPartnerAccountsManaged="idsPartnerAccountsManaged"
              @view-item="viewItem(resource, index)"
              @edit-blog="editBlog(resource)"
              @archive-item="toggleArchive(resource)"
              @feature-item="toggleFeature(resource)"
              @unfeature-item="toggleFeature(resource)"
              @bookmark-item="toggleBookmark(resource)"
              @remove-item-bookmark="toggleBookmark(resource)"
              @download-item="downloadItem(resource.resource_url, resource.name, resource.id)"
              @delete-item="openDeleteModal(resource)"
              @edit-item="openEditModal(resource)"
              @toggle-selection="toggleSelectResourceRegular(resource)"
              @setSelectedResourceItem="setSelectedResource"
              @copy-item-link="handleCopyResourceLinkEvent(resource.id, resource.urlSlug)"
            />
          </div>

          <div class="eu-empty-container partner" v-if="isEmpty">
            <div class="eu-empty">
              <div class="eu-empty-icon-container partner">
                <i class="fad fa-folder-open eu-empty-icon partner"></i>
              </div>
              <div v-if="isBrowsePage() && isAdmin" style="display: flex; flex-direction: column">
                <span class="eu-empty-title">No resources have been posted yet.</span>
                <span class="eu-empty-text">Upload files for students to view and/or download.</span>
                <span class="eu-empty-button-container">
                  <resources-upload
                    v-if="manageAccess && currentPage === 'browse' && !isDepartmentProfile"
                    :currentGroup="currentGroup"
                    :currentPartner="currentPartner"
                    :showShareBtn="showShareOnFeedBtn"
                    :filterData="filterData"
                    :isAdminRole="isAdmin"
                    :department_name="userDepartment"
                    :list_departments="list_departments"
                    :isPartnerTenant="isPartnerTenant"
                    :isPartnerTab="isPartnerTab"
                    iconColor="#fff"
                    @reloadResources="reloadAllResources"
                  />
                </span>
              </div>
              <div v-if="isBrowsePage() && !isAdmin" style="display: flex; flex-direction: column">
                <span class="eu-empty-title">No resources have been posted yet.</span>
                <span class="eu-empty-text">Check back soon!</span>
              </div>
              <div v-if="isSavedPage()" style="display: flex; flex-direction: column">
                <span class="eu-empty-title">You haven’t saved any resources yet.</span>
                <span class="eu-empty-text"
                  >Browse the community library and tap the bookmark icon to save them here.</span
                >
              </div>
              <div v-if="isArchivePage()" style="display: flex; flex-direction: column">
                <span class="eu-empty-text">No files in archive yet.</span>
              </div>
            </div>
          </div>
        </section>
        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>
        <resources-modals
          :resources="resources"
          :featuredResources="featuredResources"
          :activeResource="activeResource"
          :isDeleteModalActive="isDeleteModalActive"
          :isEditModalActive="isEditModalActive"
          :removing="removing"
          :itemToDelete="itemToDelete"
          :itemToEdit="itemToEdit"
          :list_departments="list_departments"
          :filterData="filterData"
          :currentPage="currentPage"
          :showTikTokBlock="showTikTokBlock"
          :isPartnerTab="isPartnerTab"
          :isDepartmentProfile="isDepartmentProfile"
          :areDeptsEnabled="areDeptsEnabled"
          :group="group"
          @view-item="viewItem"
          @download-item="downloadItem"
          @close-delete-modal="closeDeleteModal"
          @close-edit-modal="closeEditModal"
          @edit-item="editItem"
          @delete-item="deleteItem"
        />
          <resource-modal
            :isResourceModalActive="isModalActive"
            :activeResource="activeResource"
            @close-resource-modal="closeResourceModal"
          />
        <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
      </section>
    </main>
  </div>
</template>
<script lang="ts">
import Notification from "../../components/Notification.vue";
import ResourceLibraryService from "../services/ResourceLibraryService";
import ResourceBookmarkService from "../services/ResourceBookmarkService";
import Modal from "../../components/ModalExtended.vue";
import ResourcesTable from "./ResourcesTable.vue";
import ResourcesGrid from "./ResourcesGrid.vue";
import ResourcesUpload from "./ResourcesUpload.vue";
import ResourcesModals from "./ResourcesModals.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import JsZip from "jszip";
import * as Cookies from "js-cookie";
import { parseGETParamsIntoObject } from "../../utils/GETParametersUtil";
import {
  focusElement,
  focus_burger_menu,
  getYoutubeOrVimeoEmbedLink,
  loadTikTokScript,
  isPartnerTenant,
} from "../../utils/CustomUtils";
import { hasPermission, FEATURES, PERMISSION_TYPES, isFeatureEnabled, LICENSE_ONLY_FEATURES } from "../../utils/PermissionUtils";
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import { resourceLabelOptions } from "../../utils/constants";
import SearchBase from "../../components/formBase/SearchBase.vue";
import NavigationTabs from "../../components/NavigationTabs.vue";
import PartnerResourceCardBasic from "./PartnerResourceCardBasic.vue";
import { MediaCMSService } from "../../services/CMSService";
import BookmarkService from "../../bookmarks/services/BookmarkService";
import FeedService from "../../feed/services/FeedService";
import CommonTitle from "../../components/CommonTitle.vue";
import { useAuthStore } from "../../stores/auth";
import { useCommonDataStore } from '../../stores/commonData';
import ResourceModal from './modals/ResourceModal.vue';

const ViewMode = {
  LIST: "list",
  GRID: "grid",
};

export default {
  components: {
    Modal,
    ResourcesTable,
    ResourcesGrid,
    ResourcesUpload,
    ResourcesModals,
    LoadingSpinner,
    MultiselectFilterComponent,
    Notification,
    SearchBase,
    NavigationTabs,
    PartnerResourceCardBasic,
    CommonTitle,
    ResourceModal
  },
  name: "resources-browse",
  props: [
    "isDepartmentProfile",
    "userDepartment",
    "isAdmin",
    "group",
    "partner",
    "isPartnerTab",
    "isPartnerManager",
    "isPartnerAdmin",
  ],
  computed: {
    description_text() {
      if (this.isPartnerTenant) {
        return "Find articles, resources, and blog posts.";
      }

      return "Files uploaded by your mentors will appear here.";
    },
    preselectedId() {
      return this.$route.query.resource_id;
    },
    displayHeader() {
      return this.$route.name !== "resource_blog_details";
    },
    isPlural() {
      return this.total_num && this.total_num > 1;
    },
    partnerTabResourcesList() {
      return [...this.featuredResources, ...this.resources];
    },
    isMobile() {
      return window.innerWidth <= 576;
    },
    manageAccess() {
      return hasPermission(FEATURES.RESOURCE_LIBRARY, PERMISSION_TYPES.MANAGE);
    },
    manageAccessOrPartnerAdmin() {
      return (
        this.isPartnerAdmin ||
        this.isPartnerManager ||
        hasPermission(FEATURES.RESOURCE_LIBRARY, PERMISSION_TYPES.MANAGE)
      );
    },
    showMobileCards() {
      return (
        (this.resources && this.resources.length > 0) || (this.featuredResources && this.featuredResources.length > 0)
      );
    },
    mobileCardsResourcesList() {
      if (this.showSearchOverallSection) {
        return [...this.featuredResources, ...this.resources];
      }

      if (this.showOnly == "FEATURED") {
        return this.featuredResources;
      }

      return this.resources;
    },
    showFeaturedFilter() {
      return this.showOnly == "FEATURED";
    },
    showOtherFilter() {
      return this.showOnly == "OTHER";
    },
    displayFilterPills() {
      return this.featuredResources.length > 0 && this.resources.length > 0;
    },
    isEmpty() {
      return (
        this.resources && this.resources.length === 0 && this.featuredResources && this.featuredResources.length == 0
      );
    },
    deptSingular() {
      return useCommonDataStore().getFeatureName('department_singular')
    },
  },
  data() {
    return {
      currentGroup: null,
      currentPartner: null,
      ViewMode: ViewMode,
      libraryView: localStorage.libraryView,
      oldView: "",
      resources: [],
      featuredResources: [],
      selectedResources: [],
      selectedAllResources: false,
      selectedAllFeatured: false,
      currentPage: "browse",
      filterData: { focus_areas: [], departments: [], identities: [], audiences: [], partners: [], countries: [], career_academic_interests: [] },
      pageCount: 1,
      sortedBy: "timestamp",
      sortBy: "timestamp",
      searchInput: "",
      featuredSortedBy: "timestamp",
      featuredSortBy: "timestamp",
      archiveText: "Archive",
      bookmarkText: "Save",
      options_resource_types: [
        { name: "Document", value: "DOC" },
        { name: "Image", value: "IMG" },
        { name: "Video", value: "VID" },
        { name: "Internal Webpage", value: "URL_INTERNAL" },
        { name: "External Webpage", value: "URL_EXTERNAL" },
      ],
      directionUp: {
        name: true,
        department: true,
        timestamp: true,
      },
      directionUpFeatured: {
        name: true,
        department: true,
        timestamp: true,
      },
      selectedDepartments: [],
      selectedResTypes: [],
      selectedFocusAreas: [],
      selectedIdentities: [],
      selectedPartners: [],
      selectedDestinations: [],
      selectedAudiences: [],
      getFetchMethod: 'getResources',
      lastOpenedBurger: null,
      isModalActive: false,
      isDeleteModalActive: false,
      isEditModalActive: false,
      activeResource: { name: null, resource_url: null, description: null },
      isFeaturedCollapsed: localStorage.isFeaturedCollapsed,
      resourceLabelOptions: resourceLabelOptions.map((e) => {
        return { name: e };
      }),
      itemToDelete: {
        id: null,
        name: null,
        label: resourceLabelOptions[0],
        resource_type: null,
        resource_url: null,
      },
      itemToEdit: {
        name: "",
        description: "",
        label: resourceLabelOptions[0],
        department: { name: "" },
        is_recommended: false,
        is_partner_featured: false,
      },
      isFeaturedFilterEnabled: window.innerWidth <= 576,
      filterSectionCollapsed: true,
      isFetching: false,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      removing: false,
      isUnarchive: false,
      isLoading: true,
      list_departments: [],
      list_partners: [],
      focus_element: null,
      showTikTokBlock: false,
      isNotificationActive: false,
      notification: {
        icon: "",
        isError: false,
        msg: "",
      },
      isPartnerTenant: false,
      showShareOnFeedBtn: !this.partner,
      mobileShowList: [],
      showOnly: "FEATURED",
      blockFetching: false,
      showSearchOverallSection: false,
      total_num: 0,
      initLoad: true,
      navigationItems: [
        {
          label: "Browse Library",
          routeName: "browse",
          isRedirectUrl: false,
        },
        {
          label: "Saved Items",
          routeName: "saved",
          isRedirectUrl: false,
        },
      ],
      blogBookmarkService: null,
      feedService: new FeedService(),
      service: new ResourceLibraryService(),
      isPartnerAdminOrManager: false,
      authStore: useAuthStore(),
      idsPartnerAccountsManaged: [],
      areDeptsGroupsEnabled: false,
      areDeptsEnabled: false
    };
  },
  async mounted() {
    this.viewIconsListener();
    this.listenResize();
    if (this.preselectedId) {
      const preselectedResource = await this.service.getOneResource(this.preselectedId);
      this.viewItem(preselectedResource, preselectedResource.id);
    }
  },
  async created() {
    this.areDeptsGroupsEnabled = isFeatureEnabled(LICENSE_ONLY_FEATURES.DEPTS) || isFeatureEnabled(LICENSE_ONLY_FEATURES.GROUPS);
    this.areDeptsEnabled = isFeatureEnabled(LICENSE_ONLY_FEATURES.DEPTS)
    this.isPartnerTenant = isPartnerTenant();
    this.getFetchMethod = this.isPartnerTenant ? 'getResourcesDabi' : 'getResources';
    if (this.manageAccessOrPartnerAdmin) {
      this.navigationItems.push({
        label: "Archived Items",
        routeName: "archive",
        isRedirectUrl: false,
      });
    }
    this.bookmarkService = new ResourceBookmarkService();
    this.blogBookmarkService = new BookmarkService();
    this.isDepartmentProfile ? this.selectedDepartments.push(encodeURIComponent(this.userDepartment)) : [];
    this.selectedFocusAreas = this.group ? this.group.focus_areas.map((e) => encodeURIComponent(e.name)) : [];
    this.selectedIdentities = this.group ? this.group.identities.map((e) => encodeURIComponent(e.name)) : [];
    await this.reloadAllResources();
    this.isPartnerAdminOrManager = this.authStore.getIsPartnerAdmin || this.authStore.getIsPartnerManager;
    if (this.featuredResources.length > 0) {
      this.changeShowOnlyFilter("FEATURED");
    }
    const allFilterData = await this.service.getFilterData();
    if(this.isPartnerTenant) {
      allFilterData.countries = allFilterData.focus_areas.filter(fa => fa.type === 'destinations');
      allFilterData.focus_areas = allFilterData.focus_areas.filter(fa => fa.type !== 'destinations');
    }
    if (this.group) {
      this.currentGroup = this.group.name;
      this.filterData = {
        focus_areas: this.group.focus_areas,
        departments: [],
        partners: [],
        identities: this.group.identities,
        audiences: allFilterData.audiences
      };
    } else if (this.partner) {
      this.currentPartner = this.partner.name;
      this.filterData = allFilterData;
    } else if (this.isPartnerAdmin || this.isPartnerManager) {
      this.currentPartner = this.authStore.getUser.user.partner_name;
      this.filterData = allFilterData;
    } else {
      this.filterData = allFilterData;
    }
    this.scroll();
    if (!this.isPartnerTenant && !this.partner && this.featuredResources && this.featuredResources.length > 0) {
      this.setFeaturedVisibility(this.isFeaturedCollapsed);
    }
    this.initLoad = false;
    if (this.featuredResources.length === 0) {
      this.changeShowOnlyFilter("OTHER");
    }

    const params = parseGETParamsIntoObject();
    if (params.hasOwnProperty("page") && params["page"] === "saved") {
      this.changePage(params["page"]);
    }
    this.isUnarchive = this.currentPage === "archive" ? true : false;

    if (!this.isAdmin)
      this.list_departments = this.userDepartment ? [{ name: this.userDepartment.replace("&amp;", "&") }] : [];
    else this.list_departments = this.filterData.departments;
    this.idsPartnerAccountsManaged = this.authStore.getUser.user.ids_partner_accounts_managed;
  },
  methods: {
    editBlog(resource) {
      const cmsService = new MediaCMSService();
      window.open(cmsService.getBlogCmsUrl(resource.id), "_blank");
    },
    toggleFilterSection() {
      if (this.filterSectionCollapsed) {
        this.openFilterSection();
      } else {
        this.closeFilterSection();
      }
    },
    openFilterSection() {
      this.filterSectionCollapsed = false;
    },
    closeFilterSection() {
      this.filterSectionCollapsed = true;
    },
    openSearchResults() {
      this.displaySearchInputText = this.searchInput;
      this.showSearchOverallSection = true;
      this.isFeaturedFilterEnabled = false;
    },
    closeSearchResults(fetch_after = true) {
      const searchInputComponent = this.$refs.searchResources;
      if (searchInputComponent) {
        searchInputComponent.clearSearchInput();
      }
      this.showSearchOverallSection = false;
      this.searchInput = "";
      this.displaySearchInputText = "";
      this.isFeaturedFilterEnabled = this.isMobile;
      if (fetch_after) this.reloadAllResources();
    },
    changeShowOnlyFilter(showOnly) {
      this.showOnly = showOnly;
    },
    handleCopyResourceLinkEvent(resource_id, blog_slug = null) {
      if (blog_slug) {
        navigator.clipboard
          .writeText(window.location.origin + "/library/blog/" + blog_slug)
          .then(() => {
            this.initializeNotification("COPY_LINK", 1, false);
            this.openNotification();
          })
          .catch((e) => {
            this.initializeNotification("COPY_LINK", 1, true);
            this.openNotification();
          });
      } else {
        navigator.clipboard
          .writeText(window.location.origin + "/library?resource_id=" + resource_id)
          .then(() => {
            this.initializeNotification("COPY_LINK", 1, false);
            this.openNotification();
          })
          .catch((e) => {
            this.initializeNotification("COPY_LINK", 1, true);
            this.openNotification();
          });
      }
      if (this.lastOpenedBurger) this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
    },
    toggleSelectResourceFeatured(resource) {
      this.setSelectedResource(resource.id, "featured");
    },
    toggleSelectResourceRegular(resource) {
      this.setSelectedResource(resource.id, "resources");
    },
    loadTikTokScript,
    initializeNotification(action, number, isError) {
      this.notification.isError = isError;
      if (action == "SAVE" && !isError) {
        this.notification.msg =
          number + " item" + (number == 1 ? " was" : "s were") + " added to your saved resources.";
        this.notification.icon = "fa-solid fa-bookmark";
      } else if (action == "SAVE" && isError) {
        this.notification.msg = "Failed to save item" + (number == 1 ? "." : "s.");
        this.notification.icon = "fa-solid fa-bookmark";
      }
      if (action == "UNSAVE" && !isError) {
        this.notification.msg =
          number + " item" + (number == 1 ? " was" : "s were") + " removed from your saved resources.";
        this.notification.icon = "fa-solid fa-bookmark";
      } else if (action == "UNSAVE" && isError) {
        this.notification.msg = "Failed to remove bookmark from item" + (number == 1 ? "." : "s.");
        this.notification.icon = "fa-solid fa-bookmark";
      } else if (action == "ARCHIVED" && !isError) {
        this.notification.msg = number + " item" + (number == 1 ? " was" : "s were") + " added to archived resources.";
        this.notification.icon = "fa-solid fa-archive";
      } else if (action == "ARCHIVED" && isError) {
        this.notification.msg = "Failed to archive item" + (number == 1 ? "." : "s.");
        this.notification.icon = "fa-solid fa-archive";
      } else if (action == "UNARCHIVED" && !isError) {
        this.notification.msg =
          number + " item" + (number == 1 ? " was" : "s were") + " removed from archived resources.";
        this.notification.icon = "fa-solid fa-archive";
      } else if (action == "UNARCHIVED" && isError) {
        this.notification.msg = "Failed to unarchive item" + (number == 1 ? "." : "s.");
        this.notification.icon = "fa-solid fa-archive";
      } else if (action == "DELETED" && !isError) {
        this.notification.msg = number + " item" + (number == 1 ? " was" : "s were") + " deleted.";
        this.notification.icon = "fa-solid fa-trash-can";
      } else if (action == "DELETED" && isError) {
        this.notification.msg = "Failed to archive item" + (number == 1 ? "." : "s.");
        this.notification.icon = "fa-solid fa-trash-can";
      } else if (action == "FEATURED" && !isError) {
        this.notification.msg = number + " item" + (number == 1 ? " was" : "s were") + " added to featured resources.";
        this.notification.icon = "fa-solid fa-stars";
      } else if (action == "FEATURED" && isError) {
        this.notification.msg = "Failed to feature item" + (number == 1 ? "." : "s.");
        this.notification.icon = "fa-solid fa-stars";
      } else if (action == "UNFEATURED" && !isError) {
        this.notification.msg =
          number + " item" + (number == 1 ? " was" : "s were") + " removed from featured resources.";
        this.notification.icon = "fa-solid fa-stars";
      } else if (action == "UNFEATURED" && isError) {
        this.notification.msg = "Failed to unfeature item" + (number == 1 ? "." : "s.");
        this.notification.icon = "fa-solid fa-stars";
      } else if (action == "EDIT" && !isError) {
        this.notification.msg = "An item was successfully edited.";
        this.notification.icon = "fa-solid fa-stars";
      } else if (action == "EDIT" && isError) {
        this.notification.msg = "Failed to edit an item";
        this.notification.icon = "fa-solid fa-stars";
      } else if (action == "COPY_LINK" && !isError) {
        this.notification.msg = "Resource link successfully copied.";
        this.notification.icon = "fad fa-check-circle";
      } else if (action == "COPY_LINK" && isError) {
        this.notification.msg = "Oops, copying failed. Please try again.";
        this.notification.icon = "fad fa-exclamation-circle";
      }
    },
    clearNotification() {
      this.notification.msg = "";
      this.notification.icon = "";
      this.notification.isError = false;
    },
    openNotification() {
      this.isNotificationActive = true;
    },
    closeNotification() {
      this.isNotificationActive = false;
      this.clearNotification();
    },
    isBrowsePage() {
      return this.currentPage === "browse";
    },
    isSavedPage() {
      return this.currentPage === "saved";
    },
    isArchivePage() {
      return this.currentPage === "archive";
    },
    async sort(column) {
      this.sortBy = this.directionUp[column] ? column : `-${column}`;
      const filters = {
        sort: this.sortBy,
        departments: this.selectedDepartments,
        audiences: this.selectedAudiences,
        partners: this.selectedPartners,
        name: this.searchInput,
        resource_types: this.selectedResTypes,
        focus_areas: [...this.selectedFocusAreas, ...this.selectedDestinations],
        identities: this.selectedIdentities,
      };
      if (this.group) {
        filters["group_name"] = this.group.name;
      }
      if (this.partner) {
        filters["partners"].push(this.partner.name);
      }
      if (this.currentPage === "archive") {
        filters["archive"] = true;
      }
      const response = await this.service[this.getFetchMethod](filters);
      if (response) {
        this.resources = response.resources;
        this.total_num = response.total_num ? response.total_num : 0;
        this.total_num = this.featuredResources.length;
      }
      this.directionUp[column] = !this.directionUp[column];
      this.sortedBy = column;
      this.pageCount = 1;
    },
    async sortFeatured(column) {
      this.featuredSortBy = this.directionUpFeatured[column] ? column : `-${column}`;
      const filters = {
        sort: this.featuredSortBy,
        name: this.searchInput,
        departments: this.isDepartmentProfile ? this.selectedDepartments : [],
        partners: this.isPartnerTab ? this.selectedPartners : [],
        focus_areas: this.group ? [...this.selectedFocusAreas, ...this.selectedDestinations] : [],
        audiences: this.selectedAudiences,
        identities: this.group ? this.selectedIdentities : [],
      };
      if (this.group) {
        filters["group_name"] = this.group.name;
      }
      if (this.partner) {
        filters["partners"].push(this.partner.name);
      }
      this.featuredResources = await this.service.getFeatured(filters);
      this.directionUpFeatured[column] = !this.directionUpFeatured[column];
      this.featuredSortedBy = column;
    },
    pushAudienceTargetsBack(featured_list) {
      const sorted_list = [];
      let currentFeatureIndex = 0;

      featured_list.forEach((resource) => {
        if (resource.is_recommended) {
          sorted_list.splice(currentFeatureIndex, 0, resource);
          currentFeatureIndex += 1;
        } else {
          sorted_list.push(resource);
        }
      });

      return sorted_list;
    },
    async search(input) {
      if (input && typeof input === "string") {
        this.searchInput = input;
      }
      this.isLoading = true;
      this.pageCount = 1;
      if (this.searchInput == "") {
        this.closeSearchResults(false);
      }
      const filters = {
        sort: this.sortBy,
        departments: this.selectedDepartments,
        partners: this.selectedPartners,
        name: encodeURIComponent(this.searchInput),
        resource_types: this.selectedResTypes,
        focus_areas: [...this.selectedFocusAreas, ...this.selectedDestinations],
        audiences: this.selectedAudiences,
        identities: this.selectedIdentities,
      };
      if (this.group) {
        filters["group_name"] = this.group.name;
      }
      if (this.partner) {
        filters["partners"].push(this.partner.name);
      }
      if (this.currentPage === "saved") {
        filters["saved"] = true;
      } else if (this.currentPage === "archive") {
        filters["archive"] = true;
      }
      if (this.input) {
        filters["is_recommended"] = false;
      }
      const response = await this.service[this.getFetchMethod](filters);
      this.resources = [];
      if (response) {
        this.resources = response.resources;
        this.total_num = response.total_num ? response.total_num : 0;
      }

      if (this.currentPage === "browse") {
        filters["is_recommended"] = true;
        const responseFeatured = await this.service.getFeatured(filters);
        this.featuredResources = [];

        if (responseFeatured) {
          this.featuredResources = this.pushAudienceTargetsBack(responseFeatured);
          this.total_num += responseFeatured.length;
        }
      } else {
        this.featuredResources = [];
      }
      this.isLoading = false;

      if (this.searchInput != "" && !this.initialLoad) {
        this.openSearchResults();
      }

      if (this.featuredResources.length === 0) {
        this.changeShowOnlyFilter("OTHER");
      }
    },
    setSelectedResource(resourceId, resourceTable) {
      const isSelected = this.selectedResources.includes(resourceId);
      if (!isSelected) {
        this.selectedResources.push(resourceId);
      } else {
        this.selectedResources = this.selectedResources.filter((e) => e !== resourceId);
      }
      if (resourceTable === "featured") {
        this.selectedAllFeatured =
          this.featuredResources.filter((sr) => this.selectedResources.includes(sr.id)).length ==
          this.featuredResources.length
            ? true
            : false;
      } else if (resourceTable === "resources") {
        this.selectedAllResources =
          this.resources.filter((sr) => this.selectedResources.includes(sr.id)).length == this.resources.length
            ? true
            : false;
      }
    },
    selectAll(resources, isSelected, resourceTable) {
      const resourcesIds = resources.map((r) => r.id);
      if (isSelected) {
        const combinedResources = [...resourcesIds, ...this.selectedResources];
        this.selectedResources = [...new Set(combinedResources)];
      } else {
        // unselect all table resources
        resources.forEach((r) => {
          this.selectedResources = this.selectedResources.filter((sr) => sr !== r.id);
        });
      }

      if (resourceTable === "featured") {
        this.selectedAllFeatured = isSelected ? true : false;
      } else if (resourceTable === "resources") {
        this.selectedAllResources = isSelected ? true : false;
      }
    },
    async fetchResources() {
      this.pageCount += 1;
      this.isFetching = true;
      const filters = {
        page: this.pageCount,
        sort: this.sortBy,
        departments: this.selectedDepartments,
        partners: this.selectedPartners,
        name: this.searchInput,
        resource_types: this.selectedResTypes,
        focus_areas: [...this.selectedFocusAreas, ...this.selectedDestinations],
        audiences: this.selectedAudiences,
        identities: this.selectedIdentities,
      };
      if (this.group) {
        filters["group_name"] = this.currentGroup;
      }
      if (this.partner) {
        filters["partner_name"] = this.currentPartner;
      }
      if (this.currentPage === "saved") {
        filters["saved"] = true;
      } else if (this.currentPage === "archive") {
        filters["archive"] = true;
      }
      const response = await this.service[this.getFetchMethod](filters);
      let new_resources = [];
      if (response) {
        new_resources = response.resources;
        if (new_resources && new_resources.length > 0) {
          new_resources.forEach((r) => this.resources.push(r));
          this.total_num = response.total_num ? response.total_num : 0;
          this.total_num += this.featuredResources.length;
        } else this.pageCount = 0;
      }
      this.isFetching = false;
    },
    async onScrollHandlerMobile(event) {
      if (window.innerWidth <= 576 && !this.isFetching) {
        const resources_container = event.target;
        const newScrollLeft = resources_container.scrollLeft;
        const width = resources_container.offsetWidth;
        const scrollWidth = resources_container.scrollWidth;
        var offset = 150;

        if (
          scrollWidth - newScrollLeft - width < offset &&
          !this.isLoading &&
          this.pageCount !== 0 &&
          !this.blockFetching
        ) {
          await this.fetchResources();
        }
      }
    },
    async onScrollHandlerDesktop() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;
      if (bottomOfWindow && this.pageCount !== 0 && !this.isLoading && this.pageCount !== 0) {
        await this.fetchResources();
      }
    },
    async scroll() {
      window.onscroll = async () => {
        if (window.innerWidth > 576) {
          this.onScrollHandlerDesktop();
        }
      };
    },
    showBurger(el) {
      const burgerEl = document.querySelector(`#r${el.id}${el.timestamp}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl) {
        this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
      }
      if (burgerEl){
        burgerEl.classList.toggle("eu-resource-burger-active");
        this.lastOpenedBurger = burgerEl;
      }
    },
    async toggleBookmark(el) {
      const isMultipleFiles = el ? false : true;
      const allResources = [...this.resources, ...this.featuredResources];
      const filesToBookmark = isMultipleFiles
        ? allResources.filter((r) => this.selectedResources.includes(r.id))
        : [el];
      const unsaveMultiple = filesToBookmark.filter((e) => e.is_bookmarked === false).length === 0 ? true : false;

      if (this.lastOpenedBurger) this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
      for (const f of filesToBookmark) {
        if (!isMultipleFiles) f.is_bookmarked = !f.is_bookmarked;
        else if (unsaveMultiple) {
          f.is_bookmarked = false;
        } else f.is_bookmarked = true;
      }
      this.bookmarkText = unsaveMultiple ? "Unsaving..." : "Saving...";
      for (const f of filesToBookmark) {
        if (f.is_bookmarked) {
          if (f.resource_type === "BLOG") {
            this.blogBookmarkService.bookmarkBlogs({ target: f.id });
          } else {
            await this.bookmarkService.post({ target: f.id }, this.headers);
          }
        } else {
          if (f.resource_type === "BLOG") {
            this.blogBookmarkService.deleteBookmarkBlogs({ target: f.id });
          } else {
            await this.bookmarkService.delete({ target: f.id }, this.headers);
          }
        }
      }
      if (filesToBookmark[0].is_bookmarked) {
        this.initializeNotification("SAVE", filesToBookmark.length, false);
        this.openNotification();
      } else {
        this.initializeNotification("UNSAVE", filesToBookmark.length, false);
        this.openNotification();
      }
      this.bookmarkText = "Save";
      isMultipleFiles && (await this.reloadAllResources());
    },
    async toggleArchive(el) {
      const isMultipleFiles = el ? false : true;
      const allResources = [...this.resources, ...this.featuredResources];
      const filesToArchive = isMultipleFiles ? allResources.filter((r) => this.selectedResources.includes(r.id)) : [el];
      if (this.lastOpenedBurger) this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
      for (const f of filesToArchive) {
        f.is_archived = !this.isUnarchive;
        this.archiveText = this.isUnarchive ? "Unarchiving..." : "Archiving...";

        const res = await this.service.editResource(f, this.headers, this.group?.name, this.partner?.name);
      }
      if (filesToArchive[0].is_archived) {
        this.initializeNotification("ARCHIVED", filesToArchive.length, false);
        this.openNotification();
      } else {
        this.initializeNotification("UNARCHIVED", filesToArchive.length, false);
        this.openNotification();
      }
      this.archiveText = this.isUnarchive ? "Unarchive" : "Archive";
      this.reloadAllResources();

      this.selectedResources = [];
    },
    async toggleFeature(el) {
      if (this.isPartnerTab) {
        el.is_partner_featured = !el.is_partner_featured;
      } else {
        el.is_recommended = !el.is_recommended;
      }
      await this.service.editResource(el, this.headers, this.group?.name, this.partner?.name);
      if (this.isPartnerTab) {
        if (el.is_partner_featured) {
          this.initializeNotification("FEATURED", 1, false);
          this.openNotification();
        } else {
          this.initializeNotification("UNFEATURED", 1, false);
          this.openNotification();
        }
      } else {
        if (el.is_recommended) {
          this.initializeNotification("FEATURED", 1, false);
          this.openNotification();
        } else {
          this.initializeNotification("UNFEATURED", 1, false);
          this.openNotification();
        }
      }
      if (this.currentPage === "browse") this.reloadAllResources();
      this.closeBurgers();
    },
    viewItem(el, index) {
      if (el.resource_type === "BLOG") {
        this.$router.push(`/library/blog/${el.urlSlug}`);
        return;
      }
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      focusElement("rsources_modal_title");
      sidebar.style.display = "none";
      this.isModalActive = true;
      this.activeResource = el;
      this.activeResource.index = index;
      this.service.increaseViews(el.id);
      this.closeBurgers();
      if (el.resource_url.includes("tiktok")) {
        this.showTikTokBlock = false;
        this.loadTikTokScript("https://www.tiktok.com/embed.js").then((status) => {
          if (status === "loaded") {
            this.showTikTokBlock = true;
          }
        });
      }
    },

    async downloadItem(dataurl, filename, id) {
      const isMultiDownload = !filename ? true : false;
      if (isMultiDownload) {
        const that = this;
        const zip = new JsZip();
        const allResources = [...this.resources, ...this.featuredResources];
        const resourcesToDownload = allResources.filter(
          (r) =>
            r.resource_type !== "VID" &&
            r.resource_type !== "URL" &&
            r.resource_type !== "URL_INTERNAL" &&
            r.resource_type !== "URL_EXTERNAL" &&
            this.selectedResources.includes(r.id)
        );
        let blob = null;
        for (const r of resourcesToDownload) {
          this.service.increaseDownloaded(r.id);
          let res = await fetch(r.resource_url);
          blob = await res.blob();
          zip.file(r.name, blob, { base64: true });
        }
        zip.generateAsync({ type: "blob" }).then(function (blobs) {
          that.startDownload(blobs, null);
        });
      } else {
        this.service.increaseDownloaded(id);
        const res = await fetch(dataurl);
        const blob = await res.blob();
        this.startDownload(blob, filename);
        this.closeBurgers();
      }
    },
    closeBurgers(tableType) {
      this.lastOpenedBurger && this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
    },
    openDeleteModal(item) {
      this.isDeleteModalActive = true;
      focusElement("library_delete_modal");
      if (item) {
        this.focus_element = item?.id;
        if (!this.isPartnerTab && !isPartnerTenant) {
          this.showBurger(item);
        }
        this.itemToDelete = item;
      }
    },
    closeDeleteModal() {
      focus_burger_menu(this.focus_element, this.libraryView);
      this.isDeleteModalActive = false;
      this.removing = false;
    },
    openEditModal(item) {
      item['countries'] = [];
      this.itemToEdit = JSON.parse(JSON.stringify(item));
      if (isPartnerTenant){
        this.itemToEdit.countries = this.itemToEdit.focus_areas.filter(fa => fa.type === 'destinations').map((e) => e.name)
        this.itemToEdit.focus_areas = this.itemToEdit.focus_areas.filter(fa => fa.type !== 'destinations').map((e) => e.name)
      }
      else{
        this.itemToEdit.focus_areas = this.itemToEdit.focus_areas.map((e) => e.name);
      }
      this.itemToEdit.identities = this.itemToEdit.identities.map((e) => e.name);
      this.itemToEdit.audiences = this.itemToEdit.audiences.map((e) => e.name);
      this.itemToEdit.career_academic_interests = this.itemToEdit.career_academic_interests.map((e) => e.name);
      this.focus_element = item.id;
      focusElement("library_edit_file");
      if (!this.itemToEdit.department) {
        this.itemToEdit.department = { name: "" };
      }
      this.isEditModalActive = true;
      this.showBurger(item);
    },
    closeEditModal() {
      this.isEditModalActive = false;
      focus_burger_menu(this.focus_element, this.libraryView);
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      sidebar.style.display = "block";
    },
    async editItem(thumbnail_changed = false, thumbnail_deleted = false, thumbnail = {}) {
      if (thumbnail_deleted) {
        this.itemToEdit.thumbnail_url = "";
      } else if (thumbnail_changed) {
        const thumbnail_res = await this.feedService.uploadFile(thumbnail, this.headers);
        if (thumbnail_res && thumbnail_res.success) {
          this.itemToEdit.thumbnail_url = thumbnail_res.resource;
        }
      }

      const res = await this.service.editResource(this.itemToEdit, this.headers, this.group?.name, this.partner?.name);
      if (res.success) {
        await this.reloadAllResources();
        this.closeEditModal();
        this.initializeNotification("EDIT", 0, false);
        this.openNotification();
      } else {
        this.initializeNotification("EDIT", 0, true);
        this.openNotification();
      }
    },
    async deleteItem(id) {
      const isMultiDelete = !id ? true : false;
      let isSuccess = true;
      let failCounter = 0;
      let selectedCount = this.selectedResources.length;
      if (isMultiDelete) {
        for (const r_id of this.selectedResources) {
          this.removing = true;
          const res = await this.service.deleteResource(r_id, this.headers, this.group?.name, this.partner?.name);
          if (res.success) {
            this.selectedResources = this.selectedResources.filter((e) => e != r_id);
            if (this.selectedResources.length == 0) {
              this.reloadAllResources();
              this.closeDeleteModal();
            }
          } else {
            isSuccess = false;
            failCounter += 1;
          }
        }
        if (isSuccess) {
          this.initializeNotification("DELETED", selectedCount, false);
          this.openNotification();
        } else {
          this.initializeNotification("DELETED", failCounter, true);
          this.openNotification();
        }
      } else {
        this.removing = true;
        const res = await this.service.deleteResource(id, this.headers, this.group?.name, this.partner?.name);
        if (res.success) {
          this.reloadAllResources();
          this.closeDeleteModal();
          this.itemToDelete = {
            id: null,
            name: null,
            resource_type: null,
            resource_url: null,
          };
          this.initializeNotification("DELETED", 1, false);
          this.openNotification();
        } else {
          this.initializeNotification("DELETED", 1, true);
          this.openNotification();
        }
      }
    },
    async reloadAllResources() {
      await this.search();
    },

    startDownload(content, filename) {
      var a = document.createElement("a");
      a.download = filename ? filename : "resources";
      document.body.appendChild(a);
      a.href = URL.createObjectURL(content);
      a.style.display = "none";
      a.click();
      document.body.removeChild(a);
    },
    closeResourceModal() {
      this.isModalActive = false;
      focus_burger_menu(this.activeResource.id, this.libraryView);
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      sidebar.style.display = "block";
      this.activeResource = { name: null, resource_url: null, description: null };
    },
    setFeaturedVisibility(isCollapsed) {
      localStorage.isFeaturedCollapsed = isCollapsed;
      this.isFeaturedCollapsed = isCollapsed;
      const featuredElement: HTMLElement = document.querySelector("#euFeaturedResources");
      if (isCollapsed === "true") featuredElement.classList.add("eu-featured-resources-collapsed");
      else featuredElement.classList.remove("eu-featured-resources-collapsed");
    },
    cancelSelection() {
      this.selectedResources = [];
      this.selectedAllResources = false;
      this.selectedAllFeatured = false;
    },
    async changePage(page) {
      if (this.currentPage !== page) {
        this.searchInput = "";
        this.currentPage = page;
        this.selectedResources = [];
        this.selectedAllFeatured = false;
        this.selectedAllResources = false;
        this.isUnarchive = this.currentPage === "archive" ? true : false;
        this.archiveText = this.isUnarchive ? "Unarchive" : "Archive";
        if (this.currentPage === "archive" && this.isPartnerAdminOrManager) {
          this.selectedPartners = [encodeURIComponent(this.currentPartner)];
        }
        this.reloadAllResources();
      }
    },
    getYoutubeOrVimeoEmbedLink,
    disableIcon(icon) {
      if (icon) {
        icon.style.color = "#707070";
        icon.style.pointerEvents = "auto";
      }
    },
    enableIcon(icon) {
      if (icon) {
        icon.style.color = "#4c55a7";
        icon.style.pointerEvents = "none";
      }
    },
    setLibraryView(view) {
      localStorage.libraryView = view;
      this.libraryView = view;
    },
    checkView() {
      if (this.libraryView === ViewMode.GRID && this.oldView == "" && window.innerWidth < 1200) {
        this.setDisplayElements("none");
        this.oldView = ViewMode.GRID;
      }
      if (this.libraryView === ViewMode.LIST && window.innerWidth < 1200) {
        this.setLibraryView(ViewMode.GRID);
        this.oldView = ViewMode.LIST;
        this.setDisplayElements("none");
      } else if (window.innerWidth >= 1200 && this.oldView != "") {
        this.setLibraryView(this.oldView);
        this.oldView = "";
        this.setDisplayElements("inherit");
      }
    },

    viewIconsListener() {
      const listIcon = document.querySelector("#listIcon");
      const gridIcon = document.querySelector("#gridIcon");

      if (localStorage.libraryView == ViewMode.LIST) {
        this.disableIcon(gridIcon);
        this.enableIcon(listIcon);
      } else {
        this.setLibraryView(ViewMode.GRID);
        this.disableIcon(listIcon);
        this.enableIcon(gridIcon);
      }
    },

    viewIconClicked(view) {
      const listIcon = document.querySelector("#listIcon");
      const gridIcon = document.querySelector("#gridIcon");
      if (view === ViewMode.LIST) {
        this.setLibraryView(ViewMode.LIST);
        this.disableIcon(gridIcon);
        this.enableIcon(listIcon);
      } else if (view === ViewMode.GRID) {
        this.setLibraryView(ViewMode.GRID);
        this.disableIcon(listIcon);
        this.enableIcon(gridIcon);
      }
    },

    listenResize() {
      this.checkView();
      window.onresize = () => {
        this.checkView();
      };
    },

    setDisplayElements(prop) {
      if (document.querySelector("#gridIcon")) {
        document.querySelector("#gridIcon")["style"].display = prop;
        document.querySelector("#listIcon")["style"].display = prop;
        document.querySelector(".eu-courses-filters")["style"].display = prop;
      }
    },
    createFilterData(value, name) {
      if (name === "Departments") {
        this.selectedDepartments = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === "Audiences") {
        this.selectedAudiences = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === "Destinations") {
        this.selectedDestinations = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === "Partners") {
        this.selectedPartners = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === "Focus Areas") {
        this.selectedFocusAreas = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === "Identities") {
        this.selectedIdentities = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === "Resource Types") {
        this.selectedResTypes = value.map((v) => encodeURIComponent(v.name));
      }
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  padding: 24px 0;
}
.search-section.partner {
  margin: 0.75rem 0;
}

.search-wrapper {
  width: 100%;
}

.collapse-filter-button.partner {
  width: 100%;
  padding: 0.75rem 0;
  border: 1px solid #c3cbd6;
  border-radius: 24px;
  background-color: #fff;
}

.option-pills-container.mobile-only {
  &.partner {
    margin: 0.75rem 0;
  }
}

.resource-section.partner {
  width: 100%;
  max-width: 1400px;
}

.filter-section {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1.5rem 0 2rem;

  .search-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    padding: 3rem 5rem;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    background-image: url("https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/beglobalii-community-library-hero.jpg");
    background-size: cover;
    background-position: center;
  }

  .tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    text-align: center;
    position: relative;

    .title {
      font-size: 2.5rem;
      line-height: 1.2;
      padding: 0;
      color: #fff;
    }

    .subtitle {
      font-size: 1.25rem;
      line-height: 1.35;
      color: #fff;
    }
  }
}

.resource-content-container.partner {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

@media screen and (max-width: 1190px) {
  .resource-content-container.partner {
    grid-template-columns: repeat(2, 1fr);
  }
}
.tab-navigation-container {
  padding: 0;
}
@media screen and (max-width: 576px) {
  .navigation-tabs-container-partner {
    padding: 0 1.5rem;
  }

  .resource-content-container.partner {
    padding: 0 0 3rem;
    display: flex;
    overflow: auto;
  }

  .filter-section {
    margin: 1.5rem 0 0.75rem;

    .search-container {
      gap: 1.5rem;
      padding: 3.5rem 1.5rem;
      border-radius: 0;
    }

    .text-container {
      .title {
        font-size: 2rem;
      }

      .subtitle {
        font-size: 1.125rem;
        line-height: 1;
      }
    }
  }
}
</style>
