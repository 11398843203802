<template>
  <section style="width: 100%">
    <form v-if="!isLoading" class="profile-form" @submit.prevent="submitForm()" id="eu-form" enctype="multipart/form-data">
      <div class="eu-form-page-backlink">
        <a :href="`/groups/${group.name}`" aria-label="Go to group's page">
          <i class="fas fa-arrow-left icon" aria-hidden="true"></i>
          <span>Go to Group Page</span>
        </a>
      </div>

      <div id="main" class="profile-form-header-container">
        <h2>Edit Affinity Group</h2>
      </div>
      <div class="profile-form-section dropzone-container">
        <div>
          <h3>Cover Photo</h3>
          <p class="inup-thumbnail-desc">
            Use a high quality, landscape orientation image for best results.
          </p>
          <dropzone-component
            v-if="imageInput"
            :imageSrc="group.cover_pic"
            :imageInput="imageInput"
            :maxImageSize="image_size_data.max_size"
            :maxImageSizeFormat="image_size_data.formatted"
            @uploadImage="prepareImageUpload"
            @removeImage="handleRemoveImage"
          />
          <input type="file" name="thumbnail" id="thumbnail" class="js-input-file-control da-components-hidden-file-control" accept="image/*" maxlength="400" />
        </div>
      </div>

      <div class="profile-form-section small-dropzone-container">
        <h3 class="small-margin-bottom">Basic Information</h3>
        <small-dropzone-component
          v-if="logoInput"
          :imageSrc="group.logo_url"
          :imageInput="logoInput"
          :maxImageSize="image_size_data.max_size"
          :maxImageSizeFormat="image_size_data.formatted"
          changeButtonText="Change Logo"
          uploaderText="Upload group logo"
          @removeImage="handleRemoveLogo"
          @uploadImage="prepareLogoUpload"
        />
        <input type="file" name="logo" id="logo" class="js-input-file-control da-components-hidden-file-control" accept="image/*" maxlength="400" />

        <div class="inup-form-element-container" style="pointer-events: none;margin-top:40px">
          <input type="text" v-model="group.name" id="name" class="inup-input" maxlength="255" required="" disabled />
          <div class="inup-form-label-container">
            <label for="name">Name <span>*</span></label>
          </div>
        </div>
        <div class="inup-form-element-container">
          <textarea name="description" cols="40" rows="10" v-model="group.description" id="desc" class="inup-textarea-input no-resize" />
          <div class="inup-form-label-container">
            <label for="desc">Description</label>
          </div>
        </div>
        <div class="multiselect-wrapper" style="width: 100%; margin:0; position:relative;">
          <multiselect
            v-model="group.group_focus_areas"
            :options="focus_areas"
            :multiple="true"
            label="name"
            track-by="name"
            :max="5"
            :close-on-select="false"
            placeholder=""
          >
          </multiselect>
          <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer; left:96%;"></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">Group Focus Areas <span>*</span></label>
          </div>
        </div>

        <div class="multiselect-wrapper" style="width: 100%; margin:0; position:relative;">
          <multiselect
            v-model="group.group_identities"
            :options="identities"
            :multiple="true"
            label="name"
            track-by="name"
            :max="5"
            :close-on-select="false"
            placeholder=""
          >
          </multiselect>
          <i class="fal fa-angle-down eu-select-arrow" style="top: -35px; z-index: 52; cursor: pointer; left:96%;"></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">Group Identities</label>
          </div>
        </div>
      </div>

      <div class="profile-form-section">
        <h3 class="medium-margin-bottom">Welcome Video</h3>
        <div class="inup-form-element-container">
          <input type="text" v-model="group.welcome_video_url" id="video" class="inup-input" maxlength="255" />
          <div class="inup-form-label-container">
            <label for="video">Youtube URL</label>
          </div>
        </div>
      </div>

      <div class="profile-form-section">
        <h3 class="medium-margin-bottom">Contact Information</h3>
        <div class="inup-form-element-container">
          <input type="text" v-model="group.website" id="website" class="inup-input" maxlength="255" />
          <div class="inup-form-label-container">
            <label for="website">Website URL</label>
          </div>
        </div>

        <div class="inup-form-element-container multiple">
          <div class="inup-form-element-container small">
            <input type="text" v-model="group.phone_number" id="phone_number" class="inup-input" />
            <div class="inup-form-label-container">
              <label for="phone_number">Phone</label>
            </div>
          </div>

          <div class="inup-form-element-container small">
            <input type="email" v-model="group.email" id="email" class="inup-input" maxlength="255" />
            <div class="inup-form-label-container">
              <label for="email">Email</label>
            </div>
          </div>
        </div>

        <div class="eu-form-element-container">
          <multiple-input-select
            v-if="socialInput"
            :input="socialInput"
            :optionList="social_options"
            :filledOptions="group.social_networks"
            selectLabel="Social Link"
            inputLabel="URL"
          />
          <input type="hidden" name="social_networks" id="social-networks-input" />
        </div>
      </div>
      <div class="profile-form-section">
        <h3 class="medium-margin-bottom">Group Privacy</h3>
        <div class="inup-form-element-container">
          <select name="is_private" v-model="group.is_private" id="is_private" class="inup-input">
            <option :value="true">Private</option>
            <option :value="false">Public</option>
          </select>
          <div class="inup-form-label-container">
            <label for="is_private">Type <span>*</span></label>
          </div>
        </div>
        <div
          v-if="group.is_private"
          tabindex="0"
          @click="openPrivateUsersSelectModal"
          @keyup.enter="openPrivateUsersSelectModal"
          role="button"
          aria-label="Open a dialog to chose private users"
          class="multiselect-wrapper hidden__content"
          style="width: 100%; margin:0; position:relative;"
        >
          <multiselect-component
            id="group-private-list-multiselect"
            v-model="group.group_private_list"
            :options="all_users"
            :multiple="true"
            :limit="20"
            :limitText="limitText"
            label="name"
            track-by="name"
            :close-on-select="false"
            placeholder=""
            aria-label="Open dialog for selecting private users."
            @click.self.prevent
          />
          <div class="inup-form-label-container">
            <label for="group_private_list">Private Members</label>
          </div>
        </div>
        <div id="multiselect-department_private_list"></div>
      </div>
      <section class="profile-form-section">
        <h3 id="important-links-title" tabindex="0" class="medium-margin-bottom">Important Links</h3>
        <div class="multiple-input-select" style="margin-bottom: 20px;">
          <div class="multiple-elements">
            <div class="eu-form-element-container-small">
              <input v-model="titleInput" id="il-title-input" type="text" class="eu-form-input" />
              <div class="eu-form-label-container">
                <label for="il-title-input">Important Link Title</label>
              </div>
            </div>
            <div class="eu-form-element-container-small">
              <input v-model="urlInput" id="il-url-input" type="text" class="eu-form-input" />
              <div class="eu-form-label-container">
                <label for="il-url-input">Important Link URL</label>
              </div>
            </div>
            <button type="button" class="mis-add-button" @keyup.enter="addImportantLink" @click="addImportantLink">+ Add</button>
          </div>
          <div id="il-list"></div>
        </div>
      </section>
      <div class="profile-form-section">
        <h3 id="group-contacts-and-managers-title" tabindex="0" class="medium-margin-bottom">Group Contacts &amp; Managers</h3>
        <div class="multiselect-wrapper hidden__content" @click="openGroupContactUsersSelectModal" @keyup.enter="openGroupContactUsersSelectModal"  style="width: 100%; margin:0; margin-bottom:30px; position:relative;">
          <multiselect-component
            id="group-contacts-multiselect"
            v-model="group.group_contacts"
            :options="group.is_private? group.group_private_list:all_users"
            :multiple="true"
            label="name"
            track-by="name"
            :limit="20"
            :limitText="limitText"
            :close-on-select="false"
            placeholder=""
            aria-label="Open dialog for selecting group contacts."
            @click.self.prevent
          />
          <div class="inup-form-label-container">
            <label for="group_private_list">Group Contacts</label>
          </div>
        </div>
        <div class="multiselect-wrapper hidden__content" @click="openGroupManagerUsersSelectModal" @keyup.enter="openGroupManagerUsersSelectModal" style="width: 100%; margin:0; margin-bottom:30px; position:relative;">
          <multiselect-component
            id="group-managers-multiselect"
            v-model="group.group_managers"
            :options="group.is_private ? group.group_private_list:all_users"
            :multiple="true"
            label="name"
            track-by="name"
            :limit="20"
            :limitText="limitText"
            :close-on-select="false"
            placeholder=""
            aria-label="Open dialog for selecting group manager."
            @click.self.prevent
          />
          <div class="inup-form-label-container">
            <label for="group_private_list">Group Managers</label>
          </div>
        </div>
      </div>
      <div class="profile-form-button-container">
        <input type="submit" :value="submitText" class="eu-btn-primary-small" />
      </div>
    </form>
    <div v-if="isLoading">
        <loading-spinner wrapperClass="wrapper" />
    </div>
    <objects-select-modal
        :isModalActive="isUsersSelectModalActive"
        @close-modal="closeModalFunction"
        :title="selectModalTitle"
        actionName="Select"
        objectName="User"
        :objectsList="usersList"
        :isLoading="isLoadingSelectUsers"
        :page="selectUserPage"
        :totalPageNumber="selectUserTotalPageNumber"
        :currentPage="selectUserCurrentPage"
        :showIcon="false"
        :preselectedObjects="selectedUsers"
        :filterGroups="contactGroup_list"
        @loadData="loadUsers"
        @searchObjects="loadUsers"
    />

    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>
<script lang="ts">
import * as Cookies from 'js-cookie';
import GroupService from '../services/GroupService';
import FeedService from '../../feed/services/FeedService';
import Notification from '../../components/Notification.vue';
import DropzoneComponent from '../../form/components/DropzoneComponent.vue';
import SmallDropzoneComponent from '../../form/components/SmallDropzoneComponent.vue';
import MultipleInputSelect from '../../form/components/MultipleInputSelect.vue';
import Multiselect from 'vue-multiselect';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import ObjectsSelectModal from '../../accounts/components/modals/ObjectsSelectModal.vue'
import AccountsService from "../../accounts/services/AccountsService";
import { focusElement } from '../../utils/CustomUtils';
import ContactGroupService from '../../services/ContactGroupService';
import MultiselectComponent from '../../form/components/MultiselectComponent.vue';

export default {
  components: {
      ObjectsSelectModal,
      Notification,
      LoadingSpinner,
      Multiselect,
      MultipleInputSelect,
      DropzoneComponent,
      SmallDropzoneComponent,
      MultiselectComponent
    },
  name: 'group-manage',
  props: [
      'social_options',
      'group',
      'image_size_data',
      'all_users',
      'identities',
      'focus_areas'
    ],
  data() {
    return {
      isNotificationActive: false,
      notification: { msg: '', isError: false, icon: null },
      thumbnail: {},
      logo: {},
      imageInput: null,
      logoInput: null,
      socialInput: null,
      titleInput: null,
      urlInput: null,
      submitText: 'Save',
      isLoading: false,
      isUsersSelectModalActive: false,
      isLoadingSelectUsers: false,
      selectUserPage: 1,
      selectUserTotalPageNumber: 1,
      selectUserCurrentPage: 1,
      usersList: [],
      selectedUsers:[],
      selectModalTitle: "",
      pass_private_members: false,
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      },
      closeModalFunction: this.closePrivateUsersSelectModal,
      contactGroup_list: [],
    };
  },
  async created() {
    this.service = new GroupService();
    this.feedService = new FeedService();
    this.accountsService = new AccountsService();
    this.contactGroupService = new ContactGroupService();
    setTimeout(() => {
      this.imageInput = document.querySelector('#thumbnail');
      this.logoInput = document.querySelector('#logo');
      this.socialInput = document.querySelector('#social-networks-input');
      const ilList = document.querySelector('#il-list');
      this.populateListData(ilList, this.group.important_links, 'url');
    }, 0);
    this.loadContactGroups();
  },
  methods: {
    limitText(count) {
      return `+ ${count} more`
    },
    userToSelectObjectMapper(user) {
      return {
        "id": user.id,
        "name": user.first_name + " " + user.last_name,
        "unique_signature": user.email,
        "icon": user.profile_pic,
      }
    },
    isInPrivateList(id) {
      return this.group.group_private_list.find(object => object.id == id) !== undefined;
    },
    async loadContactGroups() {
      this.contactGroupService.getContactGroupList().then(data => {
        this.contactGroup_list = data.data;
      });
    },
    async loadUsers(searchString = "", page = 1, selectedContactGroup_id) {
      this.selectUserPage = page;

      let selectedContactGroup_id_check = selectedContactGroup_id;
      if (selectedContactGroup_id == undefined) selectedContactGroup_id_check = 0;

      if (selectedContactGroup_id_check == 0) {
        if (page==1) this.usersList = [];
        this.isLoadingSelectUsers = true;

        if (this.pass_private_members) {
          this.usersList = this.group.group_private_list;
          this.isLoadingSelectUsers = false;
          this.selectUserTotalPageNumber = 1;
          this.selectUserCurrentPage = 1;
        } else {
          let response = await this.accountsService.getUsers({
            "name":searchString,
            "page": page,
          });
          this.usersList = this.usersList.concat(response.page_data.map(user => this.userToSelectObjectMapper(user)));
          this.selectUserTotalPageNumber = response.total_pages_number;
          if(this.selectUserTotalPageNumber == this.selectUserPage) {
            this.isLoadingSelectUsers = false;
          }
          this.selectUserCurrentPage = page;
        }
      } else {
        this.usersList = [];
        let response = await this.contactGroupService.getContactGroupUsers(selectedContactGroup_id, {"name":searchString});
        this.usersList = this.usersList.concat(response.data.map(user => this.userToSelectObjectMapper(user)));
        if (this.pass_private_members) {
          this.usersList = this.usersList.filter(user=>{return this.isInPrivateList(user.id)})
        }
        this.selectUserCurrentPage = page;
        this.isLoadingSelectUsers = false;
      }
    },

    async openPrivateUsersSelectModal() {
      this.selectModalTitle = "Select private members"
      this.closeModalFunction = this.closePrivateUsersSelectModal;
      this.isUsersSelectModalActive = true;
      this.selectedUsers = this.group.group_private_list;
      focusElement('objects-select-modal-title');
      this.pass_private_members = false;
      this.loadUsers("", 1);
    },

    containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }
      return false;
    },

    closePrivateUsersSelectModal(closeInfo) {
      if (!closeInfo["canceled"]) {
        const newSelectedUsersList = closeInfo["selectedObjects"]
        this.group.group_private_list = newSelectedUsersList;
      }
      this.isUsersSelectModalActive = false;
      focusElement('important-links-title')

      if(this.group.is_private) {
        this.group.group_contacts = this.group.group_contacts.filter(contactUser => this.containsObject(contactUser, this.group.group_private_list));
        this.group.group_managers = this.group.group_managers.filter(managerUser => this.containsObject(managerUser, this.group.group_private_list));
      }
    },

    async openGroupContactUsersSelectModal() {
      this.selectModalTitle = "Select group contacts"
      this.closeModalFunction = this.closeGroupContactUsersSelectModal;
      this.isUsersSelectModalActive = true;
      this.selectedUsers = this.group.group_contacts;
      focusElement('objects-select-modal-title');
      this.pass_private_members = this.group.is_private;
      this.loadUsers("", 1);
    },

    closeGroupContactUsersSelectModal(closeInfo) {
      if (!closeInfo["canceled"]) {
        const newSelectedUsersList = closeInfo["selectedObjects"]
        this.group.group_contacts = newSelectedUsersList;
      }
      this.isUsersSelectModalActive = false;
      focusElement('group-contacts-and-managers-title')
    },

    async openGroupManagerUsersSelectModal() {
      this.selectModalTitle = "Select group managers"
      this.closeModalFunction = this.closeGroupManagerUsersSelectModal;
      this.isUsersSelectModalActive = true;
      this.selectedUsers = this.group.group_managers;
      focusElement('objects-select-modal-title');
      this.pass_private_members = this.group.is_private;
      this.loadUsers("", 1);
    },

    closeGroupManagerUsersSelectModal(closeInfo) {
      if (!closeInfo["canceled"]) {
        const newSelectedUsersList = closeInfo["selectedObjects"]
        this.group.group_managers = newSelectedUsersList;
      }
      this.isUsersSelectModalActive = false;
      focusElement('group-contacts-and-managers-title')
    },

    addImportantLink() {
      const ilList = document.querySelector('#il-list');
      if (this.titleInput && this.urlInput) {
        this.handleAddItem(ilList);
      }
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    populateListData(el) {
      el.innerHTML = '';
      if (this.group.important_links && Object.keys(this.group.important_links).length > 0) {
        for (const title in this.group.important_links) {
          el.innerHTML += `<div class="mis-item-container">
                            <a target="_blank" href="${this.group.important_links[title]}" class="mis-link-text">${title}</a>
                            <span tabindex="0" aria-label="Remove ${title}" class="list-remove-button" id="trash--url--${title}"><i class="fad fa-trash mis-remove-icon"></i></span>
                        </div>`;
        }
      }
      this.handleDeleteListener();
    },
    handleAddItem(list) {
      if (!this.group.important_links) this.group.important_links = {};
      this.group.important_links[this.titleInput] = !this.urlInput.toLowerCase().startsWith('http') ? 'https://' + this.urlInput : this.urlInput;
      this.populateListData(list);
      this.titleInput = '';
      this.urlInput = '';
    },
    handleDeleteListener() {
      const ilList = document.querySelector('#il-list');
      const trashBtn = document.getElementsByClassName('list-remove-button');
      Array.from(trashBtn).forEach(function(element) {
        element.addEventListener('click', e => {
          const objKey = e.target['parentElement'].id.split('--')[2];
          if (e.target['parentElement'].id.split('--')[1] === 'url') {
            delete this.group.important_links[objKey];
            this.urlInput = JSON.stringify(this.group.important_links);
            this.populateListData(ilList, this.group.important_links, 'url');
          }
        });
      });
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    handleRemoveImage() {
      this.thumbnail = {};
      this.group.cover_pic = null;
    },
    prepareImageUpload(fileObject) {
      this.thumbnail = fileObject;
    },
    handleRemoveLogo() {
      this.logo = {};
    },
    prepareLogoUpload(fileObject) {
      this.logo = fileObject;
    },
    validateForm() {
      if(!this.group.name || this.group.group_focus_areas.length === 0) {
        this.showNotification({ msg: 'Error: Please fill in all required fields', isError: true, icon: 'fad fa-exclamation-circle' });
        return false;
      }
      return true;
    },
    async submitForm() {
      if (this.submitText === 'Save' && this.validateForm()) {
        this.submitText = 'Saving...';
        this.isLoading = true;
        const uploadRes = await this.uploadImgs();
        if (uploadRes.success) {
          const groupDTO = this.setAdditionalFields();
          const res = await this.service.updateGroup(groupDTO, this.headers);
          if (res.success) {
            window.scrollTo(0, 0);
            this.showNotification({ msg: 'Affinity Group Successfully Updated', icon: 'fad fa-check-circle' });
          } else {
            this.showNotification({ msg: 'Server Error', isError: true, icon: 'fad fa-exclamation-circle' });
          }
        } else {
          this.showNotification({ msg: uploadRes.msg, isError: true, icon: 'fad fa-exclamation-circle' });
        }
        this.submitText = 'Save';
        this.isLoading = false;
      }
    },
    setAdditionalFields() {
      let groupDTO = {...this.group};
      groupDTO.group_private_list = this.group.group_private_list.map(e => e.id);
      groupDTO.group_managers = this.group.group_managers.map(e => e.id);
      groupDTO.group_followers = this.group.group_followers.map(e => e.id);
      groupDTO.group_identities = this.group.group_identities.map(e => e.id);
      groupDTO.group_focus_areas = this.group.group_focus_areas.map(e => e.id);
      groupDTO.group_contacts = this.group.group_contacts.map(e => e.id);
      if(this.socialInput?.value){
        groupDTO.social_networks = JSON.parse(this.socialInput.value);
      }
      return groupDTO;

    },
    async uploadImgs() {
      let imgRes, logoRes;
      if (this.thumbnail && Object.keys(this.thumbnail).length > 0) {
        imgRes = await this.feedService.uploadFile(this.thumbnail, this.headers);
        if (imgRes?.resource) {
          this.group.cover_pic = imgRes.resource;
        } else {
          return { success: false, msg: 'Group Cover Image Upload Failed' };
        }
      }
      if (this.logo && Object.keys(this.logo).length > 0) {
        logoRes = await this.feedService.uploadFile(this.logo, this.headers);
        if (logoRes?.resource) {
          this.group.logo_url = logoRes.resource;
        } else {
          return { success: false, msg: 'Group Logo Upload Failed' };
        }
      }
      return { success: true };
    }
  },

  computed: {}
};
</script>
