<template>
  <Modal class="eu-file-upload-modal" :isActiveProp="isModalActive" styleProp="display: block;" @close="closeModal">
    <section v-if="!isLoading && !isDeleteModal">
      <div v-if="STEPS[currentStep] === STEPS[0]" :style="partnerCreated ? 'pointer-events: none; opacity: 0.7;' : ''">
        <h3 id="upload_modal" tabindex="0" role="heading">Add Partner</h3>

        <div class="small-dropzone-container" style="margin-top: 20px; margin-bottom: 25px;">
          <small-dropzone-component
            v-if="logoInput"
            :imageSrc="partner.logo_url"
            :imageInput="logoInput"
            :maxImageSize="image_size_data.max_size"
            :maxImageSizeFormat="image_size_data.formatted"
            :isOpen="isModalActive"
            changeButtonText="Change Logo"
            uploaderText="Add logo"
            @removeImage="handleRemoveLogo"
            @uploadImage="prepareLogoUpload"
          />
          <input type="file" name="logo" id="logo" class="js-input-file-control da-components-hidden-file-control" accept="image/*" maxlength="400" />
        </div>
        <div style="margin-left: 20px; margin-right: 20px">
          <div class="inup-form-element-container" :class="validator.partnerName ? '' : 'input-error'" style="margin-top: 40px">
            <input type="text" v-model="partner.name" id="name" class="inup-input" maxlength="255" required="" />
            <div class="inup-form-label-container">
              <label for="name">Partner Name <span>*</span></label>
            </div>
          </div>
          <div class="inup-form-element-container">
            <select name="is_active" v-model="partner.is_active" id="is_active" class="inup-input">
              <option :value="true">Active</option>
              <option :value="false">Not Active</option>
            </select>
            <div class="inup-form-label-container">
              <label for="is_active">Status</label>
            </div>
          </div>
        </div>
      </div>

      <div v-if="STEPS[currentStep] === STEPS[1]">
        <h3 id="upload_modal" tabindex="0" role="heading">{{ isEdit ? "Edit Manager" : "Add Manager" }}</h3>
        <div class="small-dropzone-container" style="margin-top: 20px; margin-bottom: 25px;">
          <small-dropzone-component
            v-if="thumbInput"
            :imageSrc="manager.profile_pic"
            :imageInput="thumbInput"
            :maxImageSize="image_size_data.max_size"
            :maxImageSizeFormat="image_size_data.formatted"
            :isOpen="isModalActive"
            changeButtonText="Change Photo"
            uploaderText="Add Photo"
            @removeImage="handleRemoveThumbnail"
            @uploadImage="prepareThumbnailUpload"
          />
          <input type="file" name="thumb" id="thumb" class="js-input-file-control da-components-hidden-file-control" accept="image/*" maxlength="400" />
        </div>
        <div style="margin-left: 20px; margin-right: 20px">
          <div class="inup-form-element-container" :class="validator.managerFirstName ? '' : 'input-error'">
            <input type="text" v-model="manager.first_name" id="fname" class="inup-input" maxlength="255" required="" />
            <div class="inup-form-label-container">
              <label for="name">First Name <span>*</span></label>
            </div>
          </div>
          <div class="inup-form-element-container" :class="validator.managerLastName ? '' : 'input-error'">
            <input type="text" v-model="manager.last_name" id="lname" class="inup-input" maxlength="255" required="" />
            <div class="inup-form-label-container">
              <label for="name">Last Name <span>*</span></label>
            </div>
          </div>
          <div class="inup-form-element-container" :class="validator.managerEmail ? '' : 'input-error'">
            <input type="email" v-model="manager.email" id="name" class="inup-input" maxlength="255" required="" />
            <div class="inup-form-label-container">
              <label for="name">Email <span>*</span></label>
            </div>
          </div>
          <div v-if="(isPartnerAdmin || isAdmin) && (manager.email !== currentUserEmail)" class="inup-form-element-container" style="margin-top: 27px">
            <select name="manager_type" v-model="manager.partner_role" id="m_is_active" class="inup-input">
              <option value="Partner Manager">Partner Manager</option>
              <option value="Partner Admin">Partner Admin</option>
            </select>
            <div class="inup-form-label-container">
              <label for="manager_type">Type</label>
            </div>
          </div>
          <div v-if="manager.email !== currentUserEmail" class="inup-form-element-container" style="margin-top: 27px">
            <select name="is_active" v-model="manager.is_active" id="m_is_active" class="inup-input">
              <option :value="true">Active</option>
              <option :value="false">Not Active</option>
            </select>
            <div class="inup-form-label-container">
              <label for="is_active">Status</label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="STEPS[currentStep] === STEPS[2]">
        <h3 id="upload_modal" tabindex="0" role="heading">Add Manager</h3>
        <span class="manager-created-text">Invitation sent to {{manager.email}}. Add another partner manager?</span>
      </div>

      <div style="margin-left: 20px; margin-right: 20px" class="button-container" :style="managerOnly ? 'text-align: center' : ''">
        <p class="errorMsg">{{error}}</p>
        <button v-if="currentStep === 0" class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary" @click="addPartner">Next</button>
        <button v-if="currentStep === 0" class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary" @click="closeModal">Cancel</button>

        <button v-if="currentStep === 1 && !isEdit" class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary" @click="addManager">Send Invitation</button>
        <button v-if="currentStep === 1 && !isEdit && !managerOnly" class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary" @click="backToPartner">Back</button>
        <button v-if="currentStep === 1 && !isEdit && !managerOnly" class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary" @click="closeModal">Finish</button>
        <button v-if="currentStep === 1 && !isEdit && managerOnly" class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary" @click="closeModal">Cancel</button>

        <button v-if="currentStep === 1 && isEdit" class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary" @click="editManager">Save</button>
        <button v-if="currentStep === 1 && isEdit" class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary" @click="closeModal">Cancel</button>

        <button v-if="currentStep === 2" class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary" @click="backToManager">Add Another Manager</button>
        <button v-if="currentStep === 2" class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary" @click="closeModal">Exit</button>

        <span v-if="!managerOnly && currentStep < 2" style="float: right">
          <span class="dot" :class="currentStep === 0 ? 'active' : ''"></span>
          <span class="dot" :class="currentStep === 1 ? 'active' : ''"></span>
        </span>
      </div>
    </section>
    <div v-if="isLoading" class="modalSpinner">
      <loading-spinner />
    </div>

    <div v-if="isDeleteModal && !isLoading">
      <div class="row" style="width: auto">
        <div v-if="objectType == 'PARTNER'" style="display: flex; flex-direction: row">
           <h4 v-if="isBulkDelete" class="delete-modal-header">{{ bulkPartnersProp.length }} partners selected</h4>
           <h4 v-else class="delete-modal-header">{{ partnerProp.name }}</h4>
        </div>
        <div v-if="objectType == 'MANAGER'">
          <h4 v-if="isBulkDelete" class="delete-modal-header">{{ bulkManagersProp.length }} managers selected</h4>
          <h4 v-else class="delete-modal-header">{{ managerProp.first_name }} {{ managerProp.first_name }}</h4>
        </div>
        <p tabindex="0" role="heading" class="delete-text">
          Are you sure you want to delete?<br />
          {{objectType=="PARTNER" ? 'This action cannot be undone.' :
          'Deletion of a manager will only remove it from the partner.'}}
        </p>
        <form @submit.prevent="deleteObject" class="field-wrapper hasValue" style="margin-bottom: 20px">
          <div class="da-form-formItem permission-input">
            <input id="pass" type="password" autocomplete="off" maxlength="255" class="da-components-forms-input large js-da-login-email" v-model="password" />
          </div>
          <div class="field-placeholder" style="bottom: 22px">
            <span> <label for="pass">Password </label><span class="required-star">*</span></span>
          </div>
        </form>
      </div>
      <div style="margin-bottom: 30px">
        <button class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary orange" @click="deleteObject">
          Delete User
        </button>

        <button class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary" @click="closeDeleteModal">Cancel</button>
      </div>
    </div>
  </Modal>
</template>
<script lang="ts">
import Cookies from "js-cookie";
import Modal from "../../../components/ModalExtended.vue";
import SmallDropzoneComponent from "../../../form/components/SmallDropzoneComponent.vue";
import LoadingSpinner from "../../../components/LoadingSpinner.vue";
import PartnerAccountService from "../../../partneraccounts/services/PartnerAccountService";
import FeedService from "../../../feed/services/FeedService";
import { focusElement } from '../../../utils/CustomUtils';
import { useAuthStore } from "../../../stores/auth";

export default {
  name: "partner-manager-modal",

  components: { Modal, SmallDropzoneComponent, LoadingSpinner },

  props: ["isModalActive", "partnerProp", "managerProp", "isDeleteModal", "bulkManagersProp", "bulkPartnersProp"],

  data() {
    return {
      partner: this.partnerProp || { is_active: false },
      manager: this.managerProp || { is_active: false, partner_role: "Partner Manager", type: "Manager" },
      isEdit: !!this.partnerProp?.id && !!this.managerProp?.id,
      managerOnly: !!this.partnerProp?.id,
      image_size_data: {
        max_size: 10485760,
        formatted: "10 GB",
      },
      STEPS: ["addPartner", "addManager", "anotherManager"],
      logo: {},
      thumbnail: {},
      files: [],
      dragging: false,
      logoInput: null,
      thumbInput: null,
      error: "",
      isLoading: false,
      assetPath: process.env.MEDIA_URL,
      partnerService: new PartnerAccountService(),
      feedService: new FeedService(),
      currentStep: !!this.partnerProp?.id ? 1 : 0,
      partnerCreated: false,
      reloadTable: false,
      validator: {
        partnerName: true,
        managerFirstName: true,
        managerLastName: true,
        managerEmail: true,
      },
      password:'',
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      isAdmin: false,
      isPartnerAdmin: false,
      authStore: useAuthStore(),
    };
  },

  created() {
    this.getImageInputs();
    this.isPartnerAdmin = this.authStore.getIsPartnerAdmin
    this.isAdmin = this.authStore.getIsAdmin
    const userData = this.authStore.getUser;
    this.currentUserEmail = userData.user.email
  },
  watch: {
      partnerProp: function(newVal, oldVal) {
        this.reloadTable = false;
        this.managerOnly = !!newVal?.id;
        this.currentStep = !!newVal?.id ? 1 : 0;
        this.partner = newVal || { is_active: false };
        this.isEdit = !!newVal?.id && !!this.managerProp?.id;
        this.getImageInputs();
      },
      managerProp: function(newVal, oldVal) {
        this.reloadTable = false;
        this.manager = newVal || { is_active: false, partner_role: "Partner Manager", type: "Manager" };
        this.isEdit = !!newVal?.id && !!this.partnerProp?.id;
        this.getImageInputs();
      }
    },
  computed: {
    objectType() {
      return this.managerProp || this.bulkManagersProp.length > 0 ? "MANAGER" : "PARTNER";
    },
    isBulkDelete() {
      return this.bulkManagersProp.length != 0 || this.bulkPartnersProp.length != 0;
    },
  },
  methods: {
    resetValidator() {
      this.validator = {
        partnerName: true,
        managerFirstName: true,
        managerLastName: true,
        managerEmail: true,
      }
      this.error = '';
    },
    validatePartnerForm() {
      let formValid = true;
      if(this.partner === undefined || this.partner.name=="" || !this.partner.name) {
        this.validator.partnerName = false;
        formValid = false;
      }

      return formValid;
    },
    validateManagerForm() {
      let formValid = true;
      if(this.manager === undefined || this.manager.first_name=="" || !this.manager.first_name) {
        this.validator.managerFirstName = false;
        formValid = false;
      }
      if(this.manager === undefined || this.manager.last_name=="" || !this.manager.last_name) {
        this.validator.managerLastName = false;
        formValid = false;
      }
      if(this.manager === undefined || this.manager.email=="" || !this.manager.email) {
        this.validator.managerEmail = false;
        formValid = false;
      }

      return formValid
    },
    getImageInputs() {
      setTimeout(() => {
          this.logoInput = document.querySelector("#logo");
          this.thumbInput = document.querySelector("#thumb");
        }, 0);
    },
    backToPartner() {
      this.getImageInputs();
      this.currentStep = 0;
    },
    backToManager() {
      this.currentStep = 1;
      this.getImageInputs();
      this.manager = { is_active: false, partner_role: "Partner Manager", type: "Manager" };
    },
    async editManager() {
      if (!this.validateManagerForm()) {
        return;
      }
      this.isLoading = true;
      const uploadRes = await this.uploadImg();
      if (uploadRes.success) {
        const res = await this.partnerService.editPartnerManager({manager: JSON.stringify(this.manager)}, this.headers);
        if(res?.success) {
          this.isLoading = false;
          this.reloadTable = true;
          this.showNotification({msg: 'Account successfully updated', icon: "fad fa-check-circle"});
          this.closeModal();
        } else {
          this.isLoading = false;
          this.showNotification({msg: 'There was an error updating the account. Please try again', isError: true, icon: "fad-fa-exclamation-circle"})
        }
      }
    },
    async addManager() {
      if (!this.validateManagerForm()) {
        return;
      }
      this.isLoading = true;
      this.manager.partner_id = this.partner.id;
      const uploadRes = await this.uploadImg();
      if (uploadRes.success) {
        const res = await this.partnerService.createPartnerManager({manager: JSON.stringify(this.manager)}, this.headers);
        if(res?.success) {
          this.isLoading = false;
          this.currentStep = 2;
          this.reloadTable = true;
        } else {
          this.isLoading = false;
          this.showNotification({msg: res.message, isError: true, icon: "fad-fa-exclamation-circle"});
        }
      }
    },
    async addPartner() {
      if(this.partnerCreated) {
        this.currentStep = 1;
        this.getImageInputs();
      } else {
        if (!this.validatePartnerForm()) {
          return;
        }
        this.isLoading = true;
        const uploadRes = await this.uploadImg();
          if (uploadRes.success) {
            const res = await this.partnerService.createPartner(this.partner, this.headers);
            this.isLoading = false;
            this.getImageInputs();
            if(res.success) {
              this.partner.id = res.partner_id;
              this.partnerCreated = true;
              this.currentStep = 1;
              this.reloadTable = true;
              this.resetValidator();
            } else {
              if(res.error = 'partner-name-exists') {
                this.error = 'Partner Name already exists.'
                this.validator.partnerName = false;
                this.handleRemoveLogo();
              }
            }
          } else {
          this.error = 'Cannot Upload Image.'
        }
      }
    },
    async deletePartner(params) {
      this.reloadTable = true;

      const res = await this.partnerService.removePartner(params.partner_id, params, this.headers);
      if (res.success) {
        this.showNotification({msg: res.message, icon: "fad fa-check-circle"});
      } else {
        this.showNotification({msg: res.message, isError: true, icon: "fad-fa-exclamation-circle"})
      }

      this.closeDeleteModal();
      focusElement("notification_title");
    },
    async bulkDeletePartners(params) {
      this.reloadTable = true;

      const res = await this.partnerService.bulkRemovePartners(params, this.headers);
      if (res.success) {
        this.showNotification({msg: res.message, icon: "fad fa-check-circle"});
      } else {
        this.showNotification({msg: res.message, isError: true, icon: "fad-fa-exclamation-circle"})
      }

      this.closeDeleteModal();
      focusElement("notification_title");
    },
    async bulkDeleteManagers(params) {
      this.reloadTable = true;

      const res = await this.partnerService.bulkRemoveManagers(params, this.headers);
      if (res.success) {
        this.showNotification({msg: res.message, icon: "fad fa-check-circle"});
      } else {
        this.showNotification({msg: res.message, isError: true, icon: "fad-fa-exclamation-circle"})
      }

      this.closeDeleteModal();
      focusElement("notification_title");
    },
    async deleteManager(params) {
      this.reloadTable = true;

      const res = await this.partnerService.removePartnersManager(params.manager_id, params, this.headers);
      if (res.success) {
        this.showNotification({msg: res.message, icon: "fad fa-check-circle"});
      } else {
        this.showNotification({msg: res.message, isError: true, icon: "fad-fa-exclamation-circle"})
      }

      this.closeDeleteModal();
      focusElement("notification_title");
    },
    async deleteObject() {
      const params = {
        partner_id: this.partnerProp?.id,
        partner_ids: this.bulkPartnersProp,
        manager_id: this.managerProp?.id,
        managers: this.bulkManagersProp,
        password: this.password,
      }

      if(this.isBulkDelete && this.objectType == "PARTNER") {
        this.bulkDeletePartners(params);
      } else if(this.isBulkDelete && this.objectType == "MANAGER") {
        this.bulkDeleteManagers(params);
      } else if (this.objectType == "PARTNER") {
        this.deletePartner(params);
      } else if(this.objectType == "MANAGER") {
        this.deleteManager(params);
      }

    },
    closeDeleteModal() {
      this.password = '';
      this.closeModal();
    },
    closeModal() {
      this.isOpen = false;
      this.resetValidator();
      this.partnerCreated = false;
      this.manager = { is_active: false, partner_role: "Partner Manager", type: "Manager" };
      this.handleRemoveLogo();
      this.handleRemoveThumbnail();
      this.$emit("close-modal", this.reloadTable);
    },
    handleRemoveLogo() {
      this.logo = {};
    },
    prepareLogoUpload(fileObj) {
      this.logo = fileObj;
    },
    handleRemoveThumbnail() {
      this.thumbnail = {};
    },
    prepareThumbnailUpload(fileObj) {
      this.thumbnail = fileObj;
    },
    showNotification(notification) {
      this.$emit('show-notification', notification);
    },
    async uploadImg() {
      let imgRes, logoRes;
      if (this.currentStep === 1 && this.thumbnail && Object.keys(this.thumbnail).length > 0) {
        imgRes = await this.feedService.uploadFile(this.thumbnail, this.headers);
        if (imgRes?.resource) {
          this.manager.profile_pic = imgRes.resource;
        } else {
          return { success: false, msg: 'Profile Image Upload Failed' };
        }
      }
      if (this.currentStep === 0 && this.logo && Object.keys(this.logo).length > 0) {
        logoRes = await this.feedService.uploadFile(this.logo, this.headers);
        if (logoRes?.resource) {
          this.partner.logo_url = logoRes.resource;
        } else {
          return { success: false, msg: 'Logo Upload Failed' };
        }
      }
      return { success: true };
    },
  },
};
</script>
<style scoped>
.small-dropzone-container .row {
  -webkit-box-orient: horizontal !important;
  flex-direction: row !important;
}
.modalSpinner {
  height: 440px;
  align-items: center;
  display: flex;
}
.permissions-desc {
  color: #88858e;
  line-height: 1.3;
  font-size: 13px;
}
.manager-created-text {
  margin: 24px 24px 36px 24px;
  font-size: 15px;
  line-height: 1.35;
  color: #555;
  display: block;
}
h3 {
  align-self: flex-start;
  margin-left: 24px;
  margin-right: 30px;
  padding-top: 30px;
  color: #666666;
  font-size: 25px;
}

.button-container {
  margin-bottom: 30px;
}

.errorMsg {
  color: #f16b4c;
  margin-bottom: 10px;
  margin-left: 5px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #d1d1d7;
  display: inline-flex;
  margin-left: 5px;
}
.dot.active {
  background: #00a1b3;
}
</style>
