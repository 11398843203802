<template>
  <section>
    <Modal :isActiveProp="isResourceModalActive" @close="closeResourceModal">
      <div class="eu-resource-modal">
        <span
          v-if="activeResource.resource_type != 'VID' && activeResource.resource_type != 'URL' && activeResource.resource_type != 'URL_INTERNAL' && activeResource.resource_type != 'URL_EXTERNAL'"
          tabindex="0"
          :aria-label="`Download ${activeResource.name}`"
          role="button"
          @click="downloadItem(activeResource.resource_url, activeResource.name, activeResource.id)"
          @keyup.enter="downloadItem(activeResource.resource_url, activeResource.name, activeResource.id)"
          class="eu-resource-burger-el-modal"
        >
          <i class="fas fa-download eu-back-btn-arrow"></i>
          <span>Download</span>
        </span>
        <span
          tabindex="0"
          aria-label="Close modal"
          role="button"
          @click="closeResourceModal()"
          @keyup.enter="closeResourceModal()"
          class="eu-resource-burger-el-modal-back"
        >
          <i class="far fa-arrow-left eu-back-btn-arrow"></i>
          <span>{{ backTxt || "Back" }}</span>
        </span>
        <span
          v-if="showNextPrevArrow"
          @click="nextItem()"
          @keyup.enter="nextItem()"
          tabindex="0"
          aria-label="next item"
          role="button"
          class="eu-resource-modal-right"
        >
          <i class="far fa-angle-right"></i>
        </span>
        <span
          v-if="showNextPrevArrow"
          @click="prevItem()"
          @keyup.enter="prevItem()"
          tabindex="0"
          aria-label="previous item"
          role="button"
          class="eu-resource-modal-left"
        >
          <i class="far fa-angle-left"></i>
        </span>
        <h3 class="eu-resource-modal-title">{{ activeResource.name }}</h3>
        <p>
          {{ activeResource.description }}
        </p>
        <img
          v-if="activeResource.resource_type == 'IMG'"
          class="eu-resource-modal-img"
          :src="activeResource.resource_url"
          :alt="activeResource.resource_url"
        />
        <iframe
          class="eu-resource-modal-file"
          v-if="activeResource.resource_type == 'VID'"
          :src="getYoutubeOrVimeoEmbedLink(activeResource.resource_url)"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        <embed
          v-if="activeResource.resource_type == 'DOC'"
          class="eu-resource-modal-file"
          :src="activeResource.resource_url"
          alt="No preview available"
        />
        <div v-if="activeResource.resource_type == 'URL_INTERNAL'">
          <p style="margin:30px 40px 0px"></p>
          <a
          :href="activeResource.resource_url"
          target="_blank"
          style="margin-top: 35px"
          class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
          aria-label="Proceed to internal url"
          >
          Proceed  <i class="far fa-external-link" style="margin-left:5px"></i>
          </a>
        </div>
        <div v-if="activeResource.resource_type == 'URL_EXTERNAL'"  @click="onExternalUrlClick(activeResource.id)"
          @keyup.enter="onExternalUrlClick(activeResource.id)">

          <a
          :href="activeResource.resource_url"
          target="_blank"
          style="margin-top: 35px"
          class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
          aria-label="Proceed to external url"
          >
          Proceed  <i class="far fa-external-link" style="margin-left:5px"></i>
          </a>
            <p style="margin:30px 40px 0px">
              This external URL resource possibly leads to the another website. Are you sure that you want to proceed?</p>
        </div>
      </div>
    </Modal>
  </section>
</template>
<script lang="ts">
import Modal from "../../../components/ModalExtended.vue";
import { getYoutubeOrVimeoEmbedLink } from "../../../utils/CustomUtils";
import ResourceLibraryService from "../../../library/services/ResourceLibraryService";

export default {
  components: { Modal },
  name: "resource-modal",
  props: ["isResourceModalActive", "activeResource", "backTxt", "showNextPrevArrow"],
  data() {
    return {
      service: new ResourceLibraryService(),
    };
  },
  async created() {},
  methods: {
    closeResourceModal() {
      this.$emit("close-resource-modal");
    },
    nextItem() {
      this.$emit("next-item");
    },
    prevItem() {
      this.$emit("previous-item");
    },
    async downloadItem(dataurl, filename, id) {
      this.service.increaseDownloaded(id);
      const res = await fetch(dataurl);
      const blob = await res.blob();
      this.startDownload(blob, filename);
    },
    startDownload(content, filename) {
      var a = document.createElement("a");
      a.download = filename ? filename : "resources";
      document.body.appendChild(a);
      a.href = URL.createObjectURL(content);
      a.style.display = "none";
      a.click();
      document.body.removeChild(a);
    },
    onExternalUrlClick(resourceId){
      this.service.increaseClickThroughs(resourceId)
    },
    getYoutubeOrVimeoEmbedLink,
  },
};
</script>
