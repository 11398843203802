<template>
  <div class="card-wrapper" id="card">
    <div style="height:48%" >
        <div
          class="card-thumbnail"
          :style="`background-image: linear-gradient(to bottom, #000 0%, rgba(0, 0, 0, 0) 47%), url(${item.thumbnail});`"
        >
        <div class="card-option-wrapper">
          <div>
            <span v-if="item.type_of_program_management==='event'" class="card-option-baner">Event <span v-if="!item.is_published"> Draft</span></span>
            <span v-if="item.type_of_program_management==='program'" class="card-option-baner">Program <span v-if="!item.is_published"> Draft</span></span>
          </div>
          <div style="display:flex; justify-content:flex-end; width:100%">
            <!-- <span @click="showManageCourseMenu()">
              <i v-if="!attending" class="far fa-user-alt card-option-icon" title="not going"></i>
              <i v-else class="fas fa-user-check card-option-icon active" title="going"></i>
            </span>
            <div
              v-if="show_menu"
              :id="`program-${item.id}`"
              ref="menu"
              class="program-burger-menu-dialog"
            >
              <div class="program-burger-menu-option-wrapper">
                <span @click="!attending && changeAttendance()" :class="attending? 'program-burger-menu-option active': 'program-burger-menu-option'">
                  <span>
                    <i class="fas fa-user-check"></i>
                    {{item.type_of_program_management==='event' ? 'Going': 'Interested' }}
                  </span>
                  <i class="far fa-dot-circle"></i>
                </span>
                 <span @click="attending && changeAttendance()" :class="!attending? 'program-burger-menu-option active': 'program-burger-menu-option'">
                   <span >
                      <i class="fa-solid fa-user-xmark"></i>
                      {{item.type_of_program_management==='event' ? 'Not Going': 'Not interested' }}
                    </span>
                  <i class="fas fa-circle"></i>
                </span>
              </div>
            </div> -->
            <span
              @click="handleBookmark('add')"
              @keyup.enter="handleBookmark('add')"
              role="button"
              title="add to saved"
              :aria-label="`Add ${item.title} to saved events`"
            >
              <i v-if="!item.is_bookmarked" class="far fa-bookmark card-option-icon"></i>
            </span>
            <span
              @click="handleBookmark('remove')"
              @keyup.enter="handleBookmark('remove')"
              role="button"
              title="remove from saved"
              :aria-label="`Remove ${item.title} from saved events`"
            >
              <i v-if="item.is_bookmarked" class="fas fa-bookmark card-option-icon active"></i>
            </span>
            <a
              :href=" item.type_of_program_management==='event'? `/events/edit/${item.id}`: `/programs/edit/${item.id}`"
              aria-label="`Edit ${item.title} ${item.type_of_program_management}`"
              role="button"
              v-if="can_manage"
              title="`Edit ${item.title} ${item.type_of_program_management}`"
            >
              <i class="fas fa-pen card-option-icon"></i>
            </a>
          </div>
        </div>
        </div>
      <div v-if="is_partner_page ? item.is_partner_featured : item.is_featured || item.is_featured_for_audience" class="card-banner">
        <span class="featured-banner">
          <i class="fas fa-stars"></i>
          FEATURED
        </span>
      </div>
    </div>
    <div id="body" class="card-body">
      <router-link
        :to=" item.type_of_program_management==='event'? `/events/${item.slug}`: `/programs/${item.slug}`"
        :aria-label="`Go to page of ${item.title} ${item.type_of_program_management}`"
      >
        <div v-if="item.start_date" class="card-collection-data">
          <p>
            {{ item.start_date | shortDateFormat }}
            <span v-if="item.end_date">
              - {{ item.end_date | shortDateFormat }}
            </span>
          </p>
        </div>
        <h5 class="card-title">{{ item.title }}</h5>
      </router-link>
      <p
        class="card-description"
        :style="item.title.length > 25? `-webkit-line-clamp: 2` : `-webkit-line-clamp: 3`"
      >
        {{ item.description }}
      </p>
    </div>
    <div id="footer" class="card-footer">
    <div class="event-card-bottom-left">
        <span v-if="item.is_online" class="eu-event-collection-location">
          <i class="eu-event-collection-icon fas fa-desktop"></i>
          <p>Virtual</p>
        </span>
        <span v-else class="eu-event-collection-location">
          <i class="eu-event-collection-icon fas fa-map-marker-alt"></i>
          {{ item.city }}, {{ item.state }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import "../../utils/DateFormat"
import {formatEventTime} from '../../utils/DateFormat'

export default {
    name:"card-component",
    props:['item', 'is_attending', 'can_manage', 'is_partner_page'],
    data(){
        return{
          show_menu: false,
          attending: false,
        }
    },
    async created(){
      this.attending = this.is_attending;
    },
    methods:{
      handleBookmark(action){
        this.$emit("handle-bookmark", this.item.id, action);
        this.item.is_bookmarked = !this.item.is_bookmarked;
      },
      changeAttendance(){
        this.attending = !this.attending;
        this.show_menu = false;
        this.$emit("change-attendance", this.item.id);
      },
      formatEventTime,
      showManageCourseMenu() {
       this.show_menu = !this.show_menu;
      },
    }
}
</script>

<style>

</style>
