<template>
    <div style="position: relative;">
        <button
            class="iu-main-btn main solid small"
            :class="buttonClass"
            @click="showMenu()"
            aria-label="open popup menu"
            aria-haspopup="true"
        >
            {{ buttonText }} <i class="far fa-chevron-down chevron-icon iu-main-button-arrow-icon"></i>
        </button>
        <div
            class="eu-resource-burger"
            :class="is_active? 'eu-resource-burger-active' : ''"
            style="right: 0; margin-top: 5px; white-space: break-spaces"
        >
            <slot name="items"></slot>
        </div>
    </div>
</template>
<script lang="ts">
export default{
    name: 'popup-component',
    props: [
        'buttonText',
        'buttonClass',
    ],
    data(){
        return{
            is_active: false,
        }
    },

    methods:{
        showMenu(){
            this.is_active = !this.is_active;
        }
    }
}

</script>
<style lang="scss" scoped>
.iu-main-btn.solid.main.partner {
  background-color: #2d98d2;

  &:hover {
    background-color: #006ead;
  }
}

@media screen and (max-width: 576px) {
  .iu-main-btn.solid.main.partner {
    width: 100%;
  }
}
</style>
