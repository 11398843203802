<template>
  <div class="spinner-content">
    <div :class="wrapperClass" :style="wrapperStyle">
      <div class="spinner-container animation-2">
        <div :class="isPartnerTenantStyle || isPartnerTenant() ? `shape shape1dabi` : `shape shape1`"></div>
        <div :class="isPartnerTenantStyle || isPartnerTenant() ? `shape shape2dabi` : `shape shape2`"></div>
        <div :class="isPartnerTenantStyle || isPartnerTenant() ? `shape shape3dabi` : `shape shape3`"></div>
        <div :class="isPartnerTenantStyle || isPartnerTenant() ? `shape shape4dabi` : `shape shape4`"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">

import { isPartnerTenant } from '../utils/CustomUtils';
  export default {
    name: 'loading-spinner',

    props: {
      wrapperClass: {
        default: '',
        type: String
      },
      wrapperStyle: {
        default: '',
        type: String
      },
      isPartnerTenantStyle: {
        default: false,
        type: Boolean
      }
    },

    data() { return {}; },
    methods: {
      isPartnerTenant
    }
  }
</script>