import Vue from 'vue';
import FirstGenSuccess from './FirstGenSuccess.vue';

export default class FirstGenSuccessView {
  protected mountPoint: HTMLElement;

  constructor(el: HTMLElement) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const partnerWithIncludifi = new Vue({
      components: {
        FirstGenSuccess
      },
      template: "<first-gen-success />"
    })
    partnerWithIncludifi.$mount(this.mountPoint);
  }
}
