<template>
  <div>
    <section class="eu-resource-grid desktop-only" v-if="!isPartnerTab">
      <div
        v-for="(item, i) in resources"
        v-bind:key="i"
        class="iu-resource-grid-item"
        :class="selectedResources.includes(item.id) ? 'selected' : ''"
      >
        <div
          class="iu-resource-grid-image-container"
          @click="viewItem(item, i)"
          @keyup.enter="viewItem(item, i)"
          :aria-label="`Open ${item.name}`"
          aria-haspopup="dialog"
          role="button"
          tabindex="0"
        >
          <span
            :class="selectedResources.includes(item.id) ? 'selected' : ''"
            class="iu-resource-grid-checkbox"
            @click="toggleItemSelection($event, item)"
            @keyup.enter="toggleItemSelection($event, item)"
            :aria-label="`Select ${item.name}`"
            tabindex="0"
            role="checkbox"
          >
            <i class="fa-solid fa-check iu-resource-grid-check-icon" v-if="selectedResources.includes(item.id)"></i>
          </span>
          <img
            tabindex="0"
            v-if="cardImageUrl(item)"
            :src="cardImageUrl(item)"
            class="iu-resource-grid-image"
          />
          <i v-else :class="cardFaIconClass(item)" class="iu-resource-grid-icon"></i>
        </div>
        <div class="iu-resource-grid-content-container">
          <div>
            <span
              tabindex="0"
              @keyup.enter="viewItem(item, i)"
              @click="viewItem(item, i)"
              role="button"
              :aria-label="`Open ${item.name} in modal`"
              aria-haspopup="dialog"
              class="iu-resource-grid-title"
              :title="`${item.name}`"
            >
              {{ item.name }}
            </span>
            <div class="iu-resource-grid-description" :title="`${item.description}`">
              {{ item.description ? item.description : '' }}
            </div>
          </div>
          <div class="iu-resource-grid-department" :title="`Posted by ${item.department.name}`" v-if="item.department">
            Posted by {{ item.department.name }}
          </div>
        </div>
        <div class="iu-resource-grid-action-container">
          <div
            style="margin-right: 0 !important"
            tabindex="0"
            :id="`resource_burger_grid_${item.id}`"
            ref="resGridBurger"
            aria-label="show more options"
            role="button"
            aria-haspopup="menu"
            @click="showBurger(item)"
            @keyup.enter="showBurger(item)"
            class="eu-resource-cell eu-resource-cell-dots-icon grid-icon"
          >
            <i class="fad fa-ellipsis-v-alt"></i>
          </div>
          <div
            ref="resGridBurgerDropdown"
            :id="'r' + item.id + item.timestamp"
            class="eu-resource-burger"
            style="right: 20px"
          >
            <span
              tabindex="0"
              :aria-label="`Open ${item.name} in modal`"
              role="button"
              aria-haspopup="dialog"
              @click="viewItem(item, i)"
              @keyup.enter="viewItem(item, i)"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-eye" style="padding-right: 5px"></i>
              <span>View</span>
            </span>
            <span
              v-if="has_permission(item)"
              tabindex="0"
              :aria-label="`Open ${item.name} edit modal`"
              role="button"
              aria-haspopup="dialog"
              @click="openEditModal(item)"
              @keyup.enter="openEditModal(item)"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-edit" style="padding-right: 5px"></i>
              <span>Edit</span>
            </span>
            <span
              tabindex="0"
              :aria-label="item.is_partner_featured ? 'Unfeature ' + item.name : 'Feature ' + item.name"
              v-if="currentPage != 'archive' && has_permission(item) && isPartnerTab"
              @click="featureItem(item)"
              @keyup.enter="featureItem(item)"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-stars" style="padding-right: 5px"></i>
              <span>{{ item.is_partner_featured ? 'Unfeature' : 'Feature' }}</span>
            </span>
            <span
              tabindex="0"
              :aria-label="item.is_recommended ? 'Unfeature ' + item.name : 'Feature ' + item.name"
              v-if="currentPage != 'archive' && has_permission(item) && !isPartnerTab"
              @click="featureItem(item)"
              @keyup.enter="featureItem(item)"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-stars" style="padding-right: 5px"></i>
              <span>{{ item.is_recommended ? 'Unfeature' : 'Feature' }}</span>
            </span>
            <span
              v-if="
                item.resource_type != 'VID' &&
                item.resource_type != 'URL' &&
                item.resource_type != 'URL_INTERNAL' &&
                item.resource_type != 'URL_EXTERNAL'
              "
              tabindex="0"
              role="button"
              :aria-label="`Download ${item.name}`"
              @click="downloadItem(item.resource_url, item.name, item.id)"
              @keyup.enter="downloadItem(item.resource_url, item.name, item.id)"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-download" style="padding-right: 5px"></i>
              <span>Download</span>
            </span>
            <span
              tabindex="0"
              :aria-label="
                item.is_bookmarked
                  ? 'Remove ' + item.name + ' from saved resources'
                  : 'Add ' + item.name + ' to saved resources'
              "
              v-if="currentPage != 'archive'"
              @click="toggleBookmark(item)"
              @keyup.enter="toggleBookmark(item)"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fad fa-bookmark" style="padding-right: 10px"></i>
              <span>{{ item.is_bookmarked ? 'Unsave' : 'Save' }}</span>
            </span>
            <span
              tabindex="0"
              :aria-label="'Copy ' + item.name + ' link.'"
              v-if="currentPage != 'archive'"
              @click="copyResourceLink(item.id)"
              @keyup.enter="copyResourceLink(item.id)"
              role="button"
              class="eu-resource-burger-el"
            >
              <div>
                <i class="fa-solid fa-link" style="padding-right: 10px"></i>
                Copy link
              </div>
              <div class="iu-resource-copy-link-additional-text">File accessible to members only</div>
            </span>
            <span
              tabindex="0"
              :aria-label="
                item.is_archived
                  ? 'Remove ' + item.name + ' from archived resources'
                  : 'Add ' + item.name + ' to archived resources'
              "
              v-if="has_permission(item)"
              @click="toggleArchive(item)"
              @keyup.enter="toggleArchive(item)"
              role="button"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-archive" style="padding-right: 5px"></i>
              <span>{{ item.is_archived ? 'Unarchive' : 'Archive' }}</span>
            </span>
            <span
              v-if="has_permission(item)"
              tabindex="0"
              :aria-label="`Open remove modal for ${item.name}`"
              role="button"
              aria-haspopup="dialog"
              @click="openDeleteModal(item)"
              @keyup.enter="openDeleteModal(item)"
              class="eu-resource-burger-el"
            >
              <i class="fas fa-trash-alt" style="padding-right: 5px"></i>
              <span>Delete</span>
            </span>
          </div>
          <div
            tabindex="0"
            :aria-label="item.is_partner_featured ? 'Unfeature ' + item.name : 'Feature ' + item.name"
            v-if="currentPage != 'archive' && isAdminRole && isPartnerTab"
            @click="featureItem(item)"
            @keyup.enter="featureItem(item)"
            role="button"
            class="eu-resource-cell eu-resource-cell-feature-icon grid-icon"
            style="margin-right: 0 !important"
          >
            <i class="fad fa-stars" :style="item.is_partner_featured ? 'color: #00a1b3; background: #eaeeff' : ''"></i>
          </div>
          <div
            tabindex="0"
            :aria-label="item.is_recommended ? 'Unfeature ' + item.name : 'Feature ' + item.name"
            v-if="currentPage != 'archive' && isAdminRole && !isPartnerTab"
            @click="featureItem(item)"
            @keyup.enter="featureItem(item)"
            role="button"
            class="eu-resource-cell eu-resource-cell-feature-icon grid-icon"
            style="margin-right: 0 !important"
          >
            <i class="fad fa-stars" :style="item.is_recommended ? 'color: #00a1b3; background: #eaeeff' : ''"></i>
          </div>
          <div
            tabindex="0"
            :aria-label="
              item.is_bookmarked
                ? 'Remove ' + item.name + ' from saved resources'
                : 'Add ' + item.name + ' to saved resources'
            "
            v-if="currentPage != 'archive'"
            @click="toggleBookmark(item)"
            @keyup.enter="toggleBookmark(item)"
            class="eu-resource-cell eu-resource-cell-bookmark-icon grid-icon"
            style="margin-right: 0 !important"
          >
            <i class="fad fa-bookmark" :style="item.is_bookmarked ? 'color: #9f2a8c; background: #eaeeff;' : ''"></i>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="!isPartnerTab"
      @scroll="horizontalScrollHandler($event)"
      class="resource-cards-section mobile-only"
      style="padding-right: 32px; width: 100%"
    >
      <resources-card
        v-for="(item, index) in resources"
        :key="index"
        :resource="getResourceCardData(item)"
        :isSelected="selectedResources.includes(item.id)"
        @toggle-selection="toggleItemSelection"
        @view-item="viewItem(item, index)"
        @edit-item="openEditModal(item)"
        @unfeature-item="featureItem(item)"
        @feature-item="featureItem(item)"
        @download-item="downloadItem(item.resource_url, item.name, item.id)"
        @remove-item-bookmark="toggleBookmark(item)"
        @bookmark-item="toggleBookmark(item)"
        @copy-item-link="copyResourceLink(item.id)"
        @unarchive-item="toggleArchive(item)"
        @archive-item="toggleArchive(item)"
        @delete-item="openDeleteModal(item)"
      ></resources-card>
    </section>
    <section class="resource-grid-section" v-if="isPartnerTab">
      <partner-resource-card
        v-for="(item, index) in resources"
        :key="index"
        :resource="getPartnerResourceCardData(item)"
        :isSelected="selectedResources.includes(item.id)"
        @toggle-selection="toggleItemSelection"
        @view-item="viewItem(item, index)"
        @edit-item="openEditModal(item)"
        @unfeature-item="featureItem(item)"
        @feature-item="featureItem(item)"
        @download-item="downloadItem(item.resource_url, item.name, item.id)"
        @remove-item-bookmark="toggleBookmark(item)"
        @bookmark-item="toggleBookmark(item)"
        @copy-item-link="copyResourceLink(item.id)"
        @unarchive-item="toggleArchive(item)"
        @archive-item="toggleArchive(item)"
        @delete-item="openDeleteModal(item)"
      ></partner-resource-card>
    </section>
  </div>
</template>
<script lang="ts">
import ResourcesCard from './ResourcesCard.vue';
import PartnerResourceCard from './PartnerResourceCard.vue';
import handleOutsideClick from '../../directives/handleOutsideClick';
import { useAuthStore } from "../../stores/auth";

export default {
  components: {
    ResourcesCard,
    PartnerResourceCard
  },
  directives: { closable: handleOutsideClick },
  name: 'resources-grid',
  props: [
    'resources',
    'directionUp',
    'sortedBy',
    'sortBy',
    'resourceTable',
    'selectedAll',
    'selectedResources',
    'manageAccess',
    'currentPage',
    'isAdminRole',
    'department_name',
    'group',
    'partner',
    'isPartnerTab',
    'idsPartnerAccountsManaged'
  ],
  data() {
    return {};
  },
  methods: {
    cardImageUrl(item) {
      if (item.resource_type === 'IMG') {
        return item.resource_url;
      }
      if (item.thumbnail_url) {
        return item.thumbnail_url;
      }
      return null;
    },
    cardFaIconClass(item) {
      if (item.resource_type === 'DOC') {
        return 'fa-regular fa-file-alt';
      }
      if (item.resource_type === 'VID') {
        return 'far fa-file-video';
      }
      if (item.resource_type === 'URL') {
        return 'far fa-external-link';
      }
      if (item.resource_type === 'URL_INTERNAL') {
        return 'far fa-external-link';
      }
      if (item.resource_type === 'URL_EXTERNAL') {
        return 'far fa-external-link';
      }
    },
    getResourceCardData(item) {
      return {
        id: item.id,
        image_source: item.resource_type == 'IMG' ? item.resource_url : '',
        name: item.name,
        description: item.description,
        department_name: item.department?.name,
        resource_type: item.resource_type,
        has_permission: this.has_permission(item),
        is_featured: item.is_recommended,
        is_partner_featured: item.is_partner_featured,
        is_bookmarked: item.is_bookmarked,
        is_archived: item.is_archived,
      };
    },
    getPartnerResourceCardData(item) {
      return {
        id: item.id,
        image_source: item.resource_type == 'IMG' ? item.resource_url : '',
        name: item.name,
        description: item.description,
        department_name: item.department?.name,
        resource_type: item.resource_type,
        has_permission: this.has_permission(item),
        is_featured: item.is_recommended,
        is_partner_featured: item.is_partner_featured,
        is_bookmarked: item.is_bookmarked,
        is_archived: item.is_archived,
        partner_name: item.partner_name,
        partner_logo: item.partner_logo,
        created_at: item.timestamp,
        thumbnail_url: item.thumbnail_url,
      };
    },
    horizontalScrollHandler(event) {
      this.$emit('horizontal-scroll', event);
    },
    copyResourceLink(resource_id) {
      this.$emit('copyResourceLink', resource_id);
    },
    sort(column) {
      this.$emit('sortTable', column);
    },
    viewItem(el, index) {
      this.$emit('viewTableItem', el, index);
    },
    showBurger(el) {
      this.$emit('showBurgerWindow', el);
    },
    toggleArchive(el) {
      this.$emit('archive', el);
    },
    closeBurgers: function () {
      this.$emit('close-burgers', this.resourceTable);
    },
    featureItem(el) {
      this.$emit('feature', el);
    },
    downloadItem(dataurl, filename, id) {
      this.$emit('download', dataurl, filename, id);
    },
    openDeleteModal(el) {
      this.$emit('openDelete', el);
    },
    openEditModal(el) {
      this.$emit('openEdit', el);
    },
    toggleBookmark(el) {
      this.$emit('bookmark', el);
    },
    toggleItemSelection(event, item) {
      event.stopPropagation();
      this.$emit('toggleItemSelection', item);
    },
    setSelectedResource(resource, resourceTable) {
      this.$emit('setSelectedResourceItem', resource, resourceTable);
    },
    selectAll(resources, isSelected, resourceTable) {
      this.$emit('selectAllItems', resources, isSelected, resourceTable);
    },

    has_permission(el) {
      if (this.isAdminRole) return true;
      if (this.group) return el.group?.id === this.group?.id && this.manageAccess;
      if (this.partner) return el.partner?.id === this.partner?.id &&
        (this.manageAccess || this.idsPartnerAccountsManaged.includes(this.partner?.id));
      if (el.department) {
        return this.manageAccess && this.department_name === el.department.name ? true : false;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.resource-grid-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
}

@media screen and (max-width: 760px) {
  .resource-grid-section {
    grid-template-columns: 1fr;
  }
}
</style>
