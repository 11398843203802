<template>
  <div :class="label ? `has-label ${wrapperClass}` : wrapperClass" class="select-base-wrapper">
    <select
      :id="id"
      v-bind="$attrs"
      @change="$emit('input', $event.target.value)"
      :value="value"
      class="select-base-field"
    >
      <option v-if="!placeholderDisabled" value="" hidden disabled class="select-base-placeholder" :selected="placeholderActive">{{ placeholder }}</option>
      <option
        v-for="option in options"
        :value="option.value"
        :key="option.value"
        :selected="option.value === value"
      >
        {{option.label}}
      </option>
    </select>
    <i class="fa-light fa-angle-down select-base-icon"></i>
    <label
      v-if="label"
      :for="id"
      class="select-base-label"
    >{{ label }} <span class="select-base-required-star" v-if="required">*</span></label>
  </div>
</template>

<script lang='ts'>
interface Option {
  label: String;
  value: String;
}
export default {
  name: 'select-base',
  props: {
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array<Option>,
      required: true
    },
    value: {
      type: [String, Number],
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Select option"
    },
    placeholderDisabled: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    placeholderActive() {
      return this.placeholder && !this.value;
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">
.select-base {
  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    &.has-label {
      height: 48px;
    }
  }

  &-required-star {
    font-size: 13px;
    color: #e65722;
    font-weight: 600;
  }

  &-label {
    background-color: #fff;
    padding: 0 6px 2px;
    color: #3b3355;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    bottom: 32px;
    left: 6px;
    width: unset !important;
  }

  &-icon {
    font-size: 15px;
    position: absolute;
    top: 18px;
    right: 12px;
    color: #685df3;
    font-weight: 600;

    pointer-events: none;
  }

  &-field {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    height: 40px;
    padding: 9px 16px;
    border-radius: 6px;
    border: solid 1.5px #95919b;
    width: 100%;

    font-size: 16px;
    line-height: 1.35;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      border-color: #95919b;
      pointer-events: none;

      & + label {
        color: #95919b;

        >.select-base-required-star {
          color: #95919b;
        }
      }

      & + .select-base-icon {
        color: #95919b;
      }
    }

    &:focus {
      outline: none;
      border-color: #685df3;
    }

    &:active {
      border-color: #685df3;
    }
  }
}

@media screen and (max-width: 576px) {
  .select-base {
    &-field {
      height: 36px;
      font-size: 16px;
      padding: 0 12px;
    }

    &-icon {
      right: 12px;
      bottom: 10px;
    }
  }
}
</style>
