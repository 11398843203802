import Vue from 'vue';
import * as Cookies from 'js-cookie';
import RolePermissions from './components/RolePermissions.vue';

export default class RoleManager {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected appData: any;
  protected isPartner: boolean;

  constructor(el: HTMLElement, appData: any) {
    this.csrfToken = Cookies.get('csrftoken');
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.appData = appData;
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const roleManager = new Vue({
      components: { RolePermissions },
      template: `<role-permissions :appData=appData />`,
      data() {
        return {
          appData: _class.appData
        };
      },
      methods: {},
    });
    roleManager.$mount(this.mountPoint);
  }
}
