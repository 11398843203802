<template>
   <div class="flex-column-center">
    <main id="main" class="main-wrapper">
      <campus-apps-navbar :canManage="isAdmin || isDeptLead" :showAddBtn="isAdmin" :deptPlural="deptPlural"></campus-apps-navbar>
      <div class="eu-search-campusapps" role="search">
        <input v-if="searchOption === 'keyword'" @keyup.enter="search()" v-model="searchInput" type="text" placeholder="Search" class="eu-search-input" />
        <button @click="search()" class="eu-search-btn-icon-campusapps" aria-label="search community library">
          <i class="eu-search-icon fas fa-search"></i>
        </button>
        <multiselect-filter-component
          v-if="searchOption === 'focus_area'"
          placeholder="Any Focus Area"
          filter_name="Focus Areas"
          :options="filterData.focus_areas"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="searchOption === 'department'"
          :placeholder="`Any ${deptSingular}`"
          filter_name="Departments"
          :options="filterData.departments"
          @filter-data="createFilterData"
        />
        <span class="search-options-wrapper">
          <span
            tabindex="0"
            class="search-option"
            :class="searchOption === searchOptions.KEYWORD && 'active'"
            aria-label="Change to keyword search"
            role="button"
            @click="changeSearch(searchOptions.KEYWORD)"
            @keyup.enter="changeSearch(searchOptions.KEYWORD)"
          >
            Keyword Search
          </span>
          <span
            tabindex="0"
            class="search-option"
            :class="searchOption === searchOptions.FOCUS_AREA && 'active'"
            aria-label="Change to search by focus areas"
            role="button"
            @click="changeSearch(searchOptions.FOCUS_AREA)"
            @keyup.enter="changeSearch(searchOptions.FOCUS_AREA)"
          >
            By Focus Area
          </span>
          <span
            tabindex="0"
            class="search-option"
            :class="searchOption === searchOptions.DEPARTMENT && 'active'"
            aria-label="Change to search by departments"
            role="button"
            @click="changeSearch(searchOptions.DEPARTMENT)"
            @keyup.enter="changeSearch(searchOptions.DEPARTMENT)"
          >
            By {{deptSingular}}
          </span>
        </span>
      </div>
      <div v-if="!isLoading && apps.length > 0" class="ca-container">
        <div v-for="(ca, i) in apps" v-bind:key="i" class="ca-item-wrapper">
          <div class="ca-item">
            <div class="ca-content-wrapper">
              <img class="ca-logo" :src="ca.logo_url" />
              <div class="ca-content">
                <div class="ca-top">
                  <div class="ca-title">{{ ca.name }}</div>
                  <div class="ca-desc">{{ ca.description }}</div>
                </div>
                <div class="ca-bottom">
                  <div class="ca-dept"><b class="md-bold">Used by:</b> {{ ca.department.name }}</div>
                  <div class="ca-fa"><b class="md-bold">Related Focus Areas:</b> {{ parseFocusAreas(ca) }}</div>
                </div>
              </div>
            </div>
            <div class="ca-options">
              <a class="ca-goto" :href="ca.url" target="_blank">Go to {{ ca.name }} <i class="far fa-external-link ca-link-icon"></i></a>
              <span class="ca-options-wrapper">
                <span
                  v-if="isAdmin || (isDeptLead == ca.department.name)"
                  class="inup-profile-row-bookmark"
                  title="Edit Campus App"
                  @click="goToEdit(ca.id)"
                  @keyup.enter="goToEdit(ca.id)"
                  aria-label="Go to edit Campus App"
                  role="button"
                  ><i tabindex="0" class="fad fa-pen bookmark-icon"></i>
                </span>
                <span
                  class="inup-profile-row-bookmark"
                  :title="ca.is_bookmarked ? 'Remove bookmark from Campus App' : 'Bookmark Campus App'"
                  @click="handleBookmark(ca)"
                  @keyup.enter="handleBookmark(ca)"
                  aria-label="Toggle Campus App bookmark"
                  role="button"
                  ><i tabindex="0" :class="`${ca.is_bookmarked ? 'bookmark-active' : ''} fad fa-bookmark bookmark-icon`"></i>
                </span>
              </span>
            </div>
          </div>
          <div class="ca-mobile">
            <div class="ca-bottom-mobile">
              <div class="ca-dept"><b class="md-bold">Used by:</b> {{ ca.department.name }}</div>
              <div class="ca-fa"><b class="md-bold">Related Focus Areas:</b> {{ parseFocusAreas(ca) }}</div>
            </div>
            <div class="ca-options-mobile">
              <a class="ca-goto" :href="ca.url" target="_blank">Go to {{ ca.name }} <i class="far fa-external-link ca-link-icon"></i></a>
              <span class="ca-options-wrapper">
                <span
                  v-if="isAdmin || (isDeptLead == ca.department.name)"
                  class="inup-profile-row-bookmark"
                  title="Edit Campus App"
                  @click="goToEdit(ca.id)"
                  @keyup.enter="goToEdit(ca.id)"
                  aria-label="Go to edit Campus App"
                  role="button"
                  ><i tabindex="0" class="fad fa-pen bookmark-icon"></i>
                </span>
                <span
                  class="inup-profile-row-bookmark"
                  :title="`${ca.is_bookmarked ? 'Remove bookmark from Campus App' : 'Bookmark Campus App'}`"
                  @click="handleBookmark(ca)"
                  @keyup.enter="handleBookmark(ca)"
                  aria-label="Toggle Campus App bookmark"
                  role="button"
                  ><i tabindex="0" :class="`${ca.is_bookmarked ? 'bookmark-active' : ''} fad fa-bookmark bookmark-icon`"></i>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading">
        <loading-spinner wrapperClass="wrapper" />
      </div>
      <div v-if="!isLoading && apps.length == 0">
        <div class="eu-empty-container" :class="isPartnerTenant ? 'partner' : ''">
          <div class="eu-empty">
            <div class="eu-empty-icon-container" :class="isPartnerTenant ? 'partner' : ''">
              <i class="fad fa-bullhorn eu-empty-icon" :class="isPartnerTenant ? 'partner' : ''"></i>
            </div>
            <div style="display: flex; flex-direction: column">
              <span class="eu-empty-text"> No data/results. </span>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import CampusAppService from "../services/CampusAppService";
import CampusAppsNavbar from "./CampusAppsNavbar.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import BookmarkService from '../../bookmarks/services/BookmarkService';
import { isPartnerTenant } from "../../utils/CustomUtils";
import { useCommonDataStore } from "../../stores/commonData";

const SearchOptions = {
  KEYWORD: "keyword",
  FOCUS_AREA: "focus_area",
  DEPARTMENT: "department",
};

export default {
  name: "campus-apps-index",
  components: { CampusAppsNavbar, MultiselectFilterComponent, LoadingSpinner },
  props: ["isAdmin", "isDeptLead"],
  computed: {
    filterData() {
      return {
        departments: this.commonDataStore.getSingleDataArray('departments'),
        focus_areas: this.commonDataStore.getSingleDataArray('focus_areas'),
      };
    },
    deptPlural() {
      return this.commonDataStore.getFeatureName('department_plural').toLowerCase()
    },
    deptSingular() {
      return this.commonDataStore.getFeatureName('department_singular')
    }
  },
  data() {
    return {
      apps: [],
      searchOption: SearchOptions.KEYWORD,
      searchOptions: SearchOptions,
      searchInput: "",
      selectedDepartments: [],
      selectedFocusAreas: [],
      service: new CampusAppService(),
      bookmarkService: new BookmarkService(),
      commonDataStore: useCommonDataStore(),
      isLoading: false
    };
  },
  async created() {
    this.isLoading = true;
    this.apps = await this.service.getAll();
    this.isLoading = false;
  },
  methods: {
    isPartnerTenant,
    async search() {
      this.isLoading = true;
      this.apps = await this.service.getAll({ q: this.searchInput, focus_areas: this.selectedFocusAreas, departments: this.selectedDepartments });
      this.isLoading = false;
    },
    changeSearch(searchOption) {
      this.searchOption = searchOption;
    },
    createFilterData(value, name) {
      if (name === "Departments") {
        this.selectedDepartments = value.map((v) => encodeURIComponent(v.name));
      }
      if (name === "Focus Areas") {
        this.selectedFocusAreas = value.map((v) => encodeURIComponent(v.name));
      }
      this.search();
    },
    parseFocusAreas(app) {
      return app.campus_focus_areas?.map((e) => e.name).join(", ");
    },
    async handleBookmark(app) {
      const method = app.is_bookmarked ? 'deleteBookmarkedCampusApp' : 'bookmarkCampusApp'
      const res = await this.bookmarkService[method]({target: app.id})
      if(res.success) {
        app.is_bookmarked = !app.is_bookmarked;
      }
    },
    goToEdit(appId) {
      this.$router.push(`/campusapps/edit/${appId}`);
    },
  },
};
</script>
