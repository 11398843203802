<template>
  <div
    class="item-card"
    :style="`background: linear-gradient(to top, #000 10%, rgba(0, 0, 0, 0) 55%), url(${group.cover_pic}) no-repeat center center / cover;`"
  >
    <div class="toolbar">
      <div class="left"><div class="type-label">Group</div></div>
      <div class="right">
        <i
          class="fas fa-ellipsis-v"
          style="text-shadow: 2px 2px rgba(0, 0, 0, 0.1)"
          tabindex="0"
          ref="grListBurger"
          @click="showBurger()"
          @keyup.enter="showBurger()"
          aria-label="Burger menu"
          role="menu"
          aria-haspopup="menu"
        >
        </i>
        <div
          ref="grListBurgerDropdown"
          :id="`gr${group.id}`"
          v-closable="{ exclude: ['grListBurgerDropdown', 'grListBurger'], handler: 'closeBurger' }"
          class="inup-profile-row-burger dashboard-burger-pos"
        >
          <span
            @click="redirectToGroup(group.name)"
            @keyup.enter="redirectToGroup(group.name)"
            tabindex="0"
            :aria-label="`Open ${group.name}`"
            role="button"
            class="eu-resource-burger-el"
          >
            <i class="fas fa-eye" style="padding-right: 5px; color: #88858e"></i>
            <span>Visit Group</span>
          </span>
        </div>
      </div>
    </div>
    <div class="img-card-info-wrapper">
      <div
        @click="redirectToGroup(group.name)"
        @keyup.enter="redirectToGroup(group.name)"
        class="img-card-name text-clamp-1"
      >
        {{ group.name }}
      </div>
      <div class="img-card-dept text-clamp-1">{{ group.group_followers.length }} followers</div>
      <div class="img-card-btns">
        <button
          aria-label="Unfollow group"
          v-if="currentUserFollowing(group)"
          @click="unfollowGroup(group)"
          @keyup.enter="unfollowGroup(group)"
          class="iu-main-btn x-small solid main"
          style="width: 100%"
        >
          Following
        </button>
        <button
          v-else
          @click="followGroup(group)"
          @keyup.enter="followGroup(group)"
          aria-label="Follow Group"
          class="iu-main-btn x-small outlined light"
          style="width: 100%"
        >
          Follow
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import handleOutsideClick from '../../directives/handleOutsideClick';
import FeedService from "../../feed/services/FeedService";
import * as Cookies from "js-cookie";

export default {
  directives: { closable: handleOutsideClick },
  components: {},
  name: "group-card",
  props: ["group", "group_followed", "userId"],
  data() {
    return {
      lastOpenedBurger: null,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  created() {
    this.feedService = new FeedService();
  },
  methods: {
    currentUserFollowing(group) {
      let isFollowing = false;
      group.group_followers.forEach((user) => {
        if (user.id == this.userId) {
          isFollowing = true;
          return;
        }
      });

      return isFollowing;
    },
    followGroup(group) {
      this.feedService.followGroupFeed({ isFollowing: true, group: group.name }, this.headers);
      this.$emit("toggle-group-follow", group.name);
    },
    unfollowGroup(group) {
      this.feedService.followGroupFeed({ isFollowing: false, group: group.name }, this.headers);
      this.$emit("toggle-group-follow", group.name);
    },
    redirectToGroup(group_name) {
      this.$router.push(`/groups/${encodeURIComponent(group_name)}`);
    },
    showBurger() {
      const burgerEl = document.querySelector(`#gr${this.group.id}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl) {
        this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
      }
      burgerEl.classList.toggle("eu-resource-burger-active");
      this.lastOpenedBurger = burgerEl;
    },
    closeBurger() {
      this.lastOpenedBurger && this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
    },
  },
  computed: {},
};
</script>
