<template>
        <ul class="eu-event-collection">
            <li
                class="eu-event-collection-card"
                v-for="item in events"
                :key="item.id"
            >
                <router-link
                    :to="getItemSlug(item)"
                    :aria-label="`Go to page of ${item.title} event`"
                >
                    <img
                        v-if="item.thumbnail"
                        alt="Cover picture of event"
                        class="eu-event-collection-card-img"
                        v-bind:src="item.thumbnail"
                    />
                    <h5 class="eu-event-collection-title">{{ item.title }}</h5>
                </router-link>

                <div class="eu-event-collection-body">
                    <div class="eu-event-collection-date">
                        <span>{{ item.start_date | dateFormat() }}</span>
                    </div>

                    <div class="eu-event-collection-details">
                        <p>{{ item.description }}</p>
                    </div>

                    <div v-if="item.is_online" class="eu-event-collection-location">
                        <i class="eu-event-collection-icon fas fa-desktop"></i>
                        <span>Virtual</span>
                    </div>
                    <div v-else class="eu-event-collection-location">
                        <i class="eu-event-collection-icon fas fa-map-marker-alt"></i>
                        <span>{{ item.city }}, {{ item.state }}</span>
                    </div>

                    <div
                        tabindex="0"
                        @click="removeBookmark(item.id)"
                        @keyup.enter="removeBookmark(item.id)"
                        class="eu-event-collection-bookmark"
                        :aria-label="`Remove ${item.title} from saved events`"
                        role="button"
                    >
                        <i
                            class="eu-event-collection-icon fad fa-bookmark"
                            style="color: #4c55a7"
                        ></i>
                        <span style="color: #4c55a7">Saved</span>
                    </div>
                </div>
            </li>
        </ul>
</template>
<script lang="ts">

export default {
    name: "browse-saved-events",

    props: ['events'],

    data() {
        return {};
    },

    methods: {
        getItemSlug(item) {
            if (item.type_of_program_management == 'program') {
                return '/programs/' + item.slug;
            } else {
                return '/events/' + item.slug;
            }
        },
        removeBookmark(id: string) {
            this.$emit('remove-bookmark', id);
        },
    },
}
</script>
