<template>
  <div class="lobby">
    <div v-if="(isMentor && isStudentOnline) || (!isMentor && isMentorOnline)" class="status-label ready"><i class="fas fa-check-circle"></i> Ready!</div>
    <div v-else class="status-label loading"><i class="fas fa-spinner"></i> Please wait...</div>

    <h1>MentorConnect Lobby</h1>
    <div v-if="isMentor" class="status-text-container">
      <p v-if="isStudentOnline">Your student {{ usernameOther }} has joined the meeting lobby.</p>
      <p v-else>Please wait for your student to join the lobby.</p>
    </div>

    <div v-else class="status-text-container">
      <p v-if="isMentorOnline">Your mentor {{ usernameOther }} has joined the lobby. Please wait for them to start the meeting.</p>
      <p v-else>Please wait for your mentor to join and start the meeting.</p>
    </div>

    <div class="lobby-bubble lobby-bubble-triangle" :class="{ border: isOtherUserOnline }">
      <p>{{ usernameOther }}</p>
      <div class="lobby-img-wrapper">
        <div class="lobby-img-status" :style="`background: ${isOtherUserOnline ? '#3fa389' : '#e65722'}`"></div>
        <img class="lobby-img" :src="avatarOther" alt="other user's avatar" />
      </div>
    </div>

    <div v-if="isMentor">
      <button
        v-show="isStudentOnline"
        :disabled="isStudentOnline ? false : true"
        class="iu-btn-solid big green"
        @click="startMeeting"
      >
        Start meeting
        <i class="fas fa-chevron-right fa-sm" style="color:#77c4ca;position:relative;left:3px;top:1px;"></i>
      </button>
    </div>


    <div style="margin-top: 30px">
      <a href="/meetings/">Return to MentorConnect</a>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: "private-lobby",

  props: ["isMentor", "usernameOther", "avatarOther", "isStudentOnline", "isMentorOnline"],

  data() {
    return {};
  },

  computed: {
    isOtherUserOnline: function () {
      if (this.isMentor) {
        return this.isStudentOnline;
      } else {
        return this.isMentorOnline;
      }
    },
  },

  methods: {
    startMeeting: function () {
      this.$emit("start-meeting");
    },
  },
};
</script>
