import HttpService from '../services/HttpService';

export default class CommonDataService extends HttpService {
  constructor() {
    super('')
  }

  getFeatureNames(): Promise<any> {
    this.endpoint = '/licenses/api/feature-names';
    return this.get();
  }

  getDepartmentsAndFocusAreas(): Promise<any> {
    this.endpoint = '/accounts/api/filter_data';
    return this.get();
  }
}
