<template>
   <section style="grid-column: 2/16;">
    <div class="event-search-manage-container" style="margin: 0;">
      <div class="eu-search">
        <form
          action=""
          @submit.prevent="fetchFollowers()"
          class="eu-search-form"
          style="margin-top:50px"
          role="search"
        >
          <input
            v-model="search_input"
            name="q"
            type="search"
            placeholder="Search"
            class="eu-search-input"
          />
          <button class="eu-search-btn-icon" aria-label="search">
            <i class="eu-search-icon fas fa-search"></i>
          </button>
        </form>
      </div>
    </div>
    <div v-if="isLoading">
        <loading-spinner wrapperClass="wrapper"/>
    </div>
        <admins-and-mentors-tab
          v-else
          :users="followers"
          :favoriteMentorsPage="false"
          :followersPage="true"
          :loggedUser="{}"
        />
   </section>
</template>


<script>
import LoadingSpinner from "../../components/LoadingSpinner.vue"
import AdminsAndMentorsTab from './AdminsAndMentorsTab.vue';
import GroupService from '../../groups/services/GroupService';
import DepartmentService from '../../departments/services/DepartmentService';
import PartnerAccountService from '../../partneraccounts/services/PartnerAccountService';

export default{
    components: {LoadingSpinner, AdminsAndMentorsTab},
    name: "followers-tab",
    props: ["type", "id"],
    data() {
        return {
            search_input: '',
            followers: [],
            isLoading: true,
            groupService: new GroupService(),
            deptService: new DepartmentService(),
            partnerService: new PartnerAccountService()
        }
    },
    async created() {
        this.fetchFollowers();
    },
    methods:{
        async fetchFollowers() {
            this.isLoading = true;
            if(this.type === 'group') {
                this.followers = await this.groupService.getGroupFollowers({group: this.id, q: this.search_input});
            } else if(this.type === 'department') {
                this.followers = await this.deptService.getDeptFollowers({department: this.id, q: this.search_input});
            } else if(this.type === 'partner') {
                this.followers = await this.partnerService.getPartnerManagers({partner: this.id, q: this.search_input});
            }
            this.isLoading = false;
        }
    }


}
</script>
