import Cookies from 'js-cookie';
import HttpService from '../../services/HttpService';

const headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};
export default class ResourceService extends HttpService {
  constructor() {
    super('/library/api');
  }

  getResources(params): Promise<any> {
    this.endpoint = `/library/api`;
    return this.get(params);
  }

  getResourcesDabi(params?): Promise<any> {
    this.endpoint = '/library/api/dabi';
    return this.get(params);
  }

  getFeatured(params): Promise<any> {
    this.endpoint = `/library/api/featured`;
    return this.get(params);
  }

  getSaved(params): Promise<any> {
    this.endpoint = `/library/api/saved`;
    return this.get(params);
  }

  getArchived(params): Promise<any> {
    this.endpoint = `/library/api/archived`;
    return this.get(params);
  }

  getFilterData(): Promise<any> {
    this.endpoint = `/library/api/filterData`;
    return this.get();
  }

  getOneResource(id): Promise<any> {
    this.endpoint = `/library/api/details/${id}`;
    return this.get();
  }

  deleteResource(id, headers, group = null, partner = null) {
    if(partner) {
      this.endpoint = `/library/api/details/${id}?partner=${partner ? encodeURIComponent(partner) : ''}`;
    } else {
      this.endpoint = `/library/api/details/${id}?group=${group ? encodeURIComponent(group) : ''}`;
    }
    return this.delete({}, headers);
  }
  editResource(item, headers, group = null, partner = null) {
    const areas = ['focus_areas', 'identities', 'audiences'];
    areas.forEach(area => {
      if (item[area][0]?.name) {
        item[area] = item[area].map(e => e.name);
      }
    });
    if(partner) {
      this.endpoint = `/library/api/details/${item.id}?partner=${partner ? encodeURIComponent(partner) : ''}`;
    } else {
      this.endpoint = `/library/api/details/${item.id}?group=${group ? encodeURIComponent(group) : ''}`;
    }
    return this.put(item, headers);
  }

  increaseDownloaded(id) {
    this.endpoint = `/library/api/downloaded/${id}`;
    return this.put({}, headers);
  }

  increaseClickThroughs(id) {
    this.endpoint = `/library/api/clickthrough/${id}`;
    return this.put({}, headers);
  }

  increaseViews(id) {
    this.endpoint = `/library/api/views/${id}`;
    return this.put({}, headers);
  }

  createPostFromGroup(file, headers) {
    this.endpoint = `/library/api`;
    return this.postFormData(file, headers);
  }

}

