<template>
  <section v-if="isPageLoading">
    <loading-spinner wrapperClass="wrapper" />
  </section>
  <section v-else>
    <div class="flex-column-center">
      <div class="main-wrapper">
        <div class="accounts-title-wrapper">
          <common-title
            title="Manage accounts"
            description="Manage and assign different roles to your users."
          />
        </div>
        <div id="main" class="eu-simple-navbar">
          <router-link
            v-if="isAdmin || !isPartnerTenant()"
            to="/accounts"
            aria-label="Go to Accounts manage page"
            class="eu-simple-navbar-item"
          >
            Accounts
          </router-link>
          <router-link
            v-if="isAdmin && isPartnerTenant()"
            to="/accounts/partners"
            aria-label="Go to Partners manage page"
            class="eu-simple-navbar-item active"
          >
            Partners
          </router-link>
          <router-link
            v-if="isManagerOnly && isPartnerTenant()"
            to="/accounts/partners"
            aria-label="Go to manage Managers page"
            class="eu-simple-navbar-item active"
          >
            Managers
          </router-link>
        </div>
        <partners-filter @filterPartners="filterPartners" />
        <div class="eu-selected-toolbar" v-if="isBulkActionsActive">
          <span class="eu-selected-toolbar-text"> {{ bulkActionsText }} </span>
          <span v-if="isPartnersSelected" @click="openBulkDeletePartnerModal" style="cursor: pointer">
            <i class="fa-solid fa-trash-alt eu-selected-toolbar-icon"></i>Delete partners</span
          >
          <span v-if="isManagersSelected" @click="openBulkDeleteManagerModal" style="cursor: pointer">
            <i class="fa-solid fa-trash-alt eu-selected-toolbar-icon"></i>Delete managers</span
          >
          <span v-if="isManagersSelected" @click="bulkResendInvitations" style="cursor: pointer">
            <i class="fa-solid fa-envelope eu-selected-toolbar-icon"></i>Resend invitations</span
          >
          <span v-if="isManagersSelected" @click="bulkResetPassword" style="cursor: pointer">
            <i class="fa-solid fa-key eu-selected-toolbar-icon"></i>Reset password</span
          >
          <span @click="cancelSelection()" style="cursor: pointer">
            <i class="fa-solid fa-times eu-selected-toolbar-icon"></i>Cancel
          </span>
        </div>
        <div class="iu-manage-partners-actions-container" v-else>
          <partners-buttons
            :isManagerTable="isManagerOnly"
            :canAddPartner="canAddPartner"
            :canAddManager="isPartnerAdmin"
            @openAddPartner="openAddPartnerModal"
            @openAddManager="openAddManagerModal"
            @handleExport="handleExport"
          />
          <div class="total-accounts">
            Total Accounts: <b>{{ accountsCountText }}</b>
          </div>
        </div>
        <div class="table-container" style="width: 100%; overflow-y: visible; margin: 30px 0">
          <partners-table
            v-if="!isManagerOnly"
            :partners="partners"
            :is_managers_table="false"
            :sortDirection="sortDirection"
            :selectedPartners="selectedPartners"
            :selectedManagers="selectedManagers"
            :allPartnersSelected="allPartnersSelected"
            @sort="sort"
            @toggle-all-partners="toggleAllPartners"
            @toggle-partner="togglePartner"
            @toggle-manager="toggleManager"
            @edit-partner="editPartner"
            @add-manager="openAddManagerModal"
            @edit-manager="openEditManagerModal"
            @view-dashboard="viewDashboard"
            @delete-partner="openDeletePartnerModal"
            @delete-manager="openDeleteManagerModal"
            @reset-password="resetPassword"
            @resend-invitation="resendInvitation"
          ></partners-table>
          <managers-table
            v-else
            :managers="managers"
            :sortDirection="sortDirection"
            :selectedManagers="selectedManagers"
            :allManagersSelected="allManagersSelected"
            @sort="sort"
            @toggle-all-managers="toggleAllManagers"
            @toggle-manager="toggleManager"
            @add-manager="openAddManagerModal"
            @edit-manager="openEditManagerModal"
            @delete-manager="openDeleteManagerModal"
            @reset-password="resetPassword"
            @resend-invitation="resendInvitation"
          >
          </managers-table>
        </div>
        <partner-manager-modal
          :isDeleteModal="isDeleteModal"
          :isModalActive="isPartnerModalActive"
          :partnerProp="selectedPartner"
          :managerProp="selectedManager"
          :bulkPartnersProp="selectedPartnersDelete"
          :bulkManagersProp="selectedManagersDelete"
          @close-modal="closePartnerModal"
          @show-notification="showNotification"
        />

        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>
        <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import PartnersFilter from "./partners_table/PartnersFilter.vue";
import PartnersButtons from "./partners_table/PartnersButtons.vue";
import PartnersTable from "./partners_table/PartnersTable.vue";
import ManagersTable from "./partners_table/ManagersTable.vue";
import PartnerManagerModal from "../../accounts/components/modals/PartnerManagerModal.vue";
import PartnerAccountService from "../../partneraccounts/services/PartnerAccountService";
import Notification from "../../components/Notification.vue";
import Cookies from "js-cookie";
import AccountsService from "../services/AccountsService";
import { isPartnerTenant } from "../../utils/CustomUtils";
import CommonTitle from '../../components/CommonTitle.vue';

export default {
  name: "manage-partners",
  props: ["isAdmin", "isPartnerAdmin", "isPartnerManager"],
  components: {
    LoadingSpinner,
    PartnersFilter,
    PartnersButtons,
    PartnersTable,
    PartnerManagerModal,
    Notification,
    ManagersTable,
    CommonTitle
  },
  data() {
    return {
      isLoading: false,
      isPageLoading: true,
      isDeleteModal: false,
      partners: [],
      managers: [],
      sortDirection: "up",
      sortBy: "name",
      filterParameters: {},
      selectedPartners: [],
      selectedPartnersDelete: [],
      allPartnersSelected: false,
      allManagersSelected: false,
      selectedManagers: [],
      selectedManagersDelete: [],
      isPartnerModalActive: false,
      selectedPartner: null,
      selectedManager: null,
      partnerService: new PartnerAccountService(),
      accountsService: new AccountsService(),
      notification: { msg: "", isError: false, icon: null },
      isNotificationActive: false,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  computed: {
    partnersCount() {
      return this.partners ? this.partners.length : 0;
    },
    managersCount() {
      return this.managers ? this.managers.length : 0;
    },
    accountsCountText() {
      if (this.isManagerOnly) {
        return this.managersCount;
      } else {
        return this.partnersCount + "/" + this.partnersLimit;
      }
    },
    canAddPartner() {
      return this.partnersCount < this.partnersLimit;
    },
    isPartnersSelected() {
      return this.selectedPartners?.length > 0;
    },
    isManagersSelected() {
      return this.selectedManagers?.length > 0;
    },
    isBulkActionsActive() {
      return this.isManagersSelected || this.isPartnersSelected;
    },
    bulkActionsText() {
      const partnersText = this.isPartnersSelected ? `${this.selectedPartners.length} partners ` : "";
      const managersText = this.isManagersSelected ? `${this.selectedManagers.length} managers ` : "";

      if (this.isPartnersSelected && this.isManagersSelected) {
        return partnersText + "and " + managersText + "selected.";
      } else {
        return partnersText + managersText + "selected.";
      }
    },
  },
  async created() {
    await this.getPreData();
    if (this.isManagerOnly) {
      this.fetchManagers();
    } else {
      this.fetchPartners();
    }
  },
  methods: {
    isPartnerTenant,
    async getPreData() {
      this.isPageLoading = true;
      const res = await this.accountsService.getPartnerPreData();
      if (res.success) {
        this.usersPartnerId = res.users_partner_id;
        this.partnersLimit = res.partners_limit;
        this.isManagerOnly = res.is_manager_only;
      }
      this.isPageLoading = false;
    },
    async fetchPartners() {
      this.isLoading = true;
      this.partners = await this.partnerService.getAllPartners({
        limit: 999,
        sort_by: this.sortBy,
        sort_direction: this.sortDirection,
      });
      this.isLoading = false;
    },
    async fetchManagers() {
      this.isLoading = true;
      this.managers = await this.partnerService.getAllManagers({
        limit: 999,
        sort_by: this.sortBy,
        sort_direction: this.sortDirection,
      });
      this.isLoading = false;
    },
    async filterPartners(filterParameters) {
      this.partners = await this.partnerService.getAllPartners({
        q: encodeURIComponent(filterParameters.searchInput),
        is_active: filterParameters.selectedStatus,
        limit: 999,
      });
    },
    toggleManager(manager_id, partner_id) {
      const newItem = {
        manager_id: manager_id,
        partner_id: partner_id,
      };
      const index = this.selectedManagers.findIndex(
        (manager) => manager.manager_id == manager_id && manager.partner_id == partner_id
      );

      if (index > -1) {
        this.selectedManagers.splice(index, 1);
      } else {
        this.selectedManagers.push(newItem);
      }

      if (this.managers.length == this.selectedManagers.length && this.isManagerOnly) {
        this.allManagersSelected = true;
      } else {
        this.allManagersSelected = false;
      }
    },
    toggleAllPartners() {
      this.allPartnersSelected = !this.allPartnersSelected;
      this.selectedPartners = this.allPartnersSelected ? this.partners.map((x) => x.id) : [];
    },
    toggleAllManagers() {
      this.allManagersSelected = !this.allManagersSelected;
      this.selectedManagers = this.allManagersSelected
        ? this.managers.map((x) => {
            return { manager_id: x.id, partner_id: x.partner_id };
          })
        : [];
    },
    togglePartner(id) {
      const index = this.selectedPartners.indexOf(id);

      if (index > -1) {
        this.selectedPartners.splice(index, 1);
      } else {
        this.selectedPartners.push(id);
      }
      this.allPartnersSelected = this.selectedPartners.length == this.partners.length;
    },
    changeSortDirection() {
      this.sortDirection = this.sortDirection == "up" ? "down" : "up";
    },
    sort(sortBy) {
      if (sortBy == this.sortBy && this.sortDirection) {
        this.changeSortDirection();
      } else {
        this.sortDirection = "up";
      }

      this.sortBy = sortBy;
      this.fetchPartners();
    },
    editPartner(name) {
      window.location.href = `/partneraccounts/${name}/edit`;
    },
    viewDashboard(name) {
      this.$router.push(`/partneraccounts/${name}`);
    },
    openBulkDeletePartnerModal() {
      this.selectedPartnersDelete = this.selectedPartners;
      this.selectedPartner = null;
      this.selectedManager = null;
      this.isPartnerModalActive = true;
      this.isDeleteModal = true;
    },
    openDeletePartnerModal(partner) {
      this.selectedPartner = partner;
      this.selectedManager = null;
      this.isPartnerModalActive = true;
      this.isDeleteModal = true;
    },
    closePartnerModal(reloadTable) {
      this.isPartnerModalActive = false;
      this.isDeleteModal = false;
      this.selectedPartners = [];
      this.selectedManagers = [];
      this.selectedPartnersDelete = [];
      this.selectedManagersDelete = [];
      if (reloadTable) {
        this.selectedPartner = null;
        this.selectedManager = null;
        if (this.isManagerOnly) {
          this.fetchManagers();
        } else {
          this.fetchPartners();
        }
      }
    },
    cancelSelection() {
      this.selectedManagers = [];
      this.selectedPartners = [];
    },
    openAddPartnerModal() {
      this.selectedPartner = {};
      this.selectedManager = {};
      this.isPartnerModalActive = true;
    },
    openAddManagerModal(partnerId) {
      if (partnerId) {
        this.selectedPartner = { id: partnerId };
      } else {
        this.selectedPartner = { id: this.usersPartnerId };
      }
      this.selectedManager = null;
      this.isPartnerModalActive = true;
    },
    openEditManagerModal(manager, partner) {
      this.selectedManager = { ...manager };
      this.selectedPartner = { id: partner ? partner.id : manager.partner_id };
      this.isPartnerModalActive = true;
    },
    closeNotification() {
      this.notification = { msg: "", isError: false, icon: null };
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.notification = notification;
      this.isNotificationActive = true;
    },
    openBulkDeleteManagerModal() {
      this.selectedManagersDelete = this.selectedManagers;

      this.selectedManager = null;
      this.isPartnerModalActive = true;
      this.isDeleteModal = true;
    },
    openDeleteManagerModal(manager_id, partner_id) {
      this.selectedManager = { id: manager_id, partner_id: partner_id };
      this.selectedPartner = { id: partner_id ? partner_id : this.selectedManager.partner_id };
      this.isPartnerModalActive = true;
      this.isDeleteModal = true;

      const index = this.selectedManagers.findIndex(
        (manager) => manager.manager_id == manager_id && manager.partner_id == partner_id
      );

      if (index > -1) {
        this.selectedManagers.splice(index, 1);
      }
    },
    async bulkResetPassword() {
      let resetPasswordManagerIdList = this.selectedManagers.map((manager) => manager.manager_id);

      const params = {
        user_ids: resetPasswordManagerIdList,
      };

      const res = await this.partnerService.resendPasswordEmail(params, this.headers);

      if (res.success) {
        this.showNotification({ msg: res.message, icon: "fad fa-check-circle" });
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: "fad-fa-exclamation-circle" });
      }
    },
    async resetPassword(manager_id) {
      let resetPasswordManagerIdList = [];

      if (manager_id && !resetPasswordManagerIdList.includes(manager_id)) {
        resetPasswordManagerIdList.push(manager_id);
      }

      const params = {
        user_ids: resetPasswordManagerIdList,
      };

      const res = await this.partnerService.resendPasswordEmail(params, this.headers);

      if (res.success) {
        this.showNotification({ msg: res.message, icon: "fad fa-check-circle" });
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: "fad-fa-exclamation-circle" });
      }
    },
    handleExport(fileType) {
      if (this.isManagerOnly) {
        const partner_id = this.managers[0].partner_id;
        const params = {
          fileType: fileType,
          partner_id: partner_id,
        };
        this.partnerService.downloadManagers(params, this.headers);
      } else {
        const partner_ids = this.partners.map((partner) => partner.id);
        const params = {
          fileType: fileType,
          partner_ids: partner_ids,
        };
        this.partnerService.downloadPartners(params, this.headers);
      }
    },
    async resendInvitation(manager_id, partner_id) {
      const params = {
        managers: [
          {
            manager_id: manager_id,
            partner_id: partner_id,
          },
        ],
      };
      const res = await this.partnerService.inviteUsers(params, this.headers);
      if (res.success) {
        this.showNotification({ msg: res.message, icon: "fad fa-check-circle" });
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: "fad-fa-exclamation-circle" });
      }
    },
    async bulkResendInvitations() {
      const params = {
        managers: this.selectedManagers,
      };
      const res = await this.partnerService.inviteUsers(params, this.headers);
      if (res.success) {
        this.showNotification({ msg: res.message, icon: "fad fa-check-circle" });
      } else {
        this.showNotification({ msg: res.message, isError: true, icon: "fad-fa-exclamation-circle" });
      }
    },
  },
};
</script>
<style scoped>
.accounts-title-wrapper {
  padding: 24px 0;
}
</style>