<template>
    <section class="one-stop-section">
        <div class="one-stop-section-header">
            <h2>Community Library</h2>
            <router-link to="/library?page=saved" aria-label="Go to your saved resources" class="iu-main-btn main text-only small" style="white-space: nowrap;">See All<i class="fa-regular fa-chevron-right iu-main-button-arrow-icon"></i></router-link>
        </div>
        <browse-saved-resources
            v-if="!isLoading"
            :resources="resources"
            @remove-bookmark="removeBookmark"
            idPrefix='r'
        />
        <loading-spinner v-else />
    </section>
</template>
<script lang="ts">
import Cookies from 'js-cookie';
import BookmarkService from "../services/BookmarkService";
import BrowseSavedResources from "../components/BrowseSavedResources.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";

export default {
    name: "interface-saved-resources",

    components: {
        BrowseSavedResources,
        LoadingSpinner,
    },

    data() {
        return {
            resources: [],
            isLoading: true,

            service: new BookmarkService(),
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
                Accept: 'application/json'
            },
        }
    },

    created() {
        this.getSavedResources();
    },

    methods: {
        async getSavedResources() {
            this.isLoading = true;
            const result = await this.service.getBookmarkedResources({
                limit: 6,
            });
            this.resources = result['resources'];
            if (this.resources.length === 0) {
                this.$emit('empty');
            }
            this.isLoading = false;
        },

        async removeBookmark(id: string) {
            await this.service.deleteBookmarkedResources({
                target: id
            }, this.headers)
            this.getSavedResources();
        },
    },
}
</script>
