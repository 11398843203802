<template>
  <div class="dashboard-card">
    <div v-if="title" class="card-title">
      <i :class="`fa-regular ${faIcon}`" :style="iconColor ? `color: ${iconColor};` : ''"></i>
      <span class="text">{{ title }}</span>
    </div>
    <div v-if="showOptions" class="card-options">
      <!-- If we pass an array (or dict with) with names of sections instead of dict with booleans this can be v-for -->
      <div
        v-if="availableFeatures.courses && cardFeatures.includes('courses')"
        class="card-option courses"
        :class="{ active: section == SECTIONS.courses }"
        tabindex="0"
        @click="setSection('courses')"
        @keyup.enter="setSection('courses')"
      >
        <i class="fad fa-book-reader"></i> Pathways
      </div>
      <div
        v-if="availableFeatures.resource_library && cardFeatures.includes('resources')"
        class="card-option resources"
        :class="{ active: section == SECTIONS.resources }"
        tabindex="0"
        @click="setSection('resources')"
        @keyup.enter="setSection('resources')"
      >
        <i class="fad fa-images"></i> Resources
      </div>
      <div
        v-if="availableFeatures.appointments && cardFeatures.includes('mentors')"
        class="card-option mentors"
        :class="{ active: section == SECTIONS.mentors }"
        tabindex="0"
        @click="setSection('mentors')"
        @keyup.enter="setSection('mentors')"
      >
        <i class="fad fa-messages"></i> Mentors
      </div>
      <div
        v-if="availableFeatures.programs && !eventsOnly && cardFeatures.includes('experiences')"
        class="card-option experiences"
        :class="{ active: section == SECTIONS.experiences }"
        tabindex="0"
        @click="setSection('experiences')"
        @keyup.enter="setSection('experiences')"
      >
        <i class="fad fa-rocket" style="--fa-primary-opacity: 0.4; --fa-secondary-opacity: 1"></i> Opportunities
      </div>
      <div
        v-if="availableFeatures.events && cardFeatures.includes('events')"
        class="card-option events"
        :class="{ active: section == SECTIONS.events }"
        tabindex="0"
        @click="setSection('events')"
        @keyup.enter="setSection('events')"
      >
        <i class="fad fa-calendar-star"></i> Events
      </div>
      <div
        v-if="availableFeatures.quest_courses && cardFeatures.includes('questCourses')"
        class="card-option questCourses"
        :class="{ active: section == SECTIONS.questCourses }"
        tabindex="0"
        @click="setSection('questCourses')"
        @keyup.enter="setSection('questCourses')"
      >
        <i class="fad fa-book-open-reader"></i> Courses
      </div>
      <div
        v-if="availableFeatures.groups && cardFeatures.includes('groups')"
        class="card-option groups"
        tabindex="0"
        :class="{ active: section == SECTIONS.groups }"
        @click="setSection('groups')"
        @keyup.enter="setSection('groups')"
      >
        <i class="fad fa-users"></i> Groups
      </div>
      <div
        v-if="availableFeatures.depts && cardFeatures.includes('departments')"
        class="card-option departments"
        tabindex="0"
        :class="{ active: section == SECTIONS.departments }"
        @click="setSection('departments')"
        @keyup.enter="setSection('departments')"
      >
        <i class="fad fa-building"></i> {{deptPlural}}
      </div>
    </div>
    <div class="main-content-wrapper">
      <div
        v-if="pageIndex > 0"
        tabindex="0"
        @click="setPage(pageIndex - 1)"
        @keyup.enter="setPage(pageIndex - 1)"
        class="go-left"
      >
        <i class="fa-solid fa-chevron-left chevron-btn"></i>
      </div>
      <div
        v-if="pageIndex < pagesLen - 1"
        tabindex="0"
        @click="setPage(pageIndex + 1)"
        @keyup.enter="setPage(pageIndex + 1)"
        class="go-right"
        :style="showOptions ? 'transform: translateY(50%);' : 'transform: translateY(-50%);'"
      >
        <i class="fa-solid fa-chevron-right chevron-btn"></i>
      </div>
      <div :class="contentWrapperClass ? contentWrapperClass : ''">
        <slot></slot>
      </div>
    </div>
    <div class="card-footer">
      <div class="dots" :style="hideSeeAll && 'margin-bottom: 16px;'">
        <span
          tabindex="0"
          v-for="(n, i) in pagesLen"
          :key="n"
          class="featured-course-carousel-dot"
          :class="`${i === pageIndex ? 'eu-featured-event-carousel-dot-selected' : ''}`"
          @click="setPage(i)"
          @keyup.enter="setPage(i)"
        ></span>
      </div>
      <div class="right">
        <router-link
          v-if="!hideSeeAll"
          aria-label="Customize profile"
          :to="getSeeAllUrl()"
          class="iu-main-btn small solid light"
          style="z-index: 9"
        >
          See all
          <i aria-hidden="true" class="fas fa-chevron-right iu-main-button-arrow-icon"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { useCommonDataStore } from '../../stores/commonData';
import { SECTIONS, SEE_ALL_URLS } from './DashboardCards.vue';

export default {
  name: 'dashboard-card',
  components: {},
  props: [
    'seeAllUrl',
    'showOptions',
    'title',
    'section',
    'pagesLen',
    'pageIndex',
    'faIcon',
    'iconColor',
    'availableFeatures',
    'contentWrapperClass',
    'eventsOnly',
    'hideSeeAll',
    'cardFeatures'
  ],
  data() {
    return {
      SECTIONS,
    };
  },
  async created() {},
  methods: {
    setPage(index) {
      this.$emit('set-page', index);
    },
    setSection(section) {
      this.$emit('set-section', section);
    },
    getSeeAllUrl() {
      if (!this.seeAllUrl) {
        return SEE_ALL_URLS[this.section];
      } else {
        return this.seeAllUrl;
      }
    },
  },
  computed: {
     deptPlural() {
      return useCommonDataStore().getFeatureName("department_plural");
    },
  },
};
</script>
