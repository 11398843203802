<template>
  <section v-if="department">
    <section class="da-content-main--hero" style="display: grid; grid-template-columns: repeat(16, 1fr); background: #fff; border-bottom: 2px solid #ececec">
      <section class="da-content-main--hero eu-cover-image" :style="`background-image: url(${department.cover_pic});`">
        <div class="blackLayerGradient"></div>
        <h1 class="eu-cover-image-text-top"></h1>
      </section>
      <div class="iu-dept-profile-logo-wrapper-parent">
        <div v-if="department.logo" class="iu-dept-profile-logo-wrapper">
          <img :src="department.logo" class="iu-dept-profile-logo" />
        </div>
        <div class="iu-dept-profile-text">
          <div style="display: block">
            <span class="iu-dept-title">{{ department.name }}</span>
            <span class="iu-dept-label">{{deptSingular}}</span>
            <p style="margin-top: 16px; white-space: pre-line">{{ department.description }}</p>
          </div>
        </div>
      </div>
      <div style="grid-column: 2/16; display: flex; flex-direction: column-reverse;">
        <div class="department-navbar-wrapper">
          <nav class="eu-simple-navbar" style="display: flex; padding-bottom: 0;">
            <span
              tabindex="0"
              aria-label="Go to department overview page"
              role="link"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'overview' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('overview')"
              @keyup.enter="change_tab('overview')"
            >
              Overview
            </span>
            <span
              v-if="loggedUser.has_activity_feed_access"
              tabindex="0"
              aria-label="Go to department feed page"
              role="link"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'feed' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('feed')"
              @keyup.enter="change_tab('feed')"
            >
              Feed
            </span>
            <span
              tabindex="0"
              aria-label="Go to department admins & mentors page"
              role="link"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'admin_mentors' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('admin_mentors')"
              @keyup.enter="change_tab('admin_mentors')"
            >
              Admins &amp; Mentors
            </span>
            <span
              v-if="loggedUser.has_event_access"
              tabindex="0"
              aria-label="Go to department event page"
              role="button"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'events' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('events')"
              @keyup.enter="change_tab('events')"
            >
              Events
            </span>
            <span
              v-if="loggedUser.resource_library_permission.has_library_resource_access"
              tabindex="0"
              aria-label="Go to department resources page"
              role="button"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'resources' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('resources')"
              @keyup.enter="change_tab('resources')"
            >
              Resources
            </span>
            <span
              tabindex="0"
              aria-label="Go to department followers page"
              role="button"
              class="eu-simple-navbar-item"
              :class="selected_tab === 'followers' ? 'eu-simple-navbar-item-active' : ''"
              @click="change_tab('followers')"
              @keyup.enter="change_tab('followers')"
            >
              Followers
            </span>
          </nav>
        </div>
        <div v-if="loggedUser.isAdmin" class="feed-admin-btns">
          <button
            class="feed-admin-btn"
            style="margin-right: 16px; min-width: 100px"
            @click="handleFollow(department.is_following ? false : true)"
            @keyup.enter="handleFollow(department.is_following ? false : true)"
            :class="`${department.is_following ? 'iu-btn-light' : 'iu-btn-solid'}`"
            :aria-label="`${department.is_following ? 'Unfollow' : 'Follow'}`"
          >
            {{ department.is_following ? "Unfollow" : "Follow" }}
          </button>
          <a
            class="iu-btn-solid feed-admin-btn"
            style="min-width: 180px"
            :href="`/departments/${encodeURIComponent(department.name)}/edit`"
            :aria-label="`Go to ${department.name} edit page`"
          >
            <i class="fa fa-edit" style="opacity: 0.5; margin-right: 5px"></i>
            Edit {{deptSingular}}
          </a>
        </div>
      </div>
    </section>
    <div v-if="selected_tab === 'feed'" class="department-main-content-feed">
      <feed-posts
        :loggedUser="loggedUser"
        :isLoadingPosts="isLoadingPosts"
        :isLoadingScroll="isLoadingScroll"
        :posts="posts"
        :showCreatePost="canWritePost()"
        :departmentName="department.name"
        :focusAreas="focus_areas"
        :identities="identities"
        :audiences="audiences"
        :has_navbar="has_navbar"
        :show_search="show_search"
        @show-all-comments="showAllComments"
        @show-notification="showNotification"
        @get-posts="getPosts"
        @set-is-loading="setIsLoading"
        @search-feed="searchFeed"
        @update-post-reaction="updatePostReaction"
      />
    </div>
    <div class="department-main-content-overview">
      <department-details
        v-if="selected_tab === 'overview'"
        :loggedUser="loggedUser"
        :department="department"
        @show-notification="showNotification"
        @get-posts="getPosts"
        @set-is-loading="setIsLoading"
      />
      <admins-and-mentors-tab
        v-if="selected_tab === 'admin_mentors'"
        :users="users"
        :favoriteMentorsPage="false"
        :loggedUser="loggedUser"
        :ids_favorite_mentors="ids_favorite_mentors"
        @handle-favorite="handleFavoritesMentors"
      />

      <events-tab v-if="selected_tab === 'events'" :department="department" :loggedUser="loggedUser" :isGroupTab="false" />

      <resources-tab v-if="selected_tab === 'resources'" :department="department" :loggedUser="loggedUser" />

      <followers-tab v-if="selected_tab === 'followers'" type="department" :id="this.department.id" />
    </div>

    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </section>
</template>
<script lang="ts">
import * as Cookies from "js-cookie";
import FeedService from "../../feed/services/FeedService";
import DepartmentService from "../services/DepartmentService";
import ResourcesUpload from "../../library/components/ResourcesUpload.vue";
import Notification from "../../components/Notification.vue";
import FeedPosts from "../../feed/components/FeedPosts.vue";
import DepartmentDetails from "./DepartmentDetails.vue";
import EventsTab from "../../feed/components/EventsTab.vue";
import ResourcesTab from "../../feed/components/ResourcesTab.vue";
import AdminsAndMentorsTab from "../../feed/components/AdminsAndMentorsTab.vue";
import FollowersTab from "../../feed/components/FollowersTab.vue";
import AccountsService from "../../accounts/services/AccountsService";
import { FEATURES, hasPermission, PERMISSION_TYPES } from '../../utils/PermissionUtils';
import { useCommonDataStore } from '../../stores/commonData';

export default {
  components: { ResourcesUpload, Notification, FeedPosts, DepartmentDetails, AdminsAndMentorsTab, ResourcesTab, EventsTab, FollowersTab },
  name: "department-index",
  props: [],
  data() {
    return {
      posts: [],
      events: [],
      isLoadingPosts: true,
      isLoadingScroll: false,
      isFetchPending: false,
      pageCount: 1,
      searchHolder: "",
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      searchResultsShown: false,
      has_navbar: false,
      show_search: true,
      selected_tab: "overview",
      users: [],
      loggedUser: null,
      department: null,
      commonDataStore: useCommonDataStore(),
      active_tab: null,
      ids_favorite_mentors: [],
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  computed: {
    focus_areas() {
      return this.commonDataStore.getSingleDataArray('focus_areas');
    },
    audiences() {
      return this.commonDataStore.getSingleDataArray('audiences');
    },
    identities() {
      return this.commonDataStore.getSingleDataArray('identities');
    },
    deptSingular() {
      return useCommonDataStore().getFeatureName("department_singular");
    },
  },
  async created() {
    this.service = new FeedService();
    this.deptService = new DepartmentService();
    this.accountsService = new AccountsService();
    this.feedService = new FeedService();
    await this.getDeptData()
    this.active_tab = this.$route.query.active_tab;
    if (this.active_tab && ['overview', 'feed', 'admin_mentors', 'events', 'resources', 'followers'].includes(this.active_tab)) {
      this.change_tab(this.active_tab);
    }
    //this.selected_tab = this.loggedUser.has_activity_feed_access ? "feed" : "overview";
    if (this.selected_tab == "admin_mentors") {
      this.ids_favorite_mentors = await this.accountsService.getFavoriteMentors({ flag: "ids" });
      this.users = await this.deptService.getAdminsAndMentors(this.department.name);
    }
    this.scroll();
    await this.getPosts();
  },
  methods: {
    async getDeptData() {
      const deptName = this.$route.path.split("/").pop();
      const res = await this.deptService.getPredataDepartment(deptName);
      if (res.department) {
        this.department = res.department;
        this.loggedUser = {
          id: res.loggedUser,
          profile_pic: res.profile_pic,
          fullname: res.user_fullname,
          isAdmin: res.isAdmin,
          isMentor: res.isMentor,
          has_event_access: hasPermission(FEATURES.EVENTS) || hasPermission(FEATURES.PROGRAMS),
          has_event_managing_access: hasPermission(FEATURES.EVENTS, PERMISSION_TYPES.MANAGE) || hasPermission(FEATURES.PROGRAMS, PERMISSION_TYPES.MANAGE),
          hasWriteAccess: hasPermission(FEATURES.ACTIVITY_FEED, PERMISSION_TYPES.READ_WRITE),
          has_activity_feed_access: hasPermission(FEATURES.ACTIVITY_FEED),
          userDepts: res.user_departments,
          resource_library_permission: {
            has_library_resource_access: hasPermission(FEATURES.RESOURCE_LIBRARY),
            has_library_resource_managing_access: hasPermission(FEATURES.RESOURCE_LIBRARY, PERMISSION_TYPES.MANAGE),
          }
        }
      }
    },
    showAllComments(post) {
      this.posts = this.posts.map((p) => {
        if (post.id === p.id) {
          p.shrinkComments = false;
        }
        return p;
      });
    },
    checkCommentSize() {
      this.posts = this.posts.map((p) => {
        if (p.comments.length >= 3 && p.shrinkComments === undefined) {
          p.shrinkComments = true;
        }
        return p;
      });
    },
    async handleFollow(isFollowing: boolean) {
      this.department.is_following = !this.department.is_following;
      const res = await this.feedService.followFeed({ isFollowing, department: this.department.name }, this.headers);
      if (!res.success) {
        this.showNotification({ msg: "Server error!", isError: true, icon: "fad fa-exclamation-circle" });
      }
    },
    canWritePost() {
      return this.loggedUser.hasWriteAccess && this.loggedUser.userDepts.includes(this.department.name);
    },
    setIsLoading(isLoading) {
      this.isLoadingPosts = isLoading;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    async getPosts(pageNo, minPosts = 3) {
      this.pageCount = pageNo ? pageNo : this.pageCount;
      let newPosts = [];
      // with this while loop, we make sure that as a result we get at least 5 new posts
      while (newPosts.length <= minPosts) {
        this.isFetchPending = true;
        // get all posts from getstream (even if they're not the right type)
        const rawRes = await this.service.getDepartmentPosts({
          department: encodeURIComponent(this.department.name),
          q: this.searchHolder,
          page: this.pageCount,
        });
        if (rawRes.length === 0) {
          break;
        }
        // filter out right type of posts
        const res = rawRes.filter((p) => p.verb.includes("post"));
        newPosts = [...newPosts, ...res];
        if (newPosts.length <= minPosts) {
          this.pageCount++;
        }
      }
      this.posts = newPosts;
      this.posts &&
        this.posts.forEach((p) => {
          p.object = JSON.parse(p.object);
        });
      this.checkCommentSize();
      this.isFetchPending = false;
      this.isLoadingPosts = false;
    },
    async searchFeed(searchInput) {
      if (searchInput.length > 0) {
        this.searchResultsShown = true;
      } else this.searchResultsShown = false;
      this.searchHolder = searchInput;
      this.isLoadingPosts = true;
      await this.getPosts(1, 0);
    },
    async updatePostReaction(p) {
      p.object = JSON.parse(p.object);
      this.posts = this.posts.map((e) => {
        if (e.id === p.id) {
          e.own_reactions = p.own_reactions;
          e.reaction_counts = p.reaction_counts;
          e.comments = p.comments;
          e.newComment = "";
        }
        return e;
      });
    },
    async scroll() {
      window.onscroll = async () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;

        if (bottomOfWindow && this.pageCount !== 0 && !this.searchResultsShown && !this.isFetchPending) {
          this.pageCount += 1;

          if (!this.isLoadingPosts) {
            this.isLoadingScroll = true;
          }
          if (!this.isFetchPending) {
            let newPosts = [];
            while (newPosts.length <= 3) {
              this.isFetchPending = true;
              // get all posts from getstream (even if they're not the right type)
              const rawRes = await this.service.getDepartmentPosts({
                page: this.pageCount,
                department: encodeURIComponent(this.department.name),
                q: this.searchHolder,
              });
              if (rawRes.length === 0) {
                this.pageCount = 0;
                break;
              }
              // filter out right type of posts
              const res = rawRes.filter((p) => p.verb.includes("post"));
              newPosts = [...newPosts, ...res];
              if (newPosts.length <= 3) {
                this.pageCount++;
              }
            }
            newPosts.forEach((p) => {
              p.object = JSON.parse(p.object);
              this.posts.push(p);
            });
            this.isLoadingScroll = false;
            this.isFetchPending = false;
            this.checkCommentSize();
          }
        }
      };
    },
    async change_tab(tab) {
      this.selected_tab = tab;
      if (tab == "admin_mentors") {
        this.ids_favorite_mentors = await this.accountsService.getFavoriteMentors({ flag: "ids" });
        this.users = await this.deptService.getAdminsAndMentors(this.department.name);
      }
    },
    async handleFavoritesMentors(id) {
      if (this.ids_favorite_mentors.includes(id)) {
        await this.accountsService.removeFavoriteMentor(id, this.headers);
      } else {
        await this.accountsService.addFavoriteMentor({ user_id: this.loggedUser.id, mentor_id: id }, this.headers);
      }
      this.ids_favorite_mentors = await this.accountsService.getFavoriteMentors({ flag: "ids" });
    },
  },
};
</script>
