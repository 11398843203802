<template>
  <div class="dropdown-burger-wrapper">
    <button :id="id"
            ref="dropdownBurgerButton"
            aria-label="Show more options"
            :aria-expanded="ariaExpanded"
            type="button"
            aria-haspopup="menu"
            @click="toggleDropdown()"
            :class="buttonClass ? buttonClass : 'dropdown-burger-button'"
        >
      <i class="fa-solid fa-ellipsis-vertical"></i>
    </button>
    <div v-if="isExpanded"
        ref="dropdownMenu"
        v-closable="{ exclude: ['dropdownMenu', 'dropdownBurgerButton'],
                      handler: 'closeDropdown' }"
        class="dropdown-burger-action-list-container"
    >
      <div v-for="(actionItem, index) in actionList"
          :key="index"
          class="dropdown-burger-action-item-container"
          :aria-label="actionItem.label"
          tabindex="0"
          @click="selectAction(actionItem.action)"
          @keyup.enter="selectAction(actionItem.action)"
          >
        <div class="dropdown-burger-action-item-label-container">
          <i v-if="hasIcon(actionItem)"
              class="dropdown-burger-action-item-icon"
              :class="actionItem.icon"></i>
          {{actionItem.label}}
        </div>
        <div v-if="hasAdditionalDescription(actionItem)" class="dropdown-burger-action-item-additional-description-text">
          {{actionItem.additionalDescription}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import handleOutsideClick from '../../src/directives/handleOutsideClick';
/*
  prop actionList = [{
    'label': String,
    'icon': String, - icon class
    'action': String, - emit event name
    'additionalDescription': String,
  }]
*/
export default {
  name: 'dropdown-burger-menu',
  props: [
    //'id',
    'actionList',
    'buttonClass',
  ],
  directives: { closable: handleOutsideClick },
  computed: {
    ariaExpanded() {
      return this.isExpanded ? 'true': 'false';
    },
  },
  data() {
    return {
      isExpanded: false,
      id: 1,
    }
  },
  methods: {
    expandDropdown() {
      this.isExpanded = true;
    },
    toggleDropdown() {
      this.isExpanded = !this.isExpanded;
    },
    closeDropdown() {
      this.isExpanded = false;
    },
    hasAdditionalDescription(actionItem) {
      return !!actionItem.additionalDescription;
    },
    hasIcon(actionItem) {
      return !!actionItem.icon;
    },
    selectAction(action) {
      this.$emit(action, this.id);
      this.isExpanded = false;
    }
  },
}
</script>

<style lang="scss" scoped>

.partner-resource-dropdown {
    color: #26354a;
    background-color: transparent;
    border: none;
    font-size: 1.375rem;

  &:hover {
    color: #516075;
    cursor: pointer;
  }
}
</style>
