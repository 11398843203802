<template>
  <section class="book-mentor-section">
    <div class="book-mentor-content-container">
      <div class="eu-simple-title-medium iu-create-meeting-title-container title">Create Appointment</div>

      <div class="mc-create-meeting-wrapper">
        <div class="mc-create-meeting-text">You are booking an appointment with:</div>
        <span :aria-label="`Selected mentor`">
          <profile-card
            :listView="true"
            :user="mentor"
            :department_name="mentor.department"
            :focus_areas="stringifyArray(mentor.focus_areas, 'name')"
            @openModal="openProfileModal"
            className="mc-list-card active"
          />
        </span>
      </div>

      <div class="mc-create-meeting-wrapper">
        <div class="mc-create-meeting-text">Select your focus area:</div>
        <select class="mc-create-meeting-input-select" v-model="meetingRequest.focus_area">
          <option v-for="option in mentor.focus_areas" v-bind:value="option.name" v-bind:key="option.name">
            {{ option.name }}
          </option>
        </select>
      </div>

      <div v-if="meetingRequest.focus_area" class="mc-create-meeting-wrapper">
        <div class="mc-create-meeting-text">Choose your preferred meeting type:</div>
        <span
          v-if="hasVideoChat()"
          tabindex="0"
          aria-label="Meeting type chat"
          role="button"
          @click="setMeetingReqProp('meeting_type', 'chat')"
          @keyup.enter="setMeetingReqProp('meeting_type', 'chat')"
          class="mc-create-meeting-form-button type-btn"
          :class="`${meetingRequest.meeting_type === 'chat' ? 'active' : ''} `"
          >Chat</span
        >
        <span
          tabindex="0"
          aria-label="Meeting type video"
          role="button"
          @click="setMeetingReqProp('meeting_type', 'video')"
          @keyup.enter="setMeetingReqProp('meeting_type', 'video')"
          class="mc-create-meeting-form-button type-btn"
          :class="`${meetingRequest.meeting_type === 'video' ? 'active' : ''} `"
          >Video</span
        >
        <span
          tabindex="0"
          aria-label="Meeting type inperson"
          role="button"
          @click="setMeetingReqProp('meeting_type', 'inperson')"
          @keyup.enter="setMeetingReqProp('meeting_type', 'inperson')"
          class="mc-create-meeting-form-button type-btn"
          :class="`${meetingRequest.meeting_type === 'inperson' ? 'active' : ''} `"
          >In-Person</span
        >
      </div>

      <div
        v-if="
          meetingRequest.meeting_type && (meetingRequest.department || isPartnerTenant()) && meetingRequest.focus_area
        "
        class="mc-create-meeting-wrapper"
      >
        <div class="mc-create-meeting-text">Fill out the following to create your appointment:</div>

        <div
          :style="availableDates.length == 0 ? 'pointer-events: none;opacity:0.5' : ''"
          class="field-wrapper hasValue eu-file-upload-modal-field eu-meetings-input"
        >
          <select class="eu-upload-select" @change="onDateSelected" v-model="meetingRequest.date">
            <option v-for="option in availableDates" v-bind:value="option" v-bind:key="option">
              {{ option }}
            </option>
          </select>
          <i class="fal fa-angle-down eu-select-form-arrow"></i>
          <div class="field-placeholder" style="bottom: 38px">
            <span>Date<span class="required-star">*</span></span>
          </div>
        </div>

        <div
          :style="meetingRequest.date == null ? 'pointer-events: none;opacity:0.5' : ''"
          class="field-wrapper hasValue eu-file-upload-modal-field eu-meetings-input"
        >
          <select class="eu-upload-select" v-model="meetingRequest.time">
            <option v-for="option in availableTimes" v-bind:value="option.id" v-bind:key="option.time">
              {{ option.time }}
            </option>
          </select>
          <i class="fal fa-angle-down eu-select-form-arrow"></i>
          <div class="field-placeholder" style="bottom: 38px">
            <span>Time<span class="required-star">*</span></span>
          </div>
        </div>

        <div class="field-wrapper hasValue eu-file-upload-modal-field eu-meetings-input" style="margin-top: 45px">
          <textarea
            aria-labelledby="message"
            v-model="meetingRequest.description"
            type="text"
            placeholder="Leave some details about what you’d like to discuss."
            style="margin-bottom: 50px"
            rows="3"
            class="eu-textarea-modern"
          />
          <div id="message" class="field-placeholder"><span>Message</span></div>
        </div>

        <div class="mc-create-meeting-final-buttons">
          <span v-if="formError" tabindex="0" id="book_appointment_error" class="mc-create-meeting-error"
            >Please fill in all required fields.</span
          >
          <button class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary" @click="$router.push({name:'my-meetings'})">Cancel</button>
          <button
            @click="submitMeetingRequest(true)"
            :class="availableTimes.length > 0 ? '' : 'disabled'"
            class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
          >
            Book Appointment
          </button>
        </div>
      </div>

      <profile-card-modal :isModalActive="isProfileModalActive" :user="previewUser" @onClose="closeProfileModal" />
    </div>
  </section>
</template>

<script lang="ts">
enum DepartmentOrFocus {
  DEPARTMENT = 'department',
  FOCUS_AREA = 'focus_area',
}
import ProfileCard from '../../accounts/components/ProfileCard.vue';
import ProfileCardModal from '../../accounts/components/ProfileCardModal.vue';
import MeetingsService from '../services/MeetingsService';
import { focusElement } from '../../utils/CustomUtils';
import { stringifyArray } from '../utils/common';
import { parseLongDate } from '../utils/DateParser';
import { isPartnerTenant } from '../../utils/CustomUtils';
import moment from 'moment';
import Cookies from 'js-cookie';
export default {
  components: { ProfileCard, ProfileCardModal },
  name: 'book-mentor',
  data() {
    return {
      DepartmentOrFocus,
      currentStep: 'choose-mentor',
      departmentOrFocus: null,
      isProfileModalActive: false,
      availableDates: [],
      availableTimes: [],
      meetingRequest: {},
      service: new MeetingsService(),
      availableHours: [],
      formError: false,
      previewUser: {},
      mentor: {},
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
    };
  },
  async created() {
    await this.loadPredata();
    await this.getAvailableHours();
    this.previewUser = this.userToUserPreviewMapper(this.mentor);
  },
  methods: {
    stringifyArray,
    async loadPredata() {
      const res = await this.service.getBookMentorPredata(this.$route.params.profile_url);
      this.mentor = JSON.parse(res.mentor);
      this.meetingRequest = {
        meeting_type: null,
        time: null,
        date: null,
        focus_area: null,
        department: this.mentor.department,
        description: null,
      };
    },
    userToUserPreviewMapper(user) {
      let hours = this.availableHours;
      if (hours) {
        hours = hours.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
        hours = hours.slice(0, 5);
      }

      return {
        first_name: user.first_name,
        last_name: user.last_name,
        profile_pic: user.profile_picture,
        id: user.id,
        department: user.department_name,
        focus_areas: user.focus_areas,
        is_mentor: true,
        role: {
          is_mentor: true,
        },
        profile_url: user.url,
        upcoming_hours: hours,
      };
    },

    async getAvailableHours() {
      const res = await this.service.getMentorHours({
        mentor_id: this.mentor.id,
        timezone_offset: -new Date().getTimezoneOffset(),
      });
      if (res.status === 'success') {
        this.availableHours = res.available_hours;
        this.availableDates = [...new Set(this.availableHours.map((x) => parseLongDate(x.date)))];
      }
    },
    isPartnerTenant,
    hasVideoChat() {
      const isMeetingsEnabled: HTMLElement = document.querySelector('.js-has-video-chat-meetings');
      return JSON.parse(isMeetingsEnabled.dataset.hasVideoChatMeetings.toLowerCase());
    },
    onDateSelected() {
      this.setMeetingReqProp('time', null);
      this.availableTimes = this.availableHours
        .filter((x) => parseLongDate(x.date) == this.meetingRequest.date)
        .map((x) => {
          return { id: x.id, time: `${moment(x.startTime).format('HH:mm')} - ${moment(x.endTime).format('HH:mm')}` };
        });
    },
    setDepartmentOrFocus(type: DepartmentOrFocus) {
      this.departmentOrFocus = this.departmentOrFocus === type ? null : type;
      this.setMeetingReqProp('department', '');
      this.setMeetingReqProp('focus_area', '');
      this.setMeetingReqProp('meeting_type', null);
    },
    async submitMeetingRequest() {
      if (!this.meetingRequest.time || !this.meetingRequest.date) {
        this.formError = true;
      } else {
        this.formError = false;
        const body = {
          ...this.meetingRequest,
          timezone_offset: -new Date().getTimezoneOffset(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        const res = await this.service.postMentorRequest(body, this.headers);
        if (res.status === 'success') {
          this.$router.push('/meetings/?tab=appointments');
        }
      }
    },
    setMeetingReqProp(el, value) {
      if (value !== null && value !== '' && this.meetingRequest[el] === value) {
        this.meetingRequest[el] = null;
        if (el === 'meeting_type') {
          // set all inputs below me to null
        }
      } else this.meetingRequest[el] = value;
    },
    openProfileModal() {
      this.isProfileModalActive = true;
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      focusElement('profile_modal_title');
      sidebar.style.display = 'none';
    },
    closeProfileModal(id) {
      this.isProfileModalActive = false;
      focusElement(`profile_user_card_${id}`);
      const sidebar: HTMLElement = document.querySelector('#sidebar');
      sidebar.style.display = 'block';
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.book-mentor-section {
  padding: 24px 32px;
  display: flex;
  justify-content: center;
}
.title {
  margin: 0;
}
.book-mentor-content-container {
  width: 100%;
  max-width: 1400px;
}

@media screen and (max-width: 576px) {
  .book-mentor-section {
    padding: 24px 16px 16px;
  }
}
</style>
