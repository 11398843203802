<template>
  <div class="feed-wrapper">
    <div class="eu-simple-navbar" style="display: block">
      <router-link :to="dashboardPath" class="profile-tab navbar-activity-feed" aria-label="Go to dashboard page">
        Dashboard
      </router-link>
      <a class="eu-simple-navbar-item eu-simple-navbar-item-active" href="javascript:;">
        {{ `${isPartnerTenant ? "News &amp; Updates" : "Campus360"}` }}
      </a>
    </div>
    <div class="activity-feed-container-wrapper">
      <div class="eu-feed-activity">
        <div v-if="!isPartnerTenant">
          <div class="eu-feed-activity-header">
            <span
              class="iu-feed-department-follow-back-button"
              tabindex="0"
              @click="backToFeed()"
              @keyup.enter="backToFeed()"
              role="button"
              aria-label="Go back to feed"
              ><i class="fa-duotone fa-arrow-left"></i
            ></span>
            <span class="eu-feed-activity-header-title"> Departments You Follow </span>
          </div>
          <div v-for="d of following_departments" :key="d.name">
            <div class="iu-feed-department-follow-item-container">
              <img class="iu-feed-department-follow-item-image" :src="d.logo_url" alt="Department logo" />
              <router-link
                :aria-label="`Go to ${d.name} page`"
                class="iu-feed-department-follow-item-name"
                :to="`/departments/${encodeURIComponent(d.name)}`"
                >{{ d.name }}</router-link
              >
              <button
                @click="followFeed(false, d.name)"
                @keyup.enter="followFeed(false, d.name)"
                v-if="d.is_following"
                class="iu-feed-department-follow-item-button-active"
                :aria-label="`unfollow ${d.name}`"
              >
                <i class="fa-solid fa-check-circle" style="margin-right: 5px"></i>
                Following
              </button>
            </div>
          </div>
        </div>
        <div v-if="isPartnerTenant">
          <div class="eu-feed-activity-header">
            <span
              class="iu-feed-department-follow-back-button"
              tabindex="0"
              @click="backToFeed()"
              @keyup.enter="backToFeed()"
              role="button"
              aria-label="Go back to feed"
              ><i class="fa-duotone fa-arrow-left"></i
            ></span>
            <span class="eu-feed-activity-header-title"> Partners You Follow </span>
          </div>
          <div v-for="d of following_partners" :key="d.name">
            <div class="iu-feed-department-follow-item-container">
              <img class="iu-feed-department-follow-item-image" :src="d.logo_url" alt="Partner logo" />
              <router-link
                :aria-label="`Go to ${d.name} page`"
                class="iu-feed-department-follow-item-name"
                :to="`/partneraccounts/${encodeURIComponent(d.name)}`"
                >{{ d.name }}</router-link
              >
              <button
                @click="followPartnerFeed(false, d.name)"
                @keyup.enter="followPartnerFeed(false, d.name)"
                v-if="d.is_following"
                class="iu-feed-department-follow-item-button-active"
                :aria-label="`unfollow ${d.name}`"
              >
                <i class="fa-solid fa-check-circle" style="margin-right: 5px"></i>
                Following
              </button>
            </div>
          </div>
        </div>

        <div class="eu-feed-activity-header">
          <span
            class="iu-feed-department-follow-back-button"
            tabindex="0"
            @click="backToFeed()"
            @keyup.enter="backToFeed()"
            role="button"
            aria-label="Go back to feed"
            ><i class="fa-duotone fa-arrow-left"></i
          ></span>
          <span class="eu-feed-activity-header-title"> Groups You Follow </span>
        </div>
        <div v-for="d of following_groups" :key="d.name">
          <div class="iu-feed-department-follow-item-container">
            <img class="iu-feed-department-follow-item-image" :src="d.logo_url" />
            <router-link
              :aria-label="`Go to ${d.name} page`"
              class="iu-feed-department-follow-item-name"
              :to="`/groups/${encodeURIComponent(d.name)}`"
              >{{ d.name }}</router-link
            >
            <button
              @click="followGroupFeed(false, d.name)"
              @keyup.enter="followGroupFeed(false, d.name)"
              v-if="d.is_following"
              class="iu-feed-department-follow-item-button-active"
              :aria-label="`unfollow ${d.name}`"
            >
              <i class="fa-solid fa-check-circle" style="margin-right: 5px"></i>
              Following
            </button>
          </div>
        </div>
      </div>
      <section class="activity-feed-details">
        <feed-details
          :loggedUser="loggedUser"
          :dashboardPath="dashboardPath"
          :isShowing="isShowing"
          :number_of_following_departments="number_of_following_departments"
          :number_of_following_groups="number_of_following_groups"
          :number_of_following_partners="number_of_following_partners"
          @follow-feed="followFeed"
          @follow-group-feed="followGroupFeed"
          @follow-partner-feed="followPartnerFeed"
          @is-shown="backToFeed"
        />
      </section>
    </div>
  </div>
</template>
<script lang="ts">
import FeedDetails from "./FeedDetails.vue";
import Cookies from "js-cookie";
export default {
  components: { FeedDetails },
  name: "feed-activity-feed",
  props: [
    "loggedUser",
    "dashboardPath",
    "isShowing",
    "number_of_following_departments",
    "number_of_following_groups",
    "number_of_following_partners",
    "following_departments",
    "following_groups",
    "following_partners",
    "isPartnerTenant",
  ],
  data() {
    return {
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  async created() {},
  methods: {
    switchView() {
      const feedEl = document.querySelector(".eu-feed-posts");
      const detailsEl = document.querySelector(".eu-feed-settings");
      detailsEl.classList.add("unHiddenEl");
      feedEl.classList.remove("unHiddenEl");
    },
    backToFeed() {
      this.$emit("to-feed");
    },
    async followFeed(isFollowing: boolean, department: string) {
      this.$emit("follow-feed", isFollowing, department);
    },
    async followGroupFeed(isFollowing: boolean, group: string) {
      this.$emit("follow-group-feed", isFollowing, group);
    },
    async followPartnerFeed(isFollowing: boolean, partner: string) {
      this.$emit("follow-partner-feed", isFollowing, partner);
    },
  },
  computed: {},
};
</script>
