<template>
  <div class="pwi-page">
    <div class="welcome-to-includifi-section">
      <div class="title-container">
        <p class="description-bold">
          Navigating college as a first-generation student is a journey marked by resilience and determination.
          First-gen students blaze trails, often without the roadmap that comes from familial college experiences.
        </p>
        <button class="home-new-scroll-text-button" @click="scrollToElement">
          <i class="fa-solid fa-arrow-down"></i>
        </button>
      </div>
    </div>
    <div class="why-partner-section">
      <div class="content-container">
        <h3 class="sub-title">
          Yet, despite their immense potential and resilience, first-gen students face unique challenges that impact
          their ability to succeed. The numbers speak to this challenge.
        </h3>
        <div class="content-wrapper">
          <div class="item-container">
            <div class="icon-container">
              <img
                src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/why_first_gen/why-icon1.svg"
                alt="Target reach"
              />
            </div>
            <div class="text-container">
              <p class="description">33% of first-gen students leave school within three years of enrolling.</p>
            </div>
          </div>
          <div class="item-container">
            <div class="icon-container">
              <img
                src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/why_first_gen/why-icon2.svg"
                alt="Impactful Engagement"
              />
            </div>
            <div class="text-container">
              <p class="description">90% of first-gen/low-Income students have not graduated within 6 years.</p>
            </div>
          </div>
          <div class="item-container">
            <div class="icon-container">
              <img
                src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/why_first_gen/why-icon3.svg"
                alt="Collaborative Growth"
              />
            </div>
            <div class="text-container">
              <p id="why-first-section" class="description">
                Medium income for first-gen graduates is 37% less than their peers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="partner-with-us-section">
      <div class="content-container">
        <div class="text-container">
          <p class="description">
            But the first-gen story isn’t one of deficit. First-gen students step onto campus with eagerness and a
            wealth of potential. They’re often just less familiar with navigating campus services and opportunities like
            their continuing generation peers.
          </p>
        </div>
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/why_first_gen/why-includifi-section-1.jpg"
          alt="WhyFirstGen1"
          class="image"
        />
      </div>
    </div>
    <div class="partner-with-us-section">
      <div class="content-container">
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/why_first_gen/why-includifi-section-2.jpg"
          alt="WhyFirstGen2"
          class="image desktop-only"
        />
        <div class="text-container">
          <p class="description">
            Includifi specializes in improving first-gen student success. Our innovative approach helps colleges &
            universities level the playing field by equipping first-gen students with a dedicated hub of curated advice
            and resources to support their sense of belonging, enrollment, retention, and success.
          </p>
        </div>
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/why_first_gen/why-includifi-section-2.jpg"
          alt="WhyFirstGen2"
          class="image mobile-only"
        />
      </div>
    </div>
    <div class="become-a-partner-section">
      <div class="content-container">
        <div class="title-container">
          <h3 class="title">Learn More About Includifi’s Solution for Higher Education</h3>
        </div>
        <div class="cards-wrapper">
          <div class="card-wrapper">
            <img
              src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/why_first_gen/home-first-gen.jpg"
              alt="WhyFirstGen1"
              class="image pointer"
              @click="goTo('/solutions/recruitment-enrollment')"
            />
            <div class="title pointer" @click="goTo('/solutions/recruitment-enrollment')">Recruitment & Enrollment</div>
            <div class="description">
              Engage first-gen students and their families with curated resources and communities to achieve enrollment
              goals.
            </div>
          </div>
          <div class="card-wrapper">
            <img
              src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/why_first_gen/home-new-student.jpg"
              alt="WhyFirstGen2"
              class="image pointer"
              @click="goTo('/solutions/first-year-experiences')"
            />
            <div class="title pointer" @click="goTo('/solutions/first-year-experiences')">First Year Experiences</div>
            <div class="description">
              Ease first-gen students transitions to colleges with targeted programming and personalized access to
              campus services and experiences.
            </div>
          </div>
          <div class="card-wrapper">
            <img
              src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/why_first_gen/home-diversity-inclusion.jpg"
              alt="WhyFirstGen3"
              class="image pointer"
              @click="goTo('/solutions/first-gen-success')"
            />
            <div class="title pointer" @click="goTo('/solutions/first-gen-success')">Retention & Success</div>
            <div class="description">
              Equip first-gen students with a dedicated hub for access to tailored support and opportunities that drive
              retention and post-degree success.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "first-gen-success",
  methods: {
    scrollToElement() {
      const section = document.querySelector("#why-first-section");
      section.scrollIntoView({ behavior: "smooth", block: "end" });
    },
    goTo(url) {
      location.replace(url)
    }
  },
};
</script>
<style lang="scss" scoped>
.pwi-page {
  .pointer {
    cursor: pointer;
    transition: 0.4s;
    &:hover {
      opacity: 0.8;
    }
  }
  .cards-wrapper {
    display: flex;
    gap: 24px;
  }
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: block;
  }
  .card-wrapper {
    height: auto;
    border-radius: 1em 1em 1em 1em;
    background: #ffffff;
    -webkit-box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.04);
    width: 100%;
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .image {
      height: 154px;
      border-radius: 1em 1em 0 0;
      object-fit: cover;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      color: #444;
      padding: 16px;
      padding-bottom: 12px;
    }
    .description {
      color: #555555;
      font-size: 18px;
      line-height: 1.45;
      padding: 16px;
      padding-top: 0;
    }
  }
  .welcome-to-includifi-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    background-color: #fff;
    padding: 48px 32px 64px;

    .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      max-width: 880px;
      text-align: center;

      .title {
        font-size: 56px;
        line-height: 1.15;
        color: #333;
        padding: 0;
      }

      .description-bold {
        color: #444;
        line-height: 1.2;
        font-size: 32px;
        font-weight: 600;
        padding-bottom: 24px;
      }
    }

    .content-container {
      display: flex;
      gap: 48px;
      max-width: 1080px;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .image {
        width: 384px;
        height: 270px;
        object-fit: cover;
        border-radius: 8px;
        flex-shrink: 0;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 648px;

        .description {
          font-size: 24px;
          line-height: 1.45;
          color: #555;
        }
      }
    }
  }

  .why-partner-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #eaeeff;
    background-size: cover;
    background-position: center;
    padding: 48px 32px;

    .white-tint {
      background-color: rgba(255, 255, 255, 0.85);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .content-container {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 48px;
      align-items: center;
      max-width: 1080px;

      .sub-title {
        font-size: 28px;
        font-weight: 500;
        line-height: 1.5;
        color: #444444;
        max-width: 880px;
        text-align: center;
      }

      .content-wrapper {
        display: flex;
        width: 100%;
        gap: 64px;
        align-items: flex-start;
        border: none;
        padding: 0;
        margin: 0;

        .item-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          flex: 1;

          .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 72px;
            width: 72px;
            border-radius: 100px;

            i {
              font-size: 32px;
              color: #836cff;
            }
          }

          .text-container {
            text-align: center;
            gap: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
              font-size: 24px;
              line-height: 1.2;
              font-weight: 600;
              color: #444;
            }

            .description {
              font-size: 24px;
              line-height: 1.45;
              font-weight: 500;
              color: #444;
            }
          }
        }
      }
    }
  }

  .partner-with-us-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 32px;
    background-color: #fff;

    .content-container {
      display: flex;
      gap: 64px;
      align-items: center;
      max-width: 1080px;
      width: 100%;

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .description {
          line-height: 1.5;
          color: #555;
          font-size: 28px;
          font-weight: 500;
        }
      }

      .image {
        width: 460px;
        height: 320px;
        object-fit: cover;
        border-radius: 8px;
        flex-shrink: 0;
      }
    }
  }

  .become-a-partner-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 32px 64px;
    background-color: #f1f1f9;

    .content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      max-width: 980px;

      .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        text-align: center;

        .title {
          font-size: 40px;
          line-height: 1.15;
          color: #333;
          padding: 0;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .pwi-page {
    .cards-wrapper {
      flex-direction: column;
    }
    .why-partner-section {
      .content-container {
        .content-wrapper {
          flex-direction: column;
          align-items: center;

          .item-container {
            width: 100%;
            max-width: 450px;
          }
        }
      }
    }
    .partner-with-us-section {
      .content-container {
        flex-direction: column;
        align-items: center;

        .text-container {
          align-items: center;

          .description {
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .pwi-page {
    .mobile-only {
      display: block;
    }
    .desktop-only {
      display: none;
    }
    .welcome-to-includifi-section {
      padding: 32px 20px;
      gap: 40px;

      .title-container {
        align-items: center;

        .title {
          font-size: 48px;
        }

        .description-bold {
          font-size: 26px;
        }
      }

      .content-container {
        gap: 24px;
        flex-direction: column;
        padding: 0;

        .image {
          width: 100%;
          height: unset;
        }

        .text-container {
          gap: 12px;
          text-align: center;
          padding: 0;

          .description {
            font-size: 20px;
          }
        }
      }
    }

    .why-partner-section {
      padding: 32px 20px;
      .content-container {
        gap: 24px;
        padding: 0;
        .sub-title {
          font-size: 22px;
          text-align: center;
        }
        .content-wrapper {
          gap: 32px;

          .item-container {
            .text-container {
              .sub-title,
              .title {
                font-size: 22px;
                line-height: 1.15;
              }
              .description {
                font-size: 18px;
                line-height: 1.4;
              }
            }
          }
        }
      }
    }

    .partner-with-us-section {
      padding: 32px 20px;

      .content-container {
        padding: 0;
        gap: 24px;

        .text-container {
          .title {
            font-size: 32px;
            line-height: 1.25;
          }
          .description {
            font-size: 20px;
            line-height: 1.36;
          }
        }

        .image {
          width: 100%;
          height: unset;
        }
      }
    }

    .become-a-partner-section {
      padding: 32px 20px;
      .content-container {
        padding: 0;
        gap: 24px;

        .title-container {
          .title {
            font-size: 32px;
            line-height: 1.25;
          }
        }
      }
    }
  }
}
</style>
