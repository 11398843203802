<template>
  <section v-if="isLoading">
    <loading-spinner wrapperClass="wrapper" />
  </section>
  <section v-else>
    <section class="opportunity-page-header-container">
      <section class="image-container">
        <img :src="event.thumbnail" class="image" alt="Partner cover image" />
        <div class="tint"></div>
        <div class="back-button-container">
          <button @click="redirectTo('/opportunities')" class="back-button">
            <i class="fa-solid fa-chevron-left back-arrow"></i>
            <span class="text">Browse all opportunities</span>
          </button>
        </div>
        <div class="text-container">
          <span class="title">{{ event.title }}</span>
        </div>
      </section>
      <div class="action-container">
        <div class="event-header-btns">
          <div class="left">
            <a
              v-if="event.apply_url"
              :href="event.apply_url"
              @click="increaseClickThrough()"
              @keyup.enter="increaseClickThrough()"
              target="_blank"
              class="dabi-btn-primary"
              aria-label="Open Apply URL"
              role="menu"
              aria-haspopup="true"
            >
              Apply Now
            </a>
            <div>
              <button
                ref="calendarButton"
                class="dabi-btn-primary"
                @click="openBurger('calendar-burger')"
                aria-label="Open menu for external calendars"
                role="menu"
                aria-haspopup="true"
              >
                Add to Calendar
                <i class="fal fa-angle-down calendar-carot"></i>
              </button>
              <div
                id="calendar-burger"
                class="eu-resource-burger"
                style="width: 250px; margin-top: 10px; right: revert"
                ref="calendarBurger"
                v-closable="{ exclude: ['calendarButton', 'calendarBurger'], handler: 'closeCalendarBurger' }"
              >
                <span
                  tabindex="0"
                  @click="openExternalCalendar('google')"
                  @keyup.enter="openExternalCalendar('google')"
                  class="eu-resource-burger-el"
                  style="margin: 10px 0 10px 0px"
                  aria-label="Create event in Google calendar"
                  role="link"
                >
                  <img
                    :src="`${assetPath}assets/icons/768px-Google_Calendar_icon_%282020%29.png`"
                    style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                  />
                  <span>Google Calendar</span>
                </span>
                <span
                  tabindex="0"
                  @click="openExternalCalendar('ics')"
                  @keyup.enter="openExternalCalendar('ics')"
                  class="eu-resource-burger-el"
                  style="margin: 10px 0 10px 0px"
                  aria-label="Create event in Apple calendar"
                  role="button"
                >
                  <img
                    :src="`${assetPath}assets/icons/apple-512.webp`"
                    style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                  />
                  <span>Apple Calendar</span>
                </span>
                <span
                  tabindex="0"
                  @click="openExternalCalendar('outlook')"
                  @keyup.enter="openExternalCalendar('outlook')"
                  class="eu-resource-burger-el"
                  style="margin: 10px 0 10px 0px"
                  aria-label="Create event in Outlook calendar"
                  role="link"
                >
                  <img
                    :src="`${assetPath}assets/icons/Outlook.com_icon.png`"
                    style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                  />
                  <span>Outlook Calendar</span>
                </span>
                <span
                  tabindex="0"
                  @click="openExternalCalendar('yahoo')"
                  @keyup.enter="openExternalCalendar('yahoo')"
                  class="eu-resource-burger-el"
                  style="margin: 10px 0 10px 0px"
                  aria-label="Create event in Yahoo calendar"
                  role="link"
                >
                  <img
                    :src="`${assetPath}assets/icons/yahoo.png`"
                    style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                  />
                  <span>Yahoo Calendar</span>
                </span>
              </div>
            </div>
            <button
              v-if="event.is_bookmarked"
              class="dabi-btn-secondary active"
              @click="handleRemoveBookmark(event)"
              role="button"
              aria-label="Remove this opportunity from your bookmarks"
              tabindex="0"
            >
              <i class="fa-sharp fa-solid fa-bookmark" style="margin-right: 3px; margin-top: 3px; color: #00557f"></i>
              <span>Saved</span>
            </button>
            <button
              v-if="!event.is_bookmarked"
              class="dabi-btn-secondary"
              @click="handleAddBookmark(event)"
              role="button"
              aria-label="Add this opportunity to your bookmarks"
            >
              <i class="fa-sharp fa-regular fa-bookmark" style="margin-right: 3px; margin-top: 3px"></i>
              <span>Save</span>
            </button>
            <button
              class="dabi-btn-secondary active"
              @click="toggleLead()"
              role="button"
              aria-label="Update your RSVP status for this opportunity"
              tabindex="0"
            >
              <i
                v-if="event.is_interested"
                class="fa-sharp fa-solid fa-bookmark"
                style="margin-right: 3px; margin-top: 3px; color: #00557f"
              ></i>
              <i v-else class="fa-sharp fa-regular fa-bookmark" style="margin-right: 3px; margin-top: 3px"></i>
              <span>{{ event.type_of_program_management === "program" ? "I'm Interested" : "Attending" }}</span>
            </button>
            <!-- <button
              v-if="!isAttending"
              class="dabi-btn-secondary"
              @click="changeAttendance(true)"
              role="button"
              aria-label="Update your RSVP status for this opportunity (interested in/going)"
            >
              <i class="fa-sharp fa-regular fa-bookmark" style="margin-right: 3px; margin-top: 3px"></i>
              <span>{{ event.type_of_program_management === "program" ? "Not Interested" : "Not Attending" }}</span>
            </button> -->
          </div>
          <div class="right">
            <button
              v-if="event.can_manage"
              class="dabi-btn-primary"
              @click="redirectToEdit()"
              role="button"
              aria-label="Update your RSVP status for this opportunity (interested in/going)"
            >
              <span>Edit {{ capitalizeFirstLetterOnly(event.type_of_program_management) }}</span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <div class="event-content-body-wrapper">
      <div class="event-content-left-bar">
        <div v-if="event.partner_info" class="partner-wrapper">
          <div class="left">
            <img alt="Partner image" :src="event.partner_info.logo_url" class="avatar" />
            <div class="partner-text">
              <span class="partner-title"
                >By <b>{{ event.partner_info.name }}</b></span
              >
              <span class="partner-subtitle">{{ event.partner_info.followers }} followers</span>
            </div>
          </div>
          <div class="right">
            <button v-if="isFollowing" class="dabi-btn-primary" @click="goToPartnerProfile(event.partner_info.name)">
              Visit
            </button>
            <button v-else class="dabi-btn-primary" @click="followPartner(event.partner_info.name)">Follow</button>
          </div>
        </div>
        <div>
          <div v-if="event.type_of_program_management === 'program' && event.files.length !== 0">
            <div v-for="item in event.files" v-bind:key="item.id">
              <div v-if="item.resource_type === 'VID'" class="event-content-card">
                <h2 class="video-about-this">{{ item.name }}</h2>
                <div class="video-wrapper">
                  <iframe
                    :src="getYoutubeOrVimeoEmbedLink(item.resource_url)"
                    class="dept-video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div class="event-content-card">
            <h2 class="about-title">About</h2>
            <p class="about-text" style="margin-bottom: 20px" v-html="formatTextToHtml(event.description)"></p>
            <br />
            <a v-if="event.more_info_url" class="event-content-header-desc" :href="event.more_info_url" target="_blank">
              More Information<i
                style="margin-left: 6px; font-size: 14px"
                class="fa-solid fa-arrow-up-right-from-square"
              ></i
            ></a>
            <p class="event-content-link-wrapper" style="padding: 16px 0; margin-top: 10px">
              <a
                :href="event.external_link"
                target="_blank"
                class="dabi-btn-primary"
                :class="!event.external_link ? 'disabled' : ''"
                aria-label="Visit the website for this opportunity"
              >
                Visit Website
                <i class="fa-regular fa-arrow-up-right-from-square nav-icon"></i>
              </a>
            </p>
          </div>

          <div v-if="event.files && event.files.some((e) => e.resource_type != 'VID')" class="event-content-card">
            <h2 class="event-about-this">Files</h2>
            <div v-for="item in event.files" v-bind:key="item.id">
              <div v-if="item.resource_type !== 'VID'">
                <div class="document-wrapper item-bg">
                  <div class="icon-section">
                    <a
                      v-if="item.resource_type !== 'DOC'"
                      :href="item.resource_url"
                      target="_blank"
                      aria-label="Show resource"
                    >
                      <!-- <i v-if="item.resource_type === 'VID'"  class="far fa-file-video" style="color:#9f2a8c" aria-hidden="true"></i> -->
                      <i
                        v-if="item.resource_type === 'URL'"
                        class="far fa-external-link"
                        style="color: #438ca4; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="item.resource_type === 'IMG'"
                        class="far fa-file-image"
                        style="color: #f4c64d; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="item.resource_type === 'URL_INTERNAL'"
                        class="far fa-external-link"
                        style="color: #438ca4; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                      <i
                        v-if="item.resource_type === 'URL_EXTERNAL'"
                        class="far fa-external-link"
                        style="color: #438ca4; font-size: 20px"
                        aria-hidden="true"
                      ></i>
                    </a>
                    <i
                      v-if="item.resource_type === 'DOC'"
                      class="far fa-file-alt"
                      style="color: #8673fd"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="text-section">
                    <a :href="item.resource_url" target="_blank" class="text-section-title"> {{ item.name }} </a>
                    <!-- <p class="text-section-description"> Posted by Admissions </p> -->
                  </div>
                  <div
                    class="option-section"
                    :aria-label="`Download ${item.name}`"
                    role="button"
                    @click="downloadItem(item.resource_url, item.name)"
                    @keyup.enter="downloadItem(item.resource_url, item.name)"
                    v-if="
                      item.resource_type != 'VID' &&
                      item.resource_type != 'URL' &&
                      item.resource_type != 'URL_INTERNAL' &&
                      item.resource_type != 'URL_EXTERNAL'
                    "
                  >
                    <i class="far fa-download"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="event-content-right-bar">
        <div v-if="!event.is_past_event" class="event-content-right-bar-wrapper">
          <div v-if="event.start_date" class="item-wrapper">
            <i class="fas fa-calendar-star item-icon"></i>
            <p class="item-text-wrapper">
              <span class="content-subtitle">Date{{ event.end_date != "" && event.end_date != null ? "s" : "" }}</span>
              <span class="content-text">
                {{ event.start_date | parseDate }}
                <span v-if="event.end_date != '' && event.end_date != null"> - {{ event.end_date | parseDate }}</span>
              </span>
            </p>
          </div>
          <div>
            <div class="item-wrapper" v-if="event.type_of_program_management === 'program'">
              <i class="fas fa-hourglass-half item-icon"></i>
              <p class="item-text-wrapper">
                <span class="content-subtitle">Application Deadline</span>
                <span class="content-text" style="color: #e65722">
                  {{ event.deadline | parseDateUTC }}
                </span>
              </p>
            </div>
            <div v-if="event.location != 'Online'" class="item-wrapper">
              <i class="fas fa-map-marker-alt item-icon"></i>
              <p class="item-text-wrapper">
                <span class="content-subtitle">Location</span>
                <span class="content-text"> {{ event.location }}</span>
              </p>
            </div>
            <div v-if="event.location == 'Online' && event.online_url" class="item-wrapper">
              <i class="fas fa-map-marker-alt item-icon"></i>
              <p class="item-text-wrapper">
                <span class="content-subtitle">Virtual</span>
                <a :href="event.online_url" target="_blank">Meeting URL</a>
              </p>
            </div>
            <div class="item-wrapper" v-if="event.type_of_program_management === 'program' && event.cost > 0">
              <i class="fas fa-coins item-icon"></i>
              <p class="item-text-wrapper">
                <span class="content-subtitle">Cost</span>
                <span class="content-text"> {{ event.cost }} USD</span>
              </p>
            </div>
            <div class="item-wrapper" v-if="event.external_link">
              <i class="fas fa-globe item-icon"></i>
              <p class="item-text-wrapper">
                <span class="content-subtitle"><a :href="event.external_link" target="_blank">Visit Website</a></span>
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="event.type_of_program_management === 'program' && event.contacts.length > 0"
          class="event-content-right-bar-wrapper"
        >
          <p class="eu-feed-dept-address-title" style="padding: 0">Contacts</p>
          <div v-for="item in event.contacts" v-bind:key="item.uuid" class="department-contacts-containers">
            <img alt="Profile picture of user" :src="item.profile_picture" class="avatar" />
            <div>
              <a
                aria-label="Go to full name profile page"
                class="eu-feed-dept-social-href"
                :href="'/profiles/' + item.url"
                >{{ item.name }}</a
              >
              <p class="helpful-link-description">{{ capitalizeFirstLetterOnly(item.role) }}</p>
            </div>
          </div>
        </div>
        <div class="event-content-right-bar-wrapper">
          <div class="content-wrapper">
            <h2 class="event-content-address-title">Opportunity Type</h2>
            <div class="flex-wrapper-content">
              <p class="content-item">
                {{ opportunityType }}
              </p>
            </div>
          </div>
          <div v-if="event.focus_areas.length !== 0" class="content-wrapper">
            <h2 class="event-content-address-title">Focus Areas</h2>
            <div class="flex-wrapper-content">
              <p v-for="item in event.focus_areas" v-bind:key="item.id" class="content-item">
                {{ item.name }}
              </p>
            </div>
          </div>
          <div v-if="event.audiences && event.audiences.length !== 0" class="content-wrapper">
            <h2 class="event-content-address-title">Audience</h2>
            <div class="flex-wrapper-content">
              <p v-for="item in event.audiences" v-bind:key="item.id" class="content-item">
                {{ item.name }}
              </p>
            </div>
          </div>
          <div
            v-if="event.career_academic_interests && event.career_academic_interests.length !== 0"
            class="content-wrapper"
            style="border-bottom: 0; margin-bottom: 0"
          >
            <h2 class="event-content-address-title">Career/Academic interests</h2>
            <div class="flex-wrapper-content">
              <p v-for="item in event.career_academic_interests" v-bind:key="item.id" class="content-item">
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :isActiveProp="isModalActive" @close="closeModal()" :classProp="'iu-modal-content-container'">
      <div class="iu-modal-content">
        <h4 class="iu-modal-title-text">Express Your Interest</h4>
        <p>
          Tell us more about your interest. Any additional details you provide will assist us in making your experience
          more personalized.
        </p>
        <div class="iu-modal-form-container">
          <div class="iu-input-field-container">
            <label for="first-name" class="iu-input-field-label-container iu-input-field-label-text" style="bottom: 75px;">
              Additional Info
            </label>
            <!-- <input id="lead-note" type="text" class="iu-input-field" v-model="lead_note" /> -->
            <textarea id="lead-note" name="lead-note" cols="40" rows="3" v-model="lead_note" class="iu-input-field" style="height: 80px; overflow-x: hidden; resize: none; padding-top:10px;" />
          </div>
        </div>
        <div class="iu-modal-actions-container">
          <button
            class="iu-main-btn small solid main iu-modal-save-button"
            aria-label="Add new user"
            @click="submitLead"
          >
            Submit
          </button>
          <button class="iu-main-btn small outlined gray" aria-label="Close add user modal" @click="closeModal">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  </section>
</template>
<script lang="ts">
import * as Cookies from "js-cookie";
import makeUrls, { TCalendarEvent } from "../../meetings/utils/AddEventToCalendar";
import { focusElement } from "../../utils/CustomUtils";
import "../../meetings/utils/DateParser";
import { formatEventEnd, formatEventStart } from "../utils/DateFormat";
import EventsIndexService from "../services/EventsIndexService";
import RSVPService from "../services/RSVPService";
import handleOutsideClick from "../../directives/handleOutsideClick";
import { getYoutubeOrVimeoEmbedLink, capitalizeFirstLetterOnly, formatTextToHtml } from "../../utils/CustomUtils";
import FeedService from "../../feed/services/FeedService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import Modal from "../../components/ModalExtended.vue";
export default {
  directives: { closable: handleOutsideClick },
  components: { LoadingSpinner, Modal },
  name: "dabi-event-details",

  props: [],

  computed: {
    opportunityType() {
      if (this.event.type_of_program_management === "event") {
        return "Event";
      }

      if (this.event.type) {
        return this.capitalizeFirstLetterOnly(this.event.type);
      }

      return "Program";
    },
    isAttending() {
      return this.event.rsvp_status;
    },
    isFollowing() {
      return this.event.partner_info && this.event.partner_info.is_following;
    },
  },

  data() {
    return {
      isLoading: true,
      isModalActive: false,
      lead_note: "",
      assetPath: process.env.MEDIA_URL,
      event: null,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },

  async created() {
    this.service = new EventsIndexService();
    await this.getPreData(true);

    this.feedService = new FeedService();
    this.rsvpService = new RSVPService();
  },

  methods: {
    capitalizeFirstLetterOnly,
    getYoutubeOrVimeoEmbedLink,
    formatTextToHtml,
    async getPreData(shouldIncreaseViews = false) {
      this.isLoading = true;
      const eventType = this.$route.meta.type;
      const opportunityId = this.$route.path.split("/").pop();
      let res = null;
      if (eventType === "event") {
        res = await this.service.getPredataEvent(opportunityId);
      } else if (eventType === "program") {
        res = await this.service.getPredataProgram(opportunityId);
      }
      if (res) {
        this.event = res;
        if (shouldIncreaseViews) {
          this.service.increaseViews(this.event.id);
        }
      }
      this.isLoading = false;
    },
    increaseClickThrough() {
      this.service.increaseClickThroughs(this.event.id);
    },
    goToPartnerProfile(partnerName) {
      this.$router.push(`/partneraccounts/${partnerName}`);
    },
    async followPartner(partner) {
      const res = await this.feedService.followPartnerFeed({ isFollowing: true, partner }, this.headers);
      if (res?.success && this.event.partner_info) {
        this.event.partner_info.followers = res.followers;
        this.event.partner_info.is_following = true;
      }
    },
    closeModal() {
      this.isModalActive = false;
    },
    async toggleLead() {
      if (this.event.is_interested) {
        const res = await this.service.removeLead(this.event.id);
        if (res.success) {
          await this.getPreData();
        }
      } else {
        this.isModalActive = true;
      }
    },
    async submitLead() {
      this.closeModal();
      const res = await this.service.createLead(this.event.id, this.lead_note);
      if (res.success) {
        this.lead_note = '';
        await this.getPreData();
      }
    },
    openExternalCalendar(calendar: string) {
      const event = this.event;
      const eventTitle = `Event: ${event.title}`;
      let eventDetails = event.description || "";
      const startTime = formatEventStart(event);
      const endTime = formatEventEnd(event);
      let event_location = "";

      if (event.location == "Online Event") {
        eventDetails += "\\n\\nConference link: " + event.online_event_url;
      } else {
        if (event.full_address) {
          event_location = event.full_address;
        }
      }

      const calEvent: TCalendarEvent = {
        name: eventTitle,
        details: eventDetails,
        location: event_location,
        startsAt: startTime,
        endsAt: endTime,
      };

      const eventUrls = makeUrls(calEvent);

      if (calendar === "ics") {
        this.handleUrlDownload(eventUrls.ics, "includifi_meeting");
      } else {
        window.open(eventUrls[calendar]);
      }
    },

    handleUrlDownload(url, filename) {
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", filename);
      a.click();
    },

    async handleAddBookmark(event): Promise<void> {
      const payload = {
        target: event.id,
        action: "add",
      };

      await this.service.postBookmark(payload, this.headers);

      event.is_bookmarked = true;
    },

    async handleRemoveBookmark(event): Promise<void> {
      const payload = {
        target: event.id,
        action: "remove",
      };

      await this.service.postBookmark(payload, this.headers);

      event.is_bookmarked = false;
    },

    async changeAttendance(isAttending: boolean): Promise<void> {
      if (this.isAttending === isAttending) {
        return;
      }

      const payload = {
        target: this.event.id,
        isAttending: isAttending,
      };

      const res = await this.rsvpService.postAttendance(payload, this.headers);

      if (res["success"]) {
        this.isAttending = isAttending;
      }
    },

    openBurger(burgerId: string) {
      const burgerEl = document.getElementById(burgerId);
      burgerEl.classList.toggle("eu-resource-burger-active");
      if (burgerId === "rsvp-burger") {
        focusElement("set-attending-true", 100);
      }
    },

    closeCalendarBurger() {
      const burgerEl = document.getElementById("calendar-burger");
      burgerEl.classList.remove("eu-resource-burger-active");
    },
    closeRSVPBurger() {
      const burgerEl = document.getElementById("rsvp-burger");
      burgerEl.classList.remove("eu-resource-burger-active");
    },
    redirectTo(url) {
      this.$router.push(url);
    },
    redirectToEdit() {
      if (this.event.type_of_program_management === "event") {
        window.location.href = `/events/edit/${this.event.id}`;
      } else {
        window.location.href = `/programs/edit/${this.event.id}`;
      }
    },
    async downloadItem(dataurl: string, filename: string) {
      const res = await fetch(dataurl);
      const blob = await res.blob();
      this.startDownload(blob, filename);
    },

    startDownload(content, filename) {
      var a = document.createElement("a");
      a.download = filename ? filename : "resources";
      document.body.appendChild(a);
      a.href = URL.createObjectURL(content);
      a.style.display = "none";
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>
<style scoped lang="scss">
.avatar {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}
.item-bg {
  border: none;
  background: #f2f5f7;
}
.content-item {
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 4px 8px;
  color: #516075;
  background: #f2f5f7;
}
.about-text {
  flex-grow: 0;
  font-size: 1.25rem;
  line-height: 1.35;
  color: #494949;
}
.about-title {
  font-size: 26px;
  font-weight: 500;
  color: #444444;
  margin: 16px 0;
  line-height: 1.31;
}
.helpful-link-description {
  color: #838ea1;
  font-size: 12px;
  font-weight: 600;
}
.item-wrapper {
  margin-bottom: 20px;
  display: flex;
  gap: 12px;
}
.item-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.item-icon {
  color: #05c1a2;
  width: 3rem;
  height: 3rem;
  font-size: 1.25rem;
  display: inline-flex;
  justify-content: center;
  background: #f2f5f7;
  padding: 12px;
  border-radius: 50%;
}
.partner-wrapper {
  background: #f2f5f7;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  height: 6.5rem;
  padding: 1.5rem 2rem;
  border-radius: 24px;
  margin-right: 32px;
}
.partner-wrapper .left {
  display: flex;
  gap: 16px;
  align-items: center;
}

.partner-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.partner-title {
  color: #333;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
}
.partner-subtitle {
  color: #6d798c;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
}
.nav-icon {
  color: #a9decf;
  font-size: 12px;
  margin-top: 4px;
}
.event-content-card {
  width: 100%;
  display: inline-block;
  padding: 16px 32px 16px 0;
  border-radius: 16px;
  margin-right: 0;
  margin-bottom: 24px;
  background: transparent;
  box-shadow: none;
}
.event-header-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .left {
    display: flex;
    gap: 0.625rem;
  }
}
.calendar-carot {
  position: relative;
  font-size: 21px;
  left: -22px;
  top: 3px;
  left: 0;
}
.image-container {
  width: 100%;
  min-height: 300px;
  max-width: 1400px;
  overflow: hidden;
  border-radius: 0 0 24px 24px;
  position: relative;
  padding: 2.5rem 2rem;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .back-button-container {
    width: 100%;
    position: relative;

    .back-button {
      padding: 0.75rem 1.5rem;
      color: #101828;
      background-color: #d8dce5;
      border: none;
      border-radius: 100px;
      font-size: 1rem;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        background-color: #838ea1;
      }

      .back-arrow {
        color: #2d98d2;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
}
.text-container {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 170px;
  justify-content: flex-end;

  .title {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #fff;
  }
}
.opportunity-page-header-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid #c3cbd6;
  padding: 0 2rem;
}
.action-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem 0;
}
@media screen and (max-width: 740px) {
  .opportunity-page-header-container {
    padding: 0;

    .image-container {
      padding: 1.5rem 1rem;
      .back-button-container {
        .back-button {
          height: 3rem;
          width: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .text {
            display: none;
          }

          .back-arrow {
            font-size: 1rem;
            color: #101828;
          }
        }
      }

      .text-container {
        .title {
          font-size: 2rem;
        }
      }
    }
  }

  .partner-page-nav-section {
    padding: 0.75rem 1.25rem 0;
  }

  .action-container {
    padding-top: 0.75rem;
    flex-direction: column-reverse;
  }
  .event-header-btns {
    width: 100%;
    padding: 0 1rem;
    flex-direction: column;
    gap: 12px;
    .left {
      flex-direction: column;
    }
    .dabi-btn-primary {
      width: 100%;
    }
  }
}
</style>
