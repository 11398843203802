<template>
  <Modal :isActiveProp="isActive" @close="closeRepeatModal">
    <form
      class="event-repeat-dialog-container"
    >
      <div class="event-repeat-dialog-title">Repeat</div>
      <div class="event-repeat-dialog-repeat-every-section">
        <span class="event-repeat-dialog-repeat-every-text desktop">
          Repeat every
        </span>
        <span class="event-repeat-dialog-repeat-every-text mobile">
          Every
        </span>
        <div class="event-repeat-dialog-repeat-every-selection-container">
          <input-base
            type="number"
            id="repeat-number"
            wrapperClass="event-repeat-dialog-repeat-every-number-input-wrapper"
            aria-label="Select number of occurrences in a selected base range"
            v-model="repeatPeriodNumber"
            inputStyle="padding: 0; text-align: center;"
            :isError="inputHasError('repeat-number')"
          />
          <select-base
            id="repeat-select"
            v-model="repeatPeriod"
            :options="repeatPeriodOptions"
            aria-label="Select repeating period"
            wrapperClass="event-repeat-dialog-repeat-every-select-wrapper"
            placeholder=""
          />
        </div>
        <span
          v-if="inputHasError('repeat-number')"
          class="message-text error event-repeat-dialog-repeat-every-selection-error"
        >
          {{ getInputErrorMessage('repeat-number') }}
        </span>
        <span
          v-else-if="inputHasError('repeat-duration')"
          class="message-text error event-repeat-dialog-repeat-every-selection-error"
        >
          {{ getInputErrorMessage('repeat-duration') }}
        </span>
      </div>
      <div
        class="event-repeat-dialog-day-in-week-section"
        v-if="isRepeatEveryWeekSelected"
      >
        <button
          v-for="(day, index) in daysInWeek"
          :key="index"
          class="event-repeat-dialog-day-in-week-button"
          :class="isDayInWeekSelected(day) ? 'active' : ''"
          @click.prevent="toggleSelectedDayInWeek(day)"
          :aria-label="`${isDayInWeekSelected(day) ? 'Unselect' : 'Select'} ${day}`"
        >
          {{ day[0] }}
        </button>
      </div>
      <div
        class="event-repeat-dialog-time-section"
        v-else-if="isRepeatEveryDaySelected"
      >
        <div
          class="event-repeat-dialog-time-select"
          v-for="(time, index) in selectedStartTimeList"
          :key="index"
        >
          <span>At</span>
          <input-base
            wrapperClass="event-repeat-dialog-time-input-wrapper"
            id="event-repeat-time"
            type="time"
            v-model="selectedStartTimeList[index]"
            :disabled="index == 0"
          ></input-base>
          <button
            class="iu-main-btn small text-only main"
            :class="isAddTimeButtonDisabled(index) ? 'disabled': ''"
            :disabled="isAddTimeButtonDisabled(index)"
            :style="isAddTimeButtonDisabled(index) ? 'visibility: hidden;': ''"
            aria-label="Add another start time in a selected period."
            @click.prevent="addStartTime"
          >
            <i class="fa-solid fa-circle-plus iu-main-button-front-icon"></i>
            Add Time
          </button>
        </div>
      </div>
      <div
        class="event-repeat-dialog-time-section"
        v-else-if="isRepeatEveryMonthSelected"
      >
        <div
          class="event-repeat-dialog-day-in-month-select"
          v-for="(dayInMonth, index) in selectedDayInMonthList"
          :key="index"
        >
          <span>On the</span>
          <select-base
            id="select-day-in-month-select"
            :options="dayInMonthOptionList"
            placeholder=""
            v-model="selectedDayInMonthList[index]"
            wrapperClass="event-repeat-dialog-day-in-month-input-wrapper"
            :disabled="index == 0"
          />
          <button
            class="iu-main-btn small text-only main"
            :class="isAddDayInMonthButtonDisabled(index) ? 'disabled': ''"
            :disabled="isAddDayInMonthButtonDisabled(index)"
            :style="isAddDayInMonthButtonDisabled(index) ? 'visibility: hidden;': ''"
            aria-label="Add another start day in a selected period."
            @click.prevent="addStartDay"
          >
            <i class="fa-solid fa-circle-plus iu-main-button-front-icon"></i>
            Add Day
          </button>
        </div>
      </div>
      <div class="event-repeat-dialog-end-section">
        <div class="event-repeat-dialog-end-title">Ends</div>
        <div class="event-repeat-dialog-end-option-container">
          <div class="event-repeat-dialog-end-radio-button-wrapper">
            <input name="endOption" type="radio" value="on-date" v-model="selectedEndOption" />
            On
          </div>
          <input-base
            id="end-date"
            type="date"
            wrapperClass="event-repeat-dialog-end-date-input-wrapper"
            placeholder="Select date"
            :disabled="!isEndOptionSelected('on-date')"
            v-model="selectedEndDate"
            :min="eventEndDateStr"
            :isError="inputHasError('end-date')"
          />
        </div>
        <div class="event-repeat-dialog-end-option-container">
          <div class="event-repeat-dialog-end-radio-button-wrapper">
            <input name="endOption" type="radio" value="after-occurrences" v-model="selectedEndOption" />
            After
          </div>
          <div class="event-repeat-dialog-occurrence-number-container">
            <input-base
              id="occurrence-number"
              inputStyle="padding: 0; text-align: center;"
              v-model="selectedOccurrenceNumber"
              wrapperClass="event-repeat-dialog-occurrence-number-input"
              type="number"
              :disabled="!isEndOptionSelected('after-occurrences')"
              :isError="inputHasError('occurrence-number')"
            />
            occurrences
          </div>
        </div>
        <span
            v-if="inputHasError('end-option')"
            class="message-text error event-repeat-dialog-end-options-error"
          >
            {{ getInputErrorMessage('end-option') }}
        </span>
        <span
            v-else-if="inputHasError('end-date')"
            class="message-text error event-repeat-dialog-end-options-error"
          >
            {{ getInputErrorMessage('end-date') }}
        </span>
        <span
            v-else-if="inputHasError('occurrence-number')"
            class="message-text error event-repeat-dialog-end-options-error"
          >
            {{ getInputErrorMessage('occurrence-number') }}
        </span>
      </div>
      <div class="event-repeat-dialog-action-section">
        <button
          class="iu-main-btn x-small outlined gray"
          @click.prevent="closeRepeatModal"
          aria-label="Cancel repeat configuration and close dialog"
        >
          Cancel
        </button>
        <button
          class="iu-main-btn x-small solid main"
          @click.prevent="saveRepeatConfig"
          aria-label="Save repeat configuration and close dialog"
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import InputBase from '../../components/formBase/InputBase.vue';
import SelectBase from '../../components/formBase/SelectBase.vue';
import Modal from '../../components/ModalExtended.vue';

export default {
  name: 'event-repeat-dialog',
  components: {
    Modal,
    InputBase,
    SelectBase,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    eventStartDate: {
      type: Date,
      required: true,
    },
    eventStartTime: {
      type: String,
      required: true,
    },
    eventEndDate: {
      type: Date | null,
      required: false,
    },
    preselectedPeriod: {
      type: String,
      default: 'week',
      validator: (value) => {
        return ['daily', 'weekly', 'monthly', null].includes(value);
      }
    },
    preselectedRepeatPeriodNumber: {
      type: Number,
      required: false,
    },
    preselectedPointsInPeriod: {
      type: [String]|[Number],
      required: false,
    },
    preselectedEndOption: {
      type: String,
      required: false,
      validator: (value) => {
        return ['on-date', 'after-occurrences', null].includes(value);
      }
    },
    preselectedEndDate: {
      type: String | Date,
      required: false,
    },
    preselectedEndOccurrencesNumber: {
      type: Number,
      required: false,
    }
  },
  computed: {
    eventEndDateStr() {
      return this.eventEndDate ? this.eventEndDate.toISOString().slice(0, 10) : this.eventStartDateStr;
    },
    eventStartDateStr() {
      return this.eventStartDate.toISOString().slice(0, 10);
    },
    eventDuration() {
      const timeDiff = Date.parse(this.eventEndDate) - Date.parse(this.eventStartDate);
      return timeDiff / 1000;
    },
    isRepeatEveryWeekSelected() {
      return this.repeatPeriod === "weekly";
    },
    isRepeatEveryDaySelected() {
      return this.repeatPeriod === "daily";
    },
    isRepeatEveryMonthSelected() {
      return this.repeatPeriod === "monthly";
    },
  },
  created() {
    this.initializeDayInWeekSelection();
    this.initializeTimeSelection();
    this.initializeDayInMonthOptionList();
    this.initializeDayInMonthSelection();
    this.initializeSelectedPeriod();
    this.initializePreselectedValues();
  },
  data() {
    return {
      daysInWeek: ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
      dayInMonthOptionList: [],
      repeatPeriodOptions: [
        {
          'label': 'day',
          'value': 'daily',
        },
        {
          'label': 'week',
          'value': 'weekly',
        },
        {
          'label': 'month',
          'value': 'monthly',
        }
      ],
      repeatPeriodNumber: 1,
      repeatPeriod: 'weekly',
      selectedDayInWeekList: [],
      selectedStartTimeList: [],
      selectedDayInMonthList: [],
      selectedEndOption: null,
      selectedOccurrenceNumber: null,
      selectedEndDate: null,
      formErrorMessagesObject: {
        'repeatPeriodNumberEmpty': {
          'isActive': false,
          'message': 'Field is required.'
        },
        'repeatPeriodNumberNegative': {
          'isActive': false,
          'message': 'Please enter a whole number greater than zero.'
        },
        'repeatPeriodNumberInteger': {
          'isActive': false,
          'message': 'Please enter a whole number greater than zero.'
        },
        'endOptionEmpty': {
          'isActive': false,
          'message': 'Please define an end condition.'
        },
        'endOptionDateInvalid': {
          'isActive': false,
          'message': 'Defined end date has to be after the initial end date.'
        },
        'endOptionOccurrencesNumberInvalid': {
          'isActive': false,
          'message': 'Please enter a whole number greater than zero.'
        },
        'eventDurationInvalid': {
          'isActive': false,
          'message': 'Selected repeat period has to be longer than event duration.'
        }
      }
    };
  },
  methods: {
    isDateValues() {
      const valueArray = JSON.parse(this.preselectedPointsInPeriod);

      if (valueArray.every(item => !isNaN(item))) {
        return true;
      }

      return false;
    },
    isHourValues() {
      const valueArray = JSON.parse(this.preselectedPointsInPeriod);

      if (valueArray.some(item => item.includes(':'))) {
        return true;
      }

      return false;
    },
    isDayValues() {
      const valueArray = JSON.parse(this.preselectedPointsInPeriod);;

      if (valueArray.some(item => this.daysInWeek.includes(item))) {
        return true;
      }

      return false;
    },
    initializePreselectedValues() {
      if (!!this.preselectedRepeatPeriodNumber) {
        this.repeatPeriodNumber = this.preselectedRepeatPeriodNumber;
      }
      if (!!this.preselectedPointsInPeriod) {
        if (this.isRepeatEveryDaySelected && this.isHourValues()) {
          this.selectedStartTimeList = JSON.parse(this.preselectedPointsInPeriod);
        } else if (this.isRepeatEveryWeekSelected && this.isDayValues()) {
          this.selectedDayInWeekList = JSON.parse(this.preselectedPointsInPeriod);
        } else if (this.isRepeatEveryMonthSelected && this.preselectedPointsInPeriod && this.isDateValues()) {
          this.selectedDayInMonthList = JSON.parse(this.preselectedPointsInPeriod);
        }
      }
      if (!!this.preselectedEndOption) {
        this.selectedEndOption = this.preselectedEndOption;
      }
      if (!!this.preselectedEndDate) {
        this.selectedEndDate = this.preselectedEndDate;
      }
      if (!!this.preselectedEndOccurrencesNumber) {
        this.selectedOccurrenceNumber = this.preselectedEndOccurrencesNumber;
      }
    },
    initializeSelectedPeriod() {
      this.repeatPeriod = this.preselectedPeriod;
    },
    isAddDayInMonthButtonDisabled(index) {
      if (index + 1 < this.selectedDayInMonthList.length) {
        return true;
      }

      if (!this.selectedDayInMonthList[this.selectedDayInMonthList.length-1]) {
        return true;
      }

      return false;
    },
    isAddTimeButtonDisabled(index) {
      if (index + 1 < this.selectedStartTimeList.length) {
        return true;
      }

      if (!this.selectedStartTimeList[this.selectedStartTimeList.length-1]) {
        return true;
      }

      return false;
    },
    initializeDayInMonthSelection() {
      const dayInMonth = this.eventStartDate.getDate();
      this.selectedDayInMonthList.push(this.dayInMonthOptionList[dayInMonth-1].value);
    },
    initializeDayInMonthOptionList() {
      for (let i = 1; i <= 31; i++) {
        let suffix;
        if (i % 10 === 1 && i !== 11) {
          suffix = "st";
        } else if (i % 10 === 2 && i !== 12) {
          suffix = "nd";
        } else if (i % 10 === 3 && i !== 13) {
          suffix = "rd";
        } else {
          suffix = "th";
        }
        this.dayInMonthOptionList.push({'label': `${i}${suffix}`, 'value': i.toString()});
      }
    },
    initializeTimeSelection() {
      this.selectedStartTimeList.push(this.eventStartTime);
    },
    initializeDayInWeekSelection() {
      const dateObj = this.eventStartDate;
      const dayOfWeek = dateObj.getDay();
      this.selectedDayInWeekList.push(this.daysInWeek[dayOfWeek]);
    },
    getInputErrorMessage(inputId) {
      if (inputId === 'repeat-number') {
        if (this.formErrorMessagesObject.repeatPeriodNumberEmpty.isActive) {
          return this.formErrorMessagesObject.repeatPeriodNumberEmpty.message;
        }
        if (this.formErrorMessagesObject.repeatPeriodNumberNegative.isActive) {
          return this.formErrorMessagesObject.repeatPeriodNumberNegative.message;
        }
        if(this.formErrorMessagesObject.repeatPeriodNumberInteger.isActive) {
          return this.formErrorMessagesObject.repeatPeriodNumberInteger.message;
        }
      } else if (inputId === 'end-option') {
        if (this.formErrorMessagesObject.endOptionEmpty.isActive) {
          return this.formErrorMessagesObject.endOptionEmpty.message;
        }
      } else if (inputId === 'end-date') {
        if (this.formErrorMessagesObject.endOptionDateInvalid.isActive) {
          return this.formErrorMessagesObject.endOptionDateInvalid.message;
        }
      } else if (inputId === 'occurrence-number') {
        if(this.formErrorMessagesObject.endOptionOccurrencesNumberInvalid.isActive) {
          return this.formErrorMessagesObject.endOptionOccurrencesNumberInvalid.message;
        }
      } else if (inputId === 'repeat-duration') {
        if(this.formErrorMessagesObject.eventDurationInvalid.isActive) {
          return this.formErrorMessagesObject.eventDurationInvalid.message;
        }
      }
      return "";
    },
    inputHasError(inputId) {
      if (inputId === 'repeat-number') {
        if (
          this.formErrorMessagesObject.repeatPeriodNumberEmpty.isActive ||
          this.formErrorMessagesObject.repeatPeriodNumberNegative.isActive ||
          this.formErrorMessagesObject.repeatPeriodNumberInteger.isActive
          ) {
          return true;
        }
      } else if (inputId === 'end-option') {
        if (this.formErrorMessagesObject.endOptionEmpty.isActive) {
          return true;
        }
      } else if (inputId === 'end-date') {
        if (this.formErrorMessagesObject.endOptionDateInvalid.isActive) {
          return true;
        }
      } else if (inputId === 'occurrence-number') {
        if (this.formErrorMessagesObject.endOptionOccurrencesNumberInvalid.isActive) {
          return true;
        }
      } else if (inputId === 'repeat-duration') {
        if (this.formErrorMessagesObject.eventDurationInvalid.isActive) {
          return true;
        }
      }
      return false;
    },
    isEndOptionSelected(option) {
      return this.selectedEndOption === option;
    },
    isDayInWeekSelected(day) {
      return this.selectedDayInWeekList.includes(day);
    },
    closeRepeatModal() {
      this.$emit('close-repeat-modal');
    },
    validateRepeatPeriodNumber() {
      if (this.repeatPeriodNumber == null) {
        this.formErrorMessagesObject.repeatPeriodNumberNegative.isActive = true;
        return false;
      }

      if (typeof(this.repeatPeriodNumber) !== 'string' && this.repeatPeriodNumber < 1) {
        this.formErrorMessagesObject.repeatPeriodNumberNegative.isActive = true;
        return false;
      }

      if (typeof(this.repeatPeriodNumber) === 'string' && (+this.repeatPeriodNumber) < 1) {
        this.formErrorMessagesObject.repeatPeriodNumberNegative.isActive = true;
        return false;
      }

      if(typeof(this.repeatPeriodNumber) !== 'string' && !Number.isInteger(this.repeatPeriodNumber)) {

        this.formErrorMessagesObject.repeatPeriodNumberInteger.isActive = true;
        return false;
      }

      if(typeof(this.repeatPeriodNumber) === 'string' && !Number.isInteger(+this.repeatPeriodNumber)) {
        this.formErrorMessagesObject.repeatPeriodNumberInteger.isActive = true;
        return false;
      }

      this.formErrorMessagesObject.repeatPeriodNumberEmpty.isActive = false;
      this.formErrorMessagesObject.repeatPeriodNumberNegative.isActive = false;
      this.formErrorMessagesObject.repeatPeriodNumberInteger.isActive = false;
      return true;
    },
    validateEndOption() {
      if (!this.selectedEndOption) {
        this.formErrorMessagesObject.endOptionEmpty.isActive = true;
        return false;
      }

      if (this.isEndOptionSelected('on-date') && !this.selectedEndDate) {
        this.formErrorMessagesObject.endOptionEmpty.isActive = true;
        return false;
      }

      if (this.isEndOptionSelected('after-occurrences') && !this.selectedOccurrenceNumber) {
        this.formErrorMessagesObject.endOptionEmpty.isActive = true;
        return false;
      }

      this.formErrorMessagesObject.endOptionEmpty.isActive = false;
      return true;
    },
    validateEndDate() {
      if (this.isEndOptionSelected('on-date') &&
      (this.eventEndDate >= this.selectedEndDate ||
      this.eventEndDate >= new Date(this.selectedEndDate))) {
        this.formErrorMessagesObject.endOptionDateInvalid.isActive = true;
        return false;
      }

      this.formErrorMessagesObject.endOptionDateInvalid.isActive = false;
      return true;
    },
    validateOccurrenceNumber() {
      if (!this.isEndOptionSelected('after-occurrences')) {
        this.formErrorMessagesObject.endOptionOccurrencesNumberInvalid.isActive = false;
        return true;
      }

      if (typeof(this.selectedOccurrenceNumber) !== 'string' && this.selectedOccurrenceNumber < 1) {
        this.formErrorMessagesObject.endOptionOccurrencesNumberInvalid.isActive = true;
        return false;
      }

      if (typeof(this.selectedOccurrenceNumber) === 'string' && (+this.selectedOccurrenceNumber) < 1) {
        this.formErrorMessagesObject.endOptionOccurrencesNumberInvalid.isActive = true;
        return false;
      }

      if(typeof(this.selectedOccurrenceNumber) !== 'string' && !Number.isInteger(this.selectedOccurrenceNumber)) {

        this.formErrorMessagesObject.endOptionOccurrencesNumberInvalid.isActive = true;
        return false;
      }

      if(typeof(this.selectedOccurrenceNumber) === 'string' && !Number.isInteger(+this.selectedOccurrenceNumber)) {

        this.formErrorMessagesObject.endOptionOccurrencesNumberInvalid.isActive = true;
        return false;
      }

      this.formErrorMessagesObject.endOptionOccurrencesNumberInvalid.isActive = false;
      return true;
    },
    getSelectedPeriodDuration() {
      const dayDuration = 60 * 60 * 24;
      const weekDuration = dayDuration * 7;
      const monthDuration = weekDuration * 31;

      if (this.repeatPeriod === 'daily') {
        return dayDuration * this.repeatPeriodNumber;
      } else if (this.repeatPeriod === 'weekly') {
        return weekDuration * this.repeatPeriodNumber;
      } else if (this.repeatPeriod === 'monthly') {
        return monthDuration * this.repeatPeriodNumber;
      }

      return 0;
    },
    validateEventDuration() {
      const selectedPeriodDuration = this.getSelectedPeriodDuration();
      if (selectedPeriodDuration < this.eventDuration) {
        this.formErrorMessagesObject.eventDurationInvalid.isActive = true;
        return false;
      }

      this.formErrorMessagesObject.eventDurationInvalid.isActive = false;
      return true;
    },
    isValid() {
      const isRepeatPeriodNumberValid = this.validateRepeatPeriodNumber();
      const isEndOptionValid = this.validateEndOption();
      const isDateEndOptionValid = this.validateEndDate();
      const isOccurrencesNumberValid = this.validateOccurrenceNumber();
      const isEventDurationValid = isRepeatPeriodNumberValid ? this.validateEventDuration() : true;

      return isRepeatPeriodNumberValid &&
              isEndOptionValid &&
              isDateEndOptionValid &&
              isOccurrencesNumberValid &&
              isEventDurationValid;
    },
    getConfig() {
      const endConditions = {
        selectedEndOption: this.selectedEndOption
      }

      if (this.isEndOptionSelected('on-date')) {
        endConditions.onDate = this.selectedEndDate;
      } else if (this.isEndOptionSelected('after-occurrences')) {
        endConditions.afterOccurrencesNumber = this.selectedOccurrenceNumber;
      }

      const repeatPeriodSelectedObj = {
        repeatPeriod: this.repeatPeriod,
      }
      if (this.isRepeatEveryDaySelected) {
        repeatPeriodSelectedObj.selectedValues = this.selectedStartTimeList;
      } else if (this.isRepeatEveryWeekSelected) {
        repeatPeriodSelectedObj.selectedValues = this.selectedDayInWeekList;
      } else if (this.isRepeatEveryMonthSelected) {
        repeatPeriodSelectedObj.selectedValues = this.selectedDayInMonthList;
      }

      return {
        repeatPeriodNumber: this.repeatPeriodNumber,
        repeatPeriod: this.repeatPeriod,
        repeatPeriodValues: repeatPeriodSelectedObj.selectedValues,
        endConditions: endConditions,
      }
    },
    saveRepeatConfig() {
      const isValid = this.isValid();
      if (!isValid) {
        return;
      }

      const repeatConfig = this.getConfig();
      repeatConfig.recurring = true;
      this.$emit('save-repeat-config', repeatConfig);
    },
    toggleSelectedDayInWeek(day) {
      if (this.isDayInWeekSelected(day)) {
        this.selectedDayInWeekList = this.selectedDayInWeekList.filter((item) => item !== day);
      } else {
        this.selectedDayInWeekList.push(day);
      }
    },
    addStartTime() {
      this.selectedStartTimeList.push(null);
    },
    addStartDay() {
      this.selectedDayInMonthList.push(null);
    },
  },
};
</script>
