<template>
    <Modal :isActiveProp="true" @close="closeEditModal" :classProp="'iu-modal-content-container'">
        <div v-if="currentState=='EDIT_USER'" class="iu-modal-content">
            <h4 class="iu-modal-title-text">Edit User</h4>
            <div class="iu-modal-form-container">
                <div class="iu-modal-multiple-input-container">
                    <div class="iu-input-field-container">
                        <label for="first-name" class="iu-input-field-label-container iu-input-field-label-text">
                            First Name
                            <span class="iu-input-field-label-required-star">
                                *
                            </span>
                        </label>
                        <input
                            id="first-name"
                            type="text"
                            class="iu-input-field"
                            :class="fieldsWithError.includes('first_name') ? 'field-error': ''"
                            required
                            v-model="user.first_name"
                        >
                    </div>
                    <div class="iu-input-field-container">
                        <label for="last-name" class="iu-input-field-label-container iu-input-field-label-text">
                            Last Name
                            <span class="iu-input-field-label-required-star">
                                *
                            </span>
                        </label>
                        <input
                            id="last-name"
                            type="text"
                            class="iu-input-field"
                            :class="fieldsWithError.includes('last_name') ? 'field-error': ''"
                            required
                            v-model="user.last_name"
                        >
                    </div>
                </div>
                <div class="iu-input-field-container">
                    <input
                        id="email"
                        type="text"
                        class="iu-input-field"
                        :class="fieldsWithError.includes('email') ? 'field-error': ''"
                        required
                        v-model="user.email"
                    >
                    <label for="email" class="iu-input-field-label-container iu-input-field-label-text">
                        Email
                        <span class="iu-input-field-label-required-star">
                            *
                        </span>
                    </label>
                </div>
                <div v-if="!isPartnerTenant()" class="iu-select-field-container">
                    <label for="department" class="iu-select-field-label-container iu-input-field-label-text">
                        {{deptSingular}}
                    </label>
                    <select
                        name="department"
                        id="department"
                        class="iu-select-field"
                        v-model="user.department"
                    >
                        <option
                            v-for="department in departmentList"
                            :key="department.name"
                            :value="department.name"
                            class="iu-select-field-option"
                        >
                            {{department.name}}
                        </option>
                    </select>
                    <span class="iu-select-field-arrow"><i class="fa-solid fa-chevron-down"></i></span>
                </div>
                <div class="iu-modal-multiple-input-container">
                    <div class="iu-select-field-container" style="width: 50%">
                        <label for="department" class="iu-select-field-label-container iu-input-field-label-text">
                            Role
                            <span class="iu-input-field-label-required-star">
                                *
                            </span>
                        </label>
                        <select
                            name="department"
                            id="department"
                            class="iu-select-field"
                            v-model="user.role"
                        >
                            <option
                                v-for="role in roleList"
                                :key="role.id"
                                :value="role"
                                class="iu-select-field-option"
                            >
                                {{role.name}}
                            </option>
                        </select>
                        <span class="iu-select-field-arrow"><i class="fa-solid fa-chevron-down"></i></span>
                    </div>
                    <div class="iu-select-field-container" style="width: 206px; justify-content: center; padding-top: 22px;">
                        <toggle-button
                            id="status-toggle"
                            :value="isUserEnabled"
                            :sync="true"
                            :height="28"
                            :width="206"
                            :fontSize="14"
                            :color="{checked: '#4c55a7', unchecked: '#b4b4b4'}"
                            :labels="{checked: 'User account is enabled', unchecked: 'User account is suspended'}"
                            @change="statusSwitch"
                        />
                    </div>
                </div>
            </div>
            <div class="iu-account-modal-details-container">
                User Status: {{getUserStatus()}}
                <br>
                Account Created: {{user.added}}
                <br>
                <span
                    v-if="user.is_sso"
                >
                    Account Channel: {{sso_provider}}
                </span>
            </div>
            <div class="iu-account-modal-sensitive-actions-container">
                <button
                    class="iu-main-btn small solid gray"
                    aria-label="Open confirm reset password dialog"
                    @click="changeState(1)"
                >Reset Password</button>
                <button
                    class="iu-main-btn small solid gray"
                    aria-label="Open confirm reset user dialog"
                    @click="changeState(2)"
                >Reset User</button>
                <button
                    class="iu-main-btn small solid gray"
                    aria-label="Open confirm delete user dialog"
                    @click="changeState(3)"
                >Delete User</button>
            </div>
            <div class="iu-modal-separator"></div>
            <div class="iu-modal-actions-container">
                <button
                    class="iu-main-btn small solid main iu-modal-save-button"
                    aria-label="Save changes"
                    @click="submitSave"
                >Save</button>
                <button
                    class="iu-main-btn small outlined gray"
                    aria-label="Close edit user modal"
                    @click="closeEditModal"
                >Cancel</button>
            </div>
        </div>
        <div v-if="['CONFIRM_RESET_PASSWORD', 'CONFIRM_RESET_USER', 'CONFIRM_DELETE_USER', 'CONFIRM_DELETE_USER_AFTER_EDIT', 'CONFIRM_DELETE_MULTIPLE_USERS'].includes(currentState)" class="iu-modal-content">
            <div v-if="currentState != 'CONFIRM_DELETE_MULTIPLE_USERS'" class="iu-account-confirm-modal-name-container">
                <img :src="user.profile_pic" alt="User profile picture" class="iu-account-confirm-modal-profile-image">
                {{user.full_name}}
            </div>
            <div class="iu-modal-separator"></div>
            <div v-if="currentState=='CONFIRM_RESET_PASSWORD'" class="iu-account-confirm-modal-text-container">
                Press Confirm to send the password reset email.
            </div>
            <div v-if="currentState=='CONFIRM_RESET_USER'" class="iu-account-confirm-modal-text-container">
                Are you sure you want to reset this user? All settings for this user will be deleted. This action cannot be undone.
            </div>
            <div v-if="['CONFIRM_DELETE_USER', 'CONFIRM_DELETE_USER_AFTER_EDIT', 'CONFIRM_DELETE_MULTIPLE_USERS'].includes(currentState)" class="iu-account-confirm-modal-text-container">
                Are you sure you want to delete this user(s)? This action cannot be undone.
            </div>
            <div v-if="['CONFIRM_RESET_USER', 'CONFIRM_DELETE_USER', 'CONFIRM_DELETE_USER_AFTER_EDIT', 'CONFIRM_DELETE_MULTIPLE_USERS'].includes(currentState)" class="iu-account-confirm-modal-text-container">
                For security reasons, please type in your password:
            </div>
            <div class="iu-input-field-container" v-if="currentState!='CONFIRM_RESET_PASSWORD'">
                <label for="last-name" class="iu-input-field-label-container iu-input-field-label-text">
                    Password
                    <span class="iu-input-field-label-required-star">
                        *
                    </span>
                </label>
                <input
                    id="last-name"
                    type="password"
                    class="iu-input-field"
                    required
                    v-model="user.password"
                >
            </div>
            <div v-if="currentState=='CONFIRM_RESET_USER'" class="iu-account-confirm-modal-deactivate-account-container">
                <label class="eu-checkbox-container eu-event-collection-checkmark-label" style="font-size: 14px; margin: 0px; height: unset; width: unset; padding-left: 28px;">
                    Deactivate account as well (requires email activation)
                    <input
                        type="checkbox"
                        v-model="user.deactivateAccount"
                        style="margin-right: 8px;"
                    >
                    <span class="eu-checkmark" style="margin: 0;"></span>
                </label>
            </div>
            <div class="iu-account-confirm-modal-actions-container">
                <button
                    v-if="currentState=='CONFIRM_RESET_PASSWORD'"
                    class="iu-main-btn small red solid"
                    @click="submitConfirm"
                    aria-label="Confirm reset password"
                >
                    Confirm
                </button>
                <button
                    v-if="currentState=='CONFIRM_RESET_USER'"
                    class="iu-main-btn small red solid"
                    @click="submitConfirm"
                    aria-label="Confirm reset user"
                >
                    Reset User
                </button>
                <button
                    v-if="['CONFIRM_DELETE_USER_AFTER_EDIT', 'CONFIRM_DELETE_USER', 'CONFIRM_DELETE_MULTIPLE_USERS'].includes(currentState)"
                    class="iu-main-btn small red solid"
                    @click="submitConfirm"
                    aria-label="Confirm delete user"
                >
                    Delete User
                </button>
                <button
                    class="iu-main-btn small outlined gray"
                    aria-label="Return to edit user modal"
                    @click="closeConfirmDialog"
                >Cancel</button>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
import Modal from '../../../components/ModalExtended.vue'
import { ToggleButton } from 'vue-js-toggle-button';
import { isPartnerTenant } from "../../../utils/CustomUtils";
import { useCommonDataStore } from '../../../stores/commonData';

export default {
    name: 'edit-user-modal',

    components: {
        Modal,
        ToggleButton,
    },

    props: [
        'preselectedAction',
        'selectedUser',
        'departmentList',
        'roleList',
        'sso_provider',
    ],

    computed: {
        currentState() {
            return this.states[this.currentStateIndex];
        },
        isUserEnabled() {
            return !this.user.disabled;
        },
        deptSingular() {
        return useCommonDataStore().getFeatureName('department_singular')
        },
    },

    data() {
        return {
            states: ['EDIT_USER', 'CONFIRM_RESET_PASSWORD', 'CONFIRM_RESET_USER', 'CONFIRM_DELETE_USER_AFTER_EDIT', 'CONFIRM_DELETE_USER', 'CONFIRM_DELETE_MULTIPLE_USERS'],
            currentStateIndex: 0,
            accountChannel: 'Custom SSO',
            fullName: "User Name",
            password: '',
            user: {
                id: 0,
                profile_pic: "",
                first_name: "",
                last_name: "",
                email: "",
                department: "",
                role: {id: 0, name: ''},
                disabled: false,
                status: "",
                full_name: "",
                added: "",
                password: "",
                email_status: false,
                account_validated: false,
                is_edit_user: true,
                deactivateAccount: false,
            },
            fieldsWithError: [],
        }
    },

    created() {
        this.currentStateIndex = this.getStateIndex(this.preselectedAction);

        if(this.currentState != 'CONFIRM_DELETE_MULTIPLE_USERS') {
          this.initUser();
        }
    },

    methods: {
        isPartnerTenant,
        initUser() {
            //TODO this makes dialog coupled to the StudentBulkInterface component
            //maybe it will be good to map fields outside the component
            this.user.id = this.selectedUser.id;
            this.user.profile_pic = this.selectedUser.profile_pic;
            this.user.first_name = this.selectedUser.first_name;
            this.user.last_name = this.selectedUser.last_name;
            this.user.email = this.selectedUser.email;
            this.user.department = this.selectedUser.department;
            this.user.role = this.selectedUser.role;
            this.user.disabled = this.selectedUser.disabled;
            this.user.full_name = this.selectedUser.first_name + " " + this.selectedUser.last_name;
            this.user.added = this.selectedUser.added;
            this.user.email_status = this.selectedUser.email_status;
            this.user.account_validated = this.selectedUser.account_validated;
            this.user.status = this.getUserStatus();
        },
        isFieldValid(field_name) {
            return !this.fieldsWithError.includes(field_name);
        },
        validateFieldIsNotEmpty(field_name) {
            if (this.user[field_name] == "") {
                if (!this.fieldsWithError.includes(field_name)) {
                    this.fieldsWithError.push(field_name);
                }
            } else {
                this.fieldsWithError = this.fieldsWithError.filter(item => item !== field_name);
            }
        },
        validateFields() {
            this.validateFieldIsNotEmpty("first_name");
            this.validateFieldIsNotEmpty("last_name");
            this.validateFieldIsNotEmpty("email");

            return this.fieldsWithError.length == 0;
        },
        statusSwitch() {
            this.user.disabled = !this.user.disabled;
        },
        getUserStatus() {
            if (this.user.disabled) {
                return 'Suspended';
            }
            if (this.user.email_status == false) {
                return 'Invitation not sent'
            }
            if (this.user.account_validated == false) {
                return 'Not active'
            }
            return 'Active';
        },
        submitSave() {
            if (this.validateFields()) {
                this.$emit('editUser', this.user);
            }
        },
        submitConfirm() {
            if (this.currentState == "CONFIRM_RESET_PASSWORD") {
                this.$emit('resetPassword', this.user);
            } else if (this.currentState == "CONFIRM_RESET_USER") {
                this.$emit('resetUser', this.user, this.user.deactivateAccount);
            } else if (["CONFIRM_DELETE_USER", "CONFIRM_DELETE_USER_AFTER_EDIT"].includes(this.currentState)) {
                this.$emit('deleteUser', this.user);
            } else if (this.currentState == "CONFIRM_DELETE_MULTIPLE_USERS") {
                this.$emit('deleteMultipleUsers', this.user.password);
            }
        },
        closeConfirmDialog() {
            if (this.currentState == "CONFIRM_DELETE_MULTIPLE_USERS" || this.currentState == "CONFIRM_DELETE_USER") {
                this.$emit('closeEditModal');
            } else {
                this.changeState(0);
            }
        },
        closeEditModal() {
            this.$emit('closeEditModal');
        },
        changeState(newStateIndex) {
            this.currentStateIndex = newStateIndex;
        },
        getStateIndex(stateString) {
            return this.states.indexOf(stateString) > -1 ? this.states.indexOf(stateString) : 0;
        },
    },
}
</script>
