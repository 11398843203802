<template>
  <div
    class="item-card"
    :style="`background: linear-gradient(to top, #000 10%, rgba(0, 0, 0, 0) 55%), url(${course.course_card_image_url}) no-repeat center center / cover;`"
  >
    <div class="toolbar">
      <div class="left">
        <div v-if="course.is_recommended" class="type-label featured">
          <i class="fas fa-stars" style="color: #cbe9ec"></i>Featured
        </div>
        <div class="type-label" v-else>Course</div>
      </div>
      <div class="right">
        <i
          class="fas fa-ellipsis-v"
          style="text-shadow: 2px 2px rgba(0, 0, 0, 0.1)"
          tabindex="0"
          ref="crsListBurger"
          @click="showBurger()"
          @keyup.enter="showBurger()"
          aria-label="Burger menu"
          role="menu"
          aria-haspopup="menu"
        >
        </i>
        <div
          ref="crsListBurgerDropdown"
          :id="`crs${course.id}`"
          v-closable="{ exclude: ['crsListBurgerDropdown', 'crsListBurger'], handler: 'closeBurger' }"
          class="inup-profile-row-burger dashboard-burger-pos"
        >
          <span
            @click="redirectToCourse(course.id)"
            @keyup.enter="redirectToCourse(course.id)"
            tabindex="0"
            :aria-label="`Open ${course.name}`"
            role="button"
            class="eu-resource-burger-el"
          >
            <i class="fas fa-eye" style="padding-right: 5px; color: #88858e"></i>
            <span>View Course</span>
          </span>
        </div>
      </div>
    </div>
    <div class="img-card-info-wrapper">
      <div @click="redirectToCourse(course.id)" @keyup.enter="redirectToCourse(course.id)" class="img-card-name">
        {{ course.name }}
      </div>
    </div>
  </div>
</template>
<script>
import handleOutsideClick from "../../directives/handleOutsideClick";
import FeedService from "../../feed/services/FeedService";
import * as Cookies from "js-cookie";

export default {
  directives: { closable: handleOutsideClick },
  components: {},
  name: "course-card",
  props: ["course"],
  data() {
    return {
      lastOpenedBurger: null,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  created() {
    this.feedService = new FeedService();
  },
  methods: {
    redirectToCourse(id) {
      this.$router.push(`/courses/${id}`);
    },
    showBurger() {
      const burgerEl = document.querySelector(`#crs${this.course.id}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl) {
        this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
      }
      burgerEl.classList.toggle("eu-resource-burger-active");
      this.lastOpenedBurger = burgerEl;
    },
    closeBurger() {
      this.lastOpenedBurger && this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
    },
  },
  computed: {},
};
</script>
