import Vue from 'vue';
import * as Cookies from 'js-cookie';
import PermissionsService from '../services/PermissionsService';

export default class PermissionsManager {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected appData: any;
  protected service: PermissionsService;
  protected isPartner: boolean;

  constructor(el: HTMLElement, appData: any) {
    this.csrfToken = Cookies.get('csrftoken');
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.appData = appData;
    this.service = new PermissionsService();
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;

    const customHeaders = {
      'Accept': 'application/json',
      'X-CSRFToken': _class.csrfToken,
    };

    const permissionsManager = new Vue({
      components: {},
      template: `
        <section>
          <h2>Manage additional permissions for user: {{user_name}}</h2>
          <form class="da-forms">
              <h4> Features: </h4>
              <div v-for="perm in additional_permissions" class="da-form-formItem">
                <h4>{{ perm.name }}</h4>
                <p v-for="verb in verbs">
                  <input type="radio" :name="perm.value + '-radio-group'" :value="verb.value"
                         v-model="perm.permissionLevel">
                  {{ verb.name }}
                </p>
              </div>
            <button @click="handleSave" class="da-components-button da-btn-solid">SAVE</button>
          </form>
        </section>
      `,
      data() {
        return {
          userId: _class.appData.user_id,
          user_name: _class.appData.user_name,
          additional_permissions: _class.appData.additional_permissions,
          verbs: _class.appData.verbs,
          subdomain: _class.appData.subdomain
        };
      },
      methods: {
        async handleSave(e) {
          e.preventDefault();
          const parsedPermissions = [];
          this.additional_permissions.forEach(p => { if (p.permissionLevel >= 0) parsedPermissions.push(`${p.value}:${p.permissionLevel}`) })

          const urlParams = new URLSearchParams(window.location.search);

          const data = {
            'user_id': this.userId,
            'subdomain': this.subdomain || urlParams.get('tenant'),
            'permissions': parsedPermissions,
          };
          const res = await _class.service.postFormData(data, customHeaders);
          const redirectUrl = `${window.location.origin}/cp/permissions/`;
          const redirectParams = `?tenant=${urlParams.get('tenant')}`;
          const hasParams = !!urlParams.get('tenant');
          if (res.status === 'success')
            location.replace(hasParams ? redirectUrl + redirectParams : redirectUrl)
          else {
            console.error(res.status);
          }
        },
      },
    });
    permissionsManager.$mount(this.mountPoint);
  }
}
