import Vue from 'vue';
import { Event } from '../../types';
import moment from 'moment';
import { DateTime } from 'luxon';

Vue.filter('dateFormat', function (date) {
  if (!date) return '';
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const dateList = date.split('-');

  const dateYear = dateList[0];
  const dateMonth = months[dateList[1] - 1];
  const dateDay = dateList[2];

  return `${dateMonth} ${dateDay}, ${dateYear}`;
});

Vue.filter('shortDateFormat', function (date) {
  if (!date) return '';
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const dateList = date.split('T')[0].split('-');

  const dateYear = dateList[0];
  const dateMonth = months[dateList[1] - 1];
  const dateDay = dateList[2].trim();

  return `${dateMonth} ${dateDay}, ${dateYear}`;
});

export function formatEventTime(event: Event) {
  const start = event.start_time;
  const end = event.end_time;

  if (end) {
    return start + ' - ' + end;
  }

  return start;
}

export function formatEventStart(event: Event) {
  return event.start_time ? `${event.start_date} ${event.start_time}` : `${event.start_date}`;
}

export function formatEventEnd(event: Event) {
  if (!event.end_date && event.end_time) {
    return `${event.start_date} ${event.end_time}`;
  }

  if (event.end_date) {
    return event.end_time ? `${event.end_date} ${event.end_time}` : `${event.end_date}`;
  }

  return '';
}

export function convertTZ(date, tzString) {
  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));
}

export function parseEventDates(events) {
  return events.map((e) => {
    const eventStartString = `${e.start_date}T${e.start_time}`;
    const eventStartDateTZ = DateTime.fromISO(eventStartString, { zone: e.time_zone });
    e.start = new Date(+eventStartDateTZ);
    let eventEndString;
    if (e.end_date) {
      eventEndString = `${e.end_date}T${e.end_time}`;
    } else {
      eventEndString = `${e.start_date}T${e.end_time}`;
    }
    const eventEndDateTZ = DateTime.fromISO(eventEndString, { zone: e.time_zone });
    e.end = new Date(+eventEndDateTZ);
    return e;
  });
}

Vue.filter('parseEventTime', function (event) {
  if (event.end_date) {
    return `${moment(event.start).format('MMM D, YYYY')} - ${moment(event.end).format('MMM D, YYYY')}`;
  }
  return `${moment(event.start).format('LT')} - ${moment(event.end).format('LT')}`;
});
