<template>
    <Modal :isActiveProp="isModalActive">
        <div v-if="!(isCalling)" class="call-modal-container">
            <h3>Make a Call</h3>
            <img :src="profilePicture" alt="callee avatar" class="profile-picture">
            <h4>{{ userName }}</h4>

            <div class="switch-container">
                <p class="label-text">Video</p>
                <toggle-button
                    id="video-toggle"
                    :value="isCameraEnabled"
                    :sync="true"
                    :color="{checked: '#4c55a7', unchecked: '#b4b4b4'}"
                    :labels="{checked: 'On', unchecked: 'Off'}"
                    @change="videoSwitchHandler"
                />

                <p class="label-text">Mic</p>
                <toggle-button
                    id="mic-toggle"
                    :value="isMicEnabled"
                    :sync="true"
                    :color="{checked: '#4c55a7', unchecked: '#b4b4b4'}"
                    :labels="{checked: 'On', unchecked: 'Off'}"
                    @change="audioSwitchHandler"
                />
            </div>
            <div class="call-buttons">
            <button
                class="iu-btn-solid green"
                @click="callUser"
            >
                Make {{isCameraEnabled ? 'Video' : ''}} Call
                <i class="fas fa-chevron-right fa-sm" style="color:#77c4ca;position:relative;left:3px;top:1px;"></i>
            </button>

            <button
                class="iu-btn-light gray"
                @click="closeCallUserModal"
            >
                Cancel
            </button>
            </div>
            <p class="info-text">Video calls have a 30-minute limit.</p>
        </div>

        <div v-else class="call-modal-container">
            <h3>Calling...</h3>
            <img :src="profilePicture" alt="callee avatar" class="profile-picture">
            <h4>{{ userName }}</h4>

            <button
                class="iu-btn-light gray"
                style="margin: 32px auto;display: block;"
                @click="cancelCall"
            >
                Cancel
            </button>

            <p class="info-text">Video calls have a 30-minute limit.</p>
        </div>
    </Modal>
</template>
<script lang="ts">
import Modal from '../../../components/ModalExtended.vue';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
    components: { Modal, ToggleButton, },

    name: 'call-user-modal',

    props: ['isModalActive', 'isCalling', 'userName', 'profilePicture', 'isMicEnabled', 'isCameraEnabled',],

    data() {
        return {};
    },

    methods: {
        closeCallUserModal: function () {
            this.$emit('close-call-user-modal');
        },

        callUser: function() {
            this.$emit('call-user');
        },

        cancelCall: function() {
            this.$emit('cancel-call');
        },

        videoSwitchHandler: function(valueObject: {value: Boolean, tag: any, srcEvent: Event}) {
            this.$emit('video-toggled', valueObject.value);
        },

        audioSwitchHandler: function(valueObject: {value: Boolean, tag: any, srcEvent: Event}) {
            this.$emit('audio-toggled', valueObject.value);
        },
    },
}
</script>
