<template>
  <section>
    <ul class="iu-meeting-cards">
      <li
        v-for="(item, i) in meetings"
        v-bind:key="i"
        class="iu-meeting-card"
        :class="getCardClass(item.is_mentor, item.status)"
      >
        <div class="iu-meeting-card-upper-section">
          <div class="iu-meeting-card-time-info-container slim-scrollbar-horizontal">
            <div>
              <i class="fas fa-calendar-day iu-meeting-calendar-icon"></i>
              <span class="iu-meeting-date">{{
                item.meeting_hours.date ? item.meeting_hours.date : item.cancelled_start_time | parseLongDate
              }}</span>
            </div>
            <div>
              <i class="fas fa-clock iu-meeting-clock-icon"></i>
              <span
                v-if="item.status != 'canceled'"
                class="iu-meeting-hours"
                :class="item.status == 'active' ? 'active' : ''"
                >{{ item.meeting_hours | parseTime }}</span
              >
              <span v-if="item.status == 'canceled'" class="iu-meeting-hours">{{
                { startTime: item.cancelled_start_time, endTime: item.cancelled_end_time } | parseTime
              }}</span>
            </div>
          </div>
          <span
            tabindex="0"
            ref="burgerGrid"
            :id="`mentor_connect_appointments_grid_${item.id}`"
            class="iu-meeting-dots-icon"
            @click="showBurger(item, 'grid')"
            @keyup.enter="showBurger(item, 'grid')"
            aria-label="Burger menu"
            role="menu"
            aria-haspopup="true"
          >
            <i class="fas fa-ellipsis-v"></i>
            <div
              ref="burgerGridDropdown"
              v-closable="{ exclude: ['burgerGrid', 'burgerGridDropdown'], handler: 'closeBurgers' }"
              :id="'grid' + item.id + item.timestamp"
              class="iu-resource-burger"
              :class="openedDropdown == item.id ? 'iu-resource-burger-active' : ''"
            >
              <span
                tabindex="0"
                @click="openModal(item)"
                @keyup.enter="openModal(item)"
                class="iu-resource-burger-el"
                aria-label="open view meetings details modal"
                aria-haspopup="dialog"
                role="button"
              >
                <i class="fas fa-eye" style="padding-right: 5px"></i>
                <span>View Details</span>
              </span>

              <span
                v-if="!isPartnerTenant"
                tabindex="0"
                @click="openFollowUpModal(item)"
                @keyup.enter="openFollowUpModal(item)"
                class="iu-resource-burger-el"
                aria-label="Request follow-up meeting"
                role="button"
              >
                <i class="fas fa-envelope" style="padding-right: 5px"></i>
                <span>Request Follow Up</span>
              </span>

              <span
                tabindex="0"
                v-if="is_past"
                @click="archive(item)"
                @keyup.enter="archive(item)"
                class="iu-resource-burger-el"
                aria-label="Archive meeting"
                role="button"
              >
                <i class="fas fa-archive" style="padding-right: 5px"></i>
                <span>Archive</span>
              </span>

              <span
                tabindex="0"
                v-if="showCalendarAndCancelCheck(item.status)"
                ref="calendarDropdown"
                @click="toggleCalendarDropdown()"
                @keyup.enter="toggleCalendarDropdown()"
                aria-label="Add to calendar"
                aria-haspopup="menu"
                role="menu"
                class="iu-resource-burger-el"
              >
                <i class="fas fa-edit" style="padding-right: 5px"></i>
                <span>Add to Calendar</span>
                <i
                  v-if="!gridCalendarDropdownOpen"
                  class="fal fa-angle-down"
                  aria-hidden="true"
                  style="margin-left: 20px; font-size: 23px; position: relative; top: 3px"
                ></i>
                <i
                  v-else
                  class="fal fa-angle-up"
                  aria-hidden="true"
                  style="margin-left: 20px; font-size: 23px; position: relative; top: 3px"
                ></i>
              </span>
              <span
                v-if="gridCalendarDropdownOpen"
                tabindex="0"
                @click="openExternalCalendar('google', item)"
                @keyup.enter="openExternalCalendar('google', item)"
                aria-label="Add metenig into google calendar"
                class="iu-resource-burger-el"
              >
                <img
                  :src="`${assetPath}assets/icons/768px-Google_Calendar_icon_%282020%29.png`"
                  style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                />
                <span>Google Calendar</span>
              </span>
              <span
                v-if="gridCalendarDropdownOpen"
                tabindex="0"
                @click="openExternalCalendar('ics', item)"
                @keyup.enter="openExternalCalendar('ics', item)"
                aria-label="Add appointment into apple calendar"
                class="iu-resource-burger-el"
              >
                <img
                  :src="`${assetPath}assets/icons/apple-512.webp`"
                  style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                />
                <span>Apple Calendar</span>
              </span>
              <span
                v-if="gridCalendarDropdownOpen"
                tabindex="0"
                @click="openExternalCalendar('outlook', item)"
                @keyup.enter="openExternalCalendar('outlook', item)"
                aria-label="Add appointment into outlook calendar"
                class="iu-resource-burger-el"
              >
                <img
                  :src="`${assetPath}assets/icons/Outlook.com_icon.png`"
                  style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                />
                <span>Outlook Calendar</span>
              </span>
              <span
                v-if="gridCalendarDropdownOpen"
                tabindex="0"
                @click="openExternalCalendar('yahoo', item)"
                @keyup.enter="openExternalCalendar('yahoo', item)"
                aria-label="Add appointment into yahoo calendar"
                class="iu-resource-burger-el"
                style="margin-bottom: 11px"
              >
                <img
                  :src="`${assetPath}assets/icons/yahoo.png`"
                  style="height: 20px; width: 20px; margin-right: 5px; position: relative; top: 4px"
                />
                <span>Yahoo Calendar</span>
              </span>
              <span
                tabindex="0"
                v-if="item.chosen_mentor.id != loggedUserId"
                :aria-label="
                  !ids_favorite_mentors.includes(item.chosen_mentor.id)
                    ? `Add ${item.chosen_mentor_fullname} in favorite mentors`
                    : `Remove ${item.chosen_mentor_fullname} from favorite mentors`
                "
                @click="handleFavoritesMentors(item.chosen_mentor.id)"
                @keyup.enter="handleFavoritesMentors(item.chosen_mentor.id)"
                role="button"
                class="iu-resource-burger-el"
              >
                <i class="fad fa-bookmark" style="padding-right: 5px"></i>
                <span v-if="!ids_favorite_mentors.includes(item.chosen_mentor.id)">Add Mentor to Favorites</span>
                <span v-else> Remove Mentor from Favorites </span>
              </span>
              <span
                tabindex="0"
                v-if="showCalendarAndCancelCheck(item.status)"
                @click="openCancelationModal(item)"
                @keyup.enter="openCancelationModal(item)"
                aria-label="Open appointment cancelation dialog"
                aria-haspopup="dialog"
                role="button"
                class="iu-resource-burger-el"
              >
                <i class="fas fa-times" style="padding-right: 5px"></i>
                <span>Cancel Appointment</span>
              </span>
            </div>
          </span>
        </div>
        <div class="iu-meeting-card-lower-section">
          <div class="iu-meeting-card-image-container">
            <img
              :src="item.is_mentor ? item.requester_obj.profile_pic : item.chosen_mentor.profile_pic"
              alt="profile picture"
              class="meeting-card-profile-pic"
            />
          </div>
          <div class="iu-meeting-card-mentor-and-status-info-container slim-scrollbar-horizontal">
            <div class="iu-meeting-card-mentor-name-container">
              <i class="fas fa-user"></i>
              <span v-if="item.is_mentor" class="info-text">{{ item.requester }}</span>
              <span v-else class="info-text"
                ><span style="font-weight: 600">{{ item.chosen_mentor_fullname }}</span>
                <span v-if="!isPartnerTenant"> • {{ item.chosen_mentor.department.name }}</span></span
              >
            </div>
            <div>
              <span v-if="getStatusText(item.status) === 'Active'" class="eu-meeting-card-status" style="padding: 0">
                <a
                  v-if="item.meeting_type != 'inperson'"
                  target="_blank"
                  :href="hasVideoChat() ? `/meetings/${item.meeting_type}/${item.id}` : item.meeting_url"
                >
                  <button class="da-components-button da-btn-solid eu-meetings-modal-btn">
                    Go to meeting
                    <i class="fas fa-chevron-right right-chevron-btn" aria-hidden="true"></i>
                  </button>
                </a>
                <button v-else class="da-components-button da-btn-solid eu-meetings-modal-btn" style="pointer-events: none; background: #685df3">
                  In-person meeting
                </button>
              </span>
              <span
                v-else-if="getStatusText(item.status) === 'Action Required'"
                ref="action_required_button"
                class="eu-meeting-card-status"
                :class="item.status"
                @click="openActionRequiredDropdown(item.id)"
              >
                <i :class="icons[item.status]"></i>
                {{ getStatusText(item.status) }} <i class="fa-solid fa-caret-down iu-dropdown-icon"></i>
                <div
                  ref="action_required_dropdown"
                  v-closable="{
                    exclude: ['action_required_button', 'action_required_dropdown'],
                    handler: 'closeActionRequiredDropdown',
                  }"
                  :id="'action_required_dropdown' + item.id"
                  class="iu-resource-burger"
                  :class="openedActionRequiredDropdown == item.id ? 'iu-resource-burger-active' : ''"
                  style="bottom: 100%"
                >
                  <span
                    tabindex="0"
                    class="iu-resource-burger-mentor-el accept"
                    @click="acceptMeeting(item)"
                    @keyup.enter="acceptMeeting(item)"
                    aria-label="Accept appointment"
                    role="button"
                  >
                    <i class="fas fa-check-circle" style="padding-right: 5px"></i>
                    <span>Accept</span>
                  </span>
                  <span
                    tabindex="0"
                    class="iu-resource-burger-mentor-el deny"
                    @click="denyMeeting(item)"
                    @keyup.enter="denyMeeting(item)"
                    aria-label="Deny appointment"
                    role="button"
                  >
                    <i class="fas fa-times-circle" style="padding-right: 5px"></i>
                    <span>Deny</span>
                  </span>
                </div>
              </span>
              <span v-else class="eu-meeting-card-status" :class="item.status">
                <i :class="icons[item.status]"></i>
                {{ getStatusText(item.status) }}
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>
<script lang="ts">
import handleOutsideClick from "../../../directives/handleOutsideClick";
import "../../utils/DateParser";
import { getStatusText, showCalendarAndCancelCheck } from "../../utils/common";

export default {
  directives: { closable: handleOutsideClick },
  components: {},
  name: "meetings-grid",
  props: [
    "meetings",
    "isPartnerTenant",
    "calendarDropdownOpen",
    "loggedUserId",
    "icons",
    "ids_favorite_mentors",
    "isLoading",
  ],

  data() {
    return {
      assetPath: process.env.MEDIA_URL,
      is_past: false,
      openedDropdown: null,
      openedActionRequiredDropdown: null,
      gridCalendarDropdownOpen: false,
    };
  },

  methods: {
    getStatusText,
    showCalendarAndCancelCheck,
    hasVideoChat() {
      const isMeetingsEnabled: HTMLElement = document.querySelector(".js-has-video-chat-meetings");
      return JSON.parse(isMeetingsEnabled.dataset.hasVideoChatMeetings.toLowerCase());
    },
    openActionRequiredDropdown(id) {
      this.openedActionRequiredDropdown = id;
    },
    closeActionRequiredDropdown() {
      this.openedActionRequiredDropdown = null;
    },
    isActive(item) {
      if (item.status === "past" || item.status === "canceled" || item.status === "expired") {
        return false;
      }
      return true;
    },

    acceptMeeting(item) {
      this.$emit("accept-meeting", item);
    },

    denyMeeting(item) {
      this.$emit("deny-meeting", item);
    },

    getCardClass(is_mentor, status: string) {
      if (status === "past" || status === "canceled" || status === "expired") {
        return "inactive";
      } else if (status === "urgent") {
        return "action-required";
      }

      return "active";
    },

    showBurger: function (i, view) {
      this.is_past = i.status === "past" ? true : false;
      if (this.openedDropdown != i.id) {
        this.openedDropdown = i.id;
        this.gridCalendarDropdownOpen = false;
      }
    },

    closeBurgers: function () {
      this.openedDropdown = null;
      this.gridCalendarDropdownOpen = false;
    },

    openModal: function (i) {
      this.$emit("open-modal", i);
    },

    openFollowUpModal: function (i) {
      this.$emit("open-follow-up-modal", i);
    },

    openCancelationModal: function (i) {
      this.$emit("open-cancelation-modal", i);
    },

    toggleCalendarDropdown: function () {
      this.gridCalendarDropdownOpen = !this.gridCalendarDropdownOpen;
    },

    openExternalCalendar: function (cal, item) {
      this.$emit("open-external-calendar", cal, item);
    },
    archive: function (item) {
      this.$emit("archive-meeting", item);
    },
    handleFavoritesMentors: function (mentor_id) {
      this.$emit("handle-favorite", mentor_id);
    },
  },
};
</script>
