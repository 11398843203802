<template>
  <settings-wrapper activeTab="myInterests">
    <section class="profile-form">
      <div class="profile-form-header-container">
        <h2>Edit My Interests</h2>
      </div>
      <div v-if="isLoading" id="objects-list-loading-spinner">
        <loading-spinner />
      </div>
      <div v-else>
        <div class="multiselect-wrapper" style="position: relative; margin-left: 0; margin-right: 0; width: 100%">
          <multiselect
            v-model="selectedAreas1"
            :options="focus_areas_1"
            :multiple="true"
            label="name"
            track-by="name"
            :close-on-select="false"
            placeholder=""
            :max="5"
          >
          </multiselect>
          <i
            class="fal fa-angle-down eu-select-arrow"
            style="top: 11px !important; right: 21px !important; left: unset !important; position: absolute"
          ></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">{{ FOCUS_AREA_TYPES[focus_areas_types[0]] }}</label>
          </div>
        </div>
        <div class="multiselect-wrapper" style="position: relative; margin-left: 0; margin-right: 0; width: 100%">
          <multiselect
            v-model="selectedAreas2"
            :options="focus_areas_2"
            :multiple="true"
            label="name"
            track-by="name"
            :close-on-select="false"
            placeholder=""
            :max="5"
          >
          </multiselect>
          <i
            class="fal fa-angle-down eu-select-arrow"
            style="top: 11px !important; right: 21px !important; left: unset !important; position: absolute"
          ></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">{{ FOCUS_AREA_TYPES[focus_areas_types[1]] }}</label>
          </div>
        </div>

        <div
          v-if="!isPartnerTenant()"
          class="multiselect-wrapper"
          style="position: relative; margin-left: 0; margin-right: 0; width: 100%"
        >
          <multiselect
            v-model="selectedIdentities"
            :max="5"
            :options="identities"
            :multiple="true"
            label="name"
            track-by="name"
            :close-on-select="false"
            placeholder=""
          >
          </multiselect>
          <i
            class="fal fa-angle-down eu-select-arrow"
            style="top: 11px !important; right: 21px !important; left: unset !important; position: absolute"
          ></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">Identities</label>
          </div>
        </div>

        <div class="multiselect-wrapper" style="position: relative; margin-left: 0; margin-right: 0; width: 100%">
          <multiselect
            v-model="selectedCAI"
            :max="5"
            :options="cai"
            :multiple="true"
            label="name"
            track-by="name"
            :close-on-select="false"
            placeholder=""
          >
          </multiselect>
          <i
            class="fal fa-angle-down eu-select-arrow"
            style="top: 11px !important; right: 21px !important; left: unset !important; position: absolute"
          ></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">Career/Academic Interests</label>
          </div>
        </div>
        <div
          v-if="isPartnerTenant()"
          class="multiselect-wrapper"
          style="position: relative; margin-left: 0; margin-right: 0; width: 100%"
        >
          <multiselect
            v-model="selectedAudience"
            :options="audiences"
            :multiple="false"
            label="name"
            track-by="name"
            :close-on-select="true"
            placeholder=""
          >
          </multiselect>
          <i
            class="fal fa-angle-down eu-select-arrow"
            style="top: 11px !important; right: 21px !important; left: unset !important; position: absolute"
          ></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">Audience Type</label>
          </div>
        </div>

        <div
          v-if="isPartnerTenant() && shouldShowUniPicker()"
          class="multiselect-wrapper"
          style="position: relative; margin-left: 0; margin-right: 0; width: 100%"
        >
          <singleselect-filter-component
            id="uniPick"
            key="uniPick"
            placeholder="Search/enter your school name"
            filter_name="school"
            :options="universities"
            @search-change="institutionOptionsList"
            :selected_status="selectedUni"
            labelProp="institution_name"
            trackByProp="institution_id"
            noOptionsText="Start typing in order to get a list of available schools/institutions"
            @filter-data="changeUniSelection"
            :classicStyle="true"
            additionalStyle="width: 100% !important;"
          />
          <i
            class="fal fa-angle-down eu-select-arrow"
            style="top: 11px !important; right: 21px !important; left: unset !important; position: absolute"
          ></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">School/University</label>
          </div>
        </div>

        <div
          v-if="isPartnerTenant() && shouldShowUniPicker()"
          class="multiselect-wrapper"
          style="position: relative; margin-left: 0; margin-right: 0; width: 100%"
        >
          <singleselect-filter-component
            id="gradYear"
            key="gradYear"
            placeholder="Search your expected graduation year"
            :options="generateYears()"
            :selected_status="selectedGradYear"
            labelProp="year"
            :classicStyle="true"
            trackByProp="year"
            @filter-data="changeGraduationSelection"
            additionalStyle="width: 100% !important;"
          />
          <i
            class="fal fa-angle-down eu-select-arrow"
            style="top: 11px !important; right: 21px !important; left: unset !important; position: absolute"
          ></i>
          <div class="inup-form-label-container">
            <label for="group_private_list">Graduation Year</label>
          </div>
        </div>

        <div v-if="isPartnerTenant() && shouldShowZip()" class="inup-form-element-container">
          <input
            placeholder="ZIP Code (up to 7 characters)"
            type="text"
            class="inup-input"
            style="height: 42px"
            v-model="zipCode"
            @input="validateZipCode"
            title="Enter a valid ZIP code of 4 to 7 alphanumeric characters"
          />
          <div v-if="zipCodeError" class="error">Invalid ZIP code. Please enter 4 to 7 characters.</div>
          <div class="inup-form-label-container">
            <label for="video">ZIP Code</label>
          </div>
        </div>
        <div class="profile-form-button-container">
          <button
            @click="submit"
            @keyup.enter="submit"
            aria-label="Save changes"
            type="submit"
            name="Save"
            class="eu-btn-primary-small"
            style="height: 41px; width: 91px"
            :style="zipCodeError ? `pointer-events: none; opacity: 0.7;` : ''"
          >
            Save
          </button>
        </div>
      </div>
      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </section>
  </settings-wrapper>
</template>
<script lang="ts">
import SettingsService from "../services/SettingsService";
import Notification from "../../components/Notification.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import Multiselect from "vue-multiselect";
import SettingsWrapper from "./SettingsWrapper.vue";
import { isPartnerTenant } from "../../utils/CustomUtils";
import USInstitutionService from "../../users/services/USInstitutionService";
import SingleselectFilterComponent from "../../form/components/SingleselectFilterComponent.vue";

const FOCUS_AREA_TYPES = {
  experiences: "Experiences",
  support: "Support",
  destinations: "Country Interest",
  services_and_experiences: "Services & Experiences",
};

export default {
  components: { Notification, LoadingSpinner, Multiselect, SettingsWrapper, SingleselectFilterComponent},
  name: "edit-interests-index",
  props: [],
  data() {
    return {
      isNotificationActive: false,
      isLoading: true,
      selectedAudience: null,
      selectedAreas1: this.selected_focus_areas_1,
      selectedAreas2: this.selected_focus_areas_2,
      selectedIdentities: this.selected_identities,
      selectedUni: null,
      selectedGradYear: null,
      selectedCAI: this.selected_cai,
      notification: { msg: "", isError: false, icon: null },
      FOCUS_AREA_TYPES,
      zipCode: "",
      zipCodeError: false,
      uniService: new USInstitutionService(),
      universities: [],
    };
  },
  async created() {
    this.service = new SettingsService();
    await this.getPreData();
    this.isLoading = false;
  },
  methods: {
    isPartnerTenant,
    async getPreData() {
      this.isLoading = true;
      const response = await this.service.getInterestsPreData();
      if (response) {
        this.selectedAreas1 = response.selected_focus_areas_1;
        this.selectedAreas2 = response.selected_focus_areas_2;
        this.selectedAudience = response.selected_audience.length > 0 ? response.selected_audience[0] : null;
        this.selectedIdentities = response.selected_identities;
        this.selectedCAI = response.selected_cai;
        this.focus_areas_1 = response.focus_areas_1;
        this.focus_areas_2 = response.focus_areas_2;
        this.audiences = response.audiences;
        this.cai = response.cai;
        this.identities = response.identities;
        this.focus_areas_types = response.focus_areas_types;
        this.selectedUni = response.selected_uni;
        this.selectedGradYear = { year: response.selected_grad_year };
        this.zipCode = response.zip;
      }
      this.isLoading = false;
    },
    async submit() {
      this.isLoading = true;
      const res = await this.service.updateMyInterests(
        {
          focus_areas_1: this.selectedAreas1.map((e) => e.id),
          focus_areas_2: this.selectedAreas2.map((e) => e.id),
          cai: this.selectedCAI.map((e) => e.id),
          identities: this.selectedIdentities.map((e) => e.id),
          audience: this.selectedAudience.id,
          zip: this.shouldShowZip() ? this.zipCode : '',
          school: this.shouldShowUniPicker() ? this.selectedUni.institution_id : '',
          gradYear: this.shouldShowUniPicker() ? (this.selectedGradYear.year ?? this.selectedGradYear ?? '') : ''
        },
        this.headers
      );
      if (res?.success) {
        this.showNotification({ msg: res.message, icon: "fad fa-check-circle" });
      } else {
        this.showNotification({
          msg: res ? res.message : "Server Error",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
      this.isLoading = false;
    },
    shouldShowUniPicker() {
      return [1, 2, 4].includes(this.selectedAudience.id);
    },
    shouldShowZip() {
      return [6].includes(this.selectedAudience.id);
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    validateZipCode() {
      const pattern = /^[a-zA-Z0-9]{4,7}$/;
      this.zipCodeError = !pattern.test(this.zipCode);
    },
    changeUniSelection(value) {
      this.selectedUni = value;
    },
    changeGraduationSelection(value) {
      this.selectedGradYear = value;
    },
    async institutionOptionsList(q) {
      let params: Object = {
        institution_name: q,
      };
      const res = await this.uniService.get(params);
      this.universities = res.schools;
    },
    generateYears() {
      const currentYear = new Date().getFullYear(); // Gets the current year
      const years = [];

      for (let i = 0; i <= 6; i++) {
        years.push({ year: currentYear + i });
      }

      return years;
    },
  },
  computed: {},
};
</script>
