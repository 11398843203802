<template> 
  <section>
          <div tabindex="0" :aria-label="`Question: ${stepTitles[currentStepNo]}`" class="passport-form-title">{{stepTitles[currentStepNo]}}</div>
        <div v-if="currentStepNo === 0">
            <div aria-label="Answer: yes" role="button" tabindex="0" :class="`passport-form-button ${passport.first_generation_student ? 'active' : ''}`" @click="setPassportProp('first_generation_student', true)">Yes</div>
            <div aria-label="Answer: No" role="button" tabindex="0" :class="`passport-form-button ${passport.first_generation_student === false ? 'active' : ''}`" @click="setPassportProp('first_generation_student', false)">No</div>
            <div aria-label="Answer: Not Sure" role="button" tabindex="0" :class="`passport-form-button ${passport.first_generation_student === null && passport.first_generation_student_answered ? 'active' : ''}`" @click="setPassportProp('first_generation_student', null)">Not Sure</div>
        </div>
        <div v-if="currentStepNo === 1">
            <div aria-label="Answer: yes" role="button" tabindex="0" :class="`passport-form-button ${passport.pell_grant_recipient ? 'active' : ''}`" @click="setPassportProp('pell_grant_recipient', true)">Yes</div>
            <div aria-label="Answer: No" tabindex="0" :class="`passport-form-button ${passport.pell_grant_recipient === false ? 'active' : ''}`" @click="setPassportProp('pell_grant_recipient', false)">No</div>
            <div aria-label="Answer: Not Sure" role="button" tabindex="0" :class="`passport-form-button ${passport.pell_grant_recipient === null && passport.pell_grant_recipient_answered ? 'active' : ''}`" @click="setPassportProp('pell_grant_recipient', null)">Not Sure</div>
        </div>
        <div v-if="currentStepNo === 2">
            <select  class="passport-form-input-select" v-model="passport.gender">  
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Non-Binary">Non-Binary</option>
                <option value="Prefer Not To Say">Prefer Not To Say</option>
            </select>
        </div>
        <div v-if="currentStepNo === 3">
            <select  class="passport-form-input-select" v-model="passport.ethnicity">  
                <option value="African-American/Black">African-American/Black</option>
                <option value="Asian">Asian</option>
                <option value="Hispanic/Latino">Hispanic/Latino</option>
                <option value="Indigenous Person">Indigenous Person</option>
                <option value="Middle Eastern">Middle Eastern</option>
                <option value="Native Hawaiian/Pacific Islander">Native Hawaiian/Pacific Islander</option>
                <option value="White/Caucasian">White/Caucasian</option>
                <option value="Two or more races">Two or more races</option>
                <option value="Prefer Not To Say">Prefer Not To Say</option>
            </select>
        </div>
         <div v-if="currentStepNo === 4">
            <div aria-label="Answer: yes" role="button" tabindex="0" :class="`passport-form-button ${passport.differently_abled ? 'active' : ''}`" @click="setPassportProp('differently_abled', true)">Yes</div>
            <div aria-label="Answer: No" role="button"  tabindex="0" :class="`passport-form-button ${passport.differently_abled === false ? 'active' : ''}`" @click="setPassportProp('differently_abled', false)">No</div>
            <div aria-label="Answer: Prefer Not To Say" role="button"  tabindex="0" :class="`passport-form-button ${passport.differently_abled === null && passport.differently_abled_answered ? 'active' : ''}`" @click="setPassportProp('differently_abled', null)">Prefer Not To Say</div>
        </div>
        <div v-if="currentStepNo === 5">
            <section v-if="isBadgeEarned()">
              <div class="passport-form-title">Woohoo! You earned a badge.</div>
              <img class="passport-badge-earned" :src="`${assetPath}badges/background-earned.svg`"/>
            </section>
            <div class="passport-form-label" style="margin-bottom: 30px">Keep filling out sections to earn badges and complete your passport.</div>
            <a v-if="nextStep" :href="`/passport?step=${encodeURIComponent(nextStep)}`" class="da-components-button da-btn-solid enup-demo-button passport-final-btn">Continue with Passport</a>
            <a :href="dashboardLink" class="da-components-button da-btn-solid enup-demo-button passport-final-btn inverted">Quit & Go to Dashboard</a>
        </div>
  </section>
</template>
<script lang="ts">

export default {
  components: {  },
  name: "passport-my-background",
  props: ['passport', 'currentStepNo', 'dashboardLink', 'nextStep'],
  data() {
    return {
      stepTitles: ['Are you a first generation student?', 'Are you a Pell Grant recipient?', 'Select your Gender.', 'Select your Race & Ethnicity.', 'Are you differently abled?'],
      assetPath: process.env.MEDIA_URL,
    };
  },
  created() {
    },
  methods: {
      setPassportProp(propName, value) {
        this.$emit('set-passport-prop', propName, value);
    },
    isBadgeEarned() {
        this.$emit('is-badge-earned');
        return this.passport.badge;
    },
  },
  computed: {},
};
</script>

