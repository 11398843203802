import Vue from 'vue';
import RequestADemo from './RequestADemo.vue';
import VueCompositionAPI from '@vue/composition-api';
import { createPinia, PiniaPlugin } from 'pinia';

Vue.use(VueCompositionAPI);
const pinia = createPinia();
Vue.use(PiniaPlugin);

export default class RequestADemoView {
  protected mountPoint: HTMLElement;

  constructor(el: HTMLElement) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const requestADemoSection = new Vue({
      components: {
        RequestADemo
      },
      pinia,
      template: "<request-a-demo />"
    })
    requestADemoSection.$mount(this.mountPoint);
  }
}
