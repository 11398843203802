<template>
  <div class="dabi-student-dashboard-container">
    <partner-tenant-featured-programs
      v-if="hasPermission(FEATURES.PROGRAMS) && !isSectionHidden('featured-programs')"
      @is-empty="hideSection('featured-programs')"
    ></partner-tenant-featured-programs>
    <section class="partner-tenant-dashboard-cta-section">
      <partner-tenant-dashboard-cta></partner-tenant-dashboard-cta>
    </section>
    <div class="partner-tenant-dashboard-padding-wrapper">
      <partner-tenant-all-features-tabs
        v-if="(hasPermission(FEATURES.EVENTS) || hasPermission(FEATURES.PROGRAMS)) && !isSectionHidden('opportunities')"
        @is-empty="hideSection('opportunities')"
      ></partner-tenant-all-features-tabs>
      <!-- <partner-tenant-overall></partner-tenant-overall> -->
      <partner-tenant-pathways
        v-if="hasPermission(FEATURES.COURSES) && !isSectionHidden('courses')"
        @is-empty="hideSection('courses')"
      ></partner-tenant-pathways>
      <!-- <partner-tenant-guides
        v-if="has_permission('guides') && !isSectionHidden('guides')"
        @is-empty="hideSection('guides')"
      ></partner-tenant-guides> -->
      <partner-tenant-articles-and-resources
        v-if="hasPermission(FEATURES.RESOURCE_LIBRARY) && !isSectionHidden('articles-and-resources')"
        @is-empty="hideSection('articles-and-resources')"
      ></partner-tenant-articles-and-resources>
      <partner-tenant-discover-partners
        v-if="!isSectionHidden('partners')"
        @is-empty="hideSection('partners')"
      ></partner-tenant-discover-partners>
      <partner-tenant-recommended-groups
        v-if="!isSectionHidden('groups')"
        @is-empty="hideSection('groups')"
      ></partner-tenant-recommended-groups>
    </div>
  </div>
</template>
<script>
import PartnerTenantOverall from './components/PartnerTenantOverall.vue';
import PartnerTenantFeaturedPrograms from './components/PartnerTenantFeaturedPrograms.vue';
import PartnerTenantAllFeaturesTabs from './components/PartnerTenantAllFeaturesTabs.vue';
import PartnerTenantArticlesAndResources from './components/PartnerTenantArticlesAndResources.vue';
import PartnerTenantPathways from './components/PartnerTenantPathways.vue';
// import PartnerTenantGuides from './components/PartnerTenantGuides.vue';
import PartnerTenantRecommendedGroups from './components/PartnerTenantRecommendedGroups.vue';
import PartnerTenantDiscoverPartners from './components/PartnerTenantDiscoverPartners.vue';
import PartnerTenantDashboardCta from './components/PartnerTenantDashboardCta.vue';
import { FEATURES, hasPermission } from "../../utils/PermissionUtils";

export default {
  name: 'partner-tenant-dashboard',
  components: {
    PartnerTenantOverall,
    PartnerTenantFeaturedPrograms,
    PartnerTenantAllFeaturesTabs,
    PartnerTenantArticlesAndResources,
    PartnerTenantPathways,
    // PartnerTenantGuides,
    PartnerTenantRecommendedGroups,
    PartnerTenantDiscoverPartners,
    PartnerTenantDashboardCta,
  },
  props: [],
  data() {
    return {
      hiddenSections: [],
      FEATURES: FEATURES
    };
  },
  created() {

  },
  methods: {
    hasPermission,
    hideSection(sectionName) {
      this.hiddenSections.push(sectionName);
    },
    isSectionHidden(sectionName) {
      return this.hiddenSections.includes(sectionName);
    },
  },
  computed: {},
};
</script>
