<template>
<section
  class="iu-navbar-faq-dropdown-container"
>
  <section class="iu-navbar-faq-header-section">
    <div class="iu-navbar-faq-title-container">
      <span v-if="mainTabsActive" class="iu-navbar-faq-title">
        Help & FAQ
      </span>
      <span v-if="contactUsTabActive || thankYouTabActive" class="iu-navbar-faq-title">
        <button
          class="iu-navbar-faq-contact-us-back-button"
          aria-label="Close contact us form. Go back to the FAQ tabs."
          @click="closeContactUsForm"
        >
          <i class="fa-regular fa-arrow-left"></i>
        </button>
        <span>Contact Us</span>
      </span>
      <button
        class="iu-navbar-faq-close-btn"
        @click="closeFaqModal"
        aria-label="Close FAQ modal"
      >
        <i class="fa-solid fa-x iu-navbar-faq-close-icon"></i>
      </button>
    </div>
    <div v-if="mainTabsActive" class="iu-navbar-faq-tab-container">
      <span
        class="iu-navbar-faq-tab-item"
        :class="isTabActive(1) ? 'active' : ''"
        @click="switchMainTab(1)"
        @keyup.enter="switchMainTab(1)"
        aria-label="Open how tos tab"
        tabindex="0"
      >
        How-tos
      </span>
      <span
        class="iu-navbar-faq-tab-item"
        :class="isTabActive(2) ? 'active' : ''"
        @click="switchMainTab(2)"
        @keyup.enter="switchMainTab(2)"
        aria-label="Open features overview tab"
        tabindex="0"
      >
        Features Overview
      </span>
    </div>
  </section>
  <section class="iu-navbar-faq-content-section">
    <ul v-if="mainTabsActive" class="iu-navbar-faq-content-list">
      <li
        v-for="(item, index) in activeList"
        :key="index"
        class="iu-navbar-faq-how-to-item"
      >
        <div class="iu-navbar-faq-how-to-item-title-container">
          <span class="iu-navbar-faq-how-to-item-title-icon" v-html="item.icon"></span>

          <span class="iu-navbar-faq-how-to-item-title-text">{{item.title}}</span>
        </div>
        <div
          v-for="(contentObject, contentListIndex) in item.contentList"
          :key="contentListIndex"
          class="iu-navbar-faq-how-to-item-content-container">
          <div
            class="iu-navbar-faq-how-to-item-content-title-container"
            tabindex="0"
            :aria-label="'Expand item ' + contentObject.contentListItem.contentTitle"
            :aria-controls="'content-' + index + '-' + contentListIndex"
            role="button"

            @click="toggleItem(index, contentListIndex)"
            @keyup.enter="toggleItem(index, contentListIndex)"
          >
            <i
              class="fa-solid iu-navbar-faq-how-to-item-content-arrow-icon"
              :class="isActiveItem(index, contentListIndex) ? 'fa-chevron-up active': 'fa-chevron-down'">
            </i>
            <span
              class="iu-navbar-faq-how-to-item-content-title-text"
              :class="isActiveItem(index, contentListIndex) ? 'active': ''">
              {{contentObject.contentListItem.contentTitle}}
            </span>
          </div>
          <p
            class="iu-navbar-faq-how-to-item-content-text-container"
            v-show="isActiveItem(index, contentListIndex)"
            v-html="contentObject.parsedHtml"
            :aria-hidden="!isActiveItem(index, contentListIndex) ? 'true' : 'false'"
            :id="'content-' + index + '-' + contentListIndex"
          >
          </p>
        </div>
      </li>
    </ul>
    <form v-show="contactUsTabActive" class="iu-navbar-faq-contact-us-content-container">
      <div class="iu-navbar-faq-contact-us-main-text">
        Got questions, feedback, or bug reports? Let us know!
      </div>
      <div class="iu-navbar-faq-contact-us-form-container">
        <div class="iu-navbar-faq-contact-us-form-field-container">
          <label for="contact-us-message" class="iu-navbar-faq-contact-us-form-label">What can we help you with?<span class="required"> *</span></label>
          <textarea v-model="contactUsMessage" class="iu-navbar-faq-contact-us-form-textarea" :class="!isContactUsFormValid ? 'error' : ''"  @focus="isContactUsFormValid = true" name="contact-us-message" id="contact-us-message" cols="30" rows="10" placeholder="Give us as many details as you can."></textarea>
          <span v-if="!isContactUsFormValid" class="iu-navbar-faq-contact-us-form-error-message">This field is required.</span>
        </div>
        <div class="iu-navbar-faq-contact-us-form-field-container" v-if="false">
          <label for="contact-us-message" class="iu-navbar-faq-contact-us-form-label">Attach files (optional)</label>

          <div v-if="uploadingFiles" class="iu-navbar-faq-contact-us-form-file-list-container">
            <div v-for="(file, index) in fileList" :key="index" class="iu-navbar-faq-contact-us-form-single-file-container">
              <i class="iu-navbar-faq-contact-us-form-single-file-icon fa-regular fa-image"></i>
              <div class="iu-navbar-faq-contact-us-form-single-file-name">
                <div class="iu-navbar-faq-contact-us-form-single-file-name-text">{{file.name}}</div>
                <div v-if="!file.is_uploading" class="iu-navbar-faq-contact-us-form-single-file-size-text">{{file.size}} mb</div>
                <div
                    v-if="file.is_uploading"
                    class="eu-line eu-stripesLoaderAnimated"
                    style="width: 100%;"
                >
                </div>
              </div>
              <button v-if="!file.is_uploading" class="iu-navbar-faq-contact-us-form-single-file-delete-button"><i class="fa-solid fa-trash-can"></i></button>
              <button v-if="file.is_uploading" class="iu-navbar-faq-contact-us-form-single-file-cancel-button"><i class="fa-solid fa-x"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="iu-navbar-faq-contact-us-form-submit-container">
        <button
          :class="(isContactUsFormValid && !isMessageSent) ? 'iu-main-btn small solid main' : 'iu-main-btn small solid main disabled'"
          ref="contactUsSubmitButton"
          aria-label="Send us a message"
          @click="sendContactUsMessage"

        >
          Send us a message
        </button>
      </div>
      <span v-if="!isContactUsFormValid" class="iu-navbar-faq-contact-us-form-error-message">Please complete all required fields.</span>
    </form>
    <div class="iu-navbar-faq-thank-you-message-container" v-if="thankYouTabActive">
      <div class="iu-navbar-faq-thank-you-message-title">
        Thank you for getting in touch!
      </div>
      <div class="iu-navbar-faq-thank-you-message-text">
        We are looking into your request and will get back to you as quickly as possible!
      </div>
      <div class="iu-navbar-faq-thank-you-message-signature">
        - The Includifi Team
      </div>
      <div>
        <button
          class="iu-main-btn small outlined gray iu-navbar-faq-thank-you-message-back-button"
          aria-label="Go back to main FAQ section"
          @click="closeContactUsForm"
        >
          <i class="fa-regular fa-arrow-left iu-main-button-front-icon"></i>Back to FAQ
        </button>
        <button
          class="iu-main-btn small outlined gray"
          aria-label="Close FAQ dialog"
          @click="closeFaqModal"
        >
          Close
        </button>
      </div>
    </div>
  </section>
  <section class="iu-navbar-faq-contact-us-section" v-if="contactUsActive && !contactUsTabActive && !thankYouTabActive">
    <div class="iu-navbar-faq-contact-us-section-container">
      <span class="iu-navbar-faq-contact-us-section-text">
        Can't find what you need?
      </span>
      <span
        tabindex="0"
        aria-label="Open contact us form"
        @click="openContactUsForm"
        @keyup.enter="openContactUsForm"
        class="iu-navbar-faq-contact-us-section-link">
        Contact us ✉️
      </span>
    </div>
  </section>
  <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />

</section>
</template>

<script lang="ts">
import ContactUsService from '../../services/ContactUsService';
import Notification from "../../components/Notification.vue"
import * as Cookies from 'js-cookie';
import { HowTosCMSService, FeaturesCMSService } from '../../services/CMSService'


export default {
  name: 'top-navbar-faq',

  components: {
    Notification
  },

  props: ['user_role'],

  data() {
    return {
      howToList: [],
      featureList: [],
      activeList: [],
      contactUsActive: false,
      mainTabsActive: true,
      contactUsTabActive: false,
      thankYouTabActive: false,
      activeMainTabIndex: 1,
      activeItems: [],
      fileList: [
        {
          'name': 'File 1',
          'type': 'IMG',
          'is_uploading': true,
          'size': 234,
        },
        {
          'name': 'File 2',
          'type': 'IMG',
          'is_uploading': false,
          'size': 234,
        },
        {
          'name': 'File 3',
          'type': 'IMG',
          'is_uploading': true,
          'size': 234,
        },
      ],
      isContactUsFormValid: true,
      isMessageSent: false,
      contactUsMessage: "",
      contactUsService: null,

      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },

      howTosService: new HowTosCMSService(),
      featuresService: new FeaturesCMSService(),

      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      }
    }
  },

  async created() {
    this.howTosService.getAll().then(data1 => {
      this.howToList = this.filterListForRole(data1.docs, this.user_role);
      this.syncActiveItemList();
    });
    this.featuresService.getAll().then(data1 => {
      this.featureList = this.filterListForRole(data1.docs, this.user_role);
      this.syncActiveItemList();
    });
    this.contactUsService = new ContactUsService();
    this.contactUsService.getIfContactUsEnabled().then(data => {
      this.contactUsActive = data.contactUsEnabled;
    });
  },

  methods: {
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },

    closeNotification() {
      this.isNotificationActive = false;
    },

    uploadingFile() {
      return this.fileList.length() > 0;
    },

    validateTextarea() {
      if (this.contactUsMessage?.length > 0) {
        this.isContactUsFormValid = true;
      } else {
        this.isContactUsFormValid = false;
      }
    },

    openThankYouTab() {
      this.mainTabsActive = false;
      this.contactUsTabActive = false;
      this.thankYouTabActive = true;
    },

    sendContactUsMessage(event) {
      event.preventDefault();
      event.stopPropagation();
      this.validateTextarea();

      if (!this.isContactUsFormValid) {
        return;
      }

      this.isMessageSent = true;
      this.contactUsService.sendContactUsMessage(this.contactUsMessage, this.headers).then(data => {
        if (data.success) {
          this.isMessageSent = false;
          this.contactUsMessage = "";

          this.openThankYouTab();
        } else {
          const notification = { msg: data.message, isError: true, icon: null };
          this.showNotification(notification);
        }
      });
    },
    filterListForRole(list, role) {
      return list.filter(item => item['restrictions'].includes(role));
    },

    switchMainTab(index) {
      this.activeItems = [];
      this.activeMainTabIndex = index;
      this.syncActiveItemList();
    },

    isTabActive(index) {
      return this.activeMainTabIndex == index;
    },

    syncActiveItemList() {
      if (this.isTabActive(1)) {
        this.activeList = this.howToList;
      }

      if (this.isTabActive(2)) {
        this.activeList = this.featureList;
      }
    },

    closeFaqModal() {
      this.$emit("closeFaqModal");
    },

    getContentIndex(index, contentListIndex) {
      return index.toString() + "." + contentListIndex.toString();
    },

    toggleItem(index, contentListIndex) {
      const id = this.getContentIndex(index, contentListIndex);
      const activeIndex = this.activeItems.indexOf(id);

      if (activeIndex === -1) {
        this.activeItems.push(id);
      } else {
        this.activeItems.splice(activeIndex, 1);
      }
    },

    isActiveItem(index, contentListIndex) {
      const id = this.getContentIndex(index, contentListIndex);
      return this.activeItems.includes(id);
    },

    closeContactUsForm(event) {
      event.stopPropagation();
      this.contactUsTabActive = false;
      this.mainTabsActive = true;
      this.thankYouTabActive = false;

    },

    openContactUsForm(event) {
      event.stopPropagation();
      this.mainTabsActive = false;
      this.contactUsTabActive = true;
      this.thankYouTabActive = false;

    },
  },
}
</script>
