import HttpService from '../../services/HttpService';

export default class RecommendationService extends HttpService {
  constructor() {
    super('');
  }

  getEvents(params): Promise<any>{
    this.endpoint = `/events/api/recommended`;
    return this.get(params);
  }

  getPrograms(params): Promise<any>{
    this.endpoint = `/programs/api/recommended`;
    return this.get(params);
  }

  getGroups(params): Promise<any> {
    this.endpoint = `/groups/api/recommended`;
    return this.get(params);
  }
}
