import HttpService from '../services/HttpService';
import * as Cookies from 'js-cookie';

const _headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class PublicService extends HttpService {
  constructor() {
    super('/');
  }

  requestADemo(form_data_json) {
    this.endpoint = "/request-a-demo";
    return this.post(form_data_json, _headers);
  }

  becomeAPartner(form_data_json) {
    this.endpoint = "become-a-partner";
    return this.post(form_data_json, _headers);
  }
}
