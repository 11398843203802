<template>
  <div class="task-card" @click="showQuestPage" @keyup.enter="showQuestPage" aria-label="Go to quest page" tabindex="0">
    <div class="text-container">
      <div class="title-text text-clamp-1">
        {{ task.name }}
      </div>
      <div class="description-text text-clamp-1">
        {{ task.description }}
      </div>
    </div>
    <div class="status-label-container">
      <div class="status-label" :class="taskStatus">
        <i :class="taskStatusIcon"></i>{{ dueDateFormatted }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
export default {
  name: 'task-card',
  props: ['task'],
  computed: {
    taskStatus() {
      // This should return an string from array ['pending', 'not-following', 'done']
      if (this.task.isCompleted) return 'done';
      if (this.task.isFollowing) return 'pending';
      return 'not-following';
    },
    taskStatusIcon() {
      if (this.taskStatus === 'pending') return 'fa-solid fa-hourglass-half';
      if (this.taskStatus === 'done') return 'fa-solid fa-check-circle';
      if (this.taskStatus === 'not-following') return 'fa-solid fa-triangle-exclamation';

      return 'fa-solid fa-question-mark'
    },
    tooltipText() {
      if (this.taskStatus === 'not-following') {
        return 'There\'s at least one task that should be done before this one.';
      }
      return '';
    },
    dueDateFormatted() {
      if (!this.task.due_date) {
        return 'No time limit';
      }
      return `Due ${moment(new Date(this.task.due_date)).format('D/M/YY')}`;
    }
  },
  methods: {
    showQuestPage() {
      window.location.href = `/quests/questMode/${this.task.quest_id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.task-card {
  width: 100%;
  padding: 16px;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    border-color: #685df3;
  }
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.15;
    color: #444;
  }

  .description-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.2;
    color: #88858e;
  }
}

.status-label-container {
  display: flex;
}

.status-label {
  padding: 1px 6px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 600;
  display: flex;
  gap: 6px;
  align-items: center;
  position: relative;

  i {
    font-size: 11px;
  }

  &.pending {
    background-color: #fff6d2;
    color: #f6764d;
  }

  &.not-following {
    background-color: #d1d1d7;
    color: #555555;
  }

  &.done {
    background-color: #dff6ed;
    color: #45b298;
  }
}
</style>
