<template>
    <Modal :isActiveProp="isModalActive" @close="closeModal">
        <h3 tabindex="0" id="modal_add_or_edit_users" role="heading" v-if="editMode">Edit {{ roleName }}</h3>
        <h3 tabindex="0" id="modal_add_or_edit_users" role="heading" v-else>Manually Add {{ roleName }}</h3>

        <div class="container">
            <span tabindex="0" id="modal_error_valid_data" class="error" v-show="error">Please fill out every field and<br> use a valid email address.</span>

            <div class="eu-input-container">
                <input
                    id="first-name"
                    type="text"
                    class="eu-input"
                    required
                    v-model="firstName"
                >
                <div class="label-container">
                    <label for="first-name">First Name<span> *</span></label>
                </div>
            </div>

            <div class="eu-input-container">
                <input
                    id="last-name"
                    type="text"
                    class="eu-input"
                    required
                    v-model="lastName"
                >
                <div class="label-container">
                    <label for="last-name">Last Name<span> *</span></label>
                </div>
            </div>

            <div class="eu-input-container">
                <input
                    id="email"
                    type="email"
                    class="eu-input"
                    required
                    v-model="email"
                    :disabled="editMode"
                >
                <div class="label-container">
                    <label for="email">Email<span> *</span></label>
                </div>
            </div>

            <div v-if="show_dept_picker" class="field-wrapper hasValue eu-file-upload-modal-field" style="margin: 0 0 20px;">
                <select id="department" class="eu-upload-select" v-model="department" required>
                    <option value=""></option>
                    <option v-for="option in departments" v-bind:value="option.name" v-bind:key="option.id">
                        {{ option.name }}
                     </option>
                </select>
                <i class="fal fa-angle-down eu-select-arrow" style="left: 290px; top: -33px; z-index: 52;"></i>
                <div class="label-container">
                    <label for="email">Department<span> *</span></label>
                </div>
            </div>



            <div class="checkbox-content-container">
                <label class="eu-checkbox-container eu-event-collection-checkmark-label">
                Send a confirmation email
                    <input
                        type="checkbox"
                        id="email"
                        name="email"
                        v-model="sendInvite"
                        style="margin-right: 10px;"
                    >
                    <span class="eu-checkmark"></span>
                </label>
            </div>
        </div>

        <div class="button-container">
            <button
                class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                @click="addUser"
                aria-label="Submit new user."
            >{{editMode ? `Edit ${roleName}` : `Add ${roleName}`}}</button>
            <button
                class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                aria-label="Close modal."
                @click="closeModal"
            >Cancel</button>
        </div>
    </Modal>
</template>
<script lang="ts">
import Modal from '../../../components/ModalExtended.vue';
import { focusElement, isValidEmailString } from '../../../utils/CustomUtils';

export default {
    name: 'manual-upload-modal',

    components: { Modal, },

    props: [
        'isModalActive',
        'editMode',
        'userObject',
        'roles',
        'isRoleChangeable',
        'show_dept_picker',
        'departments'
    ],

    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',

            selectedRole: null,
            sendInvite: true,
            error: false,
            department: null,
        }
    },

    created() {
        if (!this.isRoleChangeable) {
            this.selectedRole = this.roles[0].name;
        }
        if(!this.show_dept_picker){
            this.department = this.departments[0].name;
        }
    },

    computed: {
        roleName(): string {
            if (this.isRoleChangeable) {
                return `User`;
            }

            return `${this.roles[0].name}`;
        }
    },

    watch: {
        userObject() {
            if (this.userObject) {
                this.firstName = this.userObject.first_name;
                this.lastName = this.userObject.last_name;
                this.email = this.userObject.email;
                // need to preselect role as well
            }
        }
    },

    methods: {
        addUser() {
            this.checkInput();

            if (this.error) {
                focusElement("modal_error_valid_data");
                return;
            }

            this.$emit(
                'add-manual',
                {
                    userObject: {
                        first_name: this.firstName,
                        last_name: this.lastName,
                        email: this.email,
                        role: this.selectedRole.toUpperCase(),
                        department: this.department
                    },
                    sendInvite: this.sendInvite,
                }
            )

            this.closeModal();
        },

        checkInput() {
            this.error = false;

            if (!this.firstName) {
                this.elemntClassManagment('first-name');
            } else {
                this.elemntClassManagment('first-name', true);
            }

            if (!this.lastName) {
                this.elemntClassManagment('last-name');
            } else {
                this.elemntClassManagment('last-name', true);
            }

            if (!this.validateEmail(this.email)) {
                this.elemntClassManagment('email');
            } else {
                this.elemntClassManagment('email', true);
            }

            if(this.show_dept_picker){
                if (!this.department) {
                    this.elemntClassManagment('department');
                } else {
                    this.elemntClassManagment('department', true);
                }
            }
        },

        elemntClassManagment(elementId:string, remove=false){
            const el = document.getElementById(elementId);
            if(!remove){
                el.classList.add('error-border');
                this.error = true;
                return
            }
                el.classList.remove('error-border');
        },


        validateEmail(email: string) {
            return isValidEmailString(email, 'accounts');
        },

        closeModal() {
            this.firstName = '';
            this.lastName = '';
            this.email = '';

            this.error = false;

            const fnEl = document.getElementById('first-name');
            fnEl.style.setProperty("border-color", "#949494");
            const lnEl = document.getElementById('last-name');
            lnEl.style.setProperty("border-color", "#949494");
            const emEl = document.getElementById('email');
            emEl.style.setProperty("border-color", "#949494");
            focusElement("user-import-button");
            this.$emit('close-modal');
        },
    },
}
</script>
<style scoped>
h3 {
    align-self: flex-start;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 30px;
    color: #666666;
    font-size: 25px;
    font-weight: 500;
}

.container {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 50px;
    margin-right: 50px;
}

.eu-input-container {
    position: relative;
    margin-top: 21px;
    margin-bottom: 31px;
}

.eu-input-container.last {
    margin-bottom: 20px;
}

.eu-input {
    border: 3px solid #949494;
    padding-left: 10px;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    outline: none;
    color: #707070;
    font-size: 16px;
}

.eu-input:focus {
    border: solid 6px #d0c2ff;
}

 .label-container {
    position: absolute;
    bottom: 39px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    left: 8px;
    padding: 0 8px;
    -webkit-transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
    transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
    z-index: 2;
    width: 100%;
}

.label-container label {
    font-size: 13px;
    font-weight: 500;
    color:  #4c55a7;
    background: #ffffff;
    padding: 0px 6px 0px 10px;
    text-align: left;
}

.label-container span {
    font-weight: 600;
    color: #E65722;
    padding: revert;
}

.button-container {
    margin-bottom: 30px;
}

.checkbox-content-container {
    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;
    margin-bottom: 21px;
    max-height: fit-content;
}

.error {
    color: #E65722;
}

.error-border {
    border-color: #E65722 !important;
}
</style>
