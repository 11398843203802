<template>
<div>
<section class="partner-tenant-partners-hero-section">
  <div class="tint"></div>
  <div class="partner-tenant-partners-hero-content-container">
    <h2 class="partner-tenant-partners-hero-title">
      Partner with Us
    </h2>
    <p class="partner-tenant-partners-hero-description">
      Provide the Best Learning Experience for Students and Professionals
    </p>
  </div>
</section>
<breadcrumbs
  :routeItems="breadcrumbsLinkItems"
  currentPageLabel="Partners"
/>
<!-- <section class="partner-tenant-partners-partners-section">
  <div class="partner-tenant-partners-partners-content-container">
    <h3 class="partner-tenant-partners-partners-title">
      Learn About Our Partners
    </h3>
    <div class="partner-tenant-partners-partners-logos-container">
      <span class="partner-tenant-partners-partners-logo-container">
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/daad-logo.png"
          class="partner-tenant-partners-partners-logo"
        />
      </span>
      <span class="partner-tenant-partners-partners-logo-container">
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/london-met-logo.png"
          class="partner-tenant-partners-partners-logo"
        />
      </span>
      <span class="partner-tenant-partners-partners-logo-container">
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/ies-logo.png"
          class="partner-tenant-partners-partners-logo"
        />
      </span>
    </div>
  </div>
</section> -->
<section class="partner-tenant-partners-impact-section">
  <div class="partner-tenant-partners-impact-content-container">
    <div class="partner-tenant-partners-impact-title-container">
      <h3 class="partner-tenant-partners-impact-title">
        Let’s Create Impact Together
      </h3>
      <p class="partner-tenant-partners-impact-description">
        Provide the best inclusive learning experience.
        Learn more about ways to work with us and deliver
        maximum impact.
      </p>
    </div>
    <div class="partner-tenant-partners-impact-details-wrapper">
      <div class="partner-tenant-partners-impact-image-container">
        <img
          :src="partnerDetailsContentList[expandedDetailsTab === -1 ? 0 : expandedDetailsTab].image"
          alt=""
          class="partner-tenant-partners-impact-details-image"
        >
      </div>
      <div class="partner-tenant-partners-impact-details-container">
        <div
          class="partner-tenant-partners-impact-details-tab"
          v-for="item, index in partnerDetailsContentList"
          :key="index"
        >
          <div class="partner-tenant-partners-impact-details-title-container">
            <img :src="item.icon" alt="" class="partner-tenant-partners-impact-details-icon">
            <div class="partner-tenant-partners-impact-details-title">
              {{item.title}}
            </div>
            <button
              v-if="expandedDetailsTab === index"
              @click="closeDetailsTab()"
              :aria-label="`Collapse ${item.title} tab`"
              class="partner-tenant-partners-impact-details-button"
            >
              <i class="fa-solid fa-minus"></i>
            </button>
            <button
              v-else
              @click="openDetailsTab(index)"
              :aria-label="`Expand ${item.title} tab`"
              class="partner-tenant-partners-impact-details-button"
            >
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
          <div
            :id="`tab-${index}`"
            class="partner-tenant-partners-impact-details-item-content-container"
          >
            <p  class="partner-tenant-partners-impact-details-description">
              {{item.description}}
            </p>
            <img :src="item.imageMobile" alt="" class="partner-tenant-partners-impact-details-image mobile">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="partner-tenant-partners-testimonials-section">
  <div class="partner-tenant-partners-testimonials-content-container">
    <h1 class="partner-tenant-partners-testimonials-title">
      What Our Partners Are Saying
    </h1>
    <div class="partner-tenant-partners-testimonials-description-wrapper">
      <div class="partner-tenant-partners-testimonials-description-text-container">
        <slider-dots
          :totalNo="2"
          :initialSelectedNo="1"
          @changeSelected="changeTestimonial"
          :switchSeconds="7"
        />
        <div class="partner-tenant-partners-testimonials-description-text-wrapper">
          <p class="partner-tenant-partners-testimonials-description-text-strong">
           “{{testimonialSteps[testimonialStep-1]['heading']}}
          </p>
          <p class="partner-tenant-partners-testimonials-description-text">
            {{testimonialSteps[testimonialStep-1]['rest']}}”
          </p>
        </div>
      </div>
      <div class="partner-tenant-partners-testimonials-image-container" :style="`background-image: url(${testimonialSteps[testimonialStep-1]['logo']});`">

      </div>
    </div>
  </div>
</section>
<section class="partner-tenant-partners-cta-section">
  <div class="partner-tenant-partners-cta-content-container">
    <img
      src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Graphic-Diversity-Abroad-Membership.webp"
      class="partner-tenant-partners-cta-image-container"
    >
    <div class="partner-tenant-partners-cta-description-container">
      <p class="partner-tenant-partners-cta-description">
        Learn more about membership with our partner
        organization, BeGlobalii, the leading
        consortium advancing student success through
        equitable access to global learning opportunities.
      </p>
      <div>
        <button
          class="partner-tenant-partners-cta-button"
          @click="redirectTo('https://www.diversityabroad.org/Membership-Community', true)"
          aria-label="Redirect to DiversityAbroad site."
        >
          Let's Go
          <i class="fa-solid fa-arrow-up-right-from-square"></i>
        </button>
      </div>
    </div>
  </div>
</section>
<partner-tenant-request-demo-section></partner-tenant-request-demo-section>
</div>
</template>

<script>
import PartnerTenantRequestDemoSection from './components/PartnerTenantRequestDemoSection.vue';
import SliderDots from './components/SliderDots.vue';
import Breadcrumbs from './components/Breadcrumbs.vue'
export default {
  name: 'partner-tenant-partners',
  components: {
    PartnerTenantRequestDemoSection,
    SliderDots,
    Breadcrumbs
  },
  data() {
    return {
      testimonialStep: 1,
      testimonialSteps: [
        {
          heading: "Partnering with BeGlobalii has been a hugely positive experience for us.",
          rest: "Our partnership has given us the opportunity to showcase the welcoming and inclusive nature of study abroad in Ireland through the authentic testimony of alumni who shared their unfiltered reflections, thoughts, and perspectives on their experiences on the island.",
          logo: `${process.env.MEDIA_URL}assets/education-in-ireland-thumbnail.png`,

        },
        { heading: "Our partnership with BeGlobalii is important not only for student recruitment, but also to enhance inclusive support and expand accessibility.",
          rest: "IES Abroad partners with BeGlobalii to expand and support identity-based resources for our students and their families and to expand the IES Abroad Initiative to Diversify Study Abroad (IDEA), founded in 2008.",
          logo: `${process.env.MEDIA_URL}assets/ies-logo.png`,
        }
      ],
      breadcrumbsLinkItems: [
        {
          'label': 'Home',
          'path': '/partner/home',
          'isSpa': true
        }
      ],
      partnerDetailsContentList: [
        {
          'title': 'Sponsor an Educational Event',
          'description' : 'Offline and online, BeGlobalii\
                          connects students and young people\
                          to international opportunities. Our\
                          virtual and in-person events introduce\
                          hundreds of students and connect young\
                          people where they are.',
          'icon': 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Icon-Sponsor-Educational-Event.webp',
          'image': 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Graphic-Sponsor-Educational-Event.webp',
          'imageMobile': 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Graphic-Sponsor-Educational-Event-Mobile.webp'
         },
         {
          'title': 'Feature Your Opportunities',
          'description' : 'Navigating the wide range of global education and career opportunities\
                          available can be overwhelming. BeGlobalii houses a powerful directory that\
                          connects our student community directly with scholarships, global study,\
                          and career opportunities, as well as graduate school programs from our partners.\
                          Opportunities in our directories are only available to our partners, allowing students\
                          and families to select opportunities from institutions and organizations that have a clear\
                          commitment to diversity, equity, and inclusion.',
          'icon': 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Icon-Feature-Opportunities.webp',
          'image': 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Graphic-Feature-Opportunities.webp',
          'imageMobile': 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Graphic-Feature-Opportunities-Mobile.webp'
         },
         {
          'title': 'Sponsor Digital Resources',
          'description' : 'We have over a decade of promising practices,\
                          knowledge and authenticity to the experiences\
                          of underrepresented students and young people.\
                          We create content that engages and guides our\
                          community through the international education\
                          process from program exploration to leveraging\
                          global experiences for career development. We\
                          highly value the trust and rapport that we have\
                          developed with our community and partner with\
                          select organizations that can contribute to\
                          resources such as our Destination Guides,\
                          student stories and articles and more.',
          'icon': 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Icon-Sponsor-Resources.webp',
          'image': 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Graphic-Sponsor-Digital-Resources.webp',
          'imageMobile': 'https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Graphic-Sponsor-Digital-Resources-Mobile.webp'
         }
      ],
      expandedDetailsTab: -1,
    }
  },
  methods: {
    redirectTo(url, newTab=false) {
      newTab ? window.open(url, '_blank') : window.location.href = url;
    },
    animateDetailsTab(index, expanding=true) {
      const element = document.getElementById(`tab-${index}`);

      if (expanding) {
        const contentHeight = element.scrollHeight;
        element.style.maxHeight = contentHeight + "px";
      } else {
        element.style.maxHeight = 0;
      }
    },
    changeTestimonial(stepNo) {
      this.testimonialStep = stepNo;
    },
    openDetailsTab(index) {
      if (this.expandedDetailsTab !== -1) {
        this.animateDetailsTab(this.expandedDetailsTab, false);
      }
      this.expandedDetailsTab = index;
      this.animateDetailsTab(index, true);
    },
    closeDetailsTab() {
      this.animateDetailsTab(this.expandedDetailsTab, false);
      this.expandedDetailsTab = -1;
    }
  }
}
</script>
