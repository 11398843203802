<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <div class="partner-accounts-content-wrapper">
        <div class="title-wrapper">
          <common-title title="Partners" description="Browse content from other partner accounts." />
        </div>
        <div class="filter-section mobile-full-w">
          <div class="search-container">
            <img
              src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/globalii-partners-landing-hero.jpg"
              class="image"
              alt=""
            />
            <div class="tint"></div>
            <div class="text-container">
              <h3 class="title">Search partners</h3>
              <p class="subtitle">Browse our partners’ diverse offerings and uncover exciting opportunities</p>
            </div>
            <div class="search-wrapper">
              <search-base
                ref="searchPartners"
                id="search-partners"
                placeholder="Search partners"
                aria-label="Search partners by name"
                @search="search"
              ></search-base>
            </div>
          </div>
        </div>

        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>
        <div v-else-if="data.length > 0" class="partner-cards-container">
          <partner-card-component
            v-for="(item, index) in data"
            :key="index"
            :item="item"
            :fallback_image="background_img"
            @toggle-follow="toggleFollowing(item)"
          />
        </div>
        <div v-else>
          <div class="eu-empty-container" :class="isPartnerTenant ? 'partner' : ''" v-if="data.length === 0">
            <div class="eu-empty">
              <div class="eu-empty-icon-container" :class="isPartnerTenant ? 'partner' : ''">
                <i class="fad fa-bullhorn eu-empty-icon" :class="isPartnerTenant ? 'partner' : ''"></i>
              </div>
              <div style="display: flex; flex-direction: column">
                <span class="eu-empty-text">
                  <span style="font-weight: 600">No data/results.</span>
                  <br />
                  Check back later!
                </span>
              </div>
            </div>
          </div>
        </div>
        <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Cookies from "js-cookie";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import PartnerAccountService from "../../partneraccounts/services/PartnerAccountService";
import FeedService from "../../feed/services/FeedService";
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import PartnerCardComponent from "./PartnerCardComponent.vue";
import Notification from "../../components/Notification.vue";
import SearchBase from "../../components/formBase/SearchBase.vue";
import { isPartnerTenant } from "../../utils/CustomUtils";
import CommonTitle from "../../components/CommonTitle.vue";

export default {
  name: "partners-index",

  components: {
    CommonTitle,
    LoadingSpinner,
    MultiselectFilterComponent,
    PartnerCardComponent,
    Notification,
    SearchBase,
  },

  data() {
    return {
      data: [],
      assetPath: process.env.MEDIA_URL,
      isLoading: true,
      search_input: "",
      pageCount: 1,
      partnerService: new PartnerAccountService(),
      feedService: new FeedService(),
      notification: { msg: "", isError: false, icon: null },
      isNotificationActive: false,
      background_img: null,

      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },

  async created() {
    const res = await this.partnerService.getPredata();
    this.background_img = res.background_img;
    this.fetchData();
    this.scroll();
  },

  methods: {
    isPartnerTenant,
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    search(input) {
      this.search_input = input;
      this.fetchData();
    },
    async fetchData() {
      this.pageCount = 1;
      this.isLoading = true;
      this.data = await this.partnerService.getAllPartners({ q: encodeURIComponent(this.search_input) });
      this.isLoading = false;
    },
    async toggleFollowing(partner) {
      const res = await this.feedService.followPartnerFeed(
        { isFollowing: !partner.is_following, partner: partner.name },
        this.headers
      );
      if (res?.success) {
        if (partner.is_following) {
          partner.follower_count -= 1;
        } else {
          partner.follower_count += 1;
        }
        partner.is_following = !partner.is_following;
      }
    },

    async scroll() {
      window.onscroll = async () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;

        if (bottomOfWindow && this.pageCount !== 0) {
          this.pageCount += 1;
          const newData = await this.partnerService.getAllPartners({ q: this.search_input, page: this.pageCount });
          if (newData.length > 0) {
            this.data = [...this.data, ...newData];
          } else this.pageCount = 0;
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  padding: 24px 0;
}
.partner-accounts-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.partner-accounts-content-wrapper {
  width: 100%;
  max-width: 1400px;
}
.partner-cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
}

@media screen and (max-width: 1250px) {
  .partner-cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .partner-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .partner-cards-container {
    display: flex;
    overflow: auto;
    padding: 0 1.5rem;
  }
}

.search-wrapper {
  width: 100%;
}
.filter-section {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1.5rem 0 2rem;

  .search-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    padding: 3rem 5rem;
    border-radius: 24px;
    position: relative;
    overflow: hidden;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    text-align: center;
    position: relative;

    .title {
      font-size: 2.5rem;
      line-height: 1.2;
      padding: 0;
      color: #fff;
    }

    .subtitle {
      font-size: 1.25rem;
      line-height: 1.35;
      color: #fff;
    }
  }
}

@media screen and (max-width: 576px) {
  .partner-accounts-section {
    padding: 24px 0;
  }
  .filter-section {
    margin: 1.5rem 0 0.75rem;

    .search-container {
      gap: 1.5rem;
      padding: 3.5rem 1.5rem;
      border-radius: 0;
    }

    .text-container {
      .title {
        font-size: 2rem;
      }

      .subtitle {
        font-size: 1.125rem;
        line-height: 1;
      }
    }
  }
}
</style>
