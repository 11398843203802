<template>
  <div class='multiselect-custom-wrapper'>
    <multiselect
      v-model="selected"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      label="name"
      track-by="name"
      placeholder=""
      :max="limit"
      @input="handleMultiselectChange"
    ></multiselect>
    <i class="fas fa-chevron-down multiselect-chevron"></i>
  </div>
</template>
<script lang="ts">
import Multiselect from 'vue-multiselect';

export default {
  name: "multiselect-component",

  components: { Multiselect },

  props: ['input', 'options', 'preselected', 'name', 'limit'],

  data() {
    return {
      selected: null,
    };
  },

  created() {
    this.selected = this.preselected;
    if(this.input)
      this.input.value = JSON.stringify(this.selected);
  },

  methods: {
    handleMultiselectChange() {
      this.input? this.input.value = JSON.stringify(this.selected): this.$emit('handle-change', this.selected, this.name);
    },
  },
}
</script>
<style scoped>

.multiselect-custom-wrapper {
  cursor: pointer;
  position:relative;
}

.multiselect-chevron {
  position: absolute;
  right: 16px;
  top: 16px;
  color: #5E50B5;
  pointer-events: none;
  z-index: 2;
}
</style>
