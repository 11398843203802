import Vue from "vue";

Vue.filter("unixFormat", function (timestamp: number) {

    let date = new Date(timestamp);

    let hours = date.getHours();
    let minutes = "0" + date.getMinutes();

    let formattedTime = hours + ':' + minutes.substr(-2);

    return formattedTime;
});