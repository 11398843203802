<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <h1 class="eu-simple-title">Pathway Modules</h1>
      <div class="eu-simple-subtitle">All courses will appear here</div>
      <section>
        <nav class="eu-simple-navbar">
          <span
            tabindex="0"
            @click="$router.push('/courses')"
            @keyup.enter="$router.push('/courses')"
            class="eu-simple-navbar-item"
            aria-label="Go to courses pages"
          >
            Browse Courses
          </span>
          <span
            tabindex="0"
            class="eu-simple-navbar-item eu-simple-navbar-item-active"
            aria-label="Go to manage courses page"
          >
            Manage Courses</span
          >
          <span
            tabindex="0"
            @click="$router.push('/courses?page=saved')"
            @keyup.enter="$router.push('/courses?page=saved')"
            class="eu-simple-navbar-item"
            aria-label="Go to your saved courses"
            >Saved Courses
          </span>
        </nav>
        <search-component placeholder="Search courses" :displaySettings="false" @search-data="filterData" />
        <div class="filter-container">
          <multiselect-filter-component
            placeholder="Any Focus Area"
            filter_name="Focus Areas"
            param_name="focus_areas"
            :options="focus_areas"
            :limit="5"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            placeholder="Any Identity"
            filter_name="Identities"
            param_name="identities"
            :options="identities"
            :limit="5"
            @filter-data="createFilterData"
          />
        </div>
        <div
          class="table-container manage-event-program-wrapper"
          style="width: 100%; overflow-y: hidden; overflow-x: auto; margin: 30px 0"
        >
          <table v-if="!isLoading" class="eu-resource-table">
            <tr class="eu-resource-row">
              <th class="eu-resource-cell eu-resource-cell-th" scope="col">
                <span> Name </span>
              </th>
              <th class="eu-resource-cell eu-resource-cell-th" scope="col">
                <span>Status </span>
              </th>
              <th class="eu-resource-cell eu-resource-cell-th" scope="col"></th>
            </tr>
            <tr v-for="item of courses" :key="item.id" class="eu-resource-row">
              <td class="eu-resource-cell">
                <span>{{ item.name }}</span>
              </td>

              <td class="eu-resource-cell">
                <span v-if="item.is_private" class="mark-ended">
                  <i class="fa-solid fa-lock-keyhole"></i>
                  Private
                </span>
                <span v-else class="mark-published">
                  <i class="fas fa-calendar-check"></i>
                  Public
                </span>
              </td>
              <td class="eu-resource-cell eu-resource-cell-dots-icon">
                <span
                  tabindex="0"
                  ref="resListBurger"
                  :id="`resource_burger_list_${item.id}`"
                  @click="showBurger(item)"
                  @keyup.enter="showBurger(item)"
                  aria-label="Burger menu"
                  arole="menu"
                  aria-haspopup="menu"
                >
                  <i class="fas fa-ellipsis-v"></i>
                </span>
              </td>
              <div ref="resListBurgerDropdown" :id="`r${item.id}`" class="eu-resource-burger">
                <router-link
                  :to="`/courses/${item.id}`"
                  :aria-label="`Go to ${item.name} courses`"
                  class="eu-resource-burger-el"
                >
                  <i class="fas fa-eye" style="padding-right: 5px"></i>
                  <span>View</span>
                </router-link>
                <router-link
                  :to="`/courses/edit/${item.id}`"
                  :aria-label="`Go to ${item.name} edit page`"
                  class="eu-resource-burger-el"
                >
                  <i class="fas fa-edit" style="padding-right: 5px"></i>
                  <span>Edit</span>
                </router-link>
              </div>
            </tr>
          </table>
        </div>
        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SearchComponent from "../../components/search/SearchComponent.vue";
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import CoursesService from "../services/CoursesService";

export default {
  components: {
    SearchComponent,
    MultiselectFilterComponent,
    LoadingSpinner,
  },
  name: "manage-courses",
  props: [],
  data() {
    return {
      service: new CoursesService(),
      courses: [],
      search_input: "",
      isLoading: true,
      identities: [],
      focus_areas: [],
      params: {
        focus_areas: [],
        identities: [],
      },
      lastOpenedBurger: null,
    };
  },
  async created() {
    await this.fetchFilterData();
    this.courses = await this.service.getCourses();
    this.isLoading = false;
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.courses = await this.service.getCourses({ q: this.search_input, ...this.params });
      this.isLoading = false;
    },
    async fetchFilterData() {
      this.isLoading = true;
      const res = await this.service.getPredataCoursesEdit();
      if (res) {
        this.focus_areas = res.focus_areas;
        this.identities = res.identities;
      }
      this.isLoading = false;
    },
    createFilterData(value, name, param_name) {
      this.params[param_name] = value.map((v) => encodeURIComponent(v.name));
      this.fetchData();
    },
    filterData(value) {
      this.search_input = value;
      this.fetchData();
    },
    showBurger(el) {
      const burgerEl = document.querySelector(`#r${el.id}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl) {
        this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
      }
      burgerEl.classList.toggle("eu-resource-burger-active");
      this.lastOpenedBurger = burgerEl;
    },
  },
};
</script>

<style lang="sss" scoped>
</style>