<template>
  <section class="one-stop-section"
    style="display:inherit;"
  >
    <div class="one-stop-section-header">
      <h2>Mentors</h2>

      <router-link to="/meetings/?tab=favorites" aria-label="Go to your saved mentors." class="iu-main-btn main text-only small" style="white-space: nowrap;">See All<i class="fa-regular fa-chevron-right iu-main-button-arrow-icon"></i></router-link>
    </div>
    <div v-if="!isLoading" class="overflow-mobile">
      <browse-saved-mentors
        :mentors="mentors"
        @remove-bookmark="removeBookmark"
      />
    </div>
  </section>
</template>

<script lang="ts">
import Cookies from 'js-cookie';
import BookmarkService from "../services/BookmarkService";
import BrowseSavedMentors from "./BrowseSavedMentors.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue"

export default {
  name: "interface-saved-mentors",

  components: {
    BrowseSavedMentors,
    LoadingSpinner,
  },

  data() {
    return {
      mentors: [],
      isLoading: true,

      service: new BookmarkService(),
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      }
    }
  },

  created() {
    this.getSavedMentors();
  },

  methods: {
    async getSavedMentors() {
      this.isLoading = true;
      const result = await this.service.getBookmarkedMentors({
        limit: 3,
      });
      this.mentors = result;
      if (this.mentors.length === 0) {
        this.$emit('empty');
      }
      this.isLoading = false;
    },

    async removeBookmark(id) {
      await this.service.deleteBookmarkedMentors({
        target: [id]
      }, this.headers);
      this.getSavedMentors();
    },
  }
}
</script>
