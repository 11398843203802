import Vue from 'vue';
import * as Cookies from 'js-cookie';
import LicenseService from './services/LicenseService';
import { Datetime } from 'vue-datetime';
import CoursesService from '../courses/services/CoursesService'
import GuidesService from '../guides/services/GuidesService';
import Notification from '../components/Notification.vue'
import SeedDataService from './services/SeedDataService';

export default class LicenseManager {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected appData: any;
  protected service: LicenseService;
  protected isPartner: boolean;
  protected courseService: CoursesService;
  protected schema: string;
  protected seedService: SeedDataService;
  protected guidesService: GuidesService;

  constructor(el: HTMLElement, appData: any, schema:string) {
    this.csrfToken = Cookies.get('csrftoken');
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.appData = appData;
    this.schema = schema;
    this.service = new LicenseService();
    this.courseService = new CoursesService();
    this.guidesService = new GuidesService();
    this.seedService = new SeedDataService();
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;

    const customHeaders = {
      'Accept': 'application/json',
      'X-CSRFToken': _class.csrfToken,
    };
    const licenseManager = new Vue({
      template: `
        <section>
          <form class="da-forms license-wrapper">
            <div class="license-left">
              <h4> Tenant Type: </h4>
              <div class="da-form-formItem" style="min-height: 30px">
                <select class="eu-upload-select" @change="onTenantTypeChange" v-model="tenant_type">
                  <option value="Regular_Tenant">Business Tenant</option>
                  <option value="Partner_Tenant">Consumer Tenant</option>
                </select>
              </div>
              <h4> Features: </h4>
              <div v-for="perm in permissions" class="da-form-formItem" style="min-height: 0px">
                <p v-if="(tenant_type == 'Regular_Tenant' && !regularTenantExcludedFeatures.includes(perm.value)) || (tenant_type == 'Partner_Tenant' && partnerTenantFeatures.includes(perm.value))">
                  <input type="checkbox" v-model="perm.isActive"/>
                  {{perm.name}}
                </p>
              </div>
              <h4> Custom Feature Names: </h4>
              <div class="da-form-formItem" v-if="isActiveFeature('depts')">
                <h5>Department (singular and plural):</h5>
                <div style="display: flex; gap: 8px">
                <input class="da-components-forms-input" v-model="department_singular" />
                <input class="da-components-forms-input" v-model="department_plural" />
                </div>
              </div>
              <h4> SSO Provider: </h4>
              <div class="da-form-formItem" style="min-height: 30px">
              <select class="eu-upload-select" v-model="sso_provider">
                <option value="">None</option>
                <option value="microsoft">Microsoft</option>
                <option value="dartmouth">Dartmouth</option>
                <option value="lehigh">Lehigh</option>
              </select>
              </div>
              <div v-if="tenant_type == 'Regular_Tenant'">
                <h4> SeedData: </h4>
                <div class="da-form-formItem" style="min-height: 30px">
                  <button
                    class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                    @click="addData"
                  >
                    Add
                  </button>
                  <button
                    class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                    @click="deleteData"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div class="license-right">
              <div class="da-form-formItem">
                <h4>Valid From:</h4>
                <datetime
                  type="date"
                  class="datetime-theme-blues"
                  v-model="starts"
                  input-class="da-components-forms-input"
                  :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
                  :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
                  :week-start="7"
                  title="Valid From"
                ></datetime>
              </div>
              <div class="da-form-formItem">
                <h4>Valid Until:</h4>
                <datetime
                  type="date"
                  class="datetime-theme-blues"
                  v-model="expires"
                  input-class="da-components-forms-input"
                  :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
                  :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
                  :min-datetime="new Date().toISOString()"
                  :week-start="7"
                  title="Valid Until"
                ></datetime>
              </div>
              <div class="da-form-formItem">
                <h4>User Limit:</h4>
                <input type="number" class="da-components-forms-input" v-model="users_limit" />
              </div>
              <div class="da-form-formItem" v-if="isActiveFeature('depts')">
                <h4>Department Limit:</h4>
                <input type="number" class="da-components-forms-input" v-model="department_limit" />
              </div>
              <div class="da-form-formItem" v-if="tenant_type == 'Partner_Tenant'">
                <h4>Partner Limit:</h4>
                <input type="number" class="da-components-forms-input" v-model="partner_limit" />
              </div>
              <div class="da-form-formItem" v-if="isActiveFeature('depts')">
                <h4>Affinity Group Limit:</h4>
                <input type="number" class="da-components-forms-input" v-model="group_limit" />
              </div>
              <div class="da-form-formItem">
                <h4>Resource Usage Limit (in GB):</h4>
                <input type="number" class="da-components-forms-input" v-model="allowed_resource_usage_in_gb" />
              </div>
              <div class="da-form-formItem">
                <h4>Focus Area Type 1:</h4>
                <select class="eu-upload-select" v-model="focus_areas_1">
                  <option value="experiences">Experiences</option>
                  <option value="destinations">Destinations</option>
                </select>
              </div>
              <div class="da-form-formItem">
                <h4>Focus Area Type 2:</h4>
                <select class="eu-upload-select" v-model="focus_areas_2">
                  <option value="support">Support</option>
                  <option value="services_and_experiences">Services & Experiences</option>
                </select>
              </div>
              <div class="da-form-formItem"  v-if="isActiveFeature('courses')">
                <h4>Thinkific Courses Bundle ID (eg. 67618):</h4>
                <div
                  style="display: flex; width:38%; flex-direction:column; align-items: flex-start;"
                >
                  <input type="text" class="da-components-forms-input" v-model="thinkific_bundles" />
                  <button
                    @click="syncCoursesData"
                    style="margin: 10px 0;"
                    class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                    aria-label="Sync thinkific courses data with our data base"
                  >
                    Sync Courses data
                  </button>
                </div>
                </div>
                <div class="da-form-formItem"  v-if="isActiveFeature('courses')">
                <h4>Thinkific Guides Bundle ID (eg. 67618):</h4>
                <div
                  style="display: flex; width:38%; flex-direction:column; align-items: flex-start;"
                >
                  <input type="text" class="da-components-forms-input" v-model="thinkific_guide_bundles" />
                  <button
                    @click="syncGuidesData"
                    style="margin: 10px 0;"
                    class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                    aria-label="Sync thinkific guide data with our data base"
                  >
                    Sync Guides data
                  </button>
                </div>
                </div>
              </div>
            </div>
          </form>
          <button
            @click="handleSave"
            class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
            aria-label="Save form"
          >
            SAVE
          </button>
          <Notification
            @close="closeNotification"
            :isActiveProp="isNotificationActive"
            :notification="notification"
          />
        </section>
      `,
      data() {
        return {
          partnerTenantFeatures: ['video_chat_meetings', 'courses', 'guides', 'events', 'programs', 'resource_library', 'manage_accounts', 'bulk_upload', 'recommendations', 'groups', 'activity_feed', 'appointments'],
          regularTenantExcludedFeatures: ['guides'],
          licenseId: _class.appData.license_id,
          subdomain: _class.appData.subdomain,
          all_features: _class.appData.all_features,
          permissions: _class.appData.permissions,
          expires: _class.appData.expires + 'T00:00:00.000Z',
          starts: _class.appData.starts + 'T00:00:00.000Z',
          allowed_resource_usage_in_gb: _class.appData.allowed_resource_usage_in_gb,
          users_limit: _class.appData.users_limit,
          department_limit: _class.appData.department_limit,
          group_limit: _class.appData.group_limit,
          partner_limit: _class.appData.partner_limit,
          thinkific_bundles: _class.appData.thinkific_bundles,
          thinkific_guide_bundles: _class.appData.thinkific_guide_bundles,
          sso_provider: _class.appData.sso_provider ? _class.appData.sso_provider : '',
          tenant_type: _class.appData.tenant_type,
          focus_areas_1: _class.appData.focus_areas_1,
          focus_areas_2: _class.appData.focus_areas_2,
          schema: _class.schema,
          department_singular: _class.appData.department_singular,
          department_plural: _class.appData.department_plural,
          isNotificationActive: false,
          notification: { msg: "Thinkific data have been successfully synced in database", isError: false, icon: null },
        };
      },
      components: { Datetime, Notification },
      methods: {
        onTenantTypeChange(e) {
          const tenant_type = e.target.value;
          if(tenant_type === 'Partner_Tenant') {
            this.permissions.forEach(e => { !this.partnerTenantFeatures.includes(e.value) && delete e.isActive })
          } else {
            this.permissions.forEach(e => { this.regularTenantExcludedFeatures.includes(e.value) && delete e.isActive })
          }
        },
        isActiveFeature(feature){
          let isActive = false;
          this.permissions.forEach(e => {
            if(e.value === feature && e.isActive) {
              isActive = true;
            }
          })
          return isActive;
        },
        async handleSave(e) {
          e.preventDefault();

          const parsedPermissions = [];
          this.permissions.forEach(p => { if (p.isActive) parsedPermissions.push(p.value) })

          const data = {
            'license_id': this.licenseId,
            'subdomain': this.subdomain,
            'permissions': parsedPermissions,
            'expires': this.expires,
            'starts': this.starts,
            'department_limit': this.department_limit,
            'group_limit': this.group_limit,
            'partner_limit': this.partner_limit,
            'allowed_resource_usage_in_gb': this.allowed_resource_usage_in_gb,
            'users_limit': this.users_limit,
            'thinkific_bundles': this.thinkific_bundles,
            'thinkific_guide_bundles': this.thinkific_guide_bundles,
            'sso_provider': this.sso_provider,
            'tenant_type': this.tenant_type,
            'focus_areas_1': this.focus_areas_1,
            'focus_areas_2': this.focus_areas_2,
            'department_singular': this.department_singular,
            'department_plural': this.department_plural,
          };

          await _class.service.postFormData(data, customHeaders);
          location.reload();
        },
        async handleDelete(e) {
          e.preventDefault();
          if (confirm('Are you sure?')) {
            await _class.service.delete({ 'license_id': this.licenseId }, customHeaders);
            location.reload();
          }
        },
        async syncCoursesData(e){
          e.preventDefault();
          const res = await _class.courseService.syncData(_class.schema, customHeaders);
          if(!res){
            this.notification.msg= "Server Error!";
            this.notification.isError = true
            this.isNotificationActive = true;
            return;
          }
          this.notification = { msg: "Thinkific Courses have been successfully synced in database", isError: false, icon: null };
          this.isNotificationActive = true;

        },
        async syncGuidesData(e){
          e.preventDefault();
          const res = await _class.guidesService.syncData(_class.schema, customHeaders);
          if(!res){
            this.notification.msg= "Server Error!";
            this.notification.isError = true
            this.isNotificationActive = true;
            return;
          }
          this.notification = { msg: "Thinkific Guides have been successfully synced in database", isError: false, icon: null };
          this.isNotificationActive = true;

        },
        closeNotification() {
          this.isNotificationActive = false;
        },
        async addData(e){
          e.preventDefault();
          const res = await _class.seedService.addData(_class.schema);
          if(!res){
            this.notification.msg= "Server Error!";
            this.notification.isError = true
            this.isNotificationActive = true;
            return;
          }
          this.notification = { msg: "Test data have been successfully aded in database", isError: false, icon: null };
          this.isNotificationActive = true;
        },
        async deleteData(e){
          e.preventDefault();
          const res = await _class.seedService.deleteData(_class.schema, customHeaders);
          if(!res){
            this.notification.msg= "Server Error!";
            this.notification.isError = true
            this.isNotificationActive = true;
            return;
          }
          this.notification = { msg: "Test data have been successfully deleted from database", isError: false, icon: null };
          this.isNotificationActive = true;
        }

      },
    });
    licenseManager.$mount(this.mountPoint);
  }
}
