<template >
  <div v-if="isPageLoading">
    <loading-spinner wrapperClass="wrapper" />
  </div>
  <div v-else class="flex-column-center">
    <div class="main-wrapper">
      <div class="title-wrapper">
        <common-title title="Opportunities" description="Browse opportunities posted by your university." />

      </div>
      <nav class="tab-navigation-container">
        <router-link to="/opportunities" class="tab-navigation-item" aria-label="Go to events pages">
          Browse <span class="desktop-only-text">Opportunities</span></router-link
        >
        <router-link to="/opportunities/manage" class="tab-navigation-item active" aria-label="Go to manage event page">
          Manage <span class="desktop-only-text">Opportunities</span></router-link
        >
        <router-link to="/events?page=saved" class="tab-navigation-item" aria-label="Go to your saved events"
          >Saved<span class="desktop-only-text">/Upcoming</span></router-link
        >
        <router-link to="/opportunities/leads" class="tab-navigation-item" aria-label="Go to manage leads page">
          <span class="desktop-only-text">Manage</span> Leads</router-link
        >
      </nav>
      <div class="manage-events-search-form-container">
        <form action="" @submit.prevent="fetchEvents()" class="eu-search-form" role="search">
          <input
            v-model="search_input"
            name="q"
            type="search"
            placeholder="Search for opportunities"
            class="eu-search-input"
          />
          <button class="eu-search-btn-icon" aria-label="search">
            <i class="eu-search-icon fas fa-search"></i>
          </button>
        </form>
      </div>
      <div class="filter-container content-filters-container">
        <multiselect-filter-component
          v-if="!department && !isPartnerTenant"
          :placeholder="`Any ${deptSingular}`"
          filter_name="Departments"
          style="margin: 0 !important"
          :options="departments"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="!department && hasEventsAccess && hasProgramsAccess"
          placeholder="Events &amp; Programs"
          filter_name="Events &amp; Programs"
          style="margin: 0 !important"
          :options="[{ name: 'Events' }, { name: 'Programs' }]"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="!department"
          placeholder="Not Archived"
          filter_name="All"
          style="margin: 0 !important"
          :options="[{ name: 'Not Archived' }, { name: 'Archived' }]"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          v-if="!department"
          placeholder="Published"
          filter_name="Published &amp; Draft"
          style="margin: 0 !important"
          :options="[{ name: 'Published' }, { name: 'Draft' }]"
          @filter-data="createFilterData"
        />
      </div>
      <div v-if="!isLoading" class="table-container manage-event-program-wrapper manage-opportunities-table-wrapper">
        <events-table
          :events="events"
          :numberOfGuest="numberOfGuest"
          :directionUp="directionUp"
          :sortedBy="sortedBy"
          @open-delete-modal="openDeleteModal"
          @sort_table="sort"
          @handle_update="handelUpdate"
        />
      </div>
      <div v-if="isLoading">
        <loading-spinner wrapperClass="wrapper" />
      </div>

      <events-modals
        :isDeleteModalActive="isDeleteModalActive"
        :removing="removing"
        :itemToDelete="itemToDelete"
        @delete-item="deleteEvent"
        @close-delete-modal="closeDeleteModal"
      />
      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </div>
  </div>
</template>

<script>
import ProgramService from "../../programs/services/ProgramService";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import EventsIndexService from "../services/EventsIndexService";
import { focusElement } from "../../utils/CustomUtils";
import RSVPService from "../services/RSVPService";
import EventsModals from "./EventsModals.vue";
import EventsTable from "./EventsTable.vue";
import Cookies from "js-cookie";
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import Notification from "../../components/Notification.vue";
import CommonTitle from "../../components/CommonTitle.vue";
import PopupComponent from "../../components/popup/PopupComponent.vue";
import { isPartnerTenant } from "../../utils/CustomUtils";
import { FEATURES, hasPermission } from "../../utils/PermissionUtils";
import { useCommonDataStore } from '../../stores/commonData';

export default {
  name: "event-admin-manage-component",
  components: {
    Notification,
    EventsTable,
    LoadingSpinner,
    EventsModals,
    MultiselectFilterComponent,
    CommonTitle,
    PopupComponent,
  },
  props: [],
  computed: {
    deptSingular() {
      return useCommonDataStore().getFeatureName("department_singular");
    },
  },
  data() {
    return {
      isLoading: true,
      isPageLoading: true,
      events: [],
      numberOfGuest: [],
      params: {
        departments: [],
        groups_name: [],
        partners_name: [],
        focus_area: "",
        status_of_event: "ALL",
        saved: "",
        type: "",
      },
      department: null,
      departments: [],
      groups: [],
      partners: [],
      search_input: "",
      isDeleteModalActive: false,
      removing: false,
      itemToDelete: {
        id: null,
        name: null,
        type_of_program_management: null,
      },
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      sortBy: "timestamp",
      directionUp: {
        title: true,
        start_date: true,
        creation_date: true,
      },
      isPartnerTenant: isPartnerTenant(),
      sortedBy: "",
      pageCount: 1,
      notification: { msg: "", isError: false, icon: null },
      isNotificationActive: false,
    };
  },
  async created() {
    this.hasEventsAccess = hasPermission(FEATURES.EVENTS);
    this.hasProgramsAccess = hasPermission(FEATURES.PROGRAMS);
    this.eventsService = new EventsIndexService();
    await this.getPreData();
    this.programService = new ProgramService();
    this.rsvpService = new RSVPService();
    if (this.department) {
      this.params.departments.push(this.department);
    }
    if (this.groups) {
      for (let group of this.groups) {
        this.params.groups_name.push(encodeURIComponent(group["name"]));
      }
    }
    if (this.partners) {
      for (let partner of this.partners) {
        this.params.partners_name.push(encodeURIComponent(partner["name"]));
      }
    }
    this.fetchEvents();
    this.fetchNumberOfGuest();
    this.scroll();
  },
  methods: {
    async getPreData() {
      this.isPageLoading = true;
      const res = await this.eventsService.getPredataManage();
      if (res) {
        this.department = res.department ? encodeURIComponent(res.department) : null;
        this.departments = res.departments;
        this.groups = res.groups;
        this.partners = res.partners;
      }
      this.isPageLoading = false;
    },
    async fetchEvents() {
      this.isLoading = true;
      if (this.pageCount == 0) this.pageCount = 1;
      const response = await this.eventsService.getEvents({
        ...this.params,
        q: this.search_input,
        page: this.pageCount,
      });
      if (response) {
        this.events = response.opportunities;
      }
      this.isLoading = false;
    },
    async fetchNumberOfGuest() {
      this.isLoading = true;
      let res = await this.rsvpService.getNumberOfGuest();
      this.numberOfGuest = res.list;
      this.isLoading = false;
    },
    openDeleteModal(item) {
      this.itemToDelete = item;
      this.isDeleteModalActive = true;
      focusElement("event_delete_modal");
    },
    closeDeleteModal() {
      this.isDeleteModalActive = false;
    },
    async deleteEvent(id, type) {
      this.removing = true;
      let res = {};
      if (type === "event") {
        res = await this.eventsService.remove(id, this.headers);
      } else {
        res = await this.programService.remove(id, this.headers);
      }
      this.removing = false;
      this.isDeleteModalActive = false;

      if (res.success) {
        this.pageCount = 0;
        this.fetchEvents();
      }

      const notification = { msg: "", isError: false, icon: null };
      if (res.success) {
        notification.msg = "Opportunity successfully removed.";
        notification.icon = "fad fa-check-circle";
      } else {
        notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        notification.isError = false;
        notification.icon = "fad fa-exclamation-circle";
      }
      this.showNotification(notification);
    },
    async sort(column) {
      this.pageCount = 1;
      this.params["sort"] = this.directionUp[column] ? column : `-${column}`;
      await this.fetchEvents();
      this.sortedBy = column;
      this.directionUp[column] = !this.directionUp[column];
    },
    createFilterData(value, name) {
      if (name === "Departments") {
        this.params.departments = value.map((v) => encodeURIComponent(v.name));
      } else if (name === "Events & Programs") {
        this.params.type = value.map((v) => v.name.toLowerCase().slice(0, -1));
      } else if (name == "All") {
        this.params.archived = value.map((v) => v.name.toLowerCase());
      } else if (name == "Published & Draft") {
        this.params.published = value.map((v) => v.name.toLowerCase());
      }

      this.pageCount = 1;
      this.fetchEvents();
    },
    async scroll() {
      window.onscroll = async () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;

        if (bottomOfWindow && this.pageCount !== 0) {
          this.pageCount += 1;

          const response = await this.eventsService.getEvents({
            ...this.params,
            q: this.search_input,
            page: this.pageCount,
          });

          let newEvents = [];

          if (response && response.opportunities) {
            newEvents = response.opportunities;
          }

          if (newEvents.length > 0) {
            newEvents.forEach((e) => {
              this.events.push(e);
            });
          } else this.pageCount = 0;
        }
      };
    },
    closeNotification() {
      this.notification = { msg: "", isError: false, icon: null };
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.notification = notification;
      this.isNotificationActive = true;
    },
    async handelUpdate(item) {
      const res = await this.eventsService.update(item, this.headers);
      if (res) {
        this.pageCount = 1;
        this.fetchEvents();
      }

      const notification = { msg: "", isError: false, icon: null };
      if (res.success) {
        notification.msg = "Opportunity successfully updated.";
        notification.icon = "fad fa-check-circle";
      } else {
        notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        notification.isError = false;
        notification.icon = "fad fa-exclamation-circle";
      }
      this.showNotification(notification);
    },
  },
};
</script>

<style scoped lang="scss">
.title-wrapper {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
}
.mobile-options {
  width: 100%;
  display: flex;
  gap: 8px;
}
@media screen and (max-width: 576px) {
  .title-wrapper {
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
  }
}
</style>
