<template>
  <section class="resource-section">
    <div class="filter-section">
      <div class="search-container">
        <div class="tint"></div>
        <div class="text-container">
          <h3 class="title">
            Explore resources
          </h3>
          <p class="subtitle">
            Search for  files, videos, audio, and more
          </p>
        </div>
        <search-base
          ref="searchResources"
          id="search-resources"
          placeholder="Search by keyword"
          aria-label="Search guides by keyword"
          style="width: 100%;"
          v-model="searchInput"
          @search="search"
        ></search-base>
      </div>
      <div class="filter-contain">
        <multiselect-filter-component
          placeholder="Any Type"
          filter_name="Resource Types"
          style="width: 100% !important; margin: 0px !important"
          :options="resourceLabelOptions"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          placeholder="Any Focus Area"
          filter_name="Focus Areas"
          style="width: 100% !important; margin: 0px !important"
          :options="filterData.focus_areas"
          @filter-data="createFilterData"
        />
        <multiselect-filter-component
          placeholder="Any Identity"
          filter_name="Identities"
          style="width: 100% !important; margin: 0px !important"
          :options="filterData.identities"
          @filter-data="createFilterData"
        />
      </div>
    </div>
    <div class="resource-content-container" ref="resourcesContainer">
      <partner-resource-card-basic v-for="(resource, index) in featuredResourcesList" :key="`featured-${index}`" :resource="resource"/>
      <partner-resource-card-basic v-for="(resource, index) in resourcesList" :key="index" :resource="resource" />
    </div>
    <div v-if="isFetching" class="resource-loading-spinner-container">
      <loading-spinner />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from '@vue/composition-api';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import useResourceManager from '../composables/useResourceManager.vue';
import PartnerResourceCardBasic from './PartnerResourceCardBasic.vue';
import { useWindowSize, useScroll } from '@vueuse/core';
import SearchBase from '../../components/formBase/SearchBase.vue';
import { resourceLabelOptions } from '../../utils/constants';
import MultiselectFilterComponent from '../../form/components/MultiselectFilterComponent.vue';

export default defineComponent({
  name: 'partner-resource-browse',
  setup(pros, context) {
    // @ts-ignore
    const { // @ts-ignore
      featuredResourcesList, // @ts-ignore
      resourcesList,// @ts-ignore
      isFetching,// @ts-ignore
      total_num,// @ts-ignore
      allLoaded,// @ts-ignore
      fetchFeaturedResources,// @ts-ignore
      fetchResources,// @ts-ignore
      search,// @ts-ignore
      searchInput,// @ts-ignore
      filterData,// @ts-ignore
      createFilterData,//@ts-ignore
    } =
      // @ts-ignore
      useResourceManager.setup({ mode: 'PARTNER' }, context);

    const { width: windowWidth } = useWindowSize();

    const resourcesContainer = ref<HTMLElement | null>(null)
    const offset = {
      bottom: 0,
      right: 0
    }
    let el = null
    if(windowWidth.value > 576) {
      el = window;
      offset.bottom = 150;
    } else {
      el = resourcesContainer;
      offset.right = 50;
    }
    const { arrivedState } = useScroll(el, { offset: offset });


    return {
      featuredResourcesList,
      resourcesList,
      isFetching,
      total_num,
      allLoaded,
      filterData,
      createFilterData,
      fetchFeaturedResources,
      fetchResources,
      search,
      searchInput,
      arrivedState,
      windowWidth
    };
  },
  watch: {
    arrivedState: {
      deep: true,
      handler() {
        if(this.loadingConditionsMet()) {
          this.fetchResources({});
        }
      }
    }
  },
  components: {
    LoadingSpinner,
    PartnerResourceCardBasic,
    SearchBase,
    MultiselectFilterComponent
  },
  created() {
    this.fetchFeaturedResources({});
    this.fetchResources({});
  },
  data() {
    return {
      resourceLabelOptions: resourceLabelOptions.map((e) => {
        return { name: e };
      }),
    };
  },
  methods: {
    loadingConditionsMet() {
      if (this.windowWidth > 576) {
        return this.arrivedState.bottom && !this.allLoaded;
      } else {
        return this.arrivedState.right && !this.allLoaded;
      }
    }
  },
});
</script>
<style scoped lang="scss">
.resource-section {
  width: 100%;
  max-width: 1400px;
}

.filter-section {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 2rem;

  .search-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    padding: 3rem 5rem;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
  }

  .tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    text-align: center;
    position: relative;

    .title {
      font-size: 2.5rem;
      line-height: 1.2;
      padding: 0;
      color: #fff;
    }

    .subtitle {
      font-size: 1.25rem;
      line-height: 1.35;
      color: #fff;
    }
  }
}

.resource-content-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}
</style>
