<template>
  <div class="blog-overview-card">
    <router-link :to="`/partner/home/blogs/${blog.urlSlug}`" class="blog-overview-card-image-container">
      <img :src="blog.image.url" alt="img" class="blog-overview-card-image" />
    </router-link>
    <div class="blog-overview-card-content-container">
      <div class="blog-overview-card-author-container">
        <img
          :src="blog.author.profilePic.url"
          alt="Profile picture"
          class="blog-overview-card-author-image"
        />
        <span class="blog-overview-card-author-name"> By {{ blog.author.name }} </span>
        <span class="blog-overview-card-date-created">
          {{ formatDate(blog.createdAt) }}
        </span>
      </div>
      <div class="blog-overview-card-text-container">
        <h4 style="padding: 0">
          <router-link :to="`/partner/home/blogs/${blog.urlSlug}`" class="blog-overview-card-title" tabindex="0">
            {{ blog.title }}
          </router-link>
        </h4>
        <p class="blog-overview-card-description">
          {{ blog.description }}
        </p>
      </div>
      <div class="blog-overview-card-pills-container">
        <span v-for="(tag, index) in blog.tag" :key="index" class="partner-tenant-blogs-featured-pill">
          {{ tag.tagText }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'blog-overview-card',
  props: ['blog'],
  created() {},
  methods: {
    formatDate(dateString) {
      return moment(dateString).format('MMMM DD, YYYY');
    },
    redirectTo(url) {
      this.$router.push(url);
    },
  },
};
</script>
