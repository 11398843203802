<template>
  <div v-if="alert" class="dashboard-alerts-container" :style="`background: ${ALERT_STYLE[alert.type].bg}`">
    <div class="dashboard-alerts-left">
      <i class="fa-solid fa-circle-xmark dashboard-alerts-x" @click="closeAlerts"></i>
      <i
        :class="`dashboard-alerts-icon fa-duotone ${ALERT_STYLE[alert.type].icon}`"
        :style="`color: ${ALERT_STYLE[alert.type].iconBg}`"
      ></i>
      <div v-if="alert.type === ALERT_TYPES.meeting" class="dashboard-alerts-text">You have <router-link to="/meetings">a meeting</router-link> with {{alert.with}} at {{ alert.time | parseOneTime }}</div>
      <div v-if="alert.type === ALERT_TYPES.event" class="dashboard-alerts-text">You have an opportunity today: <router-link :to="`/events/${alert.slug}`">{{alert.title}}</router-link></div>
    </div>
    <div v-if="alerts.length > 1" class="dashboard-alerts-right">
      <div>{{index+1}}/{{alerts.length}}</div>
      <div>
        <i @click="changeAlert(index-1)" class="fa-duotone fa-caret-left dashboard-alerts-caret"></i>
        <i @click="changeAlert(index+1)" class="fa-duotone fa-caret-right dashboard-alerts-caret"></i>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardService from "../services/DashboardService";

const ALERT_TYPES = {
  event: "event",
  meeting: "meeting",
};
const ALERT_STYLE = {
  event: {
    bg: "#fff6d2",
    icon: "fa-calendar-star",
    iconBg: "#ffa800",
  },
  meeting: {
    bg: "#ffe6e0",
    icon: "fa-messages",
    iconBg: "#f6764d",
  },
};
export default {
  name: "dashboard-alerts",
  components: {},
  props: [],
  data() {
    return {
      service: new DashboardService(),
      index: 0,
      alerts: [],
      alert: null,
      ALERT_TYPES,
      ALERT_STYLE,
    };
  },
  async created() {
    const res = await this.service.getCalendarData({
      isTodayOnly: true,
      timezoneOffset: -new Date().getTimezoneOffset(),
    });
    res.events.forEach((e) => (e.type = ALERT_TYPES.event));
    res.meetings.forEach((e) => (e.type = ALERT_TYPES.meeting));
    this.alerts = [...res.events, ...res.meetings];
    this.alert = this.alerts.length > 0 && this.alerts[0];
  },
  methods: {
    changeAlert(next) {
      let newIndex;
      if(next > this.alerts.length - 1) {
        newIndex = 0;
      } else if(next < 0) {
        newIndex = this.alerts.length - 1;
      } else {
        newIndex = next;
      }
      this.alert = this.alerts[newIndex]
      this.index = newIndex;
    },
    closeAlerts() {
      this.alert = null;
    }
  },
  computed: {},
};
</script>