<template>
  <section>
        <div tabindex="0" :aria-label="`Question: ${stepTitles[currentStepNo]}`" class="passport-form-title">{{stepTitles[currentStepNo]}}</div>
        <div v-if="currentStepNo === 0">
            <div  class="passport-form-label">
                <label for="first_name" >First Name</label>
            </div>
            <input id="first_name" type="text" class="passport-form-input-text" maxlength="255" v-model="profile.user.first_name" />
            <div class="passport-form-label">
                <label for="last_name">Last Name </label>
            </div>
            <input id="last_name" type="text" class="passport-form-input-text" maxlength="255" v-model="profile.user.last_name" />
        </div>
        <div v-if="currentStepNo === 1">
            <div class="passport-form-label">
              <label for="about_me">  Bio </label>
            </div>
            <textarea id="about_me" class="passport-form-textarea" v-model="profile.about_me" type="text" rows="3" />
            <div class="passport-form-textarea-chars" :style="`color: ${profile.about_me && profile.about_me.length > 200 ? '#E65722' : '#707070'}`">
                {{profile.about_me ? profile.about_me.length : '0'}}/200
            </div>
        </div>
        <div v-if="currentStepNo === 2">
            <div v-if="!profile.profile_pic" v-cloak @drop.prevent="handleFileUpload" @dragover.prevent class="innerRow dnd-wrapper passport-img-wrapper">
                <label
                @dragenter="dragging = true"
                @dragleave="dragging = false"
                class="file-upload-label"
                :style="{ background: dragging ? '#eaeeff' : '' }"
                for="profile_image"
                >
                    <span style="font-size: 13px">
                        <i class="fad fa-cloud-upload-alt dnd-upload-icon" style="font-size: 40px"></i> <br />
                        Drag &amp; drop files here <br />
                        or <br />
                        <input @change="handleFileUpload" style="width:0" id="profile_image" type="file" accept="image/*" />
                        <span style="color: #4c55a7; font-weight: 500; cursor: pointer">
                           <label for="profile_image"> Browse from device <i class="fal fa-angle-right" style="color: #5E50B5; font-size: 15px"></i> </label>
                        </span>
                    </span>
                </label>
            </div>
            <div v-if="profile.profile_pic" class="passport-img-exist-wrapper">
                <img :src="profile.profile_pic" class="passport-img-exist" />
                <div v-cloak class="passport-img-different">
                    <input class="upload-img-input" @change="handleFileUpload" style="width:0" id="profile_image" type="file" accept="image/*" />
                    <label class="upload-img-label" for="profile_image" style="cursor: pointer">Upload different photo</label>
                </div>
            </div>
            <label class="eu-checkbox-container" style="font-size: 14px; padding-left: 0; margin-top: 25px; text-align: center; color: #707070; cursor: default"
            >Accepted file types: <br />
            <span style="font-weight: 500"> .jpg, .png, .webp no larger than 2MB</span>
          </label>
        </div>
         <div v-if="currentStepNo === 3">
             <div class="passport-form-label">Social Links</div>
             <div class="passport-social-links-wrapper">
                <select :disabled="profile.dontShareSocials" class="passport-form-input-select dropdown"  v-model="selectedSocial">
                    <option disabled value="">Select a site</option>
                    <option v-for="option in socialOptions" :value="option.value" :key="option.value">
                        {{ option.text }}
                    </option>
                </select>
                <i class="fal fa-angle-down eu-select-arrow" aria-hidden="true"></i>
                <input :disabled="profile.dontShareSocials" :placeholder="`Add ${selectedSocial ? selectedSocial + ' ' : ''}link`" type="text" class="passport-form-input-text" maxlength="255" v-model="socialInput" />
            </div>
            <button type="button" :style="`${socialInput && selectedSocial ? '': 'color: #707070'}`" class="passport-add-button" @click="addObject">+ Add Social Link</button>

            <div v-for="item in filledSocials" :key="item.value" class="passport-socials-container">
                <i v-if="item.icon" class="passport-socials-icon" :class="item.icon"></i>
                <i v-else class="fas fa-plus passport-socials-icon"></i>
                <a class="passport-socials-name" :href="item.socialInput" target="_blank">{{ item.text }}</a>
                <button :aria-label="`Remove ${item.text} social link`" class="passport-socials-del-btn" @click="removeObject(item.value)"><i class="fad fa-trash"></i></button>
            </div>

            <div class="passport-form-label">Or</div>
            <div tabindex="0" role="button" :class="`passport-form-button ${profile.dontShareSocials ? 'active' : ''}`" @click="toggleShareSocials">Prefer not to share</div>
        </div>
        <div v-if="currentStepNo === 4">
            <section v-if="isBadgeEarned()">
              <div class="passport-form-title">Woohoo! You earned a badge.</div>
              <img class="passport-badge-earned" :src="`${assetPath}badges/profile-earned.svg`"/>
            </section>
            <div class="passport-form-label" style="margin-bottom: 30px">Keep filling out sections to earn badges and complete your passport.</div>
            <a v-if="nextStep" :href="`/passport?step=${encodeURIComponent(nextStep)}`" class="da-components-button da-btn-solid enup-demo-button passport-final-btn">Continue with Passport</a>
            <a :href="dashboardLink" class="da-components-button da-btn-solid enup-demo-button passport-final-btn inverted">Quit & Go to Dashboard</a>
        </div>
  </section>
</template>
<script lang="ts">
import { validateExtensionFile } from '../../utils/TypecheckUtils';

export default {
  components: {  },
  name: "passport-your-profile",
  props: ['profile', 'currentStepNo', 'dashboardLink', 'socialOptions', 'nextStep'],
  data() {
    return {
      dragging: false,
      filledSocials: [],
      selectedSocial: '',
      socialInput: '',
      socialsObject: {},
      assetPath: process.env.MEDIA_URL,
      stepTitles: ['Let’s set up your profile.', 'Write a short description about yourself.', 'Upload your profile photo.', 'Enter your social links.'],
    };
  },
  created() {

        this.socialOptions.forEach(e => {
            this.socialsObject[e.value] = '';
        });
        if(this.profile.social_networks && this.profile.social_networks !== 'null') {
            for (const [key, value] of Object.entries(JSON.parse(this.profile.social_networks))) {
                if (value) {
                    const index = this.socialOptions.findIndex(x => x.value === key);
                    let temp = this.socialOptions.splice(index, 1)[0];
                    temp.socialInput = value;
                    this.filledSocials.push(temp);
                    this.socialsObject[key] = value;
                }
            }
        }

    },
  methods: {
    handleFileUpload(event) {
        const addedImg = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
        const imageExt = ['jpg', 'jpeg', 'png', 'webp'];
        if (!addedImg) return;
        if (validateExtensionFile(addedImg.name, imageExt)) {
            this.submitImage(addedImg);
        } else {
            this.showNotification({ msg: 'Please use a supported image filetype.', isError: true, icon: 'fad fa-exclamation-circle' });
        return;
        }
    },
    isBadgeEarned() {
        this.$emit('is-badge-earned');
        return this.profile.badge;
    },
    showNotification(notif) {
        this.$emit('show-notification', notif);
    },
    submitImage(img) {
        this.$emit('submit-image', img);
    },
    toggleShareSocials() {
        this.$emit('toggle-share-socials');
        this.filledSocials = [];
        this.socialsObject = {};
    },
    removeObject(value) {
        this.socialsObject[value] = '';
        const index = this.filledSocials.findIndex(x => x.value === value);
        let temp = this.filledSocials.splice(index, 1)[0];
        this.socialOptions.push(temp);
        this.profile.social_networks = JSON.stringify(this.socialsObject);
    },
    addObject(){
        if (!this.socialInput || !this.selectedSocial)
            return;

        const socialInput = this.socialInput;
        this.socialInput = '';
        const index = this.socialOptions.findIndex(x => x.value === this.selectedSocial);
        let temp = this.socialOptions.splice(index, 1)[0];
        temp.socialInput = socialInput;
        this.filledSocials.push(temp);
        this.socialsObject[this.selectedSocial] = socialInput;
        this.selectedSocial = '';
        this.profile.social_networks = JSON.stringify(this.socialsObject);
    }
  },
  computed: {},
};
</script>

