<template>
  <span>
    <div>
      <a
      id="user-import-button"
      ref="userImportButton"
      class="inup-user-import-btn"
      href="/profiles/settings/mentorGroups"
      aria-haspopup="true"
      style="font-size:14px;height:auto"
    >
      Mentor Groups <i class="fas fa-gears"></i>
    </a>
    <button
      id="user-import-button"
      ref="userImportButton"
      class="inup-user-import-btn"
      @click="showImportMenu()"
      style="margin-top: 15px;font-size:14px;height:auto"
      aria-haspopup="true"
    >
      {{ uploadButtonText }}<i class="far fa-chevron-down chevron-icon"></i>
    </button>
    </div>
    <div
      id="user-import-menu"
      ref="importMenu"
      v-closable="{exclude:['userImportButton', 'importMenu',], handler: 'closeImportMenu'}"
      class="eu-resource-burger"
      style="width: 285px; right: 5%; margin-top: 5px;"
    >
      <span 
        tabindex="0"
        class="eu-resource-burger-el" 
        @click="openBulkModal()"
        @keyup.enter="openBulkModal()"  
        aria-label="open bulk add mentor modal"
        role="button"
        aria-haspopup="dialog"
      >
        <i class="fad fa-file-upload" style="padding-right: 5px"></i>
        <span>Bulk Import</span>
        <p class="subtext">Add multiple {{ subtext }} via spreadsheet.</p>
      </span>
      <span 
        tabindex="0"
        class="eu-resource-burger-el" 
        @click="openManualModal()"
        @keyup.enter="openManualModal()"  
        aria-label="open manual add mentor modal"
        role="button"
        aria-haspopup="dialog"
      >
        <i class="fas fa-plus" style="padding-right: 5px"></i>
        <span>Manual Add</span>
        <p class="subtext">Add {{ subtext }} individually.</p>
      </span>
    </div>

    <manual-upload-modal
      :isModalActive="isManualModalActive"
      :userObject="userObject"
      :editMode="editMode"
      :roles="roles"
      :show_dept_picker="!is_dept_lead && !isPartnerTenant()"
      :departments="departments"
      :isRoleChangeable="isRoleChangeable"
      @add-manual="addUser"
      @close-modal="closeManualModal"
    />

    <bulk-upload-modal
      :isModalActive="isBulkModalActive"
      :roles="roles"
      :isRoleChangeable="isRoleChangeable"
      @close-modal="closeBulkModal"
    />

    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
  </span>
</template>
<script lang="ts">
import Cookies from 'js-cookie';
import handleOutsideClick from '../../directives/handleOutsideClick';
import BulkUploadModal from './modals/BulkUploadModal.vue';
import ManualUploadModal from './modals/ManualUploadModal.vue';
import ImportService from '../services/ImportService';
import Notification from '../../components/Notification.vue';
import { Department, Role } from '../../types';
import { PropType } from 'vue'
import { focusElement, isPartnerTenant } from '../../utils/CustomUtils';

export default {
  name: 'user-import-interface',

  props: {
    'roles': Array as PropType<Array<Role>>,
    'isRoleChangeable': Boolean,
    'departments':  Array as PropType<Array<Department>>,
    'is_dept_lead': Boolean
  },

  directives: { closable: handleOutsideClick },

  components: {
    BulkUploadModal,
    ManualUploadModal,
    Notification,
  },

  data() {
    return {
      userObject: null,
      userForUpload: null,

      isBulkModalActive: false,
      isManualModalActive: false,
      editMode: false,

      isNotificationActive: false,
      notification: {
        msg: '',
        isError: false,
        icon: null,
      },

      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json'
      },
    };
  },

  computed: {
    uploadButtonText(): string {
      if (this.isRoleChangeable) {
        return `Add Users`;
      }

      return `Add ${this.roles[0].name}s`;
    },

    subtext(): string {
      if (this.isRoleChangeable) {
        return `users`;
      }

      return `${this.roles[0].name.toLowerCase()}s`;
    }
  },

  created() {
    this.importService = new ImportService();
  },


  methods: {
    isPartnerTenant,
    async addUser(params: {userObject: {first_name : string, last_name: string, email: string, role: string, department: string}, sendInvite: boolean}) {

      this.userForUpload = params.userObject;

      const requestObject = {
        user: JSON.stringify(this.userForUpload),
        import_type: 'MANUAL',
        send_invite: params.sendInvite,
      }

      const result = await this.importService.postFormData(requestObject, this.headers);

      if (result.status === 'success') {
        this.showNotification({
          msg: `${this.userForUpload.first_name} ${this.userForUpload.last_name} has been successfully uploaded`,
          isError: false,
          icon: 'fad fa-check-circle'
        });
      } else {
        this.showNotification({
          msg: result.message,
          isError: true,
          icon: 'fad fa-exclamation-circle'
        });
      }
      focusElement("notification_title")
    },

    showImportMenu() {
      const menuEl = document.querySelector(`#user-import-menu`);

      menuEl.classList.toggle('eu-resource-burger-active');
    },

    closeImportMenu() {
      const menuEl = document.querySelector(`#user-import-menu`);

      menuEl.classList.remove('eu-resource-burger-active');
    },

    openManualModal() {
      this.closeImportMenu();
      this.isManualModalActive = true;
      focusElement("modal_add_or_edit_users");
    },

    closeManualModal() {
      this.isManualModalActive = false;
    },

    openBulkModal() {
      this.closeImportMenu();
      focusElement("upload_files_modal");
      this.isBulkModalActive = true;
    },

    closeBulkModal() {
      this.isBulkModalActive = false;
    },

    showNotification(notification: { msg: string, isError: boolean, icon: string}) {
      this.notification = notification;
      this.isNotificationActive = true;
    },

    closeNotification() {
      this.isNotificationActive = false;
    },
  },
}
</script>