<template>
  <section class="io-calendar-dropdown">
    <div v-if="isLoading && isCalendarOpened" style="padding:45px;margin:100px;">
      <loading-spinner wrapperClass="spinner-whole-screen-center" />
    </div>
    <div v-else-if="!isLoading && isCalendarOpened" class="wrap">
      <ejs-calendar id="calendar" v-model="date" @change="onChange" :renderDayCell="load"></ejs-calendar>
      <div v-for="u in meetings" v-bind:key="u.id" class="eu-dashboard-card-body-wrapper io-calendar-item">
        <div class="eu-dashboard-card-body-date">
                  {{ u.date | parseShortDate }}
                </div>
        <div>
          <p class="eu-dashboard-card-body-date-information">{{ u.date | parseMidDate }} at {{ u.time | parseOneTime }}</p>
          <p class="eu-dashboard-card-body-date-with" style="margin-right:0">with {{ u.with }}</p>
        </div>
      </div>
      <div v-for="u in events" v-bind:key="u.id" class="eu-dashboard-card-body-wrapper io-calendar-item">
        <div class="eu-dashboard-card-body-date" :style="`background-image:url('${u.thumbnail}'); background-size: cover;min-width:64px;height:64px;`"></div>
        <div>
          <p class="eu-dashboard-card-body-date-information">
            <a class="io-calendar-item-a" :href="`/events/${u.slug}`" :aria-label="'Go to opportunity ' + u.title + ' page'">{{ u.title }}</a>
          </p>
          <p class="eu-dashboard-card-body-date-with" style="margin-right:0">{{ u | parseEventTime }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import Vue from 'vue';
import { CalendarPlugin } from '@syncfusion/ej2-vue-calendars';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import DashboardService from '../../dashboard/services/DashboardService';
import { parseEventDates } from '../../events/utils/DateFormat';
Vue.use(CalendarPlugin);
export default {
  name: 'top-navbar-calendar',
  components: { LoadingSpinner },
  props: ['isCalendarOpened'],
  data() {
    return {
      date: null,
      isLoading: true,
      allMeetings: [],
      allEvents: [],
      events: null,
      meetings: null,
      service: new DashboardService()
    };
  },
  async created() {
    await this.getCalendarData();
    this.data = await this.service.getCalendarData({});
    this.showContentForSelectedDate(new Date());
  },
  mounted() {},
  methods: {
    async getCalendarData() {
      this.data = await this.service.getCalendarData({});
      this.allMeetings = this.data?.meetings;
      this.allEvents = parseEventDates(this.data?.events);
      this.isLoading = false;
    },
    showContentForSelectedDate(date) {
      this.meetings = this.allMeetings.filter(m => {
        return this.isDateEqual(date, new Date(m.date));
      });
      this.events = this.allEvents.filter(m => {
        return this.isDateEqual(date, m.start);
      });
    },
    onChange(e) {
      this.showContentForSelectedDate(e.value);
    },
    load: function(args) {
      let span;
      span = document.createElement('span');
      span.setAttribute('class', 'e-io-highlight');
      this.allMeetings.forEach(m => {
        if (this.isDateEqual(args?.date, new Date(m.date)) && !args.element.className.includes('e-other-month')) {
          args.element.appendChild(span);
          args.element.className = 'e-io-highlight-day';
        }
      });
      this.allEvents.forEach(m => {
        if (this.isDateEqual(args?.date, m.start) && !args.element.className.includes('e-other-month')) {
          args.element.appendChild(span);
          args.element.className = 'e-io-highlight-day';
        }
      });
    },
    isDateEqual(date1, date2) {
      return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
    }
  }
};
</script>
