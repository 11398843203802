import Cookies from 'js-cookie';
import HttpService from '../../services/HttpService';

const _headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class BookmarkService extends HttpService {
  constructor() {
    super('/bookmarks/api');
  }

  getMyHubPredata() {
    this.endpoint = '/bookmarks/api/my-hub/predata';
    return this.get();
  }

  getBookmarkedCourses(data: { limit: number; ids_only: boolean }): Promise<any> {
    this.endpoint = '/bookmarks/api/courses';
    return this.get(data);
  }

  deleteBookmarkedCourses(data: { targets: Array<number> }, headers: Object): Promise<any> {
    this.endpoint = '/bookmarks/api/courses';
    return this.delete(data, headers);
  }

  postBookmarkCourses(data: { targets: Array<number> }): Promise<any> {
    this.endpoint = '/bookmarks/api/courses';
    return this.post(data, _headers);
  }

  postBookmarkGuides(data: { targets: Array<number> }): Promise<any> {
    this.endpoint = '/bookmarks/api/guides';
    return this.post(data, _headers);
  }

  deleteBookmarkedGuides(data: { targets: Array<number> }, headers: Object): Promise<any> {
    this.endpoint = '/bookmarks/api/guides';
    return this.delete(data, headers);
  }

  getBookmarkedEvents(data: { limit: number }): Promise<any> {
    this.endpoint = '/bookmarks/api/events';
    return this.get(data);
  }

  deleteBookmarkedEvents(data: { targets: Array<number> }, headers: Object): Promise<any> {
    this.endpoint = '/bookmarks/api/events';
    return this.delete(data, headers);
  }

  toggleBookmarkEvent(payload: Object, headers: Object): Promise<any> {
    this.endpoint = '/events/api/index';
    return this.postFormData(payload, headers);
  }

  postBookmarkEvent(payload: object, headers: Object): Promise<any> {
    this.endpoint = '/bookmarks/api/events';
    return this.postFormData(payload, headers);
  }

  postBookmarkResources(payload: object, headers: object): Promise<any> {
    this.endpoint = '/bookmarks/api/resources';
    return this.postFormData(payload, headers);
  }

  getBookmarkedResources(data: { limit: number; ids_only: boolean }): Promise<any> {
    this.endpoint = '/bookmarks/api/resources';
    return this.get(data);
  }

  /* Currently only support deletion of a single resource */
  deleteBookmarkedResources(data: { target: string }, headers: Object) {
    this.endpoint = '/bookmarks/api/resources';
    return this.delete(data, headers);
  }

  getBookmarkedMentors(data: { limit: number }): Promise<any> {
    this.endpoint = '/accounts/api/favorite_mentors';
    data['timezone_offset'] = new Date().getTimezoneOffset();
    return this.get(data);
  }

  deleteBookmarkedMentors(data: { target: string }, headers: Object) {
    this.endpoint = '/accounts/api/favorite_mentors';
    return this.delete(data, headers);
  }

  getBookmarkedCampusApps(data: { limit: number }): Promise<any> {
    this.endpoint = '/bookmarks/api/campusapps';
    return this.get(data);
  }

  deleteBookmarkedCampusApp(data: { target: string }) {
    this.endpoint = '/bookmarks/api/campusapps';
    return this.delete(data, _headers);
  }

  bookmarkCampusApp(data) {
    this.endpoint = '/bookmarks/api/campusapps';
    return this.post(data, _headers);
  }

  deleteBookmarkedQuest(data: { target: string }) {
    this.endpoint = '/bookmarks/api/quests';
    return this.delete(data, _headers);
  }

  bookmarkQuest(data) {
    this.endpoint = '/bookmarks/api/quests';
    return this.post(data, _headers);
  }

  bookmarkBlogs(data: { target: string }) {
    this.endpoint = '/bookmarks/api/blogs';
    return this.post(data, _headers);
  }

  deleteBookmarkBlogs(data: { target: string }) {
    this.endpoint = '/bookmarks/api/blogs';
    return this.delete(data, _headers);
  }
}
