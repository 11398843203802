<template>
  <section
    class="eu-notification"
    :class="isActive ? 'notificationActive' : ''"
    :style="notification.isError ? `background: #E65722` : `background: ${primaryColor}`"
  >
    <i :class="notification.icon ? `${notification.icon} eu-notification-icon` : 'eu-notification-icon'"></i>
    <span id="notification_title" tabindex="0" class="eu-notification-text">{{ notification.msg }}</span>
    <span tabindex="0" aria-label="Close notification" @click="$emit('close')" @keyup.enter="$emit('close')">
      <i class="far fa-times eu-notification-close" :style="notification.isError ? `color: #fff` : ''"></i>
    </span>
  </section>
</template>

<script lang='ts'>
import { isPartnerTenant } from "../utils/CustomUtils";
export default {
  name: "notification-popup",
  props: ["isActiveProp", "notification"],
  methods: {isPartnerTenant},
  watch: {
    isActiveProp: {
      immediate: true,
      handler(newValue, oldValue) {
        this.isActive = newValue;
      },
    },
  },
  data() {
    return {
      primaryColor: this.isPartnerTenant() ? "#2d98d2" : "#4c55a7",
      isActive: this.isActiveProp,
    };
  },
};
</script>
<style>
.eu-notification {
  display: none;
  padding: 20px;
  background: #4c55a7;
  color: #fff;
  position: fixed;
  top: 70px;
  right: 60px;
  font-size: 14px;
  border-radius: 10px;
}
@media only screen and (max-width: 576px) {
  .eu-notification {
    right: auto;
  }
}
.eu-notification-text {
  padding-right: 30px;
}
.eu-notification-close {
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.eu-notification-icon {
  padding-right: 10px;
  font-size: 16px;
}
.notificationActive {
  display: inline-block;
  z-index: 999;
}
</style>
