<template>
    <Modal class="eu-file-upload-modal" :isActiveProp="isModalActive" @close="closeModal">
        <h3 tabindex="0" id="upload_files_modal" role="heading">Upload file(s)</h3>

        <span class="error" v-show="error">{{ error }}</span>

        <div class="row base-file-container">

          <span
            v-for="(file, i) in files"
            v-bind:key="i"
            class="file-container"
          >
            <i class="fas fa-file-excel file-icon"></i>
            <span class="eu-upload-filename"> {{ file.name }} </span>
            <span tabindex="0" id="upload_error_modal"
                class="eu-upload-status"
                :class="{'error': file.status === 'failed'}"
            >({{ file.status }})</span>

            <span
                v-if="file.status == 'uploading'"
                class="eu-line eu-stripesLoaderAnimated"
            ></span>
            <span
                v-else-if="file.status == 'pending'"
                class="eu-line eu-stripesLoader line-pending"
            ></span>
            <span
                v-else-if="file.status == 'failed'"
                class="eu-line eu-stripesLoader line-failed"
            ></span>
            <span
                v-else-if="file.status == 'success'"
                class="eu-line eu-stripesLoader line-success"
            ></span>

            <p class="error" style="position: absolute;">{{ file.error }}</p>

            <span v-if="file.status === 'success'">
                <i class="fas fa-check-circle success"></i>
            </span>
            <span tabindex="0" class="failed_upload" aria-label="Remove file" role="button" @click="removeFile(file)" @keyup.enter="removeFile(file)" v-else-if="file.status === 'failed'">
                <i class="fas fa-trash-alt delete" ></i>
            </span>
          </span>
        </div>
        <div class="row" style="align-items: baseline">
          <div class="column" style="width: 100%">
            <div v-cloak @drop.prevent="handleFileUpload" @dragover.prevent class="innerRow dnd-wrapper">
              <label
                for="bulk-files"
                class="file-upload-label"
                :style="{ background: dragging ? '#eaeeff' : '' }"
                @dragenter="dragging = true"
                @dragleave="dragging = false"
              >
                <span>
                  <i class="fad fa-cloud-upload-alt dnd-upload-icon"></i> <br />
                  Drag &amp; drop files here <br />
                  or <br />
                <input
                    id="bulk-files"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    multiple
                    @change="handleFileUpload"
                />
                  <span class="desc-link">
                    Browse from device <i class="fal fa-angle-right desc-icon"></i>
                  </span>
                </span>
              </label>
            </div>
          </div>
          <label class="eu-checkbox-container accept-desc"
            >Accepted file types: <span style="font-weight: 500"> .xls, .xslx, .csv </span>
          </label>
        </div>

        <div class="checkbox-content-container">
            <label class="eu-checkbox-container eu-event-collection-checkmark-label">
            Send a confirmation email
                <input
                    type="checkbox"
                    id="email"
                    name="email"
                    v-model="sendInvites"
                    style="margin-right: 10px;"
                    @change="handleChangeInvites"
                >
                <span class="eu-checkmark"></span>
            </label>
        </div>

        <div class="button-container">
            <button
                class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                @click="postFiles"
            >Import<span v-show="sendInvites"> &amp; Send Invites</span></button>
            <button
                class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                @click="closeModal"
            >Close</button>
        </div>
    </Modal>
</template>
<script lang="ts">
import Cookies from 'js-cookie';
import { validateExtensionFile } from '../../../utils/TypecheckUtils';
import Modal from '../../../components/ModalExtended.vue';
import ImportService from "../../services/ImportService";
import { focusElement } from '../../../utils/CustomUtils';

export default {
    name: 'bulk-upload-modal',

    components: { Modal },

    props: [
        'isModalActive',
        'roles',
        'isRoleChangeable',
    ],

    data() {
        return {
            files: [],
            sendInvites: true,
            selectedRole: null,
            dragging: false,
            error: '',

            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
                Accept: 'application/json'
            },
        };
    },

    created() {
        if (!this.isRoleChangeable) {
            this.selectedRole = this.roles[0].name;
        }

        this.importService = new ImportService();
    },

    methods: {
        handleFileUpload(event) {
            const uploadFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
            if (!uploadFiles.length) return;

            const extensions = ['xlsx', 'xls', 'csv'];

            for (let i = 0; i < uploadFiles.length; i++) {

                let file = {
                    file_url: URL.createObjectURL(uploadFiles[i]),
                    file: uploadFiles[i],
                    name: uploadFiles[i].name,

                    import_type: 'BULK',
                    send_invites: this.sendInvites,
                    confirm: 'true',
                    role: this.selectedRole.toUpperCase(),
                    status: 'pending',
                };

                if (!validateExtensionFile(uploadFiles[i].name, extensions)) {
                    this.error = "Please use a valid document format: .xls, .xlsx, or .csv "
                } else {
                    this.files.push(file);
                }
            }
        },

        async postFiles() {
            this.$emit('started-upload');
            this.files.forEach(async file => {
                if (file.status === 'success') {
                    this.removeFile(file);
                } else {

                    file.status = 'uploading';

                    const result = await this.importService.postFormData(file, this.headers);

                    if (result.status === 'success' && result.total > 0) {
                        file.status = 'success';
                        focusElement("user-import-button");

                    } else {
                        focusElement("upload_error_modal");
                        file.status = 'failed';
                        file.error = `Upload failed` + (result.message ? `: ${result.message}` : ``);
                    }
                }
            });

        },

        handleChangeInvites() {
            for (let i = 0; i < this.files.length; i++) {
                let fileObject = this.files[i];

                fileObject.send_invites = this.sendInvites;
            }
        },

        removeFile(file) {
            this.files = this.files.filter(e => e.name !== file.name);
        },

        closeModal() {
            this.files = [];
            focusElement("user-import-button");
            this.$emit('close-modal');
        },
    },
}
</script>
<style scoped>
h3 {
    align-self: flex-start;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 30px;
    color: #666666;
    font-size: 25px;
    font-weight: 500;
}

.button-container {
    margin-bottom: 30px;
}

.base-file-container {
    align-items: baseline;
    overflow: hidden;
}

.line-pending {
    background-position: 0;
}

.line-success {
    background: revert;
    background-color: #4c55a7;
}

.line-failed {
    background-color: #E65722
}

.file-container {
    position: relative;
    margin: 25px 0;
}

.file-icon {
    color: #eaeeff;
    font-size: 50px;
}

.desc-link {
    color: #4c55a7;
    font-weight: 500;
    cursor: pointer;
}

.desc-icon {
    color: #5E50B5;
    font-size: 15px;
}

.accept-desc {
    padding-left: 0;
    margin-top: 25px;
    text-align: center;
    color: #707070;
    cursor: default;
}

.error {
    color: #E65722;
}

.success {
    position: absolute;
    color: #5fc6e5;
    font-size: 22px;
    right: 5px;
}

.delete {
    position: absolute;
    cursor: pointer;
    color: #E65722;
    font-size: 22px;
    right: 5px;
}

.checkbox-content-container {
    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;
    margin-bottom: 21px;
    max-height: fit-content;
}
</style>
