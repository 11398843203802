<template>
<section>
    <Modal :isActiveProp="isDeleteModalActive" @close="closeDeleteModal">
        <div class="row" style="width: auto">
            <div v-if="itemToDelete.title" style="text-align: center">
                <span class="eu-upload-filename" style="position: inherit"> {{ itemToDelete.tile }} </span>
                <p tabindex="0" id="event_delete_modal" style="padding-top: 30px">Are you sure you want to delete this {{itemToDelete.type_of_program_management}}?</p>
            </div>
        </div>
        <div class="row">
            <div class="column">
                <button
                    @click="removing ? '' : deleteItem(itemToDelete.id, itemToDelete.type_of_program_management)"
                    :aria-label="`Remove ${itemToDelete.title} ${itemToDelete.type_of_program_management}`"
                    class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                    style="width: fit-content; height: fit-content"
                >
                    <span v-if="!removing">Yes</span><span v-if="removing" style="pointer-events: none; opacity: 0.7">Removing...</span>
                </button>
                <button
                    @click="closeDeleteModal"
                    aria-label="Close modal"
                    class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                    style="width: fit-content; height: fit-content"
                >
                    Cancel
                </button>
            </div>
        </div>
    </Modal>
</section>
</template>
<script lang="ts">
import Modal from '../../components/ModalExtended.vue';

export default {
    components: {Modal},
    name: 'events-modals',
    props:['isDeleteModalActive','removing','itemToDelete'],

  data() {
    return {
    }
  },
  async created() {

  },

  methods:{
    closeDeleteModal() {
      this.$emit('close-delete-modal');
    },
    async deleteItem(id, type) {
       this.$emit('delete-item', id, type);
    },
  }

}

</script>
