export function getUserProps() {
  const userStatusDataComponent: HTMLElement = document.querySelector('.js-status-data');
  const loggedUser = JSON.parse(userStatusDataComponent.dataset.userStatus);
  const isAdmin = loggedUser.user.role === 'ADMIN';
  const isDeptLead = loggedUser.user.role === 'DEPARTMENT_LEAD' && loggedUser.user.dept;
  const userDepartment = loggedUser.user.dept || false;
  const isPartnerAdmin = loggedUser.user.partner_role === 'Partner Admin';
  const isPartnerManager = loggedUser.user.partner_role === 'Partner Manager';

  return { isAdmin, isDeptLead, userDepartment, isPartnerAdmin, isPartnerManager };
}

export function injectUserPropsToRoutes(routes) {
  return routes.map((route) => ({
    ...route,
    props: route.props
      ? typeof route.props === 'function'
        ? (r) => ({ ...route.props(r), ...getUserProps() })
        : { ...route.props, ...getUserProps() }
      : getUserProps,
  }));
}
