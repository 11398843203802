<template>
  <section>
    <div class="accounts-view-section">
      <Modal :isActiveProp="isDeleteModalActive" @close="closeDeleteModal">
        <div class="profile-notification-modal">
          <p id="remove_post_modal_title" tabindex="0" style="padding: 30px">
            Are you sure that you want to remove this post?
          </p>
          <div v-if="filePostDelete">
            <label class="eu-checkbox-container eu-event-collection-checkmark-label" style="margin-left: 30px">
              Remove file from library
              <input
                type="checkbox"
                id="email"
                name="email"
                v-model="deleteFileFromLibrary"
                style="margin-right: 10px"
              />
              <span class="eu-checkmark"></span>
            </label>
          </div>
          <div class="row" style="width: 100%">
            <div class="column">
              <button
                @click="removePost"
                class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                style="width: fit-content; height: fit-content"
              >
                Remove Post
              </button>
              <button
                @click="closeDeleteModal"
                class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                style="width: fit-content; height: fit-content"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal :isActiveProp="isPostModalActive" @close="closePostModal">
        <div class="eu-feed-post-modal">
          <div v-if="!showFileDialog && !isLoadingUpload">
            <div id="create-post-title" class="eu-create-post-title" tabindex="0">Create a Post</div>
            <div class="eu-create-post-add-file-wrapper">
              <label for="add-post-file" style="cursor: pointer">
                <i class="fas fa-plus eu-create-post-add-file-icon"></i>
                <span class="eu-create-post-add-file-text">Add a file</span>
              </label>
              <input
                @change="handleFileUpload"
                id="add-post-file"
                type="file"
                aria-label="Choose file to attach."
                multiple
              />
            </div>
            <div class="eu-create-post-wrapper" style="padding-left: 0">
              <img alt="Logged user profile picture" class="eu-feed-thumbnail-bg" :src="loggedUser.profile_pic" />
              <span class="eu-feed-user-name">{{ loggedUser.fullname }}</span>
              <textarea
                class="eu-feed-textarea eu-feed-textarea-white"
                @change="updatePostText"
                aria-label="Write a post."
                :value="postText"
                placeholder="Write something..."
                type="text"
                rows="2"
              />

              <!-- one image item -->
              <img
                v-if="files.length === 1 && files[0].resource_type === 'IMG'"
                class="eu-feed-image-frame"
                :src="files[0].resource_url"
              />
              <!-- multiple images -->
              <div
                v-if="files.length > 1 || (files.length === 1 && files[0].resource_type === 'DOC')"
                class="eu-feed-multiple-files"
              >
                <div tabindex="0" v-for="f of files" :key="f.id" class="eu-feed-file-wrapper">
                  <div v-if="f.resource_type == 'IMG'">
                    <span
                      class="eu-feed-image-preview"
                      :style="{ backgroundImage: 'url(' + f.resource_url + ')' }"
                    ></span>
                    <span class="eu-feed-file-preview-text">{{ f.name }}</span>
                  </div>
                  <div v-if="f.resource_type == 'DOC'">
                    <i class="fas fa-file-upload eu-feed-file-preview"></i>
                    <span class="eu-feed-file-preview-text">{{ f.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="width: 100%">
              <div class="column">
                <button
                  @click="createPostCheck"
                  class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                  style="width: fit-content; height: fit-content"
                  aria-label="Submit a post."
                >
                  Create Post
                </button>
                <button
                  @click="closePostModal"
                  class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                  style="width: fit-content; height: fit-content"
                  aria-label="Cancel creating a post."
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div v-if="showFileDialog && !isLoadingUpload">
            <div class="eu-create-post-dialog-title">
              Your post contains a file. Would you like to save it to the resource library?
            </div>
            <div class="eu-create-post-dialog-desc">
              Warning to indicate the library should only be used for content useful to the entire department.
            </div>
            <div class="row" style="width: 100%">
              <div class="column">
                <button
                  @click="createPost(true)"
                  id="add-to-library-btn"
                  class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary"
                  style="width: fit-content; height: fit-content"
                  aria-label="Create post and add resource to library."
                >
                  Yes
                </button>
                <button
                  @click="createPost(false)"
                  id="create-post-only-btn"
                  class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary"
                  style="width: fit-content; height: fit-content"
                  aria-label="Create post without adding resource to library."
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <div v-if="isLoadingUpload">
            <loading-spinner />
          </div>
        </div>
      </Modal>
    </div>
  </section>
</template>
<script lang="ts">
import Modal from "../../components/ModalExtended.vue";
import { getYoutubeOrVimeoEmbedLink } from "../../utils/CustomUtils";
import LoadingSpinner from "../../components/LoadingSpinner.vue";

export default {
  components: { Modal, LoadingSpinner },
  name: "feed-modals",
  props: [
    "isDeleteModalActive",
    "isPostModalActive",
    "postText",
    "showFileDialog",
    "loggedUser",
    "files",
    "isLoadingUpload",
    "filePostDelete",
  ],
  data() {
    return {
      deleteFileFromLibrary: false,
    };
  },
  async created() {},
  methods: {
    createPost(option) {
      this.$emit("create-post", option, false);
    },

    updatePostText(event) {
      this.$emit("update-post-text", event.target.value);
    },
    handleFileUpload(event) {
      this.$emit("handle-file-upload", event);
    },

    createPostCheck() {
      this.$emit("create-post-check");
    },

    closePostModal() {
      if (!this.isLoadingUpload) this.$emit("close-post-modal");
    },
    closeDeleteModal() {
      this.$emit("close-delete-modal");
    },
    async removePost() {
      if (this.filePostDelete) {
        this.$emit("remove-post", this.deleteFileFromLibrary);
      } else {
        this.$emit("remove-post", false);
      }
    },
    async downloadItem(dataurl, filename, id) {
      this.$emit("download-item", dataurl, filename, id);
    },
    getYoutubeOrVimeoEmbedLink,
  },
};
</script>
