<template>
  <section class="profile-settings-page flex-column-center">
    <div class="main-wrapper">
      <header>
        <h1>Settings</h1>
      </header>
      <div class="profile-settings-container">
        <settings-sidebar :activeTab="activeTab" />
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
import SettingsSidebar from "./SettingsSidebar.vue";

export default {
  name: "SettingsWrapper",
  components: {
    SettingsSidebar
  },
  props: ['activeTab']
};
</script>

<style>
</style>