<template>
  <section>
    <section class="partner-tenant-home-hero-container"
    style="background-image: url('https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/BeGlobalii-homepage-hero.webp');">
      <h1 class="partner-tenant-home-hero-text">
        Unlocking the World &amp; Connecting You
        to Opportunity
      </h1>
      <div>
        <button
          class="partner-tenant-home-hero-button-primary"
          aria-label="Go to sign up page"
          @click="redirectTo('/accounts/register')"
        >
          <b>Get Started</b> - it's free!
        </button>
      </div>
    </section>
    <section class="partner-tenant-home-basic-info-container">
      <div class="partner-tenant-home-basic-info-text-container">
        <h2 class="partner-tenant-home-basic-info-title">Connect with the World</h2>
        <p class="partner-tenant-home-basic-info-description">
          The BeGlobalii community inspires, connects,
          and assists you in reaching your academic and career
          potential in our increasingly interconnected world.
        </p>
      </div>
      <div class="partner-tenant-home-basic-info-images-container">
        <div class="partner-tenant-home-basic-info-image-container">
          <img
            src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/dabi-laniding-connect-3.png"
            class="partner-tenant-home-basic-info-image"
            alt="Image 1"
          >
          <div class="partner-tenant-home-basic-info-image-text">
            Join Our Community
          </div>
        </div>
        <div class="partner-tenant-home-basic-info-image-container">
          <img
            src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/dabi-laniding-connect-2.webp"
            class="partner-tenant-home-basic-info-image"
            alt="Image 2"
          >
          <div class="partner-tenant-home-basic-info-image-text">
            Find the Right Opportunity
          </div>
        </div>
        <div class="partner-tenant-home-basic-info-image-container">
          <img
            src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/dabi-laniding-connect-3.webp"
            class="partner-tenant-home-basic-info-image"
            alt="Image 3"
          >
          <div class="partner-tenant-home-basic-info-image-text">
            Access Articles, Guides &amp; Tips
          </div>
        </div>
      </div>
      <div class="partner-tenant-home-basic-info-button-container">
        <button
          class="partner-tenant-home-basic-info-button-outlined"
          aria-label="Go to sign up page"
          @click="redirectTo('/accounts/register')"
        >
          Get Started
        </button>
      </div>
    </section>
    <partner-tenant-opportunities></partner-tenant-opportunities>
    <partner-tenant-testimonials></partner-tenant-testimonials>
    <section class="partner-tenant-home-partners-container">
      <div class="partner-tenant-home-partners-text-container">
        <div class="partner-tenant-home-partners-title">Our Partners</div>
        <div class="partner-tenant-home-partners-description">
          Connect with our partners and unlock a world of opportunities that will help you achieve your goals and aspirations
        </div>
      </div>
      <div class="partner-tenant-home-partners-logos-container">
        <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/daad-logo.png" class="partner-tenant-home-partners-logo" />
        <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/london-met-logo.png" class="partner-tenant-home-partners-logo" />
        <img src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/ies-logo.png" class="partner-tenant-home-partners-logo" />
      </div>
    </section>
    <section class="partner-tenant-home-join-us-container">
      <div class="partner-tenant-home-join-us-image-container">
        <div class="partner-tenant-home-join-us-text-container">
          <div class="partner-tenant-home-join-us-text">
            See how BeGlobalii can help you
            achieve your education and career goals
          </div>
          <div>
            <button
              class="partner-tenant-home-join-us-button"
              aria-label="Go to sign up page"
              @click="redirectTo('/accounts/register')"
            >
              Join our community
            </button>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import PartnerTenantOpportunities from './components/PartnerTenantOpportunities.vue';
import PartnerTenantTestimonials from './components/PartnerTenantTestimonials.vue';
import PartnerTenantFooter from './components/PartnerTenantFooter.vue';

export default {
  name: 'partner-tenant-landing-app',

  components: {
    PartnerTenantOpportunities,
    PartnerTenantTestimonials,
    PartnerTenantFooter,
  },

  data() {
    return {

    }
  },

  methods: {
    redirectTo(url) {
      window.location.href = url;
    }
  },
}
</script>
