<template>
  <div>
    <input type="file" name="dropzone-image-input" :id="`dropzone-image-input-default-${id}`" class="js-input-file-control da-components-hidden-file-control" accept="image/*" maxlength="400" />
    <p class="error" v-if="error">{{ error }}</p>
    <div v-if="src" class="thumbnail-container" style="display: flex; align-items: center;">

      <img :src="src" alt="this is where your selected image should be" class="eu-profile-picture" :style="dialogDisplay ? '' : 'margin: 0 24px 0 17px;'">


      <input @change="handleImageUpload" id="small-change-image" type="file" accept="image/*" />
      <div class="iu-settings-logo-buttons-container">
        <button aria-label="Change image" for="small-change-image" class="iu-main-btn small gray solid" @click="handleInput" @keyup.enter="handleInput" type="button">
          <i class="fa-solid fa-camera iu-main-button-front-icon"></i>{{ changeButtonText }}
        </button>
        <button aria-label="Remove image" class="iu-main-btn small gray solid" v-on:click="removeImage" type="button">
          <i class="far fa-trash-alt iu-main-button-front-icon"></i>Delete
        </button>
      </div>
    </div>

    <div v-else class="row" :style="dialogDisplay ? 'margin: 0; gap: 12px;' : 'margin-left: 24px;'">
      <div class="column">
        <div v-cloak @click="handleInput" @drop.prevent="handleImageUpload" @dragover.prevent class="innerRow">
          <label
            @dragenter="dragging = true"
            @dragleave="dragging = false"
            class="image-upload-label"
            :style="{ background: dragging ? '#eaeeff' : '' }"
            for="imageInput"
          >
            <span class="dropzone-help-text">
              <i class="fa-duotone fa-cloud-arrow-up" style="font-size: 32px; color: #9f87e0; opacity: 0.3;"></i>
            </span>
          </label>
        </div>
      </div>
      <div class="small-dz-text-container" :style="dialogDisplay ? 'margin: 0; display: flex; flex-direction: column; gap: 8; height: 100%; justify-content: center;' : ''">
        <div class="clickable" tabindex="0" @click="handleInput"  @keyup.enter="handleInput">
          <div class="iu-settings-logo-buttons-container">
            <button aria-label="Change image" for="small-change-image" class="iu-main-btn small gray solid"  type="button">
              <i class="fa-solid fa-camera iu-main-button-front-icon"></i>{{ uploaderText }}
            </button>
          </div>
        </div>
        <div>
          <span class="small-dz-help-text">
            .jpeg or .png, no larger than {{ maxImageSizeFormat }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { useTimeout } from '@vueuse/core';
import { validateExtensionFile } from "../../utils/TypecheckUtils";

export default {
  name: "dropzone-component",
  props: [
    'id',
    'imageSrc',
    'imageInput',
    'maxImageSize',
    'maxImageSizeFormat',
    'uploaderText',
    'changeButtonText',
    'isOpen',
    'dialogDisplay'
  ],

  data() {
    return {
      dragging: false,
      error: "",
      src: '',
      localInput: null,
      timeoutId: null,
    };
  },

  created() {
    if (this.imageInput) {
      this.localInput = this.imageInput;
      this.localInput.addEventListener('change', e => {
        this.handleImageUpload(e);
      });
    } else {
      this.timeoutId = setTimeout(() => {
        this.localInput = document.getElementById(`dropzone-image-input-default-${this.id}`);
        this.localInput.addEventListener('change', e => {
          this.handleImageUpload(e);
        });
      }, 500);
      ;
    }

    this.src = this.imageSrc;
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (this.localInput) {
      this.localInput.removeEventListener('change', this.handleImageUpload);
    }
  },
  watch: {
    imageSrc: function(newVal, oldVal) {
      this.src = newVal;
    },
    isOpen: function(newVal, oldVal) {
      if(!newVal) {
        this.removeImage();
      }
    }
  },
  methods: {
    handleInput() {
      this.localInput.click();
    },

    handleImageUpload(event) {
      const images = event.dataTransfer ? event.dataTransfer.files: event.target.files;
      const imageExt = ["jpg", "jpeg", "png"];
      let fileObject = {
        file: images[0],
        name: images[0].name,
        fileUrl: URL.createObjectURL(images[0]),
      }

      if (!validateExtensionFile(images[0].name, imageExt)) {
        this.error = "Please use a supported image filetype."

        return;
      }

      if (images[0].size > this.maxImageSize) {
        this.error = `Please use an image file under ${this.maxImageSizeFormat}.`

        return;
      }

      this.error = "";
      this.src = fileObject.fileUrl;

      this.localInput.files = images;
      //event.srcElement.value = "";
      this.$emit('uploadImage', fileObject);
    },

    removeImage() {
      this.src = '';

      this.$emit('removeImage');
    }
  }
}

</script>
