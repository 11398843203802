<template>
  <section>
    <notification />
    <vue-progress-bar></vue-progress-bar>
    <partner-tenant-header></partner-tenant-header>
    <router-view></router-view>
    <partner-tenant-footer></partner-tenant-footer>
  </section>
</template>

<script>
import PartnerTenantHeader from './components/PartnerTenantHeader.vue';
import PartnerTenantFooter from './components/PartnerTenantFooter.vue';
import Notification from '../../../common/Notification.vue';

export default {
  name: 'partner-tenant-landing-app',

  components: {
    PartnerTenantHeader,
    PartnerTenantFooter,
    Notification
  },

  data() {
    return {

    }
  },

  methods: {

  },
}
</script>
