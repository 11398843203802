<template>
  <table class="eu-resource-table">
    <tr class="eu-resource-row">
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="toggleAll()"
          @keyup.enter="toggleAll()"
          aria-label="Select all users in table"
          role="checkbox"
          :class="selectedAll ? 'eu-resource-cell-select eu-resource-cell-select-active' : 'eu-resource-cell-select'"
        ></span>
        <span
          tabindex="0"
          @click="sort('first_name')"
          @keyup.enter="sort('first_name')"
          aria-label="Sort table by first name"
          role="button"
          >
          First name
          <i :class="sortBy !== 'first_name' ? 'fas fa-sort' : sortDirection === 'up' ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('last_name')"
          @keyup.enter="sort('last_name')"
          aria-label="Sort table by last name"
          role="button"
          >
          Last name
          <i :class="sortBy !== 'last_name' ? 'fas fa-sort' : sortDirection === 'up' ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('email')"
          @keyup.enter="sort('email')"
          aria-label="Sort table by email"
          role="button"
          >
          Email
          <i :class="sortBy !== 'email' ? 'fas fa-sort' : sortDirection === 'up' ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('role')"
          @keyup.enter="sort('role')"
          aria-label="Sort table by role"
          role="button"
          >
          Role
          <i :class="sortBy !== 'role' ? 'fas fa-sort' : sortDirection === 'up' ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th v-if="!isPartnerTenant() && areDeptsEnabled" class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('department')"
          @keyup.enter="sort('department')"
          aria-label="Sort table by department"
          role="button"
          >
           {{deptSingular}}
          <i :class="sortBy !== 'department' ? 'fas fa-sort' : sortDirection === 'up' ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('date_joined')"
          @keyup.enter="sort('date_joined')"
          aria-label="Sort table by date added"
          role="button"
          >
          Added
          <i :class="sortBy !== 'date_joined' ? 'fas fa-sort' : sortDirection === 'up' ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('last_login')"
          @keyup.enter="sort('last_login')"
          aria-label="Sort table by date added"
          role="button"
          >
          Last Login
          <i :class="sortBy !== 'last_login' ? 'fas fa-sort' : sortDirection === 'up' ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('status')"
          @keyup.enter="sort('status')"
          aria-label="Sort table by account status"
          role="button"
          >
          Status
          <i :class="sortBy !== 'status' ? 'fas fa-sort' : sortDirection === 'up' ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th></th>
    </tr>
    <tr
      v-for="(item, i) in students"
      :key="i"
      :class="selected.includes(item.id) ? 'eu-resource-row eu-resource-row-selected' : 'eu-resource-row'"
      style="white-space: nowrap;"
    >
      <td class="eu-resource-cell eu-resource-cell-name" style="display:flex; align-items: center;">
        <span
          tabindex="0"
          @click="toggleStudent(item.id)"
          @keyup.space="toggleStudent(item.id)"
          :aria-label="`Select ${item.first_name} ${item.last_name}`"
          role="checkbox"
          :class="selected.includes(item.id) ? 'eu-resource-cell-select eu-resource-cell-select-active' : 'eu-resource-cell-select'"
        ></span>

        <img class="eu-profile-thumbnail" alt="profile img" style="width:32px;height:32px;"  :src="item.profile_pic ? item.profile_pic : '/static/assets/icons/default-profile.svg'"  />
        <span class="bold iu-table_text_clickable" role="button" aria-label="Open edit user dialog" @click="editStudent(item)" @keyup.enter="editStudent(item)">{{ item.first_name }}</span>
      </td>
      <td class="eu-resource-cell eu-resource-cell-name">
        <span class="bold iu-table_text_clickable" role="button" aria-label="Open edit user dialog" @click="editStudent(item)" @keyup.enter="editStudent(item)">{{ item.last_name }}</span>
      </td>
      <td class="eu-resource-cell">
        {{ item.email }}
      </td>
      <td class="eu-resource-cell">
        {{ item.partner_role ? item.partner_role : item.role && capitalizeFirstLetter(item.role.name) }}</td>
      <td v-if="!isPartnerTenant() && areDeptsEnabled" class="eu-resource-cell">
        {{ item.department }}
      </td>
      <td class="eu-resource-cell">
        {{ item.added }}
      </td>
      <td class="eu-resource-cell">
        {{ item.last_login }}
      </td>
      <td class="eu-resource-cell">
        {{ userStatuses[i] }}
      </td>

      <td class="iu-table-action-cell">
        <button
          ref="menuDots"
          :id="`more_option_managa_accounts_${item.id}`"
          tabindex="0"
          aria-label="Burger menu"
          role="menu"
          aria-haspopup="true"
          class="iu-table-action-button"
          @click="showMenu(item)"
          @keyup.enter="showMenu(item)"
        >
          <i class="fas fa-ellipsis-v" ></i>
          <div
            :id="'i' + item.id"
            ref="menu"
            v-if="activeBurgerMenu == item.id"
            v-closable="{exclude:['menuDots', 'menu',], handler: 'closeMenu'}"
            class="iu-table-burger-menu"
          >

            <div
              tabindex="0"
              class="iu-table-burger-menu-item"
              @click="editStudent(item)"
              @keyup.enter="editStudent(item)"
              :aria-label="`Open edit ${item.first_name} ${item.last_name} modal`"
              role="button"
              aria-haspopup="dialog"
            >
              <i class="fa-solid fa-pencil" style="padding-right: 5px"></i>
              Edit User
            </div>

            <div
              tabindex="0"
              class="iu-table-burger-menu-item"
              @click="viewProfile(item)"
              @keyup.enter="viewProfile(item)"
              :aria-label="`Open view ${item.first_name} ${item.last_name} profile modal`"
              role="button"
              aria-haspopup="dialog"
            >
              <i class="fa-solid fa-eye" style="padding-right: 5px"></i>
              View Profile
            </div>

            <div
              tabindex="0"
              v-if="!item.account_validated"
              class="iu-table-burger-menu-item"
              @click="sendEmail(item.id)"
              @keyup.enter="sendEmail(item.id)"
              :aria-label="`Send ${item.first_name} ${item.last_name} a welcome email`"
              role="button"
              >
              <i class="fa-solid fa-envelope" style="padding-right: 5px"></i>
              Send invitation
            </div>

            <div
              tabindex="0"
              class="iu-table-burger-menu-item"
              @click="deleteStudent(item)"
              @keyup.enter="deleteStudent(item)"
              :aria-label="`Open delete ${item.first_name} ${item.last_name} modal`"
              role="button"
              aria-haspopup="dialog"
            >
              <i class="fa-solid fa-trash-alt" style="padding-right: 5px"></i>
              Delete
            </div>
          </div>
        </button>
      </td>
    </tr>
  </table>
</template>
<script lang="ts">
import handleOutsideClick from '../../directives/handleOutsideClick';
import { isPartnerTenant } from "../../utils/CustomUtils";
import { useCommonDataStore } from '../../stores/commonData';

export default {
  name: 'students-table',

  directives: { closable: handleOutsideClick },

  props: [
    'students',
    'sortDirection',
    'sortBy',
    'selected',
    'selectedAll',
    'areDeptsEnabled'
  ],

  data() {
    return {
      activeBurgerMenu: null,
    };
  },

  computed: {
    userStatuses() {
      return this.students.map(function(item) {
        if (item.disabled) {
          return "Disabled"
        }

        if (item.account_validated) {
          return "Active"
        }

        if (item.email_status) {
          return "Not active"
        } else {
          return "Invitation not sent"
        }
      });
    },
     deptSingular() {
      return useCommonDataStore().getFeatureName('department_singular')
    },
  },

  methods: {
    viewProfile(item) {
      this.$emit('view-profile', item);
    },
    isPartnerTenant,
    showMenu(item) {
      if (item.id == this.activeBurgerMenu) {
        this.activeBurgerMenu = null;
      } else {
        this.activeBurgerMenu = item.id;
      }
    },

    sort(column: string){
      this.$emit('sort', column);
    },

    closeMenu(){
      this.activeBurgerMenu = null;
    },

    toggleStudent(id){
      this.$emit('toggle-student', id);
    },

    toggleAll() {
      this.$emit('toggle-all');
    },

    suspendStudent(id) {
      this.$emit('suspend-student', id);
    },

    editStudent(item) {
      this.$emit('edit-student', item);
    },

    editRole(item) {
      this.$emit('edit-role', item);
    },

    sendEmail(id) {
      this.$emit('send-email', id);
    },

    deleteStudent(item) {
      this.$emit('delete-student', item);
    },

    resetUser(item) {
      this.$emit('reset-user', item);
    },

    resetPassword(item) {
      this.closeMenu();
      this.$emit('reset-password', item);
    },
    capitalizeFirstLetter(string) {
        if(string)
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        else return '';
    },
  },
}

</script>
