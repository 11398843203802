<template>
    <section class="one-stop-section">
        <div class="one-stop-section-header">
            <h2>Opportunities</h2>
            <router-link to="/events?page=saved" aria-label="Go to your saved opportunities" class="iu-main-btn main text-only small" style="white-space: nowrap;">See All<i class="fa-regular fa-chevron-right iu-main-button-arrow-icon"></i></router-link>
        </div>
        <div v-if="!isLoading" class="myhub-scrollable-wrapper">
            <browse-saved-events
                :events="events"
                @remove-bookmark="removeBookmark"
            />
        </div>
        <loading-spinner v-else />
    </section>
</template>
<script lang="ts">
import "../../events/utils/DateFormat";
import Cookies from 'js-cookie';
import BookmarkService from "../services/BookmarkService";
import BrowseSavedEvents from "./BrowseSavedEvents.vue";
import LoadingSpinner from '../../components/LoadingSpinner.vue';

export default {
    name: "interface-saved-events",

    components: {
        BrowseSavedEvents,
        LoadingSpinner,
    },

    data() {
        return {
            events: [],
            isLoading: true,

            service: new BookmarkService(),
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
                Accept: 'application/json'
            },
        }
    },

    created() {
        this.getSavedEvents();
    },

    methods: {
        async getSavedEvents() {
            this.isLoading = true;
            const result = await this.service.getBookmarkedEvents({
                limit: 4,
            });
            this.events = result['events'];
            if (this.events.length === 0) {
                this.$emit('empty');
            }
            this.isLoading = false;
        },

        async removeBookmark(id: string) {
            await this.service.deleteBookmarkedEvents({
                targets: [id]
            }, this.headers);
            this.getSavedEvents();
        },
    },
}
</script>
