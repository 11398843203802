<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
    <div class="analytics-header-container">
      <div class="analytics-title-wrapper">
        <common-title
          title="Analytics"
          :description="description_text"
        />
      </div>
      <div id="main" class="eu-simple-navbar">
        <router-link to="/analytics/detailed/users" class="eu-simple-navbar-item eu-simple-navbar-item-active"
          >Detailed Reports
        </router-link>
      </div>
    </div>

    <div class="analytics-content-container">
      <div class="analytics-button-container">
        <select class="eu-select" @change="change" v-model="selectedPage" style="width: 100%">
          <option v-for="(option, i) in detailed_pages" v-bind:value="option.value" v-bind:key="option.title + i">
            {{ option.title }}
          </option>
        </select>
        <i class="fal fa-angle-down eu-select-arrow analytics-arrow"></i>

        <button
          v-if="!isLoading"
          @click="toggleOptionsDropdown"
          class="iu-main-btn main solid small iu-analytics-download-button"
        >
          Download
          <i class="fal fa-angle-down iu-main-button-arrow-icon"></i>
        </button>
        <div v-if="optionsDropdownVisible && !isLoading" class="download-dropdown">
          <span
            tabindex="0"
            @click="generateReport(page)"
            @keyup.enter="generateReport(page)"
            class="eu-upload-dropdown-element"
            aria-label="Download report for this page"
          >
            This Report
          </span>
          <span
            tabindex="0"
            @click="generateReport('all')"
            @keyup.enter="generateReport('all')"
            class="eu-upload-dropdown-element"
            aria-label="Download all analytics report"
          >
            All Reports
          </span>
        </div>
        <div v-if="isLoading" style="margin-left: 10px">
          <loading-spinner />
        </div>
      </div>

      <div style="margin-top: 25px">
        <analytics-calendar @onChange="filter" keys="keys" />
      </div>

      <analytics-card v-if="selectedPage === `users`" typeOfCards="Users" :typeFlag="typeFlag" :flag="typeOfCards">
        <template #header>
          <analytics-card-header title="Users" :has_icon="false" @clicked="show" />
        </template>
        <template #body>
          <div class="template-body-wrapper">
            <div class="template-body-left">
              <analytics-tab-container-fetch-data
                keys="users_overview"
                :has_select_filter_focus_area="false"
                :is_pie_chart="true"
                :data="{
                  has_select_filter: false,
                  has_select_filter_department: false,
                  has_select_filter_focus_area: false,
                }"
                :popularity_is_chart="true"
              />
            </div>
            <div class="template-body-right">
              <analytics-tab-container-fetch-data
                keys="users_sessions"
                :has_select_filter_focus_area="false"
                :filtersData="filtersData"
                :is_pie_chart="false"
                :data="{
                  has_select_filter: false,
                  has_select_filter_department: false,
                  has_select_filter_focus_area: false,
                }"
                :popularity_is_chart="true"
              />
            </div>
          </div>
        </template>
      </analytics-card>

      <analytics-card
        :typeFlag="typeFlag"
        v-for="data in this.cards_data"
        :key="data.title"
        :typeOfCards="data.title"
        :flag="typeOfCards"
      >
        <template #header>
          <analytics-card-header
            :title="data.title"
            :icon="data.icon"
            :color="data.color"
            :isImg="data.isImg"
            :url="data.url"
            :has_icon="data.has_icon"
            @clicked="show"
          />
        </template>
        <template #body>
          <analytics-body-card-fetch-data v-if="data.has_body" :keys="data.cards" :filtersData="filtersData" />
          <analytics-card-table-fetch-data
            v-if="data.has_table"
            :keys="data.cards"
            :filters_options="filters_options"
            :filtersData="filtersData"
            :data="data"
          />
          <analytics-tab-container-fetch-data
            v-if="data.has_chart"
            :keys="data.cards"
            :filters_options="filters_options"
            :filtersData="filtersData"
            :is_pie_chart="false"
            :data="data"
            :is_buble_chart="data.has_bubble_chart"
            :popularity_is_chart="true"
          />
          <analytics-chart-fetch-data
            v-if="data.has_column_chart || (data.has_bubble_chart && !data.has_chart)"
            :is_column_chart="true"
            :is_pie_chart="false"
            :is_buble_chart="data.has_bubble_chart"
            :paramsOptional="params"
            :keys="data.cards"
            :items_domain="getItemDomain(data)"
          />
        </template>
        <template #footer>
          <analytics-card-footer v-if="data.has_footer" :link="data.link" />
        </template>
      </analytics-card>
      <analytics-card :typeFlag="typeFlag" typeOfCards="Interests by Popularity" :flag="typeOfCards">
        <template #header>
          <analytics-card-header
            title="Interests by Popularity"
            :has_switch_option="true"
            :isImg="false"
            :has_icon="false"
            @clicked="show"
            @switch_view="switchView"
          />
        </template>
        <template #body>
          <analytics-tab-container-fetch-data
            keys="interests_by_popularity"
            :popularity_is_chart="popularity_is_chart"
            :filters_options="filters_options"
            :filtersData="filtersData"
            :is_pie_chart="false"
            :data="{
              has_select_filter: false,
              has_body_tab: true,
            }"
            :is_buble_chart="true"
          />
        </template>
        <template #footer> </template>
      </analytics-card>
    </div>
    </div>
  </div>
</template>

<script>
import AnalyticsTabContainerFetchData from '../tabItem/AnalyticsTabContainerFetchData.vue';
import AnalyticsCardTableFetchData from '../AnalyticsCardTableFetchData.vue';
import AnalyticsBodyCardFetchData from '../AnalyticsBodyCardFetchData.vue';
import AnalyticsChartFetchData from '../chart/AnalyticsChartFetchData.vue';
import LoadingSpinner from '../../../components/LoadingSpinner.vue';
import AnalyticsService from '../../services/AnalyticsService';
import AnalyticsCardHeader from '../AnalyticsCardHeader.vue';
import AnalyticsCardFooter from '../AnalyticsCardFooter.vue';
import AnalyticsCalendar from '../AnalyticsCalendar.vue';
import AnalyticsCard from '../AnalyticsCard.vue';
import Cookies from 'js-cookie';
import CommonTitle from '../../../components/CommonTitle.vue';

export default {
  components: {
    CommonTitle,
    AnalyticsTabContainerFetchData,
    AnalyticsCardTableFetchData,
    AnalyticsBodyCardFetchData,
    AnalyticsChartFetchData,
    AnalyticsCardHeader,
    AnalyticsCardFooter,
    AnalyticsCalendar,
    LoadingSpinner,
    AnalyticsCard,
  },
  props: ['initialPage'],
  data() {
    return {
      typeFlag: '',
      typeOfCards: '',
      selectedPage: this.initialPage,
      optionsDropdownVisible: false,
      isLoading: false,
      filtersData: {
        start_date: null,
        end_date: null,
        keys: '',
      },
      params: {
        type: Object,
        default: function () {
          return {};
        },
      },
      headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        Accept: 'application/json',
      },
      service: new AnalyticsService(),
      departments: [],
      focus_area: [],
      groups: [],
      identities: [],
      page: 'users',
      cards_data: {},
      detailed_pages: [],
      popularity_is_chart: true,
      permission_level: null,
    };
  },
  computed: {
    description_text() {
      if (this.permission_level === 'DEPARTMENT') {
        return 'These analytics are for your department only. \nView and download detailed reports of your features.'
      }

      return 'View and download detailed reports of your features.';
    },
    filters_options() {
      return {
        departments: this.departments,
        focus_area: this.focus_area,
        groups: this.groups,
        identities: this.identities,
      };
    },
  },
  async created() {
    this.loading = true;
    const predata = await this.service.getPreData(this.selectedPage);
    this.departments = JSON.parse(predata.departments);
    this.focus_area = JSON.parse(predata.focus_area);
    this.groups = predata.groups ? JSON.parse(predata.groups) : [];
    this.identities = JSON.parse(predata.identities);
    this.page = predata.page;
    this.cards_data = JSON.parse(predata.cards_data);
    this.detailed_pages = predata.detailed_pages;
    this.permission_level = predata.permission_level;
    this.loading = false;
  },
  methods: {
    show(typeOfCards, typeFlag) {
      this.typeOfCards = typeOfCards;
      this.typeFlag = typeFlag;
    },
    change() {
      this.$router.push(`/analytics/detailed/${this.selectedPage}`);
    },
    toggleOptionsDropdown() {
      this.optionsDropdownVisible = !this.optionsDropdownVisible;
    },
    async generateReport(name) {
      this.isLoading = true;
      await this.service.generateReport(this.headers, name);
      this.isLoading = false;
    },
    filter(key, date) {
      this.filtersData = {
        start_date: date == null ? '' : date[0],
        end_date: date == null ? '' : date[1],
        keys: key,
      };
      this.params = {
        ...this.params,
        start_date: this.filtersData.start_date,
        end_date: this.filtersData.end_date,
      };
    },
    getItemDomain(data) {
      if (data.focus_area) return this.filters_options.focus_area;

      return [];
    },
    switchView() {
      this.popularity_is_chart = !this.popularity_is_chart;
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-title-wrapper {
  padding: 24px 0;
}
.analytics-section {
  padding: 24px 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.analytics-header-container {
  width: 100%;
  max-width: 1400px;
}

.analytics-content-container {
  width: 100%;
  max-width: 1400px;
}

.title {
  font-size: 40px;
  line-height: 1.2;
  color: #333;
}

@media screen and (max-width: 576px) {
  .analytics-section {
    padding: 20px 16px;
  }
}
</style>
