<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <div class="manage-event-header">
        <div class="groups-title-wrapper">
          <common-title :title="title_text" :description="description_text" />
        </div>
      </div>
      <div
        class="event-search-manage-container mobile-full-w"
        :style="`background: url('${assetPath}assets/groups_and_depts_hero.webp') no-repeat 50% 35%; background-size: cover; position:relative; flex-direction: column;margin-top:0`"
      >
        <div class="blackLayer"></div>
        <div class="search-container-hero">
          <div style="display: flex; align-items: center; justify-content: center; flex-direction: column">
            <h1 class="search-hero-title">Build Your Community</h1>
            <!-- <p class="search-hero-description"></p> -->
          </div>
          <form
            action=""
            @submit.prevent="fetchData()"
            class="eu-search-form"
            style="margin-top:24px; width:80%, position:relative;"
            role="search"
          >
            <input v-model="search_input" name="q" type="search" placeholder="Search" class="eu-search-input" />
            <button
              class="eu-search-btn-icon"
              style="
                padding: 14px 19px 10px 15px;
                right: 0;
                height: 50px;
                background-color: #5e50b5;
                border-radius: 0px 8px 8px 0;
              "
              aria-label="search"
            >
              <i class="eu-search-icon fas fa-search" style="color: #fff"></i>
            </button>
          </form>
        </div>
      </div>
      <div class="filter-container">
        <multiselect-filter-component
          :additionalStyle="`width:33%;`"
          placeholder="Any Status"
          filter_name="Following &amp; Not Following"
          :selected_status="filterValue"
          :options="[{ name: 'Following' }, { name: 'Not Following' }]"
          @filter-data="createFilterData"
        />

        <multiselect-filter-component
          :additionalStyle="`width:33%;`"
          placeholder="Any Focus Area"
          filter_name="Focus Areas"
          :selected_status="filterValue"
          :options="filterData.focusAreas"
          @filter-data="createFilterData"
        />

        <multiselect-filter-component
          :additionalStyle="`width:33%;`"
          placeholder="Any Identity"
          filter_name="Identities"
          :selected_status="filterValue"
          :options="filterData.identities"
          @filter-data="createFilterData"
        />
      </div>
      <nav
        v-if="areGroupsEnabled && areDeptsEnabled"
        class="eu-simple-navbar"
        style="margin-top: 24px; margin-bottom: 40px"
      >
        <span
          tabindex="0"
          :class="
            currentSection === 'groups' ? 'eu-simple-navbar-item eu-simple-navbar-item-active' : 'eu-simple-navbar-item'
          "
          @click="changeSection('groups')"
          @keyup.enter="changeSection('groups')"
          role="link"
        >
          <i class="fal fa-users" style="font-size: 16px; margin-right: 5px"></i> Groups
        </span>
        <span
          tabindex="0"
          :class="
            currentSection === 'depts' ? 'eu-simple-navbar-item eu-simple-navbar-item-active' : 'eu-simple-navbar-item'
          "
          @click="changeSection('depts')"
          @keyup.enter="changeSection('depts')"
          role="link"
        >
          <i class="fal fa-building" style="font-size: 16px; margin-right: 5px"></i> {{deptPlural}}
        </span>
      </nav>

      <div v-if="isLoading">
        <loading-spinner wrapperClass="wrapper" />
      </div>
      <div
        v-else-if="data.length > 0"
        :class="`${currentSection == 'depts' ? 'grid-wrapper-four-card' : 'eu-dashboard-cards'}`"
      >
        <div
          :class="`${currentSection == 'depts' ? 'iu-dept-card' : 'iu-group-card'}`"
          v-for="item in data"
          :key="item.id"
        >
          <dept-card-component v-if="currentSection == 'depts'" :item="item" @handle-follow="handleFollow" />
          <group-card-component v-if="currentSection == 'groups'" :item="item" @handle-follow="handleFollow" />
        </div>
      </div>
      <div v-else>
        <div class="eu-empty-container" v-if="data.length === 0">
          <div class="eu-empty">
            <div class="eu-empty-icon-container">
              <i class="fad fa-bullhorn eu-empty-icon"></i>
            </div>
            <div style="display: flex; flex-direction: column">
              <span class="eu-empty-text">
                No data/results.
                <br />
                Check back later!
              </span>
            </div>
          </div>
        </div>
      </div>
      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </div>
  </div>
</template>
<script lang="ts">
import Cookies from "js-cookie";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import GroupService from "../../groups/services/GroupService";
import DepartmentService from "../../departments/services/DepartmentService";
import FeedService from "../../feed/services/FeedService";
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import DeptCardComponent from "./DeptCardComponent.vue";
import GroupCardComponent from "./GroupCardComponent.vue";
import Notification from "../../components/Notification.vue";
import { useCommonDataStore } from "../../stores/commonData";
import CommonTitle from "../../components/CommonTitle.vue";
import { isFeatureEnabled, LICENSE_ONLY_FEATURES } from "../../utils/PermissionUtils";

export default {
  name: "groups-depts-index",

  components: {
    CommonTitle,
    LoadingSpinner,
    MultiselectFilterComponent,
    DeptCardComponent,
    GroupCardComponent,
    Notification,
  },

  props: ["isAdmin"],

  data() {
    return {
      data: [],
      assetPath: process.env.MEDIA_URL,
      currentSection: "groups",
      isLoading: true,
      search_input: "",
      pageCount: 1,
      groupService: new GroupService(),
      feedService: new FeedService(),
      deptService: new DepartmentService(),
      notification: { msg: "", isError: false, icon: null },
      isNotificationActive: false,
      groupsFollowing: [],
      filterValue: [],
      areDeptsEnabled: false,
      areGroupsEnabled: false,
      params: {
        status: [],
        focus_areas: [],
        identities: [],
      },
      commonDataStore: useCommonDataStore(),
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  computed: {
    title_text() {
      if (!this.areDeptsEnabled && this.areGroupsEnabled) {
        return "Groups";
      } else if (this.areDeptsEnabled && !this.areGroupsEnabled) {
        return this.deptPlural;
      }
      return "Groups & " + this.deptPlural;
    },
    description_text() {
      if (!this.areDeptsEnabled && this.areGroupsEnabled) {
        return "Browse and follow groups.";
      } else if (this.areDeptsEnabled && !this.areGroupsEnabled) {
        return `Browse and follow university ${this.deptPluralToLowerCase}.`;
      }
      return `Browse and follow groups and university ${this.deptPluralToLowerCase}.`;
    },
    canManage() {
      return this.isAdmin;
    },
    filterData() {
      return {
        departments: this.commonDataStore.getSingleDataArray("departments"),
        focusAreas: this.commonDataStore.getSingleDataArray("focus_areas"),
        identities: this.commonDataStore.getSingleDataArray("identities"),
      };
    },
    deptSingular() {
      return useCommonDataStore().getFeatureName("department_singular");
    },
    deptPlural() {
      return useCommonDataStore().getFeatureName("department_plural");
    },
    deptPluralToLowerCase() {
      return useCommonDataStore().getFeatureName("department_plural").toLowerCase();
    },
  },
  async created() {
    this.areDeptsEnabled = isFeatureEnabled(LICENSE_ONLY_FEATURES.DEPTS);
    this.areGroupsEnabled = isFeatureEnabled(LICENSE_ONLY_FEATURES.GROUPS);
    this.currentSection = this.areGroupsEnabled ? "groups" : "depts";
    this.fetchData();
    this.scroll();
  },

  methods: {
    async handleFollow(isFollowing: boolean, itemName: string) {
      let res = { success: false };
      if (this.currentSection === "depts") {
        res = await this.feedService.followFeed({ isFollowing, department: itemName }, this.headers);
      } else if (this.currentSection === "groups") {
        res = await this.feedService.followGroupFeed({ isFollowing, group: itemName }, this.headers);
      }

      if (!res.success) {
        this.showNotification({ msg: "Server error!", isError: true, icon: "fad fa-exclamation-circle" });
      }
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    async fetchData() {
      this.pageCount = 1;

      this.isLoading = true;
      if (this.currentSection === "depts") {
        this.data = await this.deptService.getAllDepartments({
          status: this.params.status,
          q: encodeURIComponent(this.search_input),
          focus_areas: this.params.focus_areas,
          identities: this.params.identities,
        });
      } else if (this.currentSection === "groups") {
        this.data = await this.groupService.getAllGroups({ ...this.params, q: encodeURIComponent(this.search_input) });
      }

      this.isLoading = false;
    },

    async changeSection(section: string) {
      if (this.currentSection !== section) {
        this.currentSection = section;
        this.search_input = "";
        this.fetchData();
      }
    },

    async scroll() {
      window.onscroll = async () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;

        if (bottomOfWindow && this.pageCount !== 0) {
          this.pageCount += 1;
          let newData = [];
          if (this.currentSection === "groups") {
            newData = await this.groupService.getAllGroups({
              ...this.params,
              q: this.search_input,
              page: this.pageCount,
            });
          } else if (this.currentSection === "depts") {
            newData = await this.deptService.getAllDepartments({
              status: this.params.status,
              q: encodeURIComponent(this.search_input),
              page: this.pageCount,
            });
          }

          if (newData.length > 0) {
            this.data = [...this.data, ...newData];
          } else this.pageCount = 0;
        }
      };
    },
    createFilterData(value, name) {
      if (value) {
        if (name === "Following & Not Following") {
          this.params.status = value.map((v) => encodeURIComponent(v.name));
        }
        if (name === "Focus Areas") {
          this.params.focus_areas = value.map((v) => encodeURIComponent(v.id));
        }
        if (name === "Identities") {
          this.params.identities = value.map((v) => encodeURIComponent(v.id));
        }
        this.fetchData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.groups-title-wrapper {
  padding: 24px 0;
}
</style>
