import { CloudWatchEventsClient, PutEventsCommand  } from "@aws-sdk/client-cloudwatch-events";
import { getValue } from "@syncfusion/ej2-base";
import { Base64 } from 'js-base64';
import CryptoJS from 'crypto-js';

const clientXpMetricsData: HTMLElement = document.querySelector(
  ".js-data-client-xp-metrics"
);
const clientXpPathData: HTMLElement = document.querySelector(
  ".js-data-client-xp-path"
);

const clientXpMetrics = clientXpMetricsData.dataset.clientXpMetrics;
const clientXpPath = clientXpPathData.dataset.clientXpPath;

export function getClientData() {
  return {
    'innerHeight': window.innerHeight,
    'innerWidth': window.innerWidth,

    'width': screen.width,
    'height': screen.height,
    'availWidth': screen.availWidth,
    'availHeight': screen.availHeight,
    'colorDepth': screen.colorDepth,
    'pixelDepth': screen.pixelDepth,

    'userAgent': navigator.userAgent,
    'language': navigator.language,
    'onLine': navigator.onLine,
  }
};

export class LogService {
  constructor() {}

  async log(payload) {

    const logEnv = Base64.decode(clientXpPath).split('*')[1]
    if (logEnv !== 'prod' && logEnv !== 'staging' && logEnv !== 'dev'){
      return;
    }

    try {
        const client = new CloudWatchEventsClient({
          region: CryptoJS.AES.decrypt(clientXpMetrics, CryptoJS.enc.Utf8.parse(Base64.decode(clientXpPath).split('*')[0]), {mode:CryptoJS.mode.ECB}).toString(CryptoJS.enc.Utf8).split('*')[2],
          credentials: {
              accessKeyId: CryptoJS.AES.decrypt(clientXpMetrics, CryptoJS.enc.Utf8.parse(Base64.decode(clientXpPath).split('*')[0]), {mode:CryptoJS.mode.ECB}).toString(CryptoJS.enc.Utf8).split('*')[0],
              secretAccessKey: CryptoJS.AES.decrypt(clientXpMetrics, CryptoJS.enc.Utf8.parse(Base64.decode(clientXpPath).split('*')[0]), {mode:CryptoJS.mode.ECB}).toString(CryptoJS.enc.Utf8).split('*')[1]
          }
        });

        const command = new PutEventsCommand({
          Entries: [
            {
              Source: Base64.decode(clientXpPath).split('*')[1] + ".logs",
              DetailType: "frontend",
              Detail: JSON.stringify(payload),
            },
          ],
        });
        const data = await client.send(command);
      } catch (error) {
        console.log("LOG ERROR",error)
      } finally {}
  }
}
