import * as Cookies from 'js-cookie';
import DIVAppService from '../services/DIVAppService';

export default class NewsletterForm {
    form:HTMLFormElement;
    notificationComponent: Element;
    notificationText:Element;
    csrfToken:string;
    networkService:DIVAppService;
    nameField:HTMLInputElement;
    organizationNameField:HTMLInputElement;
    emailField:HTMLInputElement;

    constructor(el:HTMLElement) {
        this.form = el as HTMLFormElement;
        // this.messagePanel = this.form.querySelector('#messagePanel');
        this.initialize();
        this.addEventListeners();
        this.networkService = new DIVAppService('channels/newsletter/add');
    }

    initialize():void {
        this.nameField = this.form.querySelector('input[name="name"]');
        this.organizationNameField = this.form.querySelector('input[name="organization"]')
        this.emailField = this.form.querySelector('input[name="email"]');
        this.notificationComponent = document.getElementsByClassName('eu-notification')[0];
        this.notificationText = document.getElementsByClassName('eu-notification-text')[0];
    }

    addEventListeners():void {
        this.form.addEventListener('submit', (e) => this.handleFormSubmit(e));
    }

    handleFormSubmit(e) {
        e.preventDefault();
        this.csrfToken = Cookies.get('csrftoken');

        //get form data
        let first_name = "";
        let last_name = "";
        const full_name_array = this.nameField.value.split(" ");
        first_name = full_name_array[0];
        if (full_name_array.length > 0 && full_name_array[1] !== undefined) {
            last_name = full_name_array[1];
        }

        const formData = {
            'first_name': first_name,
            'last_name': last_name,
            'organization': this.organizationNameField.value,
            'email': this.emailField.value
        };

        const customHeaders = {
            'X-CSRFToken': this.csrfToken,
            'Accept': 'application/json',
        };

        this.networkService.postFormData(formData, customHeaders).then(res => this.handleSubmitResponse(res));
    }

    handleSubmitResponse(res) {
        const notificationMountPoint = document.querySelector('#notification');

        let msgHTML;
        // let timeOutId;

        let first_name = "";
        let last_name = "";
        const full_name_array = this.nameField.value.split(" ");
        first_name = full_name_array[0];
        if (full_name_array.length > 0 && full_name_array[1] !== undefined) {
            last_name = full_name_array[1];
        }


        if (res.success) {
            msgHTML = 'Thank you for subscribing!';
            let nodes = this.form.querySelectorAll('input');
            let inputs = Array.prototype.slice.call(nodes);

            inputs.forEach((item) => item.disabled = true);
        }
        else {
            if (this.nameField.value == '') {
              msgHTML = 'Please input your full name';
            } else if (this.emailField.value == '') {
              msgHTML = 'Please input your email'
            } else {
              msgHTML = 'There was an error processing your request, please try again.';
            }
        }

        const notification = new window.diva.ui.NotificationInterface(notificationMountPoint, true, {'icon': null, 'isError': !res.success, 'msg': msgHTML});

        this.form.reset();
      }
}
