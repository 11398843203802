<template>
  <div class="flex-column-center">
    <div class="main-wrapper">
      <h1 class="guides-page-title">Guides</h1>
      <div class="guides-page-description">All guides will appear here</div>
      <navigation-tabs :navigationItems="navigationItems" />
      <section class="guides-content-section">
        <search-base
          id="search-guide"
          placeholder="Search by keyword or location"
          aria-label="Search guides by keywords or location"
          @search="filterData"
        ></search-base>
        <div class="filter-container">
          <multiselect-filter-component
            placeholder="Any Focus Area"
            filter_name="Focus Areas"
            param_name="focus_areas"
            :options="focus_areas"
            :limit="5"
            @filter-data="createFilterData"
          />
          <multiselect-filter-component
            placeholder="Any Identity"
            filter_name="Identities"
            param_name="identities"
            :options="identities"
            :limit="5"
            @filter-data="createFilterData"
          />
        </div>
        <div
          class="table-container manage-event-program-wrapper"
          style="width: 100%; overflow-y: hidden; overflow-x: auto; margin: 30px 0"
        >
          <table v-if="!isLoading" class="eu-resource-table">
            <tr class="eu-resource-row">
              <th class="eu-resource-cell eu-resource-cell-th" scope="col">
                <span> Name </span>
              </th>
              <th class="eu-resource-cell eu-resource-cell-th" scope="col">
                <span>Status </span>
              </th>
              <th class="eu-resource-cell eu-resource-cell-th" scope="col"></th>
            </tr>
            <tr v-for="item of guides" :key="item.id" class="eu-resource-row">
              <td class="eu-resource-cell">
                <span>{{ item.name }}</span>
              </td>

              <td class="eu-resource-cell">
                <span v-if="item.is_private" class="mark-ended">
                  <i class="fa-solid fa-lock-keyhole"></i>
                  Private
                </span>
                <span v-else class="mark-published">
                  <i class="fas fa-calendar-check"></i>
                  Public
                </span>
              </td>
              <td class="eu-resource-cell eu-resource-cell-dots-icon">
                <span
                  tabindex="0"
                  ref="resListBurger"
                  :id="`resource_burger_list_${item.id}`"
                  @click="showBurger(item)"
                  @keyup.enter="showBurger(item)"
                  aria-label="Burger menu"
                  arole="menu"
                  aria-haspopup="menu"
                >
                  <i class="fas fa-ellipsis-v"></i>
                </span>
              </td>
              <div ref="resListBurgerDropdown" :id="`r${item.id}`" class="eu-resource-burger" style="width: 180px">
                <router-link :to="`/guides/${item.id}`" :aria-label="`Go to ${item.name} guides`" class="eu-resource-burger-el">
                  <i class="fas fa-eye" style="padding-right: 5px"></i>
                  <span>View</span>
                </router-link>
                <router-link
                  :to="`/guides/edit/${item.id}`"
                  :aria-label="`Go to ${item.name} edit page`"
                  class="eu-resource-burger-el"
                >
                  <i class="fas fa-edit" style="padding-right: 5px"></i>
                  <span>Edit</span>
                </router-link>
                <label :for="`${item.id}`" class="inup-checkmark-container checkmark-label" style="margin-left: 20px">
                  Mark as Featured
                  <input
                    :id="`${item.id}`"
                    type="checkbox"
                    v-model="item.is_recommended"
                    @change="toggleRecommendedGuide($event, item.id)"
                  />
                  <span class="inup-checkmark"></span>
                </label>
              </div>
            </tr>
          </table>
        </div>
        <div v-if="isLoading">
          <loading-spinner wrapperClass="wrapper" />
        </div>
        <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import * as Cookies from "js-cookie";
import SearchComponent from "../../components/search/SearchComponent.vue";
import MultiselectFilterComponent from "../../form/components/MultiselectFilterComponent.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";
import GuidesService from "../services/GuidesService";
import Notification from "../../components/Notification.vue";
import SearchBase from "../../components/formBase/SearchBase.vue";
import { getNavigationItems } from '../utils/utils';
import NavigationTabs from '../../components/NavigationTabs.vue';

export default {
  components: {
    SearchComponent,
    MultiselectFilterComponent,
    LoadingSpinner,
    Notification,
    SearchBase,
    NavigationTabs
  },
  name: "manage-guides",
  props: ['isAdmin'],
  computed: {
    navigationItems() {
      return getNavigationItems(this.isAdmin)
    }
  },
  data() {
    return {
      service: new GuidesService(),
      guides: [],
      search_input: "",
      isLoading: true,
      identities: [],
      focus_areas: [],
      params: {
        focus_areas: [],
        identities: [],
      },
      lastOpenedBurger: null,

      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  async created() {
    await this.fetchFilterData();
    this.guides = await this.service.getGuides();
    this.isLoading = false;
  },
  methods: {
    showNotification(notification: { msg: string; isError: boolean; icon: string }) {
      this.notification = notification;
      this.isNotificationActive = true;
    },

    closeNotification() {
      this.isNotificationActive = false;
    },
    async fetchFilterData() {
      this.isLoading = true;
      const res = await this.service.getPredataGuidesEdit();
      if (res) {
        this.focus_areas = res.focus_areas;
        this.identities = res.identities;
      }
      this.isLoading = false;
    },
    async fetchData() {
      this.isLoading = true;
      this.guides = await this.service.getGuides({ q: this.search_input, ...this.params });
      this.isLoading = false;
    },
    createFilterData(value, name, param_name) {
      this.params[param_name] = value.map((v) => encodeURIComponent(v.name));
      this.fetchData();
    },
    filterData(value) {
      this.search_input = value;
      this.fetchData();
    },
    showBurger(el) {
      const burgerEl = document.querySelector(`#r${el.id}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl) {
        this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
      }
      burgerEl.classList.toggle("eu-resource-burger-active");
      this.lastOpenedBurger = burgerEl;
    },
    async toggleRecommendedGuide(e: Event, id: number) {
      const target = e.target as HTMLInputElement;

      let result;

      if (target.checked) {
        result = await this.service.setRecommended({ guide_id: id }, this.headers);
      } else {
        result = await this.service.removeRecommended({ guide_id: id }, this.headers);
      }

      if (result.success) {
        this.showNotification({
          msg: result.success,
          isError: false,
          icon: "fad fa-check-circle",
        });
      } else if (result.error) {
        this.showNotification({
          msg: result.error,
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
      }
    },
  },
};
</script>
