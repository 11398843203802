<template>
  <div class="eu-form-container inup-form-container eu-form-page">
    <form class="eu-form inup-form" @submit.prevent="create()" id="eu-form" enctype="multipart/form-data">
      <div class="eu-form-section inup-form-section">
        <div>
          <h2>Program Photo</h2>
          <p class="thumbnail-desc">
            Use a landscape orientation image for best results.
            <br />
            Suggested image dimensions: 3:2 ratio or 1080x720.
          </p>
          <dropzone-component
            v-if="imageInput"
            :imageSrc="program.thumbnail"
            :imageInput="imageInput"
            :maxImageSize="image_size_data.max_size"
            :maxImageSizeFormat="image_size_data.formatted"
            @uploadImage="prepareUpload"
            @removeImage="handleRemoveImage"
          />
          <input
            type="file"
            name="thumbnail"
            id="thumbnail"
            class="js-input-file-control da-components-hidden-file-control"
            accept="image/*"
            maxlength="400"
          />
        </div>
      </div>
      <div class="eu-form-section inup-form-section">
        <div class="eu-form-element-container-no-bot-margin">
          <input class="inup-input" type="text" name="title" required v-model="program.title" />
          <div class="inup-form-label-container">
            <label for="title">Program Title<span> *</span></label>
          </div>
          <!-- error -->
        </div>
        <span id="title-counter" class="character-counter">{{ program.title.length }}/80</span>
        <div class="eu-form-description-container">
          <textarea
            name="description"
            cols="40"
            rows="10"
            class="inup-textarea-input vertical"
            v-model="program.description"
          ></textarea>
          <div class="inup-textarea-label-container">
            <label for="description">Description<span> *</span></label>
          </div>
          <!-- error -->
        </div>
        <div :class="isPartnerTenant || group ? '' : 'multiple-elements'">
          <div class="eu-form-element-container" style="width: 100%">
            <select name="type" class="inup-input" v-model="program.type" required>
              <option value selected>Select from list</option>
              <option v-for="option in type" v-bind:value="option.name" v-bind:key="option.name">
                {{ option.name }}
              </option>
            </select>
            <div class="inup-form-label-container">
              <label for="type">Program Type<span> *</span></label>
            </div>
            <!-- error -->
          </div>
          <div v-if="!isPartnerTenant && !group" class="eu-form-element-container" style="width: 100%">
            <select name="department" class="inup-input" v-model="program.department" required>
              <option value selected>Select from list</option>
              <option v-for="option in departments" v-bind:value="option.id" v-bind:key="option.id">
                {{ option.name }}
              </option>
            </select>
            <div class="inup-form-label-container">
              <label for="department">{{deptSingular}}<span> *</span></label>
            </div>
            <!-- error -->
          </div>
        </div>
        <div class="eu-form-element-container-no-bot-margin" style="margin-bottom: 30px; margin-top: 0">
          <input class="inup-input" type="url" name="url" v-model="program.external_link" />
          <div class="inup-form-label-container">
            <label for="name">External Webpage/URL <span class="primary-color">(example: https://www.beglobalii.com)</span></label>
          </div>
          <!-- error -->
        </div>
      </div>
      <div class="eu-form-section inup-form-section">
        <h2 class="header-two-margin-bot">Program details</h2>
        <div class="multiple-elements">
          <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
            <datetime
              type="date"
              class="datetime-theme-blues"
              v-model="program.start_date"
              input-class="inup-input"
              :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
              :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
              :max-datetime="program.end_date ? new Date(program.end_date).toISOString() : null"
              :min-datetime="new Date().toISOString()"
              :week-start="7"
              title="Start Date"
              use12-hour
              value-zone='UTC'
              zone='UTC'
            ></datetime>
            <div class="inup-form-label-container">
              <label for="cost">Start Date</label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
            <datetime
              type="date"
              class="datetime-theme-blues"
              v-model="program.end_date"
              input-class="inup-input"
              :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
              :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
              :min-datetime="program.start_date ? new Date(program.start_date).toISOString() : new Date().toISOString()"
              :week-start="7"
              title="End Date"
              use12-hour
              value-zone='UTC'
              zone='UTC'
            ></datetime>
            <div class="inup-form-label-container">
              <label for="cost">End Date</label>
            </div>
            <!-- error -->
          </div>
        </div>
        <div class="multiple-elements">
          <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
            <datetime
              type="time"
              class="datetime-theme-blues"
              v-model="program.start_time"
              input-class="inup-input"
              :format="{ hour: 'numeric', minute: '2-digit' }"
              :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
              :minute-step="5"
              :week-start="7"
              title="Start Time"
              use12-hour
              value-zone='UTC'
              zone='UTC'
            ></datetime>
            <div class="inup-form-label-container">
              <label for="cost">Start Time </label>
            </div>
          </div>
          <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
            <datetime
              type="time"
              class="datetime-theme-blues"
              v-model="program.end_time"
              input-class="inup-input"
              :format="{ hour: 'numeric', minute: '2-digit' }"
              :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
              :minute-step="5"
              :week-start="7"
              title="End Time"
              use12-hour
              value-zone='UTC'
              zone='UTC'
            ></datetime>
            <div class="inup-form-label-container">
              <label for="cost">End Time </label>
            </div>
          </div>
        </div>
        <div class="multiple-elements">
          <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
            <datetime
              type="date"
              class="datetime-theme-blues"
              v-model="program.deadline"
              input-class="inup-input"
              :format="{ year: 'numeric', month: 'long', day: 'numeric' }"
              :phrases="{ ok: 'Submit', cancel: 'Cancel' }"
              :min-datetime="new Date().toISOString()"
              :week-start="7"
              title="Deadline"
              use12-hour
              value-zone='UTC'
              zone='UTC'
            ></datetime>
            <div class="inup-form-label-container">
              <label for="cost">Application Deadline <span> *</span></label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container" style="width: 100%; margin-top: 0">
            <input class="inup-input" type="number" name="cost" v-model="program.cost" />
            <div class="inup-form-label-container">
              <label for="cost">Program Cost</label>
            </div>
            <!-- error -->
          </div>
        </div>

        <div class="eu-form-element-container" style="margin-top: 0">
          <select
            class="inup-input"
            id="repeat-event-select"
            @change="handleRepeatOptionChange($event.target.value)"
            :disabled="isRepeatEventDisabled"
            v-model="preselectedRepeatPeriod"
          >
            <option value="none">Does not repeat</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
          <div class="inup-form-label-container">
            <label for="repeat-event-select">Repeat</label>
          </div>
          <!-- error -->
        </div>
        <div v-if="isPartnerTenant" class="eu-form-element-container-no-bot-margin" style="margin-bottom: 10px; margin-top: 10">
          <input class="inup-input" type="url" name="Link" v-model="program.apply_url" />
          <div class="inup-form-label-container">
            <label for="Link">Apply URL <span class="primary-color">(example: https://www.beglobalii.com)</span></label>
          </div>
          <!-- error -->
        </div>
        <div v-if="isPartnerTenant" class="eu-form-element-container-no-bot-margin" style="margin-bottom: 22px; margin-top: 10">
          <input class="inup-input" type="url" name="Link" v-model="program.more_info_url" />
          <div class="inup-form-label-container">
            <label for="Link">More Info URL <span class="primary-color">(example: https://www.beglobalii.com)</span></label>
          </div>
          <!-- error -->
        </div>

        <div class="eu-form-element-container" style="margin-top: 0">
          <multiselect-component
            :options="focus_areas"
            :preselected="preselected_focus_areas"
            name="focus_areas"
            :max="5"
            @handle-change="handleChange"
          />
          <div class="inup-form-label-container">
            <label for="type">Focus Area</label>
          </div>
          <!-- error -->
        </div>

        <div v-if="isPartnerTenant" class="eu-form-element-container" style="margin-top: 0">
          <multiselect-component
            :options="destinations"
            :preselected="preselected_destinations"
            name="destinations"
            :max="5"
            @handle-change="handleChange"
          />
          <div class="inup-form-label-container">
            <label for="type">Destinations</label>
          </div>
          <!-- error -->
        </div>

        <div class="eu-form-element-container">
          <multiselect-component
            :options="identities"
            :preselected="preselected_identities"
            name="identities"
            :max="5"
            @handle-change="handleChange"
          />
          <div class="inup-form-label-container">
            <label for="type">Identity Groups</label>
          </div>
          <!-- error -->
        </div>
        <div class="eu-form-element-container">
          <multiselect-component
            :options="career_academic_interests_options"
            :preselected="preselected_career_academic"
            name="career_academic_interests"
            :max="5"
            @handle-change="handleChange"
          />
          <div class="inup-form-label-container">
            <label for="type">Career/Academic interests</label>
          </div>
          <!-- error -->
        </div>
      </div>
      <div class="eu-form-section inup-form-section" style="padding-bottom: 30px">
        <h2 class="header-two-margin-bot">Location</h2>
        <!-- <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              @click="isSelected(true, 'is_campus')"
              @keyup.enter="isSelected(true, 'is_campus')"
              role="button"
              aria-label="Program, in person/on campus"
              class="selection-tab"
              :class="this.program.is_campus ? `active` : ``"
            >
              {{ isPartnerTenant ? "In-Person" : "On Campus" }}
            </span>
            <span
              tabindex="0"
              @click="isSelected(false, 'is_campus')"
              @keyup.enter="isSelected(false, 'is_campus')"
              aria-label="Program, virtual/off campus"
              role="button"
              class="selection-tab"
              :class="!this.program.is_campus ? `active` : ``"
            >
              {{ isPartnerTenant ? "Virtual" : "Off Campus" }}
            </span>
          </div>
          <span style="align-self: center; color: #e65722"> *</span>
        </div> -->
        <div style="margin-top: 10px">
          <button
            id="btn-virtual"
            type="button"
            :class="!program.is_hybrid && program.is_online ? 'location-button active' : 'location-button'"
            @click="isVirtual(true)"
          >
            Virtual
          </button>
          <button
            id="btn-venue"
            type="button"
            :class="!program.is_hybrid && !program.is_online ? 'location-button active' : 'location-button'"
            @click="isVirtual(false)"
          >
            Venue
          </button>
          <button
            id="btn-venue"
            type="button"
            :class="program.is_hybrid ? 'location-button active' : 'location-button'"
            @click="setIsHybrid(true)"
          >
            Hybrid
          </button>
        </div>
        <div
          v-if="program.is_online || program.is_hybrid"
          class="eu-form-element-container-no-bot-margin"
          style="margin-bottom: 10px; margin-top: 10"
        >
          <input
            class="inup-input"
            type="url"
            name="Link"
            placeholder="Program link (e.g., Zoom link, livestream link, etc.)"
            v-model="program.online_url"
          />
          <div class="inup-form-label-container">
            <label for="Link">Link <span class="primary-color">(example: https://www.beglobalii.com)</span></label>
          </div>
          <!-- error -->
        </div>

        <div v-if="!program.is_online || program.is_hybrid">
          <div class="eu-form-element-container-no-bot-margin">
            <input class="inup-input" type="text" name="address_1" v-model="program.address_1" />
            <div class="inup-form-label-container">
              <label for="address_1">Address</label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container-no-bot-margin">
            <input class="inup-input" type="text" name="address_2" v-model="program.address_2" />
            <div class="inup-form-label-container">
              <label for="address_2">Address Two</label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container-no-bot-margin">
            <input class="inup-input" type="text" name="city" v-model="program.city" />
            <div class="inup-form-label-container">
              <label for="city">City</label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container" style="width: 100%">
            <select name="type" class="inup-input" v-model="program.country">
              <option v-for="(val, index) in country" v-bind:value="index" v-bind:key="index">
                {{ val }}
              </option>
            </select>
            <div class="inup-form-label-container">
              <label for="type">Country</label>
            </div>
            <!-- error -->
          </div>
          <div v-if="program.country == 'USA'" class="eu-form-element-container" style="width: 100%">
            <select name="type" class="inup-input" v-model="program.state">
              <option v-for="(val, index) in state" v-bind:value="index" v-bind:key="index">
                {{ val }}
              </option>
            </select>
            <div class="inup-form-label-container">
              <label for="type">State</label>
            </div>
            <!-- error -->
          </div>
          <div class="eu-form-element-container-no-bot-margin">
            <input class="inup-input" type="text" name="postal_code" v-model="program.postal_code" />
            <div class="inup-form-label-container">
              <label for="postal_code">Zip Code</label>
            </div>
            <!-- error -->
          </div>
        </div>
      </div>
      <div class="eu-form-section inup-form-section" style="padding-bottom: 30px; display: flex">
        <div style="width: 80%">
          <h2 class="header-two-margin-bot" style="margin-bottom: 0">Program Contacts</h2>
          <p>Add program leaders, TAs, etc.</p>
        </div>
        <div style="width: 20%; text-align: right">
          <span
            @click="openAddContactModal()"
            @keyup.enter="openAddContactModal()"
            aria-label="Open modal for adding contacts"
            aria-haspopup=""
            role="dialog"
            class="open-modal-text"
          >
            Add contact <i class="far fa-plus"></i>
          </span>
          <p v-if="program.program_contacts.length !== 0" style="color: #00a1b3">
            <b>{{ program.program_contacts.length }}</b> contacts added
          </p>
        </div>
      </div>
      <div class="eu-form-section inup-form-section" style="padding-bottom: 30px">
        <h2 class="header-two-margin-bot" style="margin-bottom: 0">Files</h2>
        <p>Files must be no larger than 10MB.</p>
        <div class="upload-file-form">
          <div v-if="error" class="row">
            <p class="error">{{ error }}</p>
          </div>
          <div
            v-for="file in files"
            v-bind:key="file.name"
            class="row upload-file-container iu-upload-modal-file-container"
            style="width: 100%"
          >
            <div v-if="file.status !== 'deleted'" class="iu-upload-modal-file-status-container">
              <span
                v-if="file.file_type == 'IMG'"
                class="uploaded-image-preview"
                style="height: 32px; background: round; width: 40px"
                :style="{ backgroundImage: 'url(' + file.file_url + ')' }"
              ></span>
              <i v-if="file.file_type == 'DOC'" class="fas fa-file-upload iu-upload-modal-file-type-icon"></i>
              <div class="iu-upload-modal-file-name-container">
                <div class="iu-upload-modal-file-name-text" style="white-space: normal">
                  {{ file.name }}
                  <span class="iu-upload-modal-file-status-text">({{ file.status }})</span>
                </div>
                <div>
                  <span
                    v-if="file.status == 'uploading'"
                    class="iu-upload-modal-status-bar iu-upload-modal-status-bar-animated"
                  ></span>
                  <span
                    v-if="file.status == 'pending'"
                    class="iu-upload-modal-status-bar iu-upload-modal-status-bar-animated line-pending"
                    style="background-position: 0"
                  ></span>
                  <span v-if="file.status == 'failed'" class="iu-upload-modal-status-bar line-failed"></span>
                  <span v-if="file.status == 'success'" class="iu-upload-modal-status-bar line-success"></span>
                </div>
              </div>
              <button
                class="iu-upload-modal-file-delete-button"
                :aria-label="'Unselect file ' + file.name"
                @click="removeFile(file)"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </div>
          </div>
          <div class="row upload-file-container">
            <div class="column" style="width: 100%">
              <div
                v-cloak
                @drop.prevent="handleFileUpload"
                @dragover.prevent
                class="innerRow dnd-wrapper"
                style="align-items: center"
              >
                <label
                  @dragenter="dragging = true"
                  @dragleave="dragging = false"
                  class="file-upload-label"
                  style="width: 100%"
                  :style="{ background: dragging ? '#eaeeff' : '' }"
                  for="resource_image"
                >
                  <span>
                    <i class="fad fa-cloud-upload-alt dnd-upload-icon"></i> <br />
                    Drag &amp; drop files here <br />
                    or <br />
                    <input @change="handleFileUpload" id="resource_image" type="file" style="width: 0" multiple />
                    <span class="file-input-text">
                      Browse from device <i class="fal fa-angle-right" style="color: #5e50b5; font-size: 15px"></i>
                    </span>
                  </span>
                </label>
              </div>
            </div>
            <label
              id="acceptedFileTypes"
              class="eu-checkbox-container"
              style="padding-left: 0; margin-top: 25px; text-align: center; color: #707070"
              >Accepted file types: <br />
              <span style="font-weight: 500" aria-labelledby="acceptedFileTypes">
                .pdf, .doc, .xls, .ppt, .jpg, .png, .gif, .webp
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="eu-form-section inup-form-section" style="padding-bottom: 30px">
        <div style="width: 100%; display: flex">
          <h2 class="header-two-margin-bot" style="margin-bottom: 0">Add internal/external URL or Embed video</h2>
          <div v-if="resource_counter != 0" style="color: #00a1b3; width: 48%; text-align: right">
            <b>{{ resource_counter }}</b> resources added
          </div>
        </div>
        <div v-if="error_url_message" class="row">
          <p class="error">{{ error_url_message }}</p>
        </div>
        <div class="eu-form-element-container-no-bot-margin" style="margin-bottom: 10px; margin-top: 10">
          <input class="inup-input" type="url" name="Link" placeholder="Link" v-model="resource_url.file_url" />
          <div class="inup-form-label-container">
            <label for="Link">Link <span class="primary-color">(example: https://www.beglobalii.com)</span></label>
          </div>
          <!-- error -->
        </div>
        <div class="eu-form-element-container-no-bot-margin" style="margin-bottom: 10px; margin-top: 10">
          <input class="inup-input" type="text" name="name" placeholder="File name" v-model="resource_url.name" />
          <div class="inup-form-label-container">
            <label for="name">File Name</label>
          </div>
          <!-- error -->
        </div>
        <div class="eu-form-element-container" style="width: 100%">
          <select name="type_of_url" class="inup-input" v-model="resource_url.file_type">
            <option value selected>Select from list</option>
            <option value="URL">Internal/external url</option>
            <option value="VID">Embeded Video</option>
          </select>
          <div class="inup-form-label-container">
            <label for="type_of_url">Resource type</label>
          </div>
          <!-- error -->
        </div>
        <button
          type="button"
          id="btn-add-url"
          class="iu-main-btn main small outlined"
          aria-label="Add resource"
          @click="addUrl()"
        >
          Add resource
        </button>
      </div>
      <div v-if="isAdmin" class="eu-form-section inup-form-section feature-section" style="">
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Feature in Opportunities</h2>
        <!-- <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              @click="isSelected(false, 'is_hero_banner')"
              @keyup.enter="isSelected(false, 'is_hero_banner')"
              role="button"
              aria-label="Reguarl listing"
              class="selection-tab"
              :class="!this.program.is_hero_banner ? `active` : ``"
            >
              Regular listing
            </span>
            <span
              tabindex="0"
              aria-label="Hero banner"
              @click="isSelected(true, 'is_hero_banner')"
              @keyup.enter="isSelected(true, 'is_hero_banner')"
              role="button"
              class="selection-tab"
              :class="this.program.is_hero_banner ? `active` : ``"
            >
              Hero banner
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right" style="left: 39%; top: 100%">
              Adds this program to the hero carousel in the Events &nbsp; Programs landing page. There is a limit of 3 hero items.
            </span>
          </span>
        </div> -->
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              aria-label="Unfeature"
              @click="isSelected(false, 'is_featured')"
              @keyup.enter="isSelected(false, 'is_featured')"
              role="button"
              class="selection-tab"
              :class="!this.program.is_featured ? `active` : ``"
            >
              Unfeatured
            </span>
            <span
              tabindex="0"
              aria-label="Feature"
              @click="isSelected(true, 'is_featured')"
              @keyup.enter="isSelected(true, 'is_featured')"
              role="button"
              class="selection-tab"
              :class="this.program.is_featured ? `active` : ``"
            >
              Feature
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right">
              Adds a blue “Featured” star to the listing. Featured programs appear first in recommendations and results.
            </span>
          </span>
        </div>
      </div>
      <div v-if="partner" class="eu-form-section inup-form-section feature-section" style="">
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Feature on {{ partner }}</h2>
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              aria-label="Unfeature"
              @click="isSelected(false, 'is_partner_featured')"
              @keyup.enter="isSelected(false, 'is_partner_featured')"
              role="button"
              class="selection-tab"
              :class="!this.program.is_partner_featured ? `active` : ``"
            >
              Unfeatured
            </span>
            <span
              tabindex="0"
              aria-label="Feature"
              @click="isSelected(true, 'is_partner_featured')"
              @keyup.enter="isSelected(true, 'is_partner_featured')"
              role="button"
              class="selection-tab"
              :class="this.program.is_partner_featured ? `active` : ``"
            >
              Feature
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right">
              Adds a blue “Featured” star to the listing inside Partner's Landing Page. Featured programs always appear
              first.
            </span>
          </span>
        </div>
      </div>
      <div v-if="partner && isAdmin" class="eu-form-section inup-form-section feature-section" style="">
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Visibility</h2>
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              @click="isSelected(false, 'is_public')"
              @keyup.enter="isSelected(false, 'is_public')"
              role="button"
              class="selection-tab"
              aria-label="Make non-public"
              :class="!this.program.is_public ? `active` : ``"
            >
              Partner Page Only
            </span>
            <span
              tabindex="0"
              @click="isSelected(true, 'is_public')"
              @keyup.enter="isSelected(true, 'is_public')"
              aria-label="Make public"
              role="button"
              class="selection-tab"
              :class="this.program.is_public ? `active` : ``"
            >
              Publish in Opportunities
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right" style="left: 39%; top: 100%">
              Choose if program will be public on Opportunities page, or it will remain visible only inside Partner
              Account
            </span>
          </span>
        </div>
      </div>
      <div class="eu-form-section inup-form-section feature-section" style="">
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Status</h2>
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              @click="isSelected(true, 'is_published')"
              @keyup.enter="isSelected(true, 'is_published')"
              role="button"
              class="selection-tab"
              aria-label="Publish"
              :class="this.program.is_published ? `active` : ``"
            >
              Published
            </span>
            <span
              tabindex="0"
              @click="isSelected(false, 'is_published')"
              @keyup.enter="isSelected(false, 'is_published')"
              aria-label="UnPublish"
              role="button"
              class="selection-tab"
              :class="!this.program.is_published ? `active` : ``"
            >
              Draft
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right" style="left: 39%; top: 100%">
              Until program is published, it's only visible in 'manage' section.
            </span>
          </span>
        </div>
      </div>
      <div v-if="isNewProgram && (partner || group || program.department)" class="eu-form-section inup-form-section feature-section" style="">
        <h2 class="header-two-margin-bot" style="margin: 0; align-self: center">Share on Feed</h2>
        <div style="display: flex">
          <div class="selection-tabs-container">
            <span
              tabindex="0"
              @click="isSelected(true, 'share_on_feed')"
              @keyup.enter="isSelected(true, 'share_on_feed')"
              role="button"
              class="selection-tab"
              aria-label="Yes"
              :class="this.program.share_on_feed ? `active` : ``"
            >
              Yes
            </span>
            <span
              tabindex="0"
              @click="isSelected(false, 'share_on_feed')"
              @keyup.enter="isSelected(false, 'share_on_feed')"
              aria-label="No"
              role="button"
              class="selection-tab"
              :class="!this.program.share_on_feed ? `active` : ``"
            >
              No
            </span>
          </div>
          <span class="tooltip-container" style="align-self: center">
            <i class="fas fa-question-circle tooltip-icon left-margin-small" aria-hidden="true"></i>
            <span class="tooltip medium right" style="left: 39%; top: 100%">
              Check this if you want this to be shared on your Feed.
            </span>
          </span>
        </div>
      </div>
      <div class="eu-form-section inup-form-section" style="padding-bottom: 30px">
        <h2 class="header-two-margin-bot">For Internal Use</h2>
        <p>These items will only be visible to admin.</p>
        <div class="eu-form-element-container-no-bot-margin">
          <input class="inup-input" type="number" name="cost" v-model="program.budget_cost" />
          <div class="inup-form-label-container">
            <label for="cost">Program Budget Cost</label>
          </div>
          <!-- error -->
        </div>
        <div class="eu-form-element-container-no-bot-margin">
          <textarea
            name="goals"
            cols="40"
            rows="10"
            class="inup-textarea-input vertical"
            v-model="program.program_goals"
          >
          </textarea>
          <div class="inup-form-label-container">
            <label for="goals">Program Goals</label>
          </div>
          <!-- error -->
        </div>
      </div>
      <div class="inup-button-container">
        <button type="submit" class="eu-btn-primary-small" :class="uploadingFiles ? 'disabled' : ''">
          {{ uploadingFiles ? "Uploading/Removing files..." : "Save" }}
        </button>
        <a class="eu-btn-secondary-small" href="javascript:history.back()"> Cancel </a>
      </div>
    </form>
    <objects-select-modal
      :isModalActive="isAddContactModalActive"
      @close-modal="closeUsersSelectModal"
      title="Program contacts"
      actionName="Add"
      objectName="User"
      :objectsList="contacts"
      :isLoading="isLoadingSelectUsers"
      :preselectedObjects="selectedUsers"
      :page="selectUserPage"
      :totalPageNumber="selectUserTotalPageNumber"
      :currentPage="selectUserCurrentPage"
      :showIcon="true"
    />
    <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    <event-repeat-dialog
      v-if="isRepeatDialogActive"
      :isActive="isRepeatDialogActive"
      :eventStartDate="new Date(program.start_date)"
      :eventStartTime="getStartTimeString()"
      :eventEndDate="new Date(program.end_date)"
      :preselectedPeriod="preselectedRepeatPeriod"
      @close-repeat-modal="closeRepeatDialog"
      @save-repeat-config="saveRepeatConfig"
    />
  </div>
</template>
<script>
import ObjectsSelectModal from "../../accounts/components/modals/ObjectsSelectModal.vue";
import MultiselectComponent from "../../form/components/MultiselectComponent.vue";
import DropzoneComponent from "../../form/components/DropzoneComponent.vue";
import { validateExtensionFile } from "../../utils/TypecheckUtils";
import FeedService from "../../feed/services/FeedService";
import Notification from "../../components/Notification.vue";
import ProgramService from "../services/ProgramService";
import { Datetime } from "vue-datetime";
import * as Cookies from "js-cookie";
import moment from "moment";
import { focusElement, isPartnerTenant } from "../../utils/CustomUtils";
import EventRepeatDialog from "../../events/components/EventRepeatDialog.vue";

export default {
  components: {
    MultiselectComponent,
    DropzoneComponent,
    Datetime,
    ObjectsSelectModal,
    Notification,
    EventRepeatDialog,
  },
  name: "programs-manage",
  props: [
    "type",
    "departments",
    "identities",
    "focus_areas",
    "destinations",
    "audiences",
    "career_academic_interests",
    "image_size_data",
    "contacts",
    "state",
    "country",
    "program_form",
    "documents",
    "group",
    "partner",
    "isNewProgram",
    "deptSingular"
  ],
  computed: {
    isRepeatEventDisabled() {
      return !(
        !!this.program.start_date &&
        !!this.program.end_date
      );
    }
  },
  data() {
    return {
      program: {
        title: "",
        type: "",
        description: "",
        department: "",
        external_link: "",
        program_goals: "",
        cost: 0,
        is_featured: false,
        is_partner_featured: false,
        is_campus: false,
        address_1: "",
        city: "",
        address_2: "",
        postal_code: "",
        state: "",
        country: "",
        focus_areas: [],
        destinations: [],
        identities: [],
        audiences: [],
        thumbnail: "",
        documents: [],
        budget_cost: 0,
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        time_zone: "UTC",
        is_hero_banner: false,
        deadline: "",
        program_contacts: [],
        type_of_program_management: "program",
        is_online: true,
        is_hybrid: false,
        online_url: "",
        is_published: true,
        is_public: true,
        recurring: false,
        occurrence_frequency: "none",
        occurrence_interval: null,
        recurring_selected_values: "",
        occurrence_final_date: null,
        occurrences_total: null,
        share_on_feed: false
      },
      preselected_focus_areas: [],
      preselected_destinations: [],
      preselected_identities: [],
      preselected_audiences: [],
      preselected_career_academic: [],
      thumbnail: {},
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      imageInput: null,
      files: [],
      dragging: false,
      error: "",
      error_url_message: "",
      selectedUsers: [],
      isAddContactModalActive: false,
      isLoadingSelectUsers: false,
      loading: false,
      selectUserPage: 1,
      selectUserTotalPageNumber: 1,
      selectUserCurrentPage: 1,
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      changeView: false,
      resource_counter: 0,
      isPartnerTenant: isPartnerTenant(),
      resource_url: {
        file_url: "",
        name: "",
        file_type: "",
        status: "uploaded",
      },
      uploadingFiles: false,
      isRepeatDialogActive: false,
      preselectedRepeatPeriod: "none",
      isAdmin: false,
      career_academic_interests_options: [],
    };
  },
  created() {
    this.career_academic_interests_options = this.career_academic_interests.map((cai) => { return {'value': cai.id, 'name': cai.name}})
    this.initServices();
    this.initUserData();
    this.initProgramData();
    setTimeout(() => {
      this.imageInput = document.querySelector("#thumbnail");
    }, 0);
  },
  methods: {
    initServices() {
      this.service = new ProgramService();
      this.feedService = new FeedService();
    },

    initUserData() {
      const userStatusDataComponent = document.querySelector(".js-status-data");
      const loggedUser = JSON.parse(userStatusDataComponent.dataset.userStatus);
      this.isAdmin = loggedUser.user.role === "ADMIN";
    },
    initProgramData() {
      if (!this.program_form) return;
      this.program = this.program_form[0];
      this.preselected_focus_areas = this.focus_areas.filter((e) => this.program.focus_areas.includes(e.value));
      this.preselected_destinations = this.destinations.filter((e) => this.program.focus_areas.includes(e.value));

      if (isPartnerTenant){
        this.program.destinations = this.preselected_destinations.filter((e) => this.program.focus_areas.includes(e.value)).map((e) => e.value);
        this.program.focus_areas = this.preselected_focus_areas.filter((e) => this.program.focus_areas.includes(e.value)).map((e) => e.value);
      }
      else{
        this.program.destinations = []
      }

      this.preselected_identities = this.identities.filter((e) => this.program.identities.includes(e.value));
      this.preselected_audiences = this.audiences.filter((e) => this.program.audiences.includes(e.value));
      this.preselected_career_academic = this.career_academic_interests_options.filter((e) => this.program.career_academic_interests.includes(e.value));
      this.selectedUsers = this.contacts.filter((e) => this.program.program_contacts.includes(e.id));
      if (this.documents.length !== 0) {
        this.files = this.documents;
      }
      if (this.program_form[0].occurrence_frequency && this.program_form[0].recurring) {
        this.preselectedRepeatPeriod = this.program_form[0].occurrence_frequency;
      }

      if (this.program.start_date) {
        this.program.start_date = new Date(this.program.start_date).toISOString();
      }

      if (this.program.end_date) {
        this.program.end_date = new Date(this.program.end_date).toISOString();
      }

      this.program.occurrence_final_date = this.program_form.occurrence_final_date;
      this.program.occurrences_total = this.program_form.occurrences_total;
    },
    getStartTimeString() {
      const date = new Date(this.program.start_time);
      const hours = date.getHours().toString().padStart(2, "0"); // extract hours and pad with leading zero if necessary
      const minutes = date.getMinutes().toString().padStart(2, "0"); // extract minutes and pad with leading zero if necessary
      const time = `${hours}:${minutes}`;
      return time;
    },
    closeRepeatDialog() {
      this.isRepeatDialogActive = false;
    },
    openRepeatDialog() {
      this.isRepeatDialogActive = true;
    },
    handleRepeatOptionChange() {
      if (this.preselectedRepeatPeriod != "none") {
        this.openRepeatDialog();
      } else {
        this.program.recurring = false;
        this.program.occurrence_frequency = "none";
      }
    },
    saveRepeatConfig(config) {
      this.program.occurrence_frequency = config.repeatPeriod;
      this.program.occurrence_interval = config.repeatPeriodNumber;
      this.program.occurrence_final_date =
        config.endConditions.selectedEndOption === "on-date" ? config.endConditions.onDate : null;
      this.program.recurring_selected_values = JSON.stringify(config.repeatPeriodValues);
      this.program.occurrences_total =
        config.endConditions.selectedEndOption === "after-occurrences"
          ? config.endConditions.afterOccurrencesNumber
          : null;
      this.program.recurring = true;
      this.closeRepeatDialog();
    },
    handleChange(value, name) {
      this.program[name] = value.map((v) => v.value);
    },
    validateData() {
      let isValid = true;
      if (!this.program.deadline) {
        this.showNotification({
          msg: "Please choose a deadline date.",
          isError: true,
          icon: "fad fa-exclamation-circle",
        });
        isValid = false;
      }
      return isValid;
    },
    async handleFileUploads() {
      const uploadPromises = this.files.map((file) => this.uploadEachFile(file));
      await Promise.all(uploadPromises);
      await this.createService();
    },

    async uploadEachFile(file) {
      if (file.status !== "pending") {
        this.program.documents.push(file);
        this.files = this.files.filter((e) => e.name !== file.name);
        if (this.files.length == 0) {
          let res = await this.service.create(this.program, this.headers);
          if (res) {
            if (this.group || this.program.group) window.location.href = `/groups/${this.group}`;
            else if (this.partner || this.program.partner) window.location.href = `/partneraccounts/${this.partner}`;
            else window.location.href = "/opportunities";
            return;
          }
          this.showNotification({ msg: "Server Error", isError: true, icon: "fad fa-exclamation-circle" });
        }
      } else {
        if (file.name.length > 0) {
          file.status = "uploading";
          const res = await this.feedService.uploadFile(file, this.headers);
          if (res.success) {
            file.file_url = res.resource;
            this.program.documents.push(file);
            file.status = "uploaded";
            this.files = this.files.filter((e) => e.name !== file.name);
            if (this.files.length == 0) {
              let res = await this.service.create(this.program, this.headers);
              if (res) {
                if (this.group || this.program.group) window.location.href = `/groups/${this.group}`;
                else if (this.partner || this.program.partner)
                  window.location.href = `/partneraccounts/${this.partner}`;
                else window.location.href = "/events";
                return;
              }
              this.uploadingFiles = false;
              this.showNotification({ msg: "Server Error", isError: true, icon: "fad fa-exclamation-circle" });
            }
          } else {
            file.status = "failed";
            this.uploadingFiles = false;
            this.error = `Upload failed for file ${file.name}: Server error.`;
          }
        } else {
          file.status = "failed";
          this.error = `Upload failed for file ${file.name}: Required file details missing.`;
          this.uploadingFiles = false;
        }
      }
    },
    async createService() {
      this.program.focus_areas = this.program.focus_areas.concat(this.program.destinations);
      let res = await this.service.create(this.program, this.headers);
      this.loading = false;
      if (res) {
        this.redirectAfterCreate();
        return;
      }
      this.showNotification({ msg: "Server Error", isError: true, icon: "fad fa-exclamation-circle" });
    },

    redirectAfterCreate() {
      if (this.group || this.program.group) {
        window.location.href = `/groups/`+encodeURIComponent(`${this.group}`);
      } else if (this.partner || this.program.partner) {
        window.location.href = `/partneraccounts/`+encodeURIComponent(`${this.partner}`);
      } else {
        window.location.href = "/opportunities";
      }
    },
    async create() {
      if(this.loading) return;
      this.loading = true;
      await this.uploadImg();
      this.program.documents = [];

      if (!this.validateData()) {
        return;
      }

      this.format_data();

      if (this.files.length !== 0) {
        await this.handleFileUploads();
      } else {
        await this.createService();
      }
      this.loading = false;
    },
    prepareUpload(fileObject) {
      this.thumbnail = fileObject;
    },
    async uploadImg() {
      let res;
      if (Object.keys(this.thumbnail).length !== 0) {
        res = await this.feedService.uploadFile(this.thumbnail, this.headers);
      }

      if (res?.resource) this.program.thumbnail = res.resource;
    },
    handleRemoveImage() {
      this.thumbnail = {};
      this.program.thumbnail = this.thumbnail;
    },
    handleFileUpload(event) {
      const uploadFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      const imageExt = ["jpg", "jpeg", "png", "gif", "webp"];
      const docExt = ["txt", "pdf", "doc", "docx", "xlsx", "ppt", "pptx"];
      if (!uploadFiles.length) return;
      for (let i = 0; i < uploadFiles.length; i++) {
        this.error = "";
        let file = {
          file_url: URL.createObjectURL(uploadFiles[i]),
          file: uploadFiles[i],
          name: uploadFiles[i].name,
          file_type: null,
          department: "",
          status: "pending",
          error: null,
        };
        if (validateExtensionFile(uploadFiles[i].name, imageExt)) {
          file.file_type = "IMG";
        } else if (validateExtensionFile(uploadFiles[i].name, docExt)) {
          file.file_type = "DOC";
        } else {
          this.error = "Please use a supported image filetype.";
          return;
        }

        if (!this.files.some((e) => e.name === file.name)) {
          this.files.push(file);
        } else {
          this.error = `File with name '${file.name}' already exists.`;
          return;
        }
      }
    },
    isSelected(selected, key) {
      this.program[key] = selected;
    },
    removeFile(file) {
      for (let f of this.files) {
        if (f.name === file.name) {
          f.status = "deleted";
        }
      }
    },
    closeUsersSelectModal(data) {
      if (!data["canceled"]) {
        this.selectedUsers = data["selectedObjects"];
        this.program.program_contacts = this.selectedUsers.map((x) => x.id);
      }
      this.isAddContactModalActive = false;
    },
    openAddContactModal() {
      this.isAddContactModalActive = true;
      focusElement("objects-select-modal-title");
    },
    isVirtual(value) {
      this.program.is_hybrid = false;
      this.program.is_online = value;
    },
    setIsHybrid(value) {
      this.program.is_online = !value;
      this.program.is_hybrid = value;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    format_data() {
      if (this.group) this.program.group_name = this.group;
      if (this.partner) this.program.partner_name = this.partner;
      if (this.program.start_date) this.program.start_date = moment.utc(this.program.start_date).format("YYYY-MM-DD");
      if (this.program.end_date) this.program.end_date = moment.utc(this.program.end_date).format("YYYY-MM-DD");
      if (this.program.start_time)
        this.program.start_time = new Date(this.program.start_time).toLocaleTimeString("en-US", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        });
      if (this.program.end_time)
        this.program.end_time = new Date(this.program.end_time).toLocaleTimeString("en-US", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        });
      if (this.program.is_online){
        this.program.city = null
        this.program.address_1 = null
        this.program.address_2 = null
        this.program.state = null
        this.program.postal_code = null
      }
      else{
        this.program.online_url = null
      }
    },
    addUrl() {
      this.error_url_message = "";
      if (this.resource_url === "" || this.resource_url.name === "" || this.resource_url.file_type === "") {
        this.error_url_message = "Please fill all the fields in order to add resources.";
        return;
      }

      this.files.push(this.resource_url);
      ++this.resource_counter;
      this.resource_url = {
        file_url: "",
        name: "",
        file_type: "",
        status: "uploaded",
      };
    },
  },
};
</script>
