<template>
    <Modal :classProp="'iu-modal-content-container'" :isActiveProp="true" @close="closeModal">
        <div class="iu-modal-content">
            <h4 class="iu-modal-title-text">Upload File(s)</h4>
            <div class="iu-upload-modal-file-container">
                <div
                    v-for="(file, i) in files"
                    v-bind:key="i"
                    class="iu-upload-modal-file-status-container"
                >
                    <i class="fa-solid fa-file-csv iu-upload-modal-file-type-icon"></i>
                    <div class="iu-upload-modal-file-name-container">
                        <div class="iu-upload-modal-file-name-text">
                            {{file.name}}
                            <span class="iu-upload-modal-file-status-text">({{file.status}})</span>
                        </div>
                        <div>
                            <span
                                v-if="file.status == 'uploading'"
                                class="iu-upload-modal-status-bar iu-upload-modal-status-bar-animated"
                            ></span>
                            <span
                                v-if="file.status == 'pending'"
                                class="iu-upload-modal-status-bar iu-upload-modal-status-bar-animated line-pending"
                            ></span>
                            <span
                                v-if="file.status == 'failed'"
                                class="iu-upload-modal-status-bar line-failed"
                            ></span>
                            <span
                                v-if="file.status == 'success'"
                                class="iu-upload-modal-status-bar line-success"
                            ></span>
                        </div>
                    </div>
                    <button
                        class="iu-upload-modal-file-delete-button"
                        :aria-label="'Unselect file ' + file.name"
                        @click="removeFile(file)"
                    >
                        <i class="fa-solid fa-trash-can"></i>
                    </button>
                </div>
            </div>
            <div class="iu-upload-modal-drop-zone-container">
                <div
                    v-cloak
                    @dragover.prevent
                    @dragenter.prevent
                    @drop.prevent="handleFileUpload"
                    :class="dragging ? 'iu-upload-modal-drop-zone-dragging' : ''"
                >
                    <label
                        for="bulk-files"
                        class="iu-upload-modal-drop-zone-content"
                        :class="dragging ? 'iu-upload-modal-drop-zone-dragging' : ''"
                        @dragenter="dragging = true"
                        @dragleave="dragging = false"
                    >
                        <i class="fad fa-cloud-upload-alt iu-upload-modal-icon"></i>
                        <span class="iu-upload-modal-desktop-tablet-only">
                            Drag &amp; drop files or
                            <span
                                class="iu-main-btn small main text-only"
                                role="button"
                                aria-label="Search from local files"
                            >browse</span>
                        </span>
                        <button class="iu-main-btn small main text-only iu-upload-modal-mobile-only">Browse for a file</button>
                        <input
                            id="bulk-files"
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            multiple
                            @change="handleFileUpload"
                        />
                    </label>
                </div>
            </div>
            <div class="iu-upload-modal-description-template-container">
                <span class="iu-upload-modal-description-text">
                    Accepted file types: .xls, .xlsx, .csv
                </span>
                <a
                    class="iu-main-btn main small text-only"
                    style="font-size: 14px;"
                    :href="`${assetPath}assets/user_bulk_example.xlsx`"
                >
                    Download template file
                </a>
            </div>
            <div class="iu-modal-checkbox-container">
                <label class="eu-checkbox-container eu-event-collection-checkmark-label" style="font-size: 14px; margin: 0px; height: unset; width: unset; padding-left: 28px;">
                    Send a invitation email
                    <input
                        type="checkbox"
                        v-model="sendInvites"
                        style="margin-right: 8px;"
                    >
                    <span class="eu-checkmark" style="margin: 0;"></span>
                </label>
                <label v-if="isSSOEnabled" class="eu-checkbox-container eu-event-collection-checkmark-label" style="font-size: 14px; margin: 0px; height: unset; width: unset; padding-left: 28px;">
                    Import as a {{sso_provider}} account
                    <input
                        type="checkbox"
                        v-model="isSSO"
                        style="margin-right: 8px;"
                        @change="handleChangeSSOStatus"
                    >
                    <span class="eu-checkmark" style="margin: 0;"></span>
                </label>
            </div>
            <div class="iu-modal-separator"></div>
            <div class="iu-modal-actions-container">
                <button
                    class="iu-main-btn small solid iu-modal-save-button"
                    :class="filesUploading ? 'disabled' : 'main'"
                    aria-label="Send invites"
                    @click="handleImportUsers"
                >{{filesUploading ? 'Importing...' : 'Import & Send Invites'}}</button>
                <button
                    class="iu-main-btn small outlined gray"
                    aria-label="Close bulk upload modal"
                    @click="closeModal"
                >
                Cancel</button>
            </div>
        </div>
    </Modal>
</template>
<script lang="ts">
import Cookies from 'js-cookie';
import { validateExtensionFile } from '../../../utils/TypecheckUtils';
import Modal from '../../../components/ModalExtended.vue';
import BulkUploadService from "../../services/BulkUploadService";
import { focusElement } from '../../../utils/CustomUtils';

export default {
    name: 'bulk-upload-modal',

    components: { Modal },

    props: ['sso_provider', 'roles',],

    computed: {
        isSSOEnabled() {
            return !!this.sso_provider;
        },
    },

    data() {
        return {
            files: [],
            sendInvites: true,
            isSSO: false,
            dragging: false,
            error: '',
            assetPath: process.env.MEDIA_URL,
            filesUploading: false,
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
                Accept: 'application/json'
            },
        };
    },

    created() {
        this.uploadService = new BulkUploadService();
    },

    methods: {
        handleFileUpload(event) {
            const uploadFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
            this.dragging = false;
            if (!uploadFiles.length) return;

            const extensions = ['xlsx', 'xls', 'csv'];

            for (let i = 0; i < uploadFiles.length; i++) {

                let file = {
                    file_url: URL.createObjectURL(uploadFiles[i]),
                    file: uploadFiles[i],
                    name: uploadFiles[i].name,

                    import_type: 'BULK',
                    send_invites: this.sendInvites,
                    is_sso: this.isSSO,
                    confirm: 'true',
                    status: 'pending',
                };

                if (!validateExtensionFile(uploadFiles[i].name, extensions)) {
                    this.error = "Please use a valid document format: .xls, .xlsx, or .csv "
                } else {
                    this.files.push(file);
                }
            }
        },

        async postFiles() {
            this.$emit('started-upload');
            await Promise.all(this.files.map(async file => {
                if (file.status === 'success') {
                    // this.removeFile(file);
                } else {

                    file.status = 'uploading';
                    //setTimeout(() => {if(file.status === 'uploading') {location.reload()} }, 100000);
                    const result = await this.uploadService.postFormData(file, this.headers);

                    if (result.status === 'success' && result.total > 0) {
                        file.status = 'success';
                        //if (this.files.length === 1) {location.reload()}

                    } else {
                        file.status = 'failed';
                        file.error = `Upload failed:` + (result.message ? ` ${result.message}` : `Check if your file/column names are valid.`);
                    }
                }
            }));
        },
        async handleImportUsers() {
            this.filesUploading = true;
            await this.postFiles();
            this.filesUploading = false;
        },

        handleChangeInvites() {
            for (let i = 0; i < this.files.length; i++) {
                let fileObject = this.files[i];

                fileObject.send_invites = this.sendInvites;
            }
        },

        handleChangeSSOStatus() {
            for (let i = 0; i < this.files.length; i++) {
                let fileObject = this.files[i];

                fileObject.is_sso = this.isSSO;
            }
        },

        removeFile(file) {
            this.files = this.files.filter(e => e.name !== file.name);
        },

        closeModal() {
            this.files = [];
            focusElement("bulk_import_manage_account");
            this.$emit('close-modal');
        },
    },
}
</script>
<style scoped>
h3 {
    align-self: flex-start;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 30px;
    color: #666666;
    font-size: 25px;
    font-weight: 500;
}

.button-container {
    margin-bottom: 30px;
}

.base-file-container {
    align-items: baseline;
    overflow: hidden;
}

.line-pending {
    background-position: 0;
}

.line-success {
    background: revert;
    background-color: #4c55a7;
}

.line-failed {
    background-color: #E65722
}

.file-container {
    position: relative;
    margin: 25px 0;
}

.file-icon {
    color: #eaeeff;
    font-size: 50px;
}

.desc-link {
    color: #4c55a7;
    font-weight: 500;
    cursor: pointer;
}

.desc-icon {
    color: #5E50B5;
    font-size: 15px;
}

.accept-desc {
    padding-left: 0;
    margin-top: 25px;
    text-align: center;
    color: #707070;
    cursor: default;
}

.error {
    color: #E65722;
}

.success {
    position: absolute;
    color: #5fc6e5;
    font-size: 22px;
    right: 5px;
}

.delete {
    position: absolute;
    cursor: pointer;
    color: #E65722;
    font-size: 22px;
    right: 5px;
}

.checkbox-content-container {
    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;
    margin-bottom: 21px;
    max-height: fit-content;
}
</style>
