import HttpService from '../../services/HttpService';
import * as Cookies from 'js-cookie';

const headers = {
  'X-CSRFToken': Cookies.get('csrftoken'),
  Accept: 'application/json',
};

export default class SettingsService extends HttpService {
  constructor() {
    super('/profiles/api');
  }

  getInterestsPreData(): Promise<any> {
    this.endpoint = `/profiles/api/settings/interests`;
    return this.get();
  }

  updateMyInterests(data): Promise<any> {
    this.endpoint = `/profiles/api/settings/interests`;
    return this.postFormData(data, headers);
  }

  getPreferencesPreData(): Promise<any> {
    this.endpoint = `/profiles/api/preferences`;
    return this.get();
  }

  updatePreferences(data) {
    this.endpoint = '/profiles/api/preferences';
    return this.postFormData(data, headers);
  }

  getResetPasswordPreData(): Promise<any> {
    this.endpoint = `/profiles/api/settings/password`;
    return this.get();
  }

  resetPassword(data) {
    this.endpoint = '/profiles/api/settings/password';
    return this.postFormData(data, headers);
  }

  getMentorSettingsPredata(): Promise<any> {
    this.endpoint = `/profiles/api/settings/mentor`;
    return this.get();
  }

  updateMentorSettings(data) {
    this.endpoint = '/profiles/api/settings/mentor';
    return this.postFormData(data, headers);
  }

}
