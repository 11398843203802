<template>
    <div class="analytics-wrapper-cards-footer">
        <router-link :aria-label="`Go to ${name} detailed Report`" :to="link" class="analytics-cards-footer"> Go to detailed Report </router-link>
    </div>
</template>

<script>
export default {
    name: "analytics-card-footer",
    props: ['link', 'name'],
    data(){
        return{

        }
    }
}
</script>

<style scoped>


</style>
