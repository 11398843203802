<template>
  <section>
    <button
      class="eu-sidebar-collapse-button"
      :aria-label="(expanded ? 'Collapse' : 'Expand') + ' side menu'"
      @click="toggleSideBar"
    >
      <i :class="'fa-solid fa-chevron' + (expanded ? '-left' : '-right')"></i>
    </button>
    <div class="eu-sidebar-container" id="sidebarContainer">
      <div class="eu-logo-wrapper">
        <img @click="redirectTo('/')" class="eu-logo eu-side-menu-logo" :src="logo" />
      </div>
      <ul class="eu-sidebar-items">
        <li class="eu-sidebar-item-category-container only-mobile">
          <div
            tabindex="0"
            @click="redirectTo('/profiles/' + userData.user.profile_url)"
            @keyup.enter="redirectTo('/profiles/' + userData.user.profile_url)"
            aria-label="View profile"
            :index="'view_profile'"
            content=""
            :class="
              'eu-sidebar-item ' +
              (activeTab === 'view_profile'
                ? 'eu-sidebar-item-' + 'view_profile' + '-active'
                : 'eu-sidebar-item-' + 'view_profile') +
              (!expanded ? ' collapsed' : '')
            "
          >
            <i class="eu-sidebar-icon fa-regular fa-circle-user"></i>
            <a tabindex="-1" style="text-align: center">{{ userData.user.first_name }} {{ userData.user.last_name }}</a>
          </div>
        </li>
        <li
          v-for="itemsCategory in separatedList"
          :key="itemsCategory.title"
          class="eu-sidebar-item-category-container"
        >
          <div class="eu-sidebar-item-category-title" :class="!expanded ? 'collapsed' : ''" v-if="itemsCategory.title">
            {{ itemsCategory.title }}
          </div>
          <ul>
            <span
              v-for="item in itemsCategory.list"
              :key="item.label"
              tabindex="0"
              @click="redirectTo(item.link, item.label)"
              @keyup.enter="redirectTo(item.link), item.label"
              :aria-label="'Go to ' + item.label"
              :class="sidebarItemClasses(item.value)"
              :target="itemTarget(item.value)"
            >
              <i class="eu-sidebar-icon" :class="item.icon"></i>
              <span role="none">{{ getItemLabel(item) }}</span>
            </span>
          </ul>
        </li>
        <div class="eu-sidebar-separator"></div>
        <li class="eu-sidebar-item-category-container">
          <div
            tabindex="0"
            @click="redirectTo('/settings/profile')"
            @keyup.enter="redirectTo('/settings/profile')"
            aria-label="Open settings"
            :index="'settings'"
            content=""
            :class="
              'eu-sidebar-item ' +
              (activeTab === 'settings'
                ? 'eu-sidebar-item-' + 'settings' + '-active'
                : 'eu-sidebar-item-' + 'settings') +
              (!expanded ? ' collapsed' : '')
            "
          >
            <i class="eu-sidebar-icon fa-regular fa-gear"></i>
            <a tabindex="-1" style="text-align: center">Settings</a>
          </div>
        </li>
        <li class="eu-sidebar-item-category-container only-mobile">
          <div
            tabindex="0"
            @click="redirectTo('/logout')"
            @keyup.enter="redirectTo('/logout')"
            aria-label="Logout"
            :index="'logout'"
            content=""
            :class="
              'eu-sidebar-item ' +
              (activeTab === 'logout' ? 'eu-sidebar-item-' + 'logout' + '-active' : 'eu-sidebar-item-' + 'logout') +
              (!expanded ? ' collapsed' : '')
            "
          >
            <i class="eu-sidebar-icon fa-regular fa-right-from-bracket"></i>
            <a tabindex="-1" style="text-align: center">Sign Out</a>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="expanded" class="eu-sidebar-footer">
      <!-- <img alt="cartoon people communicating over internet" :src="`${assetPath}assets/sidebar-graphic%20.svg`" class='eu-sidebar-graphic' /> -->
    </div>
  </section>
</template>
<script lang="ts">
import Cookies from "js-cookie";
import AccountsService from "../../accounts/services/AccountsService";
import { useAppStateStore } from "../../stores/appState";
import { isPartnerTenant } from "../../utils/CustomUtils";
import { FEATURES, hasPermission } from "../../utils/PermissionUtils";

const menuEndDefault = {
  link: "/logout",
  label: "Sign Out",
  icon: "fad fa-sign-out",
};

export default {
  name: "user-status",
  props: {
    userData: Object,
    navMenuItems: {
      type: Array,
      default: () => [],
    },
    logoUrl: String,
  },
  data() {
    return {
      appStateStore: useAppStateStore(),
      assetPath: process.env.MEDIA_URL,
      meetingsNo: localStorage.meetingsNo,
      logo: "",
      expanded: true,
      separatedList: [],
      accountService: new AccountsService(),
      isPartnerTenant: isPartnerTenant(),
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  created() {
    this.logo = this.getLogo();
    if (this.isPartnerTenant) {
      this.separateListDabi();
    } else {
      this.separateList();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.userData.user.side_menu_expanded ? this.expandSideBar(true) : this.collapseSideBar(true);
    });
  },
  methods: {
    separateListDabi() {
      const dashboardMenuItem = ["dashboard", "manage_managers", "activity_feed", "bias_report"];
      const dashboardItems = {
        title: "",
        list: this.menuItems.filter((item) => item.value && dashboardMenuItem.includes(item.value)),
      };
      const adminMenuItems = ["analytics", "manage_accounts"];
      const adminItems = {
        title: "Admin",
        list: this.menuItems.filter((item) => item.value && adminMenuItems.includes(item.value)),
      };
      const opportunitiesMenuItems = ["events", "programs", "guides", "resource_library"];
      const opportunitiesItems = {
        title: "Opportunities & Resources",
        list: this.menuItems.filter((item) => item.value && opportunitiesMenuItems.includes(item.value)),
      };
      const communityMenuItems = ["groups", "depts", "partner_account"];
      let communityItems = {
        title: "Community & Culture",
        list: this.menuItems.filter((item) => item.value && communityMenuItems.includes(item.value)),
      };

      if (dashboardItems.list.length != 0) {
        this.separatedList.push(dashboardItems);
      }
      if (adminItems.list.length != 0 && this.userData.user.role == "ADMIN") {
        this.separatedList.push(adminItems);
      }
      if (opportunitiesItems.list.length != 0) {
        this.separatedList.push(opportunitiesItems);
      }
      if (communityItems.list.length != 0) {
        this.separatedList.push(communityItems);
      }
    },
    separateList() {
      const dashboardMenuItem = ["dashboard", "partner_account", "manage_managers"];
      const dashboardItems = {
        title: "",
        list: this.menuItems.filter((item) => item.value && dashboardMenuItem.includes(item.value)),
      };

      const globalMenuItems = ["one_stop"];
      const globalItems = {
        title: "Global",
        list: this.menuItems.filter((item) => item.value && globalMenuItems.includes(item.value)),
      };

      const adminMenuItems = ["analytics", "manage_accounts", "bias_report"];
      const adminItems = {
        title: "Admin",
        list: this.menuItems.filter((item) => item.value && adminMenuItems.includes(item.value)),
      };

      const opportunitiesMenuItems = ["events", "programs", "resource_library", "campus_apps"];
      const opportunitiesItems = {
        title: "Opportunities & Resources",
        list: this.menuItems.filter((item) => item.value && opportunitiesMenuItems.includes(item.value)),
      };

      const newsMenuItems = ["activity_feed"];
      const newsItems = {
        title: "News & Updates",
        list: this.menuItems.filter((item) => item.value && newsMenuItems.includes(item.value)),
      };

      const communityMenuItems = ["groups", "depts"];
      let communityItems = {
        title: "Community & Culture",
        list: this.menuItems.filter((item) => item.value && communityMenuItems.includes(item.value)),
      };

      const learningMenuItems = ["courses", "guides", "quests", "appointments"];
      let learningItems = {
        title: "Learning & Support",
        list: this.menuItems.filter((item) => item.value && learningMenuItems.includes(item.value)),
      };

      if (dashboardItems.list.length != 0) {
        this.separatedList.push(dashboardItems);
      }
      if (adminItems.list.length != 0 && this.userData.user.role == "ADMIN") {
        this.separatedList.push(adminItems);
      }
      if (newsItems.list.length != 0) {
        this.separatedList.push(newsItems);
      }
      if (learningItems.list.length != 0) {
        this.separatedList.push(learningItems);
      }
      if (opportunitiesItems.list.length != 0) {
        this.separatedList.push(opportunitiesItems);
      }
      if (communityItems.list.length != 0) {
        this.separatedList.push(communityItems);
      }
      if (globalItems.list.length != 0) {
        this.separatedList.push(globalItems);
      }
      if (adminItems.list.length != 0 && this.userData.user.role != "ADMIN") {
        this.separatedList.push(adminItems);
      }
    },
    toggleSideBar() {
      this.expanded ? this.collapseSideBar() : this.expandSideBar();
    },
    getItemLabel(item) {
      return !this.expanded && item.collapsed_label ? item.collapsed_label : item.label;
    },

    expandSideBar(initial_load: boolean = false) {
      this.expanded = true;
      const sidebarElement = document.getElementById("sidebar");
      const contentWrapperElement = document.getElementById("content-wrapper");

      sidebarElement.classList.add("expanded");
      sidebarElement.classList.remove("collapsed");

      contentWrapperElement.classList.remove("side-menu-collapsed");

      if (!initial_load) {
        sidebarElement.classList.add("expanding-animation");
        sidebarElement.classList.remove("collapsing-animation");

        contentWrapperElement.classList.remove("content-wrapper-expanding-animation");
        contentWrapperElement.classList.add("content-wrapper-collapsing-animation");
      }

      this.accountService.rememberSideMenuExpandedChoice(this.expanded, this.headers);
    },
    collapseSideBar(initial_load: boolean = false) {
      this.expanded = false;
      const sidebarElement = document.getElementById("sidebar");
      const contentWrapperElement = document.getElementById("content-wrapper");

      sidebarElement.classList.remove("expanded");
      sidebarElement.classList.add("collapsed");

      contentWrapperElement.classList.add("side-menu-collapsed");

      if (!initial_load) {
        sidebarElement.classList.remove("expanding-animation");
        sidebarElement.classList.add("collapsing-animation");

        contentWrapperElement.classList.remove("content-wrapper-collapsing-animation");
        contentWrapperElement.classList.add("content-wrapper-expanding-animation");
      }

      this.accountService.rememberSideMenuExpandedChoice(this.expanded, this.headers);
    },
    getMenuItems() {
      return this.menuItems;
    },
    getLogo() {
      if (this.logoUrl === "None" || this.logoUrl === "" || !this.logoUrl) {
        return process.env.MEDIA_URL + "assets/engageup-logo.svg";
      }
      return this.logoUrl;
    },
    routeExistsInRouter(path) {
      try {
        const resolvedRoute = this.$router.resolve(path);
        return resolvedRoute.resolved.matched.length > 0;
      } catch (error) {
        return false;
      }
    },
    redirectTo(url, label = null) {
      if (this.routeExistsInRouter(url)) {
        this.$router.push(url);
      } else {
        const newTabOptions = ["Bias Report"];
        if (newTabOptions.includes(label)) {
          window.open(url, "_blank");
        } else {
          window.location.href = url;
        }
      }
    },
  },
  computed: {
    profileImagePath() {
      let image_path = "";

      // not logged in
      if (!this.userData) {
        return "";
      }

      if (this.userData.account_type == "Partner") {
        if (!this.userData.partner.profile_image || this.userData.partner.profile_image == "") {
          image_path = process.env.MEDIA_URL + "assets/icons/org_icon.svg";
        } else {
          image_path = this.userData.partner.profile_image;
        }
      } else {
        if (!this.userData.user.profile_image || this.userData.user.profile_image == "") {
          image_path = "/static/assets/icons/default-profile.svg";
        } else {
          image_path = this.userData.user.profile_image;
        }
      }

      return image_path;
    },
    sidebarItemClasses() {
      let activeTab = this.activeTab;
      if (this.activeTab === "manage_partners") {
        activeTab = "manage_accounts";
      }
      if (this.activeTab === "events" && !hasPermission(FEATURES.EVENTS)) {
        activeTab = "programs";
      }
      if (this.activeTab === "programs" || this.activeTab === "events") {
        return (value) => ({
          "eu-sidebar-item": true,
          "eu-sidebar-item-events-active": "events" === value,
          "eu-sidebar-item-programs-active": "programs" === value,
          collapsed: !this.expanded,
        });
      }

      return (value) => ({
        "eu-sidebar-item": true,
        ["eu-sidebar-item-" + value + "-active"]: activeTab === value,
        collapsed: !this.expanded,
      });
    },
    itemTarget() {
      return (value) => (value === "bias_report" ? "_blank" : "_self");
    },
    menuItems() {
      return this.navMenuItems;
    },
    menuEndDefault() {
      return menuEndDefault;
    },
    profileUrl() {
      return `/profiles/${this.userData.user.profile_url}`;
    },
    activeTab() {
      const activeRoute = this.appStateStore.getActiveFeature;
      return activeRoute ? activeRoute : sessionStorage.activeTab;
    },
  },
};
</script>
