<template>
  <div class="navigation-tabs-section">
    <div class="navigation-tabs-container">
      <div v-for="(item, index) in navigationItems" :key="index">
        <router-link
          v-if="!item.isRedirectUrl && !preventRedirect"
          class="tab-item-container nav-item"
          :class="isTabActive(item.routeName) ? 'active' : ''"
          :to="{ name: item.routeName, params: item.params }"
          :aria-label="`Switch to ${item.label} tab`"
        >
          {{ item.label }}
        </router-link>
        <a
          v-else-if="!preventRedirect"
          class="tab-item-container nav-item"
          :class="isTabActive(item.routeName) ? 'active' : ''"
          :href="item.redirectionUrl"
          :aria-label="`Switch to ${item.label} tab`"
        >
          {{ item.label }}
        </a>
        <span
          v-else
          class="tab-item-container nav-item"
          :class="isTabActive(item.routeName) ? 'active' : ''"
          tabindex="0"
          @click="changeTab(item.routeName)"
          @keyup.enter="changeTab(item.routeName)"
          :aria-label="`Switch to ${item.routeName} tab`"
        >
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
interface NavigationItem {
  label: string;
  routeName: string;
  isRedirectUrl: boolean;
  redirectionUrl?: string;
  params?: object;
}

export default {
  name: 'navigation-tabs',
  props: {
    navigationItems: {
      type: Array<NavigationItem>,
      required: true,
    },
    preventRedirect: {
      type: Boolean,
      default: false,
    },
    initialTab: {
      type: String,
      required: false,
    },
  },
  created() {
    if (this.preventRedirect) {
      this.activeTab = this.initialTab;
    }
  },
  data() {
    return {
      activeTab: null,
    };
  },
  methods: {
    changeTab(routeName: string) {
      this.activeTab = routeName;
      this.emitChangeTab(routeName);
    },
    isTabActive(routeName: string) {
      if (this.preventRedirect) {
        return this.activeTab === routeName;
      }

      return this.$route.name === routeName;
    },
    emitChangeTab(routeName: string) {
      this.$emit('changeTab', routeName);
    },
  },
};
</script>

<style lang="scss">
.navigation-tabs-container {
  display: flex;
  gap: 1rem;
  background-color: transparent;
  flex-wrap: nowrap;
  border-bottom: none;
  height: 35px;
}

.navigation-tabs-section {
  display: grid;
  overflow-x: scroll;
}

.tab-item-container {
  border-bottom: 3px solid transparent;
  position: relative;
  top: 0.125rem;
  white-space: nowrap;

  &.active {
    border-bottom: 3px solid #2d98d2;
  }
}

.nav-item {
  color: #838ea1;
  display: block;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 10px;

  &.active {
    color: #2d98d2;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
