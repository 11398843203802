import Vue from 'vue';
import EventDateTimeComponent from "./components/EventDateTimeComponent.vue";


export default class EventDateTimePickers {
  private readonly mountPoint: HTMLElement;
  protected startDateInput: HTMLInputElement;
  protected endDateInput: HTMLInputElement;
  protected startTimeInput: HTMLInputElement;
  protected endTimeInput: HTMLInputElement;

  constructor(
    el: HTMLElement,
    startDateInput: HTMLInputElement,
    endDateInput: HTMLInputElement,
    startTimeInput: HTMLInputElement,
    endTimeInput: HTMLInputElement
  ) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.startDateInput = startDateInput;
    this.endDateInput = endDateInput;
    this.startTimeInput = startTimeInput;
    this.endTimeInput = endTimeInput;

    this.run();
  }

  async run(): Promise<void> {
    const _self = this;

    const eventDateTimePickers = new Vue({
      components: { EventDateTimeComponent },
      template: `
        <EventDateTimeComponent
          :startDateInput="startDateInput"
          :endDateInput="endDateInput"
          :startTimeInput="startTimeInput"
          :endTimeInput="endTimeInput"
        />
      `,

      data() {
        return {
          startDateInput: _self.startDateInput,
          endDateInput: _self.endDateInput,
          startTimeInput: _self.startTimeInput,
          endTimeInput: _self.endTimeInput,
        };
      },
    });
    eventDateTimePickers.$mount(this.mountPoint);
  }
}
