<template>
    <section class="one-stop-section">
        <div class="one-stop-section-header">
            <h2>Pathway Modules</h2>
            <router-link to="/courses?page=saved" aria-label="Go to your saved courses" class="iu-main-btn main text-only small" style="white-space: nowrap;">See All<i class="fa-regular fa-chevron-right iu-main-button-arrow-icon"></i></router-link>
        </div>
        <div v-if="!isLoading" class="overflow-mobile">
            <browse-saved-courses
                :courses="courses"
                @remove-bookmark="removeBookmark"
            />
        </div>
        <loading-spinner v-else />
    </section>
</template>
<script lang="ts">
import Cookies from 'js-cookie';
import BookmarkService from "../services/BookmarkService";
import BrowseSavedCourses from "./BrowseSavedCourses.vue";
import LoadingSpinner from "../../components/LoadingSpinner.vue";

export default {
    name: "interface-saved-courses",

    components: {
        BrowseSavedCourses,
        LoadingSpinner,
    },

    data() {
        return {
            courses: [],
            isLoading: true,

            service: new BookmarkService(),
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
                Accept: 'application/json'
            },
        };
    },

    created() {
        this.getSavedCourses();
    },

    methods: {
        async getSavedCourses() {
            this.isLoading = true;
            const result = await this.service.getBookmarkedCourses({
                limit: 4,
            });
            this.courses = result['courses'];
            if (this.courses.length === 0) {
                this.$emit('empty');
            }
            this.isLoading = false;
        },

        async removeBookmark(id: number) {
            await this.service.deleteBookmarkedCourses({
                targets: [id],
            }, this.headers);
            this.getSavedCourses();
        },
    },
}
</script>
