<template>
  <div class="partner-accounts-section">
    <section v-if="!isLoadingPredata" class="group-page-header-container">
      <section class="image-container">
         <draggable
           handle=".drag-handle"
          @start="onDragStart"
          @end="onDragEnd"
        >
        <img :src="partner.cover_pic" class="image" alt="Partner cover image" />
         </draggable>
        <div class="tint"></div>
        <div class="back-button-container">
          <router-link :to="{ name: 'partner-browse' }" class="back-button">
            <i class="fa-solid fa-chevron-left back-arrow"></i>
            <span class="text"> Browse all partners</span>
          </router-link>
        </div>
        <div class="content-container">
          <span class="tag-pill">Partner</span>
          <img class="logo" v-if="partner.logo" :src="partner.logo" alt="Partner logo" />
          <div class="text-container">
            <span class="title">{{ partner.name }}</span>
            <span class="description">{{ partner.tag_line }}</span>
          </div>
        </div>
      </section>

      <div class="action-container">
        <navigation-tabs
          :navigationItems="navigationItems"
          :preventRedirect="true"
          :initialTab="selected_tab"
          @changeTab="change_tab"
          class="partner-page-nav-section"
        ></navigation-tabs>
        <div class="buttons-container">
          <button @click="toggleFollowing" :disabled="followRequestSent" class="follow-button">
            {{ followButtonText }}
          </button>
          <a
            v-if="userCanEdit"
            class="edit-button"
            :href="`/partneraccounts/${encodeURIComponent(partner.name)}/edit`"
            :aria-label="`Go to ${partner.name} edit page `"
          >
            <i class="fa-solid fa-edit"></i>
            Edit Partner
          </a>
        </div>
      </div>
    </section>
    <div v-if="!isLoadingPredata && partner.partner_type == 'BASIC'" class="departments-main-wrapper">
      <partner-details
        :loggedUser="loggedUser"
        :partner="partner"
        :hasEventsAccess="hasEventsAccess"
        :hasProgramsAccess="hasProgramsAccess"
        :isLoadingPosts="isLoadingPosts"
        :posts="latestPosts"
        @show-notification="showNotification"
        @set-is-loading="setIsLoading"
      />
    </div>
    <div v-if="!isLoadingPredata && partner.partner_type != 'BASIC'" class="departments-main-wrapper">
      <div v-if="selected_tab === 'feed'" class="department-main-content-feed">
        <feed-posts
          :loggedUser="loggedUser"
          :isLoadingPosts="isLoadingPosts"
          :isLoadingScroll="isLoadingScroll"
          :posts="posts"
          :showCreatePost="canWritePost()"
          :partnerName="partner.name"
          :focusAreas="focus_areas"
          :identities="identities"
          :audiences="audiences"
          :has_navbar="has_navbar"
          :show_search="show_search"
          @show-all-comments="showAllComments"
          @show-notification="showNotification"
          @get-posts="getPosts"
          @set-is-loading="setIsLoading"
          @search-feed="searchFeed"
          @update-post-reaction="updatePostReaction"
        />
      </div>
      <partner-details
        v-if="selected_tab === 'overview'"
        :loggedUser="loggedUser"
        :partner="partner"
        :hasEventsAccess="hasEventsAccess"
        :hasProgramsAccess="hasProgramsAccess"
        :isLoadingPosts="isLoadingPosts"
        :posts="latestPosts"
        @show-notification="showNotification"
        @set-is-loading="setIsLoading"
      />
      <resources-tab
        v-if="selected_tab === 'resources'"
        :partner="partner"
        :loggedUser="loggedUser"
        :isPartnerTab="true"
        :isPartnerManager="isPartnerManager"
        :isPartnerAdmin="isPartnerAdmin"
      />
      <div v-if="selected_tab === 'events'" class="partner-opportunities-container">
        <partner-events-tab :partner="partner" :loggedUser="loggedUser" :isPartnerTab="true" :isProgramTab="false" />
      </div>
      <div v-if="selected_tab === 'opportunities'" class="partner-opportunities-container">
        <partner-events-tab :partner="partner" :loggedUser="loggedUser" :isPartnerTab="true" :isProgramTab="true" />
      </div>

      <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
    </div>
  </div>
</template>
<script lang="ts">
import * as Cookies from "js-cookie";
import PartnerAccountService from "../services/PartnerAccountService";
import ResourcesUpload from "../../library/components/ResourcesUpload.vue";
import Notification from "../../components/Notification.vue";
import FeedPosts from "../../feed/components/FeedPosts.vue";
import PartnerDetails from "./PartnerDetails.vue";
import PartnerEventsTab from "./PartnerEventsTab.vue";
import ResourcesTab from "../../feed/components/ResourcesTab.vue";
import AdminsAndMentorsTab from "../../feed/components/AdminsAndMentorsTab.vue";
import FollowersTab from "../../feed/components/FollowersTab.vue";
import AccountsService from "../../accounts/services/AccountsService";
import FeedService from "../../feed/services/FeedService";
import NavigationTabs from "../../components/NavigationTabs.vue";
import { useCommonDataStore } from "../../stores/commonData";
import { FEATURES, hasPermission } from "../../utils/PermissionUtils";
import draggable from 'vuedraggable';

const otherNavigationItems = [
  {
    label: "Events",
    routeName: "events",
    isRedirectUrl: false,
  },
  {
    label: "Opportunities",
    routeName: "opportunities",
    isRedirectUrl: false,
  },
  {
    label: "Feed",
    routeName: "feed",
    isRedirectUrl: false,
  },
  {
    label: "Resources",
    routeName: "resources",
    isRedirectUrl: false,
  },
];

export default {
  components: {
    ResourcesUpload,
    Notification,
    FeedPosts,
    PartnerDetails,
    AdminsAndMentorsTab,
    ResourcesTab,
    PartnerEventsTab,
    FollowersTab,
    NavigationTabs,
    draggable
  },
  name: "partner-index",
  props: ["partner_name", "isPartnerManager", "isPartnerAdmin"],
  data() {
    return {
      loggedUser: null,
      partner: null,
      userCanEdit: null,
      posts: [],
      events: [],
      isLoadingPosts: true,
      isLoadingScroll: false,
      isLoadingPredata: false,
      isFetchPending: false,
      pageCount: 1,
      searchHolder: "",
      isNotificationActive: false,
      notification: { msg: "", isError: false, icon: null },
      searchResultsShown: false,
      has_navbar: false,
      show_search: false,
      selected_tab: "overview",
      users: [],
      ids_favorite_mentors: [],
      members: [],
      coverImage: "path/to/your/cover/image.jpg", // Replace with your image source
      coverImageWidth: 800, // Set the width of the cover image container
      coverImageHeight: 300, // Set the height of the cover image container
      coverImageX: 0, // Initial X position
      coverImageY: 0, // Initial Y position
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
      navigationItems: [
        {
          label: "Home",
          routeName: "overview",
          isRedirectUrl: false,
        },
      ],
      followRequestSent: false,
      service: new PartnerAccountService(),
      accountService: new AccountsService(),
      feedService: new FeedService(),
      commonDataStore: useCommonDataStore(),
      hasEventsAccess: false,
      hasProgramsAccess: false,
    };
  },
  async created() {
    this.isLoadingPredata = true;
    await this.getPredata();
    this.isLoadingPredata = false;
    this.hasEventsAccess = hasPermission(FEATURES.EVENTS);
    this.hasProgramsAccess = hasPermission(FEATURES.PROGRAMS);
    this.handleNavItems();
    this.initializeTab();
    this.scroll();
    await this.getPosts();
  },
  methods: {
    async getPredata() {
      const response = await this.service.getDetailsPredata(this.partner_name);
      this.loggedUser = {
        id: response.loggedUser,
        profile_pic: response.profile_pic,
        fullname: response.user_fullname,
        hasWriteAccess: response.isAdmin,
        isAdmin: response.isAdmin,
        isMentor: response.isMentor,
        has_activity_feed_access: response.has_activity_feed_access,
        has_event_access: response.has_event_access,
        has_event_managing_access: response.has_event_managing_access,
        partnerAdmins: response.partner_admins,
        resource_library_permission: {
          has_library_resource_access: response.has_library_resource_access,
          has_library_resource_managing_access: response.has_library_resource_managing_access,
        },
      };
      this.partner = response.partner;
      this.userCanEdit = response.userCanEdit;
      if (this.partner.partner_type !== "BASIC") {
        this.navigationItems = [...this.navigationItems, ...otherNavigationItems];
      }
    },
    onDragStart(evt) {
      console.log('drag', evt)
      this.startY = evt.clientY || evt.touches[0].clientY;
    },
    onDragEnd(evt) {
      const currentY = evt.clientY || evt.touches[0].clientY;
      const deltaY = currentY - this.startY;
      this.coverImageY += deltaY;
      this.savePosition();
    },
    savePosition() {
      localStorage.setItem('coverImageY', this.coverImageY);
    },
    handleNavItems() {
      if (!this.hasEventsAccess) {
        this.navigationItems = this.navigationItems.filter((e) => e.routeName !== "events");
      }
      if (!this.hasProgramsAccess) {
        this.navigationItems = this.navigationItems.filter((e) => e.routeName !== "opportunities");
      }
    },
    initializeTab() {
      const currentURL = window.location.href;
      const urlSearchParams = new URLSearchParams(new URL(currentURL).search);
      const tab = urlSearchParams.get("tab");
      if (tab && this.partner.partner_type !== "BASIC") {
        this.selected_tab = tab;
      }
    },
    isTenantAdmin() {
      const userStatusDataComponent: HTMLElement = document.querySelector(".js-status-data");
      const loggedUser = JSON.parse(userStatusDataComponent.dataset.userStatus);
      return loggedUser.user.role === "ADMIN";
    },
    showAllComments(post) {
      this.posts = this.posts.map((p) => {
        if (post.id === p.id) {
          p.shrinkComments = false;
        }
        return p;
      });
    },
    checkCommentSize() {
      this.posts = this.posts.map((p) => {
        if (p.comments.length >= 3 && p.shrinkComments === undefined) {
          p.shrinkComments = true;
        }
        return p;
      });
    },
    canWritePost() {
      return this.loggedUser.hasWriteAccess;
    },
    setIsLoading(isLoading) {
      this.isLoadingPosts = isLoading;
    },
    showNotification(notification) {
      this.isNotificationActive = true;
      this.notification = notification;
    },
    closeNotification() {
      this.isNotificationActive = false;
    },
    async getPosts(pageNo, minPosts = 3) {
      this.pageCount = pageNo ? pageNo : this.pageCount;
      let newPosts = [];
      // with this while loop, we make sure that as a result we get at least 5 new posts
      while (newPosts.length <= minPosts) {
        this.isFetchPending = true;
        // get all posts from getstream (even if they're not the right type)
        const rawRes = await this.feedService.getPartnerPosts({
          partner: encodeURIComponent(this.partner.name),
          q: this.searchHolder,
          page: this.pageCount,
        });
        if (rawRes.length === 0) {
          break;
        }
        // filter out right type of posts
        const res = rawRes.filter((p) => p.verb.includes("post"));
        newPosts = [...newPosts, ...res];
        if (newPosts.length <= minPosts) {
          this.pageCount++;
        }
      }
      this.posts = newPosts;
      this.posts &&
        this.posts.forEach((p) => {
          p.object = JSON.parse(p.object);
        });
      this.checkCommentSize();
      this.isFetchPending = false;
      this.isLoadingPosts = false;
    },
    async searchFeed(searchInput) {
      if (searchInput.length > 0) {
        this.searchResultsShown = true;
      } else this.searchResultsShown = false;
      this.searchHolder = searchInput;
      this.isLoadingPosts = true;
      this.show_search = true;
      await this.getPosts(1, 0);
    },
    async updatePostReaction(p) {
      p.object = JSON.parse(p.object);
      this.posts = this.posts.map((e) => {
        if (e.id === p.id) {
          e.own_reactions = p.own_reactions;
          e.reaction_counts = p.reaction_counts;
          e.comments = p.comments;
          e.newComment = "";
        }
        return e;
      });
    },
    async scroll() {
      window.onscroll = async () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 1 >= document.documentElement.offsetHeight;

        if (bottomOfWindow && this.pageCount !== 0 && !this.searchResultsShown && !this.isFetchPending) {
          this.pageCount += 1;

          if (!this.isLoadingPosts) {
            this.isLoadingScroll = true;
          }
          if (!this.isFetchPending) {
            let newPosts = [];
            while (newPosts.length <= 3) {
              this.isFetchPending = true;
              // get all posts from getstream (even if they're not the right type)
              const rawRes = await this.feedService.getPartnerPosts({
                page: this.pageCount,
                partner: encodeURIComponent(this.partner.name),
                q: this.searchHolder,
              });
              if (rawRes.length === 0) {
                this.pageCount = 0;
                break;
              }
              // filter out right type of posts
              const res = rawRes.filter((p) => p.verb.includes("post"));
              newPosts = [...newPosts, ...res];
              if (newPosts.length <= 3) {
                this.pageCount++;
              }
            }

            newPosts.forEach((p) => {
              p.object = JSON.parse(p.object);
              this.posts.push(p);
            });
            this.isLoadingScroll = false;
            this.isFetchPending = false;
            this.checkCommentSize();
          }
        }
      };
    },
    async change_tab(tab) {
      this.selected_tab = tab;
    },
    redirectTo(url) {
      window.location.href = url;
    },
    async toggleFollowing() {
      this.followRequestSent = true;
      const res = await this.feedService.followPartnerFeed(
        { isFollowing: !this.is_following, partner: this.partner.name },
        this.headers
      );
      if (res?.success) {
        this.partner.user_follows = !this.is_following;
      }
      this.followRequestSent = false;
    },
  },

  computed: {
    is_following() {
      return this.partner ? this.partner.user_follows : false;
    },
    followButtonText() {
      return this.is_following ? "Unfollow" : "Follow";
    },
    latestPosts() {
      if (!this.posts) {
        return [];
      }

      return this.posts.slice(0, 3);
    },
    audiences() {
      return this.commonDataStore.getSingleDataArray("audiences");
    },
    focus_areas() {
      return this.commonDataStore.getSingleDataArray("focus_areas");
    },
    identities() {
      return this.commonDataStore.getSingleDataArray("identities");
    },
  },
};
</script>
<style lang="scss">
.drag-handle {
  color: #b6b4bf;
  font-size: 24px;
  cursor: move;
  margin-right: 16px;
  user-select: none;
}
.group-page-header-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid #c3cbd6;
  padding: 0 2rem;

  .image-container {
    width: 100%;
    min-height: 300px;
    max-width: 1400px;
    overflow: hidden;
    border-radius: 0 0 24px 24px;
    position: relative;
    padding: 2.5rem 2rem;
    min-height: 22.5rem;

    .image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .tint {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .back-button-container {
      width: 100%;
      position: relative;

      .back-button {
        padding: 0.75rem 1.5rem;
        color: #101828;
        background-color: #d8dce5;
        border: none;
        border-radius: 100px;
        font-size: 1rem;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          background-color: #838ea1;
        }

        .back-arrow {
          color: #2d98d2;
          font-size: 0.875rem;
          font-weight: 500;
        }
      }
    }

    .content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.75rem;
      position: relative;
    }

    .tag-pill {
      background-color: #e7e5ec;
      color: #88858e;
      font-size: 0.75rem;
      padding: 0.125rem 0.375rem 0.188rem;
      border-radius: 4px;
    }

    .logo {
      border: 3px solid #fff;
      border-radius: 100px;
      width: 6rem;
      height: 6rem;
      object-fit: cover;
      object-position: center;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1.2;
        color: #fff;
      }

      .description {
        font-size: 1.25rem;
        line-height: 1.45;
        color: #fff;
        line-clamp: 2;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
    }
  }
}

.action-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-top: 0.75rem;
  gap: 1.5rem;

  .buttons-container {
    display: flex;
    gap: 0.5rem;
  }

  .edit-button {
    padding: 0.5rem 1.5rem;
    border-radius: 100px;
    border: 2px solid #838ea1;
    background-color: transparent;
    color: #101828;
    font-weight: 600;
    font-size: 1rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    i {
      color: #4ebaaa;
      font-size: 0.875rem;
    }

    &:hover {
      cursor: pointer;
      background-color: #838ea1;
    }
  }

  .follow-button {
    padding: 0.5rem 1.5rem;
    border-radius: 100px;
    font-weight: 600;
    border: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    color: #101828;
    background-color: #2d98d2;

    &:hover {
      cursor: pointer;
      background-color: #006ead;
    }
  }
}

.partner-page-nav-section {
  width: 100%;
  position: relative;
  top: 5px;
  padding-top: 1rem;
}

.partner-opportunities-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .partner-accounts-section {
    padding: 0;
  }
  .group-page-header-container {
    padding: 0;

    .image-container {
      padding: 1.5rem 1rem;
      .back-button-container {
        .back-button {
          height: 3rem;
          width: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .text {
            display: none;
          }

          .back-arrow {
            font-size: 1rem;
            color: #101828;
          }
        }
      }

      .text-container {
        .title {
          font-size: 2rem;
        }
        .description {
          font-size: 1.125rem;
        }
      }
    }
  }

  .partner-page-nav-section {
    padding: 0 1.25rem;
  }

  .action-container {
    padding-top: 1.5rem;
    flex-direction: column-reverse;
  }

  .partner-opportunities-container {
    padding: 1.5rem 1rem;
  }
}
</style>
