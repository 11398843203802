<template>
    <section>
        <section style="margin-bottom: 200px;"> 
            <div class="eu-simple-title"> Find a Mentor
                <span 
                  tabindex="0" 
                  @click="setCurrentStep('create-appointment')"
                  @keyup.enter="setCurrentStep('create-appointment')" 
                  role="button"
                  class="eu-meetings-back-btn">
                <i class="far fa-arrow-left eu-back-btn-arrow"></i>
                <span>Back to Form</span>
            </span> 
            </div>
            <div v-if="mentor_results.length > 0" class='eu-appoint-title' style="margin-bottom: 5px;"> Selection Criteria </div>
            <div class='eu-appoint-subtitle' :style="mentor_results.length > 0 ? '' : 'margin-top: 50px' "> 
            {{ mentor_results.length > 0 ? 'The following mentors meet your criteria.' : 'There are no mentors that match your search.' }} 
            </div>

            <span v-for="user in mentor_results" @click="selectMentor(user)" v-bind:value="user.id" v-bind:key="user.id" >
            <profile-card :user="user" :department_name="meetingRequest.department.name" @openModal="openProfileModal"
                :className="meetingRequest.chosen_mentor && meetingRequest.chosen_mentor.id === user.id ? 'eu-meetings-mentor-card active' : 'eu-meetings-mentor-card' "
            />
            </span>

            <div v-if="mentor_results.length > 0" >

              <div class='eu-appoint-title'> Date &amp; Time </div>

              <div :style="availableDates.length == 0 ? 'pointer-events: none;opacity:0.5': ''" class="field-wrapper hasValue eu-file-upload-modal-field eu-meetings-input">
                  <select class="eu-upload-select eu-meetings-select" @change="onDateSelected" v-model="meetingRequest.date">
                  <option v-for="option in availableDates" v-bind:value="option" v-bind:key="option">
                      {{ option }}
                  </option>
                  </select>
                  <i class="fal fa-angle-down eu-select-form-arrow"></i>
                  <div class="field-placeholder" style="bottom: 38px">
                  <span style="background: #fafafa;">Date<span style="background: #fafafa;" class="required-star">*</span></span>
                  </div>
              </div>

              <div class="eu-meetings-date-time-input-wrapper">
                  <div :style="meetingRequest.date == null ? 'pointer-events: none;opacity:0.5': ''" class="field-wrapper hasValue eu-file-upload-modal-field eu-meetings-input ">
                  <select class="eu-upload-select eu-meetings-select"  v-model="meetingRequest.time">
                      <option v-for="option in availableTimes" v-bind:value="option.id" v-bind:key="option.time">
                      {{ option.time }}
                      </option>
                  </select>
                  <i class="fal fa-angle-down eu-select-form-arrow"></i>
                  <div class="field-placeholder" style="bottom: 38px">
                      <span style="background: #fafafa;">Time<span style="background: #fafafa;" class="required-star">*</span></span>
                  </div>
                  </div>
              </div>
              <div class="field-wrapper hasValue eu-file-upload-modal-field eu-meetings-input" style="margin-top:45px;" >
                <textarea v-model="meetingRequest.description" type="text" placeholder="Enter your request details here." rows="4" class="eu-textarea-modern" />
                <div class="field-placeholder"><span style="background: #fafafa;"> Message<span style="background: #fafafa;" class="required-star">*</span></span></div>
              </div>
            </div>
            <span v-if="formError" class="eu-meetings-error">Please fill in all required fields.</span>
            <button v-if="mentor_results.length > 0" @click="submitMeetingRequest(true)" class="da-components-button da-btn-solid eu-file-upload-modal-btn-primary eu-meetings-form-btn">Request Appointment</button>
            <button @click="setCurrentStep('create-appointment')" style="color: #4c55a7" class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary eu-meetings-form-btn">Back to Form</button>
            <button @click="setCurrentStep('appointments')" style="color: #4c55a7" class="da-components-button da-btn-standard eu-file-upload-modal-btn-secondary eu-meetings-form-btn">Cancel</button>
        </section>
        <div class='accounts-view-section'>
        <profile-card-modal :isModalActive="isProfileModalActive" :user="meetingRequest.chosen_mentor" @onClose="closeProfileModal" />
        </div>
    </section>
</template>
<script lang="ts">
import ProfileCard from '../../../accounts/components/ProfileCard.vue';
import ProfileCardModal from '../../../accounts/components/ProfileCardModal.vue';
import "../../utils/DateParser";

export default {
  components: {ProfileCard, ProfileCardModal},
  name: 'choose-mentor-section',
  props: ['meetingRequest', 'availableDates', 'availableTimes', 'mentor_results', 'formError'],
  data() {
    return {
        isProfileModalActive: false,
    };
  },
  async created() {},
  methods: {
    submitMeetingRequest(isFinal) {
      this.$emit('_submitMeetingRequest', isFinal);
    },
    setCurrentStep(step) {
      this.$emit('_setCurrentStep', step);
    },
    selectMentor(user) {
      this.$emit('_selectMentor', user);
    },
    onDateSelected(){
       this.$emit('_onDateSelected');
    },
    openProfileModal() {
      this.isProfileModalActive = true;
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      sidebar.style.display = "none";
    },
    closeProfileModal() {
      this.isProfileModalActive = false;
      const sidebar: HTMLElement = document.querySelector("#sidebar");
      sidebar.style.display = "block";
    }
  },
  computed: {}
};
</script>



