import Vue from 'vue';
import UserImportInterface from './components/UserImportInterface.vue';
import { Department, Role } from '../types';

/*
  Roles is sent as an array to support setting of different roles on bulk
  or manual upload.
  The expected values are Role objects.

  In the cases where we want the Role to be hard set on the page, we can
  pass isRoleChangeable as False, and then we'll use only the first role
  that is passed as a param.
*/
interface appData {
  isRoleChangeable: boolean;
  departments: Array<Department>,
  is_dept_lead: boolean
};


export default class UserImport {
  protected mountPoint: HTMLElement;
  protected appData: appData;

  constructor(el: HTMLElement, appData: appData) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.appData = appData;
    this.run();
  };

  async run(): Promise<void> {
    const _class = this;

    const importInterface = new Vue({
      components: { UserImportInterface },

      template: `
        <user-import-interface
          :roles="[{'name': 'Mentor'}]"
          :isRoleChangeable="isRoleChangeable"
          :departments="departments"
          :is_dept_lead="is_dept_lead"
        />
      `,

      data() {
        return {
          isRoleChangeable: _class.appData.isRoleChangeable,
          departments: _class.appData.departments,
          is_dept_lead: _class.appData.is_dept_lead
        };
      },

    });

    importInterface.$mount(this.mountPoint);
  }
}