<template>
    <div class="eu-form-container inup-form-container">
        <form
            @submit.prevent="submitForm()"
            class="eu-form inup-form"
            id="eu-form"
            enctype="multipart/form-data"
        >
            <div class="eu-form-section inup-form-section">
                <h2 class="header-two-margin-bot">Policy details</h2>
                <div class="eu-form-element-container-no-bot-margin">
                    <input
                        class="inup-input"
                        type="text"
                        required
                        v-model="policy.title"
                    />
                    <div class="inup-form-label-container">
                        <label for="name">Policy Title<span> *</span></label>
                    </div>
                    <!-- error -->
                </div>
                <div class="eu-form-description-container">
                    <textarea
                        name="description"
                        cols="40" rows="10"
                        class="inup-textarea-input vertical"
                        v-model="policy.description"></textarea>
                    <div class="inup-textarea-label-container">
                        <label for="description">Description</label>
                    </div>
                    <!-- error -->
                </div>
                <div class="eu-form-element-container">
                    <multiselect-component
                        :options="audience"
                        :preselected='policy.audience'
                        name="audience"
                        @handle-change="handleChange"
                    />
                    <div class="inup-form-label-container">
                        <label for="type">Poplicy Audience</label>
                    </div>
                    <!-- error -->
                </div>
                <div class="eu-form-element-container">
                    <select name="department" class="inup-input" v-model="policy.department" :disabled="is_update" required>
                        <option value selected>Select from list</option>
                        <option v-for="option in departments" v-bind:value="option.id" v-bind:key="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                    <div class="inup-form-label-container">
                        <label for="department">Department<span> *</span></label>
                    </div>
                    <!-- error -->
                </div>
                <div class="eu-form-element-container">
                    <select name="type" class="inup-input" v-model="policy.type" required>
                        <option value selected>Select from list</option>
                        <option v-for="option in type" v-bind:value="option.value" v-bind:key="option.name">
                            {{ option.name }}
                        </option>
                    </select>
                    <div class="inup-form-label-container">
                        <label for="type">Type<span> *</span></label>
                    </div>
                    <!-- error -->
                </div>
                <div class="eu-form-element-container">
                    <multiselect-component
                        :options='identities'
                        :preselected="this.policy.identities"
                        name="identities"
                        @handle-change="handleChange"
                    />
                    <div class="inup-form-label-container">
                        <label for="type">Identity Groups<span> *</span></label>
                    </div>
                    <!-- error -->
                </div>
                <div class="eu-form-element-container">
                    <multiselect-component
                        :options="focus_areas"
                        :preselected="this.policy.focus_areas"
                        name="focus_areas"
                        @handle-change="handleChange"
                    />
                    <div class="inup-form-label-container">
                        <label for="type">Poplicy Focus</label>
                    </div>
                    <!-- error -->
                </div>
                <div class="upload-file-form">
                    Document uploud section
                    <div class="row">
                        <p class="error">{{ error }}</p>
                    </div>
                    <div class="row upload-file-container">
                        <span v-for="file in files" v-bind:key="file.name" style="position: relative; margin: 10px 0">
                            <label v-if="file.file_type == 'IMG'" class="uploaded-image-preview" :style="{ backgroundImage: 'url(' + file.file_url + ')' }"></label>
                            <i v-if="file.file_type == 'DOC'" class="fas fa-file-upload uploaded-file-icon"></i>
                            <span class="eu-upload-filename"> {{ file.name }} </span>
                            <span class="eu-upload-status">({{ file.status }})</span>
                            <span v-if="file.status == 'uploading'" class="eu-line eu-stripesLoaderAnimated"></span>
                            <span v-if="file.status == 'pending'" class="eu-line eu-stripesLoader" style="background-position: 0"></span>
                            <span v-if="file.status == 'failed'" class="eu-line eu-stripesLoader" style="background-color: #E65722"></span>
                        </span>
                    </div>
                    <div class="row upload-file-container">
                    <div class="column" style="width: 100%">
                        <div v-cloak @drop.prevent="handleFileUpload" @dragover.prevent class="innerRow dnd-wrapper">
                        <label
                            @dragenter="dragging = true"
                            @dragleave="dragging = false"
                            class="file-upload-label"
                            :style="{ background: dragging ? '#eaeeff' : '' }"
                            for="resource_image"
                        >
                            <span>
                            <i class="fad fa-cloud-upload-alt dnd-upload-icon"></i> <br />
                            Drag &amp; drop files here <br />
                            or <br />
                            <input @change="handleFileUpload" id="resource_image" type="file" style="width:0" multiple />
                            <span class='file-input-text'>
                                Browse from device <i class="fal fa-angle-right" style="color: #5E50B5; font-size: 15px"></i>
                            </span>
                            </span>

                        </label>
                        </div>
                    </div>
                    <label id='acceptedFileTypes' class="eu-checkbox-container" style="padding-left: 0; margin-top: 25px; text-align: center; color: #707070"
                        >Accepted file types: <br />
                        <span style="font-weight: 500" aria-labelledby="acceptedFileTypes"> .pdf, .doc, .xls, .ppt, .jpg, .png, .gif, .webp </span>
                    </label>
                    </div>
                </div>
                <div class="inup-button-container">
                    <input
                        type="submit"
                        value="Save"
                        class="eu-btn-primary-small"
                    />
                    <a class="eu-btn-secondary-small" href="/events/"> Cancel </a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import MultiselectComponent from '../../form/components/MultiselectComponent.vue'
import { validateExtensionFile} from '../../utils/TypecheckUtils';
import FeedService from '../../feed/services/FeedService'
import PolicyService from '../services/PolicyService'
import * as Cookies from 'js-cookie';
export default {
    components:{MultiselectComponent},
    name:'policies-manage',
    props:['audience', 'type', 'departments', 'identities' ,'focus_areas', 'policy_update', 'is_update'],
    data(){
        return{
            policy: {
                title: '',
                type: '',
                description: '',
                department: '',
                audience: [],
                valid_date: '2021-12-22 11:09:22.170548+01',
                focus_areas: [],
                identities: [],
                documents: [],
            },
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
                Accept: 'application/json'
            },
            files: [],
            dragging: false,
            error: '',
        }
    },
    created(){
        if(this.policy_update){
            this.policy = {...this.policy_update, department:this.policy_update.department.id}
        }

        this.service = new PolicyService();
        this.feedService = new FeedService();
    },
    methods:{
        handleChange(value, name){
            this.policy[name] = value.map(v=> v.value);
        },
        async create(){
            if (this.files.length !== 0) {
                await this.files.forEach(async file => {
                if (file.name.length > 0) {
                    file.status = 'uploading';
                    const res = await this.feedService.uploadFile(file, this.headers);
                        if (res.success) {
                            file.file_url = res.resource
                            this.policy.documents.push(file);
                            file.status = 'uploaded';
                            this.files = this.files.filter(e => e.name !== file.name);
                            if (this.files.length == 0) {
                                await this.service.create(this.policy,this.headers);
                            }
                        } else {
                            file.status = 'failed';
                            failure = true;
                            this.error = `Upload failed for file ${file.name}: Server error.`;
                        }
                    } else {
                        file.status = 'failed';
                        this.error = `Upload failed for file ${file.name}: Required file details missing.`;
                        failure = true;
                    }
                });
            }else{
                await this.service.create(this.policy,this.headers);
            }

        },
        submitForm(){
            if(this.is_update){
                this.service.update(this.setAditonalField(), this.headers);
                return;
            }
            this.create();
        },
        handleFileUpload(event) {
            const uploadFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
            const imageExt = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
            const docExt = ['txt', 'pdf', 'doc', 'docx', 'xlsx', 'ppt', 'pptx'];
            if (!uploadFiles.length) return;
            for (let i = 0; i < uploadFiles.length; i++) {
                this.error = '';
                let file = {
                file_url: URL.createObjectURL(uploadFiles[i]),
                file: uploadFiles[i],
                name: uploadFiles[i].name,
                file_type: null,
                department: '',
                status: 'pending',
                error: null
                };
                if (validateExtensionFile(uploadFiles[i].name, imageExt)) {
                file.file_type = 'IMG';
                } else if (validateExtensionFile(uploadFiles[i].name, docExt)) {
                file.file_type = 'DOC';
                } else {
                this.error = 'Please use a supported image filetype.';
                return;
                }

                if (!this.files.some(e => e.name === file.name)) {
                this.files.push(file);
                } else {
                this.error = `File with name '${file.name}' already exists.`;
                return;
                }
            }
        },
        setAdditionalFields() {
            let dto = {...this.policy};
            dto.focus_areas = this.policy.focus_areas.map(e => e?.value ? e.value: e);
            dto.identities = this.policy.identities.map(e => e?.value ? e.value: e);
            dto.audience = this.policy.audience.map(e => e?.value ? e.value: e);
            return dto;
        }
    }
}
</script>
