import HttpService from '../../services/HttpService';

export default class AuthService extends HttpService {
  constructor() {
    super('/session/api/login');
  }

  login(data, headers, throwError=false) {
    return this.post(data, headers, throwError);
  }
}
