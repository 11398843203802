import HttpService from './HttpService';

export default class ContactGroupService extends HttpService {
  constructor() {
    super("/accounts/api/contactGroup");
  }

  getContactGroupList(): Promise<any> {
    this.endpoint = `/accounts/api/contactGroup`
    return this.get();
  }

  addContactGroup(contactGroup, headers): Promise<any> {
    this.endpoint = `/accounts/api/contactGroup`
    return this.postFormData(contactGroup, headers)
  }

  getContactGroup(contactGroup_id): Promise<any> {
    this.endpoint = `/accounts/api/contactGroup/${contactGroup_id}`;
    return this.get();
  }

  editContactGroup(contactGroup, headers): Promise<any> {
    this.endpoint = `/accounts/api/contactGroup/${contactGroup.id}`;
    return this.putFormData(contactGroup, headers)
  }

  deleteContactGroup(contactGroup, password, headers): Promise<any> {
    this.endpoint = `/accounts/api/contactGroup/${contactGroup.id}`;
    return this.delete({'password': password}, headers)
  }

  getContactGroupUsers(contactGroup_id, params): Promise<any> {
    this.endpoint = `/accounts/api/contactGroup/${contactGroup_id}/user`;
    return this.get(params);
  }

  addContactGroupUsers(data, headers): Promise<any> {
    this.endpoint = `/accounts/api/contactGroup/${data.contactGroup_id}/user`;
    let put_data = {};
    put_data["user_id"] = data.user_id? data.user_id: null;
    put_data["user_id_list"] = data.user_list? data.user_list: [];
    put_data["method"] = "REPLACE";

    return this.putFormData(put_data, headers);
  }

  bulkUploadUsers(file, headers): Promise<any> {
    this.endpoint = `/accounts/api/contactGroup/${file.contactGroup_id}/user`;
    return this.putFormData(file, headers);
  }

  removeContactGroupUsers(data, headers): Promise<any> {
    this.endpoint = `/accounts/api/contactGroup/${data.contactGroup_id}/user`;
    let put_data = {};
    put_data["user_id"] = data.user_id? data.user_id: null;
    put_data["user_id_list"] = data.user_list? data.user_list: [];
    put_data["method"] = "DELETE";

    return this.putFormData(put_data, headers);
  }
}
