import Vue from 'vue';
import EventRepeatDialog from "./components/EventRepeatDialog.vue";

export default class EventRepeatDialogView {
  private readonly mountPoint: HTMLElement;
  private readonly eventStartDate: Date;
  private readonly eventStartTime: String;
  private readonly eventEndDate: Date | null;
  private readonly preselectedPeriod: 'daily' | 'weekly' | 'monthly';
  private readonly preselectedRepeatPeriodNumber: Number;
  private readonly preselectedPointsInPeriod: Array<String> | Array<Number>;
  private readonly preselectedEndOption: 'on-date' | 'after-occurrences';
  private readonly preselectedEndDate: String | Date;
  private readonly preselectedEndOccurrencesNumber: Number;

  constructor(
    el: HTMLElement,
    eventStartDate: Date,
    eventStartTime: String,
    eventEndDate: Date | null,
    preselectedPeriod: 'daily' | 'weekly' | 'monthly',
    preselectedRepeatPeriodNumber: Number,
    preselectedPointsInPeriod: Array<String> | Array<Number>,
    preselectedEndOption: 'on-date' | 'after-occurrences',
    preselectedEndDate: String | Date,
    preselectedEndOccurrencesNumber: Number
  ) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.eventStartDate = eventStartDate;
    this.eventStartTime = eventStartTime;
    this.eventEndDate = eventEndDate;
    this.preselectedPeriod = preselectedPeriod;
    this.preselectedRepeatPeriodNumber = preselectedRepeatPeriodNumber;
    this.preselectedPointsInPeriod = preselectedPointsInPeriod;
    this.preselectedEndOption = preselectedEndOption;
    this.preselectedEndDate = preselectedEndDate;
    this.preselectedEndOccurrencesNumber = preselectedEndOccurrencesNumber;
    this.run();
  }

  async run(): Promise<void> {
    const _self = this;

    const eventRepeatDialog = new Vue({
      components: { EventRepeatDialog },
      template: `
        <event-repeat-dialog
          :isActive="isActive"
          :eventStartDate="eventStartDate"
          :eventStartTime="eventStartTime"
          :eventEndDate="eventEndDate"
          :preselectedPeriod="preselectedPeriod"
          :preselectedRepeatPeriodNumber="preselectedRepeatPeriodNumber"
          :preselectedPointsInPeriod="preselectedPointsInPeriod"
          :preselectedEndOption="preselectedEndOption"
          :preselectedEndDate="preselectedEndDate"
          :preselectedEndOccurrencesNumber="preselectedEndOccurrencesNumber"
          @close-repeat-modal="closeRepeatModal"
          @save-repeat-config="saveRepeatConfig"
        />
      `,
      data() {
        return {
          isActive: true,
          eventStartDate: _self.eventStartDate,
          eventStartTime: _self.eventStartTime,
          eventEndDate: _self.eventEndDate,
          preselectedPeriod: _self.preselectedPeriod,
          preselectedRepeatPeriodNumber: _self.preselectedRepeatPeriodNumber,
          preselectedPointsInPeriod: _self.preselectedPointsInPeriod,
          preselectedEndOption: _self.preselectedEndOption,
          preselectedEndDate: _self.preselectedEndDate,
          preselectedEndOccurrencesNumber: _self.preselectedEndOccurrencesNumber,
        }
      },
      methods: {
        closeRepeatModal() {
          this.isActive = false;
        },
        saveRepeatConfig(config) {
          const occurrenceFrequencyInput = <HTMLInputElement>document.getElementById('occurrence-frequency');
          const occurrenceIntervalInput = <HTMLInputElement>document.getElementById('occurrence-interval');
          const occurrenceFinalDateInput = <HTMLInputElement>document.getElementById('occurrence-final-date');
          const occurrenceTotalInput = <HTMLInputElement>document.getElementById('occurrences-total');
          const recurringSelectedValues = <HTMLInputElement>document.getElementById('recurring-selected-values-input');
          const recurringCheckBox = <HTMLInputElement>document.getElementById('recurring-checkbox');

          recurringCheckBox.checked = config.recurring;
          occurrenceFrequencyInput.value = config.repeatPeriod;
          occurrenceIntervalInput.value = config.repeatPeriodNumber;
          occurrenceFinalDateInput.value = config.endConditions.onDate ? config.endConditions.onDate : null;
          occurrenceTotalInput.value = config.endConditions.afterOccurrencesNumber ? config.endConditions.afterOccurrencesNumber : null;
          recurringSelectedValues.value = JSON.stringify(config.repeatPeriodValues);
          this.closeRepeatModal();
        }
      }
    });

    eventRepeatDialog.$mount(this.mountPoint);
  }
}
