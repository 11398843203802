import Vue from 'vue';
import VueRouter from 'vue-router';
import VueProgressBar from 'vue-progressbar';
import PartnerTenantLandingApp from './components/landing_pages/PartnerTenantLandingApp.vue';
import PartnerTenantAboutUs from './components/landing_pages/PartnerTenantAboutUs.vue';
import PartnerTenantOpportunities from './components/landing_pages/PartnerTenantOpportunities.vue';
import PartnerTenantOpportunitiesLeadershipPrograms from './components/landing_pages/PartnerTenantOpportunitiesLeadershipPrograms.vue';
import PartnerTenantHome from './components/landing_pages/PartnerTenantHome.vue';
import PartnerTenantPartners from './components/landing_pages/PartnerTenantPartners.vue';
import PartnerTenantBlogs from './components/landing_pages/PartnerTenantBlogs.vue';
import BlogDetails from '../blogs/components/BlogDetails.vue';
import { createPinia, PiniaPlugin } from 'pinia';
import VueCompositionAPI from '@vue/composition-api'
import { globalProgressBarOptions } from '../GlobalComponents';
import { SelectedDataIndexPlugin } from '@syncfusion/ej2-vue-charts';

export default class PartnerTenantHomeView {
  protected mountPoint: HTMLElement;

  constructor(el: HTMLElement) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);

    this.run();
  }

  async run(): Promise<void> {
    const _class = this;

    const routes = [
      {path: '/partner/home', component: PartnerTenantHome},
      {path: '/partner/home/about-us', component: PartnerTenantAboutUs},
      {path: '/partner/home/opportunities', component: PartnerTenantOpportunities},
      {path: '/partner/home/opportunities/leadership-programs', component: PartnerTenantOpportunitiesLeadershipPrograms},
      {path: '/partner/home/partners', component: PartnerTenantPartners},
      {path: '/partner/home/blogs', component: PartnerTenantBlogs},
      {
        path: '/partner/home/blogs/:slug',
        component: BlogDetails,
        props: true,
      }
    ]

    const progressBarOptions = globalProgressBarOptions;

    Vue.use(VueCompositionAPI);
    Vue.use(VueRouter);
    const pinia = createPinia();
    Vue.use(PiniaPlugin);
    Vue.use(VueProgressBar, progressBarOptions)

    const router = new VueRouter({
      routes, // short for `routes: routes`
      mode: 'history',
      scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
      },
    })

    const partnerTenantHome = new Vue({
      components: {'partner-tenant-landing-app': PartnerTenantLandingApp},
      template: `<partner-tenant-landing-app />`,
      pinia,
      data() {
        return {

        }
      },
      mounted () {
        //  [App.vue specific] When App.vue is finish loading finish the progress bar
        this.$Progress.finish()
      },
      created () {
        //  [App.vue specific] When App.vue is first loaded start the progress bar
        this.$Progress.start()
        //  hook the progress bar to start before we move router-view
        this.$router.beforeEach((to, from, next) => {
          //  does the page we want to go to have a meta.progress object
          if (to.meta.progress !== undefined) {
            let meta = to.meta.progress
            // parse meta tags
            this.$Progress.parseMeta(meta)
          }
          //  start the progress bar
          this.$Progress.start()
          //  continue to next page;
          next()
        })
        //  hook the progress bar to finish after we've finished moving router-view
        this.$router.afterEach((to, from) => {
          //  finish the progress bar
          this.$Progress.finish()
        })
      },
      methods: {

      },
      router,
    });

    partnerTenantHome.$mount(this.mountPoint);
  }
}
