<template>
  <table class="eu-resource-table">
    <tr class="eu-resource-row">
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="toggleAllManagers"
          @keyup.enter="toggleAllManagers"
          aria-label="Select all managers in table"
          role="checkbox"
          :class="allManagersSelected ? 'eu-resource-cell-select eu-resource-cell-select-active' : 'eu-resource-cell-select'"
        ></span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('name')"
          @keyup.enter="sort('name')"
          aria-label="Sort table by partner name"
          role="button"
        >
          Name
          <i :class="sortBy !== 'name' ? 'fa-solid fa-sort': sortDirection === 'up' ? 'fa-duotone fa-sort-up' : 'fa-duotone fa-sort-down'"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span>
          Email
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span>
          Role
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span>
          Added
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
        <span
          tabindex="0"
          @click="sort('is_active')"
          @keyup.enter="sort('is_active')"
          aria-label="Sort table by status"
          role="button"
        >
          Status
          <i :class="sortBy !== 'is_active' ? 'fa-solid fa-sort': (sortDirection === 'up' ? 'fa-duotone fa-sort-up' : 'fa-duotone fa-sort-down')"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th">
      </th>
    </tr>
    <manager-row
    v-for="(manager, i) in managers"
    :key="i"
    :rowVisible="true"
    :manager="manager"
    :isSelected="isManagerSelected(manager)"
    :isManagerOnly="true"
    :partner="{}"
    @toggle-manager="toggleManager"
    @delete-manager="deleteManager"
    @reset-password="resetPassword"
    @resend-invitation="resendInvitation"
    @edit-manager="editManager"
    ></manager-row>
  </table>
</template>

<script lang="ts">
import ManagerRow from './ManagerRow.vue'

export default {
  name: 'managers-table',
  components: {
    ManagerRow,
  },
  props: [
    'isManagerOnly',
    'managers',
    'selectedManagers',
    'sortDirection',
    'allManagersSelected',
  ],
  data() {
    return {
      sortBy: 'name',
    }
  },
  methods: {
    toggleAllManagers() {
      this.$emit('toggle-all-managers');
    },
    isManagerSelected(manager) {
      return this.selectedManagers.some(item => item.manager_id ===  manager.id && item.partner_id === manager.partner_id);
    },
    toggleManager(manager_id, partner_id) {
      this.$emit('toggle-manager', manager_id, partner_id);
    },
    deleteManager(manager) {
      this.$emit('delete-manager', manager.id, manager.partner_id);
    },
    editManager(manager) {
      this.$emit('edit-manager', manager, manager.partner);
    },
    resetPassword(manager_id) {
      this.$emit('reset-password', manager_id);
    },
    resendInvitation(manager_id, partner_id) {
      this.$emit('resend-invitation', manager_id, partner_id);
    },
    sort(sortBy: string) {
      this.sortBy = sortBy;
      this.$emit('sort', sortBy);
    },
  },
}
</script>
