<template>
    <section id="event-date-time-component">
        <div class="multiple-elements">
            <div class="eu-form-element-container-small">
                <div id="event-start-date-vue">
                    <datetime
                        input-id="start-date-input-component"
                        type="date"
                        class="datetime-theme-blues"
                        v-model="startDate"
                        input-class="inup-input"
                        :format="{ year: 'numeric', month: 'long', day: 'numeric',  }"
                        :phrases="{ok: 'Submit', cancel: 'Cancel'}"
                        :min-datetime="new Date().toISOString()"
                        :max-datetime="endDate ? new Date(endDate).toISOString() : null"
                        :week-start="7"
                        title="Start Date"
                        use12-hour
                        value-zone='UTC'
                        zone='UTC'
                    ></datetime>
                    <div class="inup-form-label-container">
                        <label id="start-date-label-vue" for="start_date">Start Date<span> *</span></label>
                    </div>
                </div>
            </div>
            <div class="eu-form-element-container-small">
                <datetime
                    input-id="start-time-input-component"
                    type="time"
                    class="datetime-theme-blues"
                    v-model="startTime"
                    input-class="inup-input"
                    :format="{ hour: 'numeric', minute: '2-digit' }"
                    :phrases="{ok: 'Submit', cancel: 'Cancel'}"
                    :minute-step="5"
                    :week-start="7"
                    title="Start Time"
                    use12-hour
                    value-zone='UTC'
                    zone='UTC'
                ></datetime>
                <div class="inup-form-label-container">
                    <label for="start_time">Start Time<span> *</span></label>
                </div>
            </div>
        </div>
         <div class="multiple-elements">
            <div class="eu-form-element-container-small">
                <div id="event-end-date-vue">
                    <datetime
                        input-id="end-date-input-component"
                        type="date"
                        class="datetime-theme-blues"
                        v-model="endDate"
                        input-class="inup-input"
                        :format="{ year: 'numeric', month: 'long', day: 'numeric',  }"
                        :phrases="{ok: 'Submit', cancel: 'Cancel'}"
                        :min-datetime="startDate ? new Date(startDate).toISOString() : new Date().toISOString()"
                        :week-start="7"
                        title="End Date"
                        use12-hour
                        value-zone='UTC'
                        zone='UTC'
                    ></datetime>
                    <div class="inup-form-label-container">
                        <label id="end-date-label-vue" for="end_date">End Date<span> *</span></label>
                    </div>
                </div>
            </div>
            <div class="eu-form-element-container-small">
                <datetime
                    input-id="end-time-input-component"
                    type="time"
                    class="datetime-theme-blues"
                    v-model="endTime"
                    input-class="inup-input"
                    :format="{ hour: 'numeric', minute: '2-digit' }"
                    :phrases="{ok: 'Submit', cancel: 'Cancel'}"
                    :minute-step="5"
                    :week-start="7"
                    title="End Time"
                    use12-hour
                    value-zone='UTC'
                    zone='UTC'
                ></datetime>
                <div class="inup-form-label-container">
                    <label for="end_time">End Time<span> *</span></label>
                </div>
            </div>
        </div>
    </section>
</template>
<script lang="ts">

import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import moment from 'moment'

export default {
  name: "event-datetime-component",

  props: ['startDateInput', 'endDateInput', 'startTimeInput', 'endTimeInput'],

  components: { datetime: Datetime },

  data() {
    return {
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: ''
    };
  },
  created(){
    if(this.startDateInput.value){
        this.startDate = this.startDateInput.value
    }
     if(this.endDateInput.value){
        this.endDate = this.endDateInput.value
    }
     if(this.startTimeInput.value){
        this.startTime = this.startTimeInput.value
    }
     if(this.endTimeInput.value){
        this.endTime = this.endTimeInput.value
    }
  },

  mounted() {
    if(this.startDateInput.value){
        this.startDate = this.startDateInput.value
    }
     if(this.endDateInput.value){
        this.endDate = this.endDateInput.value
    }
     if(this.startTimeInput.value){
        this.startTime = this.startTimeInput.value
    }
     if(this.endTimeInput.value){
        this.endTime = this.endTimeInput.value
    }
  },

  watch: {
      startDate: function(val) {
            this.startDateInput.value = moment.utc(val).format("yyyy-MM-DD");
            this.$emit('change');
      },
      startTime: function(val) {
        if (val.includes('-')) {
          this.startTimeInput.value = moment(val).format("HH:mm");
        } else {
          this.startTimeInput.value = val;
        }
        this.$emit('change');
      },
      endDate: function(val) {
            this.endDateInput.value = moment.utc(val).format("yyyy-MM-DD");
            this.$emit('change');
      },
      endTime: function(val) {
        if (val.includes('-')) {
            this.endTimeInput.value = moment(val).format("HH:mm");
        } else {
          this.endTimeInput.value = val;
        }
            this.$emit('change');
      }
  },
}

</script>
