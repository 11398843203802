import { render, staticRenderFns } from "./PartnerManage.vue?vue&type=template&id=549f1446&scoped=true"
import script from "./PartnerManage.vue?vue&type=script&lang=ts"
export * from "./PartnerManage.vue?vue&type=script&lang=ts"
import style0 from "./PartnerManage.vue?vue&type=style&index=0&id=549f1446&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "549f1446",
  null
  
)

export default component.exports