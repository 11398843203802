<template >
<section>
  <table class="eu-resource-table">
    <tr class="eu-resource-row">
      <th class="eu-resource-cell eu-resource-cell-th" scope="col">
        Opportunities
        <span tabindex="0" aria-label="Sort table by name" role="button" @click="sort('title')" @:keyup.enter="sort('title')">
          <i :class="sortedBy !== 'title' ? 'fas fa-sort' : directionUp.title ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th" scope="col">
        Date
        <span tabindex="0" aria-label="Sort table by role" role="button" @click="sort('start_date')" @:keyup.enter="sort('start_date')">
          <i :class="sortedBy !== 'start_date' ? 'fas fa-sort' : directionUp.start_date ? 'fad fa-sort-up' : 'fad fa-sort-down'"></i>
        </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th" scope="col">
        Type
        <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
      </th>
      <th class="eu-resource-cell eu-resource-cell-th" scope="col">
        Status
        <span tabindex="0" aria-label="Sort table by date added" role="button"> </span>
      </th>
      <th></th>
    </tr>
    <tr v-for="(item, i) in events" v-bind:key="i" tabindex="0" id="table_manage_account_${item.id}" role="row" class="eu-resource-row" style="position: relative;">
      <td class="eu-resource-cell event-cell">
        <span class="event-table-title" :title="item.title"> {{ item.title }}</span>
        <span class="event-table-date">{{ formatEventTime(item) }} {{ item.time_zone }}</span>
      </td>
      <td class="eu-resource-cell" style="white-space: nowrap;">{{ item.start_date | shortDateFormat() }}</td>
      <td class="eu-resource-cell" style="white-space: nowrap;">{{ item.is_hybrid ? 'Hybrid' : item.is_online ? "Virtual" : "In Person" }}</td>
      <td class="eu-resource-cell" style="white-space: nowrap;">
        <span v-if="!is_past_event(item) && item.is_published" class="mark-published"><i class="fas fa-calendar-check"></i>Published</span>
        <span v-if="!item.is_published" class="mark-draft"><i class="fas fa-pencil"></i>Draft</span>
        <span v-if="is_past_event(item) && item.is_published" class="mark-ended"><i class="fas fa-flag-checkered"></i>Ended</span>
      </td>
      <td class="eu-resource-cell eu-resource-cell-feature-icon">
        <span
          tabindex="0"
          :aria-label="item.is_featured ? 'Unfeature ' + item.title : 'Feature ' + item.title"
          role="button"
          class="resource-menu-button"
          @click="handleFeatured(item)"
          @keyup.enter="handleFeatured(item)"
        >
          <i class="fas fa-stars" :style="item.is_featured ? 'color: #00a1b3; background: #eaeeff' : ''"></i>
          <div class="tooltip-text">
            {{item.is_featured ? 'Unfeature item' : 'Feature item'}}
          </div>
        </span>
      </td>
      <td class="eu-resource-cell eu-resource-cell-bookmark-icon">
        <span
          tabindex="0"
          :aria-label="item.is_bookmarked ? 'Remove ' + item.title + ` from saved ${item.type_of_program_management}` : 'Add ' + item.title + ` to saved ${item.type_of_program_management}`"
          @click="handleBookmark(item)"
          @keyup.enter="handleBookmark(item)"
          class="resource-menu-button"
          role="button"
        >
          <i :style="item.is_bookmarked ? 'color: #9f2a8c; background: #eaeeff' : ''" class="fad fa-bookmark"></i>
          <div class="tooltip-text">
            {{item.is_bookmarked ? 'Remove bookmark' : 'Add bookmark'}}
          </div>
        </span>
      </td>
      <td class="eu-resource-cell eu-resource-cell-dots-icon">
        <span
          tabindex="0"
          ref="resListBurger"
          :id="`resource_burger_list_${item.id}`"
          @click="showBurger(item)"
          @keyup.enter="showBurger(item)"
          aria-label="Burger menu"
          role="menu"
          aria-haspopup="menu"
          class="resource-menu-button"
        >
          <i class="fas fa-ellipsis-v"></i>
          <div class="tooltip-text">
            More actions...
          </div>
        </span>
      </td>
      <div style="position: relative;">
        <div v-closable="{ exclude: ['resListBurgerDropdown', 'resListBurger'], handler: 'closeBurger' }" ref="resListBurgerDropdown" :id="`r${item.id}`" class="eu-resource-burger" style="right: 0%; top: 20px;">
          <router-link
            :to="item.type_of_program_management === 'event' ? `/events/${item.slug}` : `/programs/${item.slug}`"
            :aria-label="`Go to ${item.title} ${item.type_of_program_management}`"
            class="eu-resource-burger-el"
          >
            <i class="fas fa-eye" style="padding-right: 5px"></i>
            <span>View</span>
          </router-link>
          <a
            :href="item.type_of_program_management === 'event' ? `/events/edit/${item.id}` : `/programs/edit/${item.id}`"
            :aria-label="`Go to edit ${item.title} ${item.type_of_program_management} page`"
            role="button"
            class="eu-resource-burger-el"
          >
            <i class="fas fa-edit" style="padding-right: 5px"></i>
            <span>Edit</span>
          </a>
          <span
            tabindex="0"
            @click="handleFeatured(item)"
            @keyup.enter="handleFeatured(item)"
            role="button"
            class="eu-resource-burger-el"
          >
            <i class="fas fa-stars" style="padding-right: 5px"></i>
            <span>{{ item.is_featured ? "Unfeature" : "Feature" }}</span>
          </span>
          <span
            tabindex="0"
            :aria-label="item.is_bookmarked ? 'Remove ' + 'name' + ` from saved ${item.type_of_program_management}` : 'Add ' + 'name' + ` to saved ${item.type_of_program_management}`"
            @click="handleBookmark(item)"
            @keyup.enter="handleBookmark(item)"
            role="button"
            class="eu-resource-burger-el"
          >
            <i class="fad fa-bookmark" style="padding-right: 10px"></i>
            <span>{{ item.is_bookmarked ? "Unsave" : "Save" }}</span>
          </span>
          <span
            tabindex="0"
            :aria-label="item.is_archived ? `Remove ${item.title} from archived ${item.type_of_program_management}` : `Add ${item.title} to archived ${item.type_of_program_management}`"
            role="button"
            @click="handleUpdat(item, 'is_archived')"
            @keyup.enter="handleUpdat(item, 'is_archived')"
            class="eu-resource-burger-el"
          >
            <i
              class="fas fa-archive"
              style="padding-right: 5px"
            >
            </i>
            <span>{{ item.is_archived ? 'Unarchive' : 'Archive' }}</span>
          </span>
          <span
            tabindex="0"
            :aria-label="item.is_published ? `Remove ${item.title} from draft ${item.type_of_program_management}` : `Add ${item.title} to daft ${item.type_of_program_management}`"
            role="button"
            @click="handleUpdat(item, 'is_published')"
            @keyup.enter="handleUpdat(item, 'is_published')"
            class="eu-resource-burger-el"
          >
            <i
              class="fa-solid fa-bullhorn"
              style="padding-right: 5px"
            >
            </i>
            <span>{{ item.is_published ? 'Draft' : 'Publish' }}</span>
          </span>
          <span
            tabindex="0"
            :aria-label="`Open delete ${item.title} ${item.type_of_program_management} dialog`"
            aria-haspopup="dialog"
            @click="openDeleteModal(item)"
            @keyup.enter="openDeleteModal(item)"
            role="button"
            class="eu-resource-burger-el"
          >
            <i class="fas fa-trash-alt" style="padding-right: 5px"></i>
            <span>Delete</span>
          </span>
        </div>
      </div>
    </tr>
  </table>
  <Notification @close="closeNotification" :isActiveProp="isNotificationActive" :notification="notification" />
</section>
</template>

<script>
import EventsIndexService from "../services/EventsIndexService";
import "../utils/DateFormat";
import Cookies from "js-cookie";
import handleOutsideClick from '../../directives/handleOutsideClick'
import { formatEventTime } from "../utils/DateFormat";
import Notification from "../../components/Notification.vue";

export default {
  name: "events-table",
  directives: { closable: handleOutsideClick },
  components: {
    Notification
  },
  props: ["events", "numberOfGuest", "directionUp", "sortedBy"],
  data() {
    return {
      lastOpenedBurger: null,
      notification: { msg: "", isError: false, icon: null },
      isNotificationActive: false,
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
      },
    };
  },
  async created() {
    this.service = new EventsIndexService();
  },
  methods: {
    closeBurger() {
      if (!this.lastOpenedBurger) return;

      this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
      this.lastOpenedBurger = null;
    },
    showBurger(el) {
      const burgerEl = document.querySelector(`#r${el.id}`);
      if (this.lastOpenedBurger && this.lastOpenedBurger != burgerEl) {
        this.lastOpenedBurger.classList.remove("eu-resource-burger-active");
      }
      burgerEl.classList.toggle("eu-resource-burger-active");
      this.lastOpenedBurger = burgerEl;
    },
    closeNotification() {
      this.notification = { msg: "", isError: false, icon: null };
      this.isNotificationActive = false;
    },
    showNotification(notification) {
      this.notification = notification;
      this.isNotificationActive = true;
    },
    async handleBookmark(item) {
      item.is_bookmarked = !item.is_bookmarked;

      const payload = {
        target: item.id,
        action: item.is_bookmarked ? "add" : "remove",
      };

      const res = await this.service.postBookmark(payload, this.headers);
      const notification = { msg: '', isError: false, icon: null };
      if (res.success) {
        notification.msg = `Bookmark successfully ${item.is_bookmarked ? "added" : "removed"}`;
        notification.icon = "fad fa-check-circle";
      } else {
        notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        notification.isError = false;
        notification.icon = "fad fa-exclamation-circle";
      }
      this.showNotification(notification);
    },
    is_past_event(item) {
      let today = new Date();
      today =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 < 10 ? "0" : "") +
        (today.getMonth() + 1) +
        "-" +
        ((today.getDate() < 10 ? "0" : "") + today.getDate());
      if (today > item.start_date) return true;
      return false;
    },
    async handleFeatured(item) {
      item.is_featured = !item.is_featured;
      const payload = {
        target: item.id,
        action: item.is_featured ? "feature" : "unfeature",
      };

      const res = await this.service.postFeatured(payload, this.headers);
      const notification = { msg: '', isError: false, icon: null };
      if (res.success) {
        notification.msg = `Bookmark successfully ${item.is_featured ? "featured" : "unfeatured"}`;
        notification.icon = "fad fa-check-circle";
      } else {
        notification.msg = `Oops, something went wrong. Please reload page and try again.`;
        notification.isError = false;
        notification.icon = "fad fa-exclamation-circle";
      }
      this.showNotification(notification);
    },
    openDeleteModal(item) {
      this.$emit("open-delete-modal", item);
    },
    getNumberOfGuest(item) {
      for (let a of this.numberOfGuest) {
        if (a["event_id"] == item.id) {
          return a["num"];
        }
      }
      return 0;
    },
    sort(column) {
      this.$emit("sort_table", column);
    },
    handleUpdat(item, field){
      item[field] = ! item[field];
      this.$emit("handle_update", item);
    },
    formatEventTime,
  },
};
</script>

<style scoped>
</style>

