<template>
  <section>
    <div class="eu-featured-event-container">
      <div class="eu-featured-event-card">
        <div class="eu-featured-event-header-container">
          <i class="fas fa-stars eu-featured-event-header-icon"></i>
          <p class="eu-featured-event-header">Featured Events</p>
          <div class="eu-featured-event-carousel-dots-container">
            <span
              tabindex="0"
              v-for="(event, index) in featuredEvents"
              :key="index"
              :ref="'carousel-dot-' + index"
              class="eu-featured-event-carousel-dot"
              :class="{ 'eu-featured-event-carousel-dot-selected': index === 0 }"
              @click="stopCarousel(); setFeaturedEvent(event, index)"
              @keyup.enter="stopCarousel(); setFeaturedEvent(event, index)"
            ></span>
          </div>
          <span class="eu-featured-event-header-number">
            {{currentIndex + 1}} of {{featuredEvents.length}}
          </span>
        </div>
        <hr class="eu-featured-event-hr-small">

        <div class="eu-featured-event-body">
          <router-link :to="currentlyFeatured.slug">
            <p class="eu-featured-event-title">
              {{ currentlyFeatured.name }}
            </p>
          </router-link>

          <div class="eu-featured-event-date">
            <p>
              {{ currentlyFeatured.start_date | dateFormat() }}
              <span v-if="currentlyFeatured.start_time">
                <span style="color:#00a1b3"> | </span>
                {{formatEventTime(currentlyFeatured)}}
              </span>
              <br>
              <span>{{ currentlyFeatured.time_zone }}</span>
            </p>
          </div>

          <div class="eu-featured-event-details">
            <p v-html="currentlyFeatured.description"></p>
          </div>
          <div class="event-category-container">
              <p  
              v-for="item in currentlyFeatured.focus_areas"
              :key="item.id"  
              class="event-category">
                {{item.name}}
              </p>
          </div>
          <div class="eu-featured-event-info-container">
          
            <span v-if="currentlyFeatured.is_online" class="eu-featured-event-location">
                <i class="eu-featured-event-icon fas fa-desktop"></i>
                <p>Virtual</p>
            </span>
            <div v-else class="eu-featured-event-location">
                <i class="eu-featured-event-icon fas fa-map-marker-alt"></i>
                {{ currentlyFeatured.city }}, {{ currentlyFeatured.state }}
            </div>
            <span
              tabindex="0"
              v-if="currentlyFeatured.is_bookmarked"
              @click.prevent="removeBookmark(currentlyFeatured)"
              @keyup.enter="removeBookmark(currentlyFeatured)"
              class="eu-featured-event-bookmark"
            >
              <i class="eu-featured-event-icon fad fa-bookmark"></i>
              <p>Unsave</p>
            </span>
            <span              
              tabindex="0"
              v-else
              @click.prevent="addBookmark(currentlyFeatured)"
              @keyup.enter="addBookmark(currentlyFeatured)"
              class="eu-featured-event-bookmark"
            >
              <i class="eu-featured-event-icon fal fa-bookmark"></i>
              <p>Save</p>
            </span>
          </div>
        </div>
      </div>
      <img
        v-if="currentlyFeatured.thumbnail"
        :src="currentlyFeatured.thumbnail"
        alt="Featured event thumbnail"
        class="eu-featured-event-image"
        width="705"
        height="470"
      >
    </div>
    <hr class="eu-featured-event-hr-big">
  </section>
</template>
<script lang="ts">
import { Event } from "../../types";
import "../utils/DateFormat"
import { formatEventTime  } from '../utils/DateFormat';

export default {
  name: "featured-events",
  props: ["featuredEvents"],

  data() {
      return {
        currentlyFeatured: this.featuredEvents[0],
        currentIndex: 0,

        timer: null,
      };
  },

  created() {
    let self = this;

    this.timer = setInterval(function() {
      if (self.currentIndex === self.featuredEvents.length - 1) {
        self.currentIndex = 0;
      } else {
        self.currentIndex++;
      }
      self.setFeaturedEvent(self.featuredEvents[self.currentIndex], self.currentIndex);
    }, 4000);
  },

  beforeDestroy() {
    this.stopCarousel();
  },

  methods: {
    addBookmark(item: Event) {
      this.$emit("add-bookmark", item.id);
      item.is_bookmarked = true;
    },

    removeBookmark(item: Event) {
      this.$emit("remove-bookmark", item.id);
      item.is_bookmarked = false;
    },

    setFeaturedEvent(event: Event, index: number) {
      let oldDot = document.getElementsByClassName("eu-featured-event-carousel-dot-selected")[0];
      oldDot.classList.remove("eu-featured-event-carousel-dot-selected");

      let selectedDot = this.$refs['carousel-dot-' + index][0];
      selectedDot.classList.add('eu-featured-event-carousel-dot-selected');

      this.currentIndex = index;
      this.currentlyFeatured = event;
    },

    stopCarousel() {
      clearInterval(this.timer);
    },

    formatEventTime 
  },
}
</script>
