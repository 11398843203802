<template>
  <div>
    <section class="partner-tenant-opportunities-hero-section">
      <div class="partner-tenant-opportunities-hero-content-container">
        <div class="partner-tenant-opportunities-hero-text-container">
          <h2 class="partner-tenant-opportunities-hero-title">
            Explore new opportunities that will boost your career
          </h2>
          <p class="partner-tenant-opportunities-hero-description">
            Reach your potential and choose between
            opportunities from all around the world.
          </p>
        </div>
      </div>
    </section>
    <breadcrumbs
      :routeItems="breadcrumbsLinkItems"
      currentPageLabel="Opportunities"
    />
    <section class="partner-tenant-opportunities-unit-section white">
      <div class="partner-tenant-opportunities-unit-content-container">
        <div class="partner-tenant-opportunities-unit-text-content-container">
          <div class="partner-tenant-opportunities-unit-text-container">
            <h3 class="partner-tenant-opportunities-unit-title">
              Leadership Programs
            </h3>
            <p class="partner-tenant-opportunities-unit-description">
              We are dedicated to connecting students and recent
              graduates to unique leadership opportunities that
              will equip them with the skills, experiences, and
              networks to thrive personally, academically,
              and professionally in an interconnected global
              workforce.
            </p>
          </div>
          <img class="partner-tenant-opportunities-unit-image mobile-only"
            src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Leadership%20Programs%20Collage%20Mobile.webp"
            alt="Leadership Programs image"
          />
          <div style="display: flex;">
            <router-link class="partner-tenant-opportunities-unit-button" to="/partner/home/opportunities/leadership-programs">
              Learn More
            </router-link>
          </div>
        </div>
        <img class="partner-tenant-opportunities-unit-image desktop-only"
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Leadership-Programs-Collage.webp"
          alt="Leadership Programs image"
        />
      </div>
    </section>
    <section class="partner-tenant-opportunities-unit-section gray">
      <div class="partner-tenant-opportunities-unit-content-container reverse">
        <div class="mobile-only">
          <!-- <button class="partner-tenant-opportunities-unit-button">
            Find An Opportunity
          </button> -->
        </div>
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Events-Opportunities-Collage.webp"
          alt="Events &amp; Opportunities"
          class="partner-tenant-opportunities-unit-image"
        >
        <div class="partner-tenant-opportunities-unit-text-content-container">
          <div class="partner-tenant-opportunities-unit-text-container">
            <h3 class="partner-tenant-opportunities-unit-title">
              Events &amp; Opportunities
            </h3>
            <p class="partner-tenant-opportunities-unit-description">
              Sign up, complete your profile, and let global education
              and career opportunities find you. Easily search for
              events, global experiences, scholarships, internships
              &amp; jobs, and graduate schools from our partners.
              Opportunities are available abroad and locally!
            </p>
          </div>
          <div class="partner-tenant-opportunities-unit-events-container">
            <div class="partner-tenant-opportunities-unit-event-card">
              <img
                class="partner-tenant-opportunities-unit-event-image"
                src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Seoul-South-KoreaThumbnail.webp"
                alt="Seoul, South Korea"
              />
              <div class="partner-tenant-opportunities-unit-event-text">
                <i class="fa-solid fa-location-dot"></i> Seoul, South Korea
              </div>
            </div>
            <div class="partner-tenant-opportunities-unit-event-card">
              <img
                class="partner-tenant-opportunities-unit-event-image"
                src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/New-York-USA-Thumbnail.webp"
                alt="New York, USA"
              />
              <div class="partner-tenant-opportunities-unit-event-text">
                <i class="fa-solid fa-location-dot"></i> New York, USA
              </div>
            </div>
            <div class="partner-tenant-opportunities-unit-event-card">
              <img
                class="partner-tenant-opportunities-unit-event-image"
                src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Budapest-Hungary-Thumbnail.webp"
                alt="Budapest, Hungary"
              />
              <div class="partner-tenant-opportunities-unit-event-text">
                <i class="fa-solid fa-location-dot"></i> Budapest, Hungary
              </div>
            </div>
          </div>
          <div class="partner-tenant-opportunities-unit-button-wrapper">
            <a class="partner-tenant-opportunities-unit-button" href="/accounts/register">
              Find an Opportunity
            </a>
          </div>
          <div class="desktop-only">
            <!-- <button class="partner-tenant-opportunities-unit-button">
              Find An Opportunity
            </button> -->
          </div>
        </div>
      </div>
    </section>
    <section class="partner-tenant-opportunities-unit-section white">
      <div class="partner-tenant-opportunities-unit-content-container">
        <div class="partner-tenant-opportunities-unit-text-content-container">
          <div class="partner-tenant-opportunities-unit-text-container">
            <h3 class="partner-tenant-opportunities-unit-title">
              Scholarships &amp; Funding
            </h3>
            <p class="partner-tenant-opportunities-unit-description">
              Funding study abroad and other global experiences is
              a barrier to access. We offer our community a
              central location to find scholarships, discounts,
              and other funding programs for international opportunities.
            </p>
            <div class="partner-tenant-opportunities-unit-button-wrapper">
            <a class="partner-tenant-opportunities-unit-button" href="/accounts/register">
              Get Funded
            </a>
          </div>
          </div>
          <div class="desktop-only">
            <!-- <button class="partner-tenant-opportunities-unit-button">
              Get Funded
            </button> -->
          </div>
        </div>
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Scholarship-Funding-Collage.webp"
          alt="Scholarships &amp; Funding"
          class="partner-tenant-opportunities-unit-image desktop-only"
        />
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Scholarship-Funding-Collage-Mobile.webp"
          alt="Scholarships &amp; Funding"
          class="partner-tenant-opportunities-unit-image mobile-only"
          style="width: 15rem; height: 15rem;"
        />
        <div class="mobile-only">
          <!-- <button class="partner-tenant-opportunities-unit-button">
            Get Funded
          </button> -->
        </div>
      </div>
    </section>
    <section class="partner-tenant-opportunities-unit-section gray">
      <div class="partner-tenant-opportunities-unit-content-container reverse">
        <div class="mobile-only">
          <!-- <button class="partner-tenant-opportunities-unit-button">
            Start Exploring
          </button> -->
        </div>
        <img
          src="https://core-diva.sfo2.digitaloceanspaces.com/engageup/assets/Resources-Guides-Collage.webp"
          alt="Resources &amp; Guides"
          class="partner-tenant-opportunities-unit-image"
        />
        <div class="partner-tenant-opportunities-unit-text-content-container">
          <div class="partner-tenant-opportunities-unit-text-container">
            <h3 class="partner-tenant-opportunities-unit-title">
              Guides &amp; Resources
            </h3>
            <p class="partner-tenant-opportunities-unit-description">
              We offer our community a repository of culturally relevant
              resources and guides that explore that are curated from our network of content partners.
              From diversity &amp; inclusion destination guides to how-to videos, we have the resources
              to support you in your global endeavors.
            </p>
            <div class="partner-tenant-opportunities-unit-button-wrapper">
            <a class="partner-tenant-opportunities-unit-button" href="/accounts/register">
              Start Exploring
            </a>
          </div>
          </div>
          <div class="desktop-only">
            <!-- <button class="partner-tenant-opportunities-unit-button">
              Start Exploring
            </button> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang='ts'>
import Breadcrumbs from './components/Breadcrumbs.vue';

export default {
  name: 'partner-tenant-opportunities',
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbsLinkItems: [
        {
          'label': 'Home',
          'path': '/partner/home',
          'isSpa': true
        }
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-only {
  display: none;
}

.desktop-only {
  display: initial;
}

@media screen and (max-width: 576px) {
  .mobile-only {
    display: initial;
  }

  .desktop-only {
    display: none;
  }
}
</style>
