<template>
 <div v-if="user" class="settings-links-wrapper">
    <div class="settings-links-container">
        <h2 class='settings-title'>You</h2>
        <span tabindex="0" @click="redirectTo('/settings/profile')" :class="activeTab === 'profile' && 'active'" class="link-element">Edit Profile</span>
        <a v-if="user.is_mentor && (isPartnerTenant || user.dept)"  tabindex="0" @click="redirectTo('/settings/mentor-profile')" :class="activeTab === 'mentorProfile' && 'active'" class="link-element">Edit Mentor Profile</a>
        <span tabindex="0" @click="redirectTo('/settings/interests')" :class="activeTab === 'myInterests' && 'active'" class="link-element">Edit My Interests</span>
        <a v-if="info.has_passport_access" id='settings-edit-passport' href="/pro/settings/passport" class="link-element">Edit Passport</a>
        <span tabindex="0" @click="redirectTo('/settings/preferences')" :class="activeTab === 'preferences' && 'active'" class="link-element">Preferences</span>
        <span tabindex="0" @click="redirectTo('/settings/password')" :class="activeTab === 'password' && 'active'" class="link-element">Reset Password</span>
    </div>
<div class="settings-links-container">
    <h2 v-if="info.has_partners_settings_access || info.managing_partner || (isDeptLead && user.dept && info.has_depts_access)" class='settings-title'>Management</h2>
    <a v-if="isPartnerTenant && info.has_partners_settings_access" aria-label="Partner Accounts Settings Page" id='settings-settings-partners' href="/profiles/settings/partners" class="link-element">Manage Partners</a>
    <a v-if="isPartnerTenant && info.managing_partner" aria-label="Partner Account Settings Page" id='settings-edit-partner' :href="`/partneraccounts/${info.managing_partner.name}/edit`" class="link-element">Manage Partner Account</a>
    <a v-if="isDeptLead && user.dept && info.has_depts_access" id='settings-edit-department' :href="`/departments/${user.dept}/edit`" class="link-element">Edit {{deptSingular}} Profile</a>
</div>

<div v-if="isAdmin" class="settings-links-container">
    <h2 class='settings-title'>Organization</h2>
    <a v-if="!isPartnerTenant && info.has_depts_access" aria-label="Departments Settings Page" id='settings-settings-department' href="/profiles/settings/departments" class="link-element">{{deptPlural}}</a>
    <a v-if="info.has_groups_access" aria-label="Affinity Groups Settings Page" id='settings-settings-groups' href="/profiles/settings/groups" class="link-element">Affinity Groups</a>
    <a aria-label="Contact Groups Settings Page" id='settings-settings-contactGroups' href="/profiles/settings/contactGroups" class="link-element">Contact Groups</a>
    <a v-if="hasMentorConnectAccess" aria-label="Mentor Groups Settings Page" id='settings-settings-mentorGroups' href="/profiles/settings/mentorGroups" class="link-element">Mentor Groups</a>
    <a aria-label="Focus Areas Settings Page" id='settings-settings-focusareas' href="/profiles/settings/focusareas" class="link-element">Focus Areas</a>
    <a aria-label="Identities Settings Page" id='settings-settings-identities' href="/profiles/settings/identities" class="link-element">Identities</a>
    <a aria-label="Career/Academic Interests Settings Page" id='settings-settings-cai' href="/profiles/settings/cais" class="link-element">Career/Academic Interests</a>
    <a aria-label="Audiences Settings Page" id='settings-settings-audiences' href="/profiles/settings/audiences" class="link-element">Audiences</a>
    <a aria-label="Permissions & Roles Page" id='settings-settings-roles' href="/cp/roles" class="link-element">Permissions & Roles</a>
    <a aria-label="Customize" id='settings-settings-organization' href="/profiles/settings/organization" class="link-element">Customization</a>
</div>

<div v-if="!isAdmin && (info.manage_contact_groups || info.manage_community_groups || isDeptLead)" class="settings-links-container">
    <h2 class='settings-title'>Organization</h2>
    <a v-if="info.manage_contact_groups" aria-label="Contact Groups Settings Page" id='settings-settings-contactGroups' href="/profiles/settings/contactGroups" class="link-element">Contact Groups</a>
    <a v-if="info.has_groups_access && info.manage_community_groups" aria-label="Affinity Groups Settings Page" id='settings-settings-groups' href="/profiles/settings/groups" class="link-element">Affinity Groups</a>
    <a v-if="hasMentorConnectAccess && isDeptLead" aria-label="Mentor Groups Settings Page" id='settings-settings-mentorGroups' href="/profiles/settings/mentorGroups" class="link-element">Mentor Groups</a>
</div>


 </div>
</template>
<script lang="ts">

import { useAuthStore } from '../../stores/auth';
import { useCommonDataStore } from '../../stores/commonData';
import { isPartnerTenant } from "../../utils/CustomUtils";
import { FEATURES, hasPermission } from "../../utils/PermissionUtils";

export default {
  name: "settings-sidebar",
  props: {
    activeTab: String,
  },
  data() {
    return {
      authStore: useAuthStore(),
      isPartnerTenant: isPartnerTenant(),
      hasMentorConnectAccess: hasPermission(FEATURES.MENTOR_CONNECT),
      user: null,
      info: null,
      isAdmin: false,
      isDeptLead: false,

    };
  },
  async created() {
    const userData = await this.authStore.getUser;
    this.user = userData.user;
    this.isAdmin = this.user.role === 'ADMIN';
    this.isDeptLead = this.user.role === 'DEPARTMENT_LEAD' || this.user.role === 'ADMIN';
    this.info = this.authStore.getAdditionalFields;
  },
  methods: {
    routeExistsInRouter(path: string) {
      try {
        const resolvedRoute = this.$router.resolve(path);
        return resolvedRoute.resolved.matched.length > 0;
      } catch (error) {
        return false;
      }
    },
    redirectTo(url: string) {
      if (this.routeExistsInRouter(url)) {
        this.$router.push(url);
      } else {
        window.location.href = url;
      }
    },
  },
  computed: {
    deptSingular() {
      return useCommonDataStore().getFeatureName('department_singular')
    },
    deptPlural() {
      return useCommonDataStore().getFeatureName('department_plural')
    },
  },
};
</script>
